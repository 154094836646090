import { InputBase, InputBaseComponentProps, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { closestTo, format, isValid, parse, toDate } from 'date-fns';

const GridEditDateInput = styled(InputBase)({
  fontSize: 'inherit',
  padding: '0 9px',
});

const DatePickerCell = ({ id, field, value, colDef }: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();

  const handleChange = (newValue: Date | null) => {
    if (isValid(newValue)) {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    } else {
      apiRef.current.setEditCellValue({ id, field, value: '' });
    }
  };

  const displayFormattedDate = (inputProps: InputBaseComponentProps | undefined) => {
    if (inputProps) {
      if (isValid(parse(value, 'dd/MM/yyyy', new Date()))) {
        inputProps.value = format(parse(value, 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy');
      } else if (isValid(inputProps.value)) {
        inputProps.value = format(new Date(value), 'dd/MM/yyyy');
      }
    }
    return inputProps;
  };

  return (
    <DatePicker
      inputFormat="dd/MM/yyyy"
      value={value}
      renderInput={({ inputRef, inputProps, InputProps, disabled, error }) => (
        <GridEditDateInput
          fullWidth
          autoFocus
          ref={inputRef}
          {...InputProps}
          disabled={disabled}
          error={error}
          inputProps={displayFormattedDate(inputProps)}
        />
      )}
      onChange={handleChange}
    />
  );
};

export default DatePickerCell;
