import { Box, IconButton, Theme, Typography, useTheme } from '@mui/material';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { Row } from './IdentifierConfigModal';
import React from 'react';

const createStyles = (theme: Theme, row: Row) => {
  const mode = theme.palette.mode;
  return {
    box: {
      width: '100%',
      height: '40px',
      backgroundColor: mode === 'light' ? 'common.lightblue' : '#2D333A',
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
      padding: '5px 20px',
      borderRadius: '4px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: mode === 'light' ? 'rgb(200, 215, 225)' : '#2D333A80',
      },
    },
    codeText: {
      width: '200px',
      color:
        mode === 'light'
          ? row.code === ''
            ? '#ffffff'
            : 'common.greyblueDark'
          : row.code === ''
          ? '#ffffff23'
          : 'common.white',
      fontWeight: 600,
    },
    nameText: {
      width: '200px',
      color:
        mode === 'light'
          ? row.name === ''
            ? '#ffffff'
            : 'common.greyblueDark'
          : row.name === ''
          ? '#ffffff23'
          : 'common.white',
      fontWeight: 600,
    },
    iconButton: {
      borderRadius: '4px',
      color: mode === 'light' ? 'common.greyblueDark' : 'common.greyblue',
    },
  };
};

const RowItem = ({
  row,
  setEditableRowIndex,
  deleteRow,
  index,
}: {
  row: Row;
  setEditableRowIndex: React.Dispatch<React.SetStateAction<number | null>>;
  deleteRow: (index: number) => void;
  index: number;
}) => {
  const theme = useTheme();
  const styles = createStyles(theme, row);
  return (
    <Box sx={styles.box} onDoubleClick={() => setEditableRowIndex(index)}>
      <Box sx={{ display: 'flex', flex: 6 }}>
        <Typography sx={styles.codeText}>
          {row.code === '' ? 'Enter a code' : row.code}
        </Typography>
        <Typography sx={styles.nameText}>
          {row.name === '' ? 'Enter a name' : row.name}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <IconButton onClick={() => setEditableRowIndex(index)} sx={styles.iconButton}>
          <FontAwesomeIcon icon="fas fa-edit" size="16px" />
        </IconButton>
        <IconButton
          onClick={() => deleteRow(index)}
          sx={{ borderRadius: '4px', color: 'common.negative' }}
        >
          <FontAwesomeIcon icon="fas fa-trash-alt" size="14px" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default RowItem;
