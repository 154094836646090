import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Box,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from '@mui/material';
import { modifyLocalStorageObject, useLocalStorage } from '../../hooks/useLocalStorage';

import { styled } from '@mui/material/styles';
import FontAwesomeIcon from '../FAIcon';

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: 'white',
  backgroundColor: theme.palette.common.greyblueDarker,
  fontWeight: 'bold',
  [theme.breakpoints.up('xs')]: {
    padding: '5px 0',
    fontSize: 17,
  },
  [theme.breakpoints.up('md')]: {
    padding: '10px 0',
    fontSize: 20,
  },
}));

interface ModalContentProps {
  title: string;
  children: React.ReactNode;
  contentMinHeight: string;
  img?: string;
  video?: string;
  stepper: React.ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAgain: React.Dispatch<React.SetStateAction<boolean>>;
  showAgain: boolean;
  onClose: () => void;
}

const ModalContent = ({
  title,
  children,
  contentMinHeight = '400px',
  img,
  video,
  stepper,
  setIsOpen,
  setShowAgain,
  showAgain,
  onClose,
}: ModalContentProps) => {
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let target = event.target as HTMLInputElement;
    setShowAgain((prev) => !prev);
    setUserSettings(
      modifyLocalStorageObject(
        { ...userSettings, showNewFeatures: !target.checked },
        'userSettings'
      )
    );
  };
  return (
    <Card
      sx={{
        height: '100%',
        borderRadius: '4px',
        overflow: 'hidden',
        width: '800px',
        position: 'relative',
      }}
    >
      <CardHeader
        title={<Title>{`${title}`}</Title>}
        sx={{ padding: 0, backgroundColor: '#436072', height: '45px' }}
      />
      {img && (
        <CardMedia
          component="img"
          height="auto"
          image={img}
          alt="new feature"
          //sx={{ maxHeight: '350px' }}
        />
      )}
      {video && (
        <CardMedia
          component="video"
          height="auto"
          image={video}
          alt="new feature"
          autoPlay
          muted
          loop
          allow="autoPlay"
          //sx={{ maxHeight: '350px' }}
        />
      )}
      <Box sx={{ padding: '10px', paddingTop: 0 }}>
        <CardContent
          sx={{
            minHeight: contentMinHeight,
            minWidth: { xs: '80vw', md: '600px' },
            textAlign: 'center',
          }}
        >
          {children}
        </CardContent>
        <CardActions sx={{ justifyContent: 'end', padding: '0 8px' }}>
          {stepper}
        </CardActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={!showAgain}
              sx={{
                padding: 0,
                marginRight: '2px',
                '&.Mui-checked': {
                  color: '#79909f',
                },
              }}
              onClick={handleChange}
              size="small"
            />
          }
          label="Don't show me again"
          sx={{
            width: '100%',
            justifyContent: 'end',
            marginTop: '15px',
            '& .MuiFormControlLabel-label': {
              fontSize: '0.7rem',
            },
          }}
        />
      </Box>
      <IconButton
        id="closeNewFeatures"
        sx={{
          position: 'absolute',
          top: '1%',
          right: '1%',
          color: 'common.white',
          width: '30px',
        }}
        onClick={() => {
          onClose();
          setIsOpen(false);
        }}
      >
        <FontAwesomeIcon icon="fas fa-times" size={15} padding={0} color="" />
      </IconButton>
    </Card>
  );
};

export default ModalContent;
