import React, { createRef, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { GridEditModes, GridToolbarContainer } from '@mui/x-data-grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from '@mui/material/ButtonGroup';
import FontAwesomeIcon from '../../FAIcon';
import {
  Tooltip,
  Box,
  Button,
  SvgIcon,
  IconButton,
  Divider,
  MenuItem,
  Menu,
  ListItemIcon,
  Typography,
} from '@mui/material';
import ScanningModal from '../../../containers/indexing/ScanningModal';
import CustomToolbarActionButtons from './CustomToolbarActionButtons';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { Context as StatusContext } from '../../../context/statusContext';
import { Context as AuthContext, AuthContextTypes } from '../../../context/authContext';
import ErrorHandler from '../../../utils/errorHandler';
import AssignToModal from './AssignToModal';
import { useQuery } from '@apollo/react-hooks';
import { GET_USERS_BY_ORG, GET_USER_BY_FOLDER } from '../../../utils/gql/gqlUsers';
import { Context as tasksContext } from '../../../context/tasksContext';
import { User } from '../masterTableTypes';
import { CustomToolbarProps } from './CustomToolbarTypes';
import ChangeFolderModal from './ChangeFolderModal';
import {
  hasCollectionBinPermissions,
  hasDeleteRecordPermissions,
  hasIndexByBarcodePermissions,
  hasIndexModifiedPermissions,
  hasUnindexPermissions,
  hasIndexByOCRPermissions,
  hasQuickViewPermissions,
  hasSetWorkflowPermissions,
} from '../../../containers/search/searchUtils';
import { StatusContextTypes } from '../../../context/statusContextTypes';
import { TasksContextTypes } from '../../../context/tasksContextTypes';

const OCRIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 4h7l5 5v8.58l-1.84-1.84c1.28-1.94 1.07-4.57-.64-6.28C14.55 8.49 13.28 8 12 8c-1.28 0-2.55.49-3.53 1.46-1.95 1.95-1.95 5.11 0 7.05.97.97 2.25 1.46 3.53 1.46.96 0 1.92-.28 2.75-.83L17.6 20H6V4zm8.11 11.1c-.56.56-1.31.88-2.11.88s-1.55-.31-2.11-.88c-.56-.56-.88-1.31-.88-2.11s.31-1.55.88-2.11c.56-.57 1.31-.88 2.11-.88s1.55.31 2.11.88c.56.56.88 1.31.88 2.11s-.31 1.55-.88 2.11z" />
    </SvgIcon>
  );
};

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  borderRadius: 0,
  boxShadow: 'none',
  '& .MuiButton-contained': {
    borderRadius: 0,
    border: 0,
  },
  '& .MuiButtonGroup-root .Mui-disabled': {
    borderRadius: 0,
    border: 0,
    boxShadow: 'none',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  borderRadius: 0,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.common.blueShadow['300']
      : theme.palette.common.blue,
  ':hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.common.blueShadow['500']
        : theme.palette.primary.dark,
		},
  padding: '7px 10px',
  fontSize: 10,
  minWidth: 0,
}));
const StyledButtons = styled(Button)(({ theme }) => ({
  height: '32px',
  width: '32px',
  minWidth: '32px',
  borderRadius: '0',
  ':hover': {
    backgroundColor: theme.palette.common.greyblueDarker,
  },
}));
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  alignItems: 'center',
  marginLeft: 20,
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&.MuiToggleButton-root': {
      color: theme.palette.common.white,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.common.blueShadow['400']
          : theme.palette.common.greyblueDark,
      borderRadius: 0,
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.common.blueShadow['500']
            : theme.palette.common.greyblueDarker,
        color: theme.palette.common.white,
      },
    },
    '&.Mui-selected': {
      color: theme.palette.common.greyblueDark,
      backgroundColor: theme.palette.common.white,
    },
  },
}));
const ToolbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.common.greyblueDarker
      : theme.palette.common.greyblueDarkerTransp,
  borderRadius: '5px 5px 0 0',
  color: theme.palette.white.main,
  overflow: 'hidden',
  minHeight: '32px',
}));
const RightToolbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.white.main,
}));
const LeftToolbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));
/* const StyledButtons = styled(Button)(({ theme }) => ({
  height: "32px",
  width: "32px",
  minWidth: "32px",
  borderRadius: "0",
  ":hover": {
    backgroundColor: theme.palette.common.greyblueDarker,
  },
})); */

const TableMenu = ({
  theUser,
  showExtraActions,
}: {
  theUser: User[];
  showExtraActions: (
    theUser: User[],
    handleClose: () => void
  ) => JSX.Element[] | undefined;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="Show actions"
        id="table-menu-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FontAwesomeIcon icon="fas fa-ellipsis-v" size={16} />
      </IconButton>
      <Menu
        id="table-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 90 * 4.5,
            width: '28ch',
          },
        }}
      >
        {showExtraActions(theUser, handleClose)}
      </Menu>
    </div>
  );
};

export default function CustomToolbar(props: CustomToolbarProps) {
  const {
    screen,
    selected,
    defaultTableOptions,
    editMode,
    setEditMode,
    setQuickView,
    onDeleteButton,
    addToCollection,
    unindexRecords,
    createRecord,
    setWorkflowModal,
    rows,
    setRows,
    selectedFolder,
    setSnackOpen,
    setMessage,
    setErrorFiles,
    setLoadingFiles,
    loadingFiles,
    extraActions,
    cols,
    onSaveRows,
    exportQuery,
    anchorUploadEl,
    setAnchorUploadEl,
    handleUploadClick,
    isUploadFromDrag,
    count,
    clearFields,
    selectedTemplate,
    buttonReportRef,
    tablePermissions = { collectionBin: true },
    handleReapplyLinkedInformations,
  } = props;

  const { addTask } = useContext(tasksContext) as TasksContextTypes;
  const { state: authState } = useContext(AuthContext) as AuthContextTypes;
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const [userData] = useLocalStorage('userData');
  const { role, suser, selOrg } = userData;
  const { data } = useQuery(suser ? GET_USERS_BY_ORG : GET_USER_BY_FOLDER, {
    variables: suser
      ? { organisationId: selOrg.id }
      : {
          folderId: selectedFolder ? selectedFolder.id : null,
        },
  });
  let users: User[] = [];

  const [assignToOpen, setAssignToOpen] = React.useState(false);
  const [openChangeFolder, setOpenChangeFolder] = React.useState(false);
  const [modes, setModes] = React.useState(() => [
    defaultTableOptions?.edit ? 'edit' : '',
    defaultTableOptions?.quickView ? 'quickView' : '',
  ]);
  const [scannerOpen, setScannerOpen] = React.useState(false);
  const ref = createRef<HTMLDivElement>();
  const [width, setWidth] = useState(0);

  const handleClickOpenScan = () => {
    setScannerOpen(true);
  };

  let WIDTH_LIMIT = 400; // It is used to delimit the width and display depending on the action menu or buttons in the toolbar.

  const handleModeChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newFormats: string[]
  ) => {
    if (!newFormats.includes('edit') && defaultTableOptions?.edit === true) {
      setEditMode?.();
    }
    if (!newFormats.includes('quickView') && defaultTableOptions?.quickView === true) {
      setQuickView?.();
    }
    if (newFormats.includes('quickView') && defaultTableOptions?.quickView === false) {
      setQuickView?.();
    }
    if (newFormats.includes('edit') && defaultTableOptions?.edit === false) {
      setEditMode?.();
    }
    setModes(newFormats);
  };

  const ButtonWrap = ({
    title,
    onClick,
    key,
    children,
    id,
    sx,
    onClose,
  }: {
    title: string;
    onClick: any;
    key: string;
    children: JSX.Element;
    onClose: () => void;
    id?: string;
    sx?: any;
  }) => {
    return width > WIDTH_LIMIT ? (
      <Tooltip key={key} title={title} arrow>
        <StyledButton disabled={false} onClick={onClick} id={id || title} sx={sx}>
          {children}
        </StyledButton>
      </Tooltip>
    ) : (
      <MenuItem
        key={key}
        onClick={() => {
          onClick();
          onClose();
        }}
        sx={sx}
      >
        <ListItemIcon>{children}</ListItemIcon>
        <Typography noWrap fontSize={12}>
          {title}
        </Typography>
      </MenuItem>
    );
  };

  const showExtraActions = (theUser: User[], handleClose: () => void = () => {}) => {
    if (!state.selectedFolder || state.selectedFolder[state.screen] === '') return;
    const actions = [];
    for (let action in extraActions) {
      switch (action) {
        case 'scan':
          if (!state.selectedFolder[state.screen]?.scanning) break;
          actions.push(
            <ButtonWrap
              title="Scan Barcodes"
              onClick={() => extraActions[action]({ selected: selected, preview: true })}
              key={action}
              onClose={handleClose}
            >
              <FontAwesomeIcon icon="fas fa-qrcode" size="18px" />
            </ButtonWrap>
          );
          break;
        case 'ocr':
          if (!state.selectedFolder[state.screen]?.ocrEnabled) break;
          actions.push(
            <ButtonWrap
              title="Scan with OCR"
              onClick={() => extraActions[action]({ selected, preview: true })}
              key={action}
              onClose={handleClose}
            >
              <FontAwesomeIcon icon="fa-spell-check" size="14px" />
            </ButtonWrap>
          );
          break;
        case 'saveSelected':
          if (state.selectedFolder[state.screen]?.saveRecord) break; //add this to permissions
          actions.push(
            <ButtonWrap
              title="Save Selected Records"
              onClick={(e: React.SyntheticEvent) =>
                extraActions.saveSelected(e, selected as string[])
              }
              key={action}
              sx={{
                backgroundColor: 'common.positive',
                '&:hover': {
                  backgroundColor: 'common.positiveDark',
                },
              }}
              onClose={handleClose}
            >
              <FontAwesomeIcon icon="fas fa-save" size="18px" />
            </ButtonWrap>
          );
          break;
        case 'assignTo':
          if (state.selectedFolder[state.screen]?.assignTo) break; //add this to permissions
          actions.push(
            <React.Fragment key={action}>
              <ButtonWrap
                title="Assign to be indexed by"
                onClick={() => setAssignToOpen(true)}
                key={action}
                onClose={handleClose}
              >
                <FontAwesomeIcon icon="fas fa-user-tag" size="16px" />
              </ButtonWrap>
            </React.Fragment>
          );
          break;
        case 'changeFolderToRecord':
          if (state.selectedFolder[state.screen]?.changeFolder) break; //add this to permissions
          actions.push(
            <React.Fragment key={action}>
              <ButtonWrap
                title="Change folder"
                onClick={() => setOpenChangeFolder(true)}
                key={action}
                id="change-folder"
                onClose={handleClose}
              >
                <span className="fa-stack">
                  <FontAwesomeIcon icon="fas fa-folder fa-stack-3x" size="20px" />

                  <FontAwesomeIcon
                    icon="fas fa-arrows-alt fa-stack-2x"
                    size="15px"
                    color="#0A5487"
                  />
                  <FontAwesomeIcon
                    icon="fas fa-arrows-alt fa-stack-2x"
                    size="14px"
                    color="#79909F"
                  />
                </span>
              </ButtonWrap>
            </React.Fragment>
          );
          break;
        default:
          break;
      }
    }

    if (
      (state.screen === 'search' &&
        hasDeleteRecordPermissions(
          authState.permissions || null,
          userData,
          selectedFolder,
          false
        )) ||
      state.screen === 'indexing'
    ) {
      actions.push(
        <ButtonWrap
          title="Delete Record/s"
          onClick={() => onDeleteButton?.(selected)}
          key={'delete-button'}
          sx={{
            backgroundColor: 'common.negative',
            '&:hover': {
              backgroundColor: 'common.negativeDark',
            },
          }}
          onClose={handleClose}
        >
          <FontAwesomeIcon icon="far fa-trash-alt" size="18px" />
        </ButtonWrap>
      );
    }

    if (
      tablePermissions?.collectionBin &&
      hasCollectionBinPermissions(
        authState.permissions || null,
        userData,
        selectedFolder,
        false
      )
    ) {
      actions.push(
        <ButtonWrap
          title="Add to Collection Bin"
          onClick={addToCollection}
          key="collection-button"
          onClose={handleClose}
        >
          <FontAwesomeIcon icon="fas fa-clipboard-list" size="18px" />
        </ButtonWrap>
      );
    }

    if (selectedFolder.linked_Information_Mapping && state.screen === 'search') {
      actions.push(
        <ButtonWrap
          title="Re-sync records"
          onClick={() => handleReapplyLinkedInformations(selected as string[])}
          key="resync-records"
          onClose={handleClose}
        >
          <FontAwesomeIcon icon="fas fa-sync" size="18px" />
        </ButtonWrap>
      );
    }

    if (
      hasUnindexPermissions(
        authState.permissions || null,
        userData,
        selectedFolder,
        false
      ) &&
      state.screen === 'search'
    ) {
      actions.push(
        <ButtonWrap
          title="Unindex Record/s"
          onClick={() => unindexRecords(selected)}
          key="unindex-button"
          onClose={handleClose}
        >
          <FontAwesomeIcon icon="fas fa-unlink" size="15px" />
        </ButtonWrap>
      );
    }

    if (
      state.workflow &&
      state.currentOrg &&
      state.currentOrg.processPathEnabled &&
      hasSetWorkflowPermissions(
        authState.permissions || null,
        userData,
        state.selectedFolder[state.screen],
        false
      )
    ) {
      actions.push(
        <ButtonWrap
          title="Set workflow"
          onClick={() => setWorkflowModal(true)}
          key="workflow-button"
          onClose={handleClose}
        >
          <FontAwesomeIcon icon="fas fa-project-diagram" size={15} />
        </ButtonWrap>
      );
    }

    return actions;
  };

  let theUser: User[] = [];
  if (data) {
    users = suser ? data.UsersByOrg.users : data.UsersByFolder.users;
    if (users) {
      theUser = users.filter((option) => {
        if (
          option.firstName === userData.userName &&
          option.lastName === userData.userLname
        ) {
          return option;
        }
      });
    }
  }

  useEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  if (users && users.length > 0) {
    return (
      <ErrorHandler>
        <div>
          <ToolbarContainer
            ref={ref}
            sx={{
              flexDirection: width > 850 ? 'row' : 'column',
            }}
          >
            <LeftToolbarContainer sx={{ justifyContent: 'space-between', gap: 1.5 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {rows && rows.length > 0 && screen === 'indexing' && (
                  <Tooltip title="Clear all fields" arrow>
                    <StyledButtons disabled={false} onClick={clearFields}>
                      <FontAwesomeIcon
                        icon="fas fa-undo-alt"
                        size="14px"
                        color="#fff"
                      ></FontAwesomeIcon>
                    </StyledButtons>
                  </Tooltip>
                )}
                {selected && selected.length ? (
                  <>
                    <div style={{ width: '45px', textAlign: 'center', fontSize: 10 }}>
                      {selected.length}
                      <br />
                      rows
                    </div>
                    <Box sx={{ display: width > WIDTH_LIMIT ? 'flex' : 'none' }}>
                      <FontAwesomeIcon
                        icon="fas fa-grip-lines-vertical"
                        size="18px"
                        color="rgba(255,255,255,0.2)"
                      />
                    </Box>
                  </>
                ) : null}
              </Box>
              {selected && selected.length ? (
                <>
                  <StyledButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    {theUser && width > WIDTH_LIMIT ? (
                      showExtraActions(theUser)
                    ) : (
                      <TableMenu theUser={theUser} showExtraActions={showExtraActions} />
                    )}
                  </StyledButtonGroup>
                </>
              ) : null}
            </LeftToolbarContainer>
            <Divider />
            <RightToolbarContainer>
              <GridToolbarContainer
                sx={{ padding: 0, width: '100%', justifyContent: 'space-between' }}
              >
                <Box>
                  {state.screen === 'indexing' &&
                  selectedFolder &&
                  selectedFolder.ocrEnabled ? (
                    hasIndexByOCRPermissions(
                      authState.permissions || null,
                      userData,
                      selectedFolder,
                      false
                    ) ? (
                      <Tooltip
                        title="Index by using OCR to read the values directly from the records."
                        arrow
                      >
                        <StyledButton
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === 'dark'
                                ? 'common.greyblueDarkerTransp'
                                : 'common.greyblueDarker',
                            '&:hover': {
                              backgroundColor: 'common.blue',
                            },
                          }}
                          size="small"
                          disabled={false}
                          onClick={(e) => {
                            extraActions?.ocr({ preview: false, selected });
                          }}
                          startIcon={<OCRIcon />}
                        >
                          {width > 800 || (width > 800 && selected && !selected.length)
                            ? 'Index with OCR'
                            : 'IO'}
                        </StyledButton>
                      </Tooltip>
                    ) : null
                  ) : null}
                  {state.screen === 'indexing' &&
                  selectedFolder &&
                  selectedFolder.scanning &&
                  hasIndexByBarcodePermissions(
                    authState.permissions || null,
                    userData,
                    selectedFolder,
                    false
                  ) ? (
                    <Tooltip
                      title='All the records will be scanned for barcodes and indexed automatically, this process is unatended and errors can later be edited in the "search" screen.'
                      arrow
                    >
                      <StyledButton
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                              ? 'common.greyblueDarkerTransp'
                              : 'common.greyblueDarker',
                          '&:hover': {
                            backgroundColor: 'common.blue',
                          },
                        }}
                        size="small"
                        disabled={false}
                        onClick={() => extraActions?.scan({ selected, preview: false })}
                        startIcon={
                          <FontAwesomeIcon icon="fas fa-file-upload" size="18px" />
                        }
                      >
                        {width > 800 || (width > 800 && selected && !selected.length)
                          ? 'Index By Barcode'
                          : 'IB'}
                      </StyledButton>
                    </Tooltip>
                  ) : null}
                  {state.screen === 'indexing' ? (
                    hasIndexModifiedPermissions(
                      authState.permissions || null,
                      userData,
                      selectedFolder,
                      false
                    ) ? (
                      <Tooltip
                        title="Only records that have been modified manually or with the barcode scanner will be indexed."
                        arrow
                      >
                        <StyledButton
														data-testId={'index-modified-button'}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === 'dark'
                                ? 'common.greyblueDarkerTransp'
                                : 'common.greyblueDarker',
                            '&:hover': {
                              backgroundColor: 'common.blue',
                            },
                          }}
                          size="small"
                          disabled={false}
                          onClick={(e) => {
                            //let selected = rows.map((row) => row.id);
                            setTimeout(() => {
                              onSaveRows?.(e, true, rows);
                            }, 50);
                          }}
                          startIcon={
                            <FontAwesomeIcon icon="fas fa-file-upload" size="18px" />
                          }
                        >
                          {width > 800 || (width > 800 && selected && !selected.length)
                            ? 'Index Modified'
                            : 'IM'}
                        </StyledButton>
                      </Tooltip>
                    ) : null
                  ) : null}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CustomToolbarActionButtons
                    exportQuery={exportQuery}
                    screen={screen}
                    handleClickOpenScan={handleClickOpenScan}
                    selectedFolder={selectedFolder}
                    setSnackOpen={setSnackOpen}
                    setMessage={setMessage}
                    rows={rows}
                    cols={cols}
                    setRows={setRows}
                    createRecord={createRecord}
                    anchorUploadEl={anchorUploadEl}
                    setAnchorUploadEl={setAnchorUploadEl}
                    handleUploadClick={handleUploadClick}
                    isUploadFromDrag={isUploadFromDrag}
                    theUser={theUser}
                    users={users}
                    setErrorFiles={setErrorFiles}
                    setLoadingFiles={setLoadingFiles}
                    loadingFiles={loadingFiles}
                    count={count}
                    selectedTemplate={selectedTemplate}
                    buttonReportRef={buttonReportRef}
                  />
                  {screen !== 'reporting' && (
                    <FontAwesomeIcon
                      icon="fas fa-grip-lines-vertical"
                      size="18px"
                      color="rgba(255,255,255,0.2)"
                    />
                  )}
                  <StyledToggleButtonGroup
                    size="small"
                    value={modes}
                    onChange={handleModeChange}
                    aria-label="Modes"
                  >
                    {/* {!permissions.edit ? null : (
                <Tooltip title="Edit Mode" arrow>
                  <ToggleButton
                    selected={options.edit}
                    value="edit"
                    aria-label="edit"
                  >
                    <FontAwesomeIcon icon="far fa-edit" size="18px" />
                  </ToggleButton>
                </Tooltip>
              )} */}

                    {screen !== 'reporting' &&
                      (editMode === 'cell' ? (
                        <Tooltip title="Cell editing mode active" arrow>
                          <ToggleButton
                            value={'row'}
                            onClick={() => {
                              setEditMode?.('row' as GridEditModes);
                            }}
                          >
                            <FontAwesomeIcon icon="far fa-th-large" size="18px" />
                          </ToggleButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Row editing mode active" arrow>
                          <ToggleButton
                            value={'cell'}
                            onClick={() => {
                              setEditMode?.('cell' as GridEditModes);
                            }}
                          >
                            <FontAwesomeIcon icon="far fa-th-list" size="18px" />
                          </ToggleButton>
                        </Tooltip>
                      ))}
                    {hasQuickViewPermissions(
                      authState.permissions || null,
                      userData,
                      selectedFolder,
                      false
                    ) && screen !== 'reporting' ? (
                      <Tooltip title="View Document" arrow>
                        <ToggleButton
                          selected={defaultTableOptions?.quickView}
                          value="quickView"
                          aria-label="quickView"
                        >
                          <FontAwesomeIcon icon="far fa-eye" size="18px" />
                        </ToggleButton>
                      </Tooltip>
                    ) : null}
                  </StyledToggleButtonGroup>
                </Box>
              </GridToolbarContainer>
            </RightToolbarContainer>
            {scannerOpen && (
              <ScanningModal
                selectedFolder={selectedFolder || null}
                createRecord={createRecord}
                setUnindexed={setRows}
                unIndexed={rows}
                open={scannerOpen}
                setOpen={setScannerOpen}
                theUser={theUser}
                users={users}
                addTask={addTask}
                deleteRecords={onDeleteButton}
              />
            )}
            {assignToOpen && (
              <AssignToModal
                open={assignToOpen}
                action={extraActions!.assignTo}
                selected={selected as string[]}
                setOpen={setAssignToOpen}
                users={users}
                rows={rows}
                setMessage={setMessage}
                setSnackOpen={setSnackOpen}
              />
            )}
            {openChangeFolder && (
              <ChangeFolderModal
                open={openChangeFolder}
                action={extraActions!.changeFolderToRecord}
                selected={selected}
                selectedFolder={selectedFolder}
                setOpen={setOpenChangeFolder}
                users={users}
                rows={rows}
                setMessage={setMessage}
                setSnackOpen={setSnackOpen}
              />
            )}
          </ToolbarContainer>
        </div>
      </ErrorHandler>
    );
  } else {
    return null;
  }
}
