import { FormControlLabel, FormLabel, Grid, Divider, Typography } from '@mui/material';
import React from 'react';
import StyledSwitch from '../../../components/form/StyledSwitch';

export interface folderOptions {
  autoAppendRecords: boolean;
  scanning: boolean;
  ocrEnabled: boolean;
  workFlow: boolean;
  enforceWorkflowNotes: boolean;
}

interface FolderOptionProps {
  options: folderOptions;
  setOptions: React.Dispatch<React.SetStateAction<folderOptions>>;
}

const FolderOptions = ({ options, setOptions }: FolderOptionProps) => {
  return (
    <Grid container paddingY="20px">
      <Grid item container xs={12}>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="autoAppendRecords"
                  color="secondary"
                  checked={options.autoAppendRecords}
                  onClick={(e) => {
                    setOptions((prev) => ({
                      ...prev,
                      autoAppendRecords: !options.autoAppendRecords,
                    }));
                  }}
                />
              }
              label={<Typography variant="caption">Auto Append Records</Typography>}
            />
          </Grid>
          <Grid item>
            <FormLabel>
              Records with the same main field will be automatically merged together
            </FormLabel>
          </Grid>
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="workFlow"
                  color="secondary"
                  checked={options.workFlow}
                  onClick={(e) => {
                    setOptions((prev) => ({
                      ...prev,
                      workFlow: !options.workFlow,
                    }));
                  }}
                />
              }
              label={<Typography variant="caption">Workflow Functionality</Typography>}
            />
          </Grid>
          <Grid item>
            <FormLabel>
              Enabling workflow allows the folder to have approval workflows and other
              kinds of workflows
            </FormLabel>
          </Grid>
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="enforceWorkflowNotes"
                  color="secondary"
                  checked={options.enforceWorkflowNotes}
                  onClick={(e) => {
                    setOptions((prev) => ({
                      ...prev,
                      enforceWorkflowNotes: !options.enforceWorkflowNotes,
                    }));
                  }}
                />
              }
              label={<Typography variant="caption">Enforce Workflow Notes</Typography>}
            />
          </Grid>
          <Grid item>
            <FormLabel>Enforce workflow notes description</FormLabel>
          </Grid>
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="scanning"
                  color="secondary"
                  checked={options.scanning}
                  onClick={(e) => {
                    setOptions((prev) => ({
                      ...prev,
                      scanning: !options.scanning,
                    }));
                  }}
                />
              }
              label={<Typography variant="caption">Barcode scan Enabled</Typography>}
            />
          </Grid>
          <Grid item>
            <FormLabel>
              Enable the barcode scanning functionality on this folder
            </FormLabel>
          </Grid>
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="ocr"
                  color="secondary"
                  checked={options.ocrEnabled}
                  onClick={(e) => {
                    setOptions((prev) => ({
                      ...prev,
                      ocrEnabled: !options.ocrEnabled,
                    }));
                  }}
                />
              }
              label={<Typography variant="caption">OCR Enabled</Typography>}
            />
          </Grid>
          <Grid item>
            <FormLabel>
              This option enables OCR automatic document reading to index records
              automatically
            </FormLabel>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FolderOptions;
