import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  List,
  Drawer,
  Toolbar,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  MenuOpen as MenuOpenIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import { useLocalStorage, modifyLocalStorageObject } from '../hooks/useLocalStorage';
import SideDrawerItem from './sideDrawerItem';
import { Context as StatusContext } from '../context/statusContext';
import { Context as AuthContext, AuthContextTypes } from '../context/authContext';
import utils from '../utils/utils';
import { StatusContextTypes } from '../context/statusContextTypes';
import { makeStyles } from '@mui/styles';
let { hasSetWorkflowInAnyFolder } = utils;

const MENU_OPTIONS = [
  { title: 'Dashboard', link: '/dash', role: -1, suser: true, user: true },
  {
    title: 'Security Groups',
    link: '/dash/users',
    role: -1,
    suser: true,
    user: true,
  },
  {
    title: 'Organisations',
    link: '/dash/orgs',
    role: -1,
    suser: true,
    user: true,
  },
  { title: 'Users', link: '/dash/users', role: 0, suser: true, user: true },
  {
    title: 'Folders',
    link: '/dash/folders',
    role: 0,
    suser: true,
    user: true,
  },
  {
    title: 'Reporting',
    link: '/dash/reports',
    role: 1,
    suser: true,
    user: true,
  },
  { title: 'Search', link: '/dash/search', role: 2, suser: true, user: true },
  {
    title: 'Process Steps',
    link: '/dash/folders',
    role: -1,
    suser: true,
    user: true,
  },
  {
    title: 'Indexing',
    link: '/dash/indexing',
    role: 1,
    suser: true,
    user: true,
  },
  {
    title: 'ErrorLog',
    link: '/dash/errorLogs',
    role: -1,
    suser: true,
    user: true,
  },
  {
    title: 'Communication',
    link: '/dash/communication',
    role: -1,
    suser: true,
    user: true,
  },
  {
    title: 'Maintenance',
    link: '/dash/maintenance',
    role: -1,
    suser: true,
    user: true,
  },
  {
    title: 'WorkFlow',
    link: '/dash/workflow',
    role: 2,
    suser: true,
    user: true,
  },
];

const drawerWidth = 160;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: 'width 0.3s ease-in-out',
  },
  drawerClose: {
    transition: 'width 0.3s ease-in-out',
    overflowX: 'hidden',
    width: 54,
  },
  drawerContainer: {
    overflowX: 'auto',
    height: '100%',
  },
  drawerToggle: {
    alignSelf: 'flex-end',
  },
}));

const SideDrawer = () => {
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const { state: authState } = useContext(AuthContext) as AuthContextTypes;
  const classes = useStyles();
  const [{ sideDrawerToggled }, setExpanded] = useLocalStorage('userSettings');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const toggleDrawerExpanded = () =>
    setExpanded(
      modifyLocalStorageObject({ sideDrawerToggled: !sideDrawerToggled }, 'userSettings')
    );

  useEffect(() => {
    MENU_OPTIONS.forEach((option, index) => {
      if (option.link === window.location.pathname) {
        setSelectedIndex(index);
      }
    });
  }, []);

  return (
    <Drawer
      variant="permanent"
      className={`${classes.drawer} ${
        sideDrawerToggled ? classes.drawerOpen : classes.drawerClose
      }`}
      classes={{
        paper: `${classes.drawer} ${
          sideDrawerToggled ? classes.drawerOpen : classes.drawerClose
        }`,
      }}
      PaperProps={{
        sx: {
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.greyblueDarker
              : undefined,
        },
      }}
    >
      <Toolbar />
      <Grid container className={classes.drawerContainer}>
        <Grid item xs={12}>
          <List>
            {MENU_OPTIONS.map((option, i) => {
              if (
                option.title === 'WorkFlow' &&
                authState.permissions &&
                (!state.workflow ||
                  (state.currentOrg && !state.currentOrg.processPathEnabled) ||
                  !hasSetWorkflowInAnyFolder(authState.permissions))
              )
                return null;
              return (
                <SideDrawerItem
                  key={`${option}${i}`}
                  title={option.title}
                  link={option.link}
                  role={option.role}
                  suser={option.suser}
                  user={option.user}
                  sideDrawerToggled={sideDrawerToggled}
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
                  index={i}
                />
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.drawerToggle}>
          <List>
            <ListItem onClick={toggleDrawerExpanded}>
              <ListItemIcon sx={{ minWidth: '35px' }}>
                {sideDrawerToggled ? <MenuOpenIcon /> : <ChevronRightIcon />}
              </ListItemIcon>
              <ListItemText
                sx={{ minWidth: '35px' }}
                primary={sideDrawerToggled ? 'Collapse menu' : ''}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default SideDrawer;
