import download from 'downloadjs';
import JSZip from 'jszip';

const save = {
  zip: {},
  do: async (urls, collection, filename) => {
    save.init();
    let mainValues;
    let names = [];
    let count = {};
    for (let i = 0; i < collection.length; i++) {
      mainValues = collection[i].fields.filter((field, j) => {
        if (collection[i].folderId.primaryFolderFieldId === field.field.id) {
          return field;
        } else if (!collection[i].folderId.primaryFolderFieldId && j === 0) {
          return field;
        } else {
          return [{ field: { name: 'notFilled' }, value: 'notFilled' }];
        }
      });
      if (!count.hasOwnProperty(mainValues[0].value)) count[mainValues[0].value] = 0;
      if (mainValues[0].value === '')
        mainValues[0].value = collection[i].folder + '_' + collection[i].id.slice(-5);
      window.log(urls);
      const url = urls[i].getUrl;
      if (names.includes(mainValues[0].value)) {
        let newName = `${mainValues[0].value}(${++count[mainValues[0].value]})`;
        count[mainValues[0].value] = count[mainValues[0].value]++;
        names.push(newName);
        await save.addToZip(url, { value: newName });
      } else {
        names.push(mainValues[0].value);
        await save.addToZip(url, mainValues[0]);
      }
    }
    let date = new Date(Date.now());

    save.zip.generateAsync({ type: 'blob' }).then(
      function (blob) {
        let name = filename
          ? filename
          : collection[0].folder
          ? collection[0].folder + '_' + date.toISOString() + '.zip'
          : mainValues[0].value + '.zip';
        download(blob, name, 'application/octet-stream');
      },
      function (err) {
        window.log(err);
      }
    );
  },
  addToZip: async (url, fileName) => {
    const newName =
      fileName.value && fileName.value !== '' ? fileName.value : save.getNewName();
    const pdfArrayBuffer = await fetch(url).then((res) => res.arrayBuffer());
    const pdfBlob = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
    save.zip.file(newName + '.pdf', pdfBlob);
  },
  getNewName: () => {
    let options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    let date = new Date(Date.now()).toLocaleDateString('en-GB', options);
    date = date.replace(' ', '_');
    date = date.replace(', ', '_');
    date = date.replace(':', '_');
    return date;
  },
  init: () => {
    save.zip = new JSZip();
  },
};

export default save;
