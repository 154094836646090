import { useEffect, useState } from 'react';

const useCountdown = (endDate: string) => {
  const endDateMs = new Date(endDate).getTime();

  const [countDown, setCountDown] = useState(endDateMs - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(endDateMs - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [endDateMs]);

  return getTimeLeft(countDown);
};

const getTimeLeft = (countDown: number) => {
  if (countDown < 0) return [0, 0, 0, 0];

  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };
