import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider as AuthProvider } from './context/authContext';
import { Provider as StatusProvider } from './context/statusContext';
import debugInit from './utils/debug';

debugInit();

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <StatusProvider>
        <App />
      </StatusProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
