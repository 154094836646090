import { Backdrop, Box, CircularProgress, Stack } from '@mui/material';
import { fabric } from 'fabric';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFabricContext } from '../../hooks/useFabricCanvas';
import RTC from '../../utils/socketComm';
import { enclose, getClientPosition, zoomDelta } from './fabricUtils';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { Context as RTCContext } from '../../context/RTCContext';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { BoundingBoxInput } from '../../hooks/OCRTemplateSetup/types';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/display/api';

GlobalWorkerOptions.workerSrc =
  'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';

interface Props {
  fileURL: string;
  template?: any;
}

const OCRDocumentView = (props: Props) => {
  const { fileURL, template } = props;
  const activeRectRef = useRef<fabric.Rect | null>(null);
  const canvasRefs = useRef<fabric.Canvas[]>([]);
  const { state, setAnalyzedArea } = useContext(RTCContext);

  const {
    state: { screen },
  } = useContext(StatusContext) as StatusContextTypes;
  const {
    canvasRef,
    canDraw,
    selectedObject,
    inputType,
    boundingBox,
    objects,
    setObjects,
    folderFieldId,
    totalPages,
    setTotalPages,
    currentPage,
    toggleObjectVisibility,
    ocrTemplateFolderField,
    setOcrTemplateFolderField,
    analyzedResponseObjects,
    setAnalyzedResponseObjects,
    action,
    panning,
    textractId,
    setTextractId,
    deleteAllObjects,
    validateAreaData,
    modifiedFields,
    setModifiedFields,
    setBackdrop,
    recordId,
  } = useFabricContext();
  const containerRef = useRef<any>();
  // These are needed for the canvas to be able to access the state updated variables
  const origXRef = useRef<number>(0);
  const origYRef = useRef<number>(0);
  const canDrawRef = useRef<boolean>(canDraw);
  const selectedObjectRef = useRef<string>(selectedObject);
  const textractIdRef = useRef('');
  const folderFieldIdRef = useRef('');
  const inputTypeRef = useRef('');
  const inputNameRef = useRef('');
  const objectsRef = useRef(objects);
  const clickedPageRef = useRef(0);
  const zoomInfoRef = useRef([]);
  const ocrTemplateFolderFieldRef = useRef(ocrTemplateFolderField);
  const ocrTemplateRef = useRef();
  const analyzedAreaRef = useRef();
  const actionRef = useRef<string>();
  const panningRef = useRef(panning);
  const validateAreaDataRef = useRef();
  // const pdfRef = useRef<PDFDocumentProxy>();
  const [pdf, setPdf] = useState<PDFDocumentProxy>();
  const [loadingPDF, setLoadingPDF] = useState(true);
  const currentPageRef = useRef(currentPage);
  const isDraggingRef = useRef(false);
  const lastPosXRef = useRef(0);
  const lastPosYRef = useRef(0);
  const [initialCanvasPositionX, setInitialCanvasPositionX] = useState(0);

  // This useEffects that sets only a state in their refs are needed for the canvas to
  // be able to access the state updated variables inside the event handlers
  //
  // because the event handlers are not updated when the state is updated
  useEffect(() => {
    objectsRef.current = objects;
  }, [objects]);

  useEffect(() => {
    selectedObjectRef.current = selectedObject;
  }, [selectedObject]);

  useEffect(() => {
    canDrawRef.current = canDraw;
  }, [canDraw]);

  useEffect(() => {
    if (state && state.textractId) {
      textractIdRef.current = state.textractId;
      setTextractId(state.textractId);
    }
  }, [state]);

  useEffect(() => {
    ocrTemplateFolderFieldRef.current = ocrTemplateFolderField;
  }, [ocrTemplateFolderField]);

  useEffect(() => {
    if (folderFieldId !== '') {
      folderFieldIdRef.current = folderFieldId;
    }
  }, [folderFieldId]);

  useEffect(() => {
    if (inputType) {
      inputTypeRef.current = inputType;
    }
  }, [inputType]);

  useEffect(() => {
    if (state.ocrTemplate) {
      ocrTemplateRef.current = state.ocrTemplate;
    } else if (template) {
      ocrTemplateRef.current = template;
    }
  }, [state.ocrTemplate]);

  useEffect(() => {
    if (state.analyzedArea) {
      analyzedAreaRef.current = state.analyzedArea;
    }
  }, [state.analyzedArea]);

  useEffect(() => {
    if (action) {
      actionRef.current = action;
    }
  }, [action]);

  useEffect(() => {
    panningRef.current = panning;
  }, [panning]);

  useEffect(() => {
    validateAreaDataRef.current = validateAreaData;
  }, [validateAreaData]);

  useEffect(() => {
    currentPageRef.current = currentPage;
  });

  const onMouseDown = (
    event: fabric.IEvent,
    isDragging: MutableRefObject<boolean>,
    lastPosX: MutableRefObject<number>,
    lastPosY: MutableRefObject<number>
  ) => {
    clickedPageRef.current = currentPageRef.current;
    const canvas = canvasRef.current;
    let e = event.e;

    if (event.button === 2 || (panningRef.current && !canDrawRef.current)) {
      isDragging.current = true;
      e.srcElement.classList.add('panning');
      const { clientX, clientY } = getClientPosition(e);
      lastPosX.current = clientX;
      lastPosY.current = clientY;
      canvas.selection = false;
      canvas.discardActiveObject();
    } else {
      isDragging.current = false;
    }

    if ((e.which === 1 || e.buttons === 1) && canDrawRef.current && !panningRef.current) {
      e.srcElement.classList.add('drawing');
      const selectedName = selectedObjectRef.current.split('.')[0];
      const main = selectedObjectRef.current.split('.')[1] === 'main';
      // Before create a new area, check if there is already one for the same field
      canvas.getObjects().forEach((object: fabric.Object) => {
        // if area button is main delete all objects for the field,
        // else only the one that is seleted header or value
        if (object.name && main && object.name.includes(selectedName)) {
          canvas.remove(object);
        } else if (object.name && !main && object.name === selectedObjectRef.current) {
          canvas.remove(object);
        } else if (object.name) {
          object.set({ visible: false });
        }
      });

      // const newObjects = objectsRef.current.filter((element) =>
      //   main
      //     ? !element.object.name.includes(selectedName)
      //     : element.object.name !== selectedObjectRef.current
      // );
      //
      // setObjects(newObjects);

      const pointer = canvas.getPointer(event.e);
      origXRef.current = pointer.x;
      origYRef.current = pointer.y;
      const rect = new fabric.Rect({
        left: origXRef.current,
        top: origYRef.current,
        width: pointer.x - origXRef.current,
        height: pointer.y - origYRef.current,
        angle: 0,
        fill: 'rgba(224, 107, 116, 0.4)',
        stroke: 'black',
        name: selectedObjectRef.current,
        lockRotation: true,
        hasControls: false,
      });

      canvas.add(rect);
      canvas.setActiveObject(rect);
      activeRectRef.current = rect;
    }
  };

  const onMouseMove = (
    event: fabric.IEvent,
    lastPosX: MutableRefObject<number>,
    lastPosY: MutableRefObject<number>
  ) => {
    let e = event.e;
    const canvas = canvasRef.current;
    const img = canvas.getObjects()[0] as fabric.Image;

    // if (
    //   (panningRef.current && !canDrawRef.current && e.which === 1 && e.buttons === 1) ||
    //   e.which === 2 ||
    //   e.buttons === 4
    // ) {
    //   const T = canvas.viewportTransform;
    //   const { clientX, clientY } = getClientPosition(e);
    //   T[4] += clientX - lastPosX.current;
    //   T[5] += clientY - lastPosY.current;
    //   canvas.requestRenderAll();
    //   lastPosX.current = clientX;
    //   lastPosY.current = clientY;
    //   enclose(canvas, img);
    // }
    if (e.which === 1 && e.buttons === 1 && canDrawRef.current && !panningRef.current) {
      const pointer = canvas.getPointer(event.e);
      const activeObject: fabric.Object = activeRectRef.current;

      if (activeObject instanceof fabric.Rect) {
        // Determine the new dimensions, ensuring they don't exceed the canvas bounds
        let newWidth = Math.min(
          Math.abs(origXRef.current - pointer.x),
          canvas.width - activeObject.left
        );
        let newHeight = Math.min(
          Math.abs(origYRef.current - pointer.y),
          canvas.height - activeObject.top
        );

        // Adjust position if dragging left/upwards
        if (origXRef.current > pointer.x) {
          activeObject.set({ left: pointer.x });
        }
        if (origYRef.current > pointer.y) {
          activeObject.set({ top: pointer.y });
        }

        activeObject.set({ width: newWidth, height: newHeight });

        canvas.renderAll();
      }
    }
  };

  const onMouseUp = (event: fabric.IEvent, isDragging: MutableRefObject<boolean>) => {
    const canvas = canvasRef.current;
    const e = event.e;
    if (e.which === 2 || e.buttons === 4) {
      isDragging.current = false;
      if (!panningRef.current) {
        e.srcElement.classList.remove('panning');
      }
      canvas.selection = true;

      canvas.renderAll();
    }
    if ((e.which === 1 || e.buttons === 1) && canDrawRef.current && !panningRef.current) {
      e.srcElement.classList.remove('drawing');

      const object = canvas.getActiveObject();

      const updateObjects = objectsRef.current.filter(
        (obj) => obj.name === selectedObjectRef.current
      );

      setObjects([...updateObjects, { object, page: currentPageRef.current }]);

      canvas.renderAll();
      // Get object's position and size
      const left = object.left;
      const top = object.top;
      const width = object.getScaledWidth();
      const height = object.getScaledHeight();

      // Get the normalized values
      const bounds = {
        left: left / canvas.getWidth(),
        top: top / canvas.getHeight(),
        width: width / canvas.getWidth(),
        height: height / canvas.getHeight(),
      };

      console.log(textractIdRef);

      const data: {
        textractId: string;
        bounds: {
          left: number;
          top: number;
          width: number;
          height: number;
        };
        folderFieldId: string;
        individual?: boolean;
        pageIndex: number;
      } = {
        textractId: textractIdRef.current ? textractIdRef.current : template.textractId,
        bounds,
        folderFieldId: folderFieldIdRef.current,
      };

      boundingBox.current = bounds;

      // if (currentPageRef.current === 1) data.pageIndex = 0;
      // if (currentPageRef.current === totalPages) data.pageIndex = totalPages - 1;
      data.pageIndex = currentPageRef.current - 1;

      // canvas.getObjects().forEach((object: fabric.Object) => {
      //   // if area button is main delete all objects for the field,
      //   // else only the one that is seleted header or value
      //   console.log(object, 'object');
      //   if (object.name) {
      //     console.log(object.name, 'object.name');
      //   }
      // });

      const identifierObject = canvas.getObjects().find((object: fabric.Object) => {
        return object.name === `${folderFieldIdRef.current}.header-0`;
      });

      // if (
      //   actionRef.current === 'validate-ocr-area' ||
      //   (actionRef.current === 'update-field-value' && !identifierObject)
      // ) {
      //   RTC.validateOcrArea({
      //     ...(validateAreaDataRef.current as any),
      //     boundingBox: bounds,
      //   });
      //   return;
      // }

      if (folderFieldIdRef.current !== ocrTemplateFolderFieldRef.current.id)
        if (actionRef.current === 'validate-ocr-area') {
          const validateData = {
            pageIndex: currentPageRef.current - 1,
            text: analyzedAreaRef.current?.SuggestedHeaderValuePair?.HeaderTextResults,
            textractId: textractIdRef.current
              ? textractIdRef.current
              : template.textractId,
            boundingBox: bounds,
          };
          RTC.textOnDocumentCount(validateData);
        } else {
          RTC.analyseOcrArea(data);
        }
      if (folderFieldIdRef.current === ocrTemplateFolderFieldRef.current.id) {
        window.log('draw-box-for-identifiers', data);
        RTC.drawBoxForIdentifiers(data);
      }

      setBackdrop(false);
    }
  };

  const onMouseWheel = (event: fabric.IEvent) => {
    const { e } = event;
    const img = canvasRef.current.getObjects().filter((obj) => !obj.name)[0];
    zoomDelta(canvasRef.current, e.deltaY, e.offsetX, e.offsetY);
    enclose(canvasRef.current, img);

    e.preventDefault();
    e.stopPropagation();
  };

  const onMouseOver = (event: fabric.IEvent) => {
    const { e } = event;

    if (panningRef.current) {
      e.srcElement.classList.add('panning');
    } else if (!panningRef.current) {
      e.srcElement.classList.remove('panning');
    }
  };

  const onObjectAdded = (e: fabric.IEvent) => {
    const newObject = e.target;

    canvasRef.current.discardActiveObject().renderAll();
  };

  const loadPDF = async (isComponentMounted: Boolean) => {
    try {
      const loadingTask = getDocument(fileURL);

      const pdf = await loadingTask.promise;

      if (!isComponentMounted) {
        pdf.destroy();
        return;
      }

      if (isComponentMounted && pdf.numPages > 0) {
        setLoadingPDF(false);
      }
      const numPages = pdf.numPages;
      setTotalPages(numPages);

      setPdf(pdf);

      const canvas = new fabric.Canvas(canvasRef.current, {});

      canvasRef.current = canvas;
    } catch (error) {
      console.log('Error loading pdf');
    }
  };

  const getScaleToFitContainer = (page: PDFPageProxy) => {
    // Get the viewport of the page at the given scale
    let viewport = page.getViewport({ scale: 1 });

    // get the container width and height
    const containerWidth = containerRef.current?.clientWidth * 2;
    const containerHeight = containerRef.current?.clientHeight * 2;
    console.log(
      'containerWidth, containerHeight',
      currentPage,
      containerRef.current,
      containerWidth,
      containerHeight
    );
    console.log('viewport', viewport);

    // calculate the correct scale to fit the page in the container
    const widthScale = containerWidth / viewport.width;
    const heightScale = containerHeight / viewport.height;
    const scale = Math.min(widthScale, heightScale);

    return scale;
  };

  const getOverScaledPageImage = async (page: PDFPageProxy) => {
    // // Create a new canvas to render the PDF page onto
    const tempCanvas = document.createElement('canvas');

    // get the viewport of the page with the scale 2
    // we want this to get a larger image for zooming without losing quality
    let viewport = page.getViewport({ scale: 2 });
    tempCanvas.width = viewport.width;
    tempCanvas.height = viewport.height;

    // Render the page onto the canvas
    await page.render({ canvasContext: tempCanvas.getContext('2d'), viewport }).promise;

    return tempCanvas.toDataURL();
  };

  const addObjectsForCurrentPage = () => {
    const currentPageObjects = objects.filter((object) => object.page === currentPage);

    if (currentPageObjects.length > 0) {
      currentPageObjects.forEach((el) => {
        canvasRef.current.add(el.object);
      });
    }
  };

  const placeImageOnCanvas = (page: PDFPageProxy, imageURL: string, scale: number) => {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        imageURL,
        function (img: fabric.Image) {
          img.set({
            left: 0,
            top: 0,
            selectable: false,
            evented: false,
            lockMovementX: true,
            lockMovementY: true,
            imageSmoothingEnabled: false,
          });

          let viewportToFit = page.getViewport({ scale });
          img.scaleToWidth(viewportToFit.width);
          img.scaleToHeight(viewportToFit.height);

          canvasRef.current.setWidth(viewportToFit.width);
          canvasRef.current.setHeight(viewportToFit.height);
          canvasRef.current.add(img);
          console.log('img', scale, img, currentPage);

          resolve();
        },
        {},
        function (error) {
          reject(error);
        }
      );
    });
  };

  const resetCanvas = () => {
    canvasRef.current.clear();
    canvasRef.current.__eventListeners = {};
  };

  const placePDFOnCanvas = async (pdf: PDFDocumentProxy) => {
    resetCanvas();
    const page = await pdf.getPage(currentPageRef.current);
    const scale = getScaleToFitContainer(page);
    const imageURL = await getOverScaledPageImage(page);
    await placeImageOnCanvas(page, imageURL, scale);
  };

  const renderCanvas = async (pdf: PDFDocumentProxy) => {
    await placePDFOnCanvas(pdf);
    addObjectsForCurrentPage();

    // Fabric Events ========================================================================
    canvasRef.current.on('mouse:down', (e: fabric.IEvent) =>
      onMouseDown(e, isDraggingRef, lastPosXRef, lastPosYRef)
    );
    canvasRef.current.on('mouse:move', (e: fabric.IEvent) =>
      onMouseMove(e, lastPosXRef, lastPosYRef)
    );
    canvasRef.current.on('mouse:up', (e: fabric.IEvent) => onMouseUp(e, isDraggingRef));
    // canvasRef.current.on('mouse:wheel', (e: fabric.IEvent) => onMouseWheel(e));
    canvasRef.current.on('mouse:over', (e: fabric.IEvent) => onMouseOver(e));
    canvasRef.current.on('object:added', (e: fabric.IEvent) => onObjectAdded(e));
  };

  useEffect(() => {
    let isComponentMounted = true;
    if (!fileURL) return;

    if (!pdf) {
      loadPDF(isComponentMounted);
    }

    return () => {
      isComponentMounted = false;
    };
  }, [fileURL]);

  useEffect(() => {
    if (pdf && currentPageRef.current > 0 && canvasRef.current) {
      renderCanvas(pdf);
    }
  }, [pdf, currentPage]);

  // if there is a ocr are response this draw the area in the canvas
  useEffect(() => {
    if (state?.analyzedArea) {
      window.log('state.analyzedArea', state?.analyzedArea);
      // The position reference for the valueBox
      let headerPoints =
        state.analyzedArea?.SuggestedHeaderValuePair?.HeaderWordBlocksTopRightPoint;

      let identifierBox: BoundingBoxInput | null = null;
      let valueBox: BoundingBoxInput | null = null;

      if (action === 'set-field-definition') {
        // the position for the box based on the position in HeaderWordBlocksTopRightPoint
        valueBox = state.analyzedArea?.SuggestedHeaderValuePair?.SuggestedBoxToFindValue;
      } else if (action === 'update-field-value') {
        if (!analyzedResponseObjects[folderFieldId]) return;
        valueBox = state.analyzedArea?.DrawnBox;

        console.log(valueBox, 'valueBox');
        const {
          identifierBox: oldIdentifierBox,
          valueBox: oldvalueBox,
          headerPoints: oldHeaderPoints,
        } = analyzedResponseObjects[folderFieldId][0];
        console.log('old', oldIdentifierBox, oldvalueBox, oldHeaderPoints);

        headerPoints = oldHeaderPoints;

        let newValueBox = {
          height: valueBox?.height,
          width: valueBox?.width,
          left: valueBox?.left - oldHeaderPoints.right,
          top: valueBox?.top - oldHeaderPoints.top,
        };

        valueBox = newValueBox;

        setAnalyzedResponseObjects(
          (prev: {
            valueBox: BoundingBoxInput;
            identifierBox: BoundingBoxInput;
            headerPoints: { top: number; right: number };
          }) => ({
            ...prev,
            [folderFieldId]: [
              { valueBox, identifierBox: oldIdentifierBox, headerPoints },
            ],
          })
        );
      }

      if (action === 'set-field-definition') {
        identifierBox = state.analyzedArea?.SuggestedHeaderValuePair?.HeaderBoundsOfWords;
      } else if (action === 'update-field-header') {
        if (!analyzedResponseObjects[folderFieldId]) return;
        identifierBox = state.analyzedArea?.DrawnBox;
        if (!analyzedResponseObjects[folderFieldId]) return;
        const {
          identifierBox: oldIdentifierBox,
          valueBox: oldvalueBox,
          headerPoints: oldHeaderPoints,
        } = analyzedResponseObjects[folderFieldId][0];
        if (!oldIdentifierBox || !oldvalueBox || !oldHeaderPoints) return;
        console.log('old', oldIdentifierBox, oldvalueBox, oldHeaderPoints);

        const absoluteLeft = oldHeaderPoints.right + oldvalueBox.left;
        const absoluteTop = oldHeaderPoints.top + oldvalueBox.top;

        const newHeaderPoints = state.analyzedArea?.WordBlocksTopRightPoint;

        let newValueBox = {
          height: oldvalueBox.height,
          width: oldvalueBox.width,
          left: 0,
          top: 0,
        };
        newValueBox.top = absoluteTop - newHeaderPoints.top;
        newValueBox.left = absoluteLeft - newHeaderPoints.right;
        valueBox = newValueBox;
      }

      if (action === 'set-field-definition') {
        setAnalyzedResponseObjects(
          (prev: {
            valueBox: BoundingBoxInput;
            identifierBox: BoundingBoxInput;
            headerPoints: { top: number; right: number };
          }) => ({
            ...prev,
            [folderFieldId]: [{ valueBox, identifierBox, headerPoints }],
          })
        );
      }

      if (action === 'update-field-header') {
        console.log(
          'update-field-header',
          folderFieldId,
          valueBox,
          identifierBox,
          headerPoints
        );
        setAnalyzedResponseObjects(
          (prev: {
            valueBox: BoundingBoxInput;
            identifierBox: BoundingBoxInput;
            headerPoints: { top: number; right: number };
          }) => ({
            ...prev,
            [folderFieldId]: [
              {
                valueBox,
                identifierBox,
                headerPoints: state.analyzedArea?.WordBlocksTopRightPoint,
              },
            ],
          })
        );
      }

      if (valueBox) {
        setModifiedFields((prev) => {
          if (!prev.includes(folderFieldId)) {
            return [...prev, folderFieldId];
          }
          return prev;
        });
        const canvas = canvasRef.current;
        let canvasWidth = canvas.getWidth();
        let canvasHeight = canvas.getHeight();

        // the pixel position of HeaderWordBlocksTopRightPoint relative to the  canvas
        let headerTop, headerRight;

        if (action === 'update-field-header') {
          headerTop = state.analyzedArea?.WordBlocksTopRightPoint.top * canvasHeight;
          headerRight = state.analyzedArea?.WordBlocksTopRightPoint.right * canvasWidth;
        } else {
          headerTop = headerPoints.top * canvasHeight;
          headerRight = headerPoints.right * canvasWidth;
        }
        const valueRectName = `${folderFieldId}.value-0`;
        const nameIdentifierRect = `${folderFieldId}.header-0`;

        canvas.getObjects().forEach((object: fabric.Object) => {
          if (object.name === selectedObjectRef.current) {
            object.set({ visible: false });
          }
          if (
            (object.name && object.name === valueRectName) ||
            object.name === nameIdentifierRect
          ) {
            canvas.remove(object);
          }
        });

        const newObjects: { object: fabric.Rec; page: number }[] = [];

        console.log('new', valueBox);
        let valueRect: fabric.Rect;
        // if (action === 'update-field-value' || action === 'set-field-definition') {
        valueRect = new fabric.Rect({
          top: headerTop + valueBox.top * canvasHeight,
          left: headerRight + valueBox.left * canvasWidth,
          width: valueBox.width * canvasWidth,
          height: valueBox.height * canvasHeight,
          angle: 0,
          fill: 'rgba(46, 213, 115, 0.4)',
          stroke: 'black',
          name: valueRectName,
          lockRotation: true,
          hasControls: false,
        });
        canvas.add(valueRect);
        newObjects.push({ object: valueRect, page: clickedPageRef.current });
        // }

        let identifierRect: fabric.Rect = null;
        if (action === 'update-field-header' || action === 'set-field-definition') {
          if (identifierBox) {
            identifierRect = new fabric.Rect({
              top: identifierBox.top * canvasHeight,
              left: identifierBox.left * canvasWidth,
              width: identifierBox.width * canvasWidth,
              height: identifierBox.height * canvasHeight,
              angle: 0,
              fill: 'rgba(251, 195, 86, 0.4)',
              stroke: 'black',
              name: nameIdentifierRect,
              lockRotation: true,
              hasControls: false,
            });

            canvas.add(identifierRect);
            newObjects.push({ object: identifierRect, page: clickedPageRef.current });
          }
        }

        setObjects((curr) => [...curr, ...newObjects]);
        setAnalyzedArea(null);
      }
    }
  }, [state?.analyzedArea]);
  // }, [state?.analyzedArea, canvasRef, folderFieldId, toggleObjectVisibility]);

  // Scroll to the current page
  useEffect(() => {
    canvasRefs.current[currentPage - 1]?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [currentPage]);

  useEffect(() => {
    if (canDraw && action === 'set-identifier') {
      canvasRefs.current[0]?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [action, canDraw]);

  useEffect(() => {
    const el = document.querySelector('#ocr-setup-toolbar');
    if (el) {
      setInitialCanvasPositionX(el.clientWidth / 4);
    }
  }, []);

  const addCanvasRef = (element: HTMLCanvasElement | null) => {
    if (element && !canvasRefs.current.includes(element)) {
      canvasRefs.current.push(element);
    }
  };

  if (loadingPDF) {
    return (
      <Stack height="100%" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress data-testid="canvas-loader" />
      </Stack>
    );
  }

  return (
    <>
      <Box
        data-testid="ocr-document-container"
        ref={containerRef}
        sx={{
          height: '100%',
          width: '100%',
          overflow: canDraw && action === 'set-identifier' ? 'hidden' : 'scroll',
          backgroundColor: 'common.lightGrey',
          '& .canvas-container': {
            margin: '0px auto',
          },
          '& .drawing': {
            cursor: 'crosshair !important',
          },
          '& .panning': {
            cursor: 'grab !important',
          },
        }}
      >
        <TransformWrapper
          initialScale={0.5}
          minScale={0.2}
          initialPositionX={initialCanvasPositionX}
          panning={{
            allowLeftClickPan: panning,
          }}
        >
          <TransformComponent
            wrapperStyle={{ height: '100%', width: '100%' }}
            contentStyle={{
              height: 'fit-content',
              width: 'fit-content',
            }}
          >
            <canvas
              id="canvasMain"
              ref={canvasRef}
              style={{
                display: 'block',
              }}
            />
          </TransformComponent>
        </TransformWrapper>
      </Box>
    </>
  );
};

export default OCRDocumentView;
