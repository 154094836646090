import { useEffect, useState } from 'react';
import { Step } from '../containers/workflow/types';

type WorkflowStatus = {
  icon: string;
  label: string;
  color: string;
};

type UserStatus = {
  id: string;
  status: string;
};

type UsersStatus = UserStatus[];

const useWorkflowStatus = () => {
  const [status, setStatus] = useState<WorkflowStatus | UsersStatus[]>();

  const getWorkflowStatus = (step: Step, currentProcessStep: Step) => {
    if (!step) return;
    let status: null | string = null;

    if (currentProcessStep && step.id === currentProcessStep.id) {
      status = 'InProgress';
    } else if (step.completed && step.approvalStatus === 'Rejected') {
      status = 'Rejected';
    } else if (step.approvalStatus === 'Unapproved' && !currentProcessStep) {
      status = 'Omitted';
    } else {
      step.completed ? (status = 'Approved') : (status = 'Waiting');
    }

    const workflowStatus = getStatus(status);
    setStatus(workflowStatus);
  };

  const getUsersStatus = (step) => {
    const usersStatus = step.usersWhoHavePerformedApproval.map((obj) => ({
      id: obj.userId,
      status: getStatus(obj.actionPerformed),
    }));

    setStatus(usersStatus);
  };

  const getStatus = (status) => {
    switch (status) {
      case 'Approved':
        return {
          icon: 'fal fa-check-circle',
          label: 'COMPLETED',
          color: 'common.positive',
        };
      case 'InProgress':
        return {
          icon: 'far fa-tasks',
          label: 'IN PROGRESS',
          color: 'common.orange',
        };
      case 'Waiting':
        return {
          icon: 'far fa-hourglass-half',
          label: 'WAITING',
          color: '',
        };
      case 'Rejected':
        return {
          icon: 'far fa-times-circle',
          label: 'REJECTED',
          color: 'common.negative',
        };
      case 'Omitted':
        return {
          icon: 'far fa-minus-circle',
          label: 'OMITTED',
          color: 'common.grey',
        };
      case 'Paused':
        return {
          icon: 'fas fa-paused',
          label: 'PAUSED',
          color: 'common.grey',
        };
      case 'Locked':
        return {
          icon: 'fas fa-lock',
          label: 'LOCKED',
          color: 'common.grey',
        };
      default:
        break;
    }
  };

  return { status, getWorkflowStatus, getUsersStatus };
};

export default useWorkflowStatus;
