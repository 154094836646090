import { Alert, AlertTitle, Box, Button, Modal, Typography } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';

interface AlertModalProps {
  open: boolean;
  handleCloseAlertModal: () => void;
  handleConfirm: () => void;
  title: string;
  message: string;
}

const AlertModal = ({
  open,
  handleCloseAlertModal,
  handleConfirm,
  title = 'Are you sure?',
  message = '',
}: AlertModalProps) => {
  return (
    <Modal open={open} onClose={handleCloseAlertModal}>
      <Alert
        icon={false}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#26292E' : theme.palette.common.white,
          boxShadow: 24,
          p: 4,
          paddingBottom: 2,
          justifyContent: 'center',
          '& .MuiAlert-message': {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            paddingBottom: 0,
          },
        }}
      >
        <Box>
          <AlertTitle
            sx={{
              marginBottom: 2,
              fontWeight: 500,
              fontSize: 20,
              color: 'common.negative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <FontAwesomeIcon icon="far fa-exclamation-circle" />
            {title}
          </AlertTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="button" sx={{ fontSize: 11 }}>
              {message}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', margin: 'auto', gap: 1 }}>
          <Button
            color="inherit"
            size="small"
            sx={{
              margin: 0,
              backgroundColor: 'common.greyblueDark',
              color: 'common.white',
              '&:hover': {
                backgroundColor: 'common.greyblueDarker',
              },
            }}
            onClick={handleCloseAlertModal}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            color="inherit"
            size="small"
            sx={{
              margin: 0,
              backgroundColor: 'common.negativeDark',
              color: 'common.white',
              '&:hover': {
                backgroundColor: 'common.negative',
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              handleConfirm();
            }}
            variant="outlined"
          >
            OK
          </Button>
        </Box>
      </Alert>
    </Modal>
  );
};

export default AlertModal;
