import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import ErrorHandler from '../../../utils/errorHandler';
import FolderSelector from '../../folder/FolderSelector';
import { ToolbarLeftProps } from '../types';
import Steps from './Steps';
import Templates from './Templates';

const styles = {
  gridItem: {
    display: 'flex',
    flexBasis: { xs: '50%', md: '100%', lg: '100%' },
    height: { xs: '50%', md: '100%', lg: '100%' },
  },
  gridContainer: {
    padding: '0',
    height: '100%',
    minHeight: '360px',
    flexWrap: 'nowrap',
    marginTop: '0px',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
};

const ToolbarLeft = ({
  handleAddStep,
  steps,
  workflows,
  setWorkflows,
  setSelectedWorkflow,
  selectedWorkflow,
  deleteWorkflow,
  folder,
  setFolder,
  applyTemplateToFolder,
  context,
}: ToolbarLeftProps) => {
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const widthMatches = useMediaQuery('(min-width:768px)');

  const handleChangeFolder = (folder: any) => setFolder(folder);
  if (context !== 'screen') return null;
  return (
    <Grid item xs={widthMatches ? 2 : 12} sx={styles.gridItem}>
      <Grid
        container
        direction="column"
        sx={{
          ...styles.gridContainer,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.black.ultraLightTransp
              : theme.palette.common.white,
        }}
      >
        <Grid
          item
          sx={{
            backgroundColor: 'common.greyblueDarker',
          }}
        >
          <ErrorHandler>
            <FolderSelector
              title="Select a folder"
              handleChangeFolder={handleChangeFolder}
              selectedFolder={folder}
              userSettings={userSettings}
              setUserSettings={setUserSettings}
            />
          </ErrorHandler>

          {/* <Box
            sx={{
              backgroundColor: 'common.greyblueDark',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              boxShadow: { sm: '0px 1px 0px 0px #ffffff96 inset', md: 'none' },
              flex: 1.2,
              overflow: 'hidden',
              minHeight: '31px',
            }}
          >
            <Box sx={{ display: 'flex', flex: 8, justifyContent: 'center' }}>
              <Typography variant="caption" color="WHITE" sx={{ padding: '5px 15px' }}>
                PANEL
              </Typography>
            </Box>
          </Box> */}
        </Grid>
        <Steps steps={steps} handleAddStep={handleAddStep} />
        <Templates
          workflows={workflows}
          setSelectedWorkflow={setSelectedWorkflow}
          selectedWorkflow={selectedWorkflow}
          deleteWorkflow={deleteWorkflow}
          applyTemplateToFolder={applyTemplateToFolder}
        />
      </Grid>
    </Grid>
  );
};

export default ToolbarLeft;
