import { Stack, Autocomplete } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import OCRSetupActions, { OCRSetupActionsTypes } from './OCRSetupActions';
import { StyledTextField } from './StyledComponents';
import KeyValuePairAutocomplete from './KeyValuePairAutocomplete';

interface Props {
  inputId?: string;
  identifierId?: any;
  field: any;
  type: 'template-name' | 'identifier' | 'value';
  inputId?: string;
  inputLabel?: string;
  inputVariant?: 'outlined' | 'standard' | 'filled';
  inputSize?: 'small' | 'medium';
  action: string;
}

export const InputWithActions = (props: Props & OCRSetupActionsTypes) => {
  const {
    el,
    identifierId,
    field,
    type,
    inputId,
    inputLabel,
    inputVariant,
    inputSize,
    action,
    canDrawArea,
    canClear,
    canEdit,
    canHideArea,
    debouncedChange,
    onChange,
    value,
    canSave,
    onSave,
    message,
    handleRemoveFields,
    renderInput,
    folder,
    setNameInputValue,
    setFolder,
  } = props;

  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    if (value && renderInput !== 'autocomplete') setInputValue(value);
  }, [value]);

  return (
    <Stack direction="row" spacing={1} data-testid={`${inputId}-container`}>
      <Stack direction="row" spacing={1} flex={12}>
        {renderInput === 'autocomplete' ? (
          <KeyValuePairAutocomplete
            id={inputId}
            value={value}
            folder={folder}
            setFolder={setFolder}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setNameInputValue={setNameInputValue}
          />
        ) : (
          <StyledTextField
            id={inputId}
            variant={inputVariant || 'outlined'}
            size={inputSize || 'small'}
            value={inputValue}
            fullWidth
            onChange={(e) => {
              setInputValue(e.target.value);
              if (debouncedChange) {
                debouncedChange(e.target.value, identifierId);
              }
            }}
            inputProps={{
              onFocus: (e) => {
                e.stopPropagation();
              },
              onClick: (e) => {
                e.stopPropagation();
              },
            }}
          />
        )}
      </Stack>
      <OCRSetupActions
        el={el}
        hasValue={Boolean(inputValue)}
        field={field}
        identifierId={identifierId}
        action={action}
        inputType={type}
        canDrawArea={canDrawArea}
        canClear={canClear}
        canEdit={canEdit}
        canHideArea={canHideArea}
        canSave={canSave}
        onSave={onSave}
        handleRemoveFields={handleRemoveFields}
      />
    </Stack>
  );
};
