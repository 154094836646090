import { gql } from 'apollo-boost';

export const ADD_NOTE_TO_RECORD = gql`
  mutation addDocumentNoteToRecord($recordId: ID!, $documentNote: NoteInput!) {
    addDocumentNoteToRecord(recordId: $recordId, documentNote: $documentNote) {
      record {
        id
        documentNotes {
          id
          content
          priority
          createdAt
          updatedAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedBy {
            id
            firstName
            lastName
          }
        }
      }
      error
    }
  }
`;

export const DELETE_NOTE_FROM_RECORD = gql`
  mutation deleteDocumentNoteFromRecord($recordId: ID!, $documentNoteId: ID!) {
    deleteDocumentNoteFromRecord(recordId: $recordId, documentNoteId: $documentNoteId) {
      record {
        id
        documentNotes {
          id
          content
          priority
          createdAt
          updatedAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedBy {
            id
            firstName
            lastName
          }
        }
      }
      error
    }
  }
`;
