import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect } from 'react';
import { Filter } from '../ReportingSideBar';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';

const INPUTFORMAT = 'dd/MM/yyyy';
const DateFilter = () => {
  const { setValue: setFormValue, watch } = useFormContext();
  const [value, setValue] = React.useState<any>(null);

  useEffect(() => {
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex((filter) => filter.id === 'date');
    index !== -1 &&
      setFormValue(`selectedTemplate.variables.filters[${index}].value`, value);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={(newValue) => newValue && setValue(newValue)}
        inputFormat={INPUTFORMAT}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: false }}
            inputProps={{
              ...params.inputProps,
              placeholder: 'Select a date',
            }}
            fullWidth
            sx={{
              '& fieldset': { border: 'none' },
              '& .MuiInputBase-input': {
                textAlign: 'end',
                fontSize: 14,
                fontWeight: 500,
                color: 'common.greyblueDarker',
              },
              '& .MuiInputLabel-root': {
                color: 'common.greyblueDarker',
              },
              '& .MuiIconButton-root': {
                color: 'common.greyblueDarker',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateFilter;
