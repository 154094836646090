import { Route, Switch } from 'react-router-dom';
import { Box } from '@mui/material';
import OrgMain from '../containers/organisations/OrgMain';
import OrgProfile from '../containers/organisations/orgProfile/OrgProfile';

function Organisations() {
  return (
    <Box sx={{ height: '100%' }}>
      <Switch>
        <Route exact path="/dash/orgs" component={OrgMain} />
        <Route exact path="/dash/orgs/:id" component={OrgProfile} />
      </Switch>
    </Box>
  );
}

export default Organisations;
