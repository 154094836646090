import { Box, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { AddStepProps } from '../../types';

const styles = {
  cardContainer: {
    height: '100%',
    minWidth: 350,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  menu: {
    '& .MuiPaper-root': {
      backgroundColor: 'common.greyblueDarker',
      overflow: 'visible',
    },
    '& .MuiMenuItem-root': {
      color: 'common.white',
      '& .MuiIcon-root': {
        fontSize: 18,
        marginRight: 1.5,
      },
    },
    '& .MuiMenuItem-root:hover': {
      color: 'common.orange',
    },
  },
  menuItem: { textTransform: 'uppercase', fontWeight: 500 },
};

const AddStepCard = ({ handleAddStep }: AddStepProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <Box
      sx={{
        ...styles.cardContainer,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? 'transparent' : theme.palette.common.white,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
          color: (theme) =>
            theme.palette.mode === 'dark' ? theme.palette.common.white : '#8686865a',
          '&:hover': {
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.common.blueShadow['300']
                : 'common.greyblueDarker',
          },
        }}
        // onClick={handleClick}
        /*IMPORTANT! for now, users just can add approval step, so when they make click for add an step we add an approval step automatically */
        onClick={() => handleAddStep('approval')} // remove when others steps can be added
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <FontAwesomeIcon icon="fa-solid fa-plus" size={45} />
      </Box>
      {/* <Menu
        id="basic-menu"
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        sx={styles.menu}
        variant="menu"
      >
        <MenuItem
          sx={styles.menuItem}
          onClick={() => {
            handleAddStep('approval');
            handleClose();
          }}
        >
          Approval
        </MenuItem>
        <MenuItem
          sx={styles.menuItem}
          onClick={() => {
            handleAddStep('signature');
            handleClose();
          }}
        >
          Signature
        </MenuItem>
        <MenuItem
          sx={styles.menuItem}
          onClick={() => {
            handleAddStep('picture');
            handleClose();
          }}
        >
          Picture
        </MenuItem>
      </Menu> */}
    </Box>
  );
};

export default AddStepCard;
