import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gqlRecords from "../utils/gql/gqlRecords";
// Import the main component
import { Viewer, Worker, LoadError } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import renderToolbar from "./pdfViewer/pdfViewerToolbar";
import pagesPlugin from "./pdfViewer/pagesPlugin";
/* const INITIAL_PDF_STATE = {
  numPages: null,
  pageNumber: 1,
}; */

const SharedPdfViewer = ({
  attachment,
  height = "calc(100vh - 140px)",
  minWidth = "550px",
}) => {
  const selectPages = pagesPlugin();
  const customToolbar = defaultLayoutPlugin({
    renderToolbar,
  });

  const { data } = useQuery(gqlRecords.GET_URL, {
    variables: {
      folderId: attachment && attachment.folderId,
      key: attachment && attachment.name.split("/")[2],
      org: attachment && attachment.org,
    },
  });
  const renderError = (error: LoadError) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        message = "The document is invalid or corrupted";
        break;
      case "MissingPDFException":
        message = "The document is missing";
        break;
      case "UnexpectedResponseException":
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        break;
    }

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#e53e3e",
            borderRadius: "0.25rem",
            color: "#fff",
            padding: "0.5rem",
          }}
        >
          {message}
        </div>
      </div>
    );
  };
  if (attachment && data) {
    window.log(data);
    return (
      <>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <div style={{ height, minWidth }}>
            <Viewer
              fileUrl={data.GenerateGetURL}
              renderError={renderError}
              RenderPageProps={{ width: "100%" }}
              plugins={[customToolbar, selectPages]}
              test={"test"}
            />
          </div>
        </Worker>
      </>
    );
  } else {
    return null;
  }
};

export default SharedPdfViewer;
