import React from 'react';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

export type StatusProps = {
  status: boolean;
  snackbarMessage: string;
  severity: AlertColor | undefined;
};

export type HandleClose = (event: Event | React.SyntheticEvent, reason?: string) => void;
interface CustomSnackbarProps extends SnackbarProps {
  status: StatusProps;
  handleClose: HandleClose;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function GeneralSnackbar(props: CustomSnackbarProps) {
  const {
    status: { status, severity, snackbarMessage },
    handleClose,
  } = props;

  return (
    <Snackbar
			data-testid="snackbar"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={status}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert data-testid="alert-message"
				onClose={handleClose} severity={severity ? severity : 'success'}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
