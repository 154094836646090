import { gql } from 'apollo-boost';

enum ProcessPathEnum {
  Any,
  Incomplete,
  Unassigned,
}

interface QueryFileInput {
  id: [string];
  folderId: string;
  filters: [any];
  preview: boolean;
  limit: number;
  currentPage: number;
  sortByField: string;
  processPath: ProcessPathEnum;
}

const gqlRecords = {
  GET_RECORDS: gql`
    query {
      Records {
        records {
          # name
          totalCount
          createdBy {
            firstName
            lastName
          }
          attachments {
            name
          }
          assignTo {
            firstName
            lastName
          }
          linkedInformation
          processPath {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                allUsersMustApprove
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
              }
            }
            processStatus
          }
          processPath_Archive
          currentProcessStep {
            id
            stepOrder
            completed
            required
            ... on ApprovalStep {
              toBeApprovedBy
              approvalStatus
              allUsersMustApprove
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
              assignedBy {
                userId
                timeStamp
              }
              approvalStatusSetBy {
                userId
                timeStamp
              }
              approvalNotes {
                id
                createdBy {
                  id
                }
              }
            }
          }
        }
        error
      }
    }
  `,
  EXPORT_RECORDS: (variables: any) => {
    return JSON.stringify({
      operationName: 'Records',
      variables: variables,
      query: `query Records(
          $folderId: ID!
          $recordId: [ID]
          $filters: [FilterQuery]
          $limit: Int
          $currentPage: Int
          $sortByField: String
        ) {
          Records(
            data: {
              folderId: $folderId
              id: $recordId
              filters: $filters
              limit: $limit
              currentPage: $currentPage
              sortByField: $sortByField
            }
          ) {
            records {
              id
              attachments {
                name
                type
                format
                getUrl
              }
              folderId {
                id
                primaryFolderFieldId
                name
              }
              fields {
                field {
                  id
                  name
                  type
                }
                value
              }
              createdBy {
                firstName
                lastName
              }
              assignTo {
                firstName
                lastName
              }
              createdAt
            }
            error
            totalCount
          }
        }`,
    });
  },
  EXPORT_RECORDS_BYORG: (variables: QueryFileInput) => {
    return JSON.stringify({
      operationName: 'RecordsByOrg',
      variables: variables,
      query: `query RecordsByOrg(
          $folderId: ID!
          $recordId: [ID]
          $filters: [FilterQuery]
          $org: ID!
          $limit: Int
          $currentPage: Int
          $sortByField: String
        ) {
          RecordsByOrg(
            data: {
              folderId: $folderId
              id: $recordId
              filters: $filters
              limit: $limit
              currentPage: $currentPage
              sortByField: $sortByField
            }
            organisationId: $org
          ) {
            records {
              id
              attachments {
                name
                type
                format
                getUrl
              }
              folderId {
                id
                primaryFolderFieldId
                name
              }
              fields {
                field {
                  id
                  name
                  type
                }
                value
              }
              createdBy {
                firstName
                lastName
              }
              assignTo {
                firstName
                lastName
              }
              createdAt
            }
            error
            totalCount
          }
        }`,
    });
  },
  GENERATE_REPORT_PDF: (variables) => {
    return JSON.stringify({
      operationName: 'Records_Report',
      variables,
      query: `query Records_Report($data: ReportRequestInput) {
        Records_Report(data: $data) {
          totalCount
          linked_Informations {
            id
            primaryLinkValue {
              name
              value
              fieldId
              fieldType
            }
            linkedValues {
              name
              value
              fieldId
              fieldType
            }
            synced
            sortId
          }
          records {
            id
            createdAt
            createdBy {
              firstName
              lastName
            }
            attachments {
              name
            }
            assignTo {
              firstName
              lastName
            }
            fields {
              field {
                id
                name
                type
              }
              value
            }
            processPath {
              id
              name
              processSteps {
                id
                stepOrder
                completed
                required
                ... on ApprovalStep {
                  toBeApprovedBy
                  approvalStatus
                  allUsersMustApprove
                  usersWhoHavePerformedApproval {
                    userId
                    timeStamp
                    actionPerformed
                  }
                  assignedBy {
                    userId
                    timeStamp
                  }
                }
              }
              processStatus
            }
            currentProcessStep {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                allUsersMustApprove
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
                assignedBy {
                  userId
                  timeStamp
                }
                approvalStatusSetBy {
                  userId
                  timeStamp
                }
                approvalNotes {
                  id
                  createdBy {
                    id
                  }
                }
              }
            }
          }
          error
        }
      }`,
    });
  },
  FILTER_RECORDS_old: gql`
    query Records(
      $folderId: ID!
      $recordId: [ID]
      $filters: [FilterQuery]
      $limit: Int
      $currentPage: Int
      $sortByField: String
    ) {
      Records(
        data: {
          folderId: $folderId
          id: $recordId
          filters: $filters
          limit: $limit
          currentPage: $currentPage
          sortByField: $sortByField
        }
      ) {
        records {
          id
          linkedInformation {
            id
            synced
            sortId
          }
          attachments {
            name
            type
            format
            getUrl
          }
          folderId {
            id
            primaryFolderFieldId
            name
          }
          fields {
            field {
              id
              name
              type
            }
            value
          }
          createdBy {
            firstName
            lastName
          }
          assignTo {
            firstName
            lastName
          }
          documentNotes {
            id
            content
            priority
            createdBy {
              id
              firstName
              lastName
            }
            updatedAt
            createdAt
            updatedBy {
              id
              firstName
              lastName
            }
          }
          createdAt
        }
        error
        totalCount
      }
    }
  `,
  //with workflow status
  FILTER_RECORDS: gql`
    query Records(
      $folderId: ID!
      $recordId: [ID]
      $filters: [FilterQuery]
      $limit: Int
      $currentPage: Int
      $sortByField: String
      $processPath: ProcessPathEnum
    ) {
      Records(
        data: {
          folderId: $folderId
          id: $recordId
          filters: $filters
          limit: $limit
          currentPage: $currentPage
          sortByField: $sortByField
          processPath: $processPath
        }
      ) {
        records {
          id
          linkedInformation {
            id
            synced
            sortId
          }
          attachments {
            name
            type
            format
            getUrl
          }
          folderId {
            id
            primaryFolderFieldId
            name
          }
          fields {
            field {
              id
              name
              type
            }
            value
          }
          createdBy {
            firstName
            lastName
          }
          assignTo {
            firstName
            lastName
          }
          documentNotes {
            id
            content
            priority
            createdBy {
              id
              firstName
              lastName
            }
            updatedAt
            createdAt
            updatedBy {
              id
              firstName
              lastName
            }
          }
          createdAt
          processPath {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                allUsersMustApprove
                approvalNotes {
                  content
                  id
                  createdBy {
                    id
                    firstName
                    lastName
                  }
                }
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
                approvalStatusSetBy {
                  userId
                  timeStamp
                }
                assignedBy {
                  userId
                  timeStamp
                }
              }
            }
            processStatus
          }
          processPath_Archive {
            ... on ProcessPath {
              id
              name
              processSteps {
                id
                stepOrder
                completed
                required
                ... on ApprovalStep {
                  toBeApprovedBy
                  approvalStatus
                  allUsersMustApprove
                  usersWhoHavePerformedApproval {
                    userId
                    timeStamp
                    actionPerformed
                  }
                  assignedBy {
                    userId
                    timeStamp
                  }
                  approvalStatusSetBy {
                    userId
                    timeStamp
                  }
                }
              }
              processStatus
            }
          }
          currentProcessStep {
            id
            stepOrder
            completed
            required
            ... on ApprovalStep {
              toBeApprovedBy
              approvalStatus
              allUsersMustApprove
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
            }
          }
        }
        error
        totalCount
      }
    }
  `,
  FILTER_RECORDS_BY_ORG: gql`
    query RecordsByOrg(
      $folderId: ID!
      $recordId: [ID]
      $filters: [FilterQuery]
      $org: ID!
      $limit: Int
      $currentPage: Int
      $sortByField: String
    ) {
      RecordsByOrg(
        data: {
          folderId: $folderId
          id: $recordId
          filters: $filters
          limit: $limit
          currentPage: $currentPage
          sortByField: $sortByField
        }
        organisationId: $org
      ) {
        records {
          id
          attachments {
            name
            type
            format
            getUrl
          }
          folderId {
            id
            primaryFolderFieldId
            name
          }
          fields {
            field {
              id
              name
              type
            }
            value
          }
          createdBy {
            firstName
            lastName
          }
          documentNotes {
            id
            content
            priority
            createdBy {
              firstName
              lastName
            }
            updatedAt
            createdAt
            updatedBy {
              firstName
              lastName
            }
          }
          assignTo {
            firstName
            lastName
          }
          createdAt
          processPath {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                allUsersMustApprove
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
                approvalNotes {
                  content
                  priority
                  createdBy {
                    id
                  }
                }
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
                approvalStatusSetBy {
                  userId
                  timeStamp
                }
                assignedBy {
                  userId
                  timeStamp
                }
              }
            }
            processStatus
          }
          processPath_Archive {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                allUsersMustApprove
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
                assignedBy {
                  userId
                  timeStamp
                }
                approvalStatusSetBy {
                  userId
                  timeStamp
                }
              }
            }
            processStatus
          }
          currentProcessStep {
            id
            stepOrder
            completed
            required
            ... on ApprovalStep {
              toBeApprovedBy
              approvalStatus
              allUsersMustApprove
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
            }
          }
        }
        error
        totalCount
      }
    }
  `,
  GET_RECORD_BY_ID: gql`
    query Record($id: ID!) {
      Record(id: $id) {
        record {
          id
          attachments {
            name
            type
            format
            getUrl
          }
          fields {
            field {
              id
              name
              type
            }
            value
          }
          folderId {
            id
          }
          createdBy {
            firstName
            lastName
          }
          assignTo {
            firstName
            lastName
          }
          processPath {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                allUsersMustApprove
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
                approvalNotes {
                  id
                  content
                  priority
                  createdBy {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
            processStatus
          }
          currentProcessStep {
            id
            stepOrder
            completed
            required
            ... on ApprovalStep {
              toBeApprovedBy
              approvalStatus
              allUsersMustApprove
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
              assignedBy {
                userId
                timeStamp
              }
              approvalStatusSetBy {
                userId
                timeStamp
              }
              approvalNotes {
                id
                createdBy {
                  id
                }
              }
            }
          }
          processPath_Archive {
            ... on ProcessPath {
              id
              name
              processSteps {
                id
                stepOrder
                completed
                required
                ... on ApprovalStep {
                  toBeApprovedBy
                  approvalStatus
                  allUsersMustApprove
                  usersWhoHavePerformedApproval {
                    userId
                    timeStamp
                  }
                  assignedBy {
                    userId
                    timeStamp
                  }
                  approvalStatusSetBy {
                    userId
                    timeStamp
                  }
                }
              }
              processStatus
            }
          }
        }
        error
      }
    }
  `,
  GET_RECORD_BY_ORG: gql`
    query RecordByOrg($id: ID!, $organisationId: ID!) {
      RecordByOrg(id: $id, organisationId: $organisationId) {
        record {
          id
          attachments {
            name
            type
            format
            getUrl
          }
          fields {
            field {
              id
              name
              type
            }
            value
          }
          createdBy {
            firstName
            lastName
          }
          assignTo {
            firstName
            lastName
          }
          folderId {
            id
          }
          processPath {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                allUsersMustApprove
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
              }
            }
            processStatus
          }
          currentProcessStep {
            id
            stepOrder
            completed
            required
            ... on ApprovalStep {
              toBeApprovedBy
              approvalStatus
              allUsersMustApprove
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
            }
          }
        }
        error
      }
    }
  `,
  GET_RECORDS_BY_FOLDER_OLD: (id: string) => {
    return gql`
      query {
        Records(filter: {folderId: ${id}}) {
          records {
            name
          }
          error
        }
      }
    `;
  },
  CREATE_RECORD: gql`
    mutation createRecord(
      $data: RecordInput!
      $fields: [FieldsInput]
      $attachments: BaseAttachmentInputs!
    ) {
      createRecord(data: $data, fields: $fields, attachments: $attachments) {
        record {
          id
          attachments {
            name
            type
            format
            getUrl
          }
          toBeIndexedBy
          createdAt
          fields {
            field {
              id
              name
            }
            value
          }
        }
        attachmentPutInfos {
          name
          putUrl
        }
      }
    }
  `,
  CREATE_RECORD_FOR_ORG: gql`
    mutation addAttachmentToFolderInOrg(
      # $name: String!
      $folderId: ID!
      $organisationId: ID!
      $attachments: BaseAttachmentInputs!
    ) {
      addAttachmentToFolderInOrg(
        # assignTo: $assignTo
        organisationId: $organisationId
        folderId: $folderId
        attachments: $attachments
      ) {
        record {
          id
          toBeIndexedBy
          attachments {
            name
            type
            format
            getUrl
          }
          createdAt
          fields {
            field {
              id
              name
            }
            value
          }
        }
        attachmentPutInfos {
          name
          putUrl
        }
      }
    }
  `,
  GET_URL: gql`
    query GenerateGetURL($folderId: ID, $key: String, $org: ID) {
      GenerateGetURL(folderId: $folderId, key: $key, org: $org)
    }
  `,
  GET_URLs: gql`
    query GenerateGetURLs($keys: [String]) {
      GenerateGetURLs(keys: $keys) {
        key
        getUrl
        error
      }
    }
  `,
  GET_RECORDS_REPORT: gql`
    query Records_Report($data: ReportRequestInput) {
      Records_Report(data: $data) {
        totalCount
        linked_Informations {
          id
          primaryLinkValue {
            name
            value
            fieldId
            fieldType
          }
          linkedValues {
            name
            value
            fieldId
            fieldType
          }
          synced
          sortId
        }
        records {
          id
          createdAt
          createdBy {
            firstName
            lastName
          }
          attachments {
            name
          }
          assignTo {
            firstName
            lastName
          }
          fields {
            field {
              id
              name
              type
            }
            value
          }
          processPath {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                allUsersMustApprove
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
                assignedBy {
                  userId
                  timeStamp
                }
              }
            }
            processStatus
          }
          currentProcessStep {
            id
            stepOrder
            completed
            required
            ... on ApprovalStep {
              toBeApprovedBy
              approvalStatus
              allUsersMustApprove
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
              assignedBy {
                userId
                timeStamp
              }
              approvalStatusSetBy {
                userId
                timeStamp
              }
              approvalNotes {
                id
                createdBy {
                  id
                }
              }
            }
          }
        }
        error
      }
    }
  `,
  EDIT_RECORD: gql`
    mutation updateRecord(
      $recordId: ID!
      $updatedRecord: UpdateRecordInput
      $fields: [FieldsInput]
      $overwriteFields: Boolean = false
    ) {
      updateRecord(
        recordId: $recordId
        updatedRecord: $updatedRecord
        fields: $fields
        overwriteFields: $overwriteFields
      ) {
        record {
          id
        }
      }
    }
  `,

  UPDATE_RECORD_V2: gql`
    mutation updateRecord_V2($updateData: UpdateRecordInput_V2!) {
      updateRecord_V2(updateData: $updateData) {
        record {
          id
          currentProcessStep {
            completed
            required
            ... on ApprovalStep {
              approvalStatus
              allUsersMustApprove
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
            }
          }
          processPath {
            id
          }
        }
        error
      }
    }
  `,

  UPDATE_RECORD_ASYNC: gql`
    mutation updateRecordsAsync(
      $recordsToUpdate: UpdateRecordsAsyncInput
      $taskId: String!
    ) {
      updateRecordsAsync(recordsToUpdate: $recordsToUpdate, taskId: $taskId) {
        recordIds
        error
      }
    }
  `,

  DELETE_RECORD: gql`
    mutation deleteRecordsAsync($recordIds: [ID]!, $taskId: String) {
      deleteRecordsAsync(recordIds: $recordIds, taskId: $taskId) {
        records {
          recordId
        }
      }
    }
  `,
  SCAN_BARCODE_ASYNC: gql`
    mutation scanBarcodeOnRecordsAsync(
      $recordIds: [ID]
      $taskId: String
      $folderId: ID
      $preview: Boolean
    ) {
      scanBarcodeOnRecordsAsync(
        recordIds: $recordIds
        taskId: $taskId
        folderId: $folderId
        preview: $preview
      ) {
        recordIds
        error
      }
    }
  `,
  SCAN_BARCODE: gql`
    mutation scanBarcodeOnRecords($recordIds: [ID], $preview: Boolean) {
      scanBarcodeOnRecords(recordIds: $recordIds, preview: $preview) {
        recordsWithLink {
          record {
            id
            fields {
              value
              field {
                id
                name
              }
            }
          }
          linkedInfoFields {
            field
          }
        }
        error
      }
    }
  `,
  UNINDEX_RECORDS: gql`
    mutation unindexRecords($recordIds: [ID!]!, $folderId: ID!, $taskId: String) {
      unindexRecords(recordIds: $recordIds, folderId: $folderId, taskId: $taskId) {
        unindexedCount
        error
      }
    }
  `,
};

export default gqlRecords;
