import { Alert, AlertTitle, Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import FontAwesomeIcon from '../../../components/FAIcon';

interface WarningModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: (e: any) => void;
}

const styles = {
  alert: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
    justifyContent: 'center',
    '& .MuiAlert-message': {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      paddingBottom: 0,
    },
  },
  alertTitle: {
    marginBottom: 2,
    fontWeight: 500,
    fontSize: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
  confirmButton: {
    margin: 0,
    backgroundColor: 'common.negativeDark',
    color: 'common.white',
    '&:hover': {
      backgroundColor: 'common.negative',
    },
  },
  cancelButton: {
    margin: 0,
    backgroundColor: 'common.greyblueDark',
    color: 'common.white',
    '&:hover': {
      backgroundColor: 'common.greyblueDarker',
    },
  },
};

const WarningModal = ({ isOpen, handleClose, handleConfirm }: WarningModalProps) => {
  return (
    <Modal open={isOpen}>
      <Alert
        icon={false}
        sx={{
          ...styles.alert,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.greyblueDarker
              : theme.palette.common.white,
        }}
      >
        <Box>
          <AlertTitle
            sx={{
              ...styles.alertTitle,
              color: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.common.negative
                  : theme.palette.common.negativeDark,
            }}
          >
            <FontAwesomeIcon icon="far fa-exclamation-circle" />
            Warning
          </AlertTitle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              color: 'common.greyblueDarker',
            }}
          >
            <Typography variant="button" sx={{ fontSize: 11 }}>
              You have some changes that will not be saved
            </Typography>
            <Typography variant="button" sx={{ fontSize: 11 }}>
              Are you sure you want to leave without saving?
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', margin: 'auto', gap: 1 }}>
          <Button
            size="small"
            sx={styles.cancelButton}
            onClick={handleClose}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            size="small"
            sx={styles.confirmButton}
            onClick={handleConfirm}
            variant="outlined"
          >
            YES
          </Button>
        </Box>
      </Alert>
    </Modal>
  );
};

export default WarningModal;
