import React from 'react';
import FontAwesomeIcon from '../../components/FAIcon';
import ToolbarBase from '../../components/toolbarScreen/ToolbarBase';
import ToolbarButtonLeft from '../../components/toolbarScreen/ToolbarButtonLeft';
import ToolbarTitle from '../../components/toolbarScreen/ToolbarTitle';
import { useHistory } from 'react-router-dom';

const CustomToolbar = () => {
  let history = useHistory();

  return (
    <ToolbarBase background={true}>
      <ToolbarButtonLeft buttonOnClick={() => history.goBack()}>
        <FontAwesomeIcon icon="fas fa-angle-left" />
      </ToolbarButtonLeft>
      <ToolbarTitle title="Maintenance" />
    </ToolbarBase>
  );
};

export default CustomToolbar;
