import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Context as StatusContext } from '../../context/statusContext';
import ShareTo from '../ShareTo';
import SharePopOver from './sharePopOver';
import { RotateDirection } from '@react-pdf-viewer/core';
import {
  Button,
  IconButton,
  Stack,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import FontAwesomeIcon from '../FAIcon';

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '15px 10px',
    fontSize: '16px',
    fontWeight: 300,
    backgroundColor: theme.palette.common.black,
  },
}));

const renderToolbar = (
  Toolbar: (props: ToolbarProps) => ReactElement,
  trackPosition: React.MutableRefObject<boolean>
) => {
  const togglePinned = () => {
    trackPosition.current = !trackPosition.current;
  };
  return (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
          Rotate,
          Print,
        } = slots;
        const { state } = useContext(StatusContext);
        /*   const getFilePluginInstance = getFilePlugin();
        const { Download } = getFilePluginInstance;
        const [isEmailOpen, setIsEmailOpen] = useState(false);
        const [isSaveOpen, setIsSaveOpen] = useState(false); */
        const [shareIsOpen, setShareIsOpen] = useState(false);

        const handleShareClose = () => {
          setShareIsOpen(false);
        };

        return (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="space-between"
            width="100%"
          >
            <Stack
              direction="row"
              flex={1}
              justifyContent="start"
              alignItems="center"
              gap={1}
            >
              <ShowSearchPopover />
            </Stack>
            <Stack
              direction="row"
              flex={1}
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <ZoomOut />
              <Zoom />
              <ZoomIn />
              <Rotate direction={RotateDirection.Forward} />
              <GoToPreviousPage />
              <Stack
                direction="row"
                flex={1}
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <CurrentPageInput style={{ width: '30px' }} />{' '}
                <Stack
                  direction="row"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  /
                  <NumberOfPages style={{ width: '30px' }} />
                </Stack>
              </Stack>
              <GoToNextPage />
            </Stack>
            <Stack
              direction="row"
              flex={1}
              justifyContent="end"
              alignItems="center"
              gap={1}
            >
              <SharePopOver />
              <EnterFullScreen />
              <Print />

              <DarkTooltip title="Pin viewer position" arrow>
                <span>
                  <IconButton
                    onClick={togglePinned}
                    sx={{
                      borderRadius: '4px',
                      '& .MuiIcon-root': {
                        color: (theme) => {
                          return trackPosition && trackPosition.current
                            ? 'common.orange'
                            : theme.palette.mode === 'light'
                            ? '#464646'
                            : '#c0c0c0';
                        },
                      },
                      '&:hover': {
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light' ? '#d6d6d6' : '#121212',
                      },
                    }}
                  >
                    <FontAwesomeIcon
                      icon="fas fa-map-pin"
                      color="inherit"
                      size="1.2rem"
                    />
                  </IconButton>
                </span>
              </DarkTooltip>

              <ShareTo
                isOpen={shareIsOpen}
                handleClose={handleShareClose}
                selectedRecord={state.selectedRecord}
              ></ShareTo>
            </Stack>
          </Stack>
        );
      }}
    </Toolbar>
  );
};
export default renderToolbar;
