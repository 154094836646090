import React, { useEffect } from 'react';
import { Step } from '../../types';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { Note } from '../../../../components/Notes/notesTypes';
import _ from 'lodash';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { User } from '../../../../components/masterTable/masterTableTypes';
import utils from '../../../../utils/utils';
import useWorkflowStatus from '../../../../hooks/useWorkflowStatus';

const styles = {
  gridContainer: {
    maxHeight: '80px',
    overflowY: 'auto',
    padding: '0 10px',
    gap: '5px 15px',
    justifyContent: { md: 'center', lg: 'flex-start' },
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
  },
};

interface CheckboxGroupProps {
  users: User[];
  handleUpdateStep: (stepOrder: number, key: string, value: any) => void;
  step: Step;
  toggleNotesDrawer: (note: Note | null) => void;
}

const UsersList = ({ users, step, toggleNotesDrawer }: CheckboxGroupProps) => {
  const { status, getUsersStatus } = useWorkflowStatus();

  useEffect(() => {
    getUsersStatus(step);
  }, [step]);

  if (!users.length || !step) return null;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid
        container
        spacing={0.5}
        rowGap={1.2}
        columns={{ xs: 1, sm: 8, md: 6, lg: 8, xl: 12 }}
        sx={styles.gridContainer}
      >
        {[ ...users].map((user) => {
          let tooltipInfo: any = _.find(step.usersWhoHavePerformedApproval, {
            userId: user.id,
          });
          let userHasNotes = step?.approvalNotes?.find(
            (note: Note) => note?.createdBy?.id === user.id && note.content !== ''
          );

          const userStatus = _.isArray(status) && status?.find((el) => user.id === el.id);

          return step.toBeApprovedBy.includes(user.id) ? (
            <Box
              sx={{
                display: 'flex',
                gap: 0.3,
                minWidth: '106px',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 0.3,
                  justifyContent: 'center',
                  // marginLeft: checked ? 1.6 : undefined,
                }}
              >
                <Tooltip
                  title={userStatus && userStatus.status ? userStatus?.status.label : ''}
                  placement="top"
                >
                  <Box
                    sx={{
                      display: 'inline-flex',
                      marginTop: '2px',
                      color: (theme) =>
                        userStatus && userStatus.status
                          ? userStatus?.status?.color
                          : theme.palette.mode === 'dark'
                          ? 'common.white'
                          : 'common.greyblueDark',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        userStatus && userStatus.status
                          ? userStatus?.status?.icon
                          : 'fal fa-check-circle'
                      }
                      size={11}
                    />
                  </Box>
                </Tooltip>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'baseline' }}>
                    <Typography
                      component="span"
                      sx={{
                        color: 'common.white',
                        textTransform: 'uppercase',
                        textAlign: 'initial',
                        fontWeight: 200,
                        fontSize: 10,
                        display: 'flex',
                        gap: 0.5,
                        minWidth: '88px',
                      }}
                    >
                      {user.firstName.length + user.lastName.length >= 18
                        ? `${user.firstName} ${user.lastName.slice(0, 5)}...`
                        : `${user.firstName} ${user.lastName}`}

                      {userHasNotes && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '&:hover': { cursor: 'pointer' },
                          }}
                          onClick={() => toggleNotesDrawer(userHasNotes)}
                        >
                          <FontAwesomeIcon
                            icon="fas fa-sticky-note"
                            color="#ccc"
                            size={10}
                          />
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {tooltipInfo && (
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 8,
                        color: 'common.greyblue',
                        '&:hover': { cursor: 'pointer' },
                      }}
                    >
                      Performed on {`${utils.getDateTime(tooltipInfo.timeStamp) || ''}`}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ) : null;
        })}
      </Grid>
    </Box>
  );
};

export default UsersList;
