import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useState } from 'react';
import Logout from '../../containers/LogOut';
import UserProfile from '../../containers/user/userProfile/UserProfile';
import { Context as RTCContext } from '../../context/RTCContext';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import FontAwesomeIcon from '../FAIcon';
import ModalWrapper from '../modal/ModalWrapper';

const classes = {
  listItemText: {
    '& .MuiTypography-root': {
      color: '#fff',
      fontWeight: 500,
      fontSize: 14,
    },
  },
  arrow: {
    border: '10px solid transparent',
    borderBottomColor: 'common.greyblueDarker',
    width: '0',
    maxWidth: '0',
    position: 'relative',
    left: '80%',
  },
  popover: {
    marginRight: 10,
  },
  paper: {
    backgroundColor: 'transparent',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};

function stringAvatar(name: string) {
  return {
    sx: {
      fontSize: 18,
      width: 34,
      height: 34,
      bgcolor: 'white.main',
      color: 'common.greyblueDark',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 5,
    top: 35,
    padding: '0 4px',
  },
}));

interface UserMenuProps {
  setAnchorUserMenu: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  anchorUserMenu: HTMLAnchorElement | null;
  sendData: boolean;
}
const UserMenu = ({ setAnchorUserMenu, anchorUserMenu, sendData }: UserMenuProps) => {
  const { state } = useContext(RTCContext);
  const { state: statusState, setPaletteMode } = useContext(
    StatusContext
  ) as StatusContextTypes;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userData] = useLocalStorage('userData');
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  let { suser, userLname, userName } = userData;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorUserMenu(null);
  };

  const togglePaletteMode = (mode) => {
    setUserSettings({ ...userSettings, paletteMode: mode });
    setPaletteMode(mode);
  };

  const badgeStatus = state.rtcStatus;
  return (
    <>
      <StyledBadge color={badgeStatus} variant="dot">
        <Button
          data-testid="user-menu-button"
          sx={{ minWidth: 34, paddingRight: 0 }}
          onClick={(e) => {
            if (!anchorUserMenu) {
              handleClick(e);
            } else {
              handleClose();
            }
          }}
        >
          <Avatar {...stringAvatar(userData.userName + ' ' + userData.userLname)} />
        </Button>
      </StyledBadge>

      <Popover
        PaperProps={{ sx: classes.paper }}
        anchorEl={anchorUserMenu}
        open={Boolean(anchorUserMenu)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 44,
          horizontal: 1085,
        }}
        sx={classes.popover}
      >
        <Box sx={classes.arrow}></Box>
        <Box sx={{ backgroundColor: 'common.greyblueDarker', borderRadius: '4px' }}>
          <MenuItem onClick={handleClose} sx={{ padding: '4px' }} disabled={suser}>
            <ListItem onClick={() => setModalIsOpen(true)}>
              <ListItemIcon sx={{ minWidth: '41px' }}>
                <FontAwesomeIcon icon="fa-solid fa-user" size={16} color={'#fff'} />
              </ListItemIcon>
              <ListItemText primary={'User profile'} sx={classes.listItemText} />
            </ListItem>
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ padding: '4px' }}>
            <Logout title="Logout" color="#fff" />
          </MenuItem>
        </Box>
      </Popover>
      <ModalWrapper isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            bgcolor: 'background.paper',
            borderRadius: '5px',
            border: 'none',
            outline: 0,
          }}
        >
          <UserProfile connected={false} isModal={true} />
          <IconButton
            sx={{
              position: 'absolute',
              top: '1%',
              right: '1%',
              color: 'common.white',
              width: '30px',
            }}
            onClick={() => setModalIsOpen(false)}
            id="close-user-menu"
          >
            <FontAwesomeIcon icon="fas fa-times" size={15} />
          </IconButton>
        </Box>
      </ModalWrapper>
    </>
  );
};

export default UserMenu;
