import { gql } from 'apollo-boost';

const gqlIndexes = {
  OCR_RECORDS: gql`
    mutation ocrRecordsAsync(
      $recordIds: [ocrRecordIdInput]
      $folderId: ID
      $taskId: String
      $preview: Boolean
    ) {
      ocrRecordsAsync(
        recordIds: $recordIds
        folderId: $folderId
        taskId: $taskId
        preview: $preview
      ) {
        recordIds
        error
      }
    }
  `,
  GET_UNINDEXED: gql`
    query UnindexedRecords(
      $folderId: ID!
      $limit: Int
      $page: Int
      $orderByAscending: Boolean
    ) {
      UnindexedRecords(
        folderId: $folderId
        limit: $limit
        page: $page
        orderByAscending: $orderByAscending
      ) {
        totalCount
        records {
          id
          toBeIndexedBy
          fields {
            field {
              id
              name
            }
            value
          }
          createdBy {
            firstName
            lastName
          }
          folderId {
            name
            id
            primaryFolderFieldId
          }
          attachments {
            name
          }
          assignTo {
            firstName
            lastName
          }
          documentNotes {
            id
            content
            priority
            createdBy {
              id
              firstName
              lastName
            }
            updatedAt
            createdAt
            updatedBy {
              id
              firstName
              lastName
            }
          }
          createdAt
          processPath {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
              }
            }
            processStatus
          }
          textractProcessComplete
          currentProcessStep {
            id
            stepOrder
            completed
            required
            ... on ApprovalStep {
              toBeApprovedBy
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
            }
          }
        }
        error
      }
    }
  `,
  GET_UNINDEXED_BY_ORG: gql`
    query UnindexedByOrgRecords(
      $folderId: ID!
      $organisationId: ID!
      $limit: Int
      $page: Int
    ) {
      UnindexedByOrgRecords(
        folderId: $folderId
        organisationId: $organisationId
        limit: $limit
        page: $page
      ) {
        totalCount
        records {
          id
          fields {
            field {
              id
              name
            }
            value
          }
          createdBy {
            firstName
            lastName
          }
          folderId {
            name
            id
            primaryFolderFieldId
          }
          attachments {
            name
          }
          assignTo {
            firstName
            lastName
          }
          createdAt
          documentNotes {
            id
            content
            priority
            createdAt
            createdBy {
              lastName
            }
          }
          toBeIndexedBy
          processPath {
            id
            name
            processSteps {
              id
              stepOrder
              completed
              required
              ... on ApprovalStep {
                toBeApprovedBy
                approvalStatus
                usersWhoHavePerformedApproval {
                  userId
                  timeStamp
                  actionPerformed
                }
              }
            }
            processStatus
          }
          textractProcessComplete
          currentProcessStep {
            id
            stepOrder
            completed
            required
            ... on ApprovalStep {
              toBeApprovedBy
              usersWhoHavePerformedApproval {
                userId
                timeStamp
                actionPerformed
              }
            }
          }
        }
        error
      }
    }
  `,
};
export default gqlIndexes;
