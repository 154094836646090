import React from 'react';
import { Card, CardContent, CardMedia, Grid, Theme, Typography } from '@mui/material';
import FolderIcon from '../../images/folder-icon.svg';
import FolderIconActive from '../../images/folder-icon-active.svg';

import { useHistory } from 'react-router-dom';
import { Folder } from './types';

const classes = {
  gridContent: {
    '.css-r9dp9p-MuiGrid-root>.MuiGrid-item': {
      padding: 0,
    },
  },
  cardMain: {
    height: '180px',
    width: '180px',
    transition: '.3s',
    '&:hover': {
      boxShadow: '0px 5px 5px rgb(0 0 0 / 20%)',
    },
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? theme.palette.common.greyblueDarker : undefined,
  },
  cardMedia: {
    position: 'relative',
    top: '15px',
    width: '80px',
    height: '80px',
    margin: '0 auto',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardContent: {
    textAlign: 'center',
    padding: 0,
    position: 'relative',
    top: '15px',
  },
};

const FolderCardExtend = ({ folder }: { folder: Folder }) => {
  const history = useHistory();

  const { id, name, createdBy, createdAt } = folder;
  let active = true;
  const folderIsActive: boolean | undefined = active; // This parameter must come from folder

  return (
    <>
      {' '}
      <Grid item justifyContent="center" sx={classes.gridContent}>
        <Card sx={classes.cardMain}>
          <CardMedia
            id={id}
            component="img"
            sx={classes.cardMedia}
            image={folderIsActive ? FolderIconActive : FolderIcon}
            onClick={() => history.push(`/dash/folders/${id}`)}
          />
          <CardContent sx={classes.cardContent}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color={(theme) =>
                theme.palette.mode === 'dark' ? 'common.blueGrey.0' : 'common.blue'
              }
              sx={{ overflowWrap: 'break-word', margin: 0, fontSize: 15 }}
            >
              {name}
            </Typography>
            <Typography
              variant="h6"
              fontSize={12}
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.blueGrey.200' : undefined,
              }}
            >
              {createdBy}
            </Typography>
            <Typography
              variant="h6"
              fontSize={12}
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.blueGrey.200' : undefined,
              }}
            >
              Created at: {createdAt}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default FolderCardExtend;
