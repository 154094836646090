import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_USER } from '../../../../utils/gql/gqlUsers';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import FontAwesomeIcon from '../../../../components/FAIcon';
import usersUtils from '../../../../utils/usersUtils';
import GeneralSnackbar, { StatusProps } from '../../../../components/SnackBar';

import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { User } from '../../../../components/masterTable/masterTableTypes';
import { StyledPrimaryButton } from '../../../../components/form/StyledButtons';
import { SecondStyledTexfield } from '../../../../components/form/StyledTextfield';
import StyledSwitch from '../../../../components/form/StyledSwitch';

interface UserSecurityProps {
  user: User;
}

interface FormInputs {
  password: string;
  changePW?: string;
}

const styles = {
  textfield: {
    '& label.Mui-focused': {
      color: 'common.greyblueDarker',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'common.greyblueDark',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'common.greyblueDarker',
      },
    },
  },
};

export default function UserSecurity({ user }: UserSecurityProps) {
  const [currentUser] = useLocalStorage('userData');
  const [isChangePw, setIsChangePw] = useState<boolean>(false);
  // used by react-hook-form to handle the form
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>();
  const [passwordInfo, setPasswordInfo] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [error, setPasswordError] = useState<{ success: boolean }>({ success: false });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [snackbar, setSnackbar] = React.useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'warning',
  });

  const handleCloseSnackbar = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;

    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };

  const validatePassword = (value: string) => {
    if (!usersUtils.validatePassword(value)) {
      // Sets the hook-form error, for the input helper message
      setError('password', {
        type: 'error',
        // success: false,
        message:
          'The Password must contain a minimum of 8 characters, at least one upper case letter, one lower case letter, one number and one special character.',
      });
      // Sets the error that used for the  onUserUpdate function
      // the hook form one can't be used because for some reason
      // is deleted before onUserUpdate
      setPasswordError({
        success: false,
      });
    }
    if (usersUtils.validatePassword(value)) {
      setError('password', {
        type: 'success',
        // success: true,
        message: 'Your password is strong enough.',
      });
      setPasswordError({
        success: true,
      });
    }
    setPasswordInfo(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const handleGenerate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const securePass = usersUtils.passwordGenerator();
    setValue('password', securePass);
    setPassword(securePass);
    validatePassword(securePass);
  };

  const [updateUserMutation] = useMutation(UPDATE_USER);
  const updateUser = (values: FormInputs) => {
    let variables = {
      id: user.id,
      password: values.password,
      changePw: isChangePw,
    };
    return updateUserMutation({
      variables,
    });
  };

  const onUserUpdate = async (data: FormInputs) => {
    if (!error.success) {
      setSnackbar({
        status: true,
        snackbarMessage: "Can't update the user",
        severity: 'error',
      });
      return;
    }
    updateUser({ ...data })
      .then(({ data }) => {
        // window.log('Updated user....', data);
        setSnackbar({
          status: true,
          snackbarMessage: 'User Updated Successfully',
          severity: 'success',
        });
      })
      .catch((e) => {
        // window.log(e);
        setSnackbar({
          status: true,
          snackbarMessage: "Can't update the user",
          severity: 'error',
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onUserUpdate)}>
      <Grid container spacing={2}>
        <Grid item container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <SecondStyledTexfield
              fullWidth
              {...register('password', {
                required: 'A password is needed',
              })}
              id="password-input"
              name="password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              autoComplete="current-password"
              value={password || ''}
              onChange={handleChange}
              error={errors.password && errors.password.type === 'error' ? true : false}
              color={
                errors.password && errors.password.type === 'success'
                  ? 'success'
                  : undefined
              }
              label="Password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon="fas fa-eye" size="18px" />
                      ) : (
                        <FontAwesomeIcon icon="fas fa-eye-slash" size="18px" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={styles.textfield}
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={6}
            justifyContent={{ xs: 'center', md: 'start' }}
          >
            <StyledPrimaryButton variant="contained" onClick={handleGenerate}>
              Generate Password
            </StyledPrimaryButton>
          </Grid>
        </Grid>

        <Grid item display={passwordInfo ? 'block' : 'none'} xs={12} md={6}>
          {errors.password && errors.password.type === 'error' ? (
            <Fade in={passwordInfo}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setPasswordInfo(false);
                    }}
                  >
                    <FontAwesomeIcon icon="fas fa-times-circle" size="18px" />
                  </IconButton>
                }
              >
                {errors.password.message}
              </Alert>
            </Fade>
          ) : null}
          {errors.password && errors.password.type === 'success' ? (
            <Fade in={passwordInfo}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setPasswordInfo(false);
                    }}
                  >
                    <FontAwesomeIcon icon="fas fa-times-circle" size="18px" />
                  </IconButton>
                }
              >
                {errors.password.message}
              </Alert>
            </Fade>
          ) : null}
        </Grid>

        {currentUser.id !== user.id ? (
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ margin: 0, marginBottom: 1 }}
              control={
                <StyledSwitch
                  // {...usersUtils.registerOptions(register, 'changePW', 'changePW')}
                  {...register('changePW')}
                  name="changePW"
                  checked={isChangePw}
                  onChange={() => setIsChangePw(!isChangePw)}
                  defaultChecked={false}
                />
              }
              label="Request user to stablish a new password on first login"
            />
          </Grid>
        ) : null}

        <Grid item container xs={8} justifyContent="end">
          <StyledPrimaryButton variant="contained" type="submit">
            Update
          </StyledPrimaryButton>
        </Grid>
      </Grid>
      <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
    </form>
  );
}
