import React, { useState } from 'react';
import ToolbarBase from '../../components/toolbarScreen/ToolbarBase';
import ToolbarButtonLeft from '../../components/toolbarScreen/ToolbarButtonLeft';
import ToolbarTitle from '../../components/toolbarScreen/ToolbarTitle';
import ToolbarActionRight from '../../components/toolbarScreen/ToolbarActionRight';
import { useHistory } from 'react-router-dom';
import UserPopup from './UserPopup';
import FontAwesomeIcon from '../../components/FAIcon';
import { ApolloQueryResult } from 'apollo-boost';
import { Box } from '@mui/material';

interface UsersToolbarProps {
  setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
  refetch: (
    variables?: { organisationId: any } | null | undefined
  ) => Promise<ApolloQueryResult<any>>;
  searchQuery: string | null;
}

interface Action {
  buttonOnClick: () => void;
  buttonIcon: React.ReactElement<any, any>;
  tooltipTitle: string;
  id: string;
}

const UsersToolbar = ({ setSearchQuery, refetch, searchQuery }: UsersToolbarProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const history = useHistory();

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const actions: Action[] = [
    {
      id: 'add-user',
      buttonOnClick: handleOpenModal,
      buttonIcon: (
        <Box sx={{ padding: '4px 0', display: 'flex' }}>
          <FontAwesomeIcon icon="fas fa-user-plus" size={16} />
        </Box>
      ),
      tooltipTitle: 'Create user',
    },
  ];

  return (
    <>
      <ToolbarBase>
        <ToolbarButtonLeft buttonOnClick={() => history.goBack()}>
          <FontAwesomeIcon icon="fas fa-angle-left" />
        </ToolbarButtonLeft>
        <ToolbarTitle title={'Users'} />
        <ToolbarActionRight
          handleSearch={handleSearch}
          actions={actions}
          setSearchQuery={setSearchQuery}
        />
      </ToolbarBase>
      <UserPopup
        handleCloseModal={handleCloseModal}
        isOpenModal={isOpenModal}
        refetch={refetch}
      />
    </>
  );
};

export default UsersToolbar;
