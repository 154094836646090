import React, { useContext, useState } from 'react';
import { TextField, Typography, Grid } from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import { Context as StatusContext } from '../../context/statusContext';
import gqlShared from '../../utils/gql/gqlShared';
import { createFab } from '../collectionBin/utils';
import { StatusProps } from '../SnackBar';
import FontAwesomeIcon from '../FAIcon';

interface PDFShareToProps {
  setSnackbar: React.Dispatch<React.SetStateAction<StatusProps>>;
  collection: any;
  styles: any;
  value: number;
  context?: 'collectionBin' | 'PDFViewer';
}

export default function PDFShareTo({
  setSnackbar,
  collection = false,
  styles,
  value,
  context = 'PDFViewer',
}: PDFShareToProps) {
  const [link, setLink] = useState('');
  const { state } = useContext(StatusContext);
  const [createShareMutation] = useMutation(gqlShared.CREATE_SHARE);
  const createShare = async () => {
    createShareMutation({
      variables: {
        recordId: collection
          ? collection.map((col) => {
              return col.id;
            })
          : [state.selectedRecord.id],
        permissions: "['print','download']",
      },
    })
      .then(({ data }) => {
        let link =
          'https://' + window.location.hostname + '/shared/' + data.createShare.url;
        setLink(link);
        navigator.clipboard.writeText(link).then(
          function () {
            window.log('Copying to clipboard was successful!');
          },
          function (err) {
            console.error('Could not copy text: ', err);
          }
        );
        setSnackbar({
          status: true,
          snackbarMessage: 'Link created and copied on your clipboard',
          severity: 'success',
        });
      })
      .catch((e) => {
        window.log(e);
      });
  };
  const customButton = {
    color: styles ? 'primary' : 'secondary', // it's necessary to update PDF viewer share styles (similar to collection bin)
    icon: <FontAwesomeIcon icon="fas fa-copy" />,
    label: 'Generate Link',
    onClick: createShare,
  };
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={8}>
        <TextField
          fullWidth
          name="link"
          type="text"
          value={link}
          variant="outlined"
          sx={styles ? styles.textfield : {}}
        />
      </Grid>
      <Typography
        variant="caption"
        sx={{
          color: context === 'collectionBin' ? '' : 'common.greyblueDarker',
          padding: '8px 20px',
          fontWeight: context === 'collectionBin' ? 500 : 700,
        }}
      >
        Anyone with the link will be able to access the record for 24hs. Click on the
        button to generate a link.
      </Typography>
      {createFab(customButton, 2, value ? value : 2)}
    </Grid>
  );
}
