import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, TextField, Autocomplete } from '@mui/material';
import { Context as AuthContext } from '../context/authContext';
import { Context as StatusContext } from '../context/statusContext';
import { modifyLocalStorageObject, useLocalStorage } from '../hooks/useLocalStorage';
import { GET_ORGS } from '../utils/gql/gqlOrg';
import { useQuery } from '@apollo/react-hooks';

const OrganisationSelector = () => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { state } = useContext(AuthContext);
  const statusObj = useContext(StatusContext);
  const { setSelectedOrg } = statusObj;
  const [userData, setUserData] = useLocalStorage('userData');
  const { data, refetch, loading } = useQuery(GET_ORGS);

  const getOptions = (orgs) => {
    let options = orgs.map((org) => {
      return {
        title: org.name,
        id: org.id,
      };
    });
    setOptions(options);
  };

  const [value, setValue] = useState({
    title: userData.selOrg?.name,
    id: userData.selOrg?.id,
  });

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setSelectedOrg(userData.selOrg);
  }, []);

  useEffect(() => {
    if (!data) return;
    getOptions(data.Organisations.organisations);
  }, [data]);

  if (state.suser || userData.suser) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          flexDirection: 'row',
          display: 'flex',
          marginRight: '10px',
        }}
      >
        <Autocomplete
          sx={{
            width: '150px',
            '& input': { color: 'white.main' },
            '& .MuiInput-root::before': { borderBottom: '1px solid #ffffff9b' },
          }}
          id="OrgSelector"
          open={open}
          loading={loading}
          onOpen={() => {
            refetch();
            setOpen(true);
          }}
          onClose={() => setOpen(false)}
          value={value}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setValue(newValue);
              setUserData({
                ...userData,
                selOrg: { name: newValue.title, id: newValue.id },
              });
              setUserData(
                modifyLocalStorageObject(
                  {
                    ...userData,
                    selOrg: { name: newValue.title, id: newValue.id },
                  },
                  'userData'
                )
              );
              setSelectedOrg(newValue);
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          getOptionLabel={(option) => (option.title ? option.title : '')}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField variant="standard" {...params} />}
        />
      </Box>
    );
  } else {
    return null;
  }
};

export default OrganisationSelector;
