import { Box, IconButton, MenuItem } from '@mui/material';
import { SecondStyledTexfield } from '../../../components/form/StyledTextfield';
import { GlobalOptionsState } from '../FolderPage';
import { useState } from 'react';
import FontAwesomeIcon from '../../../components/FAIcon';
import IdentifierConfigModal from './OCRIdentifier/IdentifierConfigModal';
import { Folder } from '../types';
import { StatusProps } from '../../../components/SnackBar';

interface TemplateIdentifierProps {
  folder: Folder;
  options: {
    id: string;
    label: string;
  }[];
  folderGlobalOptions: GlobalOptionsState;
  handleEditGlobalOptions: (key: string, value: boolean | string) => void;
  setSnackbar: React.Dispatch<React.SetStateAction<StatusProps>>;
  refetch: any;
  dataTestId?: string;
}

const TemplateIdentifier = ({
  folder,
  options,
  folderGlobalOptions,
  handleEditGlobalOptions,
  setSnackbar,
  refetch,
  dataTestId,
  setCurrentFolder,
}: TemplateIdentifierProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  return (
    <>
      <Box sx={{ width: 'auto', display: 'flex', gap: '5px' }}>
        <SecondStyledTexfield
          data-testid={dataTestId}
          select
          variant="outlined"
          placeholder="Select a field"
          InputProps={{ disableUnderline: true }}
          defaultValue={
            folderGlobalOptions.apProcessingSettings?.ocrTemplateNameFolderFieldId
              ? folderGlobalOptions.apProcessingSettings?.ocrTemplateNameFolderFieldId
              : 'placeholder'
          }
          onChange={(e) => {
            handleEditGlobalOptions('apProcessingSettings', {
              ocrTemplateNameFolderFieldId: e.target.value,
            });
            setSnackbar({
              status: true,
              severity: 'success',
              snackbarMessage: 'OCR Template Name Folder Field updated',
            });
          }}
          sx={{
            width: '90%',
            '& fieldset': {
              border: (theme) => (theme.palette.mode === 'dark' ? 'none' : undefined),
            },
            '.MuiSelect-select': {
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#2D333A' : undefined,
              '&.MuiInputBase-input': { padding: '10px' },
            },
            '& .MuiSelect-icon': {
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'common.orange' : 'common.greyblueDark',
            },
          }}
        >
          <MenuItem disabled value="placeholder">
            <em>Select a field</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </SecondStyledTexfield>
        <IconButton
          sx={{ borderRadius: '4px' }}
          onClick={handleOpen}
          disabled={
            !folderGlobalOptions.apProcessingSettings?.ocrTemplateNameFolderFieldId
          }
        >
          <FontAwesomeIcon icon="fas fa-cog" size="16px" />
        </IconButton>
      </Box>
      {isModalOpen && (
        <IdentifierConfigModal
          folder={folder}
          folderGlobalOptions={folderGlobalOptions}
          isOpen={isModalOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          setSnackbar={setSnackbar}
          refetch={refetch}
          setCurrentFolder={setCurrentFolder}
        />
      )}
    </>
  );
};

export default TemplateIdentifier;
