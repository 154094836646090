import {
  AlertColor,
  Box,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GridCellEditCommitParams,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridEditDateCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowsProp,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
  MuiEvent,
} from '@mui/x-data-grid';
import clsx from 'clsx';
import { isValid, parse } from 'date-fns';
import { debounce, isString } from 'lodash';
import { createRef, Fragment, useRef } from 'react';
import { State } from '../../context/statusContextTypes';
import { modifyLocalStorageObject } from '../../hooks/useLocalStorage';
import { capitalize, splitByUpperCase } from '../../utils/text';
import utils from '../../utils/utils';
import FontAwesomeIcon from '../FAIcon';
import CellEditAutocomplete from './components/Autocomplete';
import DatePickerCell from './components/DatePickerCell';
import HeaderToggleButtons from './components/HeaderToggleButtons';
import {
  CopiedRows,
  DefaultTableOptions,
  FolderField,
  LastEnteredValue,
  SelectedFolder,
} from './masterTableTypes';
import MultistringCell from './components/MultistringCell';
// import * as moment from 'moment';
import moment from 'moment';
import StringCell from './components/StringCell';
import KeyValueCell from './components/KeyValueCell';
import currency from 'currency.js';
export function renderAutocomplete(params: GridRenderEditCellParams) {
  return <CellEditAutocomplete {...params} />;
}

export function getColumnRegex(col: FolderField) {
  let regex: RegExp | undefined = undefined;
  if (col?.indexRequirements?.regexMatch) {
    regex = new RegExp(col.indexRequirements.regexMatch);
  }
  return regex;
}

// Defines the cell type and return a new column object
export function getColumnWithCellType(
  col: FolderField,
  defaultTableOptions?: DefaultTableOptions,
  regex?: RegExp,
  state?: State,
  setDropdownModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setNewDropdownOption: React.Dispatch<React.SetStateAction<string>>,
  tableSettings,
  firstIndex,
  selectedFolder: SelectedFolder,
  refetchFolder
) {
  let type, valueOptions, valueGetter, valueFormatter, renderEditCell, renderCell;
  if (!col) return;
  if (col.name === 'createdAt') {
    col.type = 'datetime';
    col.isCreatedAt = 'true';
  }

  const width = tableSettings?.columnWidth?.[col.folderId]?.[state.screen]?.[col.id];

  if (
    selectedFolder &&
    selectedFolder.apProcessingSettings?.ocrTemplateNameFolderFieldId === col.id &&
    selectedFolder.apProcessingSettings?.ocrTemplateNameSupplierCodePairs?.length
  ) {
    renderCell = (params: GridRenderCellParams) => {
      let key =
        selectedFolder.apProcessingSettings?.ocrTemplateNameSupplierCodePairs?.find(
          (key) => key.code === params.value
        );
      return key ? <p>{`${key.code} (${key.name})`}</p> : '';
    };
    renderEditCell = (params: GridRenderCellParams) => (
      <KeyValueCell
        params={params}
        selectedFolder={selectedFolder}
        refetchFolder={refetchFolder}
      />
    );
  } else {
    switch (col.type) {
      case 'string':
        valueFormatter = ({ value }: GridValueFormatterParams) =>
          value && isString(value) && value.trim();
        renderEditCell = (params: GridRenderCellParams) => <StringCell {...params} />;
        break;
      case 'multistring':
        type = 'multistring';
        renderEditCell = (params: GridRenderCellParams) => (
          <MultistringCell
            params={params}
            screen={state.screen}
            fieldID={col.id}
            colName={col.name}
          />
        );
        break;
      case 'dropdown':
        type = 'singleSelect';
        renderEditCell = (params: GridRenderEditCellParams) =>
          renderAutocomplete({
            ...params,
            setDropdownModalOpen,
            setNewDropdownOption,
          });
        if (col.options) {
          valueOptions = col.options.map((opt) => opt.value);
        }
        break;
      case 'datetime':
        type = 'date';
        renderEditCell = (params: GridRenderEditCellParams) => {
          return <DatePickerCell {...params} />;
        };
        valueGetter = ({ value }: GridValueGetterParams) => {
          return utils.formatDateWithMoment(value);
        };
        break;
      case 'currency':
        // Check why we're not sending floatValue instead of "currencyString"
        //

        type = 'number';
        renderCell = (params: GridRenderCellParams) => {
          let currencyValue = currency(params.value, {
            pattern: `#`,
          }).format();
          if (!params.value) return <p>{utils.parseCurrency('0.00')}</p>;
          return (
            <strong>
              <p>{currencyValue}</p>
            </strong>
          );
        };
        valueFormatter = ({ value }: GridValueFormatterParams) => currency(value);
        break;
      case 'integer':
        type = 'number';
        break;
      default:
        type = col.type;
    }
  }

  let column = {
    field: col.name,
    headerName: capitalize(splitByUpperCase(col.name)),
    type: type,
    valueOptions,
    valueGetter,
    valueFormatter,
    renderCell: (params: GridRenderCellParams) => {
      return utils.GenerateRenderCell(
        params,
        regex,
        col.mainField && col.mainField === true,
        firstIndex,
        type,
        state,
        col
      );
    },
    width: width ? width : type === 'singleSelect' ? 160 : 150,
    editable: col.isCreatedAt ? false : defaultTableOptions?.edit,
  };

  if (renderEditCell) {
    column['renderEditCell'] = renderEditCell;
  }

  if (renderCell) {
    column['renderCell'] = renderCell;
  }

  return column;
}

// Adds a red color background to a cell if the value does not match regex
export function addRegexErrorClass(
  col: FolderField,
  newColObject: GridColDef,
  regex: RegExp | undefined
) {
  // Checks if the column has a regex
  if (col?.indexRequirements?.regexMatch) {
    // Adds an error class to the cell container
    newColObject.cellClassName = (params) => {
      if (regex === undefined || params.value === '') {
        return '';
      }

      if (regex instanceof RegExp) {
        return clsx({
          error: !regex.test(params.value),
        });
      }
    };
  }
}

export function renderHeader(
  col: FolderField,
  newColObject: GridColDef,
  holdFieldValue: React.MutableRefObject<string[]>,
  lastEnteredValues: LastEnteredValue[],
  state: State,
  setDropdownModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setClickedField,
  setTableSettings,
  selectedFolder,
  screen
) {
  if (!newColObject) return;
  newColObject['renderHeader'] = (params) => {
    return (
      <>
        <strong>
          {col.indexRequirements && col.indexRequirements.isMandatory && (
            <FontAwesomeIcon icon="fas fa-asterisk" size={10} color="secondary.main" />
          )}
          {col.indexRequirements && col.indexRequirements.regexMatch && (
            <FontAwesomeIcon icon="fas fa-filter" size={10} color="secondary.main" />
          )}
          {newColObject.headerName}
          <span>
            {state.screen === 'indexing' && col.name !== 'createdAt' && (
              <HeaderToggleButtons
                column={col}
                holdFieldValue={holdFieldValue}
                // setHoldFieldValue={setHoldFieldValue}
                lastEnteredValues={lastEnteredValues}
              />
            )}

            {state.screen === 'indexing' && col.type === 'dropdown' && (
              <Tooltip title="Add option" arrow>
                <IconButton
                  aria-label="add-option"
                  onClick={(e) => {
                    setDropdownModalOpen(true);
                    setClickedField(col.name);
                  }}
                >
                  <FontAwesomeIcon icon="fas fa-plus" size="10px" />
                </IconButton>
              </Tooltip>
            )}
          </span>
        </strong>
        <Divider
          id="resize-handler"
          orientation="vertical"
          variant="middle"
          light={true}
          onMouseDown={(event) => {
            const { tableSettings } = JSON.parse(
              window.localStorage.getItem('userSettings')
            );
            const elements = document.querySelectorAll(`[data-field="${params.field}"]`);
            const initialX = event.clientX;
            const initialWidth = elements[0].clientWidth;
            elements.forEach((element) => {
              element.style.maxWidth = 'none';
              element.style.minWidth = '150px';
            });
            let newWidth: number;

            const handleMouseMove = (e) => {
              const offsetX = e.clientX - initialX;
              newWidth = initialWidth + offsetX;
              elements.forEach((element) => {
                element.style.width = `${newWidth}px`;
              });
            };

            const handleMouseUp = () => {
              const fieldId = selectedFolder.folderFields.find(
                (field) => field.name === params.field
              )?.id;

              let newSettings = {
                ...tableSettings,
                columnWidth: {
                  ...tableSettings.columnWidth,
                  [selectedFolder.id]: {
                    ...tableSettings.columnWidth?.[selectedFolder.id],
                    [screen]: {
                      ...tableSettings.columnWidth?.[selectedFolder.id]?.[screen],
                      [fieldId]: newWidth,
                    },
                  },
                },
              };

              setTableSettings(
                modifyLocalStorageObject(
                  {
                    tableSettings: newSettings,
                  },
                  'userSettings'
                )
              );

              document.removeEventListener('mousemove', handleMouseMove);
              document.removeEventListener('mouseup', handleMouseUp);
            };

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
          }}
        />
      </>
    );
  };
}

export function valueMatchesRegex(
  folderField: FolderField,
  params: GridCellEditCommitParams,
  setSnackOpen: (value: React.SetStateAction<boolean>) => void,
  setMessage: (
    value: React.SetStateAction<{
      message: string;
      severity: AlertColor | undefined;
    } | null>
  ) => void,
  state: any
) {
  if (folderField.indexRequirements && folderField.indexRequirements.regexMatch) {
    const fieldRegex = new RegExp(folderField.indexRequirements.regexMatch);
    if (!fieldRegex.test(params.value) && state.screen === 'search') {
      setSnackOpen(true);
      setMessage({
        message:
          'There were no modified records or the records modified where not compliant',
        severity: 'error',
      });
      return false;
    }
  }

  return true;
}

export function emptyMainField(
  folderField: FolderField,
  params: GridCellEditCommitParams,
  setSnackOpen: (value: React.SetStateAction<boolean>) => void,
  setMessage: (
    value: React.SetStateAction<{
      message: string;
      severity: AlertColor | undefined;
    } | null>
  ) => void,
  state: any
) {
  if (folderField.mainField && !params.value && state.screen === 'search') {
    setSnackOpen(true);
    setMessage({
      message: 'Main field cannot be empty, it will not be saved',
      severity: 'error',
    });
    return true;
  }
  return false;
}

export function addRightClickedRowToSelected(
  selectionModel: any[],
  clickedRow: string | null | undefined,
  setSelectionModel: (value: React.SetStateAction<any[]>) => void,
  rows: readonly any[],
  setScreenSelectedData: any,
  modifyLocalStorageObject: (newState: any, key: any) => any,
  screenSelectedData: any,
  state: any
) {
  const selected = selectionModel;
  selected.push(clickedRow);
  setSelectionModel((prev) => [...selected]);

  // get all data of selected rows
  const selectedRowsData = selected.map(
    (selectedRow) => rows.filter((row) => row.id === selectedRow)[0]
  );

  setScreenSelectedData(
    modifyLocalStorageObject(
      {
        ...screenSelectedData,
        [`${state.screen}ScreenSelected`]: {
          selected: selectionModel,
          selectedRows: selectedRowsData,
        },
      },
      `${state.screen}ScreenSelected`
    )
  );
}

export function keepTrackOfEnteredValues(
  lastEnteredValues: LastEnteredValue[],
  params: GridCellEditCommitParams,
  setLastEnteredValues: React.Dispatch<React.SetStateAction<LastEnteredValue[]>>,
  rows: GridRowsProp
) {
  if (params.value === '') return;
  let containsField = lastEnteredValues.some((obj) => obj.field === params.field);

  if (containsField) {
    const lastValues = lastEnteredValues;
    lastValues.map((obj) => {
      if (obj.field === params.field) obj.value = params.value;
    });
    setLastEnteredValues(lastValues);
  }
  if (!containsField) {
    const fieldData = rows[0].fields.find((obj) => obj.field.name === params.field);

    setLastEnteredValues([
      ...lastEnteredValues,
      { id: fieldData?.field?.id, field: params.field, value: params.value },
    ]);
  }
}

export function setHoldedValues(
  holdFieldValue: React.MutableRefObject<string[]>,
  lastEnteredValues: LastEnteredValue[],
  model: GridCellParams,
  setCopiedRows: React.Dispatch<React.SetStateAction<CopiedRows>>,
  copiedRows: CopiedRows,
  rows: readonly any[]
) {
  const {
    row: { id },
  } = model;

  if (holdFieldValue && holdFieldValue.current.length) {
    holdFieldValue.current.forEach((col) => {
      const lev = lastEnteredValues.find((obj) => obj.field === col);
      if (!lev) return;
      model.row[col] = lev.value;
      let updatedCR = copiedRows;
      updatedCR[id] = {
        ...updatedCR[id],
        [col]: {
          id: lev.id,
          value: lev.value,
          name: lev.field,
        },
      };

      setCopiedRows(updatedCR);
    });
  }
}

export function preventNextRowOnEnter(
  event: MuiEvent<React.KeyboardEvent<HTMLElement>>,
  params: GridCellParams<any, any, any>
) {
  // Prevents the default datagrid behavior when condition is met
  if (
    event.key === 'Enter' &&
    params.colDef.type === 'singleSelect' &&
    params.cellMode !== 'view'
  ) {
    event.defaultMuiPrevented = true;
  }
}

export function catchDropdownSubmitKeys(
  event: MuiEvent<React.KeyboardEvent<HTMLElement>>,
  params: GridCellParams<any, any, any>,
  setDropdownModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setNewDropdownOption: React.Dispatch<React.SetStateAction<string>>
) {
  if (
    (event.key === 'Enter' || event.key === 'Tab') &&
    params.colDef.type === 'singleSelect' &&
    params.cellMode !== 'view' &&
    !params.value
  ) {
    const options = params.colDef.valueOptions;
    const inputValue = event.target.defaultValue;
    const newOption = inputValue !== '' && !options.some((el) => el === inputValue);

    if (newOption) {
      setNewDropdownOption(inputValue);
      setDropdownModalOpen(true);
    }
  }
}

// This function is used to check if the value the user entered is the same that the cell already has
// and returns true if it is the same value
export function isTheSameValue(
  params: GridCellEditCommitParams,
  editingRow: React.RefObject<GridValidRowModel>
) {
  if (params.value === params.formattedValue) return true;

  if (
    editingRow?.current &&
    editingRow.current.fields &&
    editingRow.current.fields.length > 0
  ) {
    const row = editingRow.current.fields.find(
      (obj: any) => obj.field.name === params.field
    );

    if (!params.value && !row?.value) return true;

    if (row && row?.field?.type === 'datetime') {
      // convert row.value to DD/MM/YYYY, params.value should be already in that format
      const value = utils.formatDateWithMoment(row.value);
      if (value === params.value) return true;
    }

    if (params?.value && row?.value && params.value === row.value) return true;
  }

  return false;
}
