import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import Button from '../../../components/Button/StyledButton';
import OCRDocumentView from '../../../components/OCRSetup/OCRDocumentView';
import OCRSetupToolbar from '../../../components/OCRSetup/OCRSetupToolbar';
import OCRTemplateSetup from '../../../components/OCRSetup/OCRTemplateSetup';
import { FabricProvider } from '../../../hooks/useFabricCanvas';
import usePDF from '../../../hooks/usePDF';
import { Folder } from '../types';
import TemplatesTab from './tabs/TemplatesTab';
import ProcessingTab from './tabs/ProcessingTab';
import { Context as StatusContext } from '../../../context/statusContext';
import { StatusContextTypes } from '../../../context/statusContextTypes';
import { GlobalOptionsState } from '../FolderPage';
import { StatusProps } from '../../../components/SnackBar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ padding: '0px 0', height: 'calc(100% - 40px)' }}
    >
      {value === index && <Box sx={{ p: 3, height: '100%' }}>{children}</Box>}
    </div>
  );
}

const TABS = [
  {
    label: 'Templates',
    value: 'Templates',
  },
  {
    label: 'System',
    value: 'System',
  },
  {
    label: 'Processing',
    value: 'Processing',
  },
  {
    label: 'Nominal',
    value: 'Nominal',
  },
  {
    label: 'Data links',
    value: 'Links',
  },
  {
    label: 'Data checks',
    value: 'Checks',
  },
  {
    label: 'Options',
    value: 'Options',
  },
  {
    label: 'Integration',
    value: 'Integration',
  },
];

interface OCRSetupTabProps {
  folder: Folder;
  handleEditGlobalOptions: (key: string, value: boolean | string) => void;
  folderGlobalOptions: GlobalOptionsState;
  setSnackbar: React.Dispatch<React.SetStateAction<StatusProps>>;
  refetch: any;
}

const OCRSetupTab = ({
  folder,
  handleEditGlobalOptions,
  folderGlobalOptions,
  setSnackbar,
  refetch,
  setCurrentFolder,
}: OCRSetupTabProps) => {
  const [tabValue, setTabValue] = useState<string>('Templates');
  const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
  const { state, setScreen } = useContext(StatusContext) as StatusContextTypes;

  const { url: fileURL } = usePDF({
    attachment: {
      folderId: selectedTemplate?.folderId,
      name: selectedTemplate?.imageUsedAsTemplate,
    },
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!state.screen) {
      setScreen('folders');
    }
  }, [state.screen]);

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons={false}
        orientation="vertical"
        indicatorColor="secondary"
        TabIndicatorProps={{ style: { display: 'none' } }}
        sx={{
          height: '100%',
          minWidth: '150px',
          borderLeft: (theme) => (theme.palette.mode === 'dark' ? 'none' : '1px solid'),
          borderLeftColor: 'common.greyblueDark',
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#2D333A' : 'common.greyblueDarker',
          '& .MuiTab-root.Mui-selected::before': {
            color: 'common.orange',
            content: '""',
            position: 'absolute',
            bottom: '45%',
            right: -3,
            transform: 'rotate(90deg)',
            width: 0,
            height: 0,
            borderLeft: '9px solid transparent',
            borderRight: '9px solid transparent',
            borderTop: '9px solid',
            borderTopColor: 'common.orange',
          },
        }}
      >
        {TABS.map(({ label, value }, index) => {
          return (
            <Tab
              key={label}
              label={label}
              value={value}
              disabled={label === 'PROCESS STEPS' || label === 'PERMISSIONS'}
              sx={{
                height: '60px',
                borderBottom: '1px solid',
                borderBottomWidth: '1.5px',
                borderBottomColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#26292E' : 'common.greyblueDark',
                color: 'common.white',
                fontSize: 14,
                '&.Mui-selected': { color: 'common.orange' },
              }}
            />
          );
        })}
      </Tabs>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Stack direction="row">
          {selectedTemplate ? (
            <Button onClick={() => setSelectedTemplate(null)}>Back</Button>
          ) : null}
          <Box
            sx={{
              width: '100%',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? undefined : 'common.greyblueDark',
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                color: 'common.orange',
                fontWeight: 700,
                p: '10px 0',
              }}
            >
              {!selectedTemplate ? 'OCR SETTINGS' : 'EDIT TEMPLATE'}
            </Typography>
          </Box>
        </Stack>
        <TabPanel value={tabValue} index={'Templates'}>
          {!selectedTemplate ? (
            <TemplatesTab
              folder={folder}
              folderGlobalOptions={folderGlobalOptions}
              handleEditGlobalOptions={handleEditGlobalOptions}
              setSelectedTemplate={setSelectedTemplate}
              setSnackbar={setSnackbar}
              refetch={refetch}
              setCurrentFolder={setCurrentFolder}
            />
          ) : (
            <FabricProvider>
              <Stack direction="row" gap={4} height="100%">
                <OCRTemplateSetup template={selectedTemplate} />
                <Stack
                  width={'100%'}
                  sx={{
                    backgroundColor: 'common.lightGrey',
                    height: '100%',
                  }}
                >
                  <OCRSetupToolbar fileURL={fileURL} />
                  {fileURL && (
                    <OCRDocumentView template={selectedTemplate} fileURL={fileURL} />
                  )}
                </Stack>
              </Stack>
            </FabricProvider>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={'Processing'}>
          <ProcessingTab />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default OCRSetupTab;
