import React, { useContext, useEffect } from 'react';
import { Box, Badge, IconButton, Tooltip, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import FontAwesomeIcon from '../FAIcon';
import { Context as TasksContext } from '../../context/tasksContext';

import Toolbar from './Toolbar';
import TasksTrayContent from './TasksTrayContent';
import { TaskContent } from '../../context/tasksContextTypes';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 15,
    fontSize: '0.5rem',
    color: theme.palette.common.greyblueDarker,
    height: '1rem',
    width: '1rem',
    minWidth: '1rem',
    padding: '0',
  },
}));
const StyledPopover = styled(Popover)(({ theme }) => ({
  '& 	.MuiPopover-paper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));

export default function TasksTray({ setAnchorTasksTray, anchorTasksTray }) {
  const { removeTask, state } = useContext(TasksContext);
  let completedTasks = state.tasks.filter((task: TaskContent) => task.progress > 99.99);
  let currentTasks = state.tasks.filter((task: TaskContent) => task.read !== true);
  const openTasksTray = (event) => {
    setAnchorTasksTray(event.currentTarget);
  };

  const closeTasksTray = () => {
    setAnchorTasksTray(null);
  };

  const clearAllCompleted = () => {
    if (completedTasks.length) {
      completedTasks.forEach((task: TaskContent) => {
        removeTask(task);
      });
    }
  };
  /*   useEffect(() => {
    window.log(state);
    completedTasks = state.tasks.filter((task) => task.progress === 100);
    currentTasks = state.tasks.filter((task) => task.read !== true);
  }, [state.refetch]); */

  const open = Boolean(anchorTasksTray);
  const id = open ? 'notificationTray' : undefined;

  useEffect(() => {
    state.tasks.forEach(
      (task: TaskContent) => task.timeStamp < Date.now() - 43200000 && removeTask(task)
    );
  }, []);

  return (
    <>
      <Tooltip title="Tasks" arrow>
        <IconButton
          onClick={anchorTasksTray ? closeTasksTray : openTasksTray}
          aria-label="tasks"
          sx={{
            padding: '4px 10px',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:hover .MuiIcon-root': {
              color: 'common.blue',
            },
          }}
        >
          <StyledBadge badgeContent={currentTasks.length} color="secondary">
            <FontAwesomeIcon icon="fas fa-tasks" size="18px" color="#fff" />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorTasksTray}
        onClose={closeTasksTray}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => e.stopPropagation()}
        elevation={0}
        PaperProps={{ sx: { backgroundColor: 'transparent' } }}
      >
        <Box
          sx={{
            border: '10px solid transparent',
            borderBottomColor: 'common.greyblueDarker',
            width: '0',
            maxWidth: '0',
            position: 'relative',
            left: '60%',
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: 'common.greyblueDarker',
            borderRadius: '5px',
            boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
            color: 'white.main',
            minWidth: '350px',
            paddingBottom: '10px',
          }}
        >
          <Toolbar
            // tasksNotifications={state.tasks}
            completedTasks={completedTasks}
            clearAllCompleted={clearAllCompleted}
          />
          <TasksTrayContent tasks={state.tasks} removeTask={removeTask} />
        </Box>
      </Popover>
    </>
  );
}
