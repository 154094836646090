export const setColor = {
  primary: "#2e9ccA",
  secondary: "#ffa502",
  secondaryDark: "#ef9a01",
  mainWhite: "#ececec",
  mainBlue: "#2e9ccA", //"#4f73af",
  mainGrey: "#f1f1f1",
  darkGrey: "#636363",
  navGrey: "#323538",
  navGreyLight: "#4d555a",
  veryLightGrey: "#cecece",
  lightGrey: "#adadad",
  inputGrey: "#f2f2f2",
  tertiary: "#2f3542",
  positive: "#2ed573",
  positiveDark: "#2ea760",
  negative: "#ff4757",
  negativeDark: "#d0424f",
};
