import { Box, IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { createRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useForm } from 'react-hook-form';
import { Permissions } from '../../../containers/user/userProfile/components/UserPermissionsTab/permissionsTypes';
import { DELETE_NOTE_FROM_RECORD } from '../../../utils/gql/gqlNotes';
import FontAwesomeIcon from '../../FAIcon';
import {
  MasterTableProps,
  Row,
  SetMessage,
  SetSnackBarState,
} from '../../masterTable/masterTableTypes';
import AltForm from '../../Notes/components/AltForm';
import NotesCard from '../../Notes/NotesCard';
import NotesForm from '../../Notes/NotesForm';
import { Inputs, Note } from '../../Notes/notesTypes';

const styles = {
  boxStyles: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
  },
  title: {
    backgroundColor: 'common.greyblueDarker',
    color: 'common.white',
    borderRadius: '8px',
    textAlign: 'left',
    padding: '5px 10px 5px 15px',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    minHeight: '500px',
    maxHeight: '500px',
    overflow: 'auto',
    p: 2,
    m: 1,
    marginBottom: 0,
    '&::-webkit-scrollbar': {
      width: '12px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#436072',
      borderRadius: '2px',
    },
  },
  footer: {
    borderTop: '1px solid #ccc',
    height: '100px',
    width: '410px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    backgroundColor: 'green',
    boxShadow: '0px 3px 17px 0px rgba(51,51,51,0.38)',
  },
  noNotesYet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 15,
    opacity: 0.5,
  },
};

interface NotesTabProps {
  recordId: string;
  records: Row[];
  setMessage: SetMessage;
  setSnackOpen: SetSnackBarState;
  folderPermissions: Permissions;
  notesRefetch: (values: any, isSearch?: boolean) => void;
}

export const NotesTab = ({
  recordId,
  notesRefetch,
  records,
  queryVariables,
  setMessage,
  setSnackOpen,
  folderPermissions,
}: NotesTabProps) => {
  const [record, setRecord] = useState<Row>(records[0]);
  const [notes, setNotes] = useState<Array<Note>>([]);
  const [showNotesList, setShowNotesList] = useState<boolean>(true);
  const [showAddANote, setShowAddANote] = useState<boolean>(false);
  const [deleteNoteFromRecord] = useMutation(DELETE_NOTE_FROM_RECORD);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    resetField,
    reset,
    setFocus,
  } = useForm<Inputs>();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const createNoteButtonRef = useRef<HTMLButtonElement>(null);
  const [width, setWidth] = useState();
  const ref = createRef();

  const deleteNote = (id: string) => {
    deleteNoteFromRecord({
      variables: {
        recordId: recordId,
        documentNoteId: id,
      },
    })
      .then((res) => {
        let newNotes = // the reverse method is used to make the last note come first
          res.data.deleteDocumentNoteFromRecord.record.documentNotes.reverse();
        setNotes(newNotes);
        notesRefetch();
      })
      .catch(() => {
        setMessage({
          message: 'Note could not be deleted',
          severity: 'error',
        });
        setSnackOpen(true);
      });
  };

  const handleAddNote = () => {
    // generates a submit request in the form component
    if (!showNotesList && submitButtonRef.current !== null)
      submitButtonRef.current.click();
  };

  const handleNotesGrid = () => {
    if (!width) return 4;
    if (width < 460) {
      return 12;
    }
    if (width <= 500) {
      return 6;
    }
    return 4;
  };

  useEffect(() => {
    const record = records.find((el) => el.id === recordId);
    // updates the notes when the user changes record
    if (!record) return;
    setRecord(record);
    setNotes(record.documentNotes?.reverse());
  }, [recordId]);

  useEffect(() => {
    // if the record has no notes, the screen should start with the form
    // if done with state change (setShowNotesList), autofocus does not work
    if (notes.length === 0) createNoteButtonRef?.current?.click();
  }, [notes, createNoteButtonRef]);

  useLayoutEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      sx={{ backgroundColor: 'common.white', height: '100%' }}
    >
      <Stack
        justifyContent="space-between"
        ref={ref}
        sx={{
          width: '100%',
          height: 'inherit',
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? 'black.dark' : 'common.white',
        }}
        spacing={2}
      >
        {notes && notes.length ? (
          <Grid
            container
            columnSpacing={1}
            padding={'30px'}
            textAlign={'left'}
            sx={{
              overflowY: 'auto',
              margin: 0,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? 'black.dark' : 'common.white',
              flex: 1,
            }}
          >
            {notes.map((note, index) => (
              <Grid xs={index === 0 ? 12 : handleNotesGrid()}>
                <NotesCard
                  note={note}
                  key={note.id}
                  index={index}
                  deleteNote={deleteNote}
                  setMessage={setMessage}
                  setSnackOpen={setSnackOpen}
                  folderPermissions={folderPermissions}
                  isExpanded={true}
                  allowManualExpand={false}
                  longDate={index === 0 ? true : false}
                  actionButtonsText={index === 0 ? true : false}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={styles.noNotesYet}>
            <FontAwesomeIcon
              icon="fa-sticky-note"
              size={180}
              padding={0}
              color="#96c2dfb0"
            />
            <Typography variant="subtitle1" sx={{ fontSize: 18 }}>
              No notes yet
            </Typography>
          </Box>
        )}
        <AltForm
          resetField={resetField}
          submitButtonRef={submitButtonRef}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          clearErrors={clearErrors}
          setShowNotesList={setShowNotesList}
          reset={reset}
          notesRefetch={notesRefetch}
          record={record}
          setNotes={setNotes}
          setMessage={setMessage}
          setSnackOpen={setSnackOpen}
          setFocus={setFocus}
        />
      </Stack>
      {folderPermissions && folderPermissions.canAddNotes && (
        <Box sx={styles.footer}>
          <Box
            sx={{
              marginLeft: showNotesList ? 8 : 6.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {showNotesList ? (
              <>
                <IconButton
                  aria-label="create notes"
                  onMouseEnter={() => setShowAddANote(true)}
                  onMouseLeave={() => setShowAddANote(false)}
                  ref={createNoteButtonRef}
                  onClick={() => {
                    showNotesList ? setShowNotesList(false) : setShowNotesList(true);
                  }}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <FontAwesomeIcon
                    icon="fas fa-plus-circle"
                    size="48px"
                    color="#0a5487"
                    padding="0px"
                  />
                </IconButton>
                <Typography
                  sx={{
                    opacity: showAddANote ? 1 : 0,
                    fontSize: '12px',
                    color: '#8399A8',
                  }}
                >
                  Create a note
                </Typography>
              </>
            ) : (
              <>
                <IconButton
                  aria-label="add notes"
                  onMouseEnter={() => setShowAddANote(true)}
                  onMouseLeave={() => setShowAddANote(false)}
                  onClick={handleAddNote}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <FontAwesomeIcon
                    icon="fas fa-check-circle"
                    size="48px"
                    color={errors.content ? '#fc0000' : '#0a5487'}
                    padding="0px"
                  />
                </IconButton>
                <Typography
                  sx={{
                    opacity: showAddANote ? 1 : 0,
                    fontSize: '12px',
                    color: '#8399A8',
                  }}
                >
                  Add a note
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
    </Stack>
  );
};
