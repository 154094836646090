import utils from '../utils/utils';
import { checkUS, checkUserSettings, initLocalStorage } from '../hooks/useLocalStorage';

import { Dispatch } from 'react';
import { Permissions } from '../containers/user/userProfile/components/UserPermissionsTab/permissionsTypes';

interface State {
  rtcConected: boolean;
  isLoggedIn: boolean;
  scannerKey: string;
  ws: string;
  role: string;
  userName: string;
  suser: boolean;
  organisations: any[];
  collectionBin: any[];
  permissions?: Permissions;
}

export interface Action {
  type: string;
  payload: string | boolean | any;
}

export type AuthContextTypes = {
  state: State;
  signin: (user: any) => void;
  setPermissions: (permissions: Permissions) => void;
};

const authReducer = (state: State, action: Action) => {
  let activateUserSettings = true;
  let organisations = [];
  let permissions;
  switch (action.type) {
    case 'signin':
      if (!action.payload) {
        localStorage.setItem('isLoggedIn', 'false');
        return {
          ...state,
          isLoggedIn: false,
        };
      }
      let payload = action.payload.user || action.payload.superUser;
      let role, isSuser;
      if (action.payload.superUser) {
        role = -1;
        organisations = action.payload.superUser.organisations;
        isSuser = true;
      } else {
        isSuser = false;
        organisations = action.payload.user.organisationId.name;
        permissions = action.payload.user.permissions;
        switch (payload.role) {
          case 'ADMIN':
            role = 0;
            break;
          case 'EDITOR':
            role = 1;
            break;
          default:
            role = 2;
            break;
        }
      }
      utils.clearUserData();
      // localStorage.setItem('appversion', `"${process.env.REACT_APP_VERSION}"`);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('theme', `"${action.payload.paletteMode}"`);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          id: payload.id,
          userName: payload.firstName,
          userLname: payload.lastName,
          role: role,
          scan: action.payload.scan,
          ws: action.payload.ws,
          organisations: organisations,
          suser: isSuser,
          selOrg: isSuser ? organisations[0] : action.payload.user.organisationId.id,
          selFolder: '',
        })
      );
      if (
        (action.payload.user?.userSettings || action.payload.superUser?.userSettings) &&
        activateUserSettings
      ) {
        window.log(action.payload);
        let user = action.payload.user ? 'user' : 'superUser';
        let localStorageSettings = utils.parseJSON(
          action.payload[user].userSettings.localStorageSettings
        );
        window.log('checking if user settings are a valid JSON');
        if (
          localStorageSettings &&
          !action.payload[user].userSettings.localStorageSettings.includes('\\\\"')
        ) {
          window.log('user settings are a valid JSON', localStorageSettings);
          // let checkStorageSettings = checkUserSettings(localStorageSettings);
          let checkStorageSettings = checkUS(localStorageSettings);
          initLocalStorage(checkStorageSettings, true);
          return {
            ...state,
            ...checkStorageSettings,
            permissions,
            isLoggedIn: true,
          };
        }
      }
      initLocalStorage();
      return {
        ...state,
        permissions,
        isLoggedIn: true,
        scannerKey: action.payload.scan,
        role: role,
        ws: action.payload.ws,
        userName: payload.firstName,
        organisations: organisations,
        suser: action.payload.superUser ? true : false,
      };
    case 'setPermissions':
      permissions = action.payload;
      return {
        ...state,
        permissions,
      };
    default:
      return state;
  }
};
const signin = (dispatch: Dispatch<Action>) => async (isUser: boolean) => {
  try {
    dispatch({ type: 'signin', payload: isUser });
  } catch (err) {
    window.log(err);
  }
};
const selectOrg = (dispatch: Dispatch<Action>) => async (selectOrg: string) => {
  try {
    dispatch({ type: 'orgSel', payload: selectOrg });
  } catch (err) {
    window.log(err);
  }
};
const setPermissions = (dispatch: Dispatch<Action>) => async (permissions) => {
  try {
    dispatch({ type: 'setPermissions', payload: permissions });
  } catch (err) {
    window.log(err);
  }
};
export const { Provider, Context } = utils.createDataContext(
  authReducer,
  { signin, setPermissions },
  {
    rtcConected: false,
    isLoggedIn: false,
    scannerKey: '',
    ws: '',
    role: '',
    userName: '',
    permissions: null,
    suser: false,
    organisations: [],
    collectionBin: [],
  }
);
