import { useQuery } from '@apollo/react-hooks';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext, AuthContextTypes } from '../../context/authContext';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { GET_FOLDERS, GET_FOLDERS_BY_ORG } from '../../utils/gql/gqlFolders';
import utils from '../../utils/utils';
import FolderGrid from './FolderGrid';
import FolderToolbar from './FolderToolbar';
import { Folder, FolderData } from './types';

interface Variables {
  includeInactive: boolean;
  organisationId?: string;
}

function FoldersMain() {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [{ folderIsCompact }] = useLocalStorage('userSettings');
  const [folderCompact, setFolderCompact] = useState(folderIsCompact || false);

  const { state, setScreen } = useContext(StatusContext) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;
  const variables: Variables = {
    includeInactive: true,
  };
  if (isSuser) {
    variables['organisationId'] =
      state.selectedOrg && _.isObject(state.selectedOrg)
        ? state.selectedOrg.id
        : selOrg.id;
  }
  const { data, error, refetch } = useQuery(isSuser ? GET_FOLDERS_BY_ORG : GET_FOLDERS, {
    variables,
  });

  let folders;

  if (data) {
    let fetchedFolders = isSuser ? data.FoldersByOrg : data.Folders;
    if (fetchedFolders && fetchedFolders.length >= 1) {
      let folderHeaders = Object.keys(fetchedFolders[0]);
      folderHeaders.pop();

      folders = fetchedFolders.map((folder: FolderData) => {
        const newObj = {
          ...folder,
          createdAt: new Date(parseInt(folder.createdAt)).toLocaleDateString(),
          createdBy: folder.createdBy?.firstName + ' ' + folder.createdBy?.lastName,
        };
        return newObj;
      });
    }
  }

  if (error) {
    utils.clearUserData(false);
  }

  useEffect(() => {
    setScreen('folders');
  }, [state.screen]);

  // Set folder mode
  useEffect(() => {
    const folder = localStorage.getItem('folderIsCompact');
    let folderMode;
    if (folder) folderMode = JSON.parse(folder);
    if (folderMode) {
      setFolderCompact(folderMode);
    }
  }, []);

  const filterFolders = (data: Folder[]) => {
    // window.log(data);

    if (!data) return;
    return data.filter((item: Folder) => {
      let keys = ['name', 'createdBy', 'createdAt'] as const;
      let result = keys.some((key) => {
        if (searchQuery) {
          return item[key].toLowerCase().includes(searchQuery.toLowerCase());
        } else {
          return false;
        }
      });
      return result;
    });
  };

  // If there is a searchQuery calls the filter function and asigns the result to folders
  if (searchQuery) {
    // window.log(searchQuery);
    folders = filterFolders(folders);
  }
  return (
    <Box sx={{ padding: '10px 10px' }}>
      <FolderToolbar
        refetch={refetch}
        setSearchQuery={setSearchQuery}
        setFolderCompact={setFolderCompact}
        folderCompact={folderCompact}
      />
      <FolderGrid folders={folders} folderCompact={folderCompact} />
    </Box>
  );
}

export default FoldersMain;
