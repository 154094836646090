import {
  Box,
  Drawer,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  styled,
  IconButton,
  Tooltip,
} from '@mui/material';
import FontAwesomeIcon from '../../components/FAIcon';
import { Filter } from './ReportingSideBar';
import { useFormContext } from 'react-hook-form';
import { SelectedFolder } from '../../components/masterTable/masterTableTypes';

export const StyledList = styled(List)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    '& .MuiListItemButton-root': {
      borderRadius: '5px',
      paddingLeft: 24,
      paddingRight: 24,

      '& .MuiListItemText-root': {
        '& .MuiListItemText-primary': {
          fontWeight: 'bold',
          color: theme.palette.common.white,
        },
      },

      '&:hover': {
        backgroundColor: theme.palette.common.greyblue,

        '& .MuiListItemText-primary': {
          color: theme.palette.common.orange,
        },
      },
    },
  })
);

interface Props {
  options: Filter[];
  onSelection: (option: Filter) => void;
  drawer: boolean;
  handleDrawerClose: (
    event: KeyboardEvent | React.SyntheticEvent,
    reason?: string
  ) => void;
  selectedFolder: SelectedFolder | null;
}

export const FilterSelectionDrawer = (props: Props) => {
  const { options, onSelection, drawer, handleDrawerClose, selectedFolder } = props;
  const { watch } = useFormContext();
  let selectedTemplate = watch('selectedTemplate');

  const isDisabled = (selectedTemplate: any, option: Filter) => {
    if (!selectedFolder || !option) return;
    const typesToDisable = [
      'workflowAssignedBy',
      'workflowStatusChangedBy',
      'workflowStatusDate',
    ];

    if (selectedFolder && !selectedFolder.workFlow) {
      typesToDisable.push('ReportTypeEnum');
    }
    const idsToDisable = ['users'];

    const hasDateTimeField = selectedFolder.folderFields.find(
      (field) => field.type === 'datetime'
    );

    if (!hasDateTimeField) {
      typesToDisable.push('dateRange');
    }

    if (typesToDisable.includes(option.type) || idsToDisable.includes(option.id)) {
      return true;
    }

    if (
      (option && option.type === 'all') ||
      (selectedTemplate && selectedTemplate.type === 'all')
    )
      return false;

    if (!selectedTemplate) {
      return false;
    }

    switch (selectedTemplate.type) {
      case 'linked_information':
        return !['dateRange', 'synced'].includes(option.type);
      case 'workflow':
        return !['userId', 'ReportTypeEnum'].includes(option.type);
      default:
        return option.type === 'linked-data-status';
    }
  };

  return (
    <Drawer
      id="action-drawer"
      variant="persistent"
      anchor={'left'}
      open={drawer}
      onClose={handleDrawerClose}
      sx={{
        backgroundColor: 'common.greyblueDarker',
        '& 	.MuiDrawer-paper': {
          backgroundColor: 'common.greyblueDarker',
          position: 'absolute',
          height: '100%',
          width: '100%',

          '&::-webkit-scrollbar': {
            width: '5px',
          },

          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
          },

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'common.greyblue',
            borderRadius: '4px',
          },
        },
      }}
    >
      <Box sx={{ textAlign: 'end', padding: '5px' }}>
        <Tooltip title="Close">
          <IconButton aria-label="close drawer" onClick={handleDrawerClose}>
            <FontAwesomeIcon icon="fas fa-times" color="common.white" size={'16px'} />
          </IconButton>
        </Tooltip>
      </Box>
      <Stack spacing={2} alignItems="center">
        <StyledList>
          {options.map((option) => {
            const disabled = isDisabled(selectedTemplate, option);
            return (
              <ListItem disablePadding key={option?.id}>
                <ListItemButton onClick={(e) => onSelection(option)} disabled={disabled}>
                  <ListItemText primary={option?.label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </StyledList>
      </Stack>
    </Drawer>
  );
};
