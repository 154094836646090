import { Box, Button, Modal, Paper, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Context as StatusContext } from '../../../context/statusContext';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { GET_FOLDERS, GET_FOLDERS_BY_ORG } from '../../../utils/gql/gqlFolders';
import { StyledAutocomplete } from '../../form/StyledAutocomplete';
import { StyledNegativeButton } from '../../form/StyledButtons';
import _ from 'lodash';

const getSelectedRecords = (selected, rows, newFolder) => {
  let filtered = rows.filter((row) => {
    let res = selected.includes(row.id);
    return res;
  });
  const records = filtered.map((rec) => {
    return {
      recordId: rec.id,
      folderId: newFolder.id,
      toBeIndexedBy: rec.toBeIndexedBy,
      overwriteFields: false,
    };
  });
  return records;
};

const ChangeFolderModal = (props) => {
  const { open, action, selected, setOpen, rows, selectedFolder } = props;
  const [userData] = useLocalStorage('userData');
  const [newFolder, setNewFolder] = useState(selectedFolder);
  const { state } = useContext(StatusContext);
  const [folders, setFolders] = useState([]);
  const { data } = useQuery(userData.suser ? GET_FOLDERS_BY_ORG : GET_FOLDERS, {
    variables: userData.suser
      ? {
          organisationId: state.selectedOrg ? state.selectedOrg.id : userData.selOrg.id,
        }
      : null,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      userData.suser ? setFolders(data.FoldersByOrg) : setFolders(data.Folders);
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? 'common.greyblueDark'
              : 'common.greyblueDarker',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <Typography variant="subtitle2">Move record/s to folder</Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'black.dark' : 'white.main',
            maxHeight: '20vh',
            overflowY: 'auto',
            p: 5,
            '&::-webkit-scrollbar': {
              width: '10px',
            },

            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#79909f',
              borderRadius: '4px',
            },
          }}
        >
          <StyledAutocomplete
            id="select-folder"
            value={newFolder}
            options={_.orderBy(
              folders,
              [(folder: any) => folder.name.toLowerCase()],
              ['asc']
            )}
            renderInput={(params) => <TextField {...params} label="Folder" />}
            getOptionLabel={(option) => option.name}
            getOptionDisabled={(option) => option.id === selectedFolder.id}
            onChange={(e, newValue) => setNewFolder(newValue)}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? 'common.greyblueDark'
                : 'common.greyblueDarker',
            padding: '15px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <StyledNegativeButton onClick={() => setOpen(false)} size="small">
            Cancel
          </StyledNegativeButton>
          <Button
            disabled={!newFolder || newFolder.id === selectedFolder.id}
            size="small"
            onClick={() => {
              let selectedRecords = getSelectedRecords(selected, rows, newFolder);
              action(selectedRecords, newFolder);
              setOpen(false);
            }}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.common.blueShadow['500']
                  : theme.palette.primary.main,
              color: 'common.white',
              '&:hover': {
                color: 'common.white',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.blueShadow['600']
                    : theme.palette.primary.dark,
              },
            }}
          >
            Change
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ChangeFolderModal;
