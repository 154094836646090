import React, { useState } from 'react';
import Papa from 'papaparse';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import { DropzoneArea } from 'material-ui-dropzone';
import { ADDLINKED_INFORMATION } from '../../../utils/gql/gqlLinkedInfo';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import FontAwesomeIcon from '../../../components/FAIcon';
import PopUp from '../../../components/PopUp';
import SimpleTable from '../../../components/SimpleTableNew';
import GeneralSnackbar from '../../../components/SnackBar';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
  Typography,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    marginTop: { xs: 1, md: 20 },
    padding: 40,
    height: { xs: '50%', md: '100%' },
    backgroundColor: '#f1f1f1',
    boxShadow: '0px 3px 5px 3px inset #0000000f',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  dropzoneParagraphClass: {
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    color: theme.palette.common.greyblueDark,
  },
  title: {
    textAlign: 'center',
    padding: { xs: '5px 0', md: '10px 0' },
    fontSize: 15,
    backgroundColor: theme.palette.common.greyblue,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  subtitle: {
    flex: 1,
    fontSize: 14,
  },
}));

const CSVImport = ({ mapping, folder }) => {
  const classes = useStyles();
  const [userData, setUserData] = useLocalStorage('userData');
  const [importData, setImportData] = useState({});
  const [processedData, setprocessedData] = useState({});
  const [popUpIsopen, setPopUpIsopen] = useState(false);
  const [format, setFormat] = useState('en-GB');
  const { suser, selOrg } = userData;

  const [snackbar, setSnackbar] = useState({
    status: false,
    snackbarMessage: '',
    severity: '',
  });
  const [addLinkedInformation] = useMutation(ADDLINKED_INFORMATION);
  const onPopUpClose = () => {
    setPopUpIsopen(false);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };
  const handleCSV = (files) => {
    window.log(mapping);
    window.log(folder);
    let error = false;
    if (files.length) {
      Papa.parse(files[0], {
        complete: (results, parser) => {
          window.log(results);

          if (results.data[0]) {
            const csvData = [];
            let primaryFieldIndex = -1;
            let colIDs = [];
            let allCols = [];
            for (let i = 0; i < results.data.length; i++) {
              if (error) {
                break;
              }
              const rows = results.data[i];
              let linkedValues = [];
              let primaryLinkValue;
              if (rows.length < 2 && rows[0] === '') {
                break;
              }
              if (i === 0) {
                for (let j = 0; j < rows.length; j++) {
                  let col = rows[j];
                  window.log(col);
                  allCols.push(col);
                  if (col === mapping.primaryLink.linkedFieldName) {
                    primaryFieldIndex = j;
                    let filteredField = folder.folderFields.filter(
                      (ff) => ff.id === mapping.primaryLink.fieldId
                    );
                    colIDs.push({
                      field: filteredField[0],
                      mapping: mapping.primaryLink,
                    });
                  } else {
                    for (let k = 0; k < mapping.linkedValues.length; k++) {
                      const linkedValue = mapping.linkedValues[k];
                      if (col === linkedValue.linkedFieldName) {
                        let filteredField = folder.folderFields.filter(
                          (ff) => ff.id === linkedValue.fieldId
                        );
                        colIDs.push({
                          field: filteredField[0],
                          mapping: linkedValue,
                        });
                      }
                    }
                  }
                }
              } else {
                for (let k = 0; k < colIDs.length; k++) {
                  const linkedCol = colIDs[k];

                  for (let j = 0; j < rows.length; j++) {
                    let col = rows[j];
                    if (linkedCol.mapping.linkedFieldName === allCols[j]) {
                      if (primaryFieldIndex === j) {
                        primaryLinkValue = {
                          name: allCols[j],
                          value: col,
                        };
                      } else {
                        switch (linkedCol.field.type) {
                          case 'datetime':
                            switch (format) {
                              case 'en-US':
                                col = new Date(col);
                                break;
                              default:
                                col = col.split(/[\/\\,.-]/);
                                if (col[2] && col[2].length < 3) {
                                  col[2] = '20' + col[2];
                                }
                                window.log(col);
                                col = new Date(col[2], col[1] - 1, col[0]);
                                col = Date.parse(col).toString();
                                break;
                            }
                            break;
                          default:
                            break;
                        }
                        linkedValues.push({ name: allCols[j], value: col });
                      }
                      break;
                    }
                  }
                }
                csvData.push({ primaryLinkValue, linkedValues });
              }
            }
            window.log(primaryFieldIndex);
            if (primaryFieldIndex >= 0) {
              setPopUpIsopen(true);
              window.log(csvData);
              /* addLinkedInformation({
                variables: {
                  data: csvData,
                  folderId: folder.id,
                  orgId: selOrg.id,
                },
              })
                .then((res) => {
                  window.log(res);
                  setPopUpIsopen(true);
                })
                .catch((e) => {
                  console.error('Could not link the info: ', e);
                }); */
              setprocessedData(csvData);
              setImportData(results.data);
            } else {
              error = true;
              setSnackbar({
                status: true,
                snackbarMessage: 'No match for primary field found.',
                severity: 'error',
              });
            }
          }
        },
      });
    }
  };
  const pushCSVData = () => {
    addLinkedInformation({
      variables: {
        data: processedData,
        folderId: folder.id,
        orgId: selOrg.id,
      },
    })
      .then((res) => {
        if (res) {
          window.log(res);
          setPopUpIsopen(false);
          setSnackbar({
            status: true,
            snackbarMessage: 'Linked Information added.',
            severity: 'success',
          });
        } else {
          setPopUpIsopen(false);
        }
      })
      .catch((e) => {
        setSnackbar({
          status: true,
          snackbarMessage: `Not able to linked this data: ${e.networkError.result.errors[0].message}`,
          severity: 'error',
        });
        console.error('Could not link the info: ', e.networkError.result);
      });
  };
  const handleChangeFormat = (event) => {
    setFormat(event.target.value);
  };
  return (
    <>
      <Typography className={classes.title}>Import external data</Typography>
      <Box sx={{ display: 'flex', gap: 5, alignItems: 'center', height: '60px' }}>
        <Typography variant="h6" className={classes.subtitle}>
          Upload an CSV file to import the data
        </Typography>
        <FormControl variant="standard" sx={{ flex: 1 }}>
          <InputLabel id="date-format-select-label">Set the date format</InputLabel>
          <Select
            labelId="date-format-label"
            id="date-format-select"
            value={format}
            label="Date Format"
            onChange={handleChangeFormat}
          >
            <MenuItem value={'en-GB'}>en-GB</MenuItem>
            <MenuItem value={'en-US'}>en-US</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <PopUp
        title="IMPORT REPORT"
        isOpen={popUpIsopen}
        close={onPopUpClose}
        action={pushCSVData}
      >
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          The File you uploaded has {importData.length - 1} Records
        </Typography>
        <SimpleTable data={importData}></SimpleTable>
      </PopUp>
      <DropzoneArea
        dropzoneParagraphClass={classes.dropzoneParagraphClass}
        dropzoneClass={classes.dropzone}
        showPreviewsInDropzone={false}
        onChange={handleCSV}
        filesLimit={1}
        maxFileSize={1000000}
        acceptedFiles={['text/csv']}
        Icon={() => {
          return <FontAwesomeIcon icon="fa-file-upload" color="#436072" size={42} />;
        }}
      />

      <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
    </>
  );
};

export default CSVImport;
