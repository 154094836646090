import { Dispatch, SetStateAction, SyntheticEvent, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { useFormContext } from 'react-hook-form';
import { Filter, Template } from './ReportingSideBar';
import FontAwesomeIcon from '../../components/FAIcon';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.common.greyblueDark
      : theme.palette.common.greyblue,
  borderRadius: 'none',
  '& input': {
    color: theme.palette.common.white,
  },
  '& fieldset': {
    border: 'none',
  },
  '& .MuiButtonBase-root': {
    color: theme.palette.common.white,
  },
}));

interface Props {
  options: Template[];
  selectedTemplate: Template | null;
  setSelectedTemplate: Dispatch<SetStateAction<Template | null>>;
  setFilters: Dispatch<SetStateAction<Filter[]>>;
  handleDeleteTemplate: (id: string) => void;
}

function groupByType(array: Template[]) {
  const groups: { [key in keyof Template]: Template[] } = array.reduce((result, item) => {
    const type = item.type as keyof Template;
    if (!result[type]) result[type] = [];
    result[type].push(item);
    return result;
  }, {} as { [key in keyof Template]: Template[] });

  return Object.values(groups).flat();
}

const TemplateSelector = (props: Props) => {
  const {
    register,
    clearErrors,
    formState: { errors },
    setValue,
  } = useFormContext();
  const fieldId = 'templateSelector';

  const handleOnChange = (e: SyntheticEvent<Element, Event>, value: any) => {
    if (!value) {
      setValue('selectedTemplate', '');
      return props.setSelectedTemplate(value as Template);
    }
    clearErrors('field-selector');
    setValue('selectedTemplate', value);
    props.setSelectedTemplate(value as Template);
    props.setFilters((value as Template).variables.filters);
  };

  const isOptionEqualToValue = (option: unknown, value: unknown) => {
    const opt = option as Template;
    const val = value as Template;
    return opt.id === val.id;
  };

  const groupedOptions = useMemo(() => groupByType(props.options), [props.options]);

  return (
    <Stack width="100%">
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? 'common.greyblueDarker'
              : 'common.greyblueDark',
          padding: '5px 10px',
        }}
      >
        <Typography sx={{ color: 'common.white', textTransform: 'uppercase' }}>
          Select a Template
        </Typography>
      </Box>
      <StyledAutocomplete
        id="field-selector"
        options={groupedOptions}
        value={props.selectedTemplate}
        groupBy={(option) => (option as Template).type.replace('_', ' ')}
        renderGroup={(params) => (
          <Box key={params.key}>
            <Typography
              variant="button"
              sx={{
                backgroundColor: 'common.greyblueDarker',
                color: 'common.white',
                display: 'block',
                textAlign: 'center',
              }}
            >
              {params.group}
            </Typography>
            <Box>{params.children}</Box>
          </Box>
        )}
        renderOption={(renderProps, option) => (
          <li
            {...renderProps}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '95%',
              }}
            >
              {option?.label}
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  props.handleDeleteTemplate(option?.id);
                }}
                edge="end"
                aria-label="delete"
              >
                <FontAwesomeIcon icon="fas fa-trash" size={10} />
              </IconButton>
            </Box>
          </li>
        )}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register(fieldId)}
            error={errors && errors[fieldId] ? true : false}
            helperText={errors[fieldId] && 'Select an option'}
            sx={{
              '& .MuiFormHelperText-root': {
                backgroundColor: 'common.white',
                width: '100%',
                margin: 0,
              },
            }}
          />
        )}
        onChange={handleOnChange}
        ListboxProps={{ style: { padding: '0' } }}
      />
    </Stack>
  );
};

export default TemplateSelector;
