import {
  Modal,
  Box,
  Typography,
  Button,
  MenuItem,
  InputLabel,
  styled,
  Theme,
} from '@mui/material';
import { StyledSelect as Select } from '../../form/StyledSelect';
import FontAwesomeIcon from '../../FAIcon';
import RegularExpressionAcord from './RegularExpressionAcord';
import CalculatedPropertyAcord from './CalculatedPropertyAcord';
import { generateScrollbar } from '../../theme';
import { StyledTextField } from '../StyledComponents';
import { useState } from 'react';
import FixedValue from './FixedValue';
import _ from 'lodash';
import AlertModal from '../../modal/AlertModal';
import { useOCRTemplate } from '../../../hooks/OCRTemplateSetup/useOCRTemplate';
import { FolderFields } from '../../../containers/folder/types';

interface AdvancedSettingsModalProps {
  open: boolean;
  handleClose: () => void;
  field: any;
  ocrTemplateDetails: any;
  ocrTemplate: any;
  folderFields: FolderFields[] | undefined;
}

export function deleteTypename(obj: any, match: string) {
  if (obj === null || obj === undefined) return;
  obj[match] && delete obj[match];
  for (let v of Object.values(obj)) {
    if (typeof v === 'object') deleteTypename(v, match);
  }
  return obj;
}

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.common.greyblue,
  fontSize: 12,
  fontWeight: 700,
  marginLeft: '2px',
  textTransform: 'uppercase',
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: '180px',
  '& .MuiSelect-select': {
    border: 'none',
    backgroundColor:
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.lightblue,
  },
  '.MuiOutlinedInput-input': { padding: '4px 10px' },
  '& .MuiSvgIcon-root': {
    transform: 'scale(0.7)',
    fill: '#8399A8',
  },
  '& fieldset': { border: 'none' },
}));

const styles = {
  header: {
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#3B4951' : theme.palette.common.greyblueDark,
    p: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.common.lightblue
        : theme.palette.common.white,
    fontSize: 12,
  },
  box: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 700,
    boxShadow: 24,
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#26292E' : theme.palette.common.white,
  },
  textfield: {
    width: '180px',
    '.MuiOutlinedInput-input': { padding: '4px 10px', border: 'none' },
    '& .MuiInputBase-root': {
      border: 'none',
      color: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.greyblueDarker,
    },
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.lightblue,
    '& fieldset': { border: 'none' },
  },
  summaryTitle: {
    fontSize: 11,
    color: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.common.greyblue
        : theme.palette.common.white,
  },
  summary: {
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.greyblueDarker,
    minHeight: '38px!important',
    height: '38px',
  },
  details: {
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#26292E' : theme.palette.common.white,
    border: 'none',
    padding: '15px 30px',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    p: 2,
  },
  footerBtn: {
    backgroundColor: 'common.positive',
    color: 'common.white',
    '&:hover': { backgroundColor: 'common.positiveDark' },
  },
};

const menuItems = {
  pageToFindValue: [
    { value: 'FRONT', label: 'Front page only' },
    { value: 'ANY', label: 'Any page' },
    { value: 'BACK', label: 'Only back page' },
  ],
  dateFormat: [
    {
      value: 'NONE',
      label: 'dd/mm/yyyy',
    },
    {
      value: 'DATE_MDY',
      label: 'mm/dd/yyyy',
    },
    {
      value: 'DATE_YMD',
      label: 'yyyy/mm/dd',
    },
    {
      value: 'DATE_YDM',
      label: 'yyyy/dd/mm',
    },
  ],
  currencyFormat: [
    {
      value: 'NONE',
      label: 'NONE',
    },
    {
      value: 'CURRENCY_EU',
      label: 'CURRENCY_EU',
    },
  ],
};

const AdvancedSettingsModal = ({
  open,
  handleClose,
  field,
  ocrTemplateDetails,
  ocrTemplate,
  folderFields,
}: AdvancedSettingsModalProps) => {
  const definitions = ocrTemplateDetails?.templateOcrFieldDefinitions?.find(
    (ff: any) => ff.folderField === field.id
  );
  const { updateTemplateFieldDefinitions } = useOCRTemplate();
  const [settings, setSettings] = useState(definitions?.textractRequirements || {});
  const [openAlert, setOpenAlert] = useState(false);
  const scrollbar = generateScrollbar('3px', '3px');

  const handleChangeSettings = (key: string, value: any) => {
    setSettings((settings: any) => {
      let res = _.set(settings, key, value);
      return { ...res };
    });
  };

  const checkIfHasChangesBeforeClose = () => {
    let isEqual = _.isEqual(settings, definitions?.textractRequirements);
    return isEqual ? handleClose() : setOpenAlert(true);
  };

  const handleCloseAlertModal = () => setOpenAlert(false);

  const handleSave = async () => {
    const definitionsWithoutIdKey = deleteTypename(definitions, '_id');
    const settingsWithoutIdKey = deleteTypename(settings, '_id');
    console.log(settingsWithoutIdKey);
    /*  const settingsWithoutCalculatedKey = deleteTypename(
      settingsWithoutIdKey,
      'calculated_Value'
    );

    delete settingsWithoutCalculatedKey.calculated_Value; */
    const variables = {
      templateId: (ocrTemplate?._id as string) || (ocrTemplate?.id as string),
      ocrFieldDefinitions: [
        {
          ...definitionsWithoutIdKey,
          folderField: field.id,
          textractRequirements: settingsWithoutIdKey,
        },
      ] as any,
    };

    if (!variables) return;

    await updateTemplateFieldDefinitions(variables);
    handleClose();
  };

  return (
    <Modal open={open} onClose={checkIfHasChangesBeforeClose} sx={{ padding: 0 }}>
      <>
        <Box sx={styles.box}>
          <Box sx={styles.header}>
            <Typography variant="button" sx={styles.title}>
              Advanced Settings
            </Typography>
            <Button sx={{ minWidth: '10px' }} onClick={checkIfHasChangesBeforeClose}>
              <FontAwesomeIcon icon="fas fa-times" size={15} color="common.white" />
            </Button>
          </Box>
          <Box
            sx={{
              padding: '20px 30px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <StyledLabel>PAGE TO READ</StyledLabel>
                <StyledSelect
                  disableUnderline
                  value={settings.headerValuePair?.[0]?.pageToFindValue}
                  onChange={(e) =>
                    handleChangeSettings(
                      'headerValuePair[0].pageToFindValue',
                      e.target.value
                    )
                  }
                  size="small"
                >
                  {menuItems['pageToFindValue'].map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Box>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <StyledLabel>FALLBACK VALUE</StyledLabel>
                <StyledTextField
                  size="small"
                  fullWidth
                  sx={styles.textfield as any}
                  value={settings.default_Value || ''}
                  onChange={(e) => handleChangeSettings('default_Value', e.target.value)}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <StyledLabel>FIXED VALUE</StyledLabel>
                <FixedValue
                  handleChangeSettings={handleChangeSettings}
                  field={field}
                  generalStyles={styles}
                  settings={settings}
                />
              </Box>
              {field.type === 'datetime' ? (
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                  <StyledLabel>DATE FORMAT</StyledLabel>
                  <StyledSelect
                    disableUnderline
                    value={settings.headerValuePair?.[0]?.specialProcessing}
                    onChange={(e) =>
                      handleChangeSettings(
                        'headerValuePair[0].specialProcessing',
                        e.target.value
                      )
                    }
                    size="small"
                  >
                    {menuItems['dateFormat'].map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Box>
              ) : field.type === 'currency' ? (
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                  <StyledLabel>CURRENCY FORMAT</StyledLabel>
                  <StyledSelect
                    disableUnderline
                    value={settings.headerValuePair?.[0]?.specialProcessing}
                    onChange={(e) =>
                      handleChangeSettings(
                        'headerValuePair[0].specialProcessing',
                        e.target.value
                      )
                    }
                    size="small"
                  >
                    {menuItems['currencyFormat'].map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box sx={{ overflow: 'auto', maxHeight: '435px', ...scrollbar }}>
            <CalculatedPropertyAcord
              generalStyles={styles}
              disabled={
                field.type !== 'currency' ||
                settings.predefined_Value!! ||
                settings.postRegexProcessing!!
              }
              folderFields={folderFields}
              handleChangeSettings={handleChangeSettings}
              settings={settings}
            />
            <RegularExpressionAcord
              generalStyles={styles}
              disabled={
                settings.predefined_Value!! ||
                (settings.calculated_Value &&
                  settings.calculated_Value?.formulaSteps?.length)
              }
              handleChangeSettings={handleChangeSettings}
              settings={settings}
            />
          </Box>
          <Box sx={styles.footer} onClick={handleSave}>
            <Button sx={styles.footerBtn}>Save</Button>
          </Box>
        </Box>
        <AlertModal
          message="You have made changes, if you close this screen you will lose them, are you
              sure you want to continue?"
          title="Are you sure?"
          open={openAlert}
          handleCloseAlertModal={handleCloseAlertModal}
          handleConfirm={() => {
            handleCloseAlertModal();
            handleClose();
          }}
        />
      </>
    </Modal>
  );
};

export default AdvancedSettingsModal;
