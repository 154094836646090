import {
  Box,
  Button,
  CardActions,
  CardContent,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { Row } from './IdentifierConfigModal';
import { ParseResult } from 'papaparse';

const createStyles = (theme: Theme) => ({
  content: {
    minHeight: '500px',
    maxHeight: '600px',
    maxWidth: '1000px',
    minWidth: { xs: '80vw', md: '600px' },
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
  },
  formattedValue: {
    width: '180px',
    fontSize: '12px',
    fontWeight: 600,
  },
  select: {
    width: '150px',
    '& .MuiSelect-select': {
      padding: '5px',
      backgroundColor: theme.palette.mode === 'light' ? 'common.lightblue' : '#2D333A',
      color: 'common.greyblue',
      fontWeight: 600,
      paddingLeft: '10px',
      textTransform: 'uppercase',
    },
    '& .MuiSvgIcon-root': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.common.greyblueDarker
          : theme.palette.common.orange,
    },
  },
  actions: { justifyContent: 'space-between', paddingTop: '20px' },
  header: { width: '180px', color: 'common.greyblue', fontWeight: 700 },
  centerGap: { display: 'flex', alignItems: 'center', gap: '10px' },
  box: { gap: '10px', display: 'flex', flexDirection: 'column' },
});

type SelectedHeaders = {
  code: null | string;
  name: null | string;
};

const ConfigCSVData = ({
  csv,
  setCsv,
  handleClose,
  setRows,
}: {
  csv: ParseResult<any> | null;
  setCsv: React.Dispatch<React.SetStateAction<ParseResult<any> | null>>;
  handleClose: () => void;
  setRows: React.Dispatch<React.SetStateAction<Row[]>>;
}) => {
  const theme = useTheme();
  const styles = createStyles(theme);
  const [selectedHeaders, setSelectedHeaders] = useState<SelectedHeaders>({
    code: null,
    name: null,
  });

  const handleSave = () => {
    const codeCounter: { [code: string]: number } = {};
    const updatedCsv: Row[] | undefined = csv?.data.slice(1).map((row) => {
      const updatedRow: Partial<Row> = { id: Number(new Date()) };
      const keys = Object.keys(selectedHeaders);
      keys.forEach((key) => {
        if (key === 'code') {
          const codeIndex = csv?.data[0].indexOf(
            selectedHeaders[key as keyof SelectedHeaders]
          );
          let code = row[codeIndex];
          while (codeCounter[code]) {
            // If this code is repeated, we increase the counter to generate a unique code. Ex: code; code(1); code(1)(1); code(1)(2)
            code += `(${codeCounter[code]})`;
            codeCounter[code]++;
          }
          codeCounter[code] = 1;
          updatedRow[key as keyof Row] = code;
        } else {
          const index = csv?.data[0].indexOf(
            selectedHeaders[key as keyof SelectedHeaders]
          );
          updatedRow[key as keyof Row] = row[index];
        }
      });
      return { ...updatedRow } as Row;
    });
    if (updatedCsv) {
      setRows((prev) => [...prev, ...updatedCsv]);
      setCsv(null);
    }
  };

  const handleChange = (value: string | null, key: string) => {
    setSelectedHeaders((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Box sx={{ padding: '10px' }}>
      <CardContent sx={styles.content}>
        <Box sx={styles.box}>
          <Box sx={{ display: 'flex', padding: '0px 20px' }}>
            {csv?.data[0].map((header: string) => (
              <Typography sx={styles.header}>{header.toUpperCase()}</Typography>
            ))}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {csv?.data.map((value, index) => {
              return index === 0 ? null : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    borderRadius: '4px',
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? index % 2 === 0
                          ? 'common.lightblue'
                          : 'transparent'
                        : index % 2 === 0
                        ? '#2D333A'
                        : 'transparent',
                    padding: '10px 20px',
                  }}
                >
                  {value.map((formattedValue: string) => (
                    <Typography
                      sx={{
                        ...styles.formattedValue,
                        color: (theme) =>
                          theme.palette.mode === 'light'
                            ? index % 2 === 0
                              ? 'common.greyblueDark'
                              : 'common.greyblue'
                            : index % 2 === 0
                            ? 'common.greyblue'
                            : 'common.white',
                      }}
                    >
                      {formattedValue}
                    </Typography>
                  ))}
                </Box>
              );
            })}
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={styles.actions}>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Box sx={styles.centerGap}>
            <Typography variant="button" sx={{ color: 'common.greyblue' }}>
              Code
            </Typography>
            <Select
              sx={styles.select}
              size="small"
              variant="filled"
              disableUnderline
              value={selectedHeaders.code}
              onChange={(e) => {
                handleChange(e.target.value, 'code');
              }}
            >
              {csv?.data[0]?.map((header: string) => (
                <MenuItem value={header}>{header}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={styles.centerGap}>
            <Typography variant="button" sx={{ color: 'common.greyblue' }}>
              Name
            </Typography>
            <Select
              sx={styles.select}
              size="small"
              variant="filled"
              disableUnderline
              value={selectedHeaders.name}
              onChange={(e) => {
                handleChange(e.target.value, 'name');
              }}
            >
              {csv?.data[0].map((header: string) => (
                <MenuItem value={header}>{header}</MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: 'common.positive',
              '&:hover': { backgroundColor: 'common.positiveDark' },
            }}
            size="small"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: 'common.negative',
              '&:hover': { backgroundColor: 'common.negativeDark' },
              marginLeft: '10px',
            }}
            size="small"
          >
            Cancel
          </Button>
        </Box>
      </CardActions>
    </Box>
  );
};

export default ConfigCSVData;
