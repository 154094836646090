import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  TextField,
  Typography,
  Button,
  Checkbox,
} from '@mui/material';
import React, { useState } from 'react';

interface TemplateDialogProps {
  handleSaveTemplate: (name: string, applyToFolder: boolean) => void;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TemplateDialog = ({
  handleSaveTemplate,
  openModal,
  setOpenModal,
}: TemplateDialogProps) => {
  const [error, setError] = useState({ status: false, message: '' });
  const [applyToFolder, setApplyToFolder] = useState(false);
  const [templateName, setTemplateName] = useState<string>('');
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      BackdropProps={{ sx: { backgroundColor: 'rgb(0 0 0 / 22%)' } }}
    >
      <DialogTitle sx={{ width: '350px', paddingLeft: '20px' }}>
        Template name
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ padding: '0 20px' }}>
          <TextField
            autoFocus
            error={error.status}
            helperText={error.status && error.message}
            margin="dense"
            label="Enter template name"
            onChange={(e) => {
              if (error.status) setError({ status: false, message: '' });
              setTemplateName(e.target.value);
            }}
            type="text"
            fullWidth
            required={true}
            variant="standard"
            sx={{
              '& label.Mui-focused': {
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'common.blueGrey.100'
                    : 'common.greyblueDarker',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'common.white'
                    : 'common.greyblueDarker',
              },
              '& .MuiFormHelperText-root': { fontSize: 11 },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: '30px',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: (theme) =>
                applyToFolder && theme.palette.mode === 'dark'
                  ? 'common.white'
                  : theme.palette.mode === 'dark'
                  ? 'common.blueGrey.100'
                  : applyToFolder
                  ? 'common.greyblueDarker'
                  : 'common.greyblueDark',
            }}
          >
            Assign this template to folder
          </Typography>
          <Checkbox
            onChange={(e) => setApplyToFolder(e.target.checked)}
            checked={applyToFolder}
            // sx={{
            //   color: 'common.greyblueDark',
            //   '&.Mui-checked': { color: 'common.greyblueDarker' },
            // }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseModal}
          size="small"
          variant="outlined"
          sx={{
            p: '2px',
            fontSize: 12,
            borderColor: (theme) =>
              theme.palette.mode === 'dark' ? 'common.blueGrey.200' : 'greyblueDark',
            '&:hover': {
              borderColor: (theme) =>
                theme.palette.mode === 'dark' ? 'common.blueGrey.400' : 'greyblueDarker',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={templateName.length === 0 ? true : false}
          variant="contained"
          name="save"
          onClick={() => {
            handleSaveTemplate(templateName, applyToFolder);
            setOpenModal(false);
          }}
          size="small"
          sx={{
            // backgroundColor: 'common.greyblueDarker',
            // '&:hover': { backgroundColor: 'common.greyblueDark' },
            p: '2px',
            fontSize: 12,
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateDialog;
