import { Dispatch } from 'react';
import utils from '../utils/utils';
import { StatusContextAction, StatusContextState } from './statusContextTypes';

const getInitialThemeMode = () => {
  let theme = localStorage.getItem('theme');
  let paletteModeLS = JSON.parse(theme || 'null');

  let res = paletteModeLS
    ? paletteModeLS
    : window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
  return res;
};

const statusReducer = (state: StatusContextState, action: StatusContextAction) => {
  switch (action.type) {
    case 'refreshToCollectionBin':
      window.dispatchEvent(new Event('storageChange'), { bubbles: true });
      if (!action.payload) {
        return {
          ...state,
        };
      } else {
        let payload = action.payload.length;
        return {
          ...state,
          collectionBinCount: payload,
        };
      }
    case 'setScreen':
      return {
        ...state,
        screen: action.payload,
      };
    case 'saveScanned':
      return {
        ...state,
        scanned: action.payload,
      };
    case 'setVisible':
      return {
        ...state,
        visible: action.payload,
      };
    case 'setViewer':
      return {
        ...state,
        viewer: action.payload,
      };
    case 'setSelectedContextFolder':
      let newState = { ...state.selectedFolder, ...action.payload };
      window.log('selectedFolder', newState);
      return {
        ...state,
        selectedFolder: { ...newState },
      };
    case 'setPaletteMode':
      return {
        ...state,
        paletteMode: action.payload,
      };
    case 'setErrorStatus':
      return {
        ...state,
        error: action.payload,
      };
    case 'setSelectedOrg':
      return {
        ...state,
        selectedOrg: action.payload,
      };
    case 'setSelectedRecord':
      return {
        ...state,
        selectedRecord: action.payload,
      };
    case 'setSnackbar':
      return {
        ...state,
        snackbar: action.payload,
      };
    case 'setWorkflow':
      return {
        ...state,
        workflow: action.payload,
      };
    case 'setCurrentOrg':
      return {
        ...state,
        currentOrg: action.payload,
      };
    default:
      return state;
  }
};

const CheckCollectionBin = (dispatch: Dispatch<StatusContextAction>) => () => {
  try {
    dispatch({});
  } catch (err) {
    window.log(err);
  }
};

const RefreshCollectionBin = (dispatch: Dispatch<StatusContextAction>) => () => {
  try {
    let collection = JSON.parse(localStorage.getItem('collectionBin'));
    dispatch({ type: 'refreshToCollectionBin', payload: collection });
  } catch (err) {
    window.log(err);
  }
};

const setScreen =
  (dispatch: Dispatch<StatusContextAction>) => (screen: StatusContextState['screen']) => {
    try {
      dispatch({ type: 'setScreen', payload: screen });
    } catch (err) {
      window.log(err);
    }
  };

const saveScanned =
  (dispatch: Dispatch<StatusContextAction>) =>
  (scanned: StatusContextState['scanned']) => {
    try {
      dispatch({ type: 'saveScanned', payload: scanned });
    } catch (err) {
      window.log(err);
    }
  };

const setVisible =
  (dispatch: Dispatch<StatusContextAction>) =>
  (visible: StatusContextState['visible']) => {
    try {
      dispatch({ type: 'setVisible', payload: visible });
    } catch (err) {
      window.log(err);
    }
  };

const setViewer =
  (dispatch: Dispatch<StatusContextAction>) => (viewer: StatusContextState['viewer']) => {
    try {
      dispatch({ type: 'setViewer', payload: viewer });
    } catch (err) {
      window.log(err);
    }
  };

const setSelectedContextFolder =
  (dispatch: Dispatch<StatusContextAction>) =>
  (folder: StatusContextState['selectedFolder']) => {
    try {
      dispatch({ type: 'setSelectedContextFolder', payload: folder });
    } catch (err) {
      window.log(err);
    }
  };

const setErrorStatus =
  (dispatch: Dispatch<StatusContextAction>) => (error: StatusContextState['error']) => {
    try {
      dispatch({ type: 'setErrorStatus', payload: error });
    } catch (err) {
      window.log(err);
    }
  };

const setSelectedOrg =
  (dispatch: Dispatch<StatusContextAction>) =>
  (org: StatusContextState['selectedOrg']) => {
    try {
      dispatch({ type: 'setSelectedOrg', payload: org });
    } catch (err) {
      window.log(err);
    }
  };

const setSelectedRecord =
  (dispatch: Dispatch<StatusContextAction>) =>
  (record: StatusContextState['selectedRecord']) => {
    try {
      dispatch({ type: 'setSelectedRecord', payload: record });
    } catch (err) {
      window.log(err);
    }
  };

const setSnackbar =
  (dispatch: Dispatch<StatusContextAction>) =>
  (status: StatusContextState['snackbar']) => {
    try {
      dispatch({ type: 'setSnackbar', payload: status });
    } catch (err) {
      window.log(err);
    }
  };

const setWorkflow =
  (dispatch: Dispatch<StatusContextAction>) =>
  (status: StatusContextState['workflow']) => {
    try {
      dispatch({ type: 'setWorkflow', payload: status });
    } catch (err) {
      window.log(err);
    }
  };
const setCurrentOrg =
  (dispatch: Dispatch<StatusContextAction>) =>
  (status: StatusContextState['currentOrg']) => {
    try {
      dispatch({ type: 'setCurrentOrg', payload: status });
    } catch (err) {
      window.log(err);
    }
  };

const setPaletteMode =
  (dispatch: Dispatch<StatusContextAction>) =>
  (mode: StatusContextState['paletteMode']) => {
    try {
      dispatch({ type: 'setPaletteMode', payload: mode });
    } catch (err) {
      window.log(err);
    }
  };

export const { Provider, Context } = utils.createDataContext(
  statusReducer,
  {
    CheckCollectionBin,
    RefreshCollectionBin,
    setScreen,
    saveScanned,
    setVisible,
    setViewer,
    setSelectedContextFolder,
    setErrorStatus,
    setSelectedOrg,
    setSelectedRecord,
    setSnackbar,
    setWorkflow,
    setCurrentOrg,
    setPaletteMode,
  },
  {
    collectionBinCount: 0,
    // localStorage.getItem('collectionBin') || []
    //   ? JSON.parse(localStorage.getItem('collectionBin')).length
    //   : 0,
    screen: '',
    scanned: [],
    visible: false,
    selectedFolder: {
      search: '',
      indexing: '',
    },
    viewer: {
      scale: 1,
      ofset: {
        top: 0,
        left: 0,
      },
    },
    error: {
      errorInfo: null,
      triggered: false,
      message: null,
    },
    selectedOrg: null,
    currentOrg: null,
    selectedRecord: null,
    snackbar: {
      status: false,
      snackbarMessage: '',
      severity: 'success',
    },
    workflow: true,
    paletteMode: getInitialThemeMode(),
  }
);
