import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import FontAwesomeIcon from '../../FAIcon';
import { Typography, Box } from '@mui/material';
import ErrorHandler from '../../../utils/errorHandler';

const NoRowOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: 'column',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));
const CustomNoRowOverlay = ({ screen }: { screen?: string }) => {
  return (
    <ErrorHandler>
      <NoRowOverlay>
        {screen === 'indexing' ? null : (
          <Box sx={{ zIndex: 99, textAlign: 'center', color: '#ccc' }}>
            <FontAwesomeIcon icon="fas fa-th-list" size={54} color="inherit" />
            <Typography>No rows found.</Typography>
          </Box>
        )}
      </NoRowOverlay>
    </ErrorHandler>
  );
};
export default CustomNoRowOverlay;
