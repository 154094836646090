import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FieldMainInputsProps } from '../../types';
import { SecondStyledTexfield } from '../../../../components/form/StyledTextfield';
import { StyledSelect } from '../../../../components/form/StyledSelect';

export default function FieldMainInputs({
  fieldUpdates,
  setFieldUpdates,
  setSelectedField,
  selectedField,
  folder,
}: FieldMainInputsProps) {
  if (!selectedField) return null;
  // It is possible that some folder fields already created in previous versions don't have the key 'indexRequirements'
  // In case it don't exist, we add a value to it
  const isMandatory: boolean = selectedField.indexRequirements?.isMandatory || false;

  const regexValue: string = selectedField.indexRequirements?.regexMatch || '';
  const barcodeRegexValue: string = selectedField.barcodeRequirements?.regexMatch || '';

  return (
    <Grid container spacing={2} marginTop={0.1}>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <SecondStyledTexfield
            label="Field Name"
            name="name"
            type="text"
            value={selectedField.name || ''}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value;
              setFieldUpdates({
                ...fieldUpdates,
                name: value,
              });
              setSelectedField((prev) => prev && { ...prev, name: value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {fieldUpdates && fieldUpdates.type && (
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="dataType-outlined-label">Data Type</InputLabel>
              <StyledSelect
                label="Data Type"
                inputProps={{
                  name: 'dataType',
                  id: 'dataType-outlined-label',
                }}
                value={selectedField.type || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldUpdates({
                    ...fieldUpdates,
                    type: value,
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem key="boolean" value="boolean">
                  Boolean
                </MenuItem>
                <MenuItem key="currency" value="currency">
                  Currency
                </MenuItem>
                <MenuItem key="datetime" value="datetime">
                  DateTime
                </MenuItem>
                <MenuItem key="integer" value="integer">
                  Integer
                </MenuItem>
                <MenuItem key="string" value="string">
                  Text
                </MenuItem>
                <MenuItem key="dropdown" value="dropdown">
                  Drop Down List
                </MenuItem>
              </StyledSelect>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <SecondStyledTexfield
            label="Regex Match"
            name="regexMatch"
            type="text"
            value={regexValue}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value;
              setFieldUpdates({
                ...fieldUpdates,
                indexRequirements: {
                  isMandatory,
                  regexMatch: value,
                },
              });
              setSelectedField(
                (prev) =>
                  prev && {
                    ...prev,
                    indexRequirements: {
                      ...prev.indexRequirements,
                      regexMatch: value,
                    },
                  }
              );
            }}
          />
        </Grid>
      </Grid>
      {folder.scanning && (
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <SecondStyledTexfield
              label="Barcode Regex Match"
              name="barcodeRegexMatch"
              type="text"
              value={barcodeRegexValue}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value;
                setFieldUpdates({
                  ...fieldUpdates,
                  barcodeRequirements: {
                    ...(fieldUpdates.barcodeRequirements || {}),
                    regexMatch: value,
                  },
                });
                setSelectedField(
                  (prev) =>
                    prev && {
                      ...prev,
                      barcodeRequirements: {
                        ...prev.barcodeRequirements,
                        regexMatch: value,
                      },
                    }
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
