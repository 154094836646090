import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { LOGOUT } from '../utils/gql';
import { useHistory } from 'react-router-dom';
import utils from '../utils/utils';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Context as StatusContext } from '../context/statusContext';
import { Context as RTCContext } from '../context/RTCContext';
import { Context as tasksContext } from '../context/tasksContext';
import FontAwesomeIcon from '../components/FAIcon';
import { clearIndexedDB } from '../utils/indexedDB/clearIndexedDB';
import { StatusContextTypes } from '../context/statusContextTypes';
import { RTCContextTypes } from '../context/RTCContextTypes';
import { TasksContextTypes } from '../context/tasksContextTypes';

const classes = {
  listItemText: {
    '& .MuiTypography-root': {
      color: '#fff',
      fontWeight: 500,
      fontSize: 14,
    },
  },
};

interface LogoutProps {
  title: string;
  sideDrawerToggled: boolean;
  color: string;
}

const Logout = ({ title, sideDrawerToggled = false, color }: LogoutProps) => {
  const history = useHistory();
  const [logoutUser] = useMutation(LOGOUT);
  const { setVisible, state } = useContext(StatusContext) as StatusContextTypes;
  const { clearTasksNotifications } = useContext(RTCContext) as RTCContextTypes;
  const { clearTasks } = useContext(tasksContext) as TasksContextTypes;
  const logout = async () => {
    logoutUser()
      .then(({ data }) => {
        let userData = window.localStorage.getItem('userData');
        let currentStorage = JSON.parse(userData || '');
        if (currentStorage.suser) {
          utils.clearUserData();
          history.push('/');
          clearIndexedDB();
          clearTasksNotifications();
          clearTasks();
          setVisible(false);
          window.location.reload();
        } else {
          utils.clearUserData();
          window.localStorage.setItem('isLoggedIn', 'false');
          window.localStorage.setItem('theme', JSON.stringify(state.paletteMode));
          history.push('/');
          clearIndexedDB();
          clearTasksNotifications();
          clearTasks();
          setVisible(false);
          window.location.reload();
        }
      })
      .catch((e) => window.log(e));
  };
  return (
    <>
      <ListItem button onClick={() => logout()} key={title}>
        <ListItemIcon sx={{ minWidth: '41px' }}>
          <FontAwesomeIcon icon="fa-solid fa-power-off" color={color} size={16} />
        </ListItemIcon>
        <ListItemText primary={title} sx={classes.listItemText} />
      </ListItem>
    </>
  );
};
export default Logout;
