import { gql } from 'apollo-boost';

export const OCR_TEMPLATE_PAYLOAD = gql`
  fragment OcrTemplatePayload on OcrTemplate {
    id
    templateIdentifiers {
      identifierText
    }
    templateName
    folderId
    templateDetails {
      templateOcrFieldDefinitions {
        folderField
        textractRequirements {
          regexMatch
          blockType
          textType
          boundingBox {
            top
            left
            height
            width
          }
          confidenceMinimum
          default_Value
          predefined_Value
          calculated_Value {
            formulaSteps {
              stepNumber
              stepType
              value
            }
          }
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
          headerValuePair {
            textToFind
            textType
            blockType
            textToFindConfidenceMinimum
            specialProcessing
            boxToFindValue {
              top
              left
              height
              width
            }
            pointToDrawValueBox {
              top
              left
            }
          }
        }
      }
    }
    folderFieldForTemplateName
    imageUsedAsTemplate
    textractId
  }
`;

export const GET_OCR_TEMPLATES = gql`
  ${OCR_TEMPLATE_PAYLOAD}
  query {
    OcrTemplates {
      ocrTemplates {
        ...OcrTemplatePayload
      }
      error
    }
  }
`;

export const GET_OCR_TEMPLATE_BY_ID = gql`
  ${OCR_TEMPLATE_PAYLOAD}
  query OcrTemplates($folderId: ID!) {
    OcrTemplates(folderId: $folderId) {
      ocrTemplates {
        ...OcrTemplatePayload
      }
      error
    }
  }
`;

export const CREATE_OCR_TEMPLATE = gql`
  ${OCR_TEMPLATE_PAYLOAD}
  mutation CreateOcrTemplate($ocrTemplate: CreateOcrTemplateInput) {
    CreateOcrTemplate(ocrTemplate: $ocrTemplate) {
      ocrTemplate {
        ...OcrTemplatePayload
      }
      error
    }
  }
`;

export const UPDATE_OCR_TEMPLATE = gql`
  ${OCR_TEMPLATE_PAYLOAD}
  mutation UpdateOcrTemplate($id: ID!, $ocrTemplateUpdates: UpdateOcrTemplateInput) {
    UpdateOcrTemplate(id: $id, ocrTemplateUpdates: $ocrTemplateUpdates) {
      ocrTemplate {
        ...OcrTemplatePayload
      }

      error
    }
  }
`;

export const UPDATE_OCR_TEMPLATE_IDENTIFIERS = gql`
  ${OCR_TEMPLATE_PAYLOAD}
  mutation UpdateOcrTemplateIdentifiers(
    $templateId: ID!
    $templateIdentifiers: [TemplateIdentifierInput]!
  ) {
    UpdateOcrTemplateIdentifiers(
      templateId: $templateId
      templateIdentifiers: $templateIdentifiers
    ) {
      ocrTemplate {
        ...OcrTemplatePayload
      }
      error
    }
  }
`;

export const UPDATE_OCR_TEMPLATE_FOLDER_FIELD_DEFINITIONS = gql`
  ${OCR_TEMPLATE_PAYLOAD}
  mutation UpdateOcrTemplateFolderFieldDefinitions(
    $templateId: ID!
    $ocrFieldDefinitions: [UpdateOcrFieldDefinitionsInput!]!
  ) {
    UpdateOcrTemplateFolderFieldDefinitions(
      templateId: $templateId
      ocrFieldDefinitions: $ocrFieldDefinitions
    ) {
      ocrTemplate {
        ...OcrTemplatePayload
      }
      error
    }
  }
`;

export const REMOVE_OCR_TEMPLATE_FOLDER_FIELD_DEFINITIONS = gql`
  ${OCR_TEMPLATE_PAYLOAD}
  mutation RemoveOcrTemplateFolderFieldDefinitions(
    $templateId: ID!
    $folderFieldID: ID!
  ) {
    RemoveOcrTemplateFolderFieldDefinitions(
      templateId: $templateId
      folderFieldID: $folderFieldID
    ) {
      ocrTemplate {
        ...OcrTemplatePayload
      }
      error
    }
  }
`;
export const DELETE_OCR_TEMPLATE = gql`
  ${OCR_TEMPLATE_PAYLOAD}
  mutation DeleteOcrTemplate($id: ID!) {
    DeleteOcrTemplate(id: $id) {
      ocrTemplate {
        ...OcrTemplatePayload
      }
      error
    }
  }
`;
