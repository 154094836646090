import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';

const StyledBtn = styled(Button)<ButtonProps>(({ theme, bgColor }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.common.positive
      : theme.palette.common.positiveDark,
  color: theme.palette.common.white,
  textTransform: 'uppercase',

  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.common.positive
        : theme.palette.common.positiveDark,
    color: theme.palette.common.orange,
  },
}));

const StyledCancelBtn = styled(Button)<ButtonProps>(({ theme, bgColor }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.common.negative
      : theme.palette.common.negativeDark,
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.common.negative
        : theme.palette.common.negativeDark,
    color: theme.palette.common.orange,
  },
}));

function WarningDialog({ open, handleAccept, handleCancel }) {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'common.greyblueDarker',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Closing this modal will terminate the report generation process. Are you sure
          you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledBtn onClick={handleCancel}>Cancel</StyledBtn>
        <StyledCancelBtn onClick={handleAccept} autoFocus>
          Accept
        </StyledCancelBtn>
      </DialogActions>
    </Dialog>
  );
}

export default WarningDialog;
