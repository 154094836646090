import { Box, Stack, Typography } from '@mui/material';
import DateTimeDisplay from './DateTimeDisplay';
import { styled } from '@mui/material/styles';

const Separator = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  paddingBottom: '20px',
}));

interface props {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const ShowCounter = ({ days, hours, minutes, seconds }: props) => {
  return (
    <Stack direction="row" alignItems={'center'} spacing={2}>
      <DateTimeDisplay value={days} type={'Days'} />
      <Separator>:</Separator>
      <DateTimeDisplay value={hours} type={'Hours'} />
      <Separator>:</Separator>
      <DateTimeDisplay value={minutes} type={'Mins'} />
      <Separator>:</Separator>
      <DateTimeDisplay value={seconds} type={'Seconds'} />
    </Stack>
  );
};

export default ShowCounter;
