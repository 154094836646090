import { Route, Switch } from "react-router-dom";
import { StyledFoldersPage } from "../styles/dashStyle";
import FoldersMain from "../containers/folder/FoldersMain";
import FolderPage from "../containers/folder/FolderPage";

function Folders() {
  return (
    <StyledFoldersPage>
      <Switch>
        <Route 
          exact
          path="/dash/folders"
          component={FoldersMain}
        />
        <Route
          exact
          path="/dash/folders/:id"
          component={FolderPage}
        />
      </Switch>
    </StyledFoldersPage>
  );
}

export default Folders;
