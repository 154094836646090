import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FilledInput from '@mui/material/FilledInput';
import useUsers from '../../../hooks/useUsers';
import { Filter } from '../ReportingSideBar';

interface UsersFilterProps {
  filter: Filter;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UsersFilter = ({ filter }: UsersFilterProps) => {
  const { users } = useUsers();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { setValue, watch } = useFormContext();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  useEffect(() => {
    filter.value && setSelectedUsers([filter.value]);
  }, [filter]);

  useEffect(() => {
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex((filter) => filter.id === 'users');
    index !== -1 &&
      setValue(`selectedTemplate.variables.filters[${index}].value`, selectedUsers);
  }, [selectedUsers]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Select
        labelId="users-filter-label"
        id="users-filter-checkbox"
        multiple
        value={selectedUsers}
        onChange={handleChange}
        input={<FilledInput />}
        renderValue={(selected) => {
          if (!users) return;
          const render = selected.map((id) => {
            const user = users.find((user) => user.id === id);
            if (!user) return;
            return `${user.firstName} ${user.lastName}`;
          });
          return render.join(', ');
        }}
        MenuProps={MenuProps}
        sx={{
          '& .MuiSelect-select': {
            color: 'common.greyblueDarker',
            fontSize: 12,
            fontWeight: 500,
          },
          '& .MuiInputBase-input': {
            textAlign: 'end',
            paddingBottom: 1.5,
            paddingTop: '16px',
          },
        }}
      >
        {users &&
          users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              <Checkbox checked={selectedUsers.includes(user.id)} />
              <ListItemText primary={`${user.firstName} ${user.lastName}`} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default UsersFilter;
