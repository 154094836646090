import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
  table: {
    width: '100%',
    overflowX: 'scroll',
  },
  selected: {
    '& :hover': {
      backgroundColor: '#95c6e8',
    },
    backgroundColor: '#95c6e8',
  },
  notselected: {
    backgroundColor: '#f7f7f7',
    '&:hover': {
      backgroundColor: '#e3e3e3',
    },
  },
});

export default function SimpleTable({ rows, selectedRow, action = () => {} }) {
  const classes = useStyles();

  const createHeaders = (rows) => {
    window.log(rows);
    let folders = {};
    let visibleHeaders = [];
    if (rows) {
      rows.forEach((row, i) => {
        if (!folders[row.folderId.name]) {
          folders[row.folderId.name] = { records: [], headers: [] };
        }
        row.folderId.folderFields.forEach((field) => {
          if (!visibleHeaders.includes(field.id)) {
            visibleHeaders.push(field.id);
            folders[row.folderId.name].headers.push(
              <TableCell
                key={field.id}
                style={{
                  display: 'block',
                  maxWidth: 200,
                  minWidth: 110,
                  padding: '8px',
                }}
                align="left"
              >
                {field.name}
              </TableCell>
            );
          }
        });

        folders[row.folderId.name].records.push(
          <TableRow
            key={i + '_record'}
            style={{ display: 'flex' }}
            className={selectedRow === i ? classes.selected : classes.notselected}
            onClick={() => {
              action(i);
            }}
          >
            {visibleHeaders.map((id) => {
              let cells = [];
              let hasField = false;
              for (let i = 0; i < row.fields.length; i++) {
                let fields = row.fields[i];
                if (fields.field.id === id) {
                  let value;
                  if (fields.field.type === 'datetime') {
                    let date = new Date(Number(fields.value));
                    value = date.toLocaleDateString('en-GB');
                  } else {
                    value = fields.value;
                  }
                  cells.push(
                    <TableCell
                      component="div"
                      key={id}
                      align="left"
                      style={{
                        display: 'block',
                        maxWidth: 200,
                        minWidth: 110,
                        padding: '8px',
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                  hasField = true;
                  break;
                } else {
                  hasField = false;
                }
              }
              if (!hasField) {
                cells.push(
                  <TableCell
                    key={i + '_Blank'}
                    style={{
                      display: 'block',
                      maxWidth: 200,
                      minWidth: 110,
                      padding: '8px',
                    }}
                    align="left"
                  ></TableCell>
                );
              }
              return cells;
            })}
          </TableRow>
        );
      });
      for (let folder in folders) {
        return (
          <>
            <TableRow
              style={{
                padding: 10,
                display: 'block',
                color: 'white',
                backgroundColor: '#0B72B9',
              }}
            >
              <Typography variant="caption">{'Folder: ' + folder}</Typography>
            </TableRow>
            <TableHead>
              <TableRow
                style={{
                  display: 'flex',
                }}
              >
                {folders[folder].headers}
              </TableRow>
            </TableHead>
            {folders[folder].records}
          </>
        );
      }
    }
  };

  return (
    <Table stickyHeader className={classes.table}>
      <TableBody>{createHeaders(rows)}</TableBody>
    </Table>
  );
}
