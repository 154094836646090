import { useContext } from 'react';
import { Context as StatusContext } from '../../context/statusContext';
import { Context as AuthContext, AuthContextTypes } from '../../context/authContext';
import { useLocalStorage } from '../useLocalStorage';
import _ from 'lodash';
import { StatusContextTypes } from '../../context/statusContextTypes';

const useUserStorageData = () => {
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const data = useLocalStorage('userData');
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const [screenSelectedData] = useLocalStorage('screenSelectedData');
  const isSuser = authContext.state.suser || suser;

  return { data, isSuser, selOrg, userSettings, setUserSettings, screenSelectedData };
};

export default useUserStorageData;
