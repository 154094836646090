import React, { useState, useContext, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {
  AppBar,
  Typography,
  Toolbar,
  Box,
  useMediaQuery,
  Button,
  IconButton,
} from '@mui/material';
import CollectionBin from './CollectionBin';
import OrganisationSelector from './OrganisationSelector';
import NotificationTray from './navBar/Notification';
import TasksTray from './taskTray/TasksTray';
import RTC from '../utils/socketComm';
import UserMenu from './navBar/UserMenu';
import NewFeatures, { Features } from './newFeatures/NewFeatures';
import NotificationsToolbar from './notificationsToolbar/NotificationsToolbar';
import { Context as StatusContext } from '../context/statusContext';
import FontAwesomeIcon from './FAIcon';
import DebugConsoleMain from './debugConsole/DebugConsoleMain';

const styles = {
  orgSelector: {
    marginRight: 20,
  },
};

interface HeaderProps {
  appversion: keyof Features;
  setappversion: () => void;
}

const Header = ({ appversion, setappversion }: HeaderProps) => {
  const mobile = useMediaQuery('(max-width:600px)');
  const [{ organisations }] = useLocalStorage('userData');
  const [{ showNewFeatures }] = useLocalStorage('userSettings');
  const [anchorCollectionBin, setAnchorCollectionBin] = useState(null);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const [anchorTasksTray, setAnchorTasksTray] = useState(null);
  const [anchorUserMenu, setAnchorUserMenu] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(showNewFeatures);
  const [consoleIsOpen, setConsoleIsOpen] = useState(false);
  const { state } = useContext(StatusContext);
  let socketDebug = process.env.REACT_APP_SOCKET_DEBUG === 'true';

  const handleOpenConsole = () => setConsoleIsOpen(true);
  const handleCloseConsole = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setConsoleIsOpen(false);
  };

  const handleClosePopover = () => {
    if (anchorCollectionBin) setAnchorCollectionBin(null);
    if (anchorNotification) setAnchorNotification(null);
    if (anchorTasksTray) setAnchorTasksTray(null);
    if (anchorUserMenu) setAnchorUserMenu(null);
  };
  const showFolder = () => {
    if (state && state.selectedFolder) {
      switch (state.screen) {
        case 'indexing':
          return state.selectedFolder.indexing ? state.selectedFolder.indexing.name : '';
        case 'search':
          return state.selectedFolder.search ? state.selectedFolder.search.name : '';
        case 'reporting':
          return state.selectedFolder.reporting
            ? state.selectedFolder.reporting.name
            : '';
        case 'record':
          return state.selectedFolder.record ? state.selectedFolder.record.name : '';
      }
    }
  };
  useEffect(() => {
    window.log(showNewFeatures);
  }, [showNewFeatures]);

  return (
    <>
      <NotificationsToolbar />
      <Box
        sx={{
          flexGrow: 1,
          height: '44px',
          flexShrink: 0,
          alignItems: 'center',
          flexDirection: 'row',
          display: 'flex',
          zIndex: 9999,
        }}
        onClick={handleClosePopover}
      >
        <AppBar
          position="relative"
          sx={{
            color: 'white',
            height: '44px',
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.common.blueShadow['700']
                : theme.palette.common.blue,
          }}
        >
          <Toolbar variant="dense" sx={{ minHeight: '43px' }}>
            {socketDebug && (
              <IconButton
                onClick={handleOpenConsole}
                sx={{
                  position: 'absolute',
                  left: -8,
                  backgroundColor: '#000',
                  p: '8px 12px',
                  borderRadius: '50px',
                  '&:hover': {
                    backgroundColor: '#000025',
                  },
                }}
              >
                <FontAwesomeIcon icon="fas fa-code" size={12} color="common.orange" />
              </IconButton>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '10px', // If console button is visible
              }}
            >
              <Typography
                variant="subtitle3"
                noWrap
                sx={{
                  marginRight: '45px',
                }}
              >
                DataCapture
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              {showFolder ? (
                <>
                  <Typography
                    variant="h6"
                    noWrap
                    style={{ color: 'white', fontSize: 15 }}
                  >
                    {!Array.isArray(organisations) ? organisations : null}
                  </Typography>
                  <FontAwesomeIcon icon="fa-chevron-right" size={12} />
                  <Typography
                    variant="h6"
                    noWrap
                    style={{ color: 'white', fontSize: 15 }}
                  >
                    {showFolder()}
                  </Typography>
                </>
              ) : (
                <Typography variant="h6" noWrap style={{ color: 'white', fontSize: 15 }}>
                  {!Array.isArray(organisations) ? organisations : null}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                width: '200px',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              {Array.isArray(organisations) ? <OrganisationSelector /> : null}

              {mobile && state.screen === 'record' ? (
                ''
              ) : (
                <>
                  <TasksTray
                    anchorTasksTray={anchorTasksTray}
                    setAnchorTasksTray={setAnchorTasksTray}
                  />
                  <NotificationTray
                    anchorNotification={anchorNotification}
                    setAnchorNotification={setAnchorNotification}
                  ></NotificationTray>
                  <CollectionBin
                    anchorCollectionBin={anchorCollectionBin}
                    setAnchorCollectionBin={setAnchorCollectionBin}
                  />
                  <UserMenu
                    sendData={RTC.sendData}
                    setAnchorUserMenu={setAnchorUserMenu}
                    anchorUserMenu={anchorUserMenu}
                  />
                </>
              )}
              {/* Button to display new features */}
              {/* <Button
                variant="text"
                color="secondary"
                onClick={() => setModalIsOpen((prevState) => !prevState)}
              >
                Modal
              </Button> */}
            </Box>
          </Toolbar>
        </AppBar>

        {socketDebug && (
          <DebugConsoleMain isOpen={consoleIsOpen} handleClose={handleCloseConsole} />
        )}
        <NewFeatures
          isOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          appversion={appversion}
          setappversion={setappversion}
        />
      </Box>
    </>
  );
};

export default Header;
