import React from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';
import { TaskContent } from '../../context/tasksContext';

interface ToolbarProps {
  completedTasks: TaskContent[];
  clearAllCompleted: () => void;
}

const Toolbar = ({ completedTasks, clearAllCompleted }: ToolbarProps) => {
  return (
    <Stack
      flexDirection="row"
      justifyContent={'space-between'}
      alignItems="center"
      padding={1}
    >
      <Typography
        sx={{
          color: 'white.main',
          fontSize: '.9rem',
          padding: '10px',
          fontWeight: 700,
        }}
        variant="subtitle3"
      >
        Tasks Tray
      </Typography>
      <Box>
        <Tooltip title="Clear All Completed" arrow>
          <span>
            <IconButton
              sx={{
                color: 'white.main',
                '&:hover': {
                  color: 'common.negative',
                  backgroundColor: 'common.white',
                  borderColor: '#0062cc',
                  boxShadow: 'none',
                },
              }}
              aria-label="Clear all"
              onClick={clearAllCompleted}
              disabled={completedTasks.length === 0 ? true : false}
            >
              <FontAwesomeIcon icon="fa-solid fa-eraser" size={14} />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default Toolbar;
