import { GET_FOLDERS, GET_FOLDERS_BY_ORG } from '../../../../../utils/gql/gqlFolders';
import { Folder } from '../../../../workflow/types';
import { LabelItem, StructureItem } from './permissionsTypes';
import {
  createFolderRestriction,
  generateBasicPermissionsObj,
  generateFolderAccessDetails,
  keyGenerator,
  keyGeneratorFolderFields,
  modifyPermissionsKey,
} from './utils';
export interface Structure {
  folder: StructureItem;
  screen: StructureItem;
  ocr: StructureItem;
}

export let structure: Structure = {
  folder: {
    contentOrigin: 'fetch', // content origin
    fetchEndpoint: (suser) => (suser ? GET_FOLDERS_BY_ORG : GET_FOLDERS), // endpoint
    endpointVariables: (suser, state, selOrg) => {
      return suser
        ? {
            organisationId: state.selectedOrg ? state.selectedOrg.id : selOrg.id,
          }
        : null;
    },
    dataName: (suser) => (suser ? 'FoldersByOrg' : 'Folders'), // key data (e.g data.Folders or data.Users.users)
    component: 'checkbox', // component
    labelKey: 'name', // property to render label
    tooltip: 'Allows full access to all folders',
    key: () => 'fullFolderAccess',
    dependencies: 'fullFolderAccess',
    convertValue: ({ value }) => (value ? value : false),
    children: {
      content: [], // folder list
      dependencies: 'fullFolderAccess',
      key: () => `folderAccessDetails`,
      convertValue: ({ hasItem, item, parentItem, value, content }) => {
        return generateFolderAccessDetails(hasItem, item, parentItem, value, content);
      },
      labelKey: 'name',
      keyID: 'folderID',
      sort: true,
      component: 'checkbox',
      valueKey: 'canAccess',
      tooltip: 'Allows access to the folder',
      children: {
        content: [
          {
            name: 'fields restrictions',
            component: 'text',
            hasChildren: true,
            children: {
              content: [],
              dataName: () => 'folderFields',
              children: {
                content: [
                  {
                    name: 'Restriction type',
                    component: 'divider',
                    dataName: () => 'children',
                    id: 'restrictionType',
                    hasChildren: false,
                  },
                  {
                    name: 'Type',
                    component: 'dropdown',
                    options: ['in', 'equals'],
                    hasChildren: false,
                    keyID: 'folderFieldId',
                    valueKey: 'folderFieldRestrictionType',
                    key: ({ parentItem, hasItem }) =>
                      keyGeneratorFolderFields(parentItem, hasItem),
                    convertValue: ({ value, hasItem, parentItem, index }) => {
                      if (hasItem && hasItem[index]) {
                        hasItem[index] = {
                          ...hasItem[index],
                          folderFieldRestrictionType: value,
                        };
                        return hasItem;
                      } else {
                        hasItem.push({
                          folderFieldId: parentItem ? parentItem?.id : undefined,
                          folderFieldRestrictionType: value,
                          folderFieldFieldType: parentItem ? parentItem?.type : 'string',
                          folderFieldValue: '',
                        });
                        return hasItem;
                      }
                    },
                  },
                  {
                    name: 'Value',
                    component: 'textfield',
                    hasChildren: false,
                    // label: 'Value',
                    valueKey: 'folderFieldValue',
                    dropdownValueKey: 'folderFieldRestrictionType',
                    keyID: 'folderFieldId',
                    dependencies: `folderAccessDetails`,
                    key: ({ parentItem, hasItem }) =>
                      keyGeneratorFolderFields(parentItem, hasItem),
                    convertValue: ({
                      value,
                      item,
                      parentLabel,
                      hasItem,
                      parentItem,
                      index,
                      globalState,
                      setGlobalState,
                    }) => {
                      if (!hasItem) hasItem = [];
                      if (hasItem && hasItem[index]) {
                        hasItem[index] = {
                          ...hasItem[index],
                          folderFieldValue: value,
                          folderFieldRestrictionType: parentLabel[4],
                        };
                        return hasItem;
                      } else {
                        if (hasItem && !hasItem[index]) {
                          return createFolderRestriction(
                            hasItem,
                            parentItem,
                            parentLabel,
                            value,
                            structure,
                            setGlobalState,
                            item,
                            globalState
                          );
                        }
                      }
                    },
                  },
                  {
                    name: 'Clear restriction',
                    component: 'button',
                    hasChildren: false,
                    onClick: (setGlobalState, id, parentItem) => {
                      setGlobalState((prev) => {
                        const folderIndex = prev.folderAccessDetails.findIndex(
                          (folder) => folder.folderID === parentItem.folderId
                        );

                        if (folderIndex !== -1) {
                          const folderPermissions =
                            prev.folderAccessDetails[folderIndex].folderPermissions;

                          const fieldIndex =
                            folderPermissions.browsePermissions.folderFieldRestrictions.findIndex(
                              (ff: any) => ff.folderFieldId === id
                            );

                          if (fieldIndex !== -1) {
                            folderPermissions.browsePermissions.folderFieldRestrictions.splice(
                              fieldIndex,
                              1
                            );
                          }
                        }
                        return { ...prev };
                      });
                    },
                  },
                ],
                customItems: 4,
              },
            },
          },
          {
            name: 'workflow permissions',
            component: 'text',
            hasChildren: true,
            checkForOrgPermissions: 'processPathEnabled',
            checkForFolderPermissions: 'workFlow',
            keepParentItem: true,
            children: {
              content: [
                {
                  name: 'set workflow',
                  component: 'checkbox',
                  hasChildren: false,
                  valueKey: 'folderPermissions.processPathPermissions.canSetProcessPath',
                  key: ({ parentItem, hasItem }) =>
                    keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
                  convertValue: ({ value, hasItem, parentItem }) => {
                    if (hasItem) {
                      return modifyPermissionsKey(
                        hasItem,
                        'folderPermissions.processPathPermissions.canSetProcessPath',
                        value
                      );
                    } else {
                      return generateBasicPermissionsObj(
                        parentItem.id,
                        'folderPermissions.processPathPermissions.canSetProcessPath',
                        value
                      );
                    }
                  },
                },

                {
                  name: 'perform workflow step',
                  component: 'checkbox',
                  hasChildren: false,
                  valueKey:
                    'folderPermissions.processPathPermissions.canPerformProcessStep',
                  key: ({ parentItem, hasItem }) =>
                    keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
                  convertValue: ({ value, hasItem, parentItem }) => {
                    if (hasItem) {
                      return modifyPermissionsKey(
                        hasItem,
                        'folderPermissions.processPathPermissions.canPerformProcessStep',
                        value
                      );
                    } else {
                      return generateBasicPermissionsObj(
                        parentItem.id,
                        'folderPermissions.processPathPermissions.canPerformProcessStep',
                        value
                      );
                    }
                  },
                },
                {
                  name: 'Search Using any filter',
                  component: 'checkbox',
                  hasChildren: false,
                  valueKey:
                    'folderPermissions.processPathPermissions.canSearchUsingAnyFilter',
                  key: ({ parentItem, hasItem }) =>
                    keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
                  convertValue: ({ value, hasItem, parentItem }) => {
                    if (hasItem) {
                      return modifyPermissionsKey(
                        hasItem,
                        'folderPermissions.processPathPermissions.canSearchUsingAnyFilter',
                        value
                      );
                    } else {
                      return generateBasicPermissionsObj(
                        parentItem.id,
                        'folderPermissions.processPathPermissions.canSearchUsingAnyFilter',
                        value
                      );
                    }
                  },
                },
                {
                  name: 'require Note When Approving',
                  component: 'checkbox',
                  hasChildren: false,
                  valueKey:
                    'folderPermissions.processPathPermissions.requireNoteWhenApproving',
                  key: ({ parentItem, hasItem }) =>
                    keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
                  convertValue: ({ value, hasItem, parentItem }) => {
                    if (hasItem) {
                      return modifyPermissionsKey(
                        hasItem,
                        'folderPermissions.processPathPermissions.requireNoteWhenApproving',
                        value
                      );
                    } else {
                      return generateBasicPermissionsObj(
                        parentItem.id,
                        'folderPermissions.processPathPermissions.requireNoteWhenApproving',
                        value
                      );
                    }
                  },
                },
                {
                  name: 'require Note When Rejecting',
                  component: 'checkbox',
                  hasChildren: false,
                  valueKey:
                    'folderPermissions.processPathPermissions.requireNoteWhenRejecting',
                  key: ({ parentItem, hasItem }) =>
                    keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
                  convertValue: ({ value, hasItem, parentItem }) => {
                    if (hasItem) {
                      return modifyPermissionsKey(
                        hasItem,
                        'folderPermissions.processPathPermissions.requireNoteWhenRejecting',
                        value
                      );
                    } else {
                      return generateBasicPermissionsObj(
                        parentItem.id,
                        'folderPermissions.processPathPermissions.requireNoteWhenRejecting',
                        value
                      );
                    }
                  },
                },
              ],
            },
          },
          {
            name: 'edit indexed records',
            component: 'checkbox',
            hasChildren: false,
            valueKey: 'folderPermissions.browsePermissions.canEditIndexedRecords',
            key: ({ parentItem, hasItem }) =>
              keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
            convertValue: ({ hasItem, value, parentItem }) => {
              if (hasItem) {
                return modifyPermissionsKey(
                  hasItem,
                  'folderPermissions.browsePermissions.canEditIndexedRecords',
                  value
                );
              } else {
                return generateBasicPermissionsObj(
                  parentItem.id,
                  'folderPermissions.browsePermissions.canEditIndexedRecords',
                  value
                );
              }
            },
          },
          {
            name: 'delete records',
            component: 'checkbox',
            hasChildren: false,
            valueKey: 'folderPermissions.canDeleteRecords',
            key: ({ parentItem, hasItem }) =>
              keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
            convertValue: ({ value, hasItem, parentItem }) => {
              if (hasItem) {
                return modifyPermissionsKey(
                  hasItem,
                  'folderPermissions.canDeleteRecords',
                  value
                );
              } else {
                return generateBasicPermissionsObj(
                  parentItem.id,
                  'folderPermissions.canDeleteRecords',
                  value
                );
              }
            },
          },
          {
            name: 'delete notes',
            component: 'checkbox',
            hasChildren: false,
            valueKey: 'folderPermissions.canDeleteNotes',
            key: ({ parentItem, hasItem }) =>
              keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
            convertValue: ({ value, hasItem, parentItem }) => {
              if (hasItem) {
                return modifyPermissionsKey(
                  hasItem,
                  'folderPermissions.canDeleteNotes',
                  value
                );
              } else {
                return generateBasicPermissionsObj(
                  parentItem.id,
                  'folderPermissions.canDeleteNotes',
                  value
                );
              }
            },
          },
          {
            name: 'remove index',
            component: 'checkbox',
            hasChildren: false,
            valueKey: 'folderPermissions.canRemoveIndex',
            key: ({ parentItem, hasItem }) =>
              keyGenerator(hasItem, parentItem, 'folderAccessDetails'),
            convertValue: ({ value, hasItem, parentItem }) => {
              if (hasItem) {
                return modifyPermissionsKey(
                  hasItem,
                  'folderPermissions.canRemoveIndex',
                  value
                );
              } else {
                return generateBasicPermissionsObj(
                  parentItem.id,
                  'folderPermissions.canRemoveIndex',
                  value
                );
              }
            },
          },
        ],
        customItems: 4,
        component: 'text',
        dataName: () => 'folderFields',
        labelKey: 'name',
        keyID: 'folderFieldId', // folderFieldId
      },
    },
  },
  screen: {
    key: () => 'fullScreenAccess',
    convertValue: ({ value }) => (value ? value : false), // it is not necessary to modify the value, since it is a unique key.
    component: 'checkbox',
    labelKey: 'name',
    dataName: () => 'content',
    tooltip: 'Allows full access to all screens',
    dependencies: 'fullScreenAccess',
    children: {
      content: [
        // another option is to add the id to a custom component
        {
          name: 'Folder Screen',
          id: 'foldersScreen',
          component: 'checkbox',
          hasChildren: false,
          tooltip: 'Allows access to folder screen',
        },
        {
          name: 'Reporting Screen',
          id: 'reportingScreen',
          component: 'checkbox',
          hasChildren: false,
          tooltip: 'Allows access to reporting screen',
        },
        {
          name: 'Users Screen',
          id: 'usersScreen',
          component: 'checkbox',
          hasChildren: false,
          tooltip: 'Allows access to users screen',
        },
      ],
      labelKey: 'name',
      component: 'checkbox',

      dependencies: 'fullScreenAccess',
      key: ({ id }) => `screenAccessDetails.${id}`,
      convertValue: ({ value }) => (value ? value : false),
    },
  },
  ocr: {
    key: () => 'ocrTemplateCreator',
    convertValue: ({ value }) => (value ? value : false), // it is not necessary to modify the value, since it is a unique key.
    component: 'checkbox',
    labelKey: 'name',
    dataName: () => 'content',
    tooltip: 'Allows full access to the OCR Template Creator',
    dependencies: 'ocrTemplateCreator',
    children: {
      content: [
        // another option is to add the id to a custom component
        {
          name: 'Folder Screen',
          id: 'foldersScreen',
          component: 'checkbox',
          hasChildren: false,
          tooltip: 'Allows access to folder screen',
        },
        {
          name: 'Reporting Screen',
          id: 'reportingScreen',
          component: 'checkbox',
          hasChildren: false,
          tooltip: 'Allows access to reporting screen',
        },
        {
          name: 'Users Screen',
          id: 'usersScreen',
          component: 'checkbox',
          hasChildren: false,
          tooltip: 'Allows access to users screen',
        },
      ],
      labelKey: 'name',
      component: 'checkbox',

      dependencies: 'ocrTemplateCreator',
      key: ({ id }) => `ocrTemplateCreator.${id}`,
      convertValue: ({ value }) => (value ? value : false),
    },
  },
};

export let initial: LabelItem[] = [
  {
    name: 'Folder Access',
    component: 'checkbox',
    childrenName: 'folder',
    labelKey: 'name',
    id: 'folder',
    key: () => `fullFolderAccess`,
    schema: { ...structure.folder },
  },
  {
    name: 'Screen Access',
    component: 'checkbox',
    childrenName: 'screen',
    labelKey: 'name',
    id: 'screen',
    // disabled: true, // REMOVE WHEN SCREEN ACCESS DETAILS ARE READY
    hasChildren: false, // REMOVE WHEN SCREEN ACCESS DETAILS ARE READY
    key: () => `fullScreenAccess`,
    schema: { ...structure.screen },
  },
  {
    name: 'OCR Template Creator',
    component: 'checkbox',
    childrenName: 'ocr',
    labelKey: 'name',
    id: 'ocr',
    // disabled: true, // REMOVE WHEN SCREEN ACCESS DETAILS ARE READY
    hasChildren: false, // REMOVE WHEN SCREEN ACCESS DETAILS ARE READY
    key: () => `ocrTemplateCreator`,
    schema: { ...structure.ocr },
  },
];

export const initialPermissions = {
  templateName: '',
  fullFolderAccess: false,
  fullScreenAccess: true,
  ocrTemplateCreator: false,
  folderAccessDetails: [],
};

export const generateInitialPermissions = (folders: Folder[]) => {
  // this should receive 'user.role' property to decide what initial value each key will have
  let folderAccessDetails = folders.map((folder: Folder) => {
    return {
      folderID: folder.id,
      canAccess: false,
      fullAccess: false, // example: if user role is ADMIN maybe must be this prop true ?
      folderPermissions: {
        browsePermissions: {
          canEditIndexedRecords: false,
          folderFieldRestrictions: [],
        },
        canDeleteRecords: false,
        canDeleteNotes: false,
        canIndex: false,
        canRemoveIndex: false,
        processPathPermissions: {
          canSetProcessPath: false,
          canPerformProcessStep: false,
          canSearchUsingAnyFilter: true,
          requireNoteWhenApproving: false,
          requireNoteWhenRejecting: false,
        },
      },
    };
  });

  return {
    templateName: '',
    fullFolderAccess: false,
    fullScreenAccess: true,
    folderAccessDetails,
    ocrTemplateCreator: false,
  };
};
