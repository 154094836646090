import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  MenuItem,
  PaletteMode,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';

import { SubmitHandler } from 'react-hook-form';
import { ADD_NOTE_TO_RECORD } from '../../utils/gql/gqlNotes';
import FontAwesomeIcon from '../FAIcon';
import Corner from './components/Corner';
import { AddNotesProps, FormValues, NotesFormProps } from './notesTypes';

const styles = {
  noteInput: {
    border: 'none',
    height: 'auto',
    padding: 2,
    marginBottom: 1,
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  helperText: {
    display: 'inline',
    position: 'relative',
    right: '-360px',
    bottom: '28px',
    fontSize: 10,
  },
  priorityBox: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
};

export const getBGColor = (priority: string, mode: PaletteMode) => {
  switch (priority) {
    // case 'Low':
    // return 'rgb(232, 245, 255)';
    // return '#f2f2f289';
    case 'Normal':
      return mode === 'dark' ? '#efc844cc' : '#f1be0626';
    case 'High':
      return mode === 'dark' ? '#c32e2ecc' : '#f8591a36';
    default:
      break;
  }
};

const NotesForm = ({
  submitButtonRef,
  register,
  handleSubmit,
  errors,
  resetField,
  clearErrors,
  setShowNotesList,
  reset,
  record,
  setNotes,
  setMessage,
  setSnackOpen,
  setFocus,
}: NotesFormProps) => {
  const [content, setContent] = useState('');
  const [priority, setPriority] = useState<string>('Normal');
  const theme = useTheme();
  const handleChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value);
  };

  const [addNoteToRecord] = useMutation(ADD_NOTE_TO_RECORD);

  const addNote = ({ content, priority, recordId }: AddNotesProps) => {
    return addNoteToRecord({
      variables: {
        recordId,
        documentNote: {
          content,
          priority,
        },
      },
    })
      .then((res) => {
        // the reverse method is used to make the last note come first
        let newNotes = res.data.addDocumentNoteToRecord.record.documentNotes.reverse();
        setNotes(newNotes);
        setShowNotesList(true);
      })
      .catch((error) => {
        setMessage({
          status: true,
          message: 'Note could not be added',
          severity: 'error',
        });
        setSnackOpen(true);
      });
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const newNote = {
      content: data.content,
      priority,
      recordId: record.id,
    };
    addNote(newNote);
    resetField('content');
  };

  useEffect(() => {
    // when the component is rendered, the focus must be on content textfield
    setFocus('content');
  }, [setFocus]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '410px' }}>
      <Box sx={styles.titleBox}>
        <Typography variant="subtitle1" sx={{ fontSize: 16 }}>
          Create a note
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            setShowNotesList(true);
            reset();
          }}
        >
          <FontAwesomeIcon
            icon="fas fa-times"
            size="14px"
            color="common.greyblueDark"
            padding="2px"
          />
        </IconButton>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('content', {
            required: {
              value: true,
              message: 'This note cannot be added empty',
            },
            maxLength: {
              value: 500,
              message: 'This note cannot be longer than 500 characters',
            },
            validate: {
              isNotEmpty: (string: string) =>
                (typeof string === 'string' && string.trim() !== '') ||
                'This note cannot be added empty',
            },
          })}
          onChange={(e) => {
            clearErrors('content');
            setContent(e.target.value);
          }}
          placeholder="Note"
          multiline
          fullWidth
          inputProps={{ sx: { height: 'auto', maxHeight: '300px', minHeight: '60px' } }}
          disableUnderline={true}
          error={errors.content ? true : false}
          sx={{
            ...styles.noteInput,
            backgroundColor: (theme) => getBGColor(priority, theme.palette.mode),
          }}
        />
        <Corner
          preview={true}
          priority={priority}
          backgroundColor={theme.palette.mode === 'dark' ? 'black.main' : 'common.white'}
        />
        <FormHelperText
          data-testid="note-word-count"
          sx={{ ...styles.helperText, color: content.length > 500 ? 'red' : '#27070786' }}
        >
          {content.length}/500
        </FormHelperText>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          {errors && errors.content && (
            <Typography
              data-testid="note-error-message"
              variant="caption"
              sx={{ fontSize: 10, color: 'red', margin: 0, width: '100%', flex: 2 }}
            >
              {errors.content.message}
            </Typography>
          )}
          <Box sx={styles.priorityBox}>
            <Typography
              data-testid="note-priority-select-label"
              variant="subtitle1"
              sx={{ fontSize: 14 }}
            >
              Priority
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
              <Select
                data-testid="note-priority-select"
                value={priority}
                onChange={handleChange}
                size="small"
                MenuProps={{ style: { zIndex: 999999, position: 'absolute' } }}
              >
                <MenuItem value={'Normal'}>Medium</MenuItem>
                <MenuItem value={'High'}>High</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <button ref={submitButtonRef} type="submit" style={{ display: 'none' }} />
      </form>
    </Box>
  );
};

export default NotesForm;
