import React from 'react';
import Grid from '@mui/material/Grid';

// components
import UserCard from './UserCard';
import { User } from '../../components/masterTable/masterTableTypes';

interface UsersInfoProps {
  users: User[];
}

const UsersInfo = ({ users }: UsersInfoProps) => {
  return (
    <Grid container sx={{ marginTop: '20px' }}>
      {users && users.map((user) => <UserCard key={user.id} user={user} />)}
    </Grid>
  );
};

export default UsersInfo;
