import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import FontAwesomeIcon from '../../components/FAIcon';
import StatusChip from '../../components/StatusChip';
import { User } from '../../components/masterTable/masterTableTypes';
import UserAvatar from './userProfile/components/UserAvatar';

const iconProps = {
  size: '18px',
  color: 'inherit',
};

interface IUser extends User {
  status?: 'offline' | 'online' | 'await' | undefined;
}

interface UserCardProps {
  user: IUser;
}

export default function UserCard({ user }: UserCardProps) {
  const history = useHistory();

  const { id, firstName, lastName, role, status } = user;

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={4}
      lg={2.4}
      xl={2}
      marginBottom="15px"
      justifyContent="center"
    >
      <Card
        data-testid="user-card"
        sx={{
          height: '240px',
          width: '200px',
          transition: '.3s',
          '&:hover': {
            boxShadow: '0px 5px 5px rgb(0 0 0 / 20%)',
          },
          backgroundColor: (theme: Theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.greyblueDarker
              : undefined,
        }}
      >
        <Box
          sx={{
            height: '35px',
            background:
              'radial-gradient(circle, rgba(121,144,159,1) 0%, rgba(52,107,144,1) 83%)',
          }}
          //component="img"
          //height="35"
          //image="https://images.unsplash.com/photo-1622547748225-3fc4abd2cca0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80"
          //alt="Paella dish"
        />
        <Box marginTop="-35px" marginBottom="0">
          <UserAvatar
            firstName={firstName}
            lastName={lastName}
            fontSize={20}
            width={52}
            height={52}
          />
        </Box>
        <CardContent>
          <Grid item container direction="column" alignItems="center" spacing={1}>
            <Grid item sx={{ textAlign: 'center', width: '100%' }}>
              <Typography
                variant="subtitle4"
                fontWeight={400}
                fontSize={10}
                sx={{ textTransform: 'uppercase' }}
              >
                {role}
              </Typography>
              <Typography
                variant="h6"
                fontSize={14}
                color={(theme) =>
                  theme.palette.mode === 'dark' ? 'common.blueGrey.0' : 'common.blue'
                }
                sx={{ overflow: 'hidden', height: '55px' }}
              >
                {firstName.toUpperCase() + ' ' + lastName.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <StatusChip status={status} size="small" />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              columnSpacing={1}
            >
              <Grid item>
                <Tooltip title="Message" placement="top" arrow>
                  <span>
                    <IconButton
                      onClick={() => history.push(`/dash/users/${id}`)}
                      sx={{
                        color: 'common.positiveDark',
                        transition: '.3s',
                        '&:hover': {
                          color: 'common.positive',
                        },
                      }}
                      disabled
                    >
                      <FontAwesomeIcon icon="fas fa-comment-alt" {...iconProps} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Edit" placement="top" arrow>
                  <span>
                    <IconButton
                      id="edit-profile"
                      disableRipple={true}
                      onClick={() => history.push(`/dash/users/${id}`)}
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === 'dark'
                            ? 'common.blueShadow.300'
                            : 'common.greyblueDarker',
                        transition: '.3s',
                        height: '38px',
                        width: '38px',
                        '&:hover': {
                          color: (theme) =>
                            theme.palette.mode === 'dark'
                              ? 'common.blueShadow.500'
                              : 'common.greyblue',
                        },
                      }}
                    >
                      <FontAwesomeIcon icon="fas fa-user-edit" {...iconProps} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
