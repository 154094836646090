import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  IconButton,
  MenuItem,
  Radio,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { useContext, useEffect, useRef, useState } from 'react';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';
import useFolder from '../../hooks/useFolders';
import useUserStorageData from '../../hooks/user/userUserStorageData';
import { capitalize } from '../../utils/text';
import FontAwesomeIcon from '../FAIcon';
import { InputWithActions } from './InputWithActions';
import OCRSetupActions from './OCRSetupActions';
import {
  StyledActionButton,
  StyledDeleteIconButton,
  StyledTextField,
} from './StyledComponents';
import { db } from '../../utils/indexedDB/indexeddb';
import { useLiveQuery } from 'dexie-react-hooks';
import { useFabricContext } from '../../hooks/useFabricCanvas';
import Modal from '../modal/StyledModal';
import AdvancedSettingsModal from './AdvancedSettingsModal/AdvancedSettingsModal';
import { FolderField } from '../masterTable/masterTableTypes';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.greyblueDarker
        : theme.palette.common.lightblue,
    fontWeight: checked ? 600 : 500,
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.common.greyblueDarker
      : theme.palette.common.lightblue,
  '&.Mui-checked': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.greyblueDarker
        : theme.palette.common.lightblue,
  },
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const dateFormats = [
  {
    value: 'NONE',
    label: 'dd/mm/yyyy',
  },
  {
    value: 'DATE_MDY',
    label: 'mm/dd/yyyy',
  },
  {
    value: 'DATE_YMD',
    label: 'yyyy/mm/dd',
  },
  {
    value: 'DATE_YDM',
    label: 'yyyy/dd/mm',
  },
];
const currencyFormats = [
  {
    value: 'NONE',
    label: 'NONE',
  },
  {
    value: 'CURRENCY_EU',
    label: 'CURRENCY_EU',
  },
];
const label = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'common.greyblueDark',
  borderRadius: '5px',
  color: 'common.white',
  padding: '0 20px',
  width: '160px',
};

const OCRSetupHeaderValuePairs = (props) => {
  const {
    headerValuePairs,
    setHeaderValuePairs,
    handleRemoveFields,
    updateAdvancedSettings,
    setOcrTemplateLoader,
    ocrTemplateDetails,
    ocrTemplate,
  } = props;
  const [valuePairInput, setValuePairInput] = useState(false);
  const [initialValues, setInitialValues] = useState<{ id: string; value: any }[]>([]);
  const valuePairInputRef = useRef(null);
  const { userSettings } = useUserStorageData();
  const { folder, fields: folderFields } = useFolder(userSettings.lastIndexedFolder);
  const [expandedFields, setExpandedFields] = useState<string[]>([]);
  const [identifiers, setIdentifiers] = useState({});
  const {
    state: { screen, selectedRecord },
  } = useContext(StatusContext) as StatusContextTypes;
  const [advanceSettingsOpen, setAdvanceSettingsOpen] = useState<string[]>([]);
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const [selectedField, setSelectedField] = useState<FolderField | null>(null);

  const { valuesOnlyFields } = useFabricContext();

  useEffect(() => {
    if (valuePairInput) {
      valuePairInputRef.current?.focus();
    }
  }, [valuePairInput]);

  const toggleExpand = (id) => {
    if (expandedFields.includes(id)) {
      setExpandedFields((prev) => prev.filter((item) => item !== id));
    } else {
      setExpandedFields((prev) => [...prev, id]);
    }
  };

  const addIdentifier = (fieldId: string) => {
    const newId = identifiers[fieldId]?.length
      ? identifiers[fieldId][identifiers[fieldId].length - 1].id + 1
      : 0;
    setIdentifiers((prevIdentifiers) => ({
      ...prevIdentifiers,
      [fieldId]: [...(prevIdentifiers[fieldId] || []), { id: newId }],
    }));
  };

  // Remove identifier from a specific field.
  const removeIdentifier = (fieldId, idToRemove) => {
    setIdentifiers((prevIdentifiers) => ({
      ...prevIdentifiers,
      [fieldId]: prevIdentifiers[fieldId].filter(
        (identifier) => identifier.id !== idToRemove
      ),
    }));
  };

  useEffect(() => {
    if (!folderFields) return;
    folderFields.forEach((field) => {
      if (!identifiers[field.id]) {
        addIdentifier(field.id);
      }
    });
  }, [folderFields]);

  const handleAdvanceSettingsClick = (id) => {
    if (advanceSettingsOpen.includes(id)) {
      setAdvanceSettingsOpen((prev) => prev.filter((item) => item !== id));
    } else {
      setAdvanceSettingsOpen((prev) => [...prev, id]);
    }
  };

  const handleDateFormatChange = (e, field) => {
    const headers = headerValuePairs.map((header) => {
      if (header.field.id === field.id) {
        header.specialProcessing = e.target.value;
      }
      return header;
    });

    setHeaderValuePairs(headers);
  };

  const handleFindAnyPage = (event: React.ChangeEvent<HTMLInputElement>, field) => {
    const headers = headerValuePairs.map((header) => {
      if (header.field.id === field.id) {
        header.pageToFindValue = (event.target as HTMLInputElement).value;
        updateAdvancedSettings(field.id);
      }
      return header;
    });
    setHeaderValuePairs(headers);
  };
  const generateAdvanceSettingsPerField = (field, specialProcessing) => {
    switch (field.type) {
      case 'datetime':
        return (
          <StyledTextField
            id="date_format_select"
            select
            label="Date Format"
            defaultValue={specialProcessing || 'NONE'}
            placeholder="Select a date format"
            size="small"
            sx={{ width: '10rem' }}
            onChange={(e) => {
              handleDateFormatChange(e, field);
              updateAdvancedSettings(field.id);
            }}
          >
            {dateFormats.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        );
      case 'currency':
        return (
          <StyledTextField
            id="currency_format_select"
            select
            label="Currecy Format"
            defaultValue={specialProcessing || 'NONE'}
            placeholder="Select a currency format"
            size="small"
            sx={{ width: '10rem' }}
            onChange={(e) => {
              handleDateFormatChange(e, field);
              updateAdvancedSettings(field.id);
            }}
          >
            {currencyFormats.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        );
    }
  };

  useEffect(() => {
    setOcrTemplateLoader(false);
  }, []);

  const generateAdvanceSettings = (field, pageToFindValue, specialProcessing) => {
    return (
      <Stack spacing={2} py="10px">
        {generateAdvanceSettingsPerField(field, specialProcessing)}

        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={(e) => handleFindAnyPage(e, field)}
          value={pageToFindValue}
        >
          <MyFormControlLabel
            value="FRONT"
            control={<StyledRadio />}
            label="Only front page"
          />
          <MyFormControlLabel value="ANY" control={<StyledRadio />} label="Any page" />
          <MyFormControlLabel
            value="BACK"
            control={<StyledRadio />}
            label="Only back page"
          />
        </RadioGroup>
      </Stack>
    );
  };

  const handleOpenSettingsModal = () => {
    setIsOpenSettingsModal(true);
  };

  const handleCloseSettingsModal = () => {
    setIsOpenSettingsModal(false);
  };

  const hasValue = (id: string) => {
    if (initialValues.length === 0) return;
    const fieldHasValue = initialValues.find((field) => field.id === id);
    return fieldHasValue ? fieldHasValue.value : '';
  };

  const getRecordFromIndexedDB = async (recordId: string) => {
    return await db.table('indexingScreen').get({ recordID: recordId });
  };

  useLiveQuery(() => {
    getRecordFromIndexedDB(selectedRecord?.id).then((res) => {
      if (res && res.fields) {
        let values = Object.values(res.fields).map((field) => ({
          id: field.id,
          value: Object.values(field)[1],
        }));
        setInitialValues(values);
      }
    });
  }, [headerValuePairs]);

  return (
    <>
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
        <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
          Draw headers and values positions
        </Typography>
      </Stack>

      <Box
        sx={{
          '&::-webkit-scrollbar': { width: '2px' },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#79909f',
            borderRadius: '4px',
          },
          paddingBottom: 2,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          maxHeight: screen === 'folders' ? '370px' : '550px',
          // '.MuiAccordion-root:not(:first-of-type).Mui-expanded': {
          //   borderTop: '1px solid',
          //   borderColor: grey[800],
          // },
        }}
      >
        {headerValuePairs.map(
          ({ field, main, headerValues, specialProcessing, pageToFindValue }, index) => {
            return (
              <>
                <Accordion
                  data-testid={`${field.name}-header-value-pair`}
                  square
                  elevation={1}
                  key={field.id}
                  disableGutters
                  expanded={expandedFields.includes(field.id)}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light' ? '#f8f8f8' : '#16212f80',
                    borderRadius: '4px',
                    '&::before': { height: 0 },
                  }}
                >
                  <AccordionSummary
                    data-testid="header-value-accordion-summary"
                    expandIcon={
                      <IconButton
                        aria-label="expand-card"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleExpand(field.id);
                        }}
                      >
                        <FontAwesomeIcon icon="fas fa-chevron-down" size="16px" />
                      </IconButton>
                    }
                    aria-controls={`${field.textToFind}`}
                    id={field.textToFind}
                    sx={{ cursor: 'auto' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Stack direction="row" spacing={3} sx={{ width: '100%' }}>
                      <Stack direction="row" spacing={1}>
                        <Typography fontWeight={500} sx={label}>
                          {capitalize(field.name)}
                        </Typography>

                        <StyledTextField
                          disabled
                          id={`${field.name}-input`}
                          variant="outlined"
                          size="small"
                          value={main === '' ? hasValue(field.id) : main}
                          inputProps={{
                            onFocus: (e) => {
                              e.stopPropagation();
                            },
                          }}
                        />
                      </Stack>
                      <OCRSetupActions
                        el={{ field, main, headerValues }}
                        identifierId={index}
                        action="set-field-definition"
                        field={field}
                        inputType={'main'}
                        canDrawArea={true}
                        canClear={true}
                        canEdit={true}
                        canHideArea={true}
                        hasAdvancedSettings={true}
                        setSelectedField={setSelectedField}
                        handleRemoveFields={handleRemoveFields}
                        handleOpenSettingsModal={handleOpenSettingsModal}
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    data-testid="header-value-accordion-details"
                    sx={{ paddingBottom: '20px' }}
                  >
                    {
                      !advanceSettingsOpen.includes(field.id) ? (
                        <Stack direction="column" spacing={1}>
                          {headerValues.map((el, advancedSettings) => (
                            <Stack
                              key={el.textToFind}
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Stack direction="row" spacing={2} sx={{ marginY: '10px' }}>
                                <InputWithActions
                                  inputId="header-input"
                                  el={el}
                                  value={el.textToFind}
                                  identifierId={0}
                                  action="update-field-header"
                                  field={field}
                                  type={'header'}
                                  canDrawArea={true}
                                  canClear={true}
                                  handleRemoveFields={handleRemoveFields}
                                />
                                <InputWithActions
                                  inputId="value-input"
                                  el={el}
                                  value={el.value}
                                  identifierId={0}
                                  action="update-field-value"
                                  field={field}
                                  type={'value'}
                                  canDrawArea={true}
                                  canClear={true}
                                  handleRemoveFields={handleRemoveFields}
                                />
                              </Stack>

                              {false && (
                                <Tooltip title="Remove identifier">
                                  <StyledDeleteIconButton
                                    aria-label="delete value pair"
                                    onClick={() => removeIdentifier(field.id, el.id)}
                                  >
                                    <FontAwesomeIcon
                                      icon="fas fa-trash"
                                      color="common.negative"
                                      size={'16px'}
                                    />
                                  </StyledDeleteIconButton>
                                </Tooltip>
                              )}
                            </Stack>
                          ))}
                        </Stack>
                      ) : null
                      // generateAdvanceSettings(field, pageToFindValue, specialProcessing)
                    }
                  </AccordionDetails>
                  {/* <Box sx={{ position: 'absolute', bottom: 2, right: '16px' }}>
                    <Tooltip title="Advanced Settings">
                      <IconButton
                        aria-label="Advanced Settings"
                        onClick={() => handleAdvanceSettingsClick(field.id)}
                        disabled={
                          headerValues[0].textToFind === '' &&
                          headerValues[0].value === '' &&
                          !valuesOnlyFields?.includes(field.id)
                        }
                      >
                        <FontAwesomeIcon icon={'fas fa-cog'} size="14px" />
                      </IconButton>
                    </Tooltip>
                  </Box> */}
                  {false && (
                    <AccordionActions>
                      <StyledActionButton
                        variant="contained"
                        startIcon={<FontAwesomeIcon icon={'fas fa-plus'} size="6px" />}
                        onClick={() => addIdentifier(field.id)}
                      >
                        Add another identifier
                      </StyledActionButton>
                    </AccordionActions>
                  )}
                </Accordion>
              </>
            );
          }
        )}
      </Box>
      {isOpenSettingsModal && (
        <AdvancedSettingsModal
          open={isOpenSettingsModal}
          handleClose={handleCloseSettingsModal}
          field={selectedField}
          ocrTemplateDetails={ocrTemplateDetails}
          ocrTemplate={ocrTemplate}
          folderFields={folderFields}
        />
      )}
    </>
  );
};

export default OCRSetupHeaderValuePairs;
