import {
  Box,
  Drawer,
  Stack,
  IconButton,
  Tooltip,
  Button,
  Typography,
  ButtonGroup,
} from '@mui/material';
import FontAwesomeIcon from '../../components/FAIcon';
import { StyledTextField } from '../../components/Textfield/StyledTextfield';
import { Template } from './ReportingSideBar';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { SelectedFolder } from '../../components/masterTable/masterTableTypes';

interface CreateTemplateDrawerProps {
  templateDrawer: boolean;
  handleDrawerClose: () => void;
  handleCreateTemplate: (template: Template) => void;
  selectedFolder: SelectedFolder | null;
  handleEditTemplate: (template: Template) => void;
  selectedTemplate: Template;
}

const CreateTemplateDrawer = ({
  templateDrawer,
  handleDrawerClose,
  handleCreateTemplate,
  selectedFolder,
  handleEditTemplate,
  selectedTemplate,
}: CreateTemplateDrawerProps) => {
  const [templateName, setTemplateName] = useState(selectedTemplate?.label || '');
  const { watch, setValue } = useFormContext();
  const handleClick = () => {
    if (templateName === '' || !selectedFolder) return;
    let values = watch();
    let newTemplate = {
      ...values.selectedTemplate,
      label: templateName,
      id: Math.floor(Math.random() * (200 - 0 + 1)) + 0,
      name: templateName,
      type: watch('selectedTemplate.type'),
      folderId: selectedFolder.id,
    };
    handleCreateTemplate(newTemplate);
    handleDrawerClose();
  };

  const handleEdit = () => {
    handleEditTemplate(watch('selectedTemplate'));
    handleDrawerClose();
  };
  return (
    <Drawer
      id="action-drawer"
      variant="persistent"
      anchor={'right'}
      open={templateDrawer}
      onClose={handleDrawerClose}
      sx={{
        backgroundColor: 'common.greyblueDarker',
        '& 	.MuiDrawer-paper': {
          backgroundColor: 'common.greyblueDarker',
          position: 'absolute',
          height: '100%',
          width: '100%',
        },
      }}
    >
      <Box sx={{ textAlign: 'end', padding: '5px' }}>
        <Tooltip title="Close">
          <IconButton
            aria-label="close drawer"
            onClick={() => {
              handleDrawerClose();
              setTemplateName('');
            }}
          >
            <FontAwesomeIcon icon="fas fa-times" color="common.white" size={'16px'} />
          </IconButton>
        </Tooltip>
      </Box>
      <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ flex: 10 }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 10,
            gap: 4,
          }}
        >
          <Typography
            variant="button"
            sx={{ color: 'common.white', textAlign: 'center' }}
          >
            {selectedTemplate
              ? 'Edit reporting template'
              : 'Create a new reporting template'}
          </Typography>
          <StyledTextField
            color="secondary"
            autoFocus
            focused
            label="Template name"
            name="template-name-input"
            sx={{ width: '90%' }}
            value={templateName}
            onChange={(e) => {
              setTemplateName(e.target.value);
              setValue('selectedTemplate.name', e.target.value);
              setValue('selectedTemplate.label', e.target.value);
            }}
          />
          <Typography
            variant="button"
            sx={{ color: 'common.white', textAlign: 'center' }}
          >
            This template will be of type:
          </Typography>
          <StyledTextField
            color="secondary"
            variant="standard"
            value={watch('selectedTemplate.type')?.replace('_', ' ')}
            InputProps={{ readOnly: true }}
            sx={{ width: '90%' }}
          />
        </Box>
        <ButtonGroup sx={{ width: '100%' }}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              color: 'common.white',
              backgroundColor: 'common.negative',
              '&:hover': { backgroundColor: 'common.negativeDark' },
            }}
            onClick={() => {
              handleDrawerClose();
              setTemplateName('');
            }}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              color: 'common.white',
              backgroundColor: 'common.greyblueDark',
              '&:hover': { backgroundColor: 'common.greyblue' },
            }}
            onClick={selectedTemplate ? handleEdit : handleClick}
          >
            {selectedTemplate ? 'Edit' : 'Create '}
          </Button>
        </ButtonGroup>
      </Stack>
    </Drawer>
  );
};

export default CreateTemplateDrawer;
