import React, { createContext, useContext } from 'react';
import useOCRSetup, { OCRSetup } from '../hooks/OCRTemplateSetup/useOCRSetup';

const OCRSetupContext = createContext<OCRSetup | null>(null);

export const OCRSetupProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const ocrSetup = useOCRSetup();

  return <OCRSetupContext.Provider value={ocrSetup}>{children}</OCRSetupContext.Provider>;
};

export const useOCRSetupContext = (): OCRSetup | null => useContext(OCRSetupContext);
