import { Button, styled } from '@mui/material';

export const StyledPrimaryButton = styled(Button)(({ theme }) => {
  let modeIsDark = theme.palette.mode === 'dark';
  return {
    '&.MuiButton-contained': {
      color: theme.palette.common.white,
      backgroundColor: modeIsDark
        ? theme.palette.common.blueShadow['500']
        : theme.palette.common.greyblueDarker,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: modeIsDark
          ? theme.palette.common.blueShadow['600']
          : theme.palette.common.greyblueDark,
      },
    },
  };
});

export const StyledSecondaryButton = styled(Button)(({ theme }) => {
  let modeIsDark = theme.palette.mode === 'dark';
  return {
    '&.MuiButton-outlined': {
      color: modeIsDark
        ? theme.palette.common.lightblue
        : theme.palette.common.greyblueDark,
      borderColor: modeIsDark
        ? theme.palette.common.blueShadow['300']
        : theme.palette.common.greyblueDarker,
      '&:hover': {
        color: modeIsDark
          ? theme.palette.common.white
          : theme.palette.common.greyblueDarker,
      },
    },
  };
});

export const StyledPositiveButton = styled(Button)(({ theme }) => {
  let modeIsDark = theme.palette.mode === 'dark';
  return {
    '&.MuiButtonBase-root': {
      backgroundColor: modeIsDark
        ? theme.palette.common.positiveDark
        : theme.palette.common.positive,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: modeIsDark
          ? theme.palette.common.positive
          : theme.palette.common.positiveDark,
      },
    },
  };
});

export const StyledNegativeButton = styled(Button)(({ theme }) => {
  let modeIsDark = theme.palette.mode === 'dark';

  return {
    '&.MuiButtonBase-root': {
      backgroundColor: modeIsDark
        ? theme.palette.common.negativeDark
        : theme.palette.common.negative,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: modeIsDark
          ? theme.palette.common.negative
          : theme.palette.common.negativeDark,
      },
    },
  };
});
