import { useEffect, useState } from 'react';

// Components
import ErrorHandler from '../../utils/errorHandler';
import FolderFieldsFilters from '../folder/FolderFieldsFilters';
import FolderSelector from '../folder/FolderSelector';
import FilterPanelToolBar from './filterPanelToolBar';

// UI
import { Box, Button, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm } from 'react-hook-form';
import { modifyLocalStorageObject, useLocalStorage } from '../../hooks/useLocalStorage';
import {
  calculateAvailableOptions,
  checkWorkflowFilterPermissions,
  generateActiveFields,
  generateRecordVariables,
  generateSearchFilters,
} from './searchUtils';
import { FieldValues, SearchFormProps } from './types';
import {
  FolderField,
  SelectedFolder,
} from '../../components/masterTable/masterTableTypes';
import { STATUSES } from './SearchMain';

const useStyles = makeStyles((theme) => ({
  filterForm: {
    height: '100%',
  },
  folderFiltersTitle: {
    padding: 12,
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

const SearchForm = ({
  setFieldValues,
  fieldValues,
  limit,
  currentPage,
  sorting,
  isSuser,
  state,
  selOrg,
  setVariables,
  loadRecords,
  folder,
  setFolder,
  setFilters,
  setResetted,
  isResetted,
  setIsFiltered,
  isFiltered,
  onSubmit,
  setWorkflowFilter,
  workflowPermissionsEnabled,
  workflowFilter,
  folderPermissions,
}: SearchFormProps) => {
  const classes = useStyles();
  const { register, control, handleSubmit, reset, resetField } = useForm<FieldValues>();
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const [activeFields, setActiveFields] = useState<FolderField[]>([]);
  const [availableWorkflowOption, setAvailableWorkflowOption] = useState('');
  // This should be moved mostly to folderSelector
  // the query is being repeated for fetching folders

  const handleChangeFolder = (selected: SelectedFolder) => {
    let filters = generateSearchFilters(fieldValues[selected.id], true, selected);
    let variables = generateRecordVariables(
      folder,
      isSuser,
      state,
      selOrg,
      filters,
      limit,
      currentPage,
      sorting,
      workflowPermissionsEnabled,
      checkWorkflowFilterPermissions(
        folderPermissions,
        STATUSES,
        userSettings,
        workflowFilter
      )
    );

    setFolder(selected);
    setVariables(variables);
    loadRecords({ variables });
    const activeFields = generateActiveFields(selected.folderFields);
    setActiveFields(activeFields);
    setUserSettings(
      modifyLocalStorageObject(
        {
          ...userSettings,
          lastRecordsFolder: selected.id,
          workflowFilter: workflowPermissionsEnabled ? variables.processPath : null,
        },
        'userSettings'
      )
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkflowFilter(event.target.value);
    setAvailableWorkflowOption(event.target.value);
    setFieldValues({ ...fieldValues });
    setUserSettings(
      modifyLocalStorageObject(
        {
          ...userSettings,
          workflowFilter: workflowPermissionsEnabled ? event.target.value : null,
        },
        'userSettings'
      )
    );
  };

  const clearSearch = () => {
    // delete filters on selected folder
    let workflowFilterAllowedValue = checkWorkflowFilterPermissions(
      folderPermissions,
      STATUSES,
      userSettings,
      workflowFilter
    );
    setWorkflowFilter(workflowFilterAllowedValue);
    setFieldValues((prevState: any) => {
      delete prevState[folder.id];
      return { ...prevState };
    });
    let newLatestRecordsSearch = fieldValues;
    delete newLatestRecordsSearch[folder.id];
    setUserSettings(
      modifyLocalStorageObject(
        {
          ...userSettings,
          latestRecordsSearch: newLatestRecordsSearch,
          workflowFilter: workflowPermissionsEnabled ? workflowFilterAllowedValue : null,
        },
        'userSettings'
      )
    );
    setFilters([]);

    let variables = generateRecordVariables(
      folder,
      isSuser,
      state,
      selOrg,
      [],
      limit,
      currentPage,
      sorting,
      workflowPermissionsEnabled,
      workflowFilterAllowedValue
    );
    setVariables(variables);
    // generates a new request to reload the files without filters
    loadRecords({ variables });
  };

  useEffect(() => {
    if (!folder.id) return;
    // When the user changes folder, it checks for filters, sets them and sends to filter the records.
    fieldValues[folder.id] ? onSubmit(fieldValues[folder.id]) : onSubmit();
  }, [folder]);

  useEffect(() => {
    setWorkflowFilter(
      checkWorkflowFilterPermissions(
        folderPermissions,
        STATUSES,
        userSettings,
        workflowFilter
      )
    );
    handleChangeFolder(folder);
  }, []);

  return (
    <Paper
      sx={{
        overflow: 'hidden',
        height: '100%',
        width: { xs: '100%', md: '30%', lg: '25%' },
      }}
    >
      <form
        onSubmit={handleSubmit((data, e) => {
          onSubmit(data, !isResetted);
        })}
        className={classes.filterForm}
      >
        <Grid
          container
          direction="column"
          sx={{
            padding: '0',
            height: '100%',
            minHeight: '360px',
            flexWrap: 'nowrap',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? 'common.white'
                : theme.palette.black.ultraLightTransp,
            marginTop: '0px',
            // borderRadius: '5px',
            overflow: 'hidden',
          }}
        >
          <Grid
            item
            sx={{
              backgroundColor: 'common.greyblueDark',
            }}
          >
            <ErrorHandler>
              <FolderSelector
                title="Select a Folder"
                handleChangeFolder={handleChangeFolder}
                selectedFolder={folder}
                userSettings={userSettings}
                setUserSettings={setUserSettings}
              />
            </ErrorHandler>
            <ErrorHandler>
              {workflowPermissionsEnabled ? (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 4,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '40px',
                    backgroundColor: 'common.greyblueDarker',
                  }}
                >
                  <Typography
                    sx={{ marginLeft: 1, color: 'common.white', fontWeight: 500 }}
                  >
                    Workflow status
                  </Typography>
                  <TextField
                    select
                    value={checkWorkflowFilterPermissions(
                      folderPermissions,
                      STATUSES,
                      userSettings,
                      workflowFilter
                    )}
                    onChange={handleChange}
                    sx={{
                      '& fieldset': { border: 'none' },
                      '& .MuiSelect-select': {
                        color: 'common.white',
                        fontWeight: 500,
                      },
                      '& .MuiSvgIcon-root': {
                        color: 'common.white',
                      },
                    }}
                    size="small"
                  >
                    {calculateAvailableOptions(folderPermissions, STATUSES).map(
                      (avOpt) => (
                        <MenuItem value={avOpt.value}>{avOpt.label}</MenuItem>
                      )
                    )}
                  </TextField>
                </Box>
              ) : null}
            </ErrorHandler>
          </Grid>
          {/* Field-based filters */}
          {folder && folder.folderFields && (
            <>
              <Grid item container className={classes.folderFiltersTitle}>
                <Typography
                  variant="h3"
                  color="textSecondary"
                  sx={{ textTransform: 'uppercase' }}
                >
                  Folder Fields
                </Typography>
              </Grid>
              {
                <FolderFieldsFilters
                  register={register}
                  control={control}
                  fields={folder.folderFields}
                  // selectedFolder={isSuser ? data.FolderByOrg : data?.Folder?.folder}
                  selectedFolder={folder}
                  setFieldValues={setFieldValues}
                  fieldValues={fieldValues}
                  isResetted={isResetted}
                  setResetted={setResetted}
                  activeFields={activeFields}
                />
              }
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              marginTop: 'auto',
              flexDirection: 'row',
            }}
          >
            <Button
              sx={{
                width: '100%',
                borderRadius: 0,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.blueShadow['500']
                    : theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.blueShadow['300']
                      : theme.palette.primary.dark,
                },
                '&.Mui-disabled': {
                  backgroundColor: '#0a5478',
                  color: 'common.greyblueDark',
                },
              }}
              type="submit"
              color="primary"
              variant="contained"
              onClick={() => {
                setResetted(false);
              }}
              disabled={!folder || activeFields.length === 0 ? true : false}
            >
              Search
            </Button>
            <FilterPanelToolBar
              selectedFolder={folder}
              clearSearch={clearSearch}
              reset={reset}
              register={register}
              resetField={resetField}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              setResetted={setResetted}
              setIsFiltered={setIsFiltered}
              isFiltered={isFiltered}
              setFieldValues={setFieldValues}
              fieldValues={fieldValues}
            ></FilterPanelToolBar>
          </Box>
        </Grid>
      </form>
    </Paper>
  );
};

export default SearchForm;
