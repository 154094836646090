import {
  Autocomplete,
  FormControl,
  Grid,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { StyledTextfield } from '../../components/themedComponents/Th_TextField';

const createStyles = (theme: Theme) => {
  const mode = theme.palette.mode;
  return {
    formControl: {
      backgroundColor:
        mode === 'dark'
          ? theme.palette.common.greyblueDarker
          : theme.palette.common.lightblue,
      borderRadius: 1,
      '& label': {
        paddingLeft: '13px',
      },
      '& .MuiInputLabel-root': {
        color:
          mode === 'dark'
            ? theme.palette.common.white
            : theme.palette.common.greyblueDark,
      },
      '& .MuiInputBase-root': {
        borderRadius: '4px',
      },
      '& input': {
        color:
          mode === 'dark'
            ? theme.palette.common.white
            : theme.palette.common.greyblueDark,
      },
      '& .MuiSvgIcon-root': {
        color:
          mode === 'dark'
            ? theme.palette.common.white
            : theme.palette.common.greyblueDarker,
      },
    },
    autocomplete: {
      width: '100%',
      height: 40,
      '& .MuiAutocomplete-inputRoot': {
        marginTop: '9px',
        height: '40px',
        '&.MuiInput-root .MuiInput-input': {
          marginLeft: '14px',
        },
      },
      '& .MuiSvgIcon-root': {
        color:
          mode === 'dark'
            ? theme.palette.common.white
            : theme.palette.common.greyblueDarker,
      },
    },
  };
};

interface KeyValueFilterInputProps {
  field: any;
  control: any;
  selectedFolder: any;
  setFieldValues: React.Dispatch<React.SetStateAction<any>>;
  fieldValues: any;
  folder: any;
  isResetted: boolean;
  setResetted: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ValuePair = {
  code: string;
  name: string;
};

const KeyValueFilterInput = ({
  field,
  control,
  selectedFolder,
  setFieldValues,
  fieldValues,
  folder,
  isResetted,
  setResetted,
}: KeyValueFilterInputProps) => {
  const initialValue = fieldValues[selectedFolder.id]?.[field.id] || undefined;
  const findCompleteValue =
    folder.apProcessingSettings.ocrTemplateNameSupplierCodePairs.find(
      (key: ValuePair) => key.code === initialValue
    );
  const [value, setValue] = React.useState(findCompleteValue);
  const [inputValue, setInputValue] = React.useState('');

  const theme = useTheme();
  const styles = createStyles(theme);

  useEffect(() => {
    if (initialValue === undefined) setValue(undefined);
  }, [fieldValues, initialValue]);

  const handleChange = (
    e: SelectChangeEvent<any>,
    data: any,
    onChange: (...event: any[]) => void
  ) => {
    if (data) {
      setResetted(false);
      onChange(data.code);
      setValue(data);
      setInputValue(`${data.code} (${data.name})`);
      setFieldValues((prevState: any) => {
        return {
          ...prevState,
          [selectedFolder.id]: {
            ...prevState[selectedFolder.id],
            [field.id]: data.code,
          },
        };
      });
    } else {
      onChange('');
      setValue('');
      setInputValue('');
      setFieldValues((prevState: any) => {
        const updatedState = { ...prevState };
        if (
          updatedState[selectedFolder.id] &&
          updatedState[selectedFolder.id][field.id]
        ) {
          delete updatedState[selectedFolder.id][field.id];
        }
        return updatedState;
      });
    }
  };

  return (
    <Grid item container key={field.id} sx={{ marginBottom: '10px' }}>
      <FormControl fullWidth variant="standard" size="small" sx={styles.formControl}>
        <Controller
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              id={field.id}
              sx={styles.autocomplete}
              options={folder.apProcessingSettings.ocrTemplateNameSupplierCodePairs}
              getOptionLabel={(option) => `${option.code} (${option.name})`}
              onChange={(e, data) => handleChange(e, data, onChange)}
              value={isResetted ? '' : value}
              inputValue={isResetted ? '' : inputValue}
              onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.common.greyblueDarker,
                  }}
                >
                  {`${option.code} (${option.name})`}
                </li>
              )}
              renderInput={(params) => (
                <StyledTextfield
                  {...params}
                  fullWidth
                  label={field.name}
                  variant="standard"
                />
              )}
            />
          )}
          name={field.id}
          control={control}
          defaultValue={initialValue}
        />
      </FormControl>
    </Grid>
  );
};

export default KeyValueFilterInput;
