import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { DrawerItemProps } from './permissionsTypes';
import { checkID, getValue } from './utils';
import { Context as StatusContext } from '../../../../../context/statusContext';
import { SecondStyledTexfield } from '../../../../../components/form/StyledTextfield';
import { StyledSelect } from '../../../../../components/form/StyledSelect';

const DrawerItem = ({
  item,
  openChildren,
  index,
  i,
  schema,
  setGlobalState,
  parentLabel,
  component,
  globalState,
  setParentLabel,
  parentItem,
  setDropdownValue,
  dropdownValue,
  setParentValue,
  parentValue,
  initial,
  content,
}: DrawerItemProps) => {
  const { state } = useContext(StatusContext);
  let initialValue = item.component === 'checkbox' ? false : '';
  const [itemValue, setItemValue] = useState<string | boolean>(initialValue);
  let keyGenerator = item.key ? item.key : schema.key ? schema.key : () => '';
  let convertValue = item.convertValue
    ? item.convertValue
    : schema.convertValue
    ? schema.convertValue
    : () => '';
  let tooltipTitle = item.tooltip ? item.tooltip : schema.tooltip ? schema.tooltip : '';
  let valueKey = item.valueKey ? item.valueKey : schema.valueKey ? schema.valueKey : null;
  let id = item.id ? item.id : Object.keys(parentItem).length > 0 ? parentItem.id : '';
  let key = keyGenerator({ id, parentLabel, i: index, parentItem, hasItem: globalState });

  const handleChange = (value: any, id = item.id) => {
    setItemValue(value);
    if (initial) setParentValue(value);
    setGlobalState((prev) => {
      let key = keyGenerator({ id, parentLabel, i: index, parentItem, hasItem: prev });
      let get = _.get(prev, key);
      if (_.isArray(get)) index = checkID(get, id, item.keyID || schema.keyID || 'id');
      let res = _.set(
        prev,
        key,
        convertValue({
          value,
          item,
          parentLabel,
          hasItem: get,
          parentItem,
          index,
          globalState,
          setGlobalState,
          content,
        })
      );
      if (schema.dependencies && value === false) {
        res = _.set(prev, schema.dependencies, false);
      }
      return { ...res };
    });
  };

  useEffect(() => {
    // finds if the item exists and if it has a value
    if (component === 'text' || component === 'divider' || component === 'textfield')
      return;
    let key = keyGenerator({
      id,
      parentLabel,
      i: index,
      parentItem,
      hasItem: globalState,
    });
    let value = getValue(
      item.id || parentItem.id,
      key,
      undefined,
      globalState,
      item,
      schema
    );
    if (valueKey && value !== null) {
      let nestedValue = _.get(value, valueKey);
      setItemValue(nestedValue);
      if (component === 'dropdown') setDropdownValue(nestedValue);
    } else {
      setItemValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState]);

  useEffect(() => {
    if (parentValue === null) return;
    let value;
    if (
      parentItem.hasOwnProperty('key') &&
      schema.dependencies &&
      schema.dependencies === parentItem.schema.dependencies
    )
      value = getValue(
        parentItem.id,
        parentItem.key(),
        undefined,
        globalState,
        item,
        schema
      );
    if (value === true || value === false) return handleChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValue]);

  useEffect(() => {
    // finds if the item exists and if it has a value when the dropdown changes
    if (component !== 'textfield') return;
    // let key = keyGenerator({ id, parentLabel, index, parentItem, hasItem: globalState });
    let value = getValue(id, key, '', globalState, item, schema);
    valueKey && value && value[valueKey] && dropdownValue === value[item.dropdownValueKey] // must be changed
      ? setItemValue(value[valueKey])
      : setItemValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValue]);

  switch (component) {
    case 'checkbox':
      return (
        <Box
          sx={{
            minWidth: 160,
            marginLeft: 0,
          }}
          id={item.id}
        >
          <Tooltip title={tooltipTitle}>
            <Checkbox
              checked={(itemValue as boolean) || false}
              disabled={item.disabled ? item.disabled : false}
              onChange={(e) => handleChange(e.target.checked as boolean)}
              id={item.name}
              size="small"
              sx={{
                m: 0,
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'common.white'
                    : openChildren && i === index
                    ? 'common.white'
                    : 'common.greyblueDark',
                '&.Mui-checked': {
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.blueShadow['100']
                      : openChildren && i === index
                      ? 'common.white'
                      : 'common.greyblueDark',
                },
              }}
            />
          </Tooltip>
          <Typography
            variant="button"
            sx={{
              fontSize: 10,
              color:
                openChildren && i === index && state.paletteMode === 'light'
                  ? 'common.white'
                  : undefined,
            }}
          >
            {item.labelKey
              ? item[item.labelKey]
              : schema.labelKey
              ? item[schema.labelKey]
              : item.name}
          </Typography>
        </Box>
      );
    case 'text':
      return (
        <Tooltip title={tooltipTitle}>
          <Typography
            variant="button"
            sx={{
              fontSize: 10,
              flex: 3,
              minWidth: 160,
              p: 1.3,
              marginLeft: 1.5,
              color:
                openChildren && i === index && state.paletteMode === 'light'
                  ? 'common.white'
                  : undefined,
            }}
          >
            {item.labelKey
              ? item[item.labelKey]
              : schema.labelKey
              ? item[schema.labelKey]
              : item.name}
          </Typography>
        </Tooltip>
      );
    case 'divider':
      return (
        <Typography
          variant="button"
          sx={{
            fontSize: 10,
            flex: 3,
            minWidth: 160,
            p: 1.3,
            marginLeft: 0,
            color:
              openChildren && i === index && state.paletteMode === 'light'
                ? 'common.white'
                : undefined,
            textAlign: 'center',
          }}
        >
          {item.labelKey
            ? item[item.labelKey]
            : schema.labelKey
            ? item[schema.labelKey]
            : item.name}
        </Typography>
      );
    case 'dropdown':
      return (
        <FormControl
          sx={{ flex: 3, marginLeft: 0, p: 1.7, bgcolor: 'white' }}
          size="small"
        >
          <Typography variant="button" sx={{ fontSize: 10 }}>
            {item.label || item.name || 'Type'}
          </Typography>
          <Tooltip title={tooltipTitle}>
            <StyledSelect
              disabled={item.disabled ? item.disabled : false}
              value={itemValue || ''}
              onChange={(e) => {
                setItemValue(e.target.value as string);
                setDropdownValue(e.target.value as string);
                setParentLabel((prev: Array<string>) => {
                  if (prev[0] === '') {
                    prev[0] = item.id ? item.id : schema.key;
                  } else {
                    prev = _.pullAll(prev, item.options);
                    prev.push(e.target.value as string);
                  }
                  return prev;
                });
              }}
            >
              {item.options.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          </Tooltip>
        </FormControl>
      );
    case 'button': {
      return (
        <Box
          sx={{
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
          id={item.id}
        >
          <Button
            size="small"
            sx={{
              color: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.common.blue,
              fontSize: 10,
            }}
            onClick={() => item.onClick(setGlobalState, id, parentItem)}
          >
            {item.name}
          </Button>
        </Box>
      );
    }
    case 'textfield': {
      return (
        <Box
          sx={{
            p: 1.7,
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
          id={item.id}
        >
          <Typography variant="button" sx={{ fontSize: 10 }}>
            {item.label || item.name || 'Value'}
          </Typography>
          <Tooltip title={tooltipTitle}>
            <SecondStyledTexfield
              disabled={item.disabled ? item.disabled : false}
              id="outlined-basic"
              variant="outlined"
              size="small"
              fullWidth
              value={itemValue}
              onChange={(e) => {
                handleChange(e.target.value, id);
              }}
              sx={{ flex: 3 }}
            />
          </Tooltip>
        </Box>
      );
    }
    default:
      return (
        <Tooltip title={tooltipTitle}>
          <Typography
            variant="button"
            sx={{
              fontSize: 10,
              flex: 3,
              minWidth: 160,
              p: 1.3,
              marginLeft: 0,
              color:
                openChildren && i === index && state.paletteMode === 'light'
                  ? 'common.white'
                  : undefined,
            }}
          >
            {item.labelKey
              ? item[item.labelKey]
              : schema.labelKey
              ? item[schema.labelKey]
              : item.name}
          </Typography>
        </Tooltip>
      );
  }
};

export default DrawerItem;
