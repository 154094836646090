import { gql } from 'apollo-boost';

const gqlEmails = {
  SEND_EMAIL: gql`
    mutation sendEmail(
      $to: [String]
      $subject: String
      $body: String
      $recordIds: [recordIdsInput]
      $html: String
    ) {
      sendEmail(
        data: {
          to: $to
          subject: $subject
          text: $body
          recordIds: $recordIds
          html: $html
        }
      ) {
        statusCode
        error
      }
    }
  `,
};
export default gqlEmails;
