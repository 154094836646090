import { Button, IconButton, TextField, ToggleButton } from '@mui/material';
import { blueGrey, grey, orange, red } from '@mui/material/colors';
import { styled } from '@mui/system';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.common.white : blueGrey[600],
  borderRadius: '5px',
  '& .MuiInputBase-root': {
    height: '100%',
    border: 'solid 2px',
    borderColor:
      theme.palette.mode === 'light' ? '#778E9F' : theme.palette.common.greyblueDark,

    '&.Mui-disabled': {
      fontWeight: 'bold',
      backgroundColor:
        theme.palette.mode === 'light' ? theme.palette.common.lightGrey : blueGrey[300],
      borderColor: theme.palette.grey[400],
    },
  },
}));

export const StyledToggleAreaButton = styled(ToggleButton)(({ theme }) => ({
  border: `2px solid ${theme.palette.common.greyblueDark}`,
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.common.greyblueDark : blueGrey[400],
  borderRadius: '5px',
  padding: '0',
  '& .MuiIcon-root': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.white
        : theme.palette.common.slateWhite,
    margin: '10px',
  },

  '&:hover': {
    backgroundColor: theme.palette.common.greyblueDarker,
    color: theme.palette.common.orange,
  },
  '&.Mui-selected': {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.common.orange : orange[400],
  },

  '&.Mui-disabled': {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.common.lightblue : blueGrey[800],
    border: `2px solid`,
    borderColor:
      theme.palette.mode === 'light' ? theme.palette.common.lightGrey : blueGrey[800],
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: `2px solid`,
  borderColor:
    theme.palette.mode === 'light' ? theme.palette.common.greyBlue : blueGrey[400],
  borderRadius: '5px',
  padding: '0',
  '& .MuiIcon-root': {
    color: theme.palette.mode === 'light' ? theme.palette.common.greyBlue : blueGrey[400],
    margin: '10px',
  },
  '&.Mui-disabled': {
    border: `2px solid`,
    borderColor:
      theme.palette.mode === 'light' ? theme.palette.common.lightblue : blueGrey[800],
    '& .MuiIcon-root': {
      color:
        theme.palette.mode === 'light' ? theme.palette.common.lightblue : blueGrey[800],
    },
  },
}));

export const StyledDeleteIconButton = styled(IconButton)(({ theme }) => ({
  border: `2px solid`,
  borderColor: theme.palette.mode === 'light' ? theme.palette.common.negative : red[300],
  borderRadius: '5px',
  padding: '0',
  '& .MuiIcon-root': {
    color:
      theme.palette.common.mode === 'light' ? theme.palette.common.negative : red[300],
    margin: '10px',
  },
  '&.Mui-disabled': {
    border: `2px solid`,
    borderColor:
      theme.palette.mode === 'light' ? theme.palette.common.lightblue : blueGrey[800],
    '& .MuiIcon-root': {
      color:
        theme.palette.mode === 'light' ? theme.palette.common.lightblue : blueGrey[800],
    },
  },
}));

export const StyledActionButton = styled(Button)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.common.greyblueDark
      : theme.palette.common.lightGrey,
  color:
    theme.palette.mode === 'light'
      ? theme.palette.common.white
      : theme.palette.common.greyblueDark,
  width: '100%',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.common.greyblueDarker
        : theme.palette.common.lightGrey,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.white
        : theme.palette.common.greyblueDarker,
  },
}));
