import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { useContext, useState } from 'react';
import gqlRecords from '../../../../../utils/gql/gqlRecords';
import FontAwesomeIcon from '../../../../FAIcon';
import { Context as StatusContext } from '../../../../../context/statusContext';
import { Context as AuthContext } from '../../../../../context/authContext';
import { useMutation } from 'react-apollo';
import utils from '../../../../../utils/utils';
import { useLocalStorage } from '../../../../../hooks/useLocalStorage';
import { SetMessage, SetSnackBarState } from '../../../../masterTable/masterTableTypes';
import { Step } from '../../../../../containers/workflow/types';
import {
  hasRequireNoteWhenApproving,
  hasRequireNoteWhenRejecting,
} from '../../../../../containers/search/searchUtils';

const StyledBtn = styled(Button)<ButtonProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor ?? '#3A5463',
  textTransform: 'uppercase',
}));

const StyledCancelBtn = styled(Button)<ButtonProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor ?? '#3A5463',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: theme.palette.common.negative,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    textTransform: 'uppercase',
    color: '#79909F',
  },
  '& .MuiTextField-root': {
    backgroundColor: theme.palette.common.white,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
    paddingBottom: '15px',
  },
}));

interface ApproveProps {
  step: Step;
  setMessage: SetMessage;
  setSnackOpen: SetSnackBarState;
  setAssignedAction: React.Dispatch<React.SetStateAction<boolean>>;
  record: any;
}

const Approve = ({
  step,
  setMessage,
  setSnackOpen,
  setAssignedAction,
  refetch,
  record,
}: ApproveProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<string>();
  const [note, setNote] = useState<string>('');
  const [noteError, setNoteError] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { state } = useContext(StatusContext);
  const [userData] = useLocalStorage('userData');
  const { state: authState } = useContext(AuthContext);

  let folderPermissions = authState.permissions.folderAccessDetails.find((folder) => {
    let folderIDOrigin =
      state.screen === 'record' ? record.folderId.id : state.selectedFolder.search.id;
    return folder.folderID === folderIDOrigin;
  })?.folderPermissions;
  window.log(folderPermissions);
  const [UPDATE_RECORD_ASYNC] = useMutation(gqlRecords.UPDATE_RECORD_ASYNC);

  const updateStepStatus = async (values: any) => {
    if (!state.selectedRecord || !state.selectedRecord.id) return;

    const variables = {
      recordsToUpdate: {
        updateRecordInputs: [
          {
            recordId: state.selectedRecord.id,
            processStepUpdate: {
              ...values,
            },
          },
        ],
      },
      taskId: utils.generateTaskID(userData),
    };

    await UPDATE_RECORD_ASYNC({ variables })
      .then((data) => {
        setAssignedAction(false);
        window.log('res data', data);
        setMessage({
          message: 'Step Action updated successfully',
          severity: 'success',
        });
        setSnackOpen(true);
        if (typeof refetch === 'function') refetch();
      })
      .catch((error) => {
        window.log('res error', error);
        setMessage({
          message: 'Error trying to update step action',
          severity: 'error',
        });
        setSnackOpen(true);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNoteError(false);
    setNote('');
  };
  const requireNoteWhenApproving = hasRequireNoteWhenApproving(
    authState.permissions,
    userData.id,
    state.screen === 'record' ? record?.folderId : state.selectedFolder[state.screen],
    false
  );
  const requireNoteWhenRejecting = hasRequireNoteWhenRejecting(
    authState.permissions,
    userData.id,
    state.screen === 'record' ? record?.folderId : state.selectedFolder[state.screen],
    false
  );
  const handleApprove = () => {
    let approvalStatus;
    if (action === 'reject') approvalStatus = 'Rejected';
    if (action === 'approve') approvalStatus = 'Approved';

    updateStepStatus({
      ApprovalStep: {
        id: step.id,
        approvalStatus,
        approvalNotes: [{ content: note }],
      },
    });
    handleClose();
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="end">
        <StyledBtn
          variant="contained"
          startIcon={
            <FontAwesomeIcon
              icon="fas fa-check-circle"
              size="12px"
              color="common.green"
            />
          }
          onClick={() => {
            handleClickOpen();
            setAction('approve');
          }}
        >
          Approve
        </StyledBtn>

        {step && (
          <Typography
            aria-label="step number"
            variant="button"
            sx={{ fontSize: 18, height: '26px' }}
          >
            STEP {step.stepOrder + 1}
          </Typography>
        )}
        <StyledBtn
          variant="contained"
          startIcon={
            <FontAwesomeIcon
              icon="fas fa-times-circle"
              size="12px"
              color="common.negative"
            />
          }
          onClick={() => {
            handleClickOpen();
            setAction('reject');
          }}
        >
          Reject
        </StyledBtn>
      </Stack>
      <StyledDialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        sx={{
          marginTop: fullScreen && '44px',
        }}
        PaperProps={{
          sx: {
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'black.dark' : 'common.greyblueDarker',
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">{'Add a note'}</DialogTitle>
        <Divider sx={{ margin: '0 10px' }} />
        <DialogContent>
          <Box minWidth={fullScreen ? '0' : '550px'}>
            <TextField
              fullWidth
              id={`${action}-note`}
              multiline
              rows={6}
              variant="outlined"
              placeholder="Leave a note here..."
              onChange={(e) => setNote(e.target.value as string)}
              /*               onBlur={(e) => {
                setNote(e.target.value as string);
              }} */
              error={true}
              helperText={
                requireNoteWhenRejecting || requireNoteWhenApproving
                  ? `Note required to ${action}`
                  : ''
              }
              sx={{
                '& .MuiInputBase-root': {
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'common.greyblueDarker' : undefined,
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <StyledCancelBtn variant="contained" onClick={handleClose}>
            Cancel
          </StyledCancelBtn>
          <Stack direction="row" gap={2} alignItems="center">
            <StyledBtn
              variant="contained"
              onClick={handleApprove}
              disabled={note === '' ? true : false}
              startIcon={
                <FontAwesomeIcon
                  icon={
                    action === 'approve' ? 'fas fa-check-circle' : 'fas fa-times-circle'
                  }
                  size="12px"
                  color={action === 'approve' ? 'common.green' : 'common.negative'}
                />
              }
            >
              {action?.toUpperCase()}
            </StyledBtn>
          </Stack>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default Approve;
