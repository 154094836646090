import { Dispatch, SetStateAction } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Workflow } from '../types';
import { styled } from '@mui/system';

const StyledInput = styled(TextField)(({ theme }) => ({
  border: 'none',
  '& input': {
    backgroundColor: theme.palette.common.greyblueDarker,
    color: theme.palette.common.white,
    fontSize: 14,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 0,
      border: 'none',
      borderBottom: '1px solid',
      borderColor: theme.palette.common.greyblueDark,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.common.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.common.greyblueDark,
    },
  },

  '& .MuiAutocomplete-endAdornment': {
    '& .MuiButtonBase-root': {
      color: theme.palette.common.white,
    },
  },
}));

interface TemplateSelectorProps {
  workflows: Workflow[];
  setSelectedWorkflow: Dispatch<SetStateAction<Workflow | null>>;
}

const TemplateSelector = ({ workflows, setSelectedWorkflow }: TemplateSelectorProps) => {
  const handleChange = (
    event: React.SyntheticEvent,
    value: { label: string; id: string } | null
  ) => {
    if (!value) {
      setSelectedWorkflow(null);
      return;
    }
    const selectedWorkflow = workflows.find((el) => el.id === value.id);
    setSelectedWorkflow(selectedWorkflow ?? null);
  };

  // Generate the options structure
  const options = workflows.map((workflow) => ({
    label: workflow.name,
    id: workflow.id,
  }));

  return (
    <Autocomplete
      id="template-selector"
      options={options}
      sx={{ width: 300 }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <StyledInput {...params} variant="outlined" placeholder="Select Template" />
      )}
      onChange={handleChange}
    />
  );
};

export default TemplateSelector;
