import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { SignatureCardProps } from '../../types';
import Base from './Base';


const SignatureCard = ({ handleRemoveStep, step }: SignatureCardProps) => {
  let { stepOrder, type, id } = step;
  return (
    <Base step={step}>
      <>
        <CardHeader
          titleTypographyProps={{ sx: { color: 'common.white' } }}
          action={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                with: 20,
                color: 'common.white',
              }}
            >
              <IconButton>
                <FontAwesomeIcon icon="fal fa-check-circle" color="#ffffff" />
              </IconButton>
              <Typography
                sx={{
                  color: '#79909F',
                  textTransform: 'uppercase',
                  fontWeight: 400,
                  fontSize: 11,
                }}
              >
                Required
              </Typography>
            </Box>
          }
          title={
            <Typography
              sx={{
                color: '#79909F',
                textAlign: 'center',
                marginRight: '30px',
                fontWeight: 700,
                fontSize: 16,
                textTransform: 'uppercase',
              }}
            >
              {type}
            </Typography>
          }
          sx={{
            flexDirection: 'row-reverse',
            backgroundColor: 'common.greyblueDarker',
          }}
        >
          header
        </CardHeader>
        <CardContent sx={{ flex: 10 }}>content</CardContent>
        <CardActions
          sx={{
            backgroundColor: 'common.greyblueDark',
            height: 30,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            onClick={() => handleRemoveStep(stepOrder, id)}
          >
            <Button
              size="small"
              endIcon={
                <Box>
                  <FontAwesomeIcon
                    icon="fal fa-trash"
                    color="common.negativeDark"
                    size={11}
                  />
                </Box>
              }
              sx={{
                color: 'common.white',
                textTransform: 'uppercase',
                fontWeight: 500,
                fontSize: 10,
                '&:hover': {
                  color: 'common.negativeDark',
                },
              }}
            >
              Remove
            </Button>
          </Box>
        </CardActions>
      </>
    </Base>
  );
};

export default SignatureCard;
