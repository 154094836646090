import React from 'react';
import { StyledActionBar } from '../styles/dashStyle';

function ActionBar(props) {
    return (
        <StyledActionBar>
            {props.children}
        </StyledActionBar>
    )
}

export default ActionBar;