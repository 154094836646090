import { Dispatch, SetStateAction } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const classes = {
  text: {
    fontWeight: 400,
  },
};

interface Props {
  setTimeStamp: Dispatch<SetStateAction<string>>;
}

export default function TimeStampButtons({ setTimeStamp }: Props) {
  return (
    <Stack direction="row" justifyContent="end" spacing={0}>
      <Button
        variant="text"
        size="small"
        onClick={() => setTimeStamp('week')}
        sx={classes.text}
      >
        Week
      </Button>
      <Button
        variant="text"
        size="small"
        onClick={() => setTimeStamp('month')}
        sx={classes.text}
      >
        Month
      </Button>
      <Button
        variant="text"
        size="small"
        onClick={() => setTimeStamp('year')}
        sx={classes.text}
      >
        Year
      </Button>
    </Stack>
  );
}
