import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { StyledUsersPage } from '../styles/dashStyle';
import UsersMain from '../containers/user/UsersMain';
import UserProfile from '../containers/user/userProfile/UserProfile';

function Users() {
  return (
    <StyledUsersPage>
      <Switch>
        <Route exact path="/dash/users" component={UsersMain} />
        <Route exact path="/dash/users/:id" component={UserProfile} />
      </Switch>
    </StyledUsersPage>
  );
}

export default Users;
