import React from 'react';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

function stringAvatar(name: string) {
  return {
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

interface UserAvatarProps {
  firstName: string;
  lastName: string;
  connected?: boolean;
  fontSize: string | number;
  width: string | number;
  height: string | number;
}

export default function UserAvatar({
  firstName,
  lastName,
  connected,
  fontSize,
  width,
  height,
}: UserAvatarProps) {
  const badgeStatus: string = connected ? 'common.positive' : 'common.negative';
  
  return (
    <Grid container direction="column" spacing={1} alignItems="center">
      <Grid item>
        <Avatar
          {...stringAvatar(firstName.toUpperCase() + ' ' + lastName.toUpperCase())}
          sx={{
            fontSize: { fontSize },
            width: { width },
            height: { height },
            bgcolor: 'common.greyblueDarker',
            color: 'lightblue',
          }}
        />
      </Grid>
    </Grid>
  );
}
