import { TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React, { useEffect, useRef } from 'react';

const StringCell = (props: GridRenderEditCellParams) => {
  let { field, id, value } = props;
  const inputRef = useRef<HTMLInputElement>();
  const apiRef = useGridApiContext();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    apiRef.current.setEditCellValue({ id, field, value: e.target.value });
  };

  useEffect(() => {
    inputRef && inputRef.current?.focus();
  }, []);

  return (
    <TextField
      defaultValue={value.trim()}
      onChange={handleChange}
      focused
      inputRef={inputRef}
    />
  );
};

export default StringCell;
