import {
  Box,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import React from 'react';
import FontAwesomeIcon from '../../../components/FAIcon';
import { StyledList } from '../FilterSelectionDrawer';
import {
  FolderField,
  SelectedFolder,
} from '../../../components/masterTable/masterTableTypes';
import { useFormContext } from 'react-hook-form';
import { Filter } from '../ReportingSideBar';

const SearchFilterDrawer = ({
  searchDrawerIsOpen,
  onClose,
  selectedFolder,
  handleSelection,
}: {
  searchDrawerIsOpen: boolean;
  onClose: () => void;
  selectedFolder: SelectedFolder | null;
  handleSelection: (option: Filter) => void;
}) => {
  const { watch } = useFormContext();

  const handleSelectField = (folderField: FolderField) => {
    handleSelection({
      label: `Search Field (${folderField.name})`,
      id: folderField.id,
      type: 'all',
      value: {
        folderFieldId: folderField.id,
      },
    });
    onClose();
  };

  if (!selectedFolder) return null;

  return (
    <Drawer
      id="action-drawer"
      variant="persistent"
      anchor={'right'}
      open={searchDrawerIsOpen}
      onClose={onClose}
      sx={{
        backgroundColor: 'common.greyblueDarker',
        '& 	.MuiDrawer-paper': {
          backgroundColor: 'common.greyblueDarker',
          position: 'absolute',
          height: '100%',
          width: '100%',
        },
      }}
    >
      <Box sx={{ textAlign: 'end', padding: '5px' }}>
        <Tooltip title="Close">
          <IconButton
            aria-label="close drawer"
            onClick={() => {
              onClose();
            }}
          >
            <FontAwesomeIcon icon="fas fa-times" color="common.white" size={'16px'} />
          </IconButton>
        </Tooltip>
      </Box>
      <Stack spacing={2} alignItems="center">
        <StyledList sx={{ width: '90%' }}>
          {selectedFolder.folderFields.map((ff) => {
            if (ff.name === 'createdAt') return null;
            let formFilters: Filter[] = watch('selectedTemplate.variables.filters');
            const isAlreadySelected = formFilters
              ? formFilters.find((filter) => filter?.value?.folderFieldId === ff.id)
              : false;
            return (
              <ListItem disablePadding key={ff.id}>
                <ListItemButton
                  onClick={(e) => handleSelectField(ff)}
                  disabled={isAlreadySelected ? true : false}
                >
                  <ListItemText primary={ff.name} sx={{ textAlign: 'center' }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </StyledList>
      </Stack>
    </Drawer>
  );
};

export default SearchFilterDrawer;
