import { Grid } from '@mui/material';
import FilterInputs from './filterInputs';
import KeyValueFilterInput from './KeyValueFilterInput';

const classes = {
  folderFiltersWrapper: {
    padding: '12px',
    minHeight: '40px',
    width: '100%',
    marginBottom: '4px',
    overflow: 'auto',
    overflowX: 'hidden',
    paddingTop: '10px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#eaeaea',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bdbcbc',
      borderRadius: '10px',
    },
  },
};

const FolderFieldsFilters = ({
  register,
  control,
  fields,
  selectedFolder,
  isResetted,
  setResetted,
  setFieldValues,
  fieldValues,
  activeFields,
}) => {
  // const [activeFields, setActiveFields] = useState([]);
  return (
    <Grid item container sx={classes.folderFiltersWrapper}>
      {activeFields &&
        activeFields.map((field, i) => {
          return (
            <FilterInputs
              key={`${field}${i}`}
              id={i}
              field={field}
              register={register}
              control={control}
              selectedFolder={selectedFolder}
              isResetted={isResetted}
              setResetted={setResetted}
              setFieldValues={setFieldValues}
              fieldValues={fieldValues}
            />
          );
        })}
    </Grid>
  );
};

export default FolderFieldsFilters;
