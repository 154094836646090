import { Route, Switch } from 'react-router-dom';
import { StyledRecordsPage } from '../styles/dashStyle';
import SearchMain from '../containers/search/SearchMain';
import RecordCreationContainer from '../containers/records/RecordCreationContainer';
import RecordPage from '../containers/records/RecordPage';

function Records() {
  return (
    <StyledRecordsPage>
      <Switch>
        <Route exact path="/dash/search" component={SearchMain} />
        <Route path="/dash/records/newrecord" component={RecordCreationContainer} />
        <Route exact path="/dash/search/:id" component={RecordPage} />
        <Route exact path="/dash/search/:folder/:id" component={RecordPage} />
      </Switch>
    </StyledRecordsPage>
  );
}

export default Records;
