import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import UsersList from './UsersList';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import GeneralSnackbar, { StatusProps } from '../../components/SnackBar';
import { OrganizationItemList } from '../../screens/Communication';
import { Context as RTCContext } from '../../context/RTCContext';
import { add } from 'date-fns';
import StyledSwitch from '../../components/form/StyledSwitch';

interface CreateNotificationTabProps {
  orgList: Array<OrganizationItemList>;
}

export interface FormValues {
  id: null | string;
  message: string;
  notificationType: 'popup' | 'toolbar';
  sendToAllUsers: boolean;
  sendLater?: string;
  noUsers?: any;
  expirationTime: number;
}

const generateExpirationTime = (timeToAdd: number, sendLater: any) => {
  let now = Date.now();
  if (sendLater) now = new Date(sendLater).getTime();
  let res = add(now, { seconds: timeToAdd });
  let expirationTime: string = new Date(res).toISOString();
  return expirationTime;
};

const CreateNotificationTab = ({ orgList }: CreateNotificationTabProps) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      id: null,
      message: '',
      notificationType: 'popup',
      sendToAllUsers: false,
      expirationTime: 86400,
    },
  });
  const [date, setDate] = React.useState<Date | number>(Date.now());
  const [usersList, setUsersList] = useState<Array<string>>([]);
  const [blackList, setBlackList] = useState<Array<string>>([]);
  const [messageLength, setMessageLength] = useState<number>(0);
  const [async, setAsync] = React.useState<boolean>(false);
  const rtcContext = useContext(RTCContext);
  const [snackbar, setSnackbar] = React.useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'success',
  });

  const handleCloseSnackbar = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ status: false, snackbarMessage: '', severity: 'success' });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAsync(event.target.checked);

  const handleChangeDate = (newValue: Date) => {
    setDate(newValue);
    let date = new Date(newValue);
    let formatDate = date.toISOString();
    setValue('sendLater', formatDate);
  };

  const onSubmit = (values: FormValues) => {
    if (values.sendToAllUsers === false && usersList.length === 0)
      return setSnackbar({
        status: true,
        snackbarMessage: 'No users check',
        severity: 'error',
      });
    let ID = `${values.notificationType[0].toUpperCase()}-${Date.now()}`;
    let expirationTime = generateExpirationTime(values.expirationTime, values.sendLater);
    let newValues = {
      ...values,
      id: ID,
      usersToSend: values.sendToAllUsers ? [] : usersList, // if 'sendToAllUsers' option is checked, clear user list
      blackList,
      expirationTime,
    };
    // window.log('new values', newValues);
    rtcContext.createNotification(newValues);
  };

  useEffect(() => {
    // clears the error 'noUsers' after 3.5 seconds
    if (!errors.noUsers) return;
    let time = setTimeout(() => {
      clearErrors('noUsers');
    }, 3500);
    return () => clearTimeout(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.noUsers]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          sx={{
            backgroundColor: 'common.greyblueDarker',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderInlineStart: '3px solid #cea323',
            boxShadow: { sm: '0px 1px 0px 0px #ffffff96 inset', md: 'none' },
            padding: '5px 15px',
            height: '30px',
          }}
        >
          <Typography variant="caption" color="WHITE">
            CREATE NOTIFICATION
          </Typography>
        </Box>
        <form
          style={{
            display: 'flex',
            height: 'calc(100% - 30px)',
            width: '100%',
            flex: 30,
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <UsersList
            orgList={orgList}
            register={register}
            usersList={usersList}
            setUsersList={setUsersList}
            blackList={blackList}
            setBlackList={setBlackList}
            resetField={resetField}
          />
          <Box
            sx={{
              p: 6,
              height: { xs: '100%', md: '100%' },
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                {...register('message', {
                  maxLength: {
                    value: 180,
                    message: 'Message must be less than 180 characters.',
                  },
                  required: {
                    value: true,
                    message: 'Message is required',
                  },
                })}
                label="Message"
                name="message"
                variant="outlined"
                type="text"
                multiline
                fullWidth
                inputProps={{ style: { height: '130px', padding: '0 14px' } }}
                onChange={(e) => {
                  clearErrors('message');
                  setMessageLength(e.target.value.length);
                }}
                helperText={
                  errors.message
                    ? `${errors.message.message} - ${messageLength}/180`
                    : `${messageLength}/180`
                }
                error={messageLength > 180 || errors.message ? true : false}
                sx={{ '.MuiFormHelperText-root': { textAlign: 'right' } }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' },
                  justifyContent: 'space-between',
                  gap: { xs: 1, sm: 1, md: 3 },
                  flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                  width: '50%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                  }}
                >
                  <StyledSwitch checked={async} onChange={handleChange} />
                  <Typography variant="caption">Schedule</Typography>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Time"
                    value={date}
                    onChange={(value) => handleChangeDate(value as Date)}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{
                          width: { sx: '93%', sm: '93%', md: '48%', lg: '48%' },
                          visibility: async ? 'visible' : 'hidden',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'column', lg: 'row' },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    width: { xs: '100%', sm: '100%', md: '50%' },
                    flexDirection: { xs: 'column', md: 'row', lg: 'row' },
                  }}
                >
                  <FormControl
                    variant="outlined"
                    sx={{ margin: '0 auto', width: '100%' }}
                    error={errors.notificationType && true}
                  >
                    <InputLabel id="notificationType">Notification type</InputLabel>
                    <Select
                      {...register('notificationType', {
                        required: { value: true, message: 'Required' },
                      })}
                      id="notificationType"
                      labelId="notificationType"
                      defaultValue="popup"
                      label="Notification type"
                      size="small"
                      error={errors.notificationType && true}
                    >
                      <MenuItem value="popup">POPUP</MenuItem>
                      {/* <MenuItem value="onlyPopup">ONLY POPUP</MenuItem> */}
                      <MenuItem value="toolbar">TOOLBAR</MenuItem>
                    </Select>
                    {errors.notificationType && (
                      <FormHelperText>{errors.notificationType.message}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    sx={{ margin: '0 auto', width: '100%' }}
                  >
                    <InputLabel id="notificationType">Expiration Time</InputLabel>
                    <Select
                      {...register('expirationTime', {
                        required: true,
                      })}
                      id="expirationTime"
                      defaultValue={86400}
                      label="Notification type"
                      size="small"
                      error={errors.expirationTime && true}
                    >
                      <MenuItem value={5}>5 sec</MenuItem>
                      <MenuItem value={10}>10 sec</MenuItem>
                      <MenuItem value={3600}>1 hour</MenuItem>
                      <MenuItem value={28800}>8 hours</MenuItem>
                      <MenuItem value={86400}>1 day</MenuItem>
                      <MenuItem value={259200}>3 days</MenuItem>
                      <MenuItem value={604800}>1 week</MenuItem>
                      <MenuItem value={1209600}>2 weeks</MenuItem>
                      <MenuItem value={2629743}>1 month</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignSelf: 'self-end' }}>
                  <Button variant="outlined" type="reset" sx={{ marginRight: 1 }}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ backgroundColor: 'common.greyblueDarker' }}
                  >
                    Create
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
      <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
    </>
  );
};

export default CreateNotificationTab;
