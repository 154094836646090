import React, { memo, MutableRefObject, useContext } from 'react';

import { Box, Container } from '@mui/material';
import UserCreationForm from './UserCreationForm';
import { TabTitle } from '../UserPopup';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useQuery } from 'react-apollo';
import { GET_FOLDERS, GET_FOLDERS_BY_ORG } from '../../../utils/gql/gqlFolders';
import { Context as StatusContext } from '../../../context/statusContext';
import { AuthContextTypes, Context as AuthContext } from '../../../context/authContext';
import { Context as FormContext, FormContextTypes } from './formContext';
import { generateInitialPermissions } from '../userProfile/components/UserPermissionsTab/structure';
import { StatusContextTypes } from '../../../context/statusContextTypes';
import _ from 'lodash';

interface ProfileTabProps {
  handleCloseModal: () => void;
  tabsValue: number;
  index: number;
  formRef: MutableRefObject<null>;
  setHasErrors: React.Dispatch<
    React.SetStateAction<{ profile: boolean; permissions: boolean }>
  >;
}

const classes = {
  dialogTitle: {
    textAlign: 'center',
    padding: { xs: '5px 0', md: '6px 0' },
    fontSize: { xs: 17, md: 20 },
    backgroundColor: 'common.greyblueDark',
    fontWeight: 'bold',
  },
  container: {
    md: { maxHeight: '680px', height: '610px', minWidth: '900px', width: '750px' },
    xs: { maxHeight: '700px', height: '280px', minWidth: '320px', width: '450px' },
  },
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const ProfileTab = ({
  handleCloseModal,
  tabsValue,
  index,
  formRef,
  setHasErrors,
}: ProfileTabProps) => {
  const { state: statusState } = useContext(StatusContext) as StatusContextTypes
  const authContext = useContext(AuthContext) as AuthContextTypes
  const { updateForm } = useContext(FormContext) as FormContextTypes
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;
  const { data } = useQuery(isSuser ? GET_FOLDERS_BY_ORG : GET_FOLDERS, {
    variables: isSuser
      ? {
          organisationId: statusState.selectedOrg && _.isObject(statusState.selectedOrg)
            ? statusState.selectedOrg.id
            : selOrg.id,
        }
      : null,
    onCompleted: (data) =>
      updateForm({
        field: 'permissions',
        value: generateInitialPermissions(isSuser ? data.FoldersByOrg : data.Folders),
      }),
  });

  return (
    <>
      <TabPanel value={tabsValue} index={index}>
        <TabTitle title="Create User" handleClose={handleCloseModal} />
        <Container sx={classes.container}>
          <UserCreationForm
            formRef={formRef}
            setHasErrors={setHasErrors}
          ></UserCreationForm>
        </Container>
      </TabPanel>
    </>
  );
};

export default memo(ProfileTab);
