import { Stack, Typography } from '@mui/material';

import FontAwesomeIcon from '../../../../../components/FAIcon';

interface Props {
  icon: string;
  color: string;
  type: string;
  value: string;
}

export default function TooltipContentDataValue({
  icon = 'fas fa-circle',
  color,
  type,
  value,
}: Props) {
  return (
    <Stack direction="row" spacing={2} key={type} alignItems="center">
      <FontAwesomeIcon size="10px" icon={icon} color={color} />
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: 12,
          fontWeight: 400,
          textTransform: 'capitalize',
          color: '#000',
        }}
      >{`${type}: `}</Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: 12, fontWeight: 700, color: '#000' }}
      >
        {value}
      </Typography>
    </Stack>
  );
}
