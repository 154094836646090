import { Avatar, Box, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { SelectedFolder } from '../../components/masterTable/masterTableTypes';
import { User } from '../comm/ListItemOrg';
import { Header, Record } from '../search/types';
import { Template } from './ReportingSideBar';

export const getHeaders = (
  records: any[],
  setHeaders: React.Dispatch<React.SetStateAction<Header[]>>,
  type: string,
  folder: any
) => {
  if (records && records.length) {
    let additionalHeaders: any[] = [];
    let visibleHeaders = [];

    if (folder && folder.folderFields) {
      visibleHeaders = folder.folderFields.filter((field) => field.active);
    }

    additionalHeaders.push(
      {
        id: 'assignedBy',
        name: 'assignedBy',
        type: 'string',
      },
      {
        id: 'assignedAt',
        name: 'assignedAt',
        type: 'string',
      }
    );

    if (type === 'Approved' || type === 'Rejected' || type === 'Unapproved') {
      if (type !== 'Unapproved') {
        additionalHeaders.push({
          id: type === 'Rejected' ? 'rejectedBy' : 'performedBy',
          name: type === 'Rejected' ? 'rejectedBy' : 'performedBy',
          type: 'string',
        });
      } else {
        additionalHeaders.push({
          id: 'toBePerformedBy',
          name: 'toBePerformedBy',
          type: 'string',
        });
      }
    } else {
      additionalHeaders.push(
        {
          id: 'workflowStatus',
          name: 'workflowStatus',
          type: 'string',
        },
        {
          id: 'performedBy',
          name: 'performedBy',
          type: 'string',
        }
      );
    }

    if (records.length === 0 || !folder || Object.keys(folder).length === 0) return;
    let createdAt = folder.folderFields.filter((field) => field.name === 'createdAt');
    if (createdAt.length === 0) {
      let created = {
        __typename: 'FolderField',
        id: 'CreatedAtID',
        active: true,
        name: 'createdAt',
        type: 'datetime',
        isCreatedAt: 'true',
      };
      visibleHeaders = [...visibleHeaders, created];
    }

    setHeaders([...visibleHeaders, ...additionalHeaders]);
  }
};

export const generateRows = (
  record: Record[],
  users: User[],
  type: string,
  toPDF = false
) => {
  if (!users || !record) return [];
  return record.map((rec) => {
    let lastProcessStep =
      rec.processPath.processSteps[rec.processPath.processSteps.length - 1];
    let user = (id: string) => {
      let user = users.find((usr) => usr.id === id);
      return user ? `${user?.firstName} ${user?.lastName}` : '';
    };
    return {
      ...rec,
      fields: [
        ...rec.fields,
        // If custom headers have been added, we need to set their values
        {
          field: {
            id: 'assignedBy',
            name: 'assignedBy',
            type: 'string',
          },
          value: user(lastProcessStep.assignedBy.userId),
        },
        {
          field: {
            id: 'assignedAt',
            name: 'assignedAt',
            type: 'string',
          },
          value: format(
            new Date(lastProcessStep.assignedBy.timeStamp),
            'dd/MM/yyyy HH:mm'
          ),
        },
        {
          field: {
            id: 'workflowStatus',
            name: 'workflowStatus',
            type: 'string',
          },
          value: lastProcessStep.approvalStatus,
        },
        type !== 'Unapproved'
          ? {
              field: {
                id: type === 'Rejected' ? 'rejectedBy' : 'performedBy',
                name: type === 'Rejected' ? 'rejectedBy' : 'performedBy',
                type: 'string',
              },
              value:
                lastProcessStep.usersWhoHavePerformedApproval.length === 0
                  ? ''
                  : user(
                      lastProcessStep.usersWhoHavePerformedApproval[
                        lastProcessStep.usersWhoHavePerformedApproval.length - 1
                      ].userId
                    ),
            }
          : null,
        type === 'Unapproved'
          ? {
              field: {
                id: 'toBePerformedBy',
                name: 'toBePerformedBy',
                type: 'string',
              },
              arrayValue: lastProcessStep.toBeApprovedBy.map((usr: any) => user(usr)),
              value: toPDF ? (
                `${user(lastProcessStep.toBeApprovedBy[0])} ${
                  lastProcessStep.toBeApprovedBy.length > 1
                    ? `+${lastProcessStep.toBeApprovedBy.length - 1}`
                    : ''
                }`
              ) : lastProcessStep.toBeApprovedBy.length !== 0 ? (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {user(lastProcessStep.toBeApprovedBy[0])}
                  {lastProcessStep.toBeApprovedBy.length > 1 ? (
                    <Tooltip
                      title={lastProcessStep.toBeApprovedBy.map(
                        (usr: string, index: number) => (
                          <>
                            {index !== 0 && <br />}
                            {user(usr)}
                          </>
                        )
                      )}
                    >
                      <Avatar sx={{ width: 20, height: 20, fontSize: '10px' }}>
                        +{lastProcessStep.toBeApprovedBy.length - 1}
                      </Avatar>
                    </Tooltip>
                  ) : null}
                </Box>
              ) : (
                ''
              ),
            }
          : null,
        {
          field: {
            id: 'createdAt',
            name: 'createdAt',
            type: 'string',
          },
          value: rec.createdAt,
        },
      ].filter((item) => item !== null),
    };
  });
};

export const getReportVariables = (
  variables: Template['variables'],
  limit: number,
  currentPage: number,
  selectedFolder: SelectedFolder | null,
  type: 'linked_information' | 'workflow',
  sorting: string
) => {
  // Create variables structure that BE needs
  let res = { data: {} };

  const reportType = variables.filters.find(
    (filter: any) => filter.type === 'ReportTypeEnum'
  );

  let isLinkedReport = type === 'linked_information';
  let reportValue = reportType?.value;

  res.data = {
    ...res.data,
    ReportTypeEnum: isLinkedReport ? 'LinkedInformation' : reportValue || 'Approved',
    paginationDetails: { limit, currentPage, sortByField: sorting },
    folderId: selectedFolder?.id || null,
  };

  if (isLinkedReport) {
    let linkedInformationReportDetails: any = {};

    const fieldDateRange = variables.filters
      .filter((filter: any) => filter.type === 'dateRange')
      .map((el) => {
        return {
          fieldname: el.fieldname,
          dateRange: {
            from: el.value.from,
            to: el.value.to,
          },
        };
      });

    // Add optional date range field
    if (fieldDateRange.length > 0) {
      linkedInformationReportDetails.fieldDateRange = fieldDateRange;
    }

    if (reportValue === 'LinkedInformation') {
      linkedInformationReportDetails.synced = reportValue === 'LinkedInformation';
    } else if (reportValue === 'LinkedInfoException') {
      linkedInformationReportDetails.synced = false;
    }

    console.log('reportValue', reportValue);
    console.log('linkedInformationReportDetails', linkedInformationReportDetails);
    res.data = {
      ...res.data,
      linkedInformationReportDetails,
    };
  }

  if (!isLinkedReport) {
    res.data = {
      ...res.data,
      approvalReportDetails: variables.filters.reduce((acc, current) => {
        if (current.type === 'ReportTypeEnum') return acc;
        return { ...acc, [current.type]: current.value };
      }, {}),
    };
  }

  return res;
};
