import React from 'react';
import FolderCardCompact from './FolderCardCompact';
import FolderCardExtend from './FolderCardExtend';
import { FolderCardsProps } from './types';

const FolderCards = ({ folder, folderCompact }: FolderCardsProps) => {
  return (
    <>
      {folderCompact ? (
        <FolderCardCompact folder={folder} />
      ) : (
        <FolderCardExtend folder={folder} />
      )}
    </>
  );
};

export default FolderCards;
