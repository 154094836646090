import { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { Context as StatusContext } from '../../context/statusContext';
import { GET_ORGS } from '../../utils/gql/gqlOrg';
import { StatusContextTypes } from '../../context/statusContextTypes';
import FontAwesomeIcon from '../../components/FAIcon';
import GeneralSnackbar, { HandleClose, StatusProps } from '../../components/SnackBar';
import OrgPopup from './orgPopup/OrgPopup';
import OrgCard from './OrgCard';
import OrgToolbar from './OrgToolbar';

export interface Org {
  id: string;
  name: string;
  address?: string;
  phoneNumber?: string;
  createdBy?: {
    firstName: string;
  };
  country?: string;
  processPathEnabled?: boolean;
  createdAt?: any;
  active?: boolean;
}

const styles = {
  rootContainer: {
    height: '100%',
    padding: 1.2,
  },
};

const OrgMain = () => {
  const widthMatches = useMediaQuery('(min-width:1280px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [selectedOrg, setSelectedOrg] = useState<false | Org>(false);
  const [processPathEnabled, setProcessPathEnabled] = useState<boolean>(false);
  const { setScreen } = useContext(StatusContext) as StatusContextTypes;
  const [snackbarStatus, setSnackbarStatus] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: undefined,
  });

  const { data, refetch } = useQuery(GET_ORGS);

  let orgs;

  if (data) orgs = data.Organisations?.organisations;

  let actions = [
    {
      tooltipTitle: 'Add organisation',
      buttonIcon: (
        <Box sx={{ height: 24, paddingTop: '2px' }}>
          <FontAwesomeIcon icon="fas fa-plus" size={20} />
        </Box>
      ),
      buttonOnClick: () => setIsDrawerOpen(true),
    },
  ];

  const handleCloseSnack: HandleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarStatus({
      status: false,
      snackbarMessage: '',
      severity: 'success',
    });
  };

  // Match users data field names for filtering
  const keys = ['name', 'address', 'phoneNumber', 'country'];

  // This function filters through an array of objects and uses a keys array for object filtering
  // More fields names can be added to the keys array to filter through more fields
  const filterOrgs = (data: Org[]) => {
    if (!data || !searchQuery) return;
    return data.filter((item: any) =>
      keys.some((key) => item[key].toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  // If there is a searchQuery calls the filter function and asigns the result to users
  if (searchQuery) orgs = filterOrgs(data.Organisations?.organisations);

  useEffect(() => {
    setScreen('organisations');
  }, []);

  return (
    <Box sx={{ height: '100%' }}>
      <Grid container direction="column" sx={styles.rootContainer}>
        <Grid item container sx={{ height: '100%' }}>
          <Grid
            item
            xs={widthMatches ? (isDrawerOpen ? 12 : 12) : 12}
            sx={{ height: '100%' }}
          >
            <Grid container alignItems="center" sx={{ marginBottom: 3 }}>
              <OrgToolbar
                setSearchQuery={setSearchQuery}
                handleSearch={handleSearch}
                actions={actions}
              />
            </Grid>
            <Grid item container>
              {orgs && orgs.map((org: Org) => <OrgCard org={org} key={org.id} />)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <OrgPopup
        setSnackbarStatus={setSnackbarStatus}
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
        refetch={refetch}
        setIsDrawerOpen={setIsDrawerOpen}
        setProcessPathEnabled={setProcessPathEnabled}
        processPathEnabled={processPathEnabled}
        isDrawerOpen={isDrawerOpen}
      />
      <GeneralSnackbar status={snackbarStatus} handleClose={handleCloseSnack} />
    </Box>
  );
};
export default OrgMain;
