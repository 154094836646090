import { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Typography,
  Switch,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Collapse,
  Modal,
  IconButton,
  Stack,
  LinearProgress,
} from '@mui/material';
import FontAwesomeIcon from '../../../FAIcon';
import utils from '../../../../utils/utils';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import Papa from 'papaparse';
import { SelectedFolder, User } from '../../masterTableTypes';
import { useGridApiContext } from '@mui/x-data-grid';
import { generateData, generateRowsByConfig } from './utils';
import gqlRecords from '../../../../utils/gql/gqlRecords';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { Context as StatusContext } from '../../../../context/statusContext';
import { StatusContextTypes } from '../../../../context/statusContextTypes';
import LoadingOverlay from '../LoadingOverlay';
import { generateRows } from '../../../../containers/reporting/utils';
import { RenderedPDFViewer } from './pdf-worker/RenderedPDFViewer';
import { saveAs } from 'file-saver';
import { useRenderPDF } from './pdf-worker/useRenderPDF';
import WarningDialog from './WarningDialog';

const styles = {
  pageContainer: { marginTop: '20px' },
  absolute: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
  },
  viewerBox: {
    height: '60vh',
    width: '720px',
    border: 'none',
    backgroundColor: 'common.greyblueDarker',
  },
  progressBox: {
    height: '70vh',
    width: '720px',
    backgroundColor: 'rgb(50 54 57)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  body: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        borderColor: 'rgb(232, 245, 255)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(232, 245, 255)',
      },
    },
    '& .MuiInputBase-input': {
      backgroundColor: '#79909f',
      color: '#fff',
      borderRadius: '4px',
      padding: 2,
    },
  },
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: 0,
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: theme.palette.common.greyblueDarker,
    },
    '&.MuiButtonBase-root.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.greyblueDarker,
      '.MuiIcon-root': {
        color: theme.palette.common.greyblueDarker,
      },
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&.MuiButtonBase-root': {
      margin: 0,
      color: theme.palette.common.orange,
    },
    '&.MuiButtonBase-root .MuiIcon-root': {
      marginRight: '5px',
    },
  },
}));

export type PDFProps = {
  rows: any[];
  cols: {
    [key: string]: any;
  }[];
  folder: SelectedFolder;
  orientation: string;
  screen: string;
  selectedTemplate: any;
  pdfIsRendered: boolean;
};

interface ReportModalProps {
  rows: any[];
  cols: {
    [key: string]: any;
  }[];
  exportQuery: any;
  screen: string;
  modalIsOpen: boolean;
  selectedTemplate: any;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  users: User[] | undefined;
  selectedFolder: SelectedFolder;
}

const ReportModal = ({
  rows,
  cols,
  selectedFolder,
  screen,
  selectedTemplate,
  exportQuery,
  modalIsOpen,
  setModalIsOpen,
  users,
}: ReportModalProps) => {
  const apiRef = useGridApiContext();
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const [{ suser }] = useLocalStorage('userData');
  const [orientation, setOrientation] = useState<string>('landscape');
  const [pdfIsRendered, setPdfIsRendered] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const memoizedRows = useMemo(() => rows, [rows]);
  const memoizedCols = useMemo(() => cols, [cols]);
  const [data, setData] = useState<any[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [warning, setWarning] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const cancelOperation = useRef(false);

  const fetchData = async () => {
    let result = await getData();
    let generatedRows = isLinkedReport
      ? utils.parseLinkedInfo(result, selectedFolder)
      : screen === 'reporting'
      ? generateRows(result, users, exportQuery.data.ReportTypeEnum, true)
      : generateRowsByConfig(apiRef, result, true);
    setData(isLinkedReport ? generatedRows.rows : generatedRows);
  };

  let isLinkedReport =
    state.screen === 'reporting' &&
    exportQuery.data.ReportTypeEnum === 'LinkedInformation';
  let query =
    state.screen === 'reporting'
      ? gqlRecords.GENERATE_REPORT_PDF
      : suser
      ? gqlRecords.EXPORT_RECORDS_BYORG
      : gqlRecords.EXPORT_RECORDS;
  let dataObj =
    state.screen === 'reporting'
      ? ['Records_Report', isLinkedReport ? 'linked_Informations' : 'records']
      : suser
      ? ['RecordsByOrg', 'records']
      : ['Records', 'records'];

  let visibleColumns = apiRef.current.getVisibleColumns();
  let headers = visibleColumns
    .filter((col) => col.field !== '__check__')
    .map((col) => ({ name: col.field }));

  const handlePdfOrientationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOrientation(event.target.checked ? 'landscape' : 'portrait');
  };

  const getData = async () => {
    let limit = 300;
    exportQuery =
      state.screen === 'reporting' && exportQuery.data.ReportTypeEnum
        ? {
            ...exportQuery,
            data: { ...exportQuery.data, paginationDetails: { limit, currentPage: 0 } },
          }
        : { ...exportQuery, limit, currentPage: 0 };
    let thisquery = await query(exportQuery);
    let totalData = [];
    let { data } = await utils.performQuery(thisquery);
    totalData = [...data[dataObj[0]][dataObj[1]]];

    const totalCount = data[dataObj[0]].totalCount;
    setTotalCount(totalCount);
    let current = 0;
    current =
      current + data[dataObj[0]].linked_Informations
        ? data[dataObj[0]].linked_Informations.length
        : data[dataObj[0]].records.length;

    setProgress((current / totalCount) * 100);
    if (data[dataObj[0]].totalCount > limit) {
      let nPages = Math.ceil(data[dataObj[0]].totalCount / limit);
      let i = 1;
      while (i < nPages && cancelOperation.current === false) {
        exportQuery =
          state.screen === 'reporting' && exportQuery.data.ReportTypeEnum
            ? {
                ...exportQuery,
                data: {
                  ...exportQuery.data,
                  paginationDetails: { limit, currentPage: i },
                },
              }
            : { ...exportQuery, limit, currentPage: i };
        thisquery = await query(exportQuery);
        let newData = await utils.performQuery(thisquery);
        current =
          state.screen === 'reporting'
            ? current + newData.data[dataObj[0]].linked_Informations.length
            : current + newData.data[dataObj[0]].records.length;
        setProgress((current / totalCount) * 100);
        totalData = [...totalData, ...newData.data[dataObj[0]][dataObj[1]]];
        i++;
      }
    } else {
      totalData = data[dataObj[0]][dataObj[1]];
    }
    return totalData;
  };

  const handleSaveCSV = async () => {
    let fields = headers.map((col) => col.name);

    const dataObject = {
      fields,
      data: generateData(data, fields),
    };
    const csvString = Papa.unparse(dataObject);
    const encodedContent = encodeURIComponent(csvString);
    const filename = `${screen}_${selectedTemplate?.name || ''}${utils.convertDate(
      new Date()
    )}-${selectedFolder.name}.csv`;
    const link = document.createElement('a');
    link.href = `data:text/csv;charset=utf-8,${encodedContent}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchData();
  }, [memoizedRows, memoizedCols]);

  const {
    value,
    error,
    loading: renderingPDF,
  } = useRenderPDF({
    rows: data,
    cols: headers,
    folder: selectedFolder,
    screen,
    orientation,
    selectedTemplate,
  });

  const downloadPDF = async () => {
    setLoading(true);
    const filename = `${screen}_${selectedTemplate?.name || ''}${utils.convertDate(
      new Date()
    )}-${selectedFolder.name}.pdf`;
    saveAs(value?.blob, filename);
    setLoading(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setPdfIsRendered(false);
    cancelOperation.current = true;
  };

  const handleCloseModal = () => {
    if (progress < 100 || renderingPDF) {
      setWarning(true);
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (value && value.url && !error) {
      setPdfIsRendered(true);
      setLoading(false);
    }
  }, [value, error]);

  return (
    <>
      <Modal open={modalIsOpen} onClose={handleCloseModal}>
        <Box sx={styles.absolute}>
          <Box>
            <Box
              sx={{
                backgroundColor: 'common.greyblueDarker',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <StyledToggleButtonGroup value={true} exclusive>
                <ToggleButton value="pdf">
                  <FontAwesomeIcon size="16px" icon="fas fa-file-pdf" color="#fff" />
                  Preview
                </ToggleButton>
              </StyledToggleButtonGroup>

              <Box sx={{ marginRight: 5 }}></Box>
            </Box>
            <Collapse in={true} collapsedSize={0} unmountOnExit>
              <Box sx={styles.viewerBox}>
                {progress === 100 && !renderingPDF ? (
                  <RenderedPDFViewer
                    value={value}
                    error={error}
                    pdfIsRendered={pdfIsRendered}
                    setPdfIsRendered={setPdfIsRendered}
                  />
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        backgroundColor: 'common.greyblueDarker',
                        paddingX: '10rem',
                        textAlign: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          width: '100%',
                          mb: '10px',
                        }}
                      >
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ color: 'common.white', fontWeight: 'bold' }}
                          >{`${Math.round(progress)}%`}</Typography>
                        </Box>
                      </Box>
                      {totalCount > 1000 && (
                        <Typography
                          variant="body2"
                          sx={{ color: 'common.white', fontWeight: 'bold' }}
                        >
                          This report will be generated only in CSV due to it's size,
                          please hold on while we gather the data
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </Box>
              {progress === 100 && !renderingPDF ? (
                <Box
                  sx={{
                    backgroundColor: 'rgb(50 54 57)',
                    width: '100%',
                    height: '40px',
                    padding: '0px 20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography color="#fff" fontWeight={500}>
                      Landscape Mode
                    </Typography>
                    <Switch
                      onChange={handlePdfOrientationChange}
                      color="secondary"
                      size="small"
                      inputProps={{ 'aria-label': 'Landscape Mode' }}
                      defaultChecked
                      disabled={!pdfIsRendered || loading}
                    />
                  </Box>

                  <Stack gap={1} flexDirection="row">
                    <Button
                      onClick={downloadPDF}
                      size="small"
                      variant="contained"
                      startIcon={
                        <span>
                          <FontAwesomeIcon
                            size="14px"
                            icon="fas fa-file-csv"
                            color="#fff"
                          />
                        </span>
                      }
                      sx={{
                        fontSize: 12,
                        paddingY: 0,
                        height: 30,
                      }}
                      disabled={!pdfIsRendered || loading || totalCount > 1000}
                    >
                      {(totalCount < 1000 && !pdfIsRendered) || loading
                        ? 'Rendering PDF...'
                        : 'Download PDF'}
                    </Button>

                    <Button
                      onClick={handleSaveCSV}
                      size="small"
                      variant="contained"
                      startIcon={
                        <span>
                          <FontAwesomeIcon
                            size="14px"
                            icon="fas fa-file-csv"
                            color="#fff"
                          />
                        </span>
                      }
                      sx={{
                        fontSize: 12,
                        paddingY: 0,
                        height: 30,
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.blueShadow['500']
                            : theme.palette.primary.main,
                        '&:hover': {
                          color: 'common.white',
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                              ? theme.palette.common.blueShadow['600']
                              : theme.palette.primary.dark,
                        },
                      }}
                      disabled={loading}
                    >
                      {loading ? 'Loading' : 'Download CSV'}
                    </Button>
                  </Stack>
                </Box>
              ) : null}
            </Collapse>
          </Box>
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: 'absolute', top: 0, right: 1 }}
          >
            <FontAwesomeIcon size="16px" icon="fas fa-times" color="#fff" />
          </IconButton>
        </Box>
      </Modal>
      <WarningDialog
        open={warning}
        handleCancel={() => setWarning(false)}
        handleAccept={closeModal}
      />
    </>
  );
};

export default ReportModal;
