import React, { useState, useContext, useEffect } from 'react';
import gqlRecords from '../utils/gql/gqlRecords';
import { useLazyQuery } from '@apollo/react-hooks';
import { Tooltip, Badge, IconButton } from '@mui/material';
import PopoverBin from './collectionBin/Popover';

import utils from '../utils/utils';
import { Context as StatusContext } from '../context/statusContext';
import print from './Printing';
import save from './Saving';
import FontAwesomeIcon from './FAIcon';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { StatusContextTypes } from '../context/statusContextTypes';
import { Record } from '../containers/search/types';

interface CollectionBinProps {
  setAnchorCollectionBin: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  anchorCollectionBin: Element | ((element: Element) => Element) | null | undefined;
}

const styles = {
  iconButton: {
    padding: '4px 10px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
    '&:hover .MuiIcon-root': {
      color: 'common.blue',
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      top: 15,
      fontSize: '0.5rem',
      color: 'common.greyblueDarker',
      height: '1rem',
      width: '1rem',
      minWidth: '1rem',
      padding: '0',
    },
  },
};

export default function CollectionBin({
  setAnchorCollectionBin,
  anchorCollectionBin,
}: CollectionBinProps) {
  const [collectionBin] = useLocalStorage('collectionBin');
  const [collection, setCollection] = useState<Record[]>([]);
  const { state, RefreshCollectionBin } = useContext(StatusContext) as StatusContextTypes;
  const [isShareMenuOpen, setIsShareMenuOpen] = useState<boolean>(false);
  const [, /*isPrintOpen*/ setIsPrintOpen] = useState<boolean>(false);
  const [, /*isSaveOpen*/ setIsSaveOpen] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [getRecordsUrls] = useLazyQuery(gqlRecords.GET_URLs, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      switch (action) {
        case 'print':
          print.do(data.GenerateGetURLs, setIsPrintOpen);
          break;
        case 'save':
          save.do(data.GenerateGetURLs, setIsSaveOpen, collection);
          break;
        default:
          break;
      }
    },
  });
  const toggleShareMenu = () => setIsShareMenuOpen(!isShareMenuOpen);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let collection = utils.checkCollectionBinLocalStorage();
    setCollection(collection);
    setAnchorCollectionBin(event.currentTarget);
  };

  const handleClose = () => setAnchorCollectionBin(null);

  const clearCollectionBin = () => {
    utils.clearCollectionBinLocalStorage();
    RefreshCollectionBin();
    setCollection([]);
  };

  const removeOneItem = (id: string) => {
    let collection = utils.checkCollectionBinLocalStorage();
    collection = collection.filter((record: Record) => record.id !== id);
    utils.updateCollectionBin(collection);
    RefreshCollectionBin();
    setCollection(collection);
  };

  const handleSaving = () => {
    setIsSaveOpen(true);
    setAction('save');
    let attachments: string[] = [];
    collection.forEach((record) =>
      record.attachments.forEach((attachment) => attachments.push(attachment.name))
    );
    getRecordsUrls({
      variables: {
        keys: attachments,
      },
    });
  };

  useEffect(() => {
    if (collectionBin) RefreshCollectionBin();
  }, [collectionBin]);

  return (
    <>
      <Tooltip title="Collection bin" arrow>
        <IconButton
          data-testid="collection-bin-header-button"
          aria-controls="Collection bin"
          onClick={handleClick}
          sx={styles.iconButton}
        >
          <Badge
            data-testid="collection-bin-badge"
            badgeContent={state.collectionBinCount}
            color="secondary"
            sx={styles.badge}
          >
            <FontAwesomeIcon
              icon="fas fa-clipboard-list"
              size="18px"
              color="#fff"
            ></FontAwesomeIcon>
          </Badge>
        </IconButton>
      </Tooltip>

      <PopoverBin
        anchorEl={anchorCollectionBin}
        handleClose={handleClose}
        toggleShareMenu={toggleShareMenu}
        isShareMenuOpen={isShareMenuOpen}
        clearCollectionBin={clearCollectionBin}
        collection={collection}
        handleSaving={handleSaving}
        removeOneItem={removeOneItem}
      />
    </>
  );
}

// const handleSaving = () => {
//   setIsSaveOpen(true);
//   setAction('save');
//   let attachments: string[] = [];
//   collection.forEach((record) =>
//     record.attachments.forEach((attachment) => attachments.push(attachment.name))
//   );
//   getRecordsUrls({
//     variables: {
//       keys: attachments,
//     },
//   });
// };
