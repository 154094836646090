import React, { useState, useContext } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Context as StatusContext } from '../../context/statusContext';
import { useLazyQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import gqlRecords from '../../utils/gql/gqlRecords';
import utils from '../../utils/utils';
import { CustomButton } from './sharePopOver';
import { StatusProps } from '../SnackBar';
import { Attachment, Row } from '../masterTable/masterTableTypes';
import FontAwesomeIcon from '../FAIcon';

const schema = yup.object().shape({
  name: yup.string().required(),
  pages: yup.string(),
});

interface PDFDownloadProps {
  fab: CustomButton;
  setSnackbar: React.Dispatch<React.SetStateAction<StatusProps>>;
}

export default function PDFDownload({ fab, setSnackbar }: PDFDownloadProps) {
  const [pagesInputSanitized, setPagesInputSanitized] = useState(false);
  const [fileName, setFileName] = useState(false);
  const pages = window.localStorage.getItem('pdfPages');
  const { state } = useContext(StatusContext);
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const [getRecordsUrls] = useLazyQuery(gqlRecords.GET_URLs, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      // window.log(data);
      createPDFFromPages(data.GenerateGetURLs[0].getUrl);
    },
  });
  const getMainField = (record: Row) => {
    // window.log('pdfDownload record: ', record);
    const mainField = record.fields.filter(
      (field) => field.field.id === record.folderId.primaryFolderFieldId
    );
    if (mainField[0] && mainField[0].value) {
      return mainField[0].value;
    } else {
      let date = new Date(Date.now());
      return record.folderId.name + '_' + date.toISOString();
    }
  };
  const createPDFFromPages = async (url: string) => {
    if (!utils.checkOutOfRange(pagesInputSanitized, pages)) {
      // window.log(url);
      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const newPDF = await PDFDocument.create();
      for (let i = 0; i < pagesInputSanitized.length; i++) {
        const p = Number(pagesInputSanitized[i]);
        const [pdfPage] = await newPDF.copyPages(pdfDoc, [p]);
        // window.log(pdfPage);
        newPDF.addPage(pdfPage);
      }
      const pdfBytes = await newPDF.save();
      // window.log(getMainField(state.selectedRecord));

      download(pdfBytes, `${fileName}.pdf`, 'application/pdf');
    } else {
      setSnackbar({
        status: true,
        snackbarMessage: `Some pages you selected are out of range, the document only has ${pages} pages`,
        severity: 'error',
      });
    }
  };
  const handleSaving = async (values: { name: string; pages: string }) => {
    console.log(values);
    let newValues;
    if (!values.pages) {
      newValues = utils.sanitizePagesInput(`1-${pages}`);
    } else {
      newValues = utils.sanitizePagesInput(values.pages);
    }

    setPagesInputSanitized(newValues);
    setFileName(values.name);
    let documents: string[] = [];
    state.selectedRecord.attachments.forEach((attachment: Attachment, i: number) => {
      if (i === state.selectedRecord.attachments.length - 1) {
        documents.push(attachment.name);
      }
    });
    getRecordsUrls({
      variables: {
        keys: documents,
      },
    });
  };
  const customButton = {
    color: 'primary',
    icon: <FontAwesomeIcon icon="fas fa-file-download" />,
    label: 'Download',
    onClick: handleSubmit(handleSaving),
  };
  return (
    <Grid container direction="column">
      <form>
        {' '}
        <TextField
          fullWidth
          label="File Name"
          type="text"
          variant="outlined"
          defaultValue={getMainField(state.selectedRecord)}
          {...register('name')}
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          fullWidth
          label="Page Range"
          type="text"
          variant="outlined"
          {...register('pages')}
          sx={{ marginBottom: '20px' }}
        />
        <Typography variant="caption">
          Enter page numbers and/or page ranges separated by comas (e.g. 2,5-8)
        </Typography>
      </form>
      {fab(customButton, 1)}
    </Grid>
  );
}
