import { useContext } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import FontAwesomeIcon from '../../FAIcon';
import { CardActionsProps } from '../notesTypes';
import { hasDeleteNotesPermissions } from '../../../containers/search/searchUtils';
import { Context as StatusContext } from '../../../context/statusContext';
import { Context as AuthContext, AuthContextTypes } from '../../../context/authContext';
import { StatusContextTypes } from '../../../context/statusContextTypes';

const styles = {
  container: {
    display: 'flex',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '& .MuiIcon-fontSizeMedium': {
      height: '12px',
      width: '12px',
      fontSize: '12px',
    },
  },
  copyButton: {
    fontSize: 10,
    color: '#8399a8',
    fontWeight: 300,
  },
  deleteButton: {
    fontSize: 10,
    color: '#8399a8',
    fontWeight: 300,
    '&:hover': {
      color: 'red',
      '.MuiButton-startIcon': {
        color: 'red',
      },
    },
  },
};

const copyIcon = (
  <FontAwesomeIcon icon="fa-solid fa-copy" size={1} padding={0} color="#8399a8" />
);

const deleteIcon = (
  <FontAwesomeIcon icon="fa-solid fa-trash" size={1} padding={0} color={false} />
);

const CardActions = ({
  showActions,
  note,
  setMessage = () => {},
  setSnackOpen = () => {},
  deleteNote = () => {},
  actionButtonsText,
}: CardActionsProps) => {
  const [userData] = useLocalStorage('userData');
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const { state: authState } = useContext(AuthContext) as AuthContextTypes;
  const handleCopy = () => {
    navigator.clipboard.writeText(note.content);
    setMessage({
      status: true,
      message: 'Note copied on your clipboard',
      severity: 'success',
    });
    setSnackOpen(true);
  };

  const handleDelete = () => {
    if (!note.id) return;
    deleteNote(note.id);
  };

  const copyButton = () => {
    return actionButtonsText ? (
      <Button
        sx={styles.copyButton}
        size="small"
        startIcon={copyIcon}
        onClick={handleCopy}
        name="copy-note"
      >
        Copy
      </Button>
    ) : (
      <Tooltip title="Copy">
        <IconButton aria-label="delete" size="small" onClick={handleCopy}>
          {copyIcon}
        </IconButton>
      </Tooltip>
    );
  };

  const deleteButton = () => {
    if (
      userData.role <= 1 &&
      hasDeleteNotesPermissions(
        authState.permissions,
        userData,
        state.selectedFolder.search,
        false
      )
    ) {
      return actionButtonsText ? (
        <Button
          sx={styles.deleteButton}
          size="small"
          startIcon={deleteIcon}
          onClick={handleDelete}
          name="delete-note"
        >
          Delete
        </Button>
      ) : (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleDelete}
            sx={styles.deleteButton}
          >
            {deleteIcon}
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <Box
      sx={{
        ...styles.container,
        visibility: showActions ? 'visible' : 'hidden',
      }}
    >
      {copyButton()}
      {deleteButton()}
    </Box>
  );
};

export default CardActions;
