import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const StyledMasterTable = styled(DataGrid)(({ theme }: DataGridProps | any) => ({
  height: '100%',
  border: 0,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  // fontFamily: theme.typography.body1,
  fontSize: 12,
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  // paddingBottom: '10px',
  '& .MuiDataGrid-main': { height: '420px' },
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    position: 'relative',
    paddingRight: 0,
    borderRight: `1px solid ${
      theme.palette.mode === 'light'
        ? theme.palette.common.greyblueDark
        : 'rgba(255,255,255,0.2)'
    }`,

    '& .MuiDataGrid-columnHeaderTitleContainer': {
      paddingRight: '10px',
      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        width: '100%',
        justifyContent: 'space-between',

        '& #resize-handler': {
          position: 'absolute',
          right: 0,
          height: '10px',
          cursor: 'w-resize',
          padding: '10px 5px',
          borderWidth: '0 1px 0 0',
          borderColor: 'rgba(255,255,255,0.5)',
          '&:hover': { borderColor: 'common.orange' },
        },
      },
    },
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : 'rgba(255,255,255,0.0005)'
    }`,
  },
  '& .MuiDataGrid-cell': {
    cursor: 'pointer',
    borderRight: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : 'rgba(255,255,255,0.05)'
    }`,
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.55)' : 'rgba(255,255,255,0.65)',
    '& .MuiTypography-root': {
      color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.55)' : 'rgba(255,255,255)',
    },
    '&:focus-within': {
      outlineColor: theme.palette.secondary.main,
      outlineStyle: 'solid',
      outlineWidth: '2px',
      outlinOffset: '-2px',
    },
  },

  '& .valid': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
  },
  '& .error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0.5 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },

  '& .MuiInputBase-input': {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.greyblueDark,
    '&:placeholder': {
      color: theme.palette.common.greyblueDark,
    },
  },

  '& .MuiDataGrid-virtualScroller': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.white.main
        : theme.palette.black.main,
    marginTop: '50px!important',
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      '& .MuiDataGrid-row': {
        backgroundColor:
          theme.palette.mode === 'light' ? 'rgba(67,96,114,0.01)' : '#2B333B95',
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.common.lightblue : '#37474F95',
        },
        '&:nth-of-type(even)': {
          backgroundColor:
            theme.palette.mode === 'light' ? 'rgba(242, 242, 242, .5)' : '#2B333B50',
          '& .MuiDataGrid-cell': {
            borderRight: `1px solid ${
              theme.palette.mode === 'light'
                ? theme.palette.common.white
                : 'rgba(255,255,255,0.02)'
            }`,
          },
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'light'
                ? theme.palette.common.lightblue
                : '#37474F95',
          },
          '& .Mui-selected': {
            backgroundColor:
              theme.palette.mode === 'light'
                ? theme.palette.common.lightblue
                : theme.palette.common.greyblueDark,
          },
        },
      },
      '& .Mui-selected': {
        backgroundColor: `${
          theme.palette.mode === 'light'
            ? theme.palette.common.lightblue
            : theme.palette.common.greyblueDarker
        } !important`,
      },

      '& .MuiDataGrid-row--editing': {
        '& .MuiDataGrid-cell': {
          // backgroundColor: theme.palette.common.lightblue,
          backgroundColor: 'rgba(10, 84, 135, 0.1)',
        },
      },
    },
  },

  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.common.greyblueDark
        : theme.palette.common.greyblue,
    color: theme.palette.white.main,
    borderRadius: 0,
    height: '52px!important',
    minHeight: '52px!important',
    maxHeight: '52px!important',
  },
  '& .MuiDataGrid-columnHeaders button': {
    color: theme.palette.white.main,
  },
  '& .MuiDataGrid-columnHeaders .MuiCheckbox-root': {
    color: theme.palette.white.main,
  },
  '& .MuiDataGrid-row .MuiCheckbox-root': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.greyblueDarkerTransp
        : theme.palette.common.white,
  },
}));

export default StyledMasterTable;
