import React, { useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';

import { FieldOptions } from './FieldCreationForm';
import ModalWrapper from '../../components/modal/ModalWrapper';
import StyledSwitch from '../../components/form/StyledSwitch';
import ModalContentBase from '../../components/modal/ModalContentBase';
import Tabs from '../../components/tabs/Tabs';
import Tab from '../../components/tabs/TabButton';
import TabPanel from '../../components/tabs/Tabpanel';
import FontAwesomeIcon from '../../components/FAIcon';
import { Folder, FolderFields, UpdateFieldProps } from './types';
import { MutationFunctionOptions, OperationVariables } from 'react-apollo';
import { ApolloQueryResult, ExecutionResult } from 'apollo-boost';

const styles = {
  description: {
    paddingTop: '10px',
  },
  switch: {
    marginLeft: '10px',
  },
};

interface FolderEditModalProps {
  editFieldModalOpen: boolean;
  setEditFieldModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFieldId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedField: React.Dispatch<React.SetStateAction<FolderFields | null>>;
  selectedField: FolderFields | null;
  setFieldUpdates: React.Dispatch<React.SetStateAction<any>>;
  fieldUpdates: any;
  updateField: ({
    id,
    name,
    type,
    active,
    options,
    matchRequiredField,
    mainField,
    indexRequirements,
    textractRequirements,
  }: UpdateFieldProps) => Promise<void>;
  selectedFieldId: string | null;
  folder: Folder;
  updateFolder: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<ExecutionResult<any>>;
  refetch: (
    variables?:
      | {
          id: string;
          organisationId: string;
        }
      | {
          id: string;
          organisationId?: string;
        }
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
}

const FolderEditModal = ({
  editFieldModalOpen,
  setEditFieldModalOpen,
  setSelectedFieldId,
  setFieldUpdates,
  fieldUpdates,
  selectedField,
  updateField,
  selectedFieldId,
  setSelectedField,
  folder,
  updateFolder,
  refetch,
}: FolderEditModalProps) => {
  const [isMainField, setIsMainField] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0); // used to switch tabs
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <ModalWrapper isOpen={editFieldModalOpen} setIsOpen={setEditFieldModalOpen}>
      <Box sx={{ display: { md: 'flex' } }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab
            icon={<FontAwesomeIcon icon="fas fa-folder-open" color="white" />}
            label="FOLDER FIELDS"
            index={0}
          />

          {fieldUpdates && fieldUpdates.type === 'dropdown' && (
            <Tab
              icon={<FontAwesomeIcon icon="fas fa-folder-open" color="white" />}
              label="Options"
              index={1}
            />
          )}
        </Tabs>
        <TabPanel value={tabValue} index={0} sx={{ padding: 0 }}>
          <ModalContentBase
            title="EDIT FOLDER FIELD"
            onCancel={() => {
              setSelectedFieldId(null);
              setFieldUpdates({});
              setEditFieldModalOpen(false);
            }}
            onSubmit={() => {
              const updates = { ...fieldUpdates };
              if (
                selectedField &&
                selectedField.type !== fieldUpdates.type &&
                selectedField.type === 'dropdown'
              ) {
                updates.options = [];
              }
              updateField(updates).then(() => {
                //Add here the folder update
                if (isMainField) {
                  updateFolder({
                    variables: {
                      folderId: folder.id,
                      primaryFolderFieldId: selectedField.id,
                    },
                  })
                    .then((data) => {
                      setSelectedFieldId(null);
                      setIsMainField(false);
                      setFieldUpdates({});
                      setEditFieldModalOpen(false);
                      refetch();
                    })
                    .catch((e) => {
                      console.error('Could not update folder name: ', e);
                    });
                } else {
                  setEditFieldModalOpen(false);
                }
              });
            }}
          >
            <Grid container direction="column" spacing={1}>
              <Grid item container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Field Name"
                    name="name"
                    type="text"
                    value={fieldUpdates.name}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                      const name = e.target.value;
                      setFieldUpdates({
                        ...fieldUpdates,
                        name,
                      });
                      setSelectedField((prev) => ({ ...prev, name }));
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="dataType-outlined-label">Data Type</InputLabel>
                    <Select
                      label="Data Type"
                      inputProps={{
                        name: 'dataType',
                        id: 'dataType-outlined-label',
                      }}
                      value={fieldUpdates.type}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldUpdates({
                          ...fieldUpdates,
                          type: value,
                        });
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem key="boolean" value="boolean">
                        Boolean
                      </MenuItem>
                      <MenuItem key="currency" value="currency">
                        Currency
                      </MenuItem>
                      <MenuItem key="datetime" value="datetime">
                        DateTime
                      </MenuItem>
                      <MenuItem key="integer" value="integer">
                        Integer
                      </MenuItem>
                      <MenuItem key="string" value="string">
                        Text
                      </MenuItem>
                      <MenuItem key="dropdown" value="dropdown">
                        Drop Down List
                      </MenuItem>
                      <MenuItem key="multistring" value="multistring">
                        Multi string
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Regex Match"
                    name="regexMatch"
                    type="text"
                    value={
                      fieldUpdates.indexRequirements
                        ? fieldUpdates.indexRequirements.regexMatch
                        : ''
                    }
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldUpdates({
                        ...fieldUpdates,
                        indexRequirements: {
                          isMandatory: fieldUpdates.indexRequirements.isMandatory,
                          regexMatch: value,
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="active"
                        color="secondary"
                        checked={fieldUpdates.active}
                        onChange={() =>
                          setFieldUpdates({
                            ...fieldUpdates,
                            active: !selectedField?.active,
                          })
                        }
                        sx={styles.switch}
                      />
                    }
                    label="Active"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="mainField"
                        color="secondary"
                        defaultChecked={selectedFieldId === folder.primaryFolderFieldId}
                        onChange={() => setIsMainField(!isMainField)}
                        sx={styles.switch}
                      />
                    }
                    label="Main Field"
                  />
                </Grid>
                <Grid item sx={styles.description}>
                  <Typography variant="caption">Is this the main field?</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="matchRequiredField"
                        color="secondary"
                        checked={fieldUpdates.matchRequiredField}
                        onChange={() =>
                          setFieldUpdates({
                            ...fieldUpdates,
                            matchRequiredField: selectedField?.matchRequiredField
                              ? false
                              : true,
                          })
                        }
                        sx={styles.switch}
                      />
                    }
                    label="Match Required"
                  />
                </Grid>

                <Grid item sx={styles.description}>
                  <Typography variant="caption">
                    Is this field defining linked data that needs to be matched?
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="isMandatory"
                        color="secondary"
                        checked={
                          fieldUpdates.indexRequirements
                            ? fieldUpdates.indexRequirements.isMandatory
                            : false
                        }
                        onChange={() =>
                          setFieldUpdates({
                            ...fieldUpdates,
                            indexRequirements: {
                              regexMatch: fieldUpdates.indexRequirements.regexMatch,
                              isMandatory:
                                fieldUpdates.indexRequirements &&
                                fieldUpdates.indexRequirements.isMandatory
                                  ? false
                                  : true,
                            },
                          })
                        }
                        sx={styles.switch}
                      />
                    }
                    label="Mandatory"
                  />
                </Grid>
                <Grid item sx={styles.description}>
                  <Typography variant="caption">
                    Is it mandatory to fill out this field?
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </ModalContentBase>
        </TabPanel>
        {fieldUpdates && fieldUpdates.type === 'dropdown' && (
          <TabPanel value={tabValue} index={1} sx={{ padding: 0 }}>
            <ModalContentBase
              title="Options"
              onCancel={() => {
                setSelectedFieldId(null);
                setFieldUpdates({});
                setEditFieldModalOpen(false);
                setTabValue(0);
              }}
            >
              <FieldOptions
                options={fieldUpdates.options}
                onDelete={(option) => {
                  const updatedOptions = fieldUpdates.options.filter(
                    (item: { key: string }) => item.key !== option.key
                  );
                  updateField({
                    ...fieldUpdates,
                    options: updatedOptions.map((o: { key: string; value: any }) => ({
                      key: o.key,
                      value: o.value,
                    })),
                  });
                }}
                onAddNew={(newOption) => {
                  updateField({
                    ...fieldUpdates,
                    options: [
                      ...fieldUpdates.options.map((o: { key: string; value: any }) => ({
                        key: o.key,
                        value: o.value,
                      })),
                      {
                        key: newOption.split(' ').join('-').toLowerCase(),
                        value: newOption,
                      },
                    ],
                  });
                }}
              />
            </ModalContentBase>
          </TabPanel>
        )}
      </Box>
    </ModalWrapper>
  );
};

export default FolderEditModal;
