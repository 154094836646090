import { Box, Typography } from '@mui/material';

interface Props {
  active?: boolean;
  payload?: any;
  type: string;
  color: string;
}

const CustomTooltip = ({ active, payload, type, color }: Props) => {
  if (active && payload && payload.length) {
    const payloadData = payload[0].payload;
    let date;

    let firstDay = payloadData.startEndDay
      ? new Date(payloadData.startEndDay[0]).toLocaleDateString()
      : null;
    let endDay = payloadData.startEndDay
      ? new Date(payloadData.startEndDay[1]).toLocaleDateString()
      : null;

    if (!firstDay && !endDay) {
      date = new Date(payloadData.date).toLocaleDateString();
    } else {
      date = payloadData.date;
    }

    return (
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '4px',
          margin: -1.5,
          minWidth: '90px',
          minHeight: '50px',
          boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.15)',
        }}
      >
        <Box sx={{ backgroundColor: '#eee', padding: '2px 5px' }}>
          <Typography
            variant="caption"
            sx={{ fontSize: 12, color: 'common.greyblueDarker' }}
          >
            {date}
          </Typography>
        </Box>
        <Box sx={{ backgroundColor: '#fff', padding: '2px 5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Box
              sx={{
                backgroundColor: color,
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                marginRight: '2px',
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: 12,
                fontWeight: 400,
                textTransform: 'capitalize',
                color: '#000',
              }}
            >
              {`${type}:`}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: 12, fontWeight: 700, color: '#000' }}
            >
              {payloadData.value[type]}
            </Typography>
          </Box>
          {firstDay && endDay && (
            <Typography
              variant="subtitle1"
              sx={{ fontSize: 12 }}
            >{`${firstDay} - ${endDay}`}</Typography>
          )}
        </Box>
      </Box>
    );
  }

  return null;
};

export default CustomTooltip;
