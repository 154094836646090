import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import {
  GridApi,
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';

export interface CellEditAutocompleteProps {
  id: string;
  value: any;
  api: GridApi;
  field: string;
  colDef: GridColDef;
  setDropdownModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNewDropdownOption: React.Dispatch<React.SetStateAction<string>>;
}

// ? Maybe can be used AutocompleteProps<any, undefined, undefinded, undefinded>
export default function CellEditAutocomplete(props: GridRenderEditCellParams) {
  const { id, value, api, field, colDef, setDropdownModalOpen, setNewDropdownOption } =
    props;
  const [inputValue, setInputValue] = useState('');
  const [val, setValue] = useState(value);
  const [open, setOpen] = useState(false);

  const handleChange = async (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    if (newValue === null) {
      newValue = { label: '' };
    }
    setValue(newValue.label);
    api.setEditCellValue({ id, field, value: newValue.label }, event);
    // Check if the event is not from the keyboard
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      // Wait for the validation to run
      const isValid = await api.commitCellChange({ id, field });
      if (isValid) {
        api.setCellMode(id, field, 'view');
      }
    }
  };
  let options = [];
  options.push({ label: '' });
  options = [
    ...options,
    ...colDef.valueOptions?.map((option) => {
      return {
        label: option,
      };
    }),
  ];

  return (
    <Autocomplete
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
      autoHighlight={true}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={val}
      options={options}
      inputValue={inputValue}
      onChange={handleChange}
      onBlur={() => {
        if (!options.find((el) => el.label === inputValue)) {
          setNewDropdownOption(inputValue);
          setDropdownModalOpen(true);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        return <TextField sx={{ width: '100%' }} autoFocus {...params} />;
      }}
    ></Autocomplete>
  );
}

CellEditAutocomplete.propTypes = {
  /**
   * GridApi that let you manipulate the grid.
   */
  api: PropTypes.object.isRequired,
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
