import { Step } from './types';

export const reorderSteps = (
  steps: Step[],
  startIndex: number,
  endIndex: number
): Step[] => {
  const result = Array.from(steps);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((step, index) => ({ ...step, stepOrder: index }));
};

export const removeStep = (steps: Step[], stepOrder: number): Step[] => {
  return steps
    .filter((step) => step.stepOrder !== stepOrder)
    .map((step, index) => ({ ...step, stepOrder: index }));
};

export const updateStep = (steps: Step[], key: string, value: any, stepOrder: number) => {
  return steps.map((step: Step) => {
    return step.stepOrder === stepOrder ? (step = { ...step, [key]: value }) : step;
  });
};

export const generateSteps = (steps: Step[]) => {
  // function used to create an array with necessary structure to send to BE
  return steps.reduce((acc: any, step: Step, index) => {
    switch (step.__typename) {
      case 'PicStep':
        return [
          ...acc,
          {
            PictureStep: {
              approvalNotes: step.approvalNotes,
              stepOrder: index,
              required: step.required,
              toBeApprovedBy: step.toBeApprovedBy,
            },
          },
        ];
      case 'SignatureStep':
        return [
          ...acc,
          {
            SigStep: {
              // approvalNotes: step.approvalNotes,
              stepOrder: index,
              required: step.required,
              name: 'signature',
              // toBeApprovedBy: step.toBeApprovedBy,
            },
          },
        ];
      case 'ApprovalStep':
        return [
          ...acc,
          {
            ApprovalStep: {
              allUsersMustApprove: step.allUsersMustApprove,
              stepOrder: index,
              required: step.required,
              toBeApprovedBy: step.toBeApprovedBy,
            },
          },
        ];
    }
    return acc;
  }, []);
};

export const addStep = (
  user: any,
  type: string,
  steps: Step[],
  stepsLength: number | null = null
) => {
  // we must add a switch to create a new step depending on its type (each step needs different obj)
  let newStep = {
    __typename: 'ApprovalStep',
    toBeApprovedBy: [],
    approvalStatus: 'assigned',
    approvalNotes: [],
    completed: false,
    required: true,
    allUsersMustApprove: false,
    assignBy: {
      user: `${user.userLname} ${user.userName}`,
      date: new Date(Date.now()).toISOString(),
      id: user.id,
    },
  };

  return [
    ...steps,
    { ...newStep, stepOrder: stepsLength !== null ? stepsLength : steps.length },
  ];
};

export const toBeApprovedByIsEmpty = (steps: Step[]): Step[] | undefined => {
  return steps.filter((step) => step.toBeApprovedBy.length === 0);
};

export const checkForErrorsInSteps = (steps: Step[], setStepsWithErrors: any) => {
  let checkForToBeApprovedEmpty = toBeApprovedByIsEmpty(steps);
  let hasErrors = false;
  if (checkForToBeApprovedEmpty && checkForToBeApprovedEmpty.length) {
    hasErrors = true;
    setStepsWithErrors(
      checkForToBeApprovedEmpty.map((step) => ({
        stepOrder: step.stepOrder,
        error: 'Add users who need to approve this step',
      }))
    );
  } else {
    hasErrors = false;
    setStepsWithErrors([]);
  }

  return hasErrors;
};
