// Returns random colors from the hue color spectrum
// different combination of saturation and lightness can be setted to return variations of the same color
// the hue represents the color and goes from 0 to 360
// the max value is set to 240, to avoid pink colors
// hsL(hue, saturation, lighness)
export const getRandomHueColors = () => {
  const hue = Math.floor(Math.random() * 240);
  const pastelColor = `hsL(${hue}, 70%, 80%)`;
  const darkenColor = `hsL(${hue}, 70%, 60%)`;
  return { pastelColor, darkenColor };
};
