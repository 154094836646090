export const dataSemanal = [
  {
    day: 'Mo',
    number: 12,
  },
  {
    day: 'Tu',
    number: 25,
  },
  {
    day: 'We',
    number: 30,
  },
  {
    day: 'Th',
    number: 11,
  },
  {
    day: 'Fr',
    number: 21,
  },
  {
    day: 'Sa',
    number: 16,
  },
  {
    day: 'Su',
    number: 40,
  },
];

export const dataMensual = [
  {
    day: '01',
    number: 12,
  },
  {
    day: '02',
    number: 25,
  },
  {
    day: '03',
    number: 30,
  },
  {
    day: '04',
    number: 11,
  },
  {
    day: '05',
    number: 21,
  },
  {
    day: '06',
    number: 16,
  },
  {
    day: '07',
    number: 40,
  },
  {
    day: '08',
    number: 31,
  },
  {
    day: '09',
    number: 22,
  },
  {
    day: '10',
    number: 5,
  },
  {
    day: '11',
    number: 21,
  },
  {
    day: '12',
    number: 42,
  },
  {
    day: '13',
    number: 33,
  },
  {
    day: '14',
    number: 14,
  },
  {
    day: '15',
    number: 16,
  },
  {
    day: '16',
    number: 22,
  },
  {
    day: '17',
    number: 36,
  },
  {
    day: '18',
    number: 17,
  },
  {
    day: '19',
    number: 19,
  },
  {
    day: '20',
    number: 29,
  },
  {
    day: '21',
    number: 39,
  },
  {
    day: '22',
    number: 31,
  },
  {
    day: '23',
    number: 25,
  },
  {
    day: '24',
    number: 16,
  },
  {
    day: '25',
    number: 20,
  },
  {
    day: '26',
    number: 40,
  },
  {
    day: '27',
    number: 33,
  },
  {
    day: '28',
    number: 44,
  },
  {
    day: '29',
    number: 22,
  },
  {
    day: '31',
    number: 11,
  },
];

export const dataAnual = [
  {
    month: 'Jan',
    number: 213,
  },
  {
    month: 'Feb',
    number: 201,
  },
  {
    month: 'Mar',
    number: 175,
  },
  {
    month: 'Apr',
    number: 169,
  },
  {
    month: 'May',
    number: 201,
  },
  {
    month: 'Jun',
    number: 178,
  },
  {
    month: 'Jul',
    number: 140,
  },
  {
    month: 'Aug',
    number: 210,
  },
  {
    month: 'Sep',
    number: 183,
  },
  {
    month: 'Oct',
    number: 263,
  },
  {
    month: 'Nov',
    number: 170,
  },
  {
    month: 'Dec',
    number: 196,
  },
];

export const listFolders = [
  '624ad74871725200138f1679',
  '624c8c7d4a6f2e001253b6c8',
  '624c8cb44a6f2e001253b6ce',
]; // prueba2, probando, dssaassas

// export const prueba2 = [
//   {
//     name: 'prueba2',
//     id: '624ad74871725200138f1679',
//     frecuency: 'week',
//     scanned: [16, 15, 20, 14, 31, 26, 2, 12],
//     indexed: [12, 25, 30, 11, 21, 16, 40, 22],
//     deleted: [16, 20, 10, 21, 24, 6, 24, 23],
//   },
// ];

export const prueba2 = [
  {
    name: 'prueba2',
    id: '624ad74871725200138f1679',
    frecuency: 'week',
    data: {
      scanned: [
        { date: '2022-04-20T22:00:00Z', value: 16 },
        { date: '2022-04-21T22:00:00Z', value: 15 },
        { date: '2022-04-22T22:00:00Z', value: 20 },
        { date: '2022-04-23T22:00:00Z', value: 14 },
        { date: '2022-04-24T22:00:00Z', value: 11 },
        { date: '2022-04-25T22:00:00Z', value: 26 },
        { date: '2022-04-26T22:00:00Z', value: 29 },
        { date: '2022-04-27T22:00:00Z', value: 12 },
        { date: '2022-04-28T22:00:00Z', value: 23 },
        { date: '2022-04-29T22:00:00Z', value: 14 },
        { date: '2022-04-30T22:00:00Z', value: 17 },
        { date: '2022-05-01T22:00:00Z', value: 27 },
        { date: '2022-05-02T22:00:00Z', value: 12 },
        { date: '2022-05-03T22:00:00Z', value: 31 },
        { date: '2022-05-04T22:00:00Z', value: 28 },
        { date: '2022-05-05T22:00:00Z', value: 8 },
        { date: '2022-05-06T22:00:00Z', value: 29 },
        { date: '2022-05-07T22:00:00Z', value: 15 },
        { date: '2022-05-08T22:00:00Z', value: 13 },
        { date: '2022-05-09T22:00:00Z', value: 18 },
      ],
      indexed: [
        { date: '2022-04-20T22:00:00Z', value: 19 },
        { date: '2022-04-21T22:00:00Z', value: 15 },
        { date: '2022-04-22T22:00:00Z', value: 20 },
        { date: '2022-04-23T22:00:00Z', value: 24 },
        { date: '2022-04-24T22:00:00Z', value: 11 },
        { date: '2022-04-25T22:00:00Z', value: 16 },
        { date: '2022-04-26T22:00:00Z', value: 2 },
        { date: '2022-04-27T22:00:00Z', value: 12 },
        { date: '2022-04-28T22:00:00Z', value: 23 },
        { date: '2022-04-29T22:00:00Z', value: 14 },
        { date: '2022-04-30T22:00:00Z', value: 8 },
        { date: '2022-05-01T22:00:00Z', value: 27 },
        { date: '2022-05-02T22:00:00Z', value: 12 },
        { date: '2022-05-03T22:00:00Z', value: 21 },
        { date: '2022-05-04T22:00:00Z', value: 28 },
        { date: '2022-05-05T22:00:00Z', value: 28 },
        { date: '2022-05-06T22:00:00Z', value: 17 },
        { date: '2022-05-07T22:00:00Z', value: 5 },
        { date: '2022-05-08T22:00:00Z', value: 13 },
        { date: '2022-05-09T22:00:00Z', value: 13 },
        { date: '2022-05-10T22:00:00Z', value: 12 },
        { date: '2022-05-11T22:00:00Z', value: 1 },
        { date: '2022-05-12T22:00:00Z', value: 1 },
        { date: '2022-05-13T22:00:00Z', value: 15 },
        { date: '2022-05-14T22:00:00Z', value: 14 },
        { date: '2022-05-15T22:00:00Z', value: 1 },
        { date: '2022-05-16T22:00:00Z', value: 18 },
        { date: '2022-05-17T22:00:00Z', value: 18 },
        { date: '2022-05-18T22:00:00Z', value: 19 },
        { date: '2022-05-19T22:00:00Z', value: 19 },
        { date: '2022-05-20T22:00:00Z', value: 10 },
        { date: '2022-05-21T22:00:00Z', value: 13 },
        { date: '2022-05-22T22:00:00Z', value: 15 },
        { date: '2022-05-23T22:00:00Z', value: 12 },
        { date: '2022-05-24T22:00:00Z', value: 17 },
        { date: '2022-05-25T22:00:00Z', value: 10 },
      ],
      deleted: [
        { date: '2022-04-20T22:00:00Z', value: 28 },
        { date: '2022-04-21T22:00:00Z', value: 12 },
        { date: '2022-04-22T22:00:00Z', value: 20 },
        { date: '2022-04-23T22:00:00Z', value: 14 },
        { date: '2022-04-24T22:00:00Z', value: 11 },
        { date: '2022-04-25T22:00:00Z', value: 8 },
        { date: '2022-04-26T22:00:00Z', value: 22 },
        { date: '2022-04-27T22:00:00Z', value: 12 },
        { date: '2022-04-28T22:00:00Z', value: 20 },
        { date: '2022-04-29T22:00:00Z', value: 14 },
        { date: '2022-04-30T22:00:00Z', value: 19 },
        { date: '2022-05-01T22:00:00Z', value: 18 },
        { date: '2022-05-02T22:00:00Z', value: 12 },
        { date: '2022-05-03T22:00:00Z', value: 22 },
        { date: '2022-05-04T22:00:00Z', value: 8 },
        { date: '2022-05-05T22:00:00Z', value: 17 },
        { date: '2022-05-06T22:00:00Z', value: 8 },
        { date: '2022-05-07T22:00:00Z', value: 22 },
        { date: '2022-05-08T22:00:00Z', value: 13 },
        { date: '2022-05-09T22:00:00Z', value: 10 },
      ],
    },
  },
];

export const prueba3 = [
  {
    name: 'prueba2',
    id: '624ad74871725200138f1679',
    frecuency: 'week',
    data: {
      scanned: [
        { date: '2022-04-20T22:00:00Z', value: 23 },
        { date: '2022-04-21T22:00:00Z', value: 5 },
        { date: '2022-04-22T22:00:00Z', value: 29 },
        { date: '2022-04-23T22:00:00Z', value: 4 },
        { date: '2022-04-24T22:00:00Z', value: 21 },
        { date: '2022-04-25T22:00:00Z', value: 36 },
        { date: '2022-04-26T22:00:00Z', value: 19 },
        { date: '2022-04-27T22:00:00Z', value: 22 },
        { date: '2022-04-28T22:00:00Z', value: 29 },
        { date: '2022-04-29T22:00:00Z', value: 8 },
        { date: '2022-04-30T22:00:00Z', value: 7 },
        { date: '2022-05-01T22:00:00Z', value: 17 },
        { date: '2022-05-02T22:00:00Z', value: 22 },
        { date: '2022-05-03T22:00:00Z', value: 11 },
        { date: '2022-05-04T22:00:00Z', value: 18 },
        { date: '2022-05-05T22:00:00Z', value: 12 },
        { date: '2022-05-06T22:00:00Z', value: 9 },
        { date: '2022-05-07T22:00:00Z', value: 5 },
        { date: '2022-05-08T22:00:00Z', value: 23 },
        { date: '2022-05-09T22:00:00Z', value: 28 },
        { date: '2022-05-10T22:00:00Z', value: 12 },
        { date: '2022-05-11T22:00:00Z', value: 1 },
        { date: '2022-05-12T22:00:00Z', value: 1 },
        { date: '2022-05-13T22:00:00Z', value: 15 },
        { date: '2022-05-14T22:00:00Z', value: 14 },
        { date: '2022-05-15T22:00:00Z', value: 1 },
        { date: '2022-05-16T22:00:00Z', value: 18 },
        { date: '2022-05-17T22:00:00Z', value: 18 },
        { date: '2022-05-18T22:00:00Z', value: 19 },
        { date: '2022-05-19T22:00:00Z', value: 19 },
        { date: '2022-05-20T22:00:00Z', value: 10 },
        { date: '2022-05-21T22:00:00Z', value: 13 },
        { date: '2022-05-22T22:00:00Z', value: 15 },
        { date: '2022-05-23T22:00:00Z', value: 12 },
        { date: '2022-05-24T22:00:00Z', value: 17 },
        { date: '2022-05-25T22:00:00Z', value: 10 },
      ],
    },
  },
];

export const probando = [
  {
    name: 'probando',
    id: '624c8c7d4a6f2e001253b6c8',
    frecuency: 'week',
    scanned: [18, 25, 10, 24, 41, 20, 17, 22],
    indexed: [22, 15, 10, 18, 21, 19, 20, 32],
    deleted: [26, 23, 21, 27, 31, 23, 8, 16],
  },
];

export const dssaassas = [
  {
    name: 'probando',
    id: '624c8cb44a6f2e001253b6ce',
    frecuency: 'week',
    scanned: [28, 21, 24, 14, 31, 29, 7, 32],
    indexed: [12, 25, 17, 28, 11, 9, 10, 22],
    deleted: [36, 33, 22, 20, 39, 33, 8, 6],
  },
];

export const folder1 = [
  {
    name: 'prueba2',
    id: '624ad74871725200138f1679',
    frecuency: 'week',
    scanned: [
      {
        day: 'Mo',
        number: 16,
      },
      {
        day: 'Tu',
        number: 15,
      },
      {
        day: 'We',
        number: 20,
      },
      {
        day: 'Th',
        number: 14,
      },
      {
        day: 'Fr',
        number: 31,
      },
      {
        day: 'Sa',
        number: 26,
      },
      {
        day: 'Su',
        number: 2,
      },
    ],
    indexed: [
      {
        day: 'Mo',
        number: 12,
      },
      {
        day: 'Tu',
        number: 25,
      },
      {
        day: 'We',
        number: 30,
      },
      {
        day: 'Th',
        number: 11,
      },
      {
        day: 'Fr',
        number: 21,
      },
      {
        day: 'Sa',
        number: 16,
      },
      {
        day: 'Su',
        number: 40,
      },
    ],
    deleted: [
      {
        day: 'Mo',
        number: 16,
      },
      {
        day: 'Tu',
        number: 20,
      },
      {
        day: 'We',
        number: 10,
      },
      {
        day: 'Th',
        number: 21,
      },
      {
        day: 'Fr',
        number: 24,
      },
      {
        day: 'Sa',
        number: 6,
      },
      {
        day: 'Su',
        number: 24,
      },
    ],
  },
];

export const folder123 = [
  { name: 'Prueba1234', id: '624ad74271725200138f1675' },
  {
    scanned: {
      semanal: [
        {
          day: 'Mo',
          number: 16,
        },
        {
          day: 'Tu',
          number: 15,
        },
        {
          day: 'We',
          number: 20,
        },
        {
          day: 'Th',
          number: 14,
        },
        {
          day: 'Fr',
          number: 31,
        },
        {
          day: 'Sa',
          number: 26,
        },
        {
          day: 'Su',
          number: 2,
        },
      ],
      mensual: [
        {
          day: '01',
          number: 12,
        },
        {
          day: '02',
          number: 25,
        },
        {
          day: '03',
          number: 30,
        },
        {
          day: '04',
          number: 11,
        },
        {
          day: '05',
          number: 21,
        },
        {
          day: '06',
          number: 16,
        },
        {
          day: '07',
          number: 40,
        },
        {
          day: '08',
          number: 31,
        },
        {
          day: '09',
          number: 22,
        },
        {
          day: '10',
          number: 5,
        },
        {
          day: '11',
          number: 21,
        },
        {
          day: '12',
          number: 42,
        },
        {
          day: '13',
          number: 33,
        },
        {
          day: '14',
          number: 14,
        },
        {
          day: '15',
          number: 16,
        },
        {
          day: '16',
          number: 22,
        },
        {
          day: '17',
          number: 36,
        },
        {
          day: '18',
          number: 17,
        },
        {
          day: '19',
          number: 19,
        },
        {
          day: '20',
          number: 29,
        },
        {
          day: '21',
          number: 39,
        },
        {
          day: '22',
          number: 31,
        },
        {
          day: '23',
          number: 25,
        },
        {
          day: '24',
          number: 16,
        },
        {
          day: '25',
          number: 20,
        },
        {
          day: '26',
          number: 40,
        },
        {
          day: '27',
          number: 33,
        },
        {
          day: '28',
          number: 44,
        },
        {
          day: '29',
          number: 22,
        },
        {
          day: '31',
          number: 11,
        },
      ],
      anual: [
        {
          month: 'Jan',
          number: 213,
        },
        {
          month: 'Feb',
          number: 201,
        },
        {
          month: 'Mar',
          number: 175,
        },
        {
          month: 'Apr',
          number: 169,
        },
        {
          month: 'May',
          number: 201,
        },
        {
          month: 'Jun',
          number: 178,
        },
        {
          month: 'Jul',
          number: 140,
        },
        {
          month: 'Aug',
          number: 210,
        },
        {
          month: 'Sep',
          number: 183,
        },
        {
          month: 'Oct',
          number: 263,
        },
        {
          month: 'Nov',
          number: 170,
        },
        {
          month: 'Dec',
          number: 196,
        },
      ],
    },
    indexed: {
      semanal: [
        {
          day: 'Mo',
          number: 12,
        },
        {
          day: 'Tu',
          number: 25,
        },
        {
          day: 'We',
          number: 30,
        },
        {
          day: 'Th',
          number: 11,
        },
        {
          day: 'Fr',
          number: 21,
        },
        {
          day: 'Sa',
          number: 16,
        },
        {
          day: 'Su',
          number: 40,
        },
      ],
      mensual: [
        {
          day: '01',
          number: 12,
        },
        {
          day: '02',
          number: 25,
        },
        {
          day: '03',
          number: 30,
        },
        {
          day: '04',
          number: 11,
        },
        {
          day: '05',
          number: 21,
        },
        {
          day: '06',
          number: 16,
        },
        {
          day: '07',
          number: 40,
        },
        {
          day: '08',
          number: 31,
        },
        {
          day: '09',
          number: 22,
        },
        {
          day: '10',
          number: 5,
        },
        {
          day: '11',
          number: 21,
        },
        {
          day: '12',
          number: 42,
        },
        {
          day: '13',
          number: 33,
        },
        {
          day: '14',
          number: 14,
        },
        {
          day: '15',
          number: 16,
        },
        {
          day: '16',
          number: 22,
        },
        {
          day: '17',
          number: 36,
        },
        {
          day: '18',
          number: 17,
        },
        {
          day: '19',
          number: 19,
        },
        {
          day: '20',
          number: 29,
        },
        {
          day: '21',
          number: 39,
        },
        {
          day: '22',
          number: 31,
        },
        {
          day: '23',
          number: 25,
        },
        {
          day: '24',
          number: 16,
        },
        {
          day: '25',
          number: 20,
        },
        {
          day: '26',
          number: 40,
        },
        {
          day: '27',
          number: 33,
        },
        {
          day: '28',
          number: 44,
        },
        {
          day: '29',
          number: 22,
        },
        {
          day: '31',
          number: 11,
        },
      ],
      anual: [
        {
          month: 'Jan',
          number: 213,
        },
        {
          month: 'Feb',
          number: 201,
        },
        {
          month: 'Mar',
          number: 175,
        },
        {
          month: 'Apr',
          number: 169,
        },
        {
          month: 'May',
          number: 201,
        },
        {
          month: 'Jun',
          number: 178,
        },
        {
          month: 'Jul',
          number: 140,
        },
        {
          month: 'Aug',
          number: 210,
        },
        {
          month: 'Sep',
          number: 183,
        },
        {
          month: 'Oct',
          number: 263,
        },
        {
          month: 'Nov',
          number: 170,
        },
        {
          month: 'Dec',
          number: 196,
        },
      ],
    },
    deleted: {
      semanal: [
        {
          day: 'Mo',
          number: 16,
        },
        {
          day: 'Tu',
          number: 20,
        },
        {
          day: 'We',
          number: 10,
        },
        {
          day: 'Th',
          number: 21,
        },
        {
          day: 'Fr',
          number: 24,
        },
        {
          day: 'Sa',
          number: 6,
        },
        {
          day: 'Su',
          number: 24,
        },
      ],
      mensual: [
        {
          day: '01',
          number: 12,
        },
        {
          day: '02',
          number: 27,
        },
        {
          day: '03',
          number: 20,
        },
        {
          day: '04',
          number: 21,
        },
        {
          day: '05',
          number: 11,
        },
        {
          day: '06',
          number: 36,
        },
        {
          day: '07',
          number: 30,
        },
        {
          day: '08',
          number: 31,
        },
        {
          day: '09',
          number: 22,
        },
        {
          day: '10',
          number: 5,
        },
        {
          day: '11',
          number: 21,
        },
        {
          day: '12',
          number: 42,
        },
        {
          day: '13',
          number: 33,
        },
        {
          day: '14',
          number: 14,
        },
        {
          day: '15',
          number: 16,
        },
        {
          day: '16',
          number: 22,
        },
        {
          day: '17',
          number: 36,
        },
        {
          day: '18',
          number: 22,
        },
        {
          day: '19',
          number: 29,
        },
        {
          day: '20',
          number: 19,
        },
        {
          day: '21',
          number: 19,
        },
        {
          day: '22',
          number: 11,
        },
        {
          day: '23',
          number: 29,
        },
        {
          day: '24',
          number: 16,
        },
        {
          day: '25',
          number: 20,
        },
        {
          day: '26',
          number: 45,
        },
        {
          day: '27',
          number: 13,
        },
        {
          day: '28',
          number: 49,
        },
        {
          day: '29',
          number: 12,
        },
        {
          day: '31',
          number: 15,
        },
      ],
      anual: [
        {
          month: 'Jan',
          number: 213,
        },
        {
          month: 'Feb',
          number: 201,
        },
        {
          month: 'Mar',
          number: 195,
        },
        {
          month: 'Apr',
          number: 161,
        },
        {
          month: 'May',
          number: 221,
        },
        {
          month: 'Jun',
          number: 128,
        },
        {
          month: 'Jul',
          number: 240,
        },
        {
          month: 'Aug',
          number: 230,
        },
        {
          month: 'Sep',
          number: 283,
        },
        {
          month: 'Oct',
          number: 233,
        },
        {
          month: 'Nov',
          number: 120,
        },
        {
          month: 'Dec',
          number: 176,
        },
      ],
    },
  },
];

export const folderDePrueba = {
  name: 'prueba2',
  id: '624ad74871725200138f1679',
  frecuency: 'week',
  data: [
    {
      date: '2022-04-09T16:33:59+00:00',
      value: { scanned: 30, deleted: 20, indexed: 10 },
    },
    {
      date: '2022-04-10T16:33:59+00:00',
      value: { scanned: 10, deleted: 32, indexed: 14 },
    },
    {
      date: '2022-04-11T16:33:59+00:00',
      value: { scanned: 22, deleted: 10, indexed: 17 },
    },
    {
      date: '2022-04-12T16:33:59+00:00',
      value: { scanned: 20, deleted: 27, indexed: 10 },
    },
    {
      date: '2022-04-13T16:33:59+00:00',
      value: { scanned: 20, deleted: 16, indexed: 18 },
    },
    {
      date: '2022-04-14T16:33:59+00:00',
      value: { scanned: 8, deleted: 28, indexed: 18 },
    },
    {
      date: '2022-04-15T16:33:59+00:00',
      value: { scanned: 25, deleted: 25, indexed: 36 },
    },
    {
      date: '2022-04-16T16:33:59+00:00',
      value: { scanned: 17, deleted: 25, indexed: 20 },
    },
    {
      date: '2022-04-17T16:33:59+00:00',
      value: { scanned: 19, deleted: 33, indexed: 30 },
    },
    {
      date: '2022-04-18T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 12 },
    },
    {
      date: '2022-04-19T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 15 },
    },
    {
      date: '2022-04-20T16:33:59+00:00',
      value: { scanned: 17, deleted: 13, indexed: 17 },
    },
    {
      date: '2022-04-21T16:33:59+00:00',
      value: { scanned: 23, deleted: 33, indexed: 22 },
    },
    {
      date: '2022-04-22T16:33:59+00:00',
      value: { scanned: 31, deleted: 13, indexed: 10 },
    },
    {
      date: '2022-04-23T16:33:59+00:00',
      value: { scanned: 14, deleted: 25, indexed: 8 },
    },
    {
      date: '2022-04-24T16:33:59+00:00',
      value: { scanned: 18, deleted: 13, indexed: 16 },
    },
    {
      date: '2022-04-25T16:33:59+00:00',
      value: { scanned: 9, deleted: 21, indexed: 22 },
    },
    {
      date: '2022-04-26T16:33:59+00:00',
      value: { scanned: 27, deleted: 13, indexed: 27 },
    },
    {
      date: '2022-04-27T16:33:59+00:00',
      value: { scanned: 16, deleted: 32, indexed: 26 },
    },

    {
      date: '2022-04-28T16:33:59+00:00',
      value: { scanned: 19, deleted: 23, indexed: 15 },
    },
    {
      date: '2022-04-29T16:33:59+00:00',
      value: { scanned: 17, deleted: 18, indexed: 22 },
    },
    {
      date: '2022-04-30T16:33:59+00:00',
      value: { scanned: 11, deleted: 16, indexed: 15 },
    },
    {
      date: '2022-05-01T16:33:59+00:00',
      value: { scanned: 9, deleted: 17, indexed: 13 },
    },
    {
      date: '2022-05-02T16:33:59+00:00',
      value: { scanned: 22, deleted: 27, indexed: 15 },
    },
    {
      date: '2022-05-03T16:33:59+00:00',
      value: { scanned: 31, deleted: 26, indexed: 12 },
    },
    {
      date: '2022-05-04T16:33:59+00:00',
      value: { scanned: 13, deleted: 24, indexed: 9 },
    },
    {
      date: '2022-05-05T16:33:59+00:00',
      value: { scanned: 24, deleted: 29, indexed: 15 },
    },
    {
      date: '2022-05-06T16:33:59+00:00',
      value: { scanned: 16, deleted: 32, indexed: 27 },
    },
    {
      date: '2022-05-07T16:33:59+00:00',
      value: { scanned: 27, deleted: 18, indexed: 15 },
    },
    {
      date: '2022-05-08T16:33:59+00:00',
      value: { scanned: 15, deleted: 9, indexed: 31 },
    },
    {
      date: '2022-05-09T16:33:59+00:00',
      value: { scanned: 21, deleted: 29, indexed: 22 },
    },
    {
      date: '2022-05-10T16:33:59+00:00',
      value: { scanned: 19, deleted: 26, indexed: 23 },
    },
    {
      date: '2022-05-11T16:33:59+00:00',
      value: { scanned: 19, deleted: 23, indexed: 31 },
    },
    {
      date: '2022-05-12T16:33:59+00:00',
      value: { scanned: 20, deleted: 22, indexed: 9 },
    },
  ],
};

export const folderDePrueba2 = {
  name: 'prueba2',
  id: '624ad74871725200138f1679',
  frecuency: 'week',
  data: [
    {
      date: '2022-04-09T16:33:59+00:00',
      value: { scanned: 30, deleted: 20, indexed: 10 },
    },
    {
      date: '2022-04-10T16:33:59+00:00',
      value: { scanned: 30, deleted: 20, indexed: 10 },
    },
    {
      date: '2022-04-11T16:33:59+00:00',
      value: { scanned: 30, deleted: 30, indexed: 26 },
    },
    {
      date: '2022-04-12T16:33:59+00:00',
      value: { scanned: 37, deleted: 10, indexed: 16 },
    },
    {
      date: '2022-04-13T16:33:59+00:00',
      value: { scanned: 10, deleted: 13, indexed: 28 },
    },
    {
      date: '2022-04-14T16:33:59+00:00',
      value: { scanned: 25, deleted: 18, indexed: 8 },
    },
    {
      date: '2022-04-15T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },
    {
      date: '2022-04-16T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },
    {
      date: '2022-04-17T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },
    {
      date: '2022-04-18T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },
    {
      date: '2022-04-19T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },
    {
      date: '2022-04-20T16:33:59+00:00',
      value: { scanned: 19, deleted: 13, indexed: 30 },
    },
    {
      date: '2022-04-21T16:33:59+00:00',
      value: { scanned: 19, deleted: 33, indexed: 20 },
    },
    {
      date: '2022-04-22T16:33:59+00:00',
      value: { scanned: 19, deleted: 13, indexed: 30 },
    },
    {
      date: '2022-04-23T16:33:59+00:00',
      value: { scanned: 24, deleted: 25, indexed: 32 },
    },
    {
      date: '2022-04-24T16:33:59+00:00',
      value: { scanned: 9, deleted: 13, indexed: 16 },
    },
    {
      date: '2022-04-25T16:33:59+00:00',
      value: { scanned: 19, deleted: 13, indexed: 22 },
    },
    {
      date: '2022-04-26T16:33:59+00:00',
      value: { scanned: 9, deleted: 13, indexed: 25 },
    },
    {
      date: '2022-04-27T16:33:59+00:00',
      value: { scanned: 19, deleted: 16, indexed: 18 },
    },

    {
      date: '2022-04-28T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },

    {
      date: '2022-04-29T16:33:59+00:00',
      value: { scanned: 17, deleted: 21, indexed: 28 },
    },
    {
      date: '2022-04-30T16:33:59+00:00',
      value: { scanned: 23, deleted: 16, indexed: 15 },
    },
    {
      date: '2022-05-01T16:33:59+00:00',
      value: { scanned: 17, deleted: 31, indexed: 22 },
    },
    {
      date: '2022-05-02T16:33:59+00:00',
      value: { scanned: 28, deleted: 27, indexed: 15 },
    },
    {
      date: '2022-05-03T16:33:59+00:00',
      value: { scanned: 31, deleted: 26, indexed: 22 },
    },
    {
      date: '2022-05-04T16:33:59+00:00',
      value: { scanned: 13, deleted: 11, indexed: 9 },
    },
    {
      date: '2022-05-05T16:33:59+00:00',
      value: { scanned: 24, deleted: 9, indexed: 25 },
    },
    {
      date: '2022-05-06T16:33:59+00:00',
      value: { scanned: 16, deleted: 12, indexed: 7 },
    },
    {
      date: '2022-05-07T16:33:59+00:00',
      value: { scanned: 27, deleted: 18, indexed: 15 },
    },
    {
      date: '2022-05-08T16:33:59+00:00',
      value: { scanned: 14, deleted: 29, indexed: 11 },
    },
    {
      date: '2022-05-09T16:33:59+00:00',
      value: { scanned: 17, deleted: 29, indexed: 18 },
    },
    {
      date: '2022-05-10T16:33:59+00:00',
      value: { scanned: 22, deleted: 27, indexed: 23 },
    },
    {
      date: '2022-05-11T16:33:59+00:00',
      value: { scanned: 23, deleted: 20, indexed: 21 },
    },
    {
      date: '2022-05-12T16:33:59+00:00',
      value: { scanned: 28, deleted: 22, indexed: 19 },
    },
  ],
};

export const folderDePrueba3 = {
  name: 'folderDePrueba3',
  id: '624ad74871725200138f1679',
  frecuency: 'week',
  data: [
    {
      date: '2022-04-11T16:33:59+00:00',
      value: { scanned: 30, deleted: 30, indexed: 26 },
    },
    {
      date: '2022-04-12T16:33:59+00:00',
      value: { scanned: 37, deleted: 10, indexed: 16 },
    },
    {
      date: '2022-04-13T16:33:59+00:00',
      value: { scanned: 10, deleted: 13, indexed: 28 },
    },
    {
      date: '2022-04-14T16:33:59+00:00',
      value: { scanned: 25, deleted: 18, indexed: 8 },
    },
    {
      date: '2022-04-15T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },
    {
      date: '2022-04-16T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },
    {
      date: '2022-04-17T16:33:59+00:00',
      value: { scanned: 29, deleted: 23, indexed: 10 },
    },
  ],
};

export const folderDePrueba4 = {
  name: 'folderDePrueba3',
  id: '624ad74871725200138f1679',
  frecuency: 'week',
  data: [
    {
      date: '2022-04-11T16:33:59+00:00',
      value: { scanned: 20, deleted: 30, indexed: 26 },
    },
    {
      date: '2022-04-12T16:33:59+00:00',
      value: { scanned: 7, deleted: 12, indexed: 16 },
    },
    {
      date: '2022-04-13T16:33:59+00:00',
      value: { scanned: 10, deleted: 13, indexed: 28 },
    },
    {
      date: '2022-04-14T16:33:59+00:00',
      value: { scanned: 25, deleted: 30, indexed: 28 },
    },
    {
      date: '2022-04-15T16:33:59+00:00',
      value: { scanned: 32, deleted: 23, indexed: 18 },
    },
    {
      date: '2022-04-16T16:33:59+00:00',
      value: { scanned: 19, deleted: 33, indexed: 12 },
    },
    {
      date: '2022-04-17T16:33:59+00:00',
      value: { scanned: 19, deleted: 18, indexed: 10 },
    },
  ],
};
