import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import useUsers from '../../../hooks/useUsers';
import { Filter } from '../ReportingSideBar';
import InputSelect from '../../../components/inputs/InputSelect';

type Options = { label: string; value: string };

interface UserFilterProps {
  filter: Filter;
}

const UserFilter = ({ filter }: UserFilterProps) => {
  const { users } = useUsers();
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<string>('all');
  const { setValue: setFormValue, watch } = useFormContext();
  const handleChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(event.target.value as string);
  };

  useEffect(() => {
    filter && setValue(filter.value);
  }, [filter]);

  useEffect(() => {
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex((flt) => flt.id === filter.id);

    index !== -1 &&
      setFormValue(
        `selectedTemplate.variables.filters[${index}].value`,
        value === 'all' ? null : value
      );
  }, [value]);

  useEffect(() => {
    if (users && users.length) {
      let optsArray: Options[] = users.map((usr) => ({
        label: `${usr.firstName} ${usr.lastName}`,
        value: usr.id,
      }));
      optsArray.unshift({ label: 'All', value: 'all' });
      setOptions(optsArray);
      setValue(optsArray[0].value);
    }
  }, [users]);

  if (!users) return null;

  return (
    <InputSelect
      options={options}
      inputName={'user'}
      helperText="Select an option"
      required={true}
      size="small"
      backgroundColor="transparent"
      handleChange={handleChange}
      value={value}
      textfieldProps={{
        sx: {
          '& .MuiSelect-select': {
            color: 'common.greyblueDarker',
            fontSize: 14,
            fontWeight: 500,
          },
          '& .MuiInputBase-input': {
            textAlign: 'end',
            paddingBottom: 1.5,
            paddingTop: '16px',
          },
        },
      }}
    />
  );
};

export default UserFilter;
