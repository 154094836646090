import React from 'react';
import { Link } from 'react-router-dom';
import { ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import AssessmentIcon from '@material-ui/icons/Assessment';
import GroupIcon from '@material-ui/icons/Group';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SecurityIcon from '@material-ui/icons/Security';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import BugReportIcon from '@material-ui/icons/BugReport';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useLocalStorage } from '../hooks/useLocalStorage';
import BusinessIcon from '@material-ui/icons/Business';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import FontAwesomeIcon from './FAIcon';

interface SideDrawerItemProps {
  title: string;
  sideDrawerToggled: boolean;
  link: string;
  role: number;
  suser: boolean;
  user: boolean;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
}
const SideDrawerItem = ({
  title,
  sideDrawerToggled,
  link,
  role,
  selectedIndex,
  setSelectedIndex,
  index,
  suser,
  user,
}: SideDrawerItemProps) => {
  const [userData] = useLocalStorage('userData');
  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    i: number
  ) => {
    setSelectedIndex(i);
  };
  let icon;
  switch (title) {
    case 'Dashboard':
      icon = <AssessmentIcon />;
      break;
    case 'Users':
      icon = <GroupIcon />;
      break;
    case 'Organisations':
      icon = <BusinessIcon />;
      break;
    case 'Folders':
      icon = <FolderOpenIcon />;
      break;
    case 'Search':
      icon = <SearchIcon />;
      break;
    case 'Security Groups':
      icon = <SecurityIcon />;
      break;
    case 'Reporting':
      icon = <AssignmentIcon />;
      break;
    case 'Process Steps':
      icon = <PlaylistAddCheckIcon />;
      break;
    case 'Indexing':
      icon = <SystemUpdateAltIcon />;
      break;
    case 'ErrorLog':
      icon = <BugReportIcon />;
      break;
    case 'Communication':
      icon = <HeadsetMicIcon />;
      break;
    case 'Maintenance':
      icon = <FontAwesomeIcon icon="fas fa-tools" size={18} />;
      break;
    case 'WorkFlow':
      icon = <FontAwesomeIcon icon="fas fa-project-diagram" size={18} />;
      break;
    default:
      icon = <FolderOpenIcon />;
      break;
  }
  if (
    userData &&
    userData.role <= role &&
    ((userData.suser && suser) || (!userData.suser && user))
  ) {
    return (
      <MenuItem
        key={`${link}${index}`}
        button
        component={Link}
        to={link}
        selected={index === selectedIndex}
        onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
          handleMenuItemClick(event, index)
        }
      >
        {!sideDrawerToggled ? (
          <Tooltip title={title} aria-label={title}>
            <ListItemIcon
              sx={{
                minWidth: '41px',
                color: (theme) =>
                  theme.palette.mode === 'dark' && index === selectedIndex
                    ? theme.palette.common.blueShadow['100']
                    : undefined,
              }}
            >
              {icon}
            </ListItemIcon>
          </Tooltip>
        ) : (
          <ListItemIcon
            sx={{
              minWidth: '41px',
              color: (theme) =>
                theme.palette.mode === 'dark' && index === selectedIndex
                  ? theme.palette.common.blueShadow['0']
                  : undefined,
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={title}
          sx={{
            '& .MuiListItemText-primary': {
              fontWeight: 400,
              color: (theme) =>
                theme.palette.mode === 'dark' && index === selectedIndex
                  ? theme.palette.common.white
                  : undefined,
            },
          }}
        />
      </MenuItem>
    );
  } else {
    return null;
  }
};

export default SideDrawerItem;
