import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  "& .MuiPaper-root": {
    height: 20,
    width: 200,
  },
}));
export default function SimpleTable({ data, width = 600 }) {
  if (data.length) {
    return (
      <TableContainer sx={{ height: 300 }} component={StyledPaper}>
        <Table sx={{ minWidth: width }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {data[0].map((col) => {
                return <TableCell>{col}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => {
              if (i !== 0) {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {data[i].map((col) => {
                      return <TableCell align="right">{col}</TableCell>;
                    })}
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <div>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }
}
