import { Box, Dialog, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import WorkFlow from '../../../screens/WorkFlow';
import { Row, SelectedFolder } from '../masterTableTypes';
import WarningModal from '../../../containers/workflow/Main/WarningModal';

interface WorkflowModalProps {
  workflowModal: boolean;
  setWorkflowModal: Dispatch<SetStateAction<boolean>>;
  selectedRecord: Row;
  records: Row[];
  canSetWorkflow: boolean;
  selectionModel: string[];
  selectedFolder: SelectedFolder;
}

const styles = {
  dialog: {
    overflow: 'hidden',
    '& .MuiDialog-paper': {
      overflow: 'hidden',
      height: '800px',
      maxWidth: '1000px',
      width: '1000px',
    },
  },
  container: {
    backgroundColor: 'common.greyblueDarker',
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    p: '10px',
    paddingBottom: '1px',
  },
};

export const WorkflowModal = ({
  workflowModal,
  setWorkflowModal,
  selectedRecord,
  records,
  canSetWorkflow,
  selectionModel,
  selectedFolder,
}: WorkflowModalProps) => {
  const [modalHasModifications, setModalHasModifications] = useState<boolean>(false);
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);

  const handleClose = () =>
    modalHasModifications ? setPopupIsOpen(true) : setWorkflowModal(false);

  const handleConfirm = (e: any) => {
    e.preventDefault();
    setWorkflowModal(false);
    setPopupIsOpen(false);
    setModalHasModifications(false);
  };

  const handleCloseWarning = () => setPopupIsOpen(false);

  return (
    <>
      <Dialog open={workflowModal} onClose={handleClose} sx={styles.dialog}>
        {selectionModel.length ? (
          <Box sx={styles.container}>
            <Typography variant="button" sx={{ color: 'common.white', fontSize: 10 }}>
              {`This workflow is going to be set on ${selectionModel.length} Records`}
            </Typography>
          </Box>
        ) : null}
        <WorkFlow
          context="modal"
          recordId={selectedRecord?.id}
          selectedFolder={selectedRecord?.folderId || selectedFolder}
          records={records}
          setWorkflowModal={setWorkflowModal}
          canSetWorkflow={canSetWorkflow}
          setModalHasModifications={setModalHasModifications}
          recordsToSet={selectionModel}
        />
      </Dialog>
      <WarningModal
        isOpen={popupIsOpen}
        handleClose={handleCloseWarning}
        handleConfirm={handleConfirm}
      />
    </>
  );
};
