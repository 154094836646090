import { TableSortLabelProps } from '@material-ui/core';
import { Table } from 'dexie';
import { Dispatch } from 'react';
import { Folder, LastEnteredValue } from '../components/masterTable/masterTableTypes';
import utils from '../utils/utils';
import {
  TableContextAction,
  TableContextState,
  TableSettings,
} from './tableContextTypes';

const tableReducer = (state: TableContextState, action: TableContextAction) => {
  switch (action.type) {
    case 'setSelectedFolder':
      return {
        ...state,
        selectedFolder: action.payload,
      };
    case 'setTableSettings':
      let settings = state.tableContextSettings;
      settings[action.payload.screen as keyof TableSettings] = action.payload.settings;
      return {
        ...state,
        settings,
      };
    case 'setLastEnteredValues':
      return {
        ...state,
        lastEnteredValues: action.payload,
      };
    case 'setRecordToUpdate':
      window.log('setting record update 2', state.updatedRecord + 1);
      // let numberOfUpdate = state.updatedRecord + 1;

      return {
        ...state,
        recordToUpdate: action.payload,
        // updatedRecord: numberOfUpdate,
      };
    case 'setRowLoader':
      return {
        ...state,
        rowLoader: action.payload,
        rowLoaderAmmount: action.payload.length,
      };
    case 'addTextractProcessLoader':
      return {
        ...state,
        textractProcessLoader: [...state.textractProcessLoader, action.payload],
      };
    case 'removeTextractProcessLoader':
      return {
        ...state,
        textractProcessLoader: state.textractProcessLoader.filter(
          (row) => row.recordId !== action.payload.recordId
        ),
      };
    case 'removeRowLoader':
      let numberOfUpdate = state.updatedRecord + 1;
      let newRowLoader =
        state.rowLoaderAmmount === state.OCRResponseAmmount + 1
          ? []
          : !action.completed
          ? state.rowLoader
          : state.rowLoader.filter((id) => id !== action.payload);
      return {
        ...state,
        rowLoader: newRowLoader,
        OCRResponseAmmount: newRowLoader.length === 0 ? 0 : state.OCRResponseAmmount + 1,
        updatedRecord: numberOfUpdate,
      };
    case 'restartCount':
      return {
        ...state,
        updatedRecord: 0,
      };
    default:
      return state;
  }
};
const setTableSelectedFolder =
  (dispatch: Dispatch<TableContextAction>) => (folder: Folder) => {
    try {
      dispatch({ type: 'setSelectedFolder', payload: folder });
    } catch (err) {
      window.log(err);
    }
  };
const setTableContextSettings =
  (dispatch: Dispatch<TableContextAction>) =>
  (settings: TableSettings, screen: keyof TableSettings) => {
    try {
      dispatch({
        type: 'setTableSettings',
        payload: { settings: settings, screen: screen },
      });
    } catch (err) {
      window.log(err);
    }
  };
const setLastEnteredValues =
  (dispatch: Dispatch<TableContextAction>) => (values: LastEnteredValue[]) => {
    try {
      dispatch({ type: 'setLastEnteredValues', payload: values });
    } catch (err) {
      window.log(err);
    }
  };
const setRecordToUpdate = (dispatch: Dispatch<TableContextAction>) => (values: any) => {
  window.log('setting record update 1');
  try {
    dispatch({ type: 'setRecordToUpdate', payload: values });
  } catch (err) {
    window.log(err);
  }
};
const setRowLoader =
  (dispatch: Dispatch<TableContextAction>) => (values: any, ammount: number) => {
    try {
      dispatch({ type: 'setRowLoader', payload: values, ammount });
    } catch (err) {
      window.log(err);
    }
  };

const addTextractProcessLoader =
  (dispatch: Dispatch<TableContextAction>) => (values: any, ammount: number) => {
    try {
      dispatch({ type: 'addTextractProcessLoader', payload: values, ammount });
    } catch (err) {
      window.log(err);
    }
  };

const removeTextractProcessLoader =
  (dispatch: Dispatch<TableContextAction>) => (values: any) => {
    try {
      dispatch({ type: 'removeTextractProcessLoader', payload: values });
    } catch (err) {
      window.log(err);
    }
  };

const removeRowLoader =
  (dispatch: Dispatch<TableContextAction>) => (values: any, completed: number) => {
    try {
      dispatch({ type: 'removeRowLoader', payload: values, completed });
    } catch (err) {
      window.log(err);
    }
  };

const restartCount = (dispatch: Dispatch<TableContextAction>) => (values: any) => {
  try {
    dispatch({ type: 'restartCount', payload: values });
  } catch (err) {
    window.log(err);
  }
};
export const { Provider, Context } = utils.createDataContext(
  tableReducer,
  {
    setTableSelectedFolder,
    setTableContextSettings,
    setLastEnteredValues,
    setRecordToUpdate,
    setRowLoader,
    removeRowLoader,
    restartCount,
    addTextractProcessLoader,
    removeTextractProcessLoader,
  },
  {
    tableSelectedFolder: {},
    tableContextSettings: {
      search: {},
      indexing: {},
      users: [],
      folders: [],
    },
    lastEnteredValues: [],
    recordToUpdate: {},
    updatedRecord: 0,
    rowLoader: [],
    textractProcessLoader: [],
    rowLoaderAmmount: 0,
    OCRResponseAmmount: 0,
  }
);
