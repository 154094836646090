import { Dispatch } from 'react';
import utils from '../utils/utils';
import { db } from '../utils/indexedDB/indexeddb';
import {
  Action,
  State,
  TaskContent,
  TaskContextInfo,
  TaskNotification,
} from './tasksContextTypes';

const tasksReducer = (state: State, action: Action) => {
  window.log(state);
  let refetch = state.refetch;
  let tasks = state.tasks;
  let data: any = action.payload;

  switch (action.type) {
    case 'addTask':
      const taskID = data?.id;
      let newTask = {
        id: taskID,
        folderName: data?.folderName,
        action: data?.action,
        ammount: data?.ammount,
        progress: 0,
        fail: 0,
        success: 0,
        timeStamp: Date.now(),
      };
      window.log(newTask);
      db.table('tasks')
        .add(newTask)
        .then((task) => {})
        .catch((e: React.SyntheticEvent) => {
          window.log(e);
        });
      tasks.push(newTask);
      break;
    case 'addTasksAsync':
      // window.log(data);
      tasks = [...tasks, ...data];
      break;
    case 'removeTask':
      tasks = tasks.filter((task) => task.id !== data?.id);
      db.table('tasks').where('id').equals(data?.id).delete();
      break;
    case 'updateTask':
      window.log('updating task: ', data);
      if (!data.taskId) break;
      db.transaction(
        'rw',
        db.table('tasks'),
        /* db.table('taskNotifications'),
        db.table('indexingScreen'), */
        async () => {
          let selectedTask: TaskContent = await db.table('tasks').get(data?.taskId);
          window.log(selectedTask);
          if (selectedTask && !selectedTask.isDone) {
            // window.log(selectedTask);
            let { success, fail, progress } = selectedTask;
            if (progress < 100) {
              switch (data?.taskResult) {
                case 'Success':
                  success++;
                  break;
                case 'Error':
                  fail++;
                  break;
                default:
                  break;
              }

              let percentage = 100 / selectedTask.ammount;
              progress = (success + fail) * percentage;
            }
            selectedTask = { ...selectedTask, progress, success, fail, onlyPopup: true };
            action.utv?.(selectedTask);
            if (progress >= 100.0 && !selectedTask.isDone) {
              selectedTask['isDone'] = true;
              if (data.doRefetch) {
                refetch = state.refetch + 1;
              }
              await action.cb?.(selectedTask);
            }
            await db.table('tasks').put(selectedTask);
          }
        }
      ).catch((err) => {
        // Probably task doesn't exist

        console.error(err.stack);
      });
      break;
    case 'finishTask':
      db.transaction('rw', db.table('tasks'), async () => {
        let selectedTask: TaskContent = await db.table('tasks').get(data?.taskId);
        if (selectedTask) {
          selectedTask = {
            ...selectedTask,
            progress: 100,
            success: data.success,
            fail: data.fail,
            onlyPopup: true,
            isDone: true,
          };
          await action.utv?.(selectedTask);
          await action.cb?.(selectedTask);
          await db.table('tasks').put(selectedTask);
          refetch = state.refetch + 1;
        }
      }).catch((err) => {
        // Probably task doesn't exist

        console.error(err);
      });

      break;
    case 'updateTaskVisualization':
      tasks = tasks.filter((task) => task.id !== data?.id);
      tasks.push(data);
      break;
    case 'clearTasks':
      return {
        tasks: [],
        refetch: 0,
      };
    default: {
      throw new Error(`Unhandled action type: ${action?.type}`);
    }
  }
  return {
    ...state,
    tasks,
    refetch,
  };
};
const finishTask = (dispatch: Dispatch<Action>) => async (task: TaskNotification) => {
  try {
    dispatch({
      type: 'finishTask',
      payload: task.data,
      cb: task.addTaskNotification,
      utv: task.updateTaskVisualization,
    });
  } catch (err) {
    window.log(err);
  }
};
const addTask = (dispatch: Dispatch<Action>) => async (task: TaskContent) => {
  try {
    dispatch({ type: 'addTask', payload: task });
  } catch (err) {
    window.log(err);
  }
};
const addTasksAsync = (dispatch: Dispatch<Action>) => async (task: TaskContent) => {
  try {
    dispatch({ type: 'addTasksAsync', payload: task });
  } catch (err) {
    window.log(err);
  }
};
const removeTask = (dispatch: Dispatch<Action>) => async (task: TaskContent) => {
  try {
    dispatch({ type: 'removeTask', payload: task });
  } catch (err) {
    window.log(err);
  }
};
const updateTask = (dispatch: Dispatch<Action>) => async (task: TaskNotification) => {
  window.log(task);
  try {
    dispatch({
      type: 'updateTask',
      payload: task.data,
      cb: task.addTaskNotification,
      utv: task.updateTaskVisualization,
    });
  } catch (err) {
    window.log(err);
  }
};
const updateTaskVisualization =
  (dispatch: Dispatch<Action>) => async (task: TaskContextInfo) => {
    try {
      dispatch({
        type: 'updateTaskVisualization',
        payload: task,
      });
    } catch (err) {
      window.log(err);
    }
  };
const clearTasks = (dispatch: Dispatch<Action>) => async () => {
  try {
    dispatch({
      type: 'clearTasks',
    });
  } catch (err) {
    window.log(err);
  }
};
export const { Provider, Context } = utils.createDataContext(
  tasksReducer,
  {
    finishTask,
    addTask,
    addTasksAsync,
    removeTask,
    updateTask,
    updateTaskVisualization,
    clearTasks,
  },
  {
    tasks: [],
    refetch: 0,
  }
);
