import React from 'react';

import { Avatar, Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import FolderIcon from '../../images/folder-icon.svg';
import FolderIconActive from '../../images/folder-icon-active.svg';
import { Folder } from './types';

const classes = {
  boxContainer: {
    backgroundColor: 'white',
    height: 'auto',
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: '.3s',
    paddingRight: '10px',
    '&:hover': {
      boxShadow: '0px 5px 5px rgb(0 0 0 / 10%)',
      cursor: 'pointer',
    },
  },
};

const FolderCardCompact = ({ folder }: { folder: Folder }) => {
  let history = useHistory();
  const { id, name, createdBy, active } = folder;
  const folderIsActive: boolean | undefined = active;
  return (
    <>
      <Box sx={classes.boxContainer} onClick={() => history.push(`/dash/folders/${id}`)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            padding: '5px 0',
            paddingLeft: '10px',
          }}
        >
          <Avatar variant="square" src={folderIsActive ? FolderIconActive : FolderIcon} />
          <Box>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color={(theme) =>
                theme.palette.mode === 'dark' ? 'common.blueGrey.0' : 'common.blue'
              }
              sx={{ fontSize: 14, margin: 0 }}
            >
              {name}
            </Typography>
            <Typography
              variant="h6"
              fontSize={11}
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.blueGrey.400' : undefined,
              }}
            >
              {createdBy}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FolderCardCompact;
