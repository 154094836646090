import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import ModalWrapper from '../../../../components/modal/ModalWrapper';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { useRef, useState } from 'react';
import AddButton from './AddButton';
import RowItem from './RowItem';
import ConfigCSVData from './ConfigCSVData';
import Papa, { ParseResult } from 'papaparse';
import EditableRowItem from './EditableRowItem';
import { UPDATE_FOLDER } from '../../../../utils/gql/gqlFolders';
import { useMutation } from 'react-apollo';
import { Folder } from '../../types';
import { StatusProps } from '../../../../components/SnackBar';
import { some } from 'lodash';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';

interface IdentifierConfigModalProps {
  folder: Folder;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  setSnackbar?: React.Dispatch<React.SetStateAction<StatusProps>>;
  refetch?: any;
  fromMasterTable?: boolean;
}

export type Row = {
  code: string;
  name: string;
  [key: string]: any;
};

const styles = {
  card: { height: '100%', borderRadius: 'inherit' },
  title: { color: 'common.lightblue', fontSize: '12px' },
  header: {
    padding: '0 20px',
    backgroundColor: 'common.greyblueDarker',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    color: 'common.lightblue',
    position: 'relative',
    top: '7px',
    right: '-25px',
  },
  content: {
    minHeight: '500px',
    maxHeight: '600px',
    maxWidth: '1000px',
    minWidth: { xs: '80vw', md: '600px' },
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
  },
  loadButton: {
    backgroundColor: 'common.greyblueDarker',
    gap: 1,
    alignItems: 'center',
    '&:hover': { backgroundColor: 'common.greyblueDark' },
  },
  saveButton: {
    backgroundColor: 'common.positive',
    '&:hover': { backgroundColor: 'common.positiveDark' },
  },
};

const IdentifierConfigModal = ({
  folder,
  isOpen,
  handleOpen,
  handleClose,
  setSnackbar,
  refetch,
  fromMasterTable = false,
  setCurrentFolder,
}: IdentifierConfigModalProps) => {
  const [editableRowIndex, setEditableRowIndex] = useState<null | number>(null);
  const [rows, setRows] = useState<Row[]>(
    folder.apProcessingSettings?.ocrTemplateNameSupplierCodePairs || []
  );
  const [csv, setCsv] = useState<ParseResult<any> | null>(null);
  const [error, setError] = useState({ error: false, message: '' });
  const [updateFolder] = useMutation(UPDATE_FOLDER);
  const [{ suser }] = useLocalStorage('userData');
  // const [visibleRows, setVisibleRows] = useState(20);
  const observerComponent = useRef(null);

  const getGlobalOptions = () => {
    return {
      active: folder?.active || false,
      scanning: folder?.scanning || false,
      autoAppendRecords: folder?.autoAppendRecords || false,
      workFlow: folder?.workFlow || false,
      ocrEnabled: folder?.ocrEnabled || false,
      enforceWorkflowNotes: false,
      apProcessingSettings: folder?.apProcessingSettings || null,
    };
  };

  const handleSave = () => {
    let hasCodeUnfilled = some(rows, ['code', '']);

    if (!hasCodeUnfilled) {
      updateFolder({
        variables: {
          ...getGlobalOptions(),
          folderId: folder.id,
          apProcessingSettings: {
            ocrTemplateNameSupplierCodePairs: rows.map(({ code, name }) => ({
              code,
              name,
            })),
          },
        },
      })
        .then((data) => {
          setRows(
            data.data.updateFolder.apProcessingSettings.ocrTemplateNameSupplierCodePairs
          );
          handleClose();
          setSnackbar?.({
            status: true,
            severity: 'success',
            snackbarMessage: 'Data updated successfully',
          });
          fromMasterTable
            ? refetch(data.data.updateFolder)
            : setCurrentFolder(data.data.updateFolder);
        })
        .catch((error) => {
          setSnackbar?.({
            status: true,
            severity: 'error',
            snackbarMessage: 'There was an error',
          });
        });
    } else {
      setSnackbar?.({
        status: true,
        severity: 'error',
        snackbarMessage: 'Code value can not be empty',
      });
    }
  };

  const addRow = () => {
    let newRow = { id: Number(new Date()), code: '', name: '' };
    setRows((prev) => [...prev, newRow]);
    setEditableRowIndex(rows.length);
  };

  const deleteRow = (rowIndex: number) => {
    setRows((rows) => rows.filter((row, index) => index !== rowIndex));
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (results) => {
        if (results.data[0]) setCsv(results);
      },
    });
  };

  const handleEditRow = (newRow: Row, rowIndex: number) => {
    setRows((rows) => rows.map((row, index) => (index === rowIndex ? newRow : row)));
  };

  // useEffect(() => {
  //   if (observerComponent.current) {
  //     const observer = new IntersectionObserver(
  //       (entries) => {
  //         if (entries[0].isIntersecting) setVisibleRows((prev) => prev + 40);
  //       },
  //       { threshold: 1 }
  //     );

  //     if (observerComponent.current) observer.observe(observerComponent.current);

  //     return () => {
  //       if (observerComponent.current) observer.unobserve(observerComponent.current);
  //     };
  //   }
  // }, [observerComponent.current]);

  return (
    <ModalWrapper isOpen={isOpen} setIsOpen={handleOpen}>
      <Card sx={styles.card}>
        <CardHeader
          title={
            <Typography variant="button" sx={styles.title}>
              Code/Name Pair Setup
            </Typography>
          }
          sx={styles.header}
          action={
            <Button onClick={handleClose} sx={styles.closeButton}>
              <FontAwesomeIcon icon="fas fa-times" size="16px" />
            </Button>
          }
        />
        {csv ? (
          <ConfigCSVData
            csv={csv}
            setCsv={setCsv}
            handleClose={handleClose}
            setRows={setRows}
          />
        ) : (
          <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
            {rows.length > 8 ? (
              <Button
                onClick={() => setRows([])}
                sx={{
                  color: 'common.negative',
                  fontSize: 10,
                  alignItems: 'center',
                  gap: '5px',
                  alignSelf: 'flex-end',
                }}
                size="small"
              >
                Delete all <FontAwesomeIcon icon="fas fa-trash-alt" size="10px" />
              </Button>
            ) : null}
            <CardContent>
              <Box
                sx={{
                  ...styles.content,
                  paddingTop: rows.length ? '4px' : '10px',
                  marginBottom: '8px',
                }}
              >
                <Box
                  sx={{
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {rows.length ? (
                    <Box sx={{ display: 'flex', padding: '0px 20px' }}>
                      <Typography
                        sx={{ width: '200px', color: 'common.greyblue', fontWeight: 700 }}
                      >
                        CODE
                      </Typography>
                      <Typography
                        sx={{ width: 'auto', color: 'common.greyblue', fontWeight: 700 }}
                      >
                        NAME
                      </Typography>
                    </Box>
                  ) : null}
                  {rows.map((row, index) => {
                    return index === editableRowIndex ? (
                      <EditableRowItem
                        row={row}
                        setEditableRowIndex={setEditableRowIndex}
                        handleEditRow={handleEditRow}
                        index={index}
                        key={index}
                        rows={rows}
                        setError={setError}
                        error={error}
                      />
                    ) : (
                      <RowItem
                        index={index}
                        row={row}
                        setEditableRowIndex={setEditableRowIndex}
                        deleteRow={deleteRow}
                        key={index}
                      />
                    );
                  })}
                  <div ref={observerComponent}></div>
                </Box>
              </Box>
              <AddButton addRow={addRow} rows={rows} />
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end', gap: '10px' }}>
              <>
                <input
                  type="file"
                  id="csv-file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <label htmlFor="csv-file">
                  <Button
                    variant="contained"
                    component="span"
                    sx={styles.loadButton}
                    size="small"
                  >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Box sx={{ marginTop: '2px' }}>
                        <FontAwesomeIcon icon="fas fa-file-csv" size={13} padding={0} />
                      </Box>
                      <Typography
                        variant="button"
                        sx={{ fontSize: '13px', color: 'common.white' }}
                      >
                        Load from csv
                      </Typography>
                    </Box>
                  </Button>
                </label>
              </>
              <Button
                onClick={handleSave}
                variant="contained"
                component="span"
                sx={styles.saveButton}
                size="small"
              >
                Submit
              </Button>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: 'common.negative',
                  '&:hover': { backgroundColor: 'common.negativeDark' },
                }}
                size="small"
              >
                Cancel
              </Button>
            </CardActions>
          </Box>
        )}
      </Card>
    </ModalWrapper>
  );
};

export default IdentifierConfigModal;
