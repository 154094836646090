import { Box, Card } from '@mui/material';
import React, { useState } from 'react';
import FontAwesomeIcon from '../components/FAIcon';
import {
  a11yProps,
  StyledTab,
  StyledCardContent,
  StyledTabPanel,
  StyledTabs,
} from '../containers/comm/CommStyled';
import CustomToolbar from '../containers/maintenance/CustomToolbar';
import ServerLogTab from '../containers/maintenance/ServerLogTab';
import UpdateTab from '../containers/maintenance/UpdateTab';

const Maintenance = () => {
  const [tabValue, setTabValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) =>
    setTabValue(newValue);

  return (
    <Box sx={{ padding: '10px 10px' }}>
      <CustomToolbar />
      <Card variant="outlined" sx={{ marginTop: '15px' }}>
        <StyledCardContent>
          <StyledTabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            <StyledTab
              icon={<FontAwesomeIcon icon="fas fa-wrench" />}
              label="Update"
              {...a11yProps(0)}
            />
            <StyledTab
              icon={<FontAwesomeIcon icon="far fa-server" />}
              label={'Server Log'}
              {...a11yProps(1)}
            />
          </StyledTabs>
          <>
            <StyledTabPanel value={tabValue} index={0} fullWidth>
              <UpdateTab />
            </StyledTabPanel>
            <StyledTabPanel value={tabValue} index={1} fullWidth>
              <ServerLogTab />
            </StyledTabPanel>
          </>
        </StyledCardContent>
      </Card>
    </Box>
  );
};

export default Maintenance;
