import React, { ReactNode, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';

export interface ToolbarBaseProps {
  children: ReactNode;
  elevation?: number;
  hasBackground?: boolean;
}

const ToolbarBase = ({ children, elevation, hasBackground = true }: ToolbarBaseProps) => {
  const { state } = useContext(StatusContext) as StatusContextTypes;
  return (
    <>
      <AppBar
        sx={{
          position: 'sticky',
          borderRadius: 1,
          backgroundColor: hasBackground
            ? state.paletteMode === 'light'
              ? 'common.greyblueDark'
              : 'common.greyblueDarker'
            : 'transparent',
          height: '40px',
          justifyContent: 'center',
        }}
        elevation={elevation}
      >
        <Toolbar
          sx={{
            padding: '0!important',
            minHeight: '30px',
            justifyContent: 'space-between',
          }}
        >
          {children}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default ToolbarBase;
