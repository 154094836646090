import React from 'react';
import { Box, List, Popover } from '@mui/material';
import ShareMenu from './shareMenu';

import Toolbar from './Toolbar';
import { showCollection } from './utils';
import { Record } from '../../containers/search/types';

interface PopoverBinProps {
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  handleClose: () => void;
  toggleShareMenu: () => void;
  isShareMenuOpen: boolean;
  clearCollectionBin: () => void;
  collection: Collection[];
  handleSaving: () => void;
  removeOneItem: (id: string) => void;
}

export interface Collection extends Record {
  folder: string;
}

const classes = {
  list: {
    minWidth: '450px',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
    '& li:nth-of-type(2n)': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    padding: 0,
    maxHeight: '475px',
    paddingBottom: 0,
  },
  arrow: {
    border: '10px solid transparent',
    borderBottomColor: 'common.greyblueDarker',
    width: '0',
    maxWidth: '0',
    position: 'relative',
    left: '83%',
  },
  box: {
    backgroundColor: 'common.greyblueDarker',
    borderRadius: '5px',
    boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
    color: 'white.main',
    width: '450px',
    paddingBottom: '10px',
  },
  popover: {
    marginRight: 10,
  },
  paper: {
    backgroundColor: 'transparent',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};

const PopoverBin = ({
  anchorEl,
  handleClose,
  toggleShareMenu,
  isShareMenuOpen,
  clearCollectionBin,
  collection,
  handleSaving,
  removeOneItem,
}: PopoverBinProps) => {
  if (!collection) return null;
  return (
    <Popover
      PaperProps={{ sx: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: 44,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClick={(e) => e.stopPropagation()}
      sx={classes.popover}
    >
      <Box sx={classes.arrow}></Box>

      <Box data-testid="collection-bin-popover" sx={{ ...classes.box }}>
        <Toolbar
          isShareMenuOpen={isShareMenuOpen}
          toggleShareMenu={toggleShareMenu}
          clearCollectionBin={clearCollectionBin}
          collection={collection}
        />
        <Box style={{ display: 'flex', height: '100%' }}>
          <List
            sx={{
              ...classes.list,
              overflowY: collection.length > 8 ? 'scroll' : 'hidden',
              transform: isShareMenuOpen ? 'translate(-400px)' : 'translate(0)',
              opacity: isShareMenuOpen ? '0' : '1',
              transition: 'all .3s ease-in-out',
              minHeight: collection.length > 0 ? '100px' : '200px',
              height: isShareMenuOpen ? '20px' : 'auto',
            }}
          >
            {showCollection(collection, removeOneItem, handleClose)}
          </List>
          <ShareMenu
            handleSaving={handleSaving}
            collection={collection}
            isShareMenuOpen={isShareMenuOpen}
          />
        </Box>
      </Box>
    </Popover>
  );
};

export default PopoverBin;
