const debugInit = () => {
  let isDebug = process.env.REACT_APP_DEBUG_TOOL === 'true';
  if (isDebug) {
    if (Function.prototype.bind) {
      window.log = console.log.bind.call(console.log, console);
    } else {
      window.log = function () {
        Function.prototype.apply.call(console.log, console, arguments);
      };
    }
  } else {
    window.log = function () {};
  }
};
export default debugInit;
