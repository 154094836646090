import { Grid } from '@mui/material';
import React from 'react';
import FolderCards from './FolderCards';
import { Folder, FolderGridProps } from './types';

const FolderGrid = ({ folders, folderCompact }: FolderGridProps) => {
  return (
    <Grid
      container
      sx={{
        margin: '0 auto',
        marginTop: '20px',
        gap: 1,
      }}
      alignItems="center"
    >
      {folders &&
        folders.map((folder: Folder, i: number) => (
          <React.Fragment key={folder.name + i}>
            <FolderCards key={folder.id} folder={folder} folderCompact={folderCompact} />
          </React.Fragment>
        ))}
    </Grid>
  );
};

export default FolderGrid;
