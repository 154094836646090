import { useMutation } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import GeneralSnackbar, { StatusProps } from '../components/SnackBar';
import { UserData } from '../containers/search/types';
import { Context as AuthContext, AuthContextTypes } from '../context/authContext';
import { Context as StatusContext } from '../context/statusContext';
import { StatusContextTypes } from '../context/statusContextTypes';
import { useLocalStorage } from '../hooks/useLocalStorage';
import Background from '../images/background-blue.webp';
import Image from '../images/form-image.png';
import { LOGIN_USER } from '../utils/gql/gqlUsers';
import { clearIndexedDB } from '../utils/indexedDB/clearIndexedDB';
import { db } from '../utils/indexedDB/indexeddb';
const schema = yup.object().shape({
  emailaddress: yup.string().trim().email().required(),
  password: yup.string().required(),
});

type FormValues = {
  emailaddress: string;
  password: string;
};

type DataUser = {
  error: any;
  scan: any;
  superUser: boolean | null;
  user?: UserData;
  suser?: UserData;
};

const styles = {
  main: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  paper: {
    alignItems: 'center',
    padding: '20px',
    borderRadius: '4px',
    boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
  },
  error: {
    color: 'red',
  },
  copyright: {
    color: 'white',
    '& > :visited': {
      color: 'white',
    },
  },
};

export default function SignIn() {
  function Copyright() {
    return (
      <Typography
        sx={{
          // color: (theme) =>
          //   theme.palette.mode === 'dark'
          //     ? theme.palette.common.white
          //     : theme.palette.common.blue,
          // '& > :visited': {
          //   color: (theme) =>
          //     theme.palette.mode === 'dark'
          //       ? theme.palette.common.white
          //       : theme.palette.common.blue,
          color: 'common.white',
          '& > :visited': {
            color: 'common.white',
          },
        }}
        align="center"
      >
        {'Copyright © '}
        <Link href="https://www.DataCapture.ie" sx={{ color: 'red' }}>
          www.DataCapture.ie
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeLS = localStorage.getItem('theme');
  const [pageToAccess, setPageToAccess] = useState<string | null>(null);
  // page to access or previous page is used when the user tries to enter to app page without being logged in. Then we save this data and when user logs in we redirect him to that page
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage('isLoggedIn', false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const { signin } = useContext(AuthContext) as AuthContextTypes;
  const { setScreen, state, setPaletteMode } = useContext(
    StatusContext
  ) as StatusContextTypes;
  const previousPage = localStorage.getItem('previousPage');
  const [snackbarStatus, setSnackbarStatus] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'error',
  });

  const handleSnackClose = (
    event: Event | React.SyntheticEvent<Element, Event>,
    reason: string | undefined
  ) => {
    if (reason === 'clickaway') return;
    setSnackbarStatus({
      status: false,
      snackbarMessage: '',
      severity: 'error',
    });
  };

  const setUserSettings = (data: DataUser, user: keyof DataUser) => {
    let userSettings = data[user].userSettings;
    let { indexedDB } = userSettings;
    indexedDB = JSON.parse(indexedDB);
    clearIndexedDB();
    Object.entries(indexedDB).forEach(([key, value]: [string, any]) =>
      db.table(key).bulkAdd(value || [])
    );
  };

  const [loginMutation, { loading }] = useMutation(LOGIN_USER);
  const SignInUser = async (values: FormValues) => {
    loginMutation({
      variables: {
        email: values.emailaddress,
        password: values.password,
      },
    })
      .then(({ data }) => {
        let user: keyof DataUser = data.loginUser.user ? 'user' : 'superUser';
        if (data.loginUser.error === 'Password Change Required!') {
          data.loginUser.paletteMode = state.paletteMode;
          signin(data.loginUser);
          setIsLoggedIn('pw');
          if (data.loginUser[user].userSettings) setUserSettings(data.loginUser, user);
        } else if (data.loginUser[user] != null) {
          data.loginUser.paletteMode = state.paletteMode;
          signin(data.loginUser);
          setIsLoggedIn(true);

          if (data.loginUser[user].userSettings) setUserSettings(data.loginUser, user);
        } else {
          setIsLoggedIn(false);
          setSnackbarStatus({
            status: true,
            snackbarMessage: 'User Authentication Failed',
            severity: 'error',
          });
        }
      })
      .catch((e) => {
        signin(false);
        setSnackbarStatus({
          status: true,
          snackbarMessage:
            'Our servers are currently down due to a schedule maintenance or update, please try again in a few minutes',
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    setScreen('login');
  }, [state.screen]);

  useEffect(() => {
    setPageToAccess(previousPage);
  }, []);
  if (!isLoggedIn) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          ...styles.main,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.black.main
              : theme.palette.common.white,
          backgroundImage: `url(${Background})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          gap: '20px',
        }}
      >
        <Typography
          variant="button"
          sx={{ color: 'common.white', fontSize: 30, textAlign: 'center' }}
        >
          Welcome to DataCapture
        </Typography>
        <Paper
          sx={{
            display: 'flex',
            width: '70%',
            height: { xs: '50%', md: '60%', lg: '60%' },
            alignItems: 'center',
            padding: { xs: '0px', md: '20px', lg: '20px' },
          }}
        >
          <Paper
            sx={{
              position: 'relative',
              padding: '5px',
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flex: { xs: 6, md: 8, lg: 10 },
            }}
            elevation={20}
          >
            <Stack
              flex={{ xs: 1, md: 0.4 }}
              justifyContent="center"
              alignItems="center"
              gap={6}
              sx={{ width: '80%' }}
            >
              <Stack alignItems="center" gap={2}>
                <Avatar
                  sx={{
                    margin: 1,
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.blueShadow['500']
                        : '#ffc929',
                    color: 'common.white',
                  }}
                >
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  component="h1"
                  variant="button"
                  align="center"
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.common.blue,
                    fontSize: 16,
                  }}
                >
                  Please log in to your account
                </Typography>
              </Stack>
              <form
                style={{
                  width: '100%', // Fix IE 11 issue.
                  maxWidth: '500px',
                  marginTop: 1,
                }}
                noValidate
                onSubmit={handleSubmit((data) => SignInUser(data))}
              >
                <Stack gap={4}>
                  <TextField
                    error={errors && errors.emailaddress ? true : false}
                    variant="standard"
                    required
                    fullWidth
                    id="emailaddress"
                    label="Email Address"
                    placeholder="Email Address"
                    autoComplete="email"
                    autoFocus
                    {...register('emailaddress')}
                    helperText={
                      errors && errors.emailaddress && 'Please add a valid email'
                    }
                  />

                  <TextField
                    error={errors && errors.password ? true : false}
                    variant="standard"
                    required
                    {...register('password')}
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    helperText={
                      errors && errors.password && 'Please add a valid password'
                    }
                  />
                </Stack>

                <LoadingButton
                  loading={loading}
                  loadingIndicator="Loading…"
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{
                    marginTop: '3rem',
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.blueShadow['500']
                        : theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.common.blueShadow['300']
                          : theme.palette.primary.dark,
                    },
                    color: 'common.white',
                  }}
                >
                  Sign In
                </LoadingButton>

                <GeneralSnackbar status={snackbarStatus} handleClose={handleSnackClose} />
              </form>
            </Stack>
          </Paper>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex', lg: 'flex' },
              width: '100%',
              height: '100%',
              backgroundImage: `url(${Image})`,
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              borderRadius: '5px',
              flex: { xs: 8, md: 10, lg: 14 },
            }}
          ></Box>
        </Paper>
        <Box>
          <Copyright />
        </Box>
      </Stack>
    );
  } else if (isLoggedIn === 'pw') {
    return <Redirect to="/welcome" />;
  } else if (pageToAccess) {
    localStorage.removeItem('previousPage');
    return <Redirect to={pageToAccess} />;
  } else {
    return <Redirect to="/dash/search" />;
  }
}
