import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import GeneralSnackbar from './SnackBar';
import { useMutation } from '@apollo/react-hooks';
import gqlShared from '../utils/gql/gqlShared';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  hr: {
    display: 'flex',
    padding: '0 40px',
    justifyContent: 'space-between',
    margin: '20px 0px',
  },
  dialog: {
    padding: 20,
  },
  pageContainer: {
    marginTop: 20,
  },
}));

const ShareTo = ({ isOpen, handleClose, selectedRecord }) => {
  const classes = useStyles();
  const [link, setLink] = React.useState('');
  const [snackbar, setSnackbar] = React.useState({
    status: false,
    snackbarMessage: '',
    severity: '',
  });
  const [createShareMutation] = useMutation(gqlShared.CREATE_SHARE);
  const createShare = async () => {
    createShareMutation({
      variables: {
        recordId: selectedRecord.id,
        permissions: "['print','download']",
      },
    })
      .then(({ data }) => {
        let link =
          'https://' + window.location.hostname + '/shared/' + data.createShare.url;
        setLink(link);
        navigator.clipboard.writeText(link).then(
          function () {
            window.log('Copying to clipboard was successful!');
          },
          function (err) {
            console.error('Could not copy text: ', err);
          }
        );
        setSnackbar({
          status: true,
          snackbarMessage: 'Link created and copied on your clipboard',
          severity: 'success',
        });
      })
      .catch((e) => {
        window.log(e);
      });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };
  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle id="max-width-dialog-title">Share link</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid
          container
          direction="row"
          justify="space-around"
          spacing={2}
          className={classes.userCreationContainer}
        >
          <Grid item xs={8}>
            <TextField
              fullWidth
              name="link"
              type="text"
              value={link}
              variant="outlined"
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              //disabled={true}
              color="primary"
              onClick={() => {
                createShare();
              }}
              variant="contained"
              style={{ color: '#fff', height: '100%' }}
            >
              Generate Link
            </Button>
          </Grid>

          <Typography variant="caption">
            Anyone with the link will be able to access the record. The link will expire
            in 24hs
          </Typography>
        </Grid>
      </DialogContent>
      <GeneralSnackbar
        status={snackbar}
        handleClose={handleCloseSnackbar}
      ></GeneralSnackbar>
    </Dialog>
  );
};

export default ShareTo;
