import React, { useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Tooltip,
  Box,
  Button,
  SvgIcon,
  Modal,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import FontAwesomeIcon from '../../FAIcon';
import UploadDialog from './UploadDialog';
import ErrorHandler from '../../../utils/errorHandler';
import PDFReportTool from './PDFReportTool/PDFReportTool';
import { Context as tasksContext } from '../../../context/tasksContext';
import { Context as rtcContext } from '../../../context/RTCContext';
import { Context as AuthContext } from '../../../context/authContext';
import utils from '../../../utils/utils';
import {
  useLocalStorage,
  modifyLocalStorageObject,
} from '../../../hooks/useLocalStorage';
import { Row, User } from '../masterTableTypes';
import { CustomToolbarActionButtonsProps } from './CustomToolbarActionButtonsTypes';
import {
  hasDocumentScannerPermissions,
  hasExportPermissions,
  hasUploadPermissions,
} from '../../../containers/search/searchUtils';

const StyledButtons = styled(Button)(({ theme }) => ({
  height: '32px',
  width: '32px',
  minWidth: '32px',
  borderRadius: '0',
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.common.blueShadow['400'] : undefined,
  ':hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.common.blueShadow['500']
        : theme.palette.common.greyblueDarker,
  },
}));

export default function CustomToolbarActionButtons(
  props: CustomToolbarActionButtonsProps
) {
  const {
    screen,
    handleClickOpenScan,
    selectedFolder,
    setMessage,
    setSnackOpen,
    rows,
    cols,
    createRecord,
    setErrorFiles,
    setLoadingFiles,
    loadingFiles,
    exportQuery,
    anchorUploadEl,
    setAnchorUploadEl,
    handleUploadClick,
    isUploadFromDrag,
    theUser,
    users,
    count,
    selectedTemplate,
    buttonReportRef,
  } = props;

  const [progress, setProgress] = React.useState(0);
  const [files, setFiles] = React.useState<File[]>([]);
  const [retriesFiles, setRetriesFiles] = React.useState<File[]>([]);
  const [dropAreaKey, setDropAreaKey] = React.useState(0);
  const [selectedUsers, setSelectedUsers] = React.useState<User[]>(theUser);
  const [userData, setUserData] = useLocalStorage('userData');
  //const [deleteRecordMutation, { loading }] = useMutation(gqlRecords.DELETE_RECORD);
  const { addTask } = useContext(tasksContext);
  const { addTaskRTC } = useContext(rtcContext);
  const { state: authState } = useContext(AuthContext);
  const printUploadPopup = () => {
    return (
      <UploadDialog
        key={'dropArea' + dropAreaKey}
        anchorEl={anchorUploadEl}
        onClose={setAnchorUploadEl}
        handleUpload={handleUpload}
        handleFilesChange={handleFilesChange}
        files={files}
        isUploadFromDrag={isUploadFromDrag}
        users={users}
        theUser={theUser}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        loadingFiles={loadingFiles}
        count={count}
      ></UploadDialog>
    );
  };
  const handleUpload = async (selectedUsers: User[]) => {
    if (!selectedFolder) {
      setMessage({
        message: 'you forgot to select a folder',
        severity: 'warning',
      });
      setSnackOpen(true);
    } else if (!files.length) {
      setMessage({ message: 'you forgot to add files', severity: 'warning' });
      setSnackOpen(true);
    } else {
      setLoadingFiles?.(true);
      processFiles(files, selectedUsers);
    }
  };

  useEffect(() => {
    if (retriesFiles.length === 0) return;
    // retries uploading the file every 1.5 seconds
    let timer = setTimeout(() => {
      if (selectedUsers) {
        processFiles(retriesFiles, selectedUsers);
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [retriesFiles]);

  const createFields = (files: Row[]) => {
    let newData = files.map((item) => {
      let newFields = selectedFolder?.folderFields.map((folderField) => {
        let newField = {
          _typename: 'Fields',
          field: {
            _typename: 'Field',
            id: folderField.id,
            name: folderField.name,
          },
          value: '',
        };
        return newField;
      });
      let newField = {
        _typename: 'Fields',
        field: {
          _typename: 'Field',
          id: 'createdAtField',
          name: 'createdAt',
        },
        value: item.createdAt,
      };
      item.fields = [...newFields, newField];
      return item;
    });
    return newData;
  };

  const promise = (files: File[], selectedUsers: User[], taskId: string) => {
    let percentage = 100 / files.length;
    return Promise.allSettled(
      // allSettled returns the file status, e.g 'fullfilled' - 'rejected'
      files.map((file, i) => {
        setProgress(percentage * (i + 1));
        return createRecord?.(
          {
            data: {
              folderId: selectedFolder?.id,
              storageType: 'S3',
              toBeIndexedBy: selectedUsers.map((selUser) => selUser.id),
            },
            file: file,
            attachments: {
              documentAttachments: [{ format: 'pdf' }],
            },
          },
          files.length,
          taskId
        );
      })
    );
  };

  let errorFilesArr: File[] = []; // files that have exhausted retries
  let retriesFilesArr: File[] = []; // files to be retry uploaded again
  let filesArr: Row[] = []; // successfully uploaded files
  function onBeforeUnload(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.returnValue = '';
    return;
  }
  const processFiles = async (files: File[], selectedUsers: User[]) => {
    window.addEventListener('beforeunload', onBeforeUnload);
    let taskId = utils.generateTaskID(userData);
    let taskUpload = {
      id: taskId,
      folderName: selectedFolder.name,
      action: 'upload_files',
      ammount: files?.length,
      screen: 'indexing',
    };
    addTask(taskUpload);
    addTaskRTC(taskUpload);
    if (selectedFolder.ocrEnabled) {
      let taskOCR = {
        id: taskId + '_PROC',
        folderName: selectedFolder.name,
        action: 'textract_record',
        ammount: files?.length,
        screen: 'indexing',
      };
      addTask(taskOCR);
      addTaskRTC(taskOCR);
    }
    await promise(files, selectedUsers, taskId)
      .then((data) => {
        data.forEach((d, index) => {
          if (d.status === 'fulfilled') {
            filesArr.push(d.value);
          } else {
            setMessage({
              message: `Retrying to load files`,
              severity: 'warning',
            });
            if (d.reason.file.hasOwnProperty('retries')) {
              // if the file already has retries, check if it is the third or more retries left.
              if (d.reason.file.retries === 0) {
                setMessage(null);
                retriesFilesArr = [];
                setRetriesFiles([]);
                errorFilesArr.push(d.reason.file); // if it exhausts the retries, it adds them to errorFilesArr
              } else {
                d.reason.file.retries = d.reason.file.retries - 1;
                retriesFilesArr.push(d.reason.file);
              }
            } else {
              d.reason.file.retries = 2; // if the file fails for the first time, it will retry to load again 3 times
              retriesFilesArr.push(d.reason.file);
            }
          }
        });
        let newData = createFields(filesArr);
        //setRows([...rows, ...newData]);
        // setDropAreaKey(dropAreaKey + 1);
        // if (isUploadFromDrag) setAnchorUploadEl(null);
        setSnackOpen(true);
        setRetriesFiles(retriesFilesArr);
        setErrorFiles((prevState) => [...prevState, ...errorFilesArr]);
      })
      .finally(() => {
        window.removeEventListener('beforeunload', onBeforeUnload);
        filesArr = [];
      });
  };
  const handleFilesChange = (files: File[]) => {
    setProgress(0);
    setFiles([...files]);
  };
  if (selectedFolder) {
    return (
      <ErrorHandler>
        <Box sx={{ marginRight: 2 }}>
          {rows.length &&
          hasExportPermissions(authState.permissions, userData, selectedFolder, false) &&
          screen !== 'indexing' ? (
            <PDFReportTool
              rows={rows}
              cols={cols}
              selectedFolder={selectedFolder}
              screen={screen}
              exportQuery={exportQuery}
              selectedTemplate={selectedTemplate}
              buttonReportRef={buttonReportRef}
            ></PDFReportTool>
          ) : null}
          {screen === 'indexing' ? (
            <>
              {hasUploadPermissions(
                authState.permissions,
                userData,
                selectedFolder,
                false
              ) ? (
                <Tooltip title="Upload Records" arrow>
                  <StyledButtons
                    onClick={handleUploadClick}
                    onDragEnter={handleUploadClick}
                  >
                    <FontAwesomeIcon
                      size="16px"
                      icon="fas fa-cloud-upload-alt"
                      color="#fff"
                    ></FontAwesomeIcon>
                  </StyledButtons>
                </Tooltip>
              ) : null}
              {hasDocumentScannerPermissions(
                authState.permissions,
                userData,
                selectedFolder,
                false
              ) ? (
                <>
                  <Tooltip title="Open Document Scanner " arrow>
                    <StyledButtons disabled={false} onClick={handleClickOpenScan}>
                      <SvgIcon
                        sx={{ height: '16px', width: '16px' }}
                        color="white"
                        viewBox="0 0 31 31"
                      >
                        <path d="M31,19.9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1l-27-16  C3,2.9,2.4,3,2.1,3.5C1.9,4,2,4.6,2.5,4.9L26.4,19H3c-0.6,0-1,0.4-1,1v6c0,1.7,1.3,3,3,3h23c1.7,0,3-1.3,3-3v-6  C31,20,31,19.9,31,19.9z M10,25H7c-0.6,0-1-0.4-1-1s0.4-1,1-1h3c0.6,0,1,0.4,1,1S10.6,25,10,25z" />
                      </SvgIcon>
                    </StyledButtons>
                  </Tooltip>
                  {theUser ? printUploadPopup() : null}
                </>
              ) : null}
            </>
          ) : null}
        </Box>
      </ErrorHandler>
    );
  } else {
    return <Box>...</Box>;
  }
}
