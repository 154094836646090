import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { PDFDocument } from 'pdf-lib';
import printJS from 'print-js';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: '#fff',
  },
}));

const print = {
  LoadedPDFDoc: {},
  newPDFDoc: {},
  do: async (urls, closeLoader) => {
    if (urls.length > 0) {
      await print.initPDF();
      for (let i = 0; i < urls.length; i++) {
        const url = urls[i].getUrl;
        window.log(url);
        await print.createEmbededPdfs(url);
      }
      const pdfBytes = await print.newPDFDoc.save();
      const pdfBlob = new Blob([pdfBytes.buffer], { type: 'application/pdf' });
      //download(pdfBlob, "test.pdf", "application/pdf")
      const pdfURL = URL.createObjectURL(pdfBlob);
      printJS({
        printable: pdfURL,
        type: 'pdf',
        onError: (err) => window.log(err),
        onLoadingStart: () => {
          document.querySelector('#customized-menu').children[0].click();
        },
        onLoadingEnd: () => {
          closeLoader(false);
        },
      });
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(pdfURL);
      }, 100);
    }
  },
  initPDF: async () => {
    print.newPDFDoc = await PDFDocument.create();
  },
  createEmbededPdfs: async (url) => {
    const pdfArrayBuffer = await fetch(url).then((res) => res.arrayBuffer());
    window.log(pdfArrayBuffer);
    print.LoadedPDFDoc = await PDFDocument.load(pdfArrayBuffer);
    const sourcePdfPage = print.LoadedPDFDoc.getPages();
    const pages = [];
    for (let i = 0; i < sourcePdfPage.length; i++) {
      pages.push(i);
    }
    let copiedPages = await print.newPDFDoc.copyPages(
      print.LoadedPDFDoc,
      pages
    );
    copiedPages.forEach((page) => {
      print.newPDFDoc.addPage(page);
    });
  },
  recordsToPrint: [],
  Element: ({ isOpen }) => {
    const classes = useStyles();
    return (
      <Backdrop className={classes.backdrop} open={isOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  },
};

export default print;
