import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect } from 'react';
import InputSelect from '../../../components/inputs/InputSelect';
import { Filter } from '../ReportingSideBar';
import { useFormContext } from 'react-hook-form';

interface LinkedDataStatusFilterProps {
  filter: Filter;
}

const FIELD_ID = 'linked-data-status';

const OPTIONS = [
  { label: 'All', value: 'AllLinkedInformation' },
  { label: 'Synced', value: 'LinkedInformation' },
  { label: 'Not synced', value: 'LinkedInfoException' },
];

const LinkedDataStatusFilter = ({ filter }: LinkedDataStatusFilterProps) => {
  const [value, setValue] = useState<string>(filter.value);
  const { setValue: setFormValue, watch } = useFormContext();

  const handleChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    setValue(filter.value);
  }, [filter]);

  useEffect(() => {
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex((filter) => filter.id === 'linked-data-status');
    index !== -1 &&
      setFormValue(`selectedTemplate.variables.filters[${index}].value`, value);
  }, [value]);

  return (
    <InputSelect
      options={OPTIONS}
      inputName={FIELD_ID}
      helperText="Select an option"
      required={true}
      size="small"
      backgroundColor="transparent"
      handleChange={handleChange}
      value={value}
      textfieldProps={{
        sx: {
          '& .MuiSelect-select': {
            color: 'common.greyblueDarker',
            fontSize: 14,
            fontWeight: 500,
          },
          '& .MuiInputBase-input': {
            textAlign: 'end',
            paddingBottom: 1.5,
            paddingTop: '16px',
          },
        },
      }}
    />
  );
};

export default LinkedDataStatusFilter;
