import React from 'react';
import { Frecuency } from '../../../DashboardMain';

interface Props {
  x?: number;
  y?: number;
  payload?: any;
  tickFormatter?: (a: any) => void;
  date: any[] | undefined;
  frecuency: string;
}

const CustomTick = ({ x, y, payload, tickFormatter, date, frecuency }: Props) => {
  let month;
  let _date = new Date(payload.value).toString();

  // when the format is by week or by month, it does not execute the function
  if (frecuency === 'week' && date) {
    _date = payload.value;

    month = date[payload.index].startEndDay
      ? new Date(date[payload.index].startEndDay[0]).toLocaleString('en-EN', {
          month: 'short',
        })
      : '';
  } else if (frecuency === 'month') {
    _date = payload.value;
  } else {
    if (tickFormatter) {
      _date = tickFormatter(payload.value) as any; // We, Mo, Su, etc
    }
  }
  return (
    <g transform={`translate(${x},${y - 2})`}>
      <text
        variant="text"
        x={frecuency === 'week' ? -20 : -10}
        y={8}
        dy={0}
        textAnchor="center"
        fill="#436072"
        style={{ fontFamily: 'Raleway', fontSize: 12 }}
      >
        {_date}
      </text>
      {month && (
        <text
          variant="text"
          x={-10}
          y={0}
          dy={23}
          textAnchor="center"
          fill="#436072"
          style={{ fontFamily: 'Raleway', fontSize: 12 }}
        >
          {month}
        </text>
      )}
    </g>
  );
};

export default CustomTick;
