import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  MyTextInput,
  MySelect,
  MyCheckbox,
} from '../../components/formComponents';
import { GET_FOLDERS_AND_USERS } from '../../utils/gql';
import gqlRecords from '../../utils/gql/gqlRecords';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import ActionBar from '../ActionBar';
import Button from '../../components/button';
import utils from '../../utils/utils';
import { StyledFormWrapper } from '../../styles/formWrapper';
import { useLocalStorage } from '../../hooks/useLocalStorage';
const RecordCreationContainer = () => {
  let fields;
  const initialValues = {
    folderId: '',
    assignTo: null,
    folderTags: [],
  };
  const validationSchema = {
    name: Yup.string().required('Required'),
    folderId: Yup.string(),
    assignTo: Yup.string(),
    folderTags: Yup.array(),
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  function selectionHandler(e) {
    history.push(`./newrecord?folder=${e.target.value}`);
  }
  let query = useQuery();
  let history = useHistory();
  const [selectedFolder] = useLocalStorage('lastRecordsFolder');
  const [createRecordMutation] = useMutation(gqlRecords.CREATE_RECORD);
  const createRecord = async (values) => {
    createRecordMutation({
      variables: {
        name: values.name,
        folderId: values.folderId,
        storageType: 'S3',
        assignTo: values.assignTo,
        folderTags: values.folderTags,
      },
    })
      .then(({ data }) => {
        history.push(`/dash/records/${data.id}`);
      })
      .catch((e) => {
        window.log(e);
      });
  };

  const [getFolders, { data }] = useLazyQuery(GET_FOLDERS_AND_USERS, {
    onCompleted: () => {
      if (data) {
        window.log(data);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      utils.clearUserData();
    },
  });
  useEffect(() => {
    getFolders();
  }, [getFolders]);
  let folderOptions = 'loading';
  let userOptions = 'loading';
  if (data) {
    if (selectedFolder || query.get('folder')) {
      const theFolder = data.FoldersAndUsers.folders.filter((folder) => {
        if (folder.id === selectedFolder || folder.id === query.get('folder')) {
          return folder;
        } else {
          return false;
        }
      });
      fields = theFolder[0].folderFields.map((field, i) => {
        let fieldtag = {};
        fieldtag = {
          id: field.id,
          key: field.name,
          value: '',
        };
        initialValues.folderTags.push(fieldtag);
        switch (field.type) {
          case 'boolean':
            validationSchema.folderTags = Yup.boolean();
            fieldtag.dataType = field.type;
            return (
              <MyCheckbox
                key={i}
                label={field.name}
                name={`folderTags.${i}`}
                isdynamic={fieldtag}
              ></MyCheckbox>
            );
          case 'currency':
            validationSchema[field.name] = Yup.string();
            fieldtag.dataType = field.type;
            return (
              <MyTextInput
                key={i}
                label={field.name}
                name={`folderTags.${i}`}
                orientation="Horizontal"
                isdynamic={fieldtag}
              ></MyTextInput>
            );
          case 'datetime':
            validationSchema[field.name] = Yup.date();
            fieldtag.dataType = field.type;
            return (
              <MyTextInput
                key={i}
                label={field.name}
                name={`folderTags.${i}`}
                orientation="Horizontal"
                type="datetime-local"
                isdynamic={fieldtag}
              ></MyTextInput>
            );
          case 'integer':
            validationSchema[field.name] = Yup.number();
            fieldtag.dataType = field.type;
            return (
              <MyTextInput
                key={i}
                label={field.name}
                name={`folderTags.${i}`}
                orientation="Horizontal"
                type="number"
                isdynamic={fieldtag}
              ></MyTextInput>
            );
          case 'string':
            validationSchema[field.name] = Yup.string();
            fieldtag.dataType = field.type;
            return (
              <MyTextInput
                key={i}
                label={field.name}
                name={`folderTags.${i}`}
                orientation="Horizontal"
                isdynamic={fieldtag}
              ></MyTextInput>
            );
          default:
            return null;
        }
      });
    }
    folderOptions = data.FoldersAndUsers.folders.map((folder) => {
      return (
        <option key={folder.id} value={folder.id}>
          {folder.name}
        </option>
      );
    });
    userOptions = data.FoldersAndUsers.users.map((user) => {
      return (
        <option
          key={user.id}
          value={user.id}
        >{`${user.firstName} ${user.lastName}`}</option>
      );
    });
  }
  window.log('initialvalues', initialValues);
  return (
    <div className="twoCol">
      <div className="title">
        <h1>Records {'>'} Create new record</h1>
      </div>

      <Formik
        initialValues={initialValues}
        //validationSchema={Yup.object(validationSchema)}
        onSubmit={(values) => {
          window.log(values);
          values.folderTags = JSON.stringify(values.folderTags);
          values.folderId = query.get('folder');
          createRecord(values);
        }}
      >
        {({ isSubmitting, submitForm, resetForm }) => (
          <>
            <StyledFormWrapper className="leftCol">
              <Form className="column">
                {/* <MyTextInput
                                label="Record Name"
                                name="name"
                                type="text"
                                placeholder=""
                            /> */}
                <MySelect
                  changefolder={{ selectionHandler: selectionHandler }}
                  label="Insert in folder"
                  name="folderId"
                  selectedfolder={query.get('folder') ? query.get('folder') : 0}
                >
                  <option value="">Select a folder</option>
                  {folderOptions}
                </MySelect>
                <MySelect label="Assign to user" name="assignTo" value="">
                  <option value="">Select an user</option>
                  {userOptions}
                </MySelect>
              </Form>
            </StyledFormWrapper>
            <StyledFormWrapper className="rightMenu">
              <Form className="column">
                <FieldArray name="folderTags">
                  <>{fields}</>
                </FieldArray>
              </Form>
            </StyledFormWrapper>
            <ActionBar>
              <Button func={resetForm} type="negative">
                Cancel
              </Button>
              <Button func={submitForm} type="positive">
                Create
              </Button>
            </ActionBar>
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordCreationContainer;
