import { Box, Switch, Typography } from '@mui/material';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { Dispatch, useContext } from 'react';
import { StatusContextTypes } from '../../../../context/statusContextTypes';
import { Context as StatusContext } from '../../../../context/statusContext';
import RTC from '../../../../utils/socketComm';
import { User } from '../../../comm/ListItemOrg';
import { capitalize } from '../../../../utils/text';

const styles = {
  accordion: {},
  summary: {
    minHeight: '42px',
    color: 'common.white',
    backgroundColor: 'common.greyblueDarkerTransp',
    '&.Mui-expanded': {
      borderInlineEnd: '3px solid #2ea760',
      minHeight: '42px',
      height: '42px',
    },
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginTop: '5px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
      marginTop: '0px',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: 2,
    },
  },
  details: {},
  title: {
    backgroundColor: 'common.greyblueDark',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: { sm: '0px 1px 0px 0px #ffffff96 inset', md: 'none' },
    padding: '5px 15px',
    height: '40px',
    marginBottom: '10px',
  },
};

const UserPreferences = ({
  user,
  setUserData,
}: {
  user: User | null;
  setUserData: React.Dispatch<React.SetStateAction<User>>;
}) => {
  const { state: statusState, setPaletteMode } = useContext(
    StatusContext
  ) as StatusContextTypes;
  console.log('user preference', user);
  const togglePaletteMode = (mode: string) => {
    localStorage.setItem('theme', JSON.stringify(mode));
    setPaletteMode(mode);
  };

  return (
    <Box>
      <Box sx={{ ...styles.title }}>
        <Typography variant="caption" sx={{ fontSize: 15, color: 'common.white' }}>
          Appearance
        </Typography>
      </Box>

      <Box
        sx={{
          marginLeft: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          justifyContent: 'space-around',
        }}
      >
        <Typography variant="button">Theme</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontSize: 13, p: '0 10px' }}>
            Light Mode
          </Typography>
          <Box>
            <Switch
              checked={statusState.paletteMode === 'dark'}
              name="theme-mode"
              onClick={() => {
                let mode = statusState.paletteMode === 'light' ? 'dark' : 'light';
                togglePaletteMode(mode)
                RTC.updateUserPreferences({
                  theme: capitalize(mode),
                });
                setUserData((prev) => ({
                  ...prev,
                  userPreferences: {
                    ...prev.userPreferences,
                    theme: mode,
                  },
                }))
              }}
              icon={
                <Box sx={{ marginRight: '3px' }}>
                  <FontAwesomeIcon icon={'far fa-sun'} size={22} color="common.orange" />
                </Box>
              }
              checkedIcon={
                <Box
                  sx={{
                    marginLeft: '5px',
                  }}
                >
                  <FontAwesomeIcon icon={'fas fa-moon'} size={20} color="common.white" />
                </Box>
              }
              sx={{
                '& .MuiSwitch-track': {
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.greyblueDarkerTransp + '!important'
                      : theme.palette.common.greyblueDarker,
                },
              }}
            />
          </Box>
          <Typography variant="h6" sx={{ fontSize: 13, p: '0 10px' }}>
            Dark mode
          </Typography>
        </Box>
      </Box>

      <Box sx={{ ...styles.title }}>
        <Typography variant="caption" sx={{ fontSize: 15, color: 'common.white' }}>
          Notifications
        </Typography>
      </Box>
      <Box
        sx={{
          marginLeft: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          justifyContent: 'space-around',
        }}
      >
        <Typography variant="button">Workflow notifications email</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Switch
              defaultChecked={user?.userPreferences ? user?.userPreferences.emailNotifications : true}
              onClick={(e) => {
                RTC.updateUserPreferences({
                  emailNotifications: e.target.checked,
                });
                setUserData((prev) => ({
                  ...prev,
                  userPreferences: {
                    ...prev.userPreferences,
                    emailNotifications: e.target.checked,
                  },
                }));
              }}
              sx={{
                '& .MuiSwitch-track': {
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.greyblueDarkerTransp + '!important'
                      : theme.palette.common.greyblueDarker,
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserPreferences;
