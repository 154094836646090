import { useEffect, useState } from 'react';
import { getWeekOfMonth } from 'date-fns';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// mui
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// components
import CustomLegend from './components/CustomLegend';
import TimeStampButtons from './components/TimeStampButtons';
import FolderSwitcher from './components/FolderSwitcher';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

// Fake Response Data
import { response } from './ResponseDataExample';

// Utils
import { getRandomHueColors } from '../../../../utils/color';
import ChartHeader from '../ChartHeader';
import { reverse } from 'lodash';
import { Folder } from '../../../folder/types';

// styles
const classes = {
  gridContainer: {
    height: '100%',
    width: '100%',
  },
  paper: {
    height: '100%',
    position: 'relative',
  },
  areaChart: {
    fontFamily: 'Raleway',
    fontSize: 12,
  },
  headerLeft: {
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  axisText: {
    fontWeight: 400,
  },
};

const fixedColors = ['#B8F3FF', '#7FE5F8', '#7DF7EC', '#7AE6A0', '#E6EB67'];

interface Props {
  folders: Folder[];
}

export default function MultiLineChart({ folders }: Props) {
  const [disabled, setDisabled] = useState([]);
  const [values, setValues] = useState([]);
  const [colors, setColors] = useState({});
  const [timeStamp, setTimeStamp] = useState('month');
  const [totals, setTotals] = useState({});
  const [folderData, setFolderData] = useState(response);

  // Gets the total sum of each item inside the value object property of an array of objects
  const getTotals = (data: any[]) => {
    const updatedTotals: any = {};
    values.forEach((value) => {
      updatedTotals[value] = data.reduce((acc, obj) => {
        return acc + obj.value[value];
      }, 0);
    });
    setTotals(updatedTotals);
  };

  // Returns an object with the names of the values in data as property
  // and colors as the values
  // it uses the getRandomPastelColor function to generate the colors
  // checks if the color already exists to avoid duplicates
  const getColorObject = () => {
    let colorObject: any = {};

    for (const property in folderData.data[0].value) {
      const newColorObject = getRandomHueColors();
      colorObject[property] = newColorObject.darkenColor;
    }

    return colorObject;
  };

  // // This function will set the data from a request
  const getFolderData = () => {
    setFolderData(response);
  };

  // Generates an array with the property names of a object
  const getValuesNames = () => {
    const names = [];
    let i = 0;
    for (let name in folderData.data[0].value) {
      names.push(name);
      i++;
      if (i === 5) {
        break;
      }
    }
    return names;
  };

  // This useEffect runs at start to fetch data
  useEffect(() => {
    getFolderData();
  }, []);

  // Set the value name and color for the values when folderData changes
  useEffect(() => {
    const values = getValuesNames();
    const colors = getColorObject();
    setValues(values);
    setColors(colors);
  }, [folderData]);

  // Runs geTotals when the value state changes
  useEffect(() => {
    getTotals(folderData.data);
  }, [values]);

  // Format the xAxis of the chart
  const formatXAxis = (item: string) => {
    const date = new Date(item);
    const day = date.getDate();
    const weekNumber = getWeekOfMonth(date);
    const monthName = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    if (timeStamp === 'week') return `w. ${weekNumber} - ${monthName} ${day}`;
    if (timeStamp === 'month') return `${monthName} ${day}`;
    if (timeStamp === 'year') return `${year} ${monthName} ${day}`;
  };

  return (
    <>
      {folders && folderData && (
        <Stack sx={classes.gridContainer}>
          <ChartHeader title="Records Status" />
          <Paper sx={classes.paper}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding="0 2rem"
              paddingTop="8px"
            >
              <Stack direction="row" spacing={1}>
                {values.map((value) => {
                  return (
                    <Typography variant="subtitle1" sx={classes.headerLeft} key={value}>
                      {`${value} = ${totals[value]}`}
                    </Typography>
                  );
                })}
              </Stack>
              <TimeStampButtons setTimeStamp={setTimeStamp} />
            </Stack>
            <ResponsiveContainer width="100%" height="90%">
              <AreaChart
                data={folderData.data}
                margin={{
                  top: 10,
                  right: 40,
                  left: 0,
                  bottom: 0,
                }}
                style={classes.areaChart}
              >
                <defs>
                  {values.map((value, index) => {
                    return (
                      <linearGradient
                        id={`color${value}`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                        key={value}
                      >
                        <stop
                          offset="5%"
                          stopColor={fixedColors[index]}
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor={fixedColors[index]}
                          stopOpacity={0}
                        />
                      </linearGradient>
                    );
                  })}
                </defs>
                <CartesianGrid strokeDasharray="0" vertical={false} />
                <XAxis
                  dataKey="date"
                  type="category"
                  allowDuplicatedCategory={false}
                  tickFormatter={formatXAxis}
                  axisLine={false}
                  style={classes.axisText}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  type="number"
                  domain={['dataMin', 'dataMax']}
                  style={classes.axisText}
                />
                <Tooltip content={<CustomTooltip active={true} />} />
                <Legend
                  iconType="plainline"
                  content={
                    <CustomLegend
                      values={values}
                      colors={fixedColors}
                      setDisabled={setDisabled}
                      disabled={disabled}
                      folder={folderData.name}
                    />
                  }
                />
                {values.map((value, index) => {
                  return (
                    <Area
                      type="linear"
                      dataKey={`value.${value}`}
                      stroke={fixedColors[index]}
                      strokeWidth={4}
                      fillOpacity={disabled.includes(value) ? 0 : 1}
                      strokeOpacity={disabled.includes(value) ? 0 : 1}
                      fill={`url(#color${value})`}
                      key={value}
                    />
                  );
                })}
              </AreaChart>
            </ResponsiveContainer>
            <FolderSwitcher loadedFolders={folders} getFolderData={getFolderData} />
          </Paper>
        </Stack>
      )}
    </>
  );
}
