import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useFormContext } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { useEffect } from 'react';

interface Props {
  options: any[];
  inputName: string;
  label?: string;
  helperText: string;
  required?: boolean;
  size: 'small' | 'medium';
  backgroundColor?: string;
  handleChange?: (
    e: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: any;
  textfieldProps?: TextFieldProps;
}

export default function InputSelect({
  options,
  inputName,
  label,
  helperText,
  required,
  size,
  backgroundColor,
  handleChange,
  value,
  textfieldProps,
}: Props) {
  const {
    register,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    value && setValue(inputName, value);
  }, [value, inputName]);

  return (
    <Box
      component="div"
      sx={{
        '& .MuiTextField-root': {
          width: '100%',
          '& .MuiInputBase-root': {
            backgroundColor: backgroundColor ? backgroundColor : 'common.lightblue',
            color: 'common.greyblueDarker',
          },
          '& .MuiInputLabel-root': {
            color: 'common.greyblueDarker',
            '&.Mui-focused': {
              color: 'common.greyblueDarker',
            },
          },
          '& .MuiSvgIcon-root': {
            color: 'common.greyblueDarker',
          },
        },
      }}
    >
      <TextField
        id={`${inputName ?? 'filled'}-select`}
        value={value}
        select
        label={label}
        defaultValue={value}
        variant="filled"
        {...register(inputName, { required })}
        helperText={errors[inputName] && helperText}
        error={errors[inputName]}
        onChange={(e) => {
          handleChange?.(e);
          clearErrors(inputName);
        }}
        size={size}
        {...textfieldProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
