import { useLiveQuery } from 'dexie-react-hooks';
import { useContext, useEffect } from 'react';
import { Context as RTCContext } from '../context/RTCContext';
import { db } from '../utils/indexedDB/indexeddb';
import * as _ from 'lodash';
import useMaintenance from './useMaintenance';

interface Maintenance {
  startDate: string;
  endDate: string;
  message: string;
  restart: boolean;
}

interface Items {
  taskNotifications: any[];
  maintenance: Maintenance[];
}

const useInterval = () => {
  // hook starting an interval with functions that check data every 2.5 seconds
  const rtcContext = useContext(RTCContext);
  const { maintenance, data, scheduledMaintenance, setMaintenance } = useMaintenance();
  const items: Items | undefined = useLiveQuery(async () => {
    return {
      taskNotifications: await db.table('taskNotifications').toArray(),
      maintenance: await db.table('maintenance').toArray(),
    };
  }, []);

  const checkForNotificationsThatMusBeSend = (items: Items) => {
    let sendLater = items.taskNotifications.find((noti) => {
      if (noti.sendLater === null) return false;
      let sendLater = noti.sendLater && new Date(noti.sendLater).getTime();
      return sendLater < Date.now() && noti;
    });
    if (sendLater) rtcContext.addTaskNotificationAsync([sendLater]);
  };

  const checkForNotificationsThatMustBeDeleted = (items: Items) => {
    let notification = items.taskNotifications.find((noti) => {
      if (_.isString(noti.sendLater)) return false; // means that the notification has not yet been created
      let expiration = new Date(noti.expirationTime).getTime();
      return expiration < Date.now() && noti;
    });
    if (notification) rtcContext.removeTaskNotification(notification.id);
  };

  const checkWhenMaintenanceModeIsFinished = (data: Maintenance) => {
    let endDate = new Date(data.endDate);
    if (endDate.getTime() <= Date.now()) {
      if (data.restart) window.location.reload();
      setMaintenance(false);
      rtcContext.maintenanceMode(false);
    }
  };

  const checkWhenMaintenanceModeStart = (data: Maintenance) => {
    let startDate = new Date(data.startDate);
    if (startDate.getTime() <= Date.now()) {
      setMaintenance(true);
      rtcContext.maintenanceMode(data);
    }
  };

  useEffect(() => {
    if (!items) return;
    if (items.taskNotifications.length === 0 && items.maintenance.length === 0) return; // add the necessary ifs to prevent that the interval start
    let interval = setInterval(() => {
      if (items.taskNotifications && items.taskNotifications.length !== 0) {
        checkForNotificationsThatMustBeDeleted(items);
        checkForNotificationsThatMusBeSend(items);
      }
      if (maintenance && data) checkWhenMaintenanceModeIsFinished(data);
      if (scheduledMaintenance && data) checkWhenMaintenanceModeStart(data);
    }, 2500);
    return () => clearInterval(interval);
  }, [items]);
};

export default useInterval;
