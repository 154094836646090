import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  FormControl,
} from '@mui/material';
import { Context as StatusContext } from '../context/statusContext';
import { useMutation } from '@apollo/react-hooks';
import { PUSH_ERRORLOGS } from './gql';
import FontAwesomeIcon from '../components/FAIcon';

const classes = {
  text: {
    marginBottom: '20px',
  },
  textField: {
    marginBottom: '20px',
  },
};

const ErrorMessage = () => {
  const { setErrorStatus, state } = useContext(StatusContext);
  const [message, setMessage] = useState(null);

  const [pushErrorLogMutation] = useMutation(PUSH_ERRORLOGS);

  const pushErrorLog = async () => {
    pushErrorLogMutation({
      variables: {
        message: message,
        errorInfo: state.error.errorInfo.componentStack,
      },
    }).then(({ data }) => {
      window.log(data);
      handleClose();
    });
  };
  const handleClose = () => {
    setErrorStatus({ triggered: false, errorInfo: '' });
  };
  const handleChange = (event: Object) => {
    setMessage(event.target.value);
  };
  return (
    <>
      <Dialog
        open={state.error.triggered}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <FontAwesomeIcon
            icon="fas fa-exclamation-triangle"
            color="common.negative"
            size="20px"
          />
          The application encounter an unexpected error
        </DialogTitle>
        <DialogContent>
          <Typography sx={classes.text}>
            If this is the first time you see this message please report the error by
            clicking on the "SEND REPORT" button and our developers will work on it as
            soon as possible.
          </Typography>
          <Typography>If possible please provide some information:</Typography>
          <FormControl fullWidth sx={classes.textField}>
            <TextField
              id="errorFeedback"
              label="Feedback"
              multiline
              rows={4}
              value={message}
              onChange={handleChange}
              variant="filled"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={pushErrorLog} color="primary" variant="contained">
            Send Report
          </Button>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ErrorMessage;
