import { Typography, Button, Box } from '@mui/material';
import React from 'react';
import FontAwesomeIcon from '../../../../../components/FAIcon';
import { weekConvertData } from '../../../../../utils/dashboardUtils';
import { Data } from '../../../DashboardMain';

const classes = {
  stepper: {
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    position: 'absolute',
    right: '10px',
    top: '12px',
  },
  activeStatistic: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'capitalize',
    minWidth: '110px',
    color: 'common.greyblueDark',
  },
  buttonStatistic: {
    minWidth: 10,
    padding: 0.2,
    '&:disabled': {
      opacity: 0.2,
    },
  },
};

interface Props {
  data: Data | weekConvertData;
  keysData: string[];
  setData: React.Dispatch<React.SetStateAction<Data | weekConvertData | null>>;
  activeStatistic: number;
  setActiveStatistic: React.Dispatch<React.SetStateAction<number>>;
}

const StatisticSwitcher = ({ keysData, setActiveStatistic, activeStatistic }: Props) => {
  const handleNext = () => {
    setActiveStatistic((prevActiveStatistic) => prevActiveStatistic + 1);
  };

  const handleBack = () => {
    setActiveStatistic((prevActiveStatistic) => prevActiveStatistic - 1);
  };

  return (
    <Box sx={classes.stepper}>
      <Button
        size="small"
        onClick={handleBack}
        disabled={activeStatistic === 0}
        sx={classes.buttonStatistic}
      >
        <FontAwesomeIcon icon="fa-solid fa-caret-left" size={17} color="#79909f" />
      </Button>
      <Typography
        sx={classes.activeStatistic}
      >{`${keysData[activeStatistic]} Records`}</Typography>
      <Button
        size="small"
        onClick={handleNext}
        disabled={activeStatistic === keysData.length - 1}
        sx={classes.buttonStatistic}
      >
        <FontAwesomeIcon icon="fa-solid fa-caret-right" size={17} color="#79909f" />
      </Button>
    </Box>
  );
};

export default StatisticSwitcher;
