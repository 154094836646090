import React, { useState } from 'react';

// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

// App components
import FontAwesomeIcon from '../../../../components/FAIcon';
import { splitByUpperCase, capitalize } from '../../../../utils/text';
import { FieldListProps, FolderFields } from '../../types';

// Styled Components
const StyledList = styled(List)(({ theme }) => ({
  padding: 0,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.common.greyblueDark,
  width: '273px',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  position: 'relative',

  '.Mui-selected': {
    backgroundColor: 'red',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.common.greyblueDarker,
  color: theme.palette.common.white,
  width: '45px',
  height: '52px',
  borderRadius: theme.shape.borderRadius,
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '12px',
    color: theme.palette.common.white,
    lineHeight: '14px',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 500,
  },
  '& .MuiListItemText-secondary': {
    fontWeight: 300,
  },
}));

export default function FieldList({
  fields,
  setSelectedFieldId,
  setSelectedField,
  setShowEdit,
  selectedFieldId,
  updateField,
}: FieldListProps) {
  const [disabled, setDisabled] = useState<string[]>(
    fields ? fields.map((ff) => (ff.active ? '' : ff.id)) : []
  );
  const handleClick = (field: FolderFields) => {
    setSelectedFieldId(field.id);
    setSelectedField(field);
    setShowEdit(true);
  };
  const disableField = (id: string, value: boolean) => {
    if (disabled.includes(id)) {
      let fields = disabled.filter((item) => item !== id);
      setDisabled(fields);
    } else {
      setDisabled((prev) => [...prev, id]);
    }

    updateField({ folderFieldId: id, active: value });
  };

  // Set a custom name for the datatype
  const setDataTypeName = (dataType: string): string => {
    switch (dataType) {
      case 'boolean':
        return 'Boolean';
      case 'currency':
        return 'Currency';
      case 'datetime':
        return 'DateTime';
      case 'integer':
        return 'Integer';
      case 'string':
        return 'Text';
      case 'dropdown':
        return 'Drop Down List';
      case 'multistring':
        return 'Multi string';
      default:
        return 'Text';
    }
  };

  const setIcon = (dataType: string): string => {
    switch (dataType) {
      case 'boolean':
        return 'fas fa-bold';
      case 'currency':
        return 'fas fa-coins';
      case 'datetime':
        return 'fas fa-calendar-alt';
      case 'integer':
        return 'fas fa-calculator';
      case 'string':
        return 'fas fa-align-left';
      case 'dropdown':
        return 'fas fa-list-alt';
      default:
        return 'fas fa-bold';
    }
  };
  return (
    <>
      <StyledList>
        <Stack spacing={1}>
          {fields &&
            fields.map((field) => {
              if (field.id === 'CreatedAtID') return '';
              return (
                <Stack direction="row" key={field.id}>
                  <FontAwesomeIcon
                    icon="fas fa-caret-right"
                    color={selectedFieldId === field.id ? '#FFD53F' : 'transparent'}
                    size="50px"
                  />
                  <StyledListItem
                    secondaryAction={
                      disabled.includes(field.id) ? (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => disableField(field.id, true)}
                          sx={{ paddingRight: '13px' }}
                        >
                          <FontAwesomeIcon
                            icon="fas fa-power-off"
                            color="common.lightblue"
                            size="24px"
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => disableField(field.id, false)}
                          sx={{ paddingRight: '13px' }}
                        >
                          <FontAwesomeIcon
                            icon="fas fa-ban"
                            color="rgba(255,255,255,0.3)"
                            size="24px"
                          />
                        </IconButton>
                      )
                    }
                    disablePadding
                  >
                    <StyledListItemButton
                      disableGutters
                      onClick={() => handleClick(field)}
                      disabled={disabled.includes(field.id) ? true : false}
                      selected={selectedFieldId === field.id ? true : false}
                      name={`${field.name} ${field.type}`}
                    >
                      <ListItemAvatar>
                        <StyledAvatar variant="square">
                          <FontAwesomeIcon
                            icon={setIcon(field.type)}
                            color="inherit"
                            size="20px"
                          />
                        </StyledAvatar>
                      </ListItemAvatar>
                      <StyledListItemText
                        primary={capitalize(splitByUpperCase(field.name))}
                        secondary={setDataTypeName(field.type)}
                        primaryTypographyProps={{ name: 'field-name' }}
                      />
                    </StyledListItemButton>
                    {field.mainField && (
                      <Box sx={{ position: 'absolute', right: 5, top: 0 }}>
                        <FontAwesomeIcon
                          icon="fas fa-star"
                          color="common.orange"
                          size="10px"
                        />
                      </Box>
                    )}
                  </StyledListItem>
                </Stack>
              );
            })}
        </Stack>
      </StyledList>
    </>
  );
}
