import { useCallback, useContext, useEffect, useState } from 'react';
import { Context as RTCContext } from '../../context/RTCContext';
import { RTCContextTypes } from '../../context/RTCContextTypes';
import {
  Box,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Button,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import ReactJson from 'react-json-view';
import { generateScrollbar } from '../theme';
import FontAwesomeIcon from '../FAIcon';
import { debounce } from 'lodash';

type EventType = 'all' | 'outgoing' | 'incoming';

type Event = {
  type: EventType;
  event: string;
  args: any[];
  time: string;
};

const DebugConsoleBody = () => {
  const { state, setSocketEvents } = useContext(RTCContext) as RTCContextTypes;
  const [events, setEvents] = useState<Event[] | []>([]);
  const [eventFilter, setEventFilter] = useState('');
  const [eventTypeFilter, setEventTypeFilter] = useState('all');
  const scrollbar = generateScrollbar('5px', '5px');

  const filterSocketEvents = (
    events: Event[],
    type: string,
    eventName: Event['event']
  ) => {
    return events.filter((event) => {
      const typeMatch = type === 'all' || event.type === type;
      const eventNameMatch = eventName === '' || event.event.includes(eventName);

      return typeMatch && eventNameMatch;
    });
  };

  const handleClear = () => {
    setSocketEvents('clearConsole');
  };

  const debouncedChange = useCallback(
    debounce((value) => {
      setEventFilter(value);
    }, 300),
    []
  );

  useEffect(() => {
    if (eventFilter || eventTypeFilter !== 'all') {
      const filteredEvents = filterSocketEvents(
        state.socketEvents,
        eventTypeFilter,
        eventFilter
      );
      setEvents(filteredEvents);
    } else {
      setEvents(state.socketEvents);
    }
  }, [state.socketEvents, eventFilter, eventTypeFilter]);

  return (
    <Stack
      flex={3}
      px={4}
      py={4}
      maxHeight="calc(100% - 300px)"
      overflow="auto"
      gap={2}
      sx={{ ...scrollbar }}
    >
      <Stack direction="row" gap={1}>
        <TextField
          size="small"
          id="socket-event-type-select"
          select
          label="Event type"
          value={eventTypeFilter}
          SelectProps={{ MenuProps: { style: { zIndex: 9999 } } }}
          sx={{ width: '200px' }}
          onChange={(e) => setEventTypeFilter(e.target.value)}
        >
          <MenuItem key={'all'} value={'all'}>
            All
          </MenuItem>
          <MenuItem key={'outgoing'} value={'outgoing'}>
            Call
          </MenuItem>
          <MenuItem key={'incoming'} value={'incoming'}>
            Response
          </MenuItem>
        </TextField>
        <TextField
          id="event-filter"
          placeholder="Search by socket event"
          fullWidth
          size="small"
          onChange={(e) => debouncedChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon="fas fa-search" size="16px" color="grey" />
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title="Clear console" arrow>
          <Button
            aria-label="clear-console"
            size="small"
            variant="contained"
            color="error"
            onClick={handleClear}
            sx={{ width: '40px' }}
          >
            <FontAwesomeIcon icon="fas fa-trash" size="16px" />
          </Button>
        </Tooltip>
      </Stack>
      {events.length > 0 &&
        events.map((event, index) => (
          <Box key={index}>
            <Stack justifyContent="space-between" direction="row">
              <Stack direction="row" gap={3}>
                <Typography fontWeight="bold">
                  {event.type === 'outgoing' ? 'SOCKET CALL' : 'SOCKET RESPONSE'}
                </Typography>
                <Typography>{event.event}</Typography>
              </Stack>
              <Typography>{event.time}</Typography>
            </Stack>
            {event.args &&
              event.args.map((arg, argIndex) => {
                const src = typeof arg === 'object' ? arg : { arg };
                return (
                  <ReactJson
                    key={argIndex}
                    src={src}
                    collapsed={true}
                    name="BODY"
                    theme={'tomorrow'}
                  />
                );
              })}
          </Box>
        ))}
    </Stack>
  );
};

export default DebugConsoleBody;
