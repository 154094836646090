import { useContext, useState, useEffect } from 'react';

// Querys
import gqlRecords from '../../utils/gql/gqlRecords';
import { useMutation } from '@apollo/react-hooks';

// Components
import ErrorHandler from '../../utils/errorHandler';

// UI
import { Box, Snackbar, Typography, Alert, AlertColor } from '@mui/material';
import FontAwesomeIcon from '../../components/FAIcon';

import { Context as RTCContext } from '../../context/RTCContext';
import { Context as tasksContext } from '../../context/tasksContext';
import { Context as StatusContext } from '../../context/statusContext';
import { useLocalStorage, modifyLocalStorageObject } from '../../hooks/useLocalStorage';
import utils from '../../utils/utils';
import { checkCreatedAtField, getHeaders, handleCloseSnack } from './searchUtils';
import MasterTable from '../../components/masterTable/MasterTable';
import { GridSortModel } from '@mui/x-data-grid';
import { Header, RecordResultsProps } from './types';
import { TasksContextTypes } from '../../context/tasksContextTypes';
import _ from 'lodash';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { RTCContextTypes } from '../../context/RTCContextTypes';
import { REAPPLY_LINKED_INFORMATIONS } from '../../utils/gql/gqlLinkedInfo';

const RecordResults = ({
  folderDetail,
  records,
  recordCount,
  setQuickView,
  editMode,
  setEditMode,
  defaultTableOptions,
  loadRecords,
  setLimit,
  limit,
  setCurrentPage,
  currentPage,
  setSorting,
  sorting,
  loading,
  selectedFolder,
  isSuser,
  selOrg,
  onClickedRow,
  exportQuery,
  filters,
  searchScreenQuickView,
  notesRefetch,
  fieldValues,
  latestRecordsSearch,
  setActiveRecordId,
  setActiveAttachment,
  setIsDrawerOpen,
  loadFolders,
  workflowFilter,
}: RecordResultsProps) => {
  const [headers, setHeaders] = useState<Header[]>([]);
  const [snackOpen, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<{ message: string; severity: AlertColor }>({
    message: '',
    severity: 'warning',
  });
  const { state: StatusState } = useContext(StatusContext) as StatusContextTypes;
  const [screenSelectedData, setScreenSelectedData] =
    useLocalStorage('screenSelectedData');
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [userData] = useLocalStorage('userData');
  const [userSettings] = useLocalStorage('userSettings');
  const { addTask, removeTask } = useContext(tasksContext) as TasksContextTypes;
  const [deleteRecordsMutation] = useMutation(gqlRecords.DELETE_RECORD);

  const { state: RTCState } = useContext(RTCContext) as RTCContextTypes;

  const [unindexRecords] = useMutation(gqlRecords.UNINDEX_RECORDS);
  const [reApplyLinked_Informations] = useMutation(REAPPLY_LINKED_INFORMATIONS);

  const handleUnindexRecords = (selectionModel: string[]) => {
    if (!folderDetail || !selectedFolder) return;
    let taskId = utils.generateTaskID(userData);
    addTask({
      id: taskId,
      folderName: folderDetail.name,
      action: 'unindex_records',
      ammount: 1, // if there are several records, they are grouped into a single task
      screen: 'search',
    });

    let variables = {
      recordIds: selectionModel,
      folderId: selectedFolder.id,
      taskId,
    };

    unindexRecords({ variables })
      // .then((res) => window.log('UNINDEX RES', res))
      .catch((error) => {
        // window.log('UNINDEX ERROR', error);
        removeTask({ id: taskId });
      });
  };

  const deleteRecords = async (values: string[]) => {
    if (!folderDetail) return;
    let taskId = utils.generateTaskID(userData);
    addTask({
      id: taskId,
      folderName: folderDetail.name,
      action: 'delete_records',
      ammount: values.length,
      screen: 'search',
    });
    deleteRecordsMutation({
      variables: {
        recordIds: values,
        taskId,
      },
    })
      .then(({ data }) => {
        if (data.deleteRecordsAsync.records)
          setMessage({
            message: 'Record/s deleted Successfully',
            severity: 'success',
          });
        setSnack(true);
        loadRecords({
          variables: {
            limit: limit,
            folderId: folderDetail && folderDetail.id,
            filters: filters,
            org: isSuser
              ? StatusState.selectedOrg && _.isObject(StatusState.selectedOrg)
                ? StatusState.selectedOrg.id
                : selOrg.id
              : null,
            currentPage: currentPage,
            sortByField: sorting,
            processPath: workflowFilter,
          },
        });
        setScreenSelectedData(
          modifyLocalStorageObject(
            {
              ...screenSelectedData,
              [`${StatusState.screen}ScreenSelected`]: { selectedRows: [], selected: [] },
            },
            `${StatusState.screen}ScreenSelected`
          )
        );
        return data;
      })
      .catch((e) => {
        // window.log(e);
        removeTask({ id: taskId });
        setMessage({
          message: 'Permission denied, please contact the administrator',
          severity: 'error',
        });
        setSnack(true);
      });
  };

  const [updateRecordsAsync] = useMutation(gqlRecords.UPDATE_RECORD_ASYNC);
  const updateRecord = async (values: string[]) => {
    if (!folderDetail) return;
    let taskId = utils.generateTaskID(userData);
    addTask({
      id: taskId,
      folderName: folderDetail.name,
      action: 'update_records',
      ammount: values.length,
      screen: 'indexing',
    });
    utils.checkForMultistringFieldBeforeUploadRecord(values);
    updateRecordsAsync({
      variables: { recordsToUpdate: { updateRecordInputs: values }, taskId },
    })
      .then(({ data }) => {
        setMessage({
          message: 'Record Updated Successfully',
          severity: 'success',
        });
        setSnack(true);
      })
      .catch((e) => {
        // window.log(e);
      });
  };

  const handleEdit = async (values: string[]) => {
    await updateRecord(values);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (!newModel.length) {
      let parsedModel = [{ field: 'createdAt', sort: 'desc' }];
      setSortModel(newModel);
      setSorting(parsedModel[0].field + '|' + parsedModel[0].sort);
    } else {
      setSortModel(newModel);
      setSorting(newModel[0].field + '|' + newModel[0].sort);
    }
  };

  const handleReapplyLinkedInformations = async (selectionModel: string[]) => {
    reApplyLinked_Informations({ variables: { ids: selectionModel } }).then((data) => {
      setMessage({
        message: 'Re-sync successful',
        severity: 'success',
      });
      setSnack(true);
      loadRecords({ variables: { ...exportQuery } });
    });
  };

  useEffect(() => {
    getHeaders(folderDetail, setHeaders);
    checkCreatedAtField(records, folderDetail, setHeaders);
  }, [folderDetail, records]);

  useEffect(() => {
    if (selectedFolder && selectedFolder.id) {
      let variables = { ...exportQuery, limit, currentPage, sortByField: sorting };
      if (userSettings.workflowFilter) {
        variables.processPath = userSettings.workflowFilter;
      }
      loadRecords({
        variables,
      });
    }
  }, [limit, currentPage, sorting]);

  useEffect(() => {
    if (selectedFolder && selectedFolder.id && RTCState.refetch > 0) {
      let variables = { ...exportQuery };
      if (userSettings.workflowFilter) {
        variables.processPath = userSettings.workflowFilter;
      }
      loadRecords({
        variables,
      });
    }
  }, [RTCState.refetch]);

  if (headers.length) {
    return (
      <ErrorHandler>
        <MasterTable
          rows={records}
          defaultVisibleRows={headers}
          setPageSize={setLimit}
          pageSize={limit}
          setCurrentPage={setCurrentPage}
          rowCount={recordCount}
          loading={loading}
          onSortModelChange={handleSortModelChange}
          onRowEdit={handleEdit}
          onDeleteButton={deleteRecords}
          handleUnindexRecords={handleUnindexRecords}
          onClickedRow={onClickedRow}
          defaultTableOptions={defaultTableOptions}
          editMode={editMode}
          setEditMode={setEditMode}
          setQuickView={setQuickView}
          selectedFolder={folderDetail ? folderDetail : null}
          exportQuery={exportQuery}
          setScreenSelectedData={setScreenSelectedData}
          screenSelectedData={screenSelectedData}
          latestRecordsSearch={latestRecordsSearch}
          notesRefetch={notesRefetch}
          fieldValues={fieldValues}
          setActiveRecordId={setActiveRecordId}
          setActiveAttachment={setActiveAttachment}
          setIsDrawerOpen={setIsDrawerOpen}
          refetchFolder={loadFolders}
          handleReapplyLinkedInformations={handleReapplyLinkedInformations}
        ></MasterTable>
        {/* <MasterTable
            setSorting={setSorting}
            setLimit={setLimit}
            setCurrentPage={setCurrentPage}
            setRecords={setRecords}
            defaultVisibleRows={status.visibleHeaders}
            columnLabels={status.headers}
            rows={records}
            recordCount={recordCount}
            action={onClick}
            editAction={handleEditKeyPress}
            setEditable={handleSetEditable}
            handleDelete={handleDeleteRecords}
            fields={folderDetail ? folderDetail.folderFields : null}
            setQuickView={setQuickView}
            setEditMode={setEditMode}
            defaultOptions={defaultTableOptions}
            screen="recordsScreen"
            setSelected={setSelected}
            selectedRows={selectedRows}
            selected={selected}
            selectedFolder={folderDetail ? folderDetail : null}
            functions={{
              collectable: true,
              quickViewable: utils.determinePermissions(state.role).quickView,
              quickEditable: utils.determinePermissions(state.role).edit,
            }}
            mainField={mainField}
          /> */}
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={(e) => handleCloseSnack(e, setSnack)}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={(e) => handleCloseSnack(e, setSnack)}
            severity={message.severity}
          >
            {message.message}
          </Alert>
        </Snackbar>
      </ErrorHandler>
    );
  } else {
    return (
      <Box
        sx={{
          backgroundColor: 'common.greyblueDark',
          height: '100%',
          borderRadius: '5px',
          color: 'white',
        }}
      >
        <Box
          sx={{
            top: '40px',
            left: '10px',
            position: 'relative',
          }}
        >
          <FontAwesomeIcon size="36px" color="#fff" icon="fas fa-angle-double-left" />
        </Box>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {' '}
          <FontAwesomeIcon icon="fas fa-exclamation-circle" size="36px" color="#fff" />
          <Typography variant="tab">Please select a folder to start.</Typography>
        </Box>
      </Box>
    );
  }
};

export default RecordResults;
