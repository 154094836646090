import React, { useEffect, useState } from 'react';

import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { useQuery } from 'react-apollo';
import { Bar, BarChart, ResponsiveContainer, XAxis, Tooltip } from 'recharts';
import { getDataByFrecuency, weekConvertData } from '../../../../utils/dashboardUtils';
import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_BY_ORG,
} from '../../../../utils/gql/gqlDashboard';
import CustomTooltip from '../DataGrid/components/CustomTooltip';
import {
  folderDePrueba,
  folderDePrueba2,
  folderDePrueba3,
  folderDePrueba4,
} from '../../MockupData';
import CustomTick from './components/CustomTick';
import FolderSwitcher from './components/FolderSwitcher';
import StatisticSwitcher from './components/StatisticSwitcher';
import ChartHeader from '../ChartHeader';
import { Data, Frecuency } from '../../DashboardMain';
import { Folder } from '../../../folder/types';

const classes = {
  gridContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  },
  titulo: {
    fontSize: 13,
    fontWeight: 400,
  },
  statisticSwitcher: {
    color: 'black',
    fontSize: 14,
    margin: 0,
    fontWeight: 500,
  },
  position: {
    position: 'absolute',
    height: '25px',
    width: 'auto',
    margin: 0,
    marginTop: 0.1,
    left: -15,
    top: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  paper: {
    position: 'relative',
    margin: 0,
    padding: 0,
    height: '100%',
  },
};

const colors = ['#FFc92990', '#7FE5f890', '#7AE6A090']; // implement function that generates colors

interface Props {
  frecuency: Frecuency;
  folders: Folder[];
  isSuser: boolean;
  selectedOrg: string | { name: string; id: string };
}

const StatisticBarChart = ({
  frecuency = { type: 'day', points: 42 },
  folders,
  isSuser = false,
  selectedOrg,
}: Props) => {
  const [data, setData] = useState<Data | weekConvertData | null>(null); // data from the database
  const [dataChart, setDataChart] = useState<any[] | undefined>(undefined); // data worked to render in the graphic
  const [keysData, setKeysData] = useState<string[]>([]); // Types of data that can be displayed
  const [activeStatistic, setActiveStatistic] = useState(0);
  const [color, setColor] = useState(colors[0]);
  const {
    loading,
    error,
    data: dataFolder,
    refetch,
  } = useQuery(isSuser ? GET_DASHBOARD_DATA : GET_DASHBOARD_DATA_BY_ORG);

  const getFolderData = (id: string, type: string, frecuency: Frecuency) => {
    type Peticion = {
      folderId: string;
      content: { type: string; values: Frecuency }[];
      organisationId?: string | { id: string; name?: string };
    };

    const peticion: Peticion = {
      folderId: id,
      content: [
        { type: 'scanned', values: frecuency },
        { type: 'indexed', values: frecuency },
        { type: 'deleted', values: frecuency },
      ],
    };
    if (isSuser) peticion['organisationId'] = selectedOrg;
    // refetch({ variables: peticion });

    setData(folderDePrueba3); // setData(dataFolder)

    if (data !== null) {
      let dataByFrecuency = getDataByFrecuency(
        data,
        keysData[activeStatistic],
        frecuency.type
      );

      setDataChart(dataByFrecuency);
    }
  };

  // if (dataFolder) setData(dataFolder); // implement when the query works

  useEffect(() => {
    // initial request to bring data, remove when query works
    setData(folderDePrueba4);
  }, []);

  useEffect(() => {
    // when loading data, it reads the keys provided and formats according to the frecuency.
    if (!data) return;
    let keys = data ? Object.keys(data.data[0].value) : [];
    setKeysData(keys);

    let dataByFrecuency = getDataByFrecuency(folderDePrueba2, keys[0], frecuency.type);

    setDataChart(dataByFrecuency);
  }, [data]);

  useEffect(() => {
    // when the statistics change, it formats that data
    if (!data) return;
    let dataByFrecuency = getDataByFrecuency(
      data,
      keysData[activeStatistic],
      frecuency.type
    );
    setDataChart(dataByFrecuency);
    setColor(colors[activeStatistic]);
  }, [activeStatistic, data, keysData]);

  return (
    data && (
      <Stack sx={classes.gridContainer}>
        <ChartHeader title="Weekly Stats" />
        <Paper sx={classes.paper}>
          {data && (
            <StatisticSwitcher
              data={data}
              keysData={keysData}
              setData={setData}
              activeStatistic={activeStatistic}
              setActiveStatistic={setActiveStatistic}
            />
          )}
          <FolderSwitcher
            folder={data.name}
            folders={folders}
            getFolderData={getFolderData}
            type={keysData[activeStatistic]}
            frecuency={frecuency}
          />
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={dataChart}
              margin={{
                top: 40,
                bottom: 35,
                right: 20,
                left: 20,
              }}
            >
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                tickFormatter={(date) => new Date(date).toDateString().slice(0, 2)}
                tick={<CustomTick date={dataChart} frecuency={frecuency.type} />}
              />
              <Tooltip
                content={<CustomTooltip type={keysData[activeStatistic]} color={color} />}
                cursor={{ fill: '#eeeeee70' }}
              />
              <Bar dataKey={`value.${keysData[activeStatistic]}`} fill={color} />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </Stack>
    )
  );
};

export default StatisticBarChart;
