import { CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { GridOverlay } from '@mui/x-data-grid';

const LoadingOverlay = ({
  text = 'Loading records',
  backgroundColor = 'rgba(242, 242, 242, 0.38)',
}) => {
  return (
    <GridOverlay data-testid="datagrid-overlay" sx={{ backgroundColor }}>
      <Stack alignItems="center" spacing={2} sx={{ color: 'common.blue', zIndex: 99 }}>
        <CircularProgress color="inherit" />
        <Typography
          data-testid="datagrid-overlay-message"
          variant="subtitle3"
          color="inherit"
        >
          {text}
        </Typography>
      </Stack>
    </GridOverlay>
  );
};

export default LoadingOverlay;
