import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import FieldDropdownOptions from '../../../containers/folder/folderFields/components/FieldDropdownOptions';
import { UPDATE_FOLDER_FIELD } from '../../../utils/gql/gqlFolders';
import ModalContentBase from '../../modal/ModalContentBase';
import ModalWrapper from '../../modal/ModalWrapper';
import { FolderField, MasterTableProps } from '../masterTableTypes';
import { AlertColor } from '@mui/material';
import _ from 'lodash';

interface props {
  dropdownModalOpen: boolean;
  setDropdownModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedFolder: MasterTableProps['selectedFolder'];
  setMessage: React.Dispatch<
    React.SetStateAction<{
      message: string;
      severity: AlertColor | undefined;
    } | null>
  >;
  field?: string;
  setSnackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchFolder: MasterTableProps['refetchFolder'];
  newDropdownOption?: string;
  setNewDropdownOption: React.Dispatch<React.SetStateAction<string | undefined>>;
  context: string;
}

const AddDropDownOption = ({
  dropdownModalOpen,
  setDropdownModalOpen,
  selectedFolder,
  field,
  refetchFolder,
  setMessage,
  setSnackOpen,
  newDropdownOption,
  setNewDropdownOption,
  context,
}: props) => {
  const [updateFolderField] = useMutation(UPDATE_FOLDER_FIELD);
  const [fieldUpdates, setFieldUpdates] = useState<FolderField | undefined>();
  const [currentField, setCurrentField] = useState<FolderField | null>(null);

  useEffect(() => {
    if (currentField) {
      setFieldUpdates({
        ...currentField,
        indexRequirements: {
          isMandatory: currentField.indexRequirements?.isMandatory || false,
          regexMatch: currentField.indexRequirements?.regexMatch || '',
        },
        options: currentField.options
          ? currentField.options.map((o) => ({
              key: o.key,
              value: o.value,
            }))
          : [],
      });
    }
  }, [selectedFolder, currentField, field]);

  useEffect(() => {
    if (selectedFolder && selectedFolder.folderFields) {
      setCurrentField(
        _.find(selectedFolder.folderFields, (f) => f.name === field) || null
      );
    }
  }, [selectedFolder, field]);

  const updateField = ({
    id,
    name,
    type,
    active,
    options,
    matchRequiredField,
    mainField,
    indexRequirements,
  }: FolderField) => {
    return updateFolderField({
      variables: {
        folderFieldId: id,
        active,
        name,
        type,
        options,
        matchRequiredField,
        mainField,
        indexRequirements,
      },
    })
      .then((data) => {
        const updatedField = data.data.updateFolderField.folderField;

        const updatedFolder = {
          ...selectedFolder,
          folderFields: selectedFolder.folderFields.map((field) =>
            field.id === updatedField.id ? updatedField : field
          ),
        };

        refetchFolder(updatedFolder);

        setMessage({
          message: 'Folder field successfully updated',
          severity: 'success',
        });
        setSnackOpen(true);
        return Promise.resolve();
      })
      .catch((e) => {
        console.error('Could not update folder field: ', e);
        setMessage({
          message: 'Could not update folder field',
          severity: 'error',
        });
        setSnackOpen(true);
      });
  };

  return (
    <>
      <ModalWrapper isOpen={dropdownModalOpen} setIsOpen={setDropdownModalOpen}>
        <ModalContentBase
          title="ADD DROP DOWN OPTIONS"
          subtitle="Use the options below to define your drop down list items."
          onCancel={() => {
            setDropdownModalOpen(false);
            setNewDropdownOption('');
          }}
          onSubmit={() => {
            fieldUpdates && updateField(fieldUpdates);
            setDropdownModalOpen(false);
            setNewDropdownOption('');
          }}
        >
          <FieldDropdownOptions
            options={fieldUpdates?.options}
            fieldUpdates={fieldUpdates}
            updateField={setFieldUpdates}
            newDropdownOption={newDropdownOption}
            context={context}
            selectedField={currentField}
            setSelectedField={setCurrentField}
          />
        </ModalContentBase>
      </ModalWrapper>
    </>
  );
};

export default AddDropDownOption;
