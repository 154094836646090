import { Permissions, StructureItem } from './permissionsTypes';
import _ from 'lodash';
import { Structure } from './structure';
import React from 'react';
import { SelectedFolder } from '../../../../../components/masterTable/masterTableTypes';

export const checkID = (arr: Array<any> = [], id: string, keyID: string) => {
  const idArr = arr.map((item: any) => item[keyID]);
  let index = idArr.indexOf(id);
  if (index === -1) index = idArr.length;
  return index;
};

export const generateArr = (
  component: string,
  prev: Permissions,
  item: any,
  parentLabel: Array<string>,
  i: number,
  index: number,
  schema: StructureItem,
  keyGenerator: ({ id, parentLabel, i }: any) => string,
  convertValue: ({
    value,
    item,
    parentLabel,
    hasItem,
    parentItem,
    index,
    globalState,
    setGlobalState,
    content,
  }: any) => void,
  value: any,
  parentItem: any,
  setGlobalState: React.Dispatch<React.SetStateAction<Permissions>>,
  globalState: Permissions,
  content: any,
  arr: Array<any>
) => {
  if (component === 'text') return prev;
  let get = _.get(prev, keyGenerator({ id: item.id, parentLabel, i }));
  let hasItem;
  if (_.isArray(get)) {
    arr = get;
    index = checkID(get, item.id, item.keyID || schema.keyID || 'id');
    index === -1 ? (hasItem = false) : (hasItem = get[index]);
  } else {
    value = get;
  }
  if (hasItem) return prev;
  let res = _.set(
    prev,
    keyGenerator({ id: item.id, parentLabel, i }),
    convertValue({
      value,
      item,
      parentLabel,
      hasItem: get,
      parentItem,
      index,
      globalState,
      setGlobalState,
      content,
    })
  );
  return { ...res };
};

interface CurrentOrg {
  name: string;
  processPathEnabled: boolean;
}

export const generateContent = (
  item: any,
  schema: StructureItem,
  data: any = [],
  isSuser: boolean,
  currentOrg: CurrentOrg | null
) => {
  let content = data ? [...data] : [];
  if (schema && schema.dataName && schema.children?.content) {
    if (schema.children.customItems)
      content = content.slice(0, schema.children.customItems);
    item.children
      ? (content = [...content, ...item.children.content])
      : item[schema.dataName(isSuser)]
      ? (content = [...content, ...item[schema.dataName(isSuser)]])
      : (content = [...content]);

    content = _.uniqWith(content, _.isEqual);
    return content;
  } else {
    if (content.find((cont) => cont.name === 'fields restrictions')) {
      let index = content.findIndex((cont) => cont.name === 'fields restrictions');
      // Check to see if label needs to show the arrow icon
      item.folderFields.length === 0
        ? (content[index] = { ...content[index], hasChildren: false })
        : (content[index] = { ...content[index], hasChildren: true });
      // Insert folder fields into label
      content[index].children.content = [...item.folderFields];
    }
    content = _.uniqWith(content, _.isEqual);

    content.forEach((cont) => {
      if (cont.checkForOrgPermissions || cont.checkForFolderPermissions) {
        let checkForOrg, checkForFolder;

        if (cont.checkForOrgPermissions) {
          checkForOrg =
            cont.checkForOrgPermissions &&
            currentOrg &&
            _.isBoolean(currentOrg[cont.checkForOrgPermissions as keyof CurrentOrg]) &&
            currentOrg[cont.checkForOrgPermissions as keyof CurrentOrg] === true;
        }

        if (cont.checkForFolderPermissions) {
          checkForFolder =
            cont.checkForFolderPermissions &&
            _.isBoolean(item[cont.checkForFolderPermissions]) &&
            item[cont.checkForFolderPermissions] === true;
        }

        cont.hide = !(checkForFolder === true && checkForOrg === true);
      }
    });

    return content;
  }
};

export const hasItem = (prev: any, item: any) =>
  prev.find((element: string) => element === item.id);

export const generateFolderAccessDetails = (
  hasItem: any,
  item: any,
  parentItem: any,
  value: boolean | undefined,
  content: any
) => {
  // if the item is already created, just update the value. If it isn't, it creates.
  let i = checkID(hasItem, item.id || parentItem.folderId, 'folderID');
  if (hasItem && hasItem[i]) {
    hasItem[i] = {
      ...hasItem[i],
      canAccess: value ? value : false,
      folderID: item.id ? item.id : parentItem.folderId,
      fullAccess: false,
      folderPermissions: {
        canDeleteNotes: hasItem[i].folderPermissions.canDeleteNotes || false,
        canDeleteRecords: hasItem[i].folderPermissions.canDeleteRecords || false,
        canRemoveIndex: hasItem[i].folderPermissions.canRemoveIndex || false,
        browsePermissions: {
          ...hasItem[i].folderPermissions.browsePermissions,
          canEditIndexedRecords:
            hasItem[i].folderPermissions.browsePermissions.canEditIndexedRecords || false,
          folderFieldRestrictions:
            hasItem[i].folderPermissions.browsePermissions.folderFieldRestrictions,
        },
      },
    };
    return hasItem;
  } else {
    let newArr = [];
    if (content) {
      newArr = content.map((folder: any) => ({
        folderID: folder.id,
        canAccess: false,
        fullAccess: false,
        folderPermissions: {
          canDeleteNotes: false,
          canDeleteRecords: false,
          canRemoveIndex: false,
          browsePermissions: {
            canEditIndexedRecords: false,
            folderFieldRestrictions: [],
          },
        },
      }));
      let i = checkID(newArr, item.id, 'folderID');
      newArr[i] = { ...newArr[i], canAccess: value ? value : false };
    }
    let filter = newArr.map((item: any) => {
      let item2;
      let hasPermissions =
        hasItem.findIndex((secItem: any) => {
          if (secItem.folderID === item.folderID) item2 = secItem;
          return secItem.folderID === item.folderID;
        }) === -1;
      if (hasPermissions === false) item = item2;
      return item;
    });
    return filter;
  }
};

export const keyGenerator = (hasItem: any, parentItem: any, path: string) => {
  let index;
  if (hasItem && _.isArray(hasItem.folderAccessDetails))
    index = checkID(
      hasItem.folderAccessDetails,
      parentItem.folderId || parentItem.id || parentItem.children.content[0].folderId,
      'folderID'
    );
  return `${path}[${index}]`;
};

export const keyGeneratorFolderFields = (parentItem: any, hasItem: any) => {
  let index;
  if (hasItem && _.isArray(hasItem.folderAccessDetails))
    index = checkID(
      hasItem.folderAccessDetails,
      parentItem.folderId || parentItem.id || parentItem.children?.content[0]?.folderId,
      'folderID'
    );
  return `folderAccessDetails[${index}].folderPermissions.browsePermissions.folderFieldRestrictions`;
};

export const createFolderRestriction = (
  hasItem: any,
  parentItem: any,
  parentLabel: Array<string>,
  value: any,
  structure: Structure,
  setGlobalState: React.Dispatch<React.SetStateAction<Permissions>>,
  item: any,
  globalState: Permissions
) => {
  // hasItem = [];
  hasItem.push({
    folderFieldId: parentItem ? parentItem?.id : undefined,
    folderFieldRestrictionType: parentLabel[4] || 'in',
    folderFieldFieldType: parentItem ? parentItem.type : 'string',
    folderFieldValue: value,
  });
  // check canAccess as true when folder has any restriction field
  setGlobalState((prev: any) => {
    if (structure.folder && structure.folder.children) {
      let { keyID, convertValue, dependencies } = structure.folder.children;
      let parentIndex = 0;
      let get = _.get(prev, 'folderAccessDetails');
      if (_.isArray(get)) parentIndex = checkID(get, parentItem.folderId, keyID || 'id');
      if (get[parentIndex] === undefined) get[parentIndex] = {};
      get[parentIndex].folderID = parentItem.folderId;
      let res = _.set(
        prev,
        'folderAccessDetails',
        convertValue?.({
          value: true,
          item,
          parentLabel,
          hasItem: get,
          parentItem,
          index: parentIndex,
          globalState,
          setGlobalState,
          content: null,
        })
      );
      if (dependencies && value === false) res = _.set(prev, dependencies, false);
      return { ...res };
    }
  });
  return hasItem;
};

export const getValue = (
  id: string,
  key: string,
  defaultValue: any,
  globalState: Permissions,
  item: any,
  schema: StructureItem
) => {
  let value = _.get(globalState, key, defaultValue);
  if (_.isArray(value)) {
    let index = checkID(value, id, item.keyID || schema.keyID || 'id');
    value = value[index];
  }
  return value;
};

export const generateFetchData = (
  data: any,
  schema: StructureItem,
  isSuser: boolean,
  item: any,
  setSchema: React.Dispatch<React.SetStateAction<StructureItem>>,
  setContentChildren: React.Dispatch<React.SetStateAction<any>>,
  currentOrg: any
) => {
  let dataName = schema.dataName ? schema.dataName : () => '';
  let getData = _.get(data, dataName(isSuser));
  let content = generateContent(item, schema, getData, isSuser, currentOrg);
  if (getData.length) {
    setSchema((prev: StructureItem) => {
      return {
        ...prev,
        children: { ...prev.children, content },
      };
    });
    if (content) setContentChildren(content);
  }
};
export const generateBasicPermissionsObj = (
  folderID: string,
  key: string,
  value: boolean
) => {
  let basicOBJ = {
    folderID,
    canAccess: false,
    fullAccess: false,
    folderPermissions: {
      canRemoveIndex: false,
      canDeleteNotes: false,
      canDeleteRecords: false,
      processPathPermissions: {
        canSearchUsingAnyFilter: true,
        canSetProcessPath: false,
        canPerformProcessStep: false,
        requireNoteWhenApproving: false,
        requireNoteWhenRejecting: false,
      },
      browsePermissions: {
        canEditIndexedRecords: false,
        folderFieldRestrictions: [],
      },
    },
  };

  basicOBJ = _.set(basicOBJ, key, value);
  return basicOBJ;
};

export const modifyPermissionsKey = (obj: any, key: string, value: boolean) => {
  let newOBJ = _.set(obj, key, value);
  let res = _.set(newOBJ, 'canAccess', value === true ? true : newOBJ.canAccess);
  return res;
};
