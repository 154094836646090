import { Box, Typography } from '@mui/material';
import { TemplatesProps } from '../types';
import TemplateButton from './TemplateButton';

const Templates = ({
  workflows,
  setSelectedWorkflow,
  selectedWorkflow,
  deleteWorkflow,
  applyTemplateToFolder,
}: TemplatesProps) => {
  return (
    <Box sx={{ flex: 5, p: 2 }}>
      <Typography
        variant="button"
        sx={{
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.greyblueDark,
          fontWeight: 500,
          textTransform: 'capitalize',
        }}
      >
        Templates
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: 2 }}>
        {workflows &&
          workflows.map((workflow) => (
            <TemplateButton
              applyTemplateToFolder={applyTemplateToFolder}
              deleteWorkflow={deleteWorkflow}
              key={workflow.id}
              workflow={workflow}
              setSelectedWorkflow={setSelectedWorkflow}
              selectedWorkflow={selectedWorkflow}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Templates;
