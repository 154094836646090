import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { ValuePair } from '../../../containers/folder/KeyValueFilterInput';
import IdentifierConfigModal from '../../../containers/folder/subcomponents/OCRIdentifier/IdentifierConfigModal';

const NoOptionComponent = ({ handleOpenModal }: { handleOpenModal: () => void }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '5px',
      }}
    >
      <Typography sx={{ textAlign: 'center' }}>No options</Typography>
      <Button
        onClick={handleOpenModal}
        size="small"
        sx={{
          color: (theme) =>
            theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblueDark',
          fontSize: 11,
        }}
      >
        Add New Option
      </Button>
    </Box>
  );
};

const KeyValueCell = ({
  params,
  selectedFolder,
  refetchFolder,
}: {
  params: GridRenderCellParams;
  selectedFolder: any;
  refetchFolder: any;
}) => {
  const { id, value, api, field } = params;
  const findCompleteValue =
    selectedFolder.apProcessingSettings?.ocrTemplateNameSupplierCodePairs.find(
      (key: ValuePair) => key.code === value
    );
  const [inputValue, setInputValue] = useState('');
  const [val, setValue] = useState(findCompleteValue);
  const [open, setOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleChange = async (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    if (newValue === null) {
      newValue = { code: '' };
    }
    setValue(newValue.code);
    api.setEditCellValue({ id, field, value: newValue.code }, event);
    // Check if the event is not from the keyboard
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      // Wait for the validation to run
      const isValid = await api.commitCellChange({ id, field });
      if (isValid) {
        api.setCellMode(id, field, 'view');
      }
    }
  };

  const handleCloseModal = () => setModalIsOpen(false);
  const handleOpenModal = () => setModalIsOpen(true);

  return (
    <>
      <Autocomplete
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        autoHighlight={true}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option) => `${option.code} (${option.name})`}
        value={val}
        options={selectedFolder.apProcessingSettings.ocrTemplateNameSupplierCodePairs}
        inputValue={inputValue}
        onChange={handleChange}
        noOptionsText={<NoOptionComponent handleOpenModal={handleOpenModal} />}
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => <TextField autoFocus {...params} />}
        renderOption={(props, option) => (
          <li {...props}>{`${option.code} (${option.name})`}</li>
        )}
      />
      <IdentifierConfigModal
        folder={selectedFolder}
        handleClose={handleCloseModal}
        isOpen={modalIsOpen}
        handleOpen={handleOpenModal}
        refetch={refetchFolder}
        fromMasterTable
      />
    </>
  );
};

export default KeyValueCell;
