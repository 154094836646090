import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledCheckboxProps extends CheckboxProps {
  variant?: 'primary' | 'secondary';
  tooltipTitle?: string;
  label?: string;
  labelProps?: TypographyProps;
  checkboxProps?: StyledCheckboxProps;
  formControlLabelProps?: any;
}

export const StyledCheckbox = styled(MuiCheckbox)<StyledCheckboxProps>(
  ({ theme, variant = 'primary' }) => ({
    ...(variant === 'primary' && {
      color: theme.palette.common.greyblueDark,
      '&.Mui-checked': {
        color: theme.palette.common.greyblueDark,
      },
    }),
    ...(variant === 'secondary' && {
      color: theme.palette.common.white,
      '&.Mui-checked': {
        color: theme.palette.common.white,
      },
    }),
  })
);

const StyledLabel = styled(Typography)<TypographyProps>(
  ({ theme, color = 'primary' }) => ({
    fontSize: 10,
    ...(color === 'primary' && {
      color: theme.palette.common.greyblueDarker,
    }),
    ...(color === 'secondary' && {
      color: theme.palette.common.white,
    }),
  })
);

const Checkbox = (props: StyledCheckboxProps) => {
  return (
    <Tooltip title={props.tooltipTitle ? props.tooltipTitle : ''}>
      <FormControlLabel
        {...props.formControlLabelProps}
        control={<StyledCheckbox {...props.checkboxProps} variant={props.variant} />}
        label={
          <StyledLabel variant="button" color={props.variant} {...props.labelProps}>
            {props.label}
          </StyledLabel>
        }
      />
    </Tooltip>
  );
};

export default Checkbox;
