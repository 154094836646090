import React from 'react';
import { Box, IconButton, Avatar, Typography } from '@mui/material';
import FontAwesomeIcon from '../../../components/FAIcon';

const AvatarInput = ({
  register,
  setError,
  classes,
  errors,
  avatarImage,
  setAvatarImage,
  resetField,
}) => {
  const avatarValidator = (file) => {
    if (!file) return;
    setAvatarImage(null);
    const formatSoported = ['image/jpg', 'image/png', 'image/gif', 'image/jpeg'];
    if (file.size > 5000000) {
      return setError('image', {
        type: 'manual',
        message: 'Max 5MB',
      });
    }
    if (!formatSoported.includes(file.type)) {
      return setError('image', {
        type: 'manual',
        message: 'Only PNG, JPG & GIF',
      });
    }
    resetField('image');
    setAvatarImage(file);
  };

  let imageURL;
  if (avatarImage) imageURL = URL.createObjectURL(avatarImage);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: { xs: '65px', md: '75px' },
          width: { xs: '65px', md: '75px' },
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          borderRadius: '50%',
          backgroundColor: avatarImage ? 'transparent' : 'common.greyblueDark',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: avatarImage ? 'transparent' : 'common.greyblueDarker',
            color: avatarImage ? '' : 'common.lightblue',
          },
          flex: '1',
        }}
      >
        <IconButton
          disabled={true}
          variant="contained"
          component="label"
          color="white"
          sx={{
            padding: 0,
            width: '20px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          {...register('image', {
            onChange: (e) => {
              avatarValidator(e.target.files[0]);
            },
            validate: {
              lessThan5MB: (files) =>
                files.length === 0 ? files : files[0]?.size < 5000000 || 'Max 5MB', //files ? ,
              acceptedFormats: (files) =>
                files.length === 0
                  ? files
                  : ['image/jpg', 'image/png', 'image/gif', 'image/jpeg'].includes(
                      files[0]?.type
                    ) || 'Only PNG, JPG & GIF',
            },
          })}
        >
          {avatarImage ? (
            <Avatar
              alt="Avatar"
              sx={{
                height: { xs: '65px', md: '75px' },
                width: { xs: '65px', md: '75px' },
              }}
              src={imageURL}
            ></Avatar>
          ) : (
            <FontAwesomeIcon icon="fas fa-camera-retro" />
          )}
          <input
            type="file"
            accept="image/*"
            name="image"
            hidden
            style={{ padding: 0 }}
          />
        </IconButton>
      </Box>
      {errors.image && (
        <Typography
          sx={{
            ...classes.errorMessage,
            textAlign: 'center',
            fontSize: '11px',
          }}
          variant="alert"
        >
          {errors.image.message}
        </Typography>
      )}
    </>
  );
};

export default AvatarInput;
