import { TextField as MuiTextfield, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type StyledTextFieldProps = TextFieldProps & {};

export const StyledTextField = styled(MuiTextfield)<StyledTextFieldProps>(
  ({ theme, color = 'primary', variant }) => {
    let greyblueDarker = theme.palette.common.greyblueDarker;
    let greyblueDark: string = theme.palette.common.greyblueDark;
    let white = theme.palette.common.white;
    let lightblue = theme.palette.common.lightblue;
    return {
      ...(color === 'primary' && {
        '& label.Mui-focused': {
          color: greyblueDarker,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: greyblueDark,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: greyblueDark,
          },
          '&:hover fieldset': {
            borderColor: greyblueDarker,
          },
          '&.Mui-focused fieldset': {
            borderColor: greyblueDarker,
          },
        },
      }),
      ...(color === 'secondary' && {
        '& .MuiInputBase-input': {
          backgroundColor: greyblueDark,
          color: white,
          borderRadius: '4px',
        },
        '&.Mui-focused fieldset': {
          boxShadow: `blue 0 0 0 2px`,
        },
        '& label.Mui-focused': {
          color: white,
        },
        '& .MuiInputLabel-root': {
          color: white,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: lightblue,
          },
          '& fieldset': {
            borderColor: lightblue,
          },
          '&:hover fieldset': {
            borderColor: lightblue,
          },
        },
      }),
      ...(variant === 'outlined' && {}),
      ...(variant === 'filled' && {}),
      ...(variant === 'standard' && {
        '& .MuiInputLabel-root': {
          zIndex: 99,
          padding: 5,
          color: color === 'primary' ? greyblueDark : lightblue,
        },
        '& .MuiInputBase-root:after': {
          borderColor: color === 'primary' ? greyblueDark : lightblue,
          color: color === 'primary' ? greyblueDark : lightblue,
        },
        '& .MuiInput-input': {
          padding: 10,
          backgroundColor: color === 'primary' ? lightblue : greyblueDark,
        },
      }),
    };
  }
) as typeof MuiTextfield;

// export const TextField = (props: StyledTextFieldProps) => {
//   return <StyledTextField {...props} />;
// };
