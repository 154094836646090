import { useEffect, useState, useContext } from 'react';
import { Box, Drawer, IconButton, Tooltip } from '@mui/material';
import FontAwesomeIcon from '../../FAIcon';
import StepAction from './stepAction/StepAction';
import { Context as StatusContext } from '../../../context/statusContext';
import { SetMessage, SetSnackBarState } from '../../masterTable/masterTableTypes';
import { Step, Workflow } from '../../../containers/workflow/types';
import StepActionContainer from './stepAction/StepActionContainer';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  label?: string;
  value: number | string;
  screen: string;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, label, screen, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${label}-tab`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      sx={{
        height: '100%',
        textAlign: 'center',
        backgroundColor: (theme) =>
          label === 'notes' || theme.palette.mode === 'dark'
            ? 'black.main'
            : 'common.white',
      }}
    >
      {value === index && children}
    </Box>
  );
};
