import { Box, Card } from '@mui/material';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Step } from '../../types';

interface BaseProps {
  children: JSX.Element;
  step: Step;
}

const Base = ({ children, step }: BaseProps) => {
  return (
    <Draggable
      key={step.stepOrder}
      draggableId={`step-${step.stepOrder}`}
      index={step.stepOrder}
      isDragDisabled={true}
    >
      {(provided, snapshot) => {
        return (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Card
              sx={{
                width: '100%',
                display: 'flex',
                backgroundColor: 'common.greyblueDarker',
                color: 'common.white',
              }}
            >
              {children}
            </Card>
          </Box>
        );
      }}
    </Draggable>
  );
};
export default Base;
