import React from 'react';
import { StepCardProps } from '../types';
import ApprovalCard from './StepsCards/ApprovalCard';
import PictureCard from './StepsCards/PictureCard';
import SignatureCard from './StepsCards/SignatureCard';

const StepCard = ({ handleRemoveStep, step, handleUpdateStep, users }: StepCardProps) => {
  switch (step.__typename) {
    case 'ApprovalStep':
      return (
        <ApprovalCard
          handleRemoveStep={handleRemoveStep}
          step={step}
          handleUpdateStep={handleUpdateStep}
          users={users}
        />
      );
    case 'signature':
      return (
        <SignatureCard
          handleRemoveStep={handleRemoveStep}
          step={step}
          handleUpdateStep={handleUpdateStep}
          users={users}
        />
      );
    case 'picture':
      return (
        <PictureCard
          handleRemoveStep={handleRemoveStep}
          step={step}
          handleUpdateStep={handleUpdateStep}
          users={users}
        />
      );

    default:
      return (
        <ApprovalCard
          handleRemoveStep={handleRemoveStep}
          step={step}
          handleUpdateStep={handleUpdateStep}
          users={users}
        />
      );
  }
};

export default StepCard;
