const response = {
  name: 'prueba2',
  id: '624ad74871725200138f1679',
  frecuency: 'week',
  data: [
    {
      date: '2022-04-01T16:33:59+00:00',
      value: { scanned: 20, deleted: 5, indexed: 40 },
    },
    {
      date: '2022-04-02T16:33:59+00:00',
      value: { scanned: 10, deleted: 10, indexed: 9 },
    },
    {
      date: '2022-04-03T16:33:59+00:00',
      value: { scanned: 50, deleted: 15, indexed: 40 },
    },
    {
      date: '2022-04-04T16:33:59+00:00',
      value: { scanned: 60, deleted: 2, indexed: 50 },
    },
    {
      date: '2022-04-05T16:33:59+00:00',
      value: { scanned: 30, deleted: 30, indexed: 40 },
    },
    {
      date: '2022-04-06T16:33:59+00:00',
      value: { scanned: 5, deleted: 40, indexed: 50 },
    },
    {
      date: '2022-04-07T16:33:59+00:00',
      value: { scanned: 10, deleted: 35, indexed: 30 },
    },
    {
      date: '2022-04-08T16:33:59+00:00',
      value: { scanned: 10, deleted: 20, indexed: 65 },
    },
    {
      date: '2022-04-09T16:33:59+00:00',
      value: { scanned: 20, deleted: 30, indexed: 65 },
    },
    {
      date: '2022-04-14T16:33:59+00:00',
      value: { scanned: 30, deleted: 19, indexed: 50 },
    },
    {
      date: '2022-04-15T16:33:59+00:00',
      value: { scanned: 60, deleted: 25, indexed: 40 },
    },
    {
      date: '2022-04-16T16:33:59+00:00',
      value: { scanned: 10, deleted: 20, indexed: 30 },
    },
    {
      date: '2022-04-27T16:33:59+00:00',
      value: { scanned: 20, deleted: 19, indexed: 40 },
    },
    {
      date: '2022-04-28T16:33:59+00:00',
      value: { scanned: 70, deleted: 18, indexed: 35 },
    },
    {
      date: '2022-04-29T16:33:59+00:00',
      value: { scanned: 20, deleted: 15, indexed: 55 },
    },
    {
      date: '2022-05-24T16:33:59+00:00',
      value: { scanned: 17, deleted: 0, indexed: 60 },
    },
    {
      date: '2022-05-27T16:33:59+00:00',
      value: { scanned: 30, deleted: 20, indexed: 80 },
    },
    { date: '2022-06-04T16:33:59+00:00', value: { scanned: 5, deleted: 8, indexed: 18 } },
    {
      date: '2022-06-13T16:33:59+00:00',
      value: { scanned: 9, deleted: 13, indexed: 30 },
    },
  ],
};

export { response };
