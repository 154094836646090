import { useState, useEffect } from 'react';
import RTC from '../utils/socketComm';
const initialLocalStorage = {
  userSettings: {
    drawerWidth: {
      search: 800,
      indexing: 800,
    },
    folderIsCompact: false,
    searchScreenQuickView: true,
    searchScreenQuickEdit: false,
    searchScreenSearch: false,
    indexingScreenQuickView: true,
    indexingScreenQuickEdit: false,
    indexingScreenSearch: false,
    sideDrawerToggled: true,
    tableAlignment: 'left',
    showNewFeatures: false,
    latestRecordsSearch: {},
    lastRecordsFolder: '',
    tableSettings: {
      search: {},
      indexing: {},
      reporting: {},
      folders: {},
      users: [],
      folderFields: {},
      columnWidth: {},
    },
    selectedOrg: null,
  },
  isLoggedIn: false,
  // appversion: `"${process.env.REACT_APP_VERSION}"`,
  // userData: {
  //   id: '',
  //   userName: '',
  //   userLname: '',
  //   role: 0,
  //   scan: '',
  //   organisations: '',
  //   suser: false,
  //   selOrg: '',
  //   selFolder: '',
  // },
  screenSelectedData: {
    indexingScreenSelected: {
      selected: [],
      selectedRows: [],
    },
    searchScreenSelected: {
      selected: [],
      selectedRows: [],
    },
    reportingScreenSelected: {
      selected: [],
      selectedRows: [],
    },
    orgScreenSelected: {
      selected: [],
      selectedRows: [],
    },
    folderFieldsScreenSelected: {
      selected: [],
      selectedRows: [],
    },
  },
  collectionBin: [],
  appversion: 'v2.0.3',
};
export function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    const currentStorage = window.localStorage.getItem(key);
    if (currentStorage === 'undefined' || currentStorage === null) {
      return defaultValue;
    } else {
      let storage = JSON.parse(currentStorage);
      if (typeof storage === 'object' && storage !== null) {
        for (const [keys] of Object.entries(storage)) {
          if (defaultValue && defaultValue[key]) {
            storage[keys] = defaultValue[key];
          }
        }
      }
      return storage;
    }
  });

  useEffect(() => {
    if (value === undefined) {
      window.localStorage.setItem(key, JSON.stringify(initialLocalStorage[key]));
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }

    let event = new Event('storageChange', { bubbles: true, cancelable: true });
    window.dispatchEvent(event);
  }, [key, value, setValue]);
  return [value, setValue];
}
export function initLocalStorage(notDefault = false, fromDB = false) {
  let initialValues = notDefault ? notDefault : initialLocalStorage;
  if (!fromDB) {
    for (const [key] of Object.entries(initialValues)) {
      let storageKey = initialValues[key];
      window.log(storageKey);
      let inStorage;
      try {
        inStorage = JSON.parse(window.localStorage.getItem(key));
      } catch (error) {}
      // let inStorage = JSON.parse(window.localStorage.getItem(key));
      if (!inStorage || inStorage === 'undefined' || inStorage === undefined) {
        window.localStorage.setItem(key, JSON.stringify(storageKey));
      }
    }
  } else {
    for (const [key] of Object.entries(initialValues)) {
      let storageKey = initialValues[key];
      window.log(key, storageKey);
      let inStorage = JSON.parse(window.localStorage.getItem(key));
      if (!inStorage) {
        if (key === 'collectionBin' && !storageKey.length) {
          window.localStorage.setItem(key, '[]');
        } else if (
          (typeof storageKey === 'object' || typeof storageKey === 'string') &&
          storageKey !== null
        ) {
          window.log(key, JSON.stringify(storageKey));
          window.localStorage.setItem(key, JSON.stringify(storageKey));
        }
      }
    }
  }
}
export function modifyLocalStorageObject(newState, key) {
  let currentStorage = JSON.parse(window.localStorage.getItem(key));
  if (newState !== undefined || newState !== 'undefined') {
    window.log('newState: ', newState, 'key: ', key);
    currentStorage = { ...currentStorage, ...newState };
  }
  return currentStorage;
}

export function checkUserSettings(userSettings) {
  Object.entries(initialLocalStorage).forEach(([fstLvl, value]) => {
    window.log(fstLvl, userSettings[fstLvl]);
    if (typeof userSettings[fstLvl] !== typeof initialLocalStorage[fstLvl]) {
      window.log('Not same first level');
      let tryJson = JSON.parse(userSettings[fstLvl]);
      window.log('Trying JSON parsing: ', tryJson);
      if (typeof tryJson !== typeof initialLocalStorage[fstLvl]) {
        userSettings[fstLvl] = initialLocalStorage[fstLvl];
      } else {
        userSettings[fstLvl] = tryJson;
      }
      let firstLvl = Object.entries(initialLocalStorage[fstLvl]);
      if (firstLvl.length) {
        window.log(userSettings[fstLvl]);
        firstLvl.forEach(([sndLvl, value]) => {
          window.log('second Level: ', sndLvl, typeof userSettings[fstLvl][sndLvl]);
          window.log(userSettings[fstLvl][sndLvl]);
          if (
            typeof userSettings[fstLvl][sndLvl] !==
            typeof initialLocalStorage[fstLvl][sndLvl]
          ) {
            let tryJson = JSON.parse(userSettings[fstLvl][sndLvl]);
            if (typeof tryJson !== typeof initialLocalStorage[fstLvl][sndLvl]) {
              userSettings[fstLvl][sndLvl] = JSON.stringify(
                initialLocalStorage[fstLvl][sndLvl]
              );
            }
          }
        });
      }
    }
    window.log('same type, keeping...', userSettings[fstLvl]);
  });

  /*  Object.entries(userSettings).forEach(([key, value]) => {
    if (Object.entries(userSettings[key])) {
      let subLevel = userSettings[key];
      Object.entries(subLevel).forEach(([keysub, value]) => {
        if (value === 'undefined' || value === '') {
          userSettings[key] = JSON.stringify(initialLocalStorage[key][keysub]);
        }
      });
    }
    if (value === 'undefined' || value === '') {
      userSettings[key] = JSON.stringify(initialLocalStorage[key]);
    }
  }); */
  window.log('this is userSettings: ', userSettings);
  return userSettings;
}

export function checkUS(obj, objToCompare = null) {
  if (!objToCompare) objToCompare = initialLocalStorage; // when executed for the first time, it is compared to 'initialLocalStorage'.
  // debugger
  if (typeof obj === 'string' && typeof obj !== typeof objToCompare) {
    try {
      obj = JSON.parse(obj);
    } catch (error) {
      obj = objToCompare;
    }
  }
  for (const key in objToCompare) {
    // debugger;
    if (obj.hasOwnProperty(key)) {
      // Check if this key exists in the object to compare, in order to have a reference.
      if (typeof obj[key] === typeof objToCompare[key]) {
        // If it is the same data type, just check if it is an object to check its keys or a string to try to parse.
        if (typeof obj[key] === 'string') {
          try {
            obj[key] = JSON.parse(obj[key]);
          } catch (error) {}
        } else if (typeof obj[key] === 'object') {
          if (objToCompare[key] !== null) checkUS(obj[key], objToCompare[key]);
        }
      } else {
        switch (typeof obj[key]) {
          case 'string':
            try {
              obj[key] = JSON.parse(obj[key]);
              if (obj[key] === undefined || obj[key] === 'undefined') {
                obj[key] = objToCompare[key]; // Example, the value of screenSelectedData.indexingScreenSelected.selected is undefined, having reference to that property in the initialLocalStorage, it converts it to its initial value ([])
              } else if (typeof obj[key] === 'object') {
                if (objToCompare[key] !== null) checkUS(obj[key], objToCompare[key]);
              }
            } catch (error) {}
            break;
          case 'object':
            if (objToCompare[key] !== null) checkUS(obj[key], objToCompare[key]);
            break;
          case 'undefined' || undefined:
            obj[key] = objToCompare[key];
            break;
          default:
            if (typeof obj[key] !== typeof objToCompare[key]) {
              try {
                obj[key] = JSON.parse(obj[key]);
              } catch (error) {
                checkUS(obj[key], objToCompare[key]);
              }
            }
            break;
        }
      }
    } else {
      obj[key] = objToCompare[key];
      // if (typeof obj[key] === 'string') {
      //   // If it has no reference in the initialLocalStorage, just check if the parse function can be applied, and if it is an object, check it again.
      //   try {
      //     obj[key] = JSON.parse(obj[key]);
      //     // if (obj[key] === undefined || obj[key] === 'undefined') obj[key] = VALOR; // si llega un valor undefined, y no tiene como referencia un valor inicial, deberia borrarse?
      //     if (typeof obj[key] === 'object') checkUS(obj[key], objToCompare[key]);
      //   } catch (error) {
      //     delete obj[key];
      //   }
      // }
    }
  }
  if (obj.hasOwnProperty('userData')) delete obj.userData;
  return obj;
}
