import { Box, Button, Typography } from '@mui/material';
import { StepsProps } from '../types';

const styles = {
  button: {
    color: 'common.greyblueDark',
    backgroundColor: '#F2F2F2',
    boxShadow: '-3px 3px 5px -4px rgba(0,0,0,0.45)',
    textTransform: 'capitalize',
    fontWeight: 400,
  },
};

const Steps = ({ steps, handleAddStep }: StepsProps) => {
  let disabled = steps.length === 20;
  return (
    <Box sx={{ flex: 5, p: 2 }}>
      <Typography
        variant="button"
        sx={{
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.greyblueDark,
          fontWeight: 500,
          textTransform: 'capitalize',
        }}
      >
        Steps
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: 2 }}>
        <Button
          sx={{
            ...styles.button,
            '&:hover': {
              backgroundColor: 'common.lightblue',
            },
          }}
          fullWidth
          disabled={disabled}
          onClick={() => handleAddStep('approval')}
        >
          Approval
        </Button>
        {/* <Button
          sx={styles.button}
          fullWidth
          disabled={disabled}
          onClick={() => handleAddStep('signature')}
        >
          Signature
        </Button>
        <Button
          sx={styles.button}
          fullWidth
          disabled={disabled}
          onClick={() => handleAddStep('picture')}
        >
          Picture
        </Button> */}
      </Box>
    </Box>
  );
};

export default Steps;
