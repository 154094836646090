import {ReactNode} from 'react';
import {buttonStyle} from '../styles/elementsStyle'
import { Link } from "react-router-dom";
const PrimaryBtn = buttonStyle.primary
const SecondaryBtn = buttonStyle.secondary
const PositiveBtn = buttonStyle.positive
const NegativeBtn = buttonStyle.negative

type buttonProps = {
    type: "primary" | "secondary" | "positive" | "negative",
    link?: string,
    func?: Function,
    children: ReactNode
}

function Button({type, link, children, func}: buttonProps): JSX.Element {
    let ButtonStyle;
    switch (type) {
        case "primary":
            ButtonStyle = PrimaryBtn;
            break;
        case "secondary":
            ButtonStyle = SecondaryBtn;
            break;
        case "positive":
            ButtonStyle = PositiveBtn;
            break;
        case "negative":
            ButtonStyle = NegativeBtn;
            break;
        default:
            ButtonStyle = PrimaryBtn;
            break;
    }
    if (link) {
        return (
            <Link to={link}>
                <ButtonStyle>
                    {children}
                </ButtonStyle>
            </Link>
        )
    } else if (func) {
        return (
            <ButtonStyle onClick={func}>
                {children}
            </ButtonStyle>
        )
    } else {
        return (
            <ButtonStyle>
                {children}
            </ButtonStyle>
        )
        
    }
}

export default Button;