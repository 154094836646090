import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle, Box, Button, Modal, Typography } from '@mui/material';
import { DataGridProps, GridEditModes } from '@mui/x-data-grid';
import axios from 'axios';
import { isValid, parse } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import CustomDialog from '../../components/CustomDialog';
import FontAwesomeIcon from '../../components/FAIcon';
import OCRTemplateSetup from '../../components/OCRSetup/OCRTemplateSetup';
import GeneralSnackbar, { HandleClose } from '../../components/SnackBar';
import CreationDrawer, {
  ContainerWithDrawer,
} from '../../components/documentDrawer/CreationDrawer';
import LinearProgressBar from '../../components/linearProgressBar';
import MasterTable from '../../components/masterTable/MasterTable';
import {
  CopiedRows,
  CreateRecord,
  FolderField,
  Row,
  Rows,
  Scan,
  SelectedFolder,
} from '../../components/masterTable/masterTableTypes';
import WholePageMessage from '../../components/wholePageMessage';
import FolderSelector from '../../containers/folder/FolderSelector';
import { OCRSetupProvider } from '../../context/OCRSetupContext';
import { Context as RTCContext } from '../../context/RTCContext';
import { Context as AuthContext } from '../../context/authContext';
import { Context as StatusContext } from '../../context/statusContext';
import { Context as TableContext } from '../../context/tableContext';
import { Context as tasksContext } from '../../context/tasksContext';
import { FabricProvider } from '../../hooks/useFabricCanvas';
import { modifyLocalStorageObject, useLocalStorage } from '../../hooks/useLocalStorage';
import ErrorHandler from '../../utils/errorHandler';
import {
  GET_FOLDERS,
  GET_FOLDERS_BY_ORG,
  GET_FOLDER_BY_ID,
  GET_FOLDER_BY_ID_BY_ORG,
} from '../../utils/gql/gqlFolders';
import gqlIndexes from '../../utils/gql/gqlIndexes';
import gqlRecords from '../../utils/gql/gqlRecords';
import { GET_USER, GET_USER_BY_ORG } from '../../utils/gql/gqlUsers';
import { db } from '../../utils/indexedDB/indexeddb';
import utils from '../../utils/utils';
import {
  checkIDBForExistingValues,
  getModifiedFieldsData,
  getOrderForProcessing,
} from './indexingHelpers';
import {
  FailedRecord,
  FieldsToSaveMap,
  HandleAssignTo,
  HandleUpladToS3,
  ProcessingOrder,
  UpdateRecordValues,
  UpdateRecords,
  variables,
} from './indexingTypes';
import { isString } from 'lodash';
import { useLiveQuery } from 'dexie-react-hooks';
const alertStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  justifyContent: 'space-between',
  '& .MuiAlert-action': {
    m: 0,
    p: 0,
  },
  '& .MuiAlert-message': {
    textAlign: 'center',
  },
};

const useStyles = makeStyles((theme) => ({
  gridItems: {
    padding: theme.spacing(3),
    height: '100%',
  },
  container: {
    height: '100%',
  },
}));

function Indexing() {
  const {
    setScreen,
    saveScanned,
    state,
    setVisible,
    setErrorStatus,
    setSelectedRecord,
    setSnackbar,
  } = useContext(StatusContext);
  const [limit, setLimit] = useState(100);
  const [activeAttachment, setActiveAttachment] =
    useState<null | { folderId: string; name: string; id: string }>(null);
  const [count, setCount] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sorting, setSorting] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [recordCount, setRecordCount] = useState(0);
  const [unIndexed, setUnindexed] = useState<Row[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<string | CreateRecord>('');
  const [headers, setHeaders] = useState<FolderField[]>([]);
  const [errorFiles, setErrorFiles] = useState([]);
  const [isLoadingFolders, setIsLoadingFolders] = useState(false);
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage('isLoggedIn', true);
  const [indexingScreenQuickView, setQuickView] = useState(true);
  const [indexingScreenQuickEdit, setQuickEdit] = useState(true);
  const [indexingScreenEditMode, setEditMode] = useState<GridEditModes>(
    GridEditModes.Cell
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [fieldsToSave, setFieldsToSave] = useState<FieldsToSaveMap>([]);
  const [failedRecords, setFailedRecords] = useState<FailedRecord[]>([]);
  const [processingOrder, setProcessingOrder] = useState<ProcessingOrder>({});
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [isLoadingData, setLoadingData] = useState(false);
  const authContext = useContext(AuthContext);
  const rtcContext = useContext(RTCContext);
  const taskContext = useContext(tasksContext);
  const tableContext = useContext(TableContext);
  const { state: taskState, addTask, removeTask } = taskContext;
  const {
    setRowLoader,
    state: { recordToUpdate },
  } = tableContext;
  const [userData, setUserData] = useLocalStorage('userData');
  const [anchorUploadEl, setAnchorUploadEl] = React.useState(null);
  const [userPermissions, setUserPermissions] = React.useState(null);
  const { suser, selOrg } = userData;
  const isSuser = authContext.state.suser || suser;
  const [screenSelectedData, setScreenSelectedData] =
    useLocalStorage('screenSelectedData');
  const { selected, selectedRows } = screenSelectedData.indexingScreenSelected;
  const [copiedRows, setCopiedRows] = React.useState<CopiedRows[]>([]);
  const [isScanning, setIsScanning] = useState(false);
  const snackbar = state.snackbar;
  const classes = useStyles();
  const [selectedFolder, setSelectedFolder] = useState<SelectedFolder>({});
  const [ocrSetup, setOcrSetup] = useState(false);
  const toggleOCRSetup = (value) => setOcrSetup(value);
  const {
    data,
    loading: loadingSelectedFolder,
    refetch: refetchFolder,
  } = useQuery(isSuser ? GET_FOLDER_BY_ID_BY_ORG : GET_FOLDER_BY_ID, {
    // skip: userSettings.lastRecordsFolder ? false : true, // If no data is saved, cancel the request.
    variables: isSuser
      ? {
          id: userSettings.lastIndexedFolder,
          organisationId: state.selectedOrg ? state.selectedOrg.id : selOrg.id,
        }
      : {
          id: userSettings.lastIndexedFolder,
        },
    onCompleted: () => {
      if (
        (data && data.Folder && data.Folder.folder) ||
        (data && data.FolderByOrg && isSuser)
      ) {
        setSelectedFolder(isSuser ? data.FolderByOrg : data.Folder.folder);
      }
    },
  });

  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };

  const getDataFromIDBAndUpdateTable = async (unindexedRecords: Row[]) => {
    console.log('Getting data from IDB and uploading table....');
    let records = unindexedRecords;
    let dataDB = await db.transaction('rw!', db.table('indexingScreen'), async () => {
      let dataDB = await Promise.all(
        unindexedRecords.map(async (record) => {
          return await db.table('indexingScreen').get({ recordID: record.id });
        })
      );
      return dataDB;
    });
    records = await checkIDBForExistingValues(unindexedRecords, dataDB);

    if (!records.length) {
      handleSetEditable();
    }
    setUnindexed([...records]);
  };

  const [loadUnindexed, { data: indexes, refetch }] = useLazyQuery(
    isSuser ? gqlIndexes.GET_UNINDEXED_BY_ORG : gqlIndexes.GET_UNINDEXED,
    {
      fetchPolicy: 'no-cache',
      variables: {
        folderId: selectedFolder ? selectedFolder.id : null,
        organisationId: isSuser
          ? state.selectedOrg
            ? state.selectedOrg.id
            : selOrg.id
          : null,
        limit,
        page: currentPage,
        orderByAscending: sorting,
      },
      onCompleted: async () => {
        if (indexes) {
          let data = isSuser ? indexes.UnindexedByOrgRecords : indexes.UnindexedRecords;
          let records: Row[] = isSuser ? [...data.records] : [...data.records];
          getDataFromIDBAndUpdateTable(records); // Check the DB to see if there is field values for each record
          setRecordCount(data.totalCount);
          setLoadingData(false);
          if (indexingScreenQuickView && selectedIndex !== '') {
            // closes the drawer if the file does not exist
            let existRecord = records.find((rec) => rec.id === selectedIndex.id);
            if (!existRecord) {
              setSelectedIndex('');
              setActiveAttachment(null);
              setIsDrawerOpen(false);
            }
          }
        }
      },
      onError: (err) => {
        if (err.graphQLErrors) {
          err.graphQLErrors.map(({ message }, i) => {
            if (message === 'You are not authorized!' || message === 'Invalid Token') {
              utils.clearUserData();
              setIsLoggedIn(false);
            }
          });
        }
      },
    }
  );
  const [ocrScan, { data: ocrded }] = useMutation(gqlIndexes.OCR_RECORDS, {
    onCompleted: async () => {
      if (ocrded) {
        window.log(ocrded);
        loadUnindexed();
      }
    },
  });

  const ocrScanRecords = (args = { preview: false, selected: [] }) => {
    let { preview, selected } = args;
    setLoadingData(true);
    let taskId = utils.generateTaskID(userData);
    let variables: variables['ocr'] = {
      taskId: taskId,
      preview,
      folderId: selectedFolder.id,
    };
    if (!preview) {
      addTask({
        id: taskId,
        successmsg: 'OCR scanning task completed.',
        folderName: selectedFolder?.name,
        action: preview ? 'ocr_records_preview' : 'ocr_records',
        ammount: selected && selected.length ? selected.length : recordCount,
        screen: 'indexing',
      });
    }

    if (selected && selected.length)
      variables.recordIds = selected.map((record) => {
        return { recordId: record, pageEnum: 'Front_Only' };
      });
    if (preview && selected) setRowLoader(selected);

    ocrScan({ variables }).then(({ data }) => {
      if (!preview) {
        db.table('indexingScreen').clear();
      }
      loadUnindexed();
    });
  };

  const clearSelection = () => {
    setScreenSelectedData(
      modifyLocalStorageObject(
        {
          ...screenSelectedData,
          indexingScreenSelected: { selectedRows: [], selected: [] },
        },
        `indexingScreenSelected`
      )
    );
  };

  const handleChangeFolder = (selected: SelectedFolder) => {
    clearSelection();
    let newVisibleHeaders = [];
    let newHeaders = selected.folderFields.map((field) => {
      newVisibleHeaders.push(field);
      return field;
    });
    setHeaders([...newHeaders]);
    setUserSettings(
      modifyLocalStorageObject(
        { ...userSettings, lastIndexedFolder: selected.id },
        'userSettings'
      )
    );
    setUserData(
      modifyLocalStorageObject(
        {
          ...userData,
          selFolder: selected,
        },
        'userData'
      )
    );
    setSelectedIndex('');
    setLoadingData(true);
    loadUnindexed();
    setSelectedFolder(selected);
  };
  let uploadFiles = 0;
  const handleUploadToS3: HandleUpladToS3 = async (
    url,
    file,
    id,
    scan = false,
    totalFiles,
    taskId
  ) => {
    try {
      const retries = 3; // amount of retries we're willing to do
      let options = {
        headers: {
          'Content-Type': file.type,
        },
      };
      for (let i = 0; i < retries; i++) {
        // if (i === 0 || i === 1 || i === 2) options = {}; // generates an error for testing
        try {
          const req = await axios.put(url, file, options);
          if (req && req.status === 200) {
            uploadFiles = uploadFiles + 1;
            rtcContext.imgUploadSuccess({ id, taskId });
            if (selectedFolder.ocrEnabled) {
              // If folder has ocr enabled, we immediately store its data into IDB to manage its "textract" key later
              let newStatus = {
                recordID: id,
                status: 'unindexed',
                fields: {},
                textract: false,
              };
              db.table('indexingScreen').add(newStatus);
            }
            break;
          } else {
            window.log('cannot fetch data');
          }
        } catch (error) {
          rtcContext.imgUploadFailed({ id, taskId });
          if (i === 2) {
            file.id = id;
            setErrorFiles((prevState) => [...prevState, file]);
          }
        }
      }
    } catch (e) {
      window.log(e);
    } finally {
      let totalRecords = errorFiles.length + uploadFiles;
      setSnackbar({
        status: true,
        snackbarMessage: `${uploadFiles} Files successfully uploaded`,
        severity: 'success',
      });
      if (selectedFolder.ocrEnabled) {
        setTimeout(() => {
          setSnackbar({
            status: true,
            snackbarMessage: `${uploadFiles} Files are being OCRed`,
            severity: 'success',
          });
        }, 3000);
      }
      setCount(totalRecords);
      if (totalFiles === totalRecords) {
        setLoadingFiles(false);
        setCount(0);
        setAnchorUploadEl(null);
      }
    }
  };

  const [createRecordMutation] = useMutation(
    isSuser ? gqlRecords.CREATE_RECORD_FOR_ORG : gqlRecords.CREATE_RECORD
  );
  const createRecord: CreateRecord = async (values, totalFiles, taskId) => {
    window.log(values);
    let variables = {
      data: values.data,
      attachments: values.attachments,
    };
    let suserVariables: any;
    if (isSuser) {
      suserVariables = {
        attachments: values.attachments,
        folderId: selectedFolder!.id,
        organisationId: state.selectedOrg ? state.selectedOrg.id : selOrg.id,
      };
    }
    return new Promise((resolve, reject) => {
      createRecordMutation({
        variables: suser ? suserVariables : variables,
      })
        .then(({ data }) => {
          if (values.scan) {
            resolve(isSuser ? data.addAttachmentToFolderInOrg : data.createRecord);
          } else {
            let url = isSuser
              ? data.addAttachmentToFolderInOrg.attachmentPutInfos[0].putUrl
              : data.createRecord.attachmentPutInfos[0].putUrl;
            handleUploadToS3(
              url,
              values.file,
              isSuser
                ? data.addAttachmentToFolderInOrg.record.id
                : data.createRecord.record.id,
              false,
              totalFiles,
              taskId
            );
            let record = isSuser
              ? data.addAttachmentToFolderInOrg.record
              : data.createRecord.record;
            resolve(record);
          }

          /*           let newUnIndexed = [...unIndexed];
          let record = isSuser
            ? data.createRecordForOrg.record
            : data.createRecord.record;
          let newField = {
            _typename: 'Fields',
            field: {
              _typename: 'Field',
              id: 'createdAtField',
              name: 'createdAt',
            },
            value: record.createdAt,
          };
          record.fields.push(newField);
          newUnIndexed.push(record);
          setUnindexed([...newUnIndexed]); */
        })
        .catch((e) => {
          reject(values);
        });
    });
  };
  // const [editRecordMutation] = useMutation(gqlRecords.EDIT_RECORD);
  // const editRecord = async (values) => {
  //   let variables = {
  //     recordId: values.recordId,
  //     fields: values.fields,
  //     updateRecord: { toBeIndexedBy: values.toBeIndexedBy },
  //   };
  //   editRecordMutation({
  //     variables,
  //   })
  //     .then(({ data }) => {
  //       if (data.updateRecord.record) {
  //         handleSetEditable();
  //       } else {
  //         setSnackbar({
  //           status: true,
  //           snackbarMessage:
  //             "The record hasn't been saved, make sure all required fields are filled.",
  //           severity: 'error',
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       setErrorStatus({ triggered: true, errorInfo: error });
  //       window.log(error);
  //     });
  // };

  const [updateRecordsAsync] = useMutation(gqlRecords.UPDATE_RECORD_ASYNC);
  const updateRecords: UpdateRecords = async (values) => {
    window.log(values);
    let taskId = utils.generateTaskID(userData);
    addTask({
      id: taskId,
      folderName: selectedFolder?.name,
      action: 'update_records',
      ammount: values.length,
      screen: 'indexing',
    });
    await updateRecordsAsync({
      variables: { recordsToUpdate: { updateRecordInputs: values }, taskId },
    })
      .then(({ data }) => {
        if (data.updateRecordsAsync.recordIds) {
          handleSetEditable();
          //loadUnindexed();
          setIsDrawerOpen(false);
        } else {
          setSnackbar({
            status: true,
            snackbarMessage:
              "The record hasn't been saved, make sure all required fields are filled.",
            severity: 'error',
          });
        }
      })
      .catch((error) => {
        setSnackbar({
          status: true,
          snackbarMessage: 'There was an error on the indexing.',
          severity: 'error',
        });
        //setErrorStatus({ triggered: true, errorInfo: error });
        window.log(error);
      });
  };

  const handleAssignTo: HandleAssignTo = async (values) => {
    let records: UpdateRecordValues[] = [];
    values.toBeIndexedBy.forEach((record) => {
      if (record.recordId) {
        const value: UpdateRecordValues = {
          recordId: record.recordId,
          toBeIndexedBy:
            record.toBeIndexedBy.length > 0 ? record.toBeIndexedBy : [userData.id],
          folderId: selectedFolder!.id,
          overwriteFields: false,
        };
        records.push(value);
      }
    });
    updateRecords(records);
    // window.log('indexes - values', values); let errors = false;
    // for (let i = 0; i < values.recordIds.length; i++) {
    //   const record = values.recordIds[i];
    //   let variables = {
    //     recordId: record,
    //     toBeIndexedBy: values.toBeIndexedBy,
    //   };
    //   editRecordMutation({
    //     variables,
    //   })
    //     .then(({ data }) => {
    //       window.log('data indexes', data);
    //       if (data.updateRecord.record) {
    //         handleSetEditable();
    //       }
    //     })
    //     .catch((error) => {
    //       setErrorStatus({ triggered: true, errorInfo: error });
    //       window.log(error);
    //     });
    // }
    // if (errors) {
    //   setSnackbar({
    //     status: true,
    //     snackbarMessage: "Some or all records weren't assigned, please try again later.",
    //     severity: 'error',
    //   });
    // } else {
    //   setSnackbar({
    //     status: true,
    //     snackbarMessage: 'Record/s assigned.',
    //     severity: 'success',
    //   });
    // }
  };

  const [deleteRecordMutation, { loading }] = useMutation(gqlRecords.DELETE_RECORD);
  const deleteRecords = async (values: string[], addNotification = true) => {
    window.log(selectedFolder);
    let taskId = utils.generateTaskID(userData);
    if (addNotification) {
      addTask({
        id: taskId,
        folderName: selectedFolder?.name,
        action: 'delete_records',
        ammount: values.length,
        screen: 'indexing',
      });
    }
    deleteRecordMutation({
      variables: {
        recordIds: values,
        taskId,
      },
    })
      .then(({ data }) => {
        clearSelection();
        setIsDrawerOpen(false);
      })
      .catch((error) => {
        window.log('e', error);
        if (addNotification) {
          removeTask({ id: taskId });
          setSnackbar({
            status: true,
            snackbarMessage: 'Permission denied, please contact the administrator',
            severity: 'error',
          });
        }
        // setErrorStatus({ triggered: true, errorInfo: error });
      });
  };

  /*End Delete Section*/
  const [scanBarcodeOnRecordsAsync] = useMutation(gqlRecords.SCAN_BARCODE_ASYNC, {
    onCompleted: (data) => {
      loadUnindexed();
    },
  });

  const scanBarcode: Scan = async (values = { preview: false, selected: [] }) => {
    let { selected, preview } = values;
    let allUnindexed = unIndexed.map((record) => {
      return record.id;
    });
    setLoadingData(true);
    if (!allUnindexed.length) {
      setIsScanning(false);
      setSnackbar({
        status: true,
        snackbarMessage: 'Please scan or upload a document first. The list is empty.',
        severity: 'error',
      });
    } else {
      let taskID = utils.generateTaskID(userData);

      let variables: variables['barcode'] = {
        taskId: taskID,
        folderId: selectedFolder.id,
      };

      // if has selected records, BE needs an array of their ids, otherwise, BE only needs folderID to scan all files

      if (selected && selected.length) variables.recordIds = selected;
      if (preview && selected) setRowLoader(selected);

      if (preview) {
        variables.preview = preview;
        /* addTask({
          id: taskID,
          folderName: selectedFolder?.name,
          action: 'scan_barcode_result',
          ammount: variables.recordIds.length,
          screen: 'indexing',
        }); */
        scanBarcodeOnRecordsAsync({ variables })
          .then(({ data }) => {
            /*  let newUnindexed = unIndexed;
            let recordResults = data.scanBarcodeOnRecords.recordsWithLink;
            let recordsFromResult = recordResults.map(
              (recordWithLink) => recordWithLink.record
            );
            recordResults = [...state.scanned, ...recordsFromResult];
            let parsedRecords = utils.parseScannedRecordsToSave(
              recordsFromResult,
              fieldsToSave
            );
            setFieldsToSave(parsedRecords);
            newUnindexed = newUnindexed.map((u_inx) => {
              for (let i = 0; i < recordsFromResult.length; i++) {
                const scanned = recordsFromResult[i];
                if (u_inx.id === scanned.id) {
                  window.log('match!!!!!');
                  u_inx.fields = scanned.fields;
                }
              }
              window.log(u_inx);
              return u_inx;
            });
            setUnindexed(newUnindexed);
            saveScanned(recordsFromResult);
            setIsScanning(false);
            if (data.scanBarcodeOnRecords.error) {
              setSnackbar({
                status: true,
                snackbarMessage: "Some Barcodes couldn't be scanned.",
                severity: 'warning',
              });
            } else {
              setSnackbar({
                status: true,
                snackbarMessage: 'Barcode scanning task sent.',
                severity: 'success',
              });
            } */
            //clearSelection();
          })
          .catch((e) => {
            setIsScanning(false);
            setSnackbar({
              status: true,
              snackbarMessage: 'Something went wrong, try again.',
              severity: 'error',
            });
          });
      } else {
        addTask({
          id: taskID,
          folderName: selectedFolder?.name,
          action: 'scan_barcode_result',
          ammount: selected && selected.length ? selected.length : recordCount,
          screen: 'indexing',
        });
        scanBarcodeOnRecordsAsync({ variables })
          .then(({ data }) => {
            setLoadingData(true);
            let newUnindexed = unIndexed;
            if (data.scanBarcodeOnRecordsAsync.error) {
              setSnackbar({
                status: true,
                snackbarMessage: "Some Barcodes couldn't be scanned.",
                severity: 'warning',
              });
            } else {
              setSnackbar({
                status: true,
                snackbarMessage: 'A Barcode-scan task has been scheduled.',
                severity: 'success',
              });
            }
            if (!newUnindexed.length) {
              handleSetEditable();
            }
            loadUnindexed();
            db.table('indexingScreen').clear();
          })
          .catch((error) => console.log('Error indexing by barcode', error))
          .finally(() => setLoadingData(false));
      }
      /* if (isSuser) {
        variables["organisationId"] = selOrg.id;
      } */
    }
  };
  const handleClickOnRecord = (row: Rows) => {
    const selectedRecord: Row[] = unIndexed.filter((un: Row) => un.id === row.id);
    row.folderId = selectedFolder;
    setSelectedIndex(selectedRecord[0]);
    setSelectedRecord(selectedRecord[0]);
    indexingScreenQuickView && setIsDrawerOpen(true);
    if (selectedRecord[0]) {
      const attachment = {
        folderId: selectedRecord[0].folderId?.id,
        name: selectedRecord[0]?.attachments[selectedRecord[0].attachments.length - 1]
          .name,
        id: selectedRecord[0].id,
      };
      setActiveAttachment(attachment);
    }
  };
  /*
   *   Mode managing functions
   *   This functions save in localstorage the current user setting
   *   regarding table modes
   */
  const handleSetEditable = () => {
    setQuickEdit(!indexingScreenQuickEdit);
    setSelectedIndex('');
  };
  const handleSetQuickView = () => {
    setQuickView(!indexingScreenQuickView);
  };

  const handleSetEditMode = () => {
    if (indexingScreenEditMode === 'cell') {
      setEditMode('row');
    } else {
      setEditMode('cell');
    }
  };
  const onEditRowsModelChange: DataGridProps['onEditRowsModelChange'] = (
    model,
    details
  ) => {
    let newValues: any, recordID;
    if (fieldsToSave.size) {
      newValues = fieldsToSave;
    } else {
      newValues = new Map();
    }

    const fieldData = getModifiedFieldsData(headers, model, newValues, copiedRows);
    let { modifiedFields, recordId, value } = fieldData;

    // Create the folderfield object to be sent to the api
    if (modifiedFields.length > 0) {
      modifiedFields = modifiedFields.map((col, index) => {
        let type = utils.determineFolderFieldType(col.type);
        let newCol = {};
        newCol[type] = value[index];
        newCol.id = col.id;
        return newCol;
      });

      let oldValues = new Map();

      modifiedFields.forEach(async (el, index) => {
        const object = Object.fromEntries(newValues.get(recordId).set(el.id, el)); // Convert data map to object
        recordID = Object.keys(model)[0];
        const data = await db.table('indexingScreen').get({ recordID: recordID }); // query if there is data from this record in db
        let newStatus = data ? data : {};
        newStatus = {
          ...newStatus,
          recordID,
          status: 'unindexed',
          fields: { ...object, [el.id]: el },
        };
        oldValues.set(newValues.get(el.id));
        //Check for old values to determine if we modify
        //the value on by assigning a value to the record map
        //in the fieldsToSave Map
        if (oldValues.id) {
          // if the latest value is an empty string e.g when you filled
          // a field and then decided to delete it afterwards
          // delete the entry of that field on the fieldToSave Map
          if (value[index] === '') {
            oldValues.delete(modifiedFields[index].id);
            if (data) db.table('indexingScreen').where({ recordID: recordID }).delete();
          } else {
            oldValues.set(modifiedFields[index].id, modifiedFields[index]);
          }
        } else if (value[index] !== '' && !data) {
          db.table('indexingScreen')
            .add(newStatus)
            .then((res) => window.log(res));
          newValues.get(recordId).set(el.id, el);
        } else if (data) {
          db.table('indexingScreen').where({ recordID: recordID }).modify(newStatus);
        }
      });
    }

    if (modifiedFields.length) {
      getOrderForProcessing(
        modifiedFields,
        recordID,
        processingOrder,
        fieldsToSave,
        selectedFolder,
        setProcessingOrder
      );
    }

    setFieldsToSave(newValues);
  };

  useEffect(() => {
    // query if there is indexing data in the DB
    const getData = async () => {
      const data = await db.table('indexingScreen').toArray();
      if (data.length > 0) {
        // Convert data object to MAP
        data.forEach((element) => {
          element.fields = new Map(Object.entries(element.fields));
        });
        const dataToSave = new Map(
          data.map((object) => {
            return [object.recordID, object.fields, object.status];
          })
        );
        setFieldsToSave(dataToSave);
      }
    };
    getData();
  }, []);

  const indexedDBValues = useLiveQuery(() => db.table('indexingScreen').toArray());
  // useEffect to watch if indexedDB has changed and upload the table (maybe it was changed manually)
  useEffect(() => {
    if (!indexes || !indexes.UnindexedRecords.records) return;
    getDataFromIDBAndUpdateTable(indexes.UnindexedRecords.records);
  }, [indexedDBValues]);

  const handleEditKeyPress = async (e, isClicked = false, rows) => {
    if ((e.key === 'Enter' || isClicked) && fieldsToSave) {
      e.preventDefault();
      let indexedRecords = [];
      const valuesArray = []; // Used to store all rows to be indexed, after the loop is pased to updateRecords()
      const failed = [];

      for (let [record, value] of fieldsToSave) {
        let selected = rows.map((row) => {
          if (row.id) {
            return row.id;
          } else return row;
        });
        if (selected.includes(record)) {
          let saveValues = fieldsToSave.get(record);
          let fieldMap = [];
          for (let [field, savedValue] of saveValues) {
            fieldMap.push(saveValues.get(field));
          }
          let canSave = true;

          headers.forEach((field) => {
            let isEdited = [];
            if (field.indexRequirements) {
              const regex = new RegExp(field.indexRequirements.regexMatch);
              for (let [fld, savedValue] of saveValues) {
                const enteredValue = Object.values(savedValue)[0];
                if (isString(enteredValue)) enteredValue.trim();
                if (fld === field.id) {
                  isEdited.push(true);
                  // Checks if the value match with the regex before save
                  if (!regex.test(enteredValue) || enteredValue === '') {
                    canSave = false;
                  }
                } else {
                  isEdited.push(false);
                }
              }
              if (field.indexRequirements.isMandatory && !isEdited.includes(true)) {
                canSave = false;
              }
            } else {
              for (let [fld, savedValue] of saveValues) {
                const enteredValue = Object.values(savedValue)[0];
                if (isString(enteredValue)) enteredValue.trim();
                if (fld === field.id) {
                  if (enteredValue === '') {
                    canSave = false;
                  }
                } else {
                  isEdited.push(false);
                }
              }
            }
          });
          let values = {
            folderId: selectedFolder.id,
            recordId: record,
            fields: fieldMap,
            overwriteFields: false,
          };

          if (canSave) {
            indexedRecords.push(record);
            valuesArray.push(values);
            // When record is indexing, removes it from DB and fieldsToSave map
            fieldsToSave.delete(record);
            db.table('indexingScreen').where({ recordID: record }).delete();
          } else {
            failed.push(values);
          }
        } else {
        }
      }

      const orderedValues = assignProcessingOrder(valuesArray);

      if (valuesArray.length) {
        setLoadingData(true);
        let orderedValuesWithParsedDate = orderedValues.map((ov) => {
          for (let i = 0; i < ov.fields.length; i++) {
            const f = ov.fields[i];
            if (Object.keys(f).includes('multistring')) {
              ov.fields[i].multistring.forEach(
                (
                  val: string // create a field for each multistring value
                ) => ov.fields.push({ id: ov.fields[i].id, stringValue: val })
              );
              let filtered = ov.fields.filter(
                (ff: FolderField) => !ff.hasOwnProperty('multistring')
              ); // remove multistring field
              ov.fields = [...filtered];
            }
            if (Object.keys(f).includes('dateValue')) {
              window.log(f.dateValue);
              let dayMonthYearDate = parse(f.dateValue, 'dd/MM/yyyy', new Date());
              if (isValid(dayMonthYearDate)) {
                ov.fields[i].dateValue = dayMonthYearDate;
              } else {
                ov.fields[i].dateValue = new Date(f.dateValue);
              }
            }
          }
          return ov;
        });
        window.log(orderedValuesWithParsedDate);
        await updateRecords(orderedValuesWithParsedDate);
      } else {
        setSnackbar({
          status: true,
          snackbarMessage:
            'There were no modified records or the records modified where not compliant',
          severity: 'error',
        });
      }
      let reducedList = unIndexed;
      for (let i = 0; i < indexedRecords.length; i++) {
        const record = indexedRecords[i];
        reducedList = reducedList.filter((index) => {
          if (index.id !== record) {
            return index;
          }
          return false;
        });
      }
      if (!reducedList.length) {
        handleSetEditable();
      }
      setFailedRecords(failed);
      setFieldsToSave(fieldsToSave);

      //setUnindexed(reducedList);
      //loadUnindexed();
    }
  };

  const assignProcessingOrder = (valuesArray) => {
    const arrayWithOrder = valuesArray;
    // loop processiongOrder state to get the recordsId, each value is an array
    // of ids with the same value as key
    for (const [key, value] of Object.entries(processingOrder)) {
      // loop the array of ids
      value.forEach((value, index) => {
        // loop the array to be sent to the backend, if the record id of the value
        // matches the current value, set orderProccesing property with the index as value
        valuesArray.forEach((row, rowIndex) => {
          if (row.recordId === value) {
            arrayWithOrder[rowIndex].orderForProcessing = index;
          }
        });
      });
    }

    return arrayWithOrder;
  };

  const [loadFolders, { data: loadedFolders }] = useLazyQuery(
    isSuser ? GET_FOLDERS_BY_ORG : GET_FOLDERS,
    {
      variables: isSuser
        ? {
            organisationId: state.selectedOrg ? state.selectedOrg.id : selOrg.id,
          }
        : null,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!isSuser ? loadedFolders.Folders.length : loadedFolders.FoldersByOrg.length) {
          setIsLoadingFolders(false);
          setVisible(true);
        } else {
          setIsLoadingFolders(false);
        }
      },
      onError: (err) => {
        if (err.graphQLErrors) {
          err.graphQLErrors.map(({ message }, i) => {
            if (message === 'You are not authorized!' || message === 'Invalid Token') {
              utils.clearUserData();
              setIsLoggedIn(false);
            } else {
              console.error(err);
              setErrorStatus({ triggered: true, errorInfo: err });
            }
          });
        }
      },
    }
  );
  const checkMainField = () => {
    const mainField = headers.filter((header) => header && header.mainField === true);
    if (mainField[0]) {
      return mainField[0].type === 'string';
    } else {
      return false;
    }
  };

  const handleChangeFolderToRecord = (modifiedRecords) => {
    updateRecords(modifiedRecords);
  };

  const clearFields = (selectedRows: any) => {
    const clearData = () => {
      if (!selectedRows.length) {
        db.table('indexingScreen')
          .toCollection()
          .modify((record) => (record.fields = {}));
      } else {
        db.table('indexingScreen')
          .toCollection()
          .modify((record) => {
            if (selectedRows.includes(record.recordID)) {
              return (record.fields = {});
            }
            return record;
          });
      }
    };
    clearData();
    setFieldsToSave([]);
    setLoadingData(true);
    loadUnindexed();
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setErrorFiles([]);
    setAnchorUploadEl(null);
    if (loadingFiles) setLoadingFiles(false);
  };
  const handleSortModelChange = (newModel) => {
    if (!newModel.length) {
      let parsedModel = [{ field: 'createdAt', sort: 'desc' }];
      setSorting(false);
    } else {
      setSorting(!sorting);
    }
  };
  useQuery(isSuser ? GET_USER_BY_ORG : GET_USER, {
    variables: isSuser
      ? {
          organisationId: state.selectedOrg ? state.selectedOrg.id : userData.selOrg.id,
          id: userData.id,
        }
      : { id: userData.id },
    onCompleted: (data) => {
      if (data && userData) {
        userData.suser
          ? setUserPermissions(null)
          : setUserPermissions(data.User.user.permissions);
      }
    },
  });
  useEffect(() => {
    window.log('recordToUpdate: ', recordToUpdate);
    if (Object.keys(recordToUpdate).length === 0) return;
    let parsedRecords = utils.parseRecordToUpdateToSave(recordToUpdate, fieldsToSave);
    setFieldsToSave(parsedRecords);
  }, [recordToUpdate]);
  useEffect(() => {
    if (errorFiles.length === 0) return;
    handleOpen();
    let IDs = errorFiles.map((file) => file.id);
    deleteRecords(IDs, false);
  }, [errorFiles]);
  useEffect(() => {
    setLoadingData(true);
    if (selectedFolder && selectedFolder.id) {
      loadUnindexed();
    }
    window.log('rtcStatus: ', rtcContext.state);
  }, [rtcContext.state.refetch]);
  useEffect(() => {
    setScreen('indexing');
    if (
      selectedFolder &&
      Object.keys(selectedFolder).length !== 0 &&
      selectedFolder.constructor === Object
    ) {
      setVisible(true);
      let newSelectedFolder = utils.addCreatedAtField(selectedFolder);
      let newHeaders = newSelectedFolder?.folderFields.filter((field) => field.active);
      // let mainFields = newSelectedFolder.folderFields.filter((field) => field.mainField);
      setHeaders([...newHeaders]);
      setLoadingData(true);
      loadUnindexed();
    }
    window.log(state.visible);
    if (!state.visible) {
      setIsLoadingFolders(true);
      loadFolders();
    }
  }, [selectedFolder, limit, currentPage, sorting]);

  if (state.visible) {
    return (
      <>
        <OCRSetupProvider>
          <FabricProvider>
            <ContainerWithDrawer
              setOpen={() => setIsDrawerOpen(true)}
              open={isDrawerOpen || !indexingScreenQuickView}
              hasAttachement={Boolean(activeAttachment)}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1.5,
                  width: '100%',
                  paddingRight: '20px',
                }}
              >
                {!ocrSetup && (
                  <Box
                    sx={{
                      backgroundColor: 'common.greyblueDark',
                      borderRadius: '4px',
                    }}
                  >
                    <FolderSelector
                      title="Folder Name"
                      handleChangeFolder={handleChangeFolder}
                      selectedFolder={selectedFolder}
                      setUserSettings={setUserSettings}
                      userSettings={userSettings}
                      setWSMTrigger={setVisible}
                    />
                  </Box>
                )}
                <Box sx={{ height: '100%', width: '100%', flex: 6 }}>
                  {headers.length && !ocrSetup && (
                    <ErrorHandler>
                      <MasterTable
                        rows={unIndexed}
                        setRows={setUnindexed}
                        rowCount={recordCount}
                        defaultVisibleRows={headers}
                        defaultTableOptions={{
                          quickView: indexingScreenQuickView,
                          edit: true,
                          search: false,
                          editMode: 'cell',
                        }}
                        setQuickView={handleSetQuickView}
                        onSortModelChange={handleSortModelChange}
                        // setEditMode={handleSetEditable}
                        editMode={indexingScreenEditMode}
                        setEditMode={handleSetEditMode}
                        onDeleteButton={deleteRecords}
                        createRecord={createRecord}
                        setErrorFiles={setErrorFiles}
                        setLoadingFiles={setLoadingFiles}
                        loadingFiles={loadingFiles}
                        selectedFolder={selectedFolder ? selectedFolder : null}
                        setPageSize={setLimit}
                        pageSize={limit}
                        setSorting={setSorting}
                        sorting={sorting}
                        loading={isLoadingData}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        onSaveRows={handleEditKeyPress}
                        count={count}
                        setAnchorUploadEl={setAnchorUploadEl}
                        anchorUploadEl={anchorUploadEl}
                        extraActions={{
                          ocr: ocrScanRecords,
                          scan: scanBarcode,
                          assignTo: handleAssignTo,
                          saveSelected: (e, selected) => {
                            handleEditKeyPress(e, true, selected);
                          },
                          changeFolderToRecord: (selected, newFolder) =>
                            handleChangeFolderToRecord(selected, newFolder),
                        }}
                        onClickedRow={handleClickOnRecord}
                        onEditRowsModelChange={onEditRowsModelChange}
                        notesRefetch={loadUnindexed}
                        copiedRows={copiedRows}
                        setCopiedRows={setCopiedRows}
                        setActiveAttachment={setActiveAttachment}
                        setIsDrawerOpen={setIsDrawerOpen}
                        rowsToUpdate={recordToUpdate}
                        refetchFolder={handleChangeFolder}
                        clearFields={clearFields}
                      />
                    </ErrorHandler>
                  )}

                  {headers.length && ocrSetup && (
                    <OCRTemplateSetup ocrScanRecords={ocrScanRecords} />
                  )}
                  {!headers.length && (
                    <Box
                      sx={{
                        backgroundColor: 'common.greyblueDark',
                        height: '100%',
                        borderRadius: '5px',
                        color: 'white',
                      }}
                    >
                      <Box
                        sx={{
                          right: '10px',
                          top: '10px',
                          textAlign: 'right',
                          position: 'relative',
                        }}
                      >
                        <FontAwesomeIcon
                          size="36px"
                          color="#fff"
                          icon="fas fa-angle-double-up"
                        />
                      </Box>
                      <Box
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <FontAwesomeIcon
                          icon="fas fa-exclamation-circle"
                          size="36px"
                          color="#fff"
                        />
                        <Typography variant="tab">
                          Please select a folder to start.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <CustomDialog isOpen={isScanning} title="Indexing By Barcode">
                <>
                  Please wait while we index the documents
                  <LinearProgressBar open={isScanning} />
                </>
              </CustomDialog>
              {isDrawerOpen && indexingScreenQuickView ? (
                <CreationDrawer
                  folder={selectedFolder}
                  fullScreenRecord={true}
                  goBack={() => setIsDrawerOpen(false)}
                  open={isDrawerOpen || !indexingScreenQuickView}
                  title={'Preview record'}
                  screen="indexing"
                  attachment={activeAttachment}
                  activeRecordId={activeAttachment?.id || ''}
                  toggleOCRSetup={toggleOCRSetup}
                  ocrScanRecords={ocrScanRecords}
                />
              ) : null}
            </ContainerWithDrawer>
          </FabricProvider>
        </OCRSetupProvider>
        {errorFiles && (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Alert
              sx={alertStyles}
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  sx={{ margin: 0 }}
                  onClick={handleClose}
                >
                  OK
                </Button>
              }
            >
              <AlertTitle sx={{ marginBottom: 2, fontWeight: 500, fontSize: 15 }}>
                Error uploading these files
              </AlertTitle>
              {errorFiles.map((error) => (
                <Typography key={error.name} sx={{ fontWeight: 500 }}>
                  - {error.name}
                </Typography>
              ))}
            </Alert>
          </Modal>
        )}
        <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
      </>
    );
  } else {
    return (
      <WholePageMessage
        isLoading={isLoadingFolders}
        message="Please Contact your Administrator of the System to gain access to
    Folders"
        title="No Folders found"
      />
    );
  }
}

export default Indexing;
