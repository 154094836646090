import React, { useState } from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-apollo';
import StatisticBarChart from './components/StatisticChart/StatisticBarChart';
import { GET_FOLDERS, GET_FOLDERS_BY_ORG } from '../../utils/gql/gqlFolders';

import { AuthContextTypes, Context as AuthContext } from '../../context/authContext';
import { Context as StatusContext } from '../../context/statusContext';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import ToolbarBase from '../../components/toolbarScreen/ToolbarBase';
import ToolbarButtonLeft from '../../components/toolbarScreen/ToolbarButtonLeft';
import ToolbarTitle from '../../components/toolbarScreen/ToolbarTitle';
import { Box, Stack, Typography } from '@mui/material';
import AreaChartGrid from './components/DataGrid/AreaChartGrid';
import DashTable from './components/dashTable/DashTable';
import MultiLineChart from './components/multiLineChart/MultiLineChart';
import FolderSwitcherMain from './components/FolderSwitcherMain';

import { folderDePrueba3 } from './MockupData';
import FontAwesomeIcon from '../../components/FAIcon';
import ToolbarActionRight from '../../components/toolbarScreen/ToolbarActionRight';

import DashboardIcon from '@material-ui/icons/Dashboard';
import NoFolders from './components/NoFolders';
import { StatusContextTypes } from '../../context/statusContextTypes';

export type Frecuency = {
  type: string;
  points: number;
};

export interface Data {
  name: string;
  id: string;
  frecuency: string;
  data: {
    date: string;
    value: {
      scanned: number;
      deleted: number;
      indexed: number;
    };
  }[];
}

const DashboardMain = () => {
  const [data, setData] = useState<Data | null>(null);
  const [gridLayout, setGridLayout] = useState<number>(2);
  const { state, setVisible, setErrorStatus, setWorkflow } = useContext(
    StatusContext
  ) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;
  let folders;

  const { data: loadedFolders } = useQuery(isSuser ? GET_FOLDERS_BY_ORG : GET_FOLDERS, {
    variables: isSuser
      ? {
          organisationId:
            state.selectedOrg && typeof state.selectedOrg === 'object'
              ? state.selectedOrg.id
              : selOrg.id,
        }
      : null,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!isSuser ? loadedFolders.Folders.length : loadedFolders.FoldersByOrg.length) {
        setVisible(true);
      }
    },
    onError: (error) => {
      setErrorStatus({ triggered: true, errorInfo: error });
      console.error(error);
    },
  });

  if (loadedFolders) {
    folders = !isSuser ? loadedFolders.Folders : loadedFolders.FoldersByOrg;
  }

  const getFolderData = (id: string, type: string, frecuency: Frecuency) => {
    type Peticion = {
      folderId: string;
      content: { type: string; values: Frecuency }[];
      organisationId?: string | { id: string; name?: string };
    };

    const peticion: Peticion = {
      folderId: id,
      content: [
        { type: 'scanned', values: frecuency },
        { type: 'indexed', values: frecuency },
        { type: 'deleted', values: frecuency },
      ],
    };
    if (isSuser) peticion['organisationId'] = state.selectedOrg;
    // refetch({ variables: peticion });
    window.log(peticion);
    setData(folderDePrueba3); // setData(dataFolder)
  };
  return (
    <Box height="100%">
      <Box sx={{ margin: '12px 0' }}>
        <ToolbarBase hasBackground={false} elevation={0}>
          <ToolbarTitle
            title={isSuser ? selOrg.name : "Company's Name"}
            hasBackground={false}
            fontSize={25}
          />
          {folders && folders.length ? (
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography
                variant="p"
                sx={{
                  fontSize: 13,
                  color: '#8b979f7d',
                  fontWeight: 500,
                  marginTop: '.5px',
                }}
              >
                Select Folder:
              </Typography>
              <FolderSwitcherMain folders={folders} getFolderData={getFolderData} />
            </Stack>
          ) : (
            ''
          )}
          <ToolbarActionRight
            actions={[
              {
                tooltipTitle: 'Change dashboard visualization',
                buttonIcon: <DashboardIcon />,
                buttonOnClick: () =>
                  setGridLayout((prevState) => (prevState === 2 ? 1 : 2)),
              },
            ]}
            hasBackground={false}
          />
        </ToolbarBase>
      </Box>
      {folders && !folders.length && <NoFolders />}
      {folders && folders.length && gridLayout === 1 && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(250px, 1fr))',
            gridTemplateAreas: {
              xs: `'one one' 'two two' 'three three' 'four four'`,
              lg: `'one two' 'four two' 'four three' `,
            },
            gap: 1,
            height: '100%',
          }}
        >
          <Box sx={{ gridArea: 'one' }}>
            <AreaChartGrid
              folders={folders}
              selectedOrg={
                state.selectedOrg && typeof state.selectedOrg === 'object'
                  ? state.selectedOrg.id
                  : selOrg.id
              }
              gridLayout={gridLayout}
            />
          </Box>
          <Box sx={{ gridArea: 'two', height: 'auto' }}>
            <StatisticBarChart folders={folders} />
          </Box>
          <Box sx={{ gridArea: 'three', height: 'auto' }}>
            <MultiLineChart folders={folders} />
          </Box>
          <Box sx={{ gridArea: 'four', maxHeight: '600px' }}>
            <DashTable />
          </Box>
        </Box>
      )}
      {folders && folders.length && gridLayout === 2 && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(250px, 1fr))',
            gridTemplateAreas: {
              xs: `'one one' 'two two' 'three three' 'four four'`,
              lg: `'one one' 'two three' 'four four'`,
            },
            gap: 1,
            height: '100%',
          }}
        >
          <Box sx={{ gridArea: 'one' }}>
            <AreaChartGrid
              folders={folders}
              selectedOrg={state.selectedOrg ? state.selectedOrg.id : selOrg.id}
            />
          </Box>
          <Box sx={{ gridArea: 'two', height: '30rem' }}>
            <StatisticBarChart folders={folders} />
          </Box>
          <Box sx={{ gridArea: 'three', height: '30rem' }}>
            <MultiLineChart folders={folders} />
          </Box>
          <Box sx={{ gridArea: 'four', paddingBottom: 8 }}>
            <DashTable />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DashboardMain;
