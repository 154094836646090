import React from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';
import ShareIcon from '@material-ui/icons/Share';
import { Collection } from './Popover';

interface ToolbarProps {
  isShareMenuOpen: boolean;
  toggleShareMenu: () => void;
  clearCollectionBin: () => void;
  collection: Collection[];
}

const Toolbar = ({
  isShareMenuOpen,
  toggleShareMenu,
  clearCollectionBin,
  collection,
}: ToolbarProps) => {
  return (
    <Stack
      flexDirection="row"
      justifyContent={'space-between'}
      alignItems="center"
      padding={1}
    >
      <Typography
        sx={{
          color: 'white.main',
          fontSize: '.9rem',
          padding: '10px',
          fontWeight: 700,
        }}
        variant="subtitle3"
      >
        Collection Bin
      </Typography>
      <Box>
        <Tooltip title={isShareMenuOpen ? 'List' : 'Share'} arrow>
          <span>
            <IconButton
              onClick={() => {
                toggleShareMenu();
              }}
              sx={{
                color: 'white.main',
                '&:hover': {
                  color: 'common.greyblueDark',
                  backgroundColor: 'common.white',
                  borderColor: '#0062cc',
                  boxShadow: 'none',
                },
              }}
              aria-label="Share"
              disabled={collection.length === 0 ? true : false}
            >
              {isShareMenuOpen ? (
                <FontAwesomeIcon icon="fa-solid fa-list-ul" size={14} />
              ) : (
                <ShareIcon style={{ fontSize: 15 }} />
              )}
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Clear All" arrow>
          <span>
            <IconButton
              data-testid="collection-bin-clear-all"
              sx={{
                color: 'white.main',
                '&:hover': {
                  color: 'common.negative',
                  backgroundColor: 'common.white',
                  borderColor: '#0062cc',
                  boxShadow: 'none',
                },
              }}
              aria-label="Clear all"
              onClick={() => {
                clearCollectionBin();
                if (isShareMenuOpen) {
                  toggleShareMenu();
                }
              }}
              disabled={collection.length === 0 ? true : false}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash" size={14} />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default Toolbar;
