import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListSubheader,
  Paper,
  Tooltip,
} from '@mui/material';
import React from 'react';
import FontAwesomeIcon from '../../components/FAIcon';
import { FormValues } from './CreateNotificationTab';
import ListItemOrg from './ListItemOrg';
import { UseFormResetField, UseFormRegister, UseFormGetValues } from 'react-hook-form';
import { OrganizationItemList } from '../../screens/Communication';

interface UsersListProps {
  orgList: OrganizationItemList[];
  register: UseFormRegister<FormValues>;
  usersList: string[];
  setUsersList: React.Dispatch<React.SetStateAction<string[]>>;
  resetField: UseFormResetField<FormValues>;
  blackList: string[];
  setBlackList: React.Dispatch<React.SetStateAction<string[]>>;
}

const UsersList = ({
  orgList,
  register,
  usersList,
  setUsersList,
  resetField,
  blackList,
  setBlackList,
}: UsersListProps) => {
  const [allChecked, setAllChecked] = React.useState<boolean>(false);
  const [orgsChecked, setOrgsChecked] = React.useState<string[]>([]);

  const clearAll = () => {
    setUsersList([]);
    setBlackList([]);
    setOrgsChecked([]);
    setAllChecked(false);
    resetField('sendToAllUsers');
  };

  const handleClick = () => {
    if (allChecked) {
      clearAll();
    } else {
      let orgsID = orgList.map((org) => org.id);
      setOrgsChecked(orgsID);
      setBlackList([]);
      setAllChecked(!allChecked);
    }
  };

  return (
    <Paper elevation={10} sx={{ width: '350px', height: '100%' }}>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <List
          sx={{ width: '100%', maxWidth: 360 }}
          component="nav"
          aria-labelledby="user-list"
          subheader={
            <ListSubheader
              component="div"
              id="user-list"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 0,
                padding: '0 8px',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? 'black.light' : 'common.greyblueDarker',
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'common.white'
                    : 'common.greyblueDarker',
                height: '50px',
                boxShadow: '0px 15px 13px -10px #00000030',
              }}
            >
              Users List
              <Box sx={{ display: 'flex', gap: 1.5 }}>
                <Tooltip title="Check all users" arrow>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={handleClick}
                        checked={allChecked}
                        disableRipple
                        {...register('sendToAllUsers')}
                        size="small"
                        sx={{
                          p: 0,
                          paddingRight: 0.5,
                          '&.Mui-checked': { color: 'common.greyblueDarker' },
                        }}
                      />
                    }
                    sx={{ margin: 0 }}
                    label="All"
                  />
                </Tooltip>
                <Tooltip title="Clear All" arrow>
                  <span>
                    <IconButton
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === 'dark'
                            ? 'common.white'
                            : 'common.greyblueDarker',
                        '&:hover': {
                          color: 'common.negative',
                          backgroundColor: 'common.white',
                        },
                      }}
                      aria-label="Clear all"
                      onClick={clearAll}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-eraser" size={14} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </ListSubheader>
          }
        >
          <Box
            sx={{
              height: 'calc(100% - 45px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '4px',
              },

              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
              },

              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#79909f',
                borderRadius: '4px',
              },
            }}
          >
            {orgList.length > 0 &&
              orgList.map((org) => (
                <ListItemOrg
                  key={org.id}
                  org={org}
                  usersList={usersList}
                  setUsersList={setUsersList}
                  allChecked={allChecked}
                  blackList={blackList}
                  setBlackList={setBlackList}
                  orgsChecked={orgsChecked}
                  setOrgsChecked={setOrgsChecked}
                  setAllChecked={setAllChecked}
                  resetField={resetField}
                />
              ))}
          </Box>
        </List>
      </Box>
    </Paper>
  );
};
export default UsersList;
