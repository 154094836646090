import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { TheUser, User } from '../masterTableTypes';
import { Chip } from '@mui/material';

interface UploadDialogUserListProps {
  options: User[];
  setSelectedUsers: (users: User[]) => void;
  theUser: User[];
}
export default function UploadDialogUserList(props: UploadDialogUserListProps) {
  const { options, setSelectedUsers, theUser } = props;
  const initialUsers = theUser[0] ? [theUser[0]] : [];
  const [users, setUsers] = React.useState<User[]>(initialUsers); // the selected users are stored here first when the input changes

  // disables the chip when there is only one value
  const disableChip = () => {
    return users.length === 0 || users.length === 1 ? true : false;
  };

  // Both useffect sets the selected users in the parent variable to be used
  // In the first render sets the option 0 to be the default selected user
  React.useEffect(() => {
    setSelectedUsers(users);
  }, []);

  // Sets the selected users in the parent users variables
  React.useEffect(() => {
    setSelectedUsers(users);
  }, [users]);

  return (
    <Autocomplete
      sx={{
        margin: '10px 0',
        '& .MuiSvgIcon-root': {
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.greyblueDarker,
        },
      }}
      multiple
      disableClearable
      id="tags-standard"
      options={options}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      defaultValue={theUser}
      onChange={(e, val, reason) => {
        setUsers(val);
      }}
      ChipProps={{
        disabled: disableChip(),
      }}
      size="small"
      limitTags={2}
      getOptionDisabled={(option) =>
        (!users.length && option === options[0]) ||
        (users.length === 1 && option === users[0])
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            label="Assign this files to be indexed by"
            placeholder="Assign another user..."
          />
        );
      }}
    />
  );
}
