import { Box, Container, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import FontAwesomeIcon from '../../components/FAIcon';

interface Props {
  endDate: string;
  message?: string;
}

const ShowMaintenance = (props: Props) => {
  const { endDate, message } = props;
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#D9D9D9',
      }}
    >
      <Stack
        spacing={8}
        alignItems="center"
        sx={{
          color: '#5A5A5A',
          textAlign: 'center',
        }}
      >
        <FontAwesomeIcon icon="fas fa-tools" size={'150px'} color="#c6c6c6" />

        <Stack spacing={4} alignItems="center">
          <Typography
            fontSize={'16px'}
            sx={{
              maxWidth: '250px',
            }}
          >
            {message
              ? message
              : `Datacapture is currently under a scheduled maintenance or update. 
              Please come back in`}
          </Typography>
          <CountdownTimer endDate={endDate} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ShowMaintenance;
