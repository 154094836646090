import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Signin from './screens/Signin';
import Dashboard from './screens/Dashboard';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { ThemeProvider as ThemeProviderV5, createTheme } from '@mui/material/styles';
import { createGenerateClassName } from '@material-ui/core/styles';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { generateTheme, generateThemeV4 } from './components/theme';
import { Context as StatusContext } from './context/statusContext';
import { Provider as RTCProvider } from './context/RTCContext';
import { Provider as TasksProvider } from './context/tasksContext';
import { Provider as TableProvider } from './context/tableContext';
import ErrorMessage from './utils/errorMessage';
import Welcome from './screens/Welcome';
import SharingScreen from './screens/Sharing';
import Maintenance from './screens/InMaintenance/InMaintenance';
import { isWithinInterval } from 'date-fns';
import useMaintenance from './hooks/useMaintenance';
import ShowMaintenance from './screens/InMaintenance/ShowInMaintenance';

// this is to avoid errors with v5 and v4 classnames
const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'mui-jss',
});

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    height: 100%;
    margin: 0;
  }
  #root {
    height: 100%;
    width: 100%;
  }
`;
const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL || 'https://api.datacapture.ie/graphql', //'http://localhost:7777/graphql', //https://api.datacapture.ie/graphql

  credentials: 'include',
});

function App() {
  const { state } = useContext(StatusContext);

  const theme = generateTheme((state && state.paletteMode) || 'light');
  const themeV4 = generateThemeV4((state && state.paletteMode) || 'light');

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={themeV4}>
        <ThemeProviderV5 theme={theme}>
          <RTCProvider>
            <TableProvider>
              <ApolloProvider client={client}>
                <TasksProvider>
                  <GlobalStyle />
                  <ErrorMessage />
                  <Switch>
                    {/* <Route exact path="/" component={Login}></Route> */}
                    <Route exact path="/" component={Signin}></Route>
                    <Route path="/dash" component={Dashboard}></Route>
                    <Route path="/welcome" component={Welcome} />
                    <Route path="/shared" component={SharingScreen} />
                    <Route render={() => <h1>404: page not found</h1>} />
                  </Switch>
                </TasksProvider>
              </ApolloProvider>
            </TableProvider>
          </RTCProvider>
        </ThemeProviderV5>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
