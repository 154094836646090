export type OcrTemplateInput = {
  templateName: string;
  pagesToRead: PagesToReadEnum;
  templateIdentifiers: TemplateIdentifierInput[];
  folderId: string;
  templateDetails: TemplateDetailsInput;
  folderFieldForTemplateName: string;
  imageUsedAsTemplate: string;
};

export type TemplateIdentifierInput = {
  identifierText: string;
  boundsToFindIdentifier?: BoundingBoxInput;
};

export type TemplateDetailsInput = {
  templateOcrFieldDefinitions: [OcrFieldDefinitionsInput];
};

export type UpdateOcrFieldDefinitionsInput = {
  folderField: string;
  textractRequirements: TextractRequirementsInput;
};

export type OcrFieldDefinitionsInput = {
  folderField: string;
  textractRequirements: TextractRequirementsInput;
};

export interface TextractRequirementsInput {
  regexMatch: string;
  textType: TextTypeEnum;
  blockType: BlockTypeEnum;
  boundingBox: BoundingBoxInput;
  confidenceMinimum: number;
  postRegexProcessing: PostRegexProcessingInput;
  headerValuePair: HeaderValuePairInput[];
}

export type BoundingBoxInput = {
  top: number;
  left: number;
  height: number;
  width: number;
};

export enum PagesToReadEnum {
  FrontAndBack = 'Front_And_Back',
  FrontOnly = 'Front_Only',
  All = 'All',
}

export interface OCRSetup {
  isActive: boolean;
  toggleOCRSetup: () => void;
  areaSelectionActive: boolean;
  toggleAreaSelection: () => void;
  scale: number;
  scaleUp: () => void;
  scaleDown: () => void;
  zoomPluginInstance: any;
  thumbnailPluginInstance: any;
}

export interface HeaderValuePairInput {
  textToFind: string;
  textType: TextTypeEnum;
  blockType: BlockTypeEnum;
  textToFindConfidenceMinimum?: number;
  boxToFindValue: BoundingBoxInput;
  pointToDrawValueBox?: PagePointInput;
  textToReadUntil?: string;
}

export enum TextTypeEnum {
  PRINTED = 'PRINTED',
  HANDWRITING = 'HANDWRITING',
}

export enum BlockTypeEnum {
  PAGE = 'PAGE',
  LINE = 'LINE',
  WORD = 'WORD',
}

export interface PagePointInput {
  top: number;
  left: number;
}

export interface PostRegexProcessingInput {
  regexSearch: string;
  postRegexType: PostRegexTypeEnum;
  replaceWith: string;
}

export enum PostRegexTypeEnum {
  SELECT = 'SELECT',
  REPLACE = 'REPLACE',
  IGNORE = 'IGNORE',
}
