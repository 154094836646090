import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import gqlEmails from '../../utils/gql/gqlEmails';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Context as StatusContext } from '../../context/statusContext';
import utils from '../../utils/utils';
import { CustomButton } from './sharePopOver';
import { StatusProps } from '../SnackBar';
import { Box, Chip, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';

const schema = yup.object().shape({
  recipients: yup.string().email().required(),
  subject: yup.string().required(),
  body: yup.string(),
});

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0.5,
    margin: 0,
  },
  progress: {
    height: '10px',
  },
  chip: {
    margin: 0.5,
  },
  pageContainer: {
    marginTop: '20px',
  },
};

interface PDFEmailing {
  fab: CustomButton;
  setSnackbar: React.Dispatch<React.SetStateAction<StatusProps>>;
}

interface emailValues {
  body: string;
  pages: string;
  recipient: string[];
  recipients: string;
  subject: string;
}

export default function PDFEmailing({ fab, setSnackbar }: PDFEmailing) {
  const { state } = useContext(StatusContext);
  const pages = window.localStorage.getItem('pdfPages');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [createEmailMutation, { loading }] = useMutation(gqlEmails.SEND_EMAIL);

  const [chipData, setChipData] = useState([state.selectedRecord]);

  const createEmail = (
    values: emailValues,
    recordIds: { pagesToSend: number[]; recordId: string }
  ) => {
    return createEmailMutation({
      variables: {
        to: values.recipients,
        subject: values.subject,
        body: values.body,
        recordIds: recordIds,
        html: `<p>${values.body}</p>`,
      },
    });
  };

  const onEmailCreation = async (values: emailValues) => {
    let regexPageRange = new RegExp('^[0-9]+(?:(?:s*,s*|-)[0-9]+)*$');
    if (!values.recipients || !values.subject) {
      setSnackbar({
        status: true,
        snackbarMessage: `Please add a recipient and a subject.`,
        severity: 'error',
      });
    } else {
      let newPages;
      if (!values.pages) {
        newPages = utils.sanitizePagesInput(`1-${pages}`);
      } else {
        if (regexPageRange.test(values.pages)) {
          newPages = utils.sanitizePagesInput(values.pages);
        } else {
          setSnackbar({
            status: true,
            snackbarMessage: `Some pages you selected are out of range, the document only has ${pages} pages`,
            severity: 'error',
          });
        }
      }
      // window.log(newPages);
      if (!utils.checkOutOfRange(newPages, pages)) {
        let recordIds = [{ recordId: state.selectedRecord.id, pagesToSend: newPages }];
        // window.log(recordIds);
        let emailAddresses = [];
        emailAddresses.push(values.recipients);

        createEmail({ ...values, recipient: emailAddresses }, recordIds)
          .then(({ data }) => {
            // window.log('Created Email....', data);
            setSnackbar({
              status: true,
              snackbarMessage: `Email sent.`,
              severity: 'success',
            });
          })
          .catch((e) => {
            window.log(e);
          });
      } else {
        setSnackbar({
          status: true,
          snackbarMessage: `Some pages you selected are out of range, the document only has ${pages} pages`,
          severity: 'error',
        });
      }
    }
  };
  const customButton = {
    color: 'primary',
    icon: <FontAwesomeIcon icon="fas fa-paper-plane" />,
    label: 'Send Email',
    onClick: handleSubmit(onEmailCreation),
  };
  useEffect(() => {
    setChipData([state.selectedRecord]);
  }, [state]);
  return (
    <>
      <Grid container direction="column">
        <div>
          <form onSubmit={handleSubmit(onEmailCreation)}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  {...register('recipients')}
                  size="small"
                  fullWidth
                  label="Recipients"
                  name="recipients"
                  type="email"
                  variant="outlined"
                  error={errors?.recipients ? true : false}
                  helperText={errors?.recipients && 'Please add a valid email'}
                />
              </Grid>
              <Grid item>
                <TextField
                  {...register('subject')}
                  size="small"
                  fullWidth
                  label="Subject"
                  name="subject"
                  variant="outlined"
                  defaultValue="Please find your documents attached"
                  error={errors?.subject ? true : false}
                  helperText={errors?.subject && 'Please add a subject'}
                />
              </Grid>

              <Grid item>
                <TextField
                  {...register('body')}
                  size="small"
                  fullWidth
                  multiline
                  id="body"
                  label="Body"
                  name="body"
                  type="text"
                  rows={5}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </div>
        <Box sx={styles.pageContainer}>
          <TextField
            {...register('pages')}
            size="small"
            fullWidth
            label="Page Range"
            name="pages"
            type="text"
            variant="outlined"
          />
          <Typography variant="caption">
            Enter page numbers and/or page ranges separated by comas (e.g. 2,5-8)
          </Typography>
        </Box>

        <Typography variant="caption">
          The pages are going to be sent as one PDF file.
        </Typography>
        {loading ? (
          <>
            <Grid item>
              <LinearProgress sx={styles.progress} />
            </Grid>
          </>
        ) : null}
        <Grid item>
          <Box component="ul" sx={styles.root}>
            {chipData.map((record, i) => {
              const icon = <AttachFileIcon />;
              if (record) {
                const mainField = record.fields.filter((field) => {
                  if (record.folderId.primaryFolderFieldId === field.field.id) {
                    return field;
                  } else return null;
                });
                if (mainField[0]) {
                  return (
                    <li key={i}>
                      <Chip
                        icon={icon}
                        label={`${mainField[0].value}`}
                        sx={styles.chip}
                      />
                    </li>
                  );
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })}
          </Box>
        </Grid>
        {fab(customButton)}
      </Grid>
    </>
  );
}
