import { useState, useEffect } from 'react';

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

const useDateRange = (days: number, isForwardDate: boolean): DateRange => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    let endDate = new Date();
    let startDate = new Date();

    if (isForwardDate) {
      endDate.setDate(startDate.getDate() + days);
    } else {
      startDate.setDate(endDate.getDate() - days);
    }

    setDateRange({ startDate, endDate });
  }, [days, isForwardDate]);

  return dateRange;
};

export default useDateRange;
