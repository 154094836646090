import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

const CustomDialog = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  children,
  confirmBtnText = 'Continue',
  cancelBtnText = 'Cancel',
  handleContinue,
  loading,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {handleContinue ? (
            <>
              <Button
                disabled={loading}
                color="default"
                variant="outlined"
                onClick={handleClose}
              >
                {cancelBtnText}
              </Button>

              <Button
                disabled={loading}
                color="secondary"
                onClick={handleContinue}
                variant="contained"
                style={{ color: '#fff' }}
              >
                {confirmBtnText}
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

CustomDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default CustomDialog;
