import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  FormControl,
  Button,
  Box,
  MenuItem,
  Modal,
  Typography,
  IconButton,
} from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import { styled } from '@mui/material/styles';
import UploadDialogUserList from './UploadDialogUserList';
import { keyframes } from '@mui/system';
import FontAwesomeIcon from '../../FAIcon';
import {
  AnchorUploadEl,
  HandleUpload,
  IsUploadFromDrag,
  MasterTableProps,
  SetAnchorUploadEl,
  TheUser,
  User,
  Users,
} from '../masterTableTypes';

const style = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 10,
  p: 3,
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledBox = styled(Box)(({ theme }) => ({
  '& svg, p': {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.greyblueDark,
  },
}));

interface UploadDialogProps {
  onClose: MasterTableProps['setAnchorUploadEl'];
  anchorEl: MasterTableProps['anchorUploadEl'];
  files: File[];
  handleFilesChange: (files: File[]) => void;
  handleUpload: HandleUpload;
  isUploadFromDrag: IsUploadFromDrag;
  users: Users;
  theUser: TheUser[];
  selectedUsers: User[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  loadingFiles: MasterTableProps['loadingFiles'];
  count: MasterTableProps['count'];
}
export default function UploadDialog(props: UploadDialogProps) {
  const {
    onClose,
    anchorEl,
    handleFilesChange,
    files,
    handleUpload,
    isUploadFromDrag,
    users,
    theUser,
    selectedUsers,
    setSelectedUsers,
    loadingFiles,
    count,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: false,
    title: '',
    message: '',
  });
  const displayUsers = () => {
    return (
      <UploadDialogUserList
        options={users}
        setSelectedUsers={setSelectedUsers}
        theUser={theUser}
      ></UploadDialogUserList>
    );
  };

  const handleClose = () => {
    onClose(null);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const filesLimit = 200;

  const open = Boolean(anchorEl);
  return (
    <>
      <Popover
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: isUploadFromDrag ? 'center' : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: isUploadFromDrag ? 'center' : 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? theme.palette.black.dark : undefined,
          },
        }}
      >
        <StyledBox
          sx={{
            width: isUploadFromDrag ? '80vw' : '300px',
            height: isUploadFromDrag ? '74vh' : 'auto',
            // maxHeight: '450px',
            padding: 3,
            '& .chip-container': {
              maxHeight: '190px',
              '& span': {
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'common.white'
                    : 'common.greyblueDarker',
              },
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '15px',
              },

              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
              },

              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#79909f',
                borderRadius: '4px',
              },
            },
          }}
        >
          <DropzoneArea
            previewGridClasses={{ container: 'chip-container' }}
            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
            useChipsForPreview={true}
            showFileNames={true}
            onChange={(files) => handleFilesChange(files)}
            maxFileSize={10000000}
            filesLimit={filesLimit}
            dropzoneText={
              <>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: 20,
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.common.greyblueDarker,
                  }}
                >
                  Drag and drop a file or click, {files ? files.length : 0} Files Added
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.negative
                        : '#fb0000a6',
                    fontWeight: (theme) => (theme.palette.mode === 'dark' ? 600 : 400),
                  }}
                >
                  {filesLimit} files max
                </Typography>
              </>
            }
            showAlerts={false}
            getFileLimitExceedMessage={(filesLimit) => {
              handleOpenModal();
              setErrorMessage({
                error: true,
                title: `Files limits is ${filesLimit}`,
                message:
                  'You have exceeded the limit of 200 files per upload. Please select up to 200 files at a time.',
              });
            }}
          />
          <FormControl variant="standard" sx={{ width: '100%' }}>
            {users ? displayUsers() : <MenuItem value={''}>Loading Users...</MenuItem>}
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpload(selectedUsers)}
              disabled={loadingFiles || selectedUsers[0] === undefined}
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.blueShadow['500']
                    : theme.palette.primary.main,
                '&:hover': {
                  color: 'common.white',
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.blueShadow['600']
                      : theme.palette.primary.dark,
                },
              }}
            >
              {loadingFiles ? (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Box
                    sx={{
                      border: '4px solid #fff',
                      borderLeftColor: 'transparent',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      animation: `${spin} 1s linear infinite`,
                    }}
                  />
                  {`  ${count || 0}/${files.length}`}
                </Box>
              ) : (
                'Upload'
              )}
            </Button>
          </FormControl>
        </StyledBox>
      </Popover>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="caption"
              sx={{ color: 'common.greyblueDark', fontSize: 22, fontWeight: 700 }}
            >
              {errorMessage.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{ m: '10px 0', fontSize: 16, color: 'common.greyblue' }}
            >
              {errorMessage.message}
            </Typography>
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                top: 2,
                right: 5,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              size="small"
            >
              <FontAwesomeIcon
                icon="far fa-times"
                size={16}
                color="common.greyblueDark"
              />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

UploadDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
};
