import { Box } from '@mui/material/';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import FontAwesomeIcon from './FAIcon';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 10,
    maxWidth: 400,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon="fa-times-circle" size={22} color="#ff6a6a" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PopUp({
  isOpen = false,
  close,
  action,
  title,
  data = () => {},
  progress,
  children,
}) {
  const [showProgress, setShowProgress] = React.useState(false);
  return (
    <div>
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogContent>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
            {title}
          </BootstrapDialogTitle>
          <Box sx={{ padding: 2 }}>
            {data()}
            {children}
          </Box>
        </DialogContent>
        {showProgress ? <LinearProgress /> : null}
        <DialogActions>
          <IconButton
            autoFocus
            data-testid="accept-action"
            id="add-import-data"
            onClick={() => {
              setShowProgress(true);
              action();
            }}
          >
            <FontAwesomeIcon icon="fa-check-circle" size={22} color="#2ed573" />
          </IconButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
