import {
  Box,
  Fab,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import utils from '../../utils/utils';

import PropTypes from 'prop-types';
import FontAwesomeIcon from '../FAIcon';
import { Collection } from './Popover';

type ICustomButton = {
  color?: string;
  className?: any;
  icon?: JSX.Element;
  label: string;
  onClick?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

const classes = {
  listitem: {
    color: 'grey',
  },
  list: {
    minWidth: '450px',
    '& li:nth-of-type(2n)': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    padding: 0,
  },
  deleteButton: {
    backgroundColor: '#dfdfdf',
    padding: '4px',
    borderRadius: 3,
    cursor: 'pointer',
    height: 16,
    color: 'white',
  },
  fab: {},
};

const getIconByType = (type: string = 'pdf') => {
  switch (type) {
    case 'pdf':
      return <FontAwesomeIcon icon="fa-solid fa-file-pdf" size="20px" color="#fff" />;
    case 'jpg' || 'jpeg':
      return <FontAwesomeIcon icon="fa-solid fa-file-image" size="20px" color="#fff" />;
    case 'word':
      return <FontAwesomeIcon icon="fa-solid fa-file-word" size="20px" color="#fff" />;
    case 'csv':
      return <FontAwesomeIcon icon="fa-solid fa-file-csv" size="20px" color="#fff" />;
    case 'excel':
      return <FontAwesomeIcon icon="fa-solid fa-file-excel" size="20px" color="#fff" />;
    default:
      return <FontAwesomeIcon icon="fa-solid fa-file" size="20px" color="#fff" />;
  }
};

export const showCollection = (
  collection: Collection[],
  removeOneItem = (id: string) => {},
  handleClose = () => {}
) => {
  let elements;
  if (collection.length) {
    elements = collection.map((record, i) => {
      let mainValue = [record.fields[0]];
      if (record.folderId.primaryFolderFieldId) {
        mainValue = record.fields.filter(
          (field) => field.field.id === record.folderId.primaryFolderFieldId
        );
        if (mainValue.length === 0) {
          mainValue[0] = {
            field: {
              name: '',
            },
            value: '',
          };
        }
      } else if (!record.folderId.primaryFolderFieldId && i === 0) {
        mainValue = [record.fields[0]];
      }
      let date = new Date(Number(record.createdAt));
      return (
        <ListItem data-testid="collection-bin-item" key={i} sx={classes.listitem}>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              backgroundColor: 'common.greyblueDarker',
              boxShadow: 'inset 0px 5px 10px #00000045',
              borderRadius: '50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '15px',
            }}
          >
            {getIconByType(record.attachments[0].format)}
          </Box>
          <ListItemText
            primary={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: 'auto',
                    minWidth: 100,
                    //   borderRight: '1px solid lightgray',
                    fontSize: 12,
                    color: '#fff',
                    fontWeight: 700,
                    wordBreak: 'break-word',
                  }}
                >
                  <p style={{ margin: 0, fontSize: 10, fontWeight: 300 }}>
                    {mainValue[0]?.field.name}:
                  </p>
                  {mainValue[0]?.value ? mainValue[0].value : 'Not Filled'}
                </Box>
                <Box
                  sx={{
                    width: 80,
                    fontSize: 12,
                    color: '#fff',
                    fontWeight: 700,
                  }}
                >
                  <p style={{ margin: 0, fontSize: 10, fontWeight: 300 }}>Created at:</p>
                  {utils.convertDate(date)}
                </Box>
                <Box
                  sx={{
                    width: 100,
                    fontSize: 12,
                    color: '#fff',
                    fontWeight: 700,
                    textAlign: 'center',
                  }}
                >{`${record.folder}`}</Box>
                <Tooltip title="Delete" arrow>
                  <span>
                    <IconButton
                      sx={{
                        color: 'white.main',
                        '& :hover': {
                          color: 'common.negative',
                        },
                      }}
                      onClick={() => removeOneItem(record.id)}
                    >
                      <FontAwesomeIcon icon="fas fa-trash" size="12px" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            }
          />
        </ListItem>
      );
    });
  } else {
    elements = (
      <Box
        data-testid="collection-bin-empty"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 5,
          height: 100,
        }}
      >
        <Typography
          variant="body1"
          noWrap
          style={{ color: 'white', textAlign: 'center', fontSize: 18 }}
        >
          No records in your collection bin
        </Typography>
      </Box>
    );
  }

  return elements;
};

const transitionDuration = {
  enter: 225,
  exit: 195,
};
const fabs = [
  {
    color: undefined,
    className: classes.fab,
    icon: <FontAwesomeIcon icon="fa-solid fa-copy" size={10} padding={0} color="#fff" />,
    label: 'Expand',
  },
];
export const createFab = (
  customButton: ICustomButton | null = null,
  order = 0,
  value = 0
) => {
  if (customButton) {
    customButton.color = customButton.color ? customButton.color : 'primary';
    return (
      <Zoom
        in={value === order}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${value === order ? transitionDuration.exit : 0}ms`,
          alignSelf: 'flex-end',
          height: '40px',
          width: '40px',
        }}
        unmountOnExit
      >
        <Tooltip title={customButton.label}>
          <IconButton
            onClick={customButton.onClick}
            sx={
              customButton.color === 'primary'
                ? {
                    color: 'white.main',
                    '&:hover': {
                      color: 'common.greyblueDark',
                      backgroundColor: 'common.white',
                      borderColor: '#0062cc',
                      boxShadow: 'none',
                    },
                  }
                : {
                    color: 'common.white',
                    backgroundColor: 'common.blue',
                    '&:hover': {
                      backgroundColor: '#0a5487',
                    },
                  }
            }
          >
            {customButton.icon}
          </IconButton>
        </Tooltip>
      </Zoom>
    );
  } else {
    return fabs.map((fab, index) => (
      <Zoom
        key={index}
        in={value === index + order}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${value === index + order ? transitionDuration.exit : 0}ms`,
          height: '40px',
          width: '40px',
        }}
        unmountOnExit
      >
        <Fab aria-label={fab.label} sx={fab.className} color={fab.color}>
          {fab.icon}
        </Fab>
      </Zoom>
    ));
  }
};

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} sx={{ paddingBottom: 0 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export function a11yProps(index: number) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}
