import React from 'react';
import {
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Autocomplete,
} from '@mui/material';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { Controller } from 'react-hook-form';
import Th_FiltersInputs from '../../components/themedComponents/Th_FiltersInputs';
import { StyledTextfield } from '../../components/themedComponents/Th_TextField';
import { checkIfFieldSaved } from '../search/searchUtils';
import { useTheme } from '@mui/material/styles';
import KeyValueFilterInput from './KeyValueFilterInput';

const currencyRegex = /^\d+(\.\d{0,2})?$/;
export default function FilterInputs({
  field,
  register,
  control,
  selectedFolder,
  isResetted,
  setResetted,
  setFieldValues,
  fieldValues,
  key,
  id,
}) {
  const initialValue = fieldValues[selectedFolder.id]
    ? fieldValues[selectedFolder.id][field.id]
      ? fieldValues[selectedFolder.id][field.id]
      : ''
    : '';
  const [value, setValue] = React.useState(initialValue);
  const [value2, setValue2] = React.useState('');
  const [notModified, setModified] = React.useState(true);
  const [isLike, setIsLike] = React.useState(false);
  const theme = useTheme();

  const handleDelete = () => {
    let newValue = value;
    newValue = newValue.slice(0, newValue.length - 1);
    setValue(newValue);
    setIsLike(false);
  };

  if (
    selectedFolder.apProcessingSettings?.ocrTemplateNameFolderFieldId === field.id &&
    selectedFolder.apProcessingSettings?.ocrTemplateNameSupplierCodePairs.length
  ) {
    return (
      <KeyValueFilterInput
        key={key}
        id={id}
        field={field}
        control={control}
        selectedFolder={selectedFolder}
        isResetted={isResetted}
        setResetted={setResetted}
        setFieldValues={setFieldValues}
        fieldValues={fieldValues}
        folder={selectedFolder}
      />
    );
  }

  switch (field.type) {
    case 'boolean':
      return (
        <Grid item container key={field.id} sx={{ marginBottom: '10px' }}>
          <FormControl
            fullWidth
            variant="standard"
            size="small"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.common.greyblueDarker
                  : theme.palette.common.lightblue,
              borderRadius: 1,
              '& label': {
                paddingLeft: '13px',
              },
              '& .MuiInputLabel-root': {
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.common.greyblueDark,
              },
              '& .MuiInputBase-root': {
                borderRadius: '4px',
              },
              '& input': {
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.common.greyblueDark,
              },
              '& .MuiSvgIcon-root': {
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.common.greyblueDarker,
              },
            }}
          >
            <Controller
              render={({ field: { onChange, ...props } }) => (
                <>
                  <Select
                    sx={{
                      '.MuiSelect-standard': {
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.greyblueDarker
                            : theme.palette.common.lightblue,
                      },
                    }}
                    // name="boolean"
                    inputProps={{
                      name: 'boolean',
                      id: field.id,
                    }}
                    onChange={(e, data) => {
                      onChange(data.props.value);
                      setValue(data.props.value);
                      if (data.props.value) {
                        setModified(false);
                        setResetted(false);
                      }
                      setFieldValues((prevState) => {
                        return {
                          ...prevState,
                          [selectedFolder.id]: {
                            ...prevState[selectedFolder.id],
                            [field.id]: data.props.value,
                          },
                        };
                      });
                    }}
                    value={
                      notModified || isResetted || fieldValues[selectedFolder.id]
                        ? checkIfFieldSaved(field.id, fieldValues, selectedFolder)
                        : ''
                    }
                    input={<Th_FiltersInputs />}
                    labelId={field.id}
                  >
                    <MenuItem value={null}>None</MenuItem>
                    <MenuItem key="true" value="true">
                      True
                    </MenuItem>
                    <MenuItem key="false" value="false">
                      False
                    </MenuItem>
                  </Select>
                </>
              )}
              name={field.id}
              control={control}
              defaultValue={null}
            />
            <InputLabel htmlFor={field.id} id={field.id}>
              {field.name}
            </InputLabel>
          </FormControl>
        </Grid>
      );
    case 'currency':
      return (
        <Grid item container key={field.id} sx={{ marginBottom: '10px' }}>
          <StyledTextfield
            {...register(field.id)}
            label={field.name}
            id="currency"
            name={field.id}
            variant="standard"
            onChange={(e) => {
              if (!currencyRegex.test(e.target.value)) return;
              let newValue = e.target.value;
              setModified(false);
              setResetted(false);
              if (newValue[newValue.length - 1] === '%') {
                setIsLike(true);
              } else {
                setIsLike(false);
              }
              setValue(newValue);
              setFieldValues((prevState) => {
                return {
                  ...prevState,
                  [selectedFolder.id]: {
                    ...prevState[selectedFolder.id],
                    [field.id]: newValue,
                  },
                };
              });
            }}
            value={
              notModified || isResetted || fieldValues[selectedFolder.id]
                ? checkIfFieldSaved(field.id, fieldValues, selectedFolder)
                : ''
            }
          />
        </Grid>
      );
    case 'multistring':
      return (
        <Grid item container key={field.id} sx={{ marginBottom: '10px' }}>
          <>
            <StyledTextfield
              fullWidth
              id="multistring"
              {...register(field.id)}
              size="small"
              label={field.name}
              name={field.id}
              variant="standard"
              onChange={(e) => {
                let newValue = e.target.value;
                setModified(false);
                setResetted(false);
                setFieldValues((prevState) => {
                  return {
                    ...prevState,
                    [selectedFolder.id]: {
                      ...prevState[selectedFolder.id],
                      [field.id]: e.target.value,
                    },
                  };
                });
                setValue(newValue);
              }}
              value={
                notModified || isResetted || fieldValues[selectedFolder.id]
                  ? checkIfFieldSaved(field.id, fieldValues, selectedFolder)
                  : ''
              }
            ></StyledTextfield>
          </>
        </Grid>
      );
    case 'integer':
      return (
        <Grid item container key={field.id} sx={{ marginBottom: '10px' }}>
          <StyledTextfield
            {...register(field.id)}
            size="small"
            fullWidth
            //type="number"
            label={field.name}
            name={field.id}
            variant="standard"
            id="integer"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={(e) => {
              if (isNaN(e.target.value)) return;
              let newValue = e.target.value;
              setModified(false);
              setResetted(false);
              if (newValue[newValue.length - 1] === '%') {
                setIsLike(true);
              } else {
                setIsLike(false);
              }
              setValue(newValue);
              setFieldValues((prevState) => {
                return {
                  ...prevState,
                  [selectedFolder.id]: {
                    ...prevState[selectedFolder.id],
                    [field.id]: newValue,
                  },
                };
              });
            }}
            value={
              notModified || isResetted || fieldValues[selectedFolder.id]
                ? checkIfFieldSaved(field.id, fieldValues, selectedFolder)
                : ''
            }
          />
          {isLike ? (
            <Chip
              variant="outlined"
              size="small"
              avatar={<Avatar>%</Avatar>}
              label="like"
              onDelete={handleDelete}
            />
          ) : null}
        </Grid>
      );
    case 'dropdown':
      return (
        <Grid item container key={field.id} sx={{ marginBottom: '10px' }}>
          <Controller
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                id="dropdown"
                sx={{
                  width: '100%',
                  height: 40,
                  '& .MuiAutocomplete-inputRoot': {
                    marginTop: '9px',
                    height: '40px',
                    '&.MuiInput-root .MuiInput-input': {
                      marginLeft: '14px',
                    },
                  },
                  '& .MuiSvgIcon-root': {
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.common.greyblueDarker,
                  },
                }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                // id={field.id}
                inputValue={
                  notModified || isResetted || fieldValues[selectedFolder.id]
                    ? checkIfFieldSaved(field.id, fieldValues, selectedFolder)
                    : ''
                }
                options={field.options}
                getOptionLabel={(option) => {
                  if (option.value) return option.value;
                  return '';
                }}
                onChange={(e, data) => {
                  onChange(data);
                }}
                onInputChange={(e, newvalue) => {
                  setValue(newvalue);
                  if (newvalue) {
                    setModified(false);
                    setResetted(false);
                  }
                  setFieldValues((prevState) => {
                    return {
                      ...prevState,
                      [selectedFolder.id]: {
                        ...prevState[selectedFolder.id],
                        [field.id]: newvalue,
                      },
                    };
                  });
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.common.white
                          : theme.palette.common.greyblueDarker,
                    }}
                  >
                    {option.value}
                  </li>
                )}
                renderInput={(params) => (
                  <StyledTextfield
                    {...params}
                    fullWidth
                    label={field.name}
                    variant="standard"
                  />
                )}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            name={field.id}
            control={control}
            defaultValue={null}
          />
        </Grid>
      );
    case 'string':
      return (
        <Grid item container key={field.id} sx={{ marginBottom: '10px' }}>
          <>
            <StyledTextfield
              fullWidth
              id="string"
              {...register(field.id)}
              size="small"
              label={field.name}
              name={field.id}
              variant="standard"
              onChange={(e) => {
                let newValue = e.target.value;
                setModified(false);
                setResetted(false);
                newValue[newValue.length - 1] === '%'
                  ? setIsLike(true)
                  : setIsLike(false);
                setFieldValues((prevState) => {
                  return {
                    ...prevState,
                    [selectedFolder.id]: {
                      ...prevState[selectedFolder.id],
                      [field.id]: e.target.value,
                    },
                  };
                });
                setValue(newValue);
              }}
              value={
                notModified || isResetted || fieldValues[selectedFolder.id]
                  ? checkIfFieldSaved(field.id, fieldValues, selectedFolder)
                  : ''
              }
            ></StyledTextfield>
            {isLike && !isResetted ? (
              <Chip
                variant="outlined"
                size="small"
                avatar={<Avatar>%</Avatar>}
                label="like"
                onDelete={handleDelete}
              />
            ) : null}
          </>
        </Grid>
      );
    case 'datetime':
      return (
        <Grid container key={field.id} sx={{ marginBottom: '10px' }}>
          <Grid item xs={12} sx={{ marginBottom: '10px' }}>
            <StyledTextfield
              InputProps={{
                sx: {
                  '&::-webkit-calendar-picker-indicator': {
                    display: 'none',
                    '-webkit-appearance': 'none',
                  },
                },
              }}
              size="small"
              fullWidth
              id="datetime-from"
              name={`${field.id}-from`}
              label={'From (' + field.name + ')'}
              type="date"
              value={
                notModified || isResetted || fieldValues[selectedFolder.id]
                  ? checkIfFieldSaved(
                      `${field.id}-from`,
                      fieldValues,
                      selectedFolder,
                      'from'
                    )
                  : value
              }
              variant="standard"
              {...register(`${field.id}-from`)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                let newValue = e.target.value;
                setModified(false);
                setResetted(false);
                setValue(newValue);
                setFieldValues((prevState) => {
                  return {
                    ...prevState,
                    [selectedFolder.id]: {
                      ...prevState[selectedFolder.id],
                      [`${field.id}-from`]: newValue,
                    },
                  };
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextfield
              size="small"
              fullWidth
              id="datetime-to"
              name={`${field.id}-to`}
              label={'To (' + field.name + ')'}
              type="date"
              value={
                notModified || isResetted || fieldValues[selectedFolder.id]
                  ? checkIfFieldSaved(`${field.id}-to`, fieldValues, selectedFolder, 'to')
                  : value2
              }
              variant="standard"
              placeholder=""
              {...register(`${field.id}-to`)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                let newValue = e.target.value;
                setModified(false);
                setResetted(false);
                setValue2(newValue);
                setFieldValues((prevState) => {
                  return {
                    ...prevState,
                    [selectedFolder.id]: {
                      ...prevState[selectedFolder.id],
                      [`${field.id}-to`]: newValue,
                    },
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      );
    case '':
      return (
        <Grid item container key={field.id} sx={{ marginBottom: '10px' }}>
          <Grid item></Grid>
        </Grid>
      );
    default:
      return null;
  }
}
