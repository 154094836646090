import { Container, SxProps, Typography } from '@mui/material';
import FolderCreationForm from './FolderCreationForm';

interface CreateFolderTabProps {
  classes: {
    dialogTitle: SxProps;
  };
}

const CreateFolderTab = ({ classes }: CreateFolderTabProps) => {
  return (
    <>
      <Typography sx={classes.dialogTitle} variant="subtitle2">
        Create folder
      </Typography>
      <Container>
        <FolderCreationForm />
      </Container>
    </>
  );
};

export default CreateFolderTab;
