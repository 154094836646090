import React, { useState, useEffect, useContext, useRef } from 'react';

import { Typography, Box, Stack } from '@mui/material';
import { Context as StatusContext } from '../context/statusContext';
import TableArea from '../containers/reporting/TableArea';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { StatusContextTypes } from '../context/statusContextTypes';
import ReportingSideBar from '../containers/reporting/ReportingSideBar';
import { FormProvider, useForm } from 'react-hook-form';
import { OperationVariables, useLazyQuery } from 'react-apollo';
import gqlRecords from '../utils/gql/gqlRecords';
import { Row, SelectedFolder } from '../components/masterTable/masterTableTypes';
import { GET_FOLDER_BY_ID, GET_FOLDER_BY_ID_BY_ORG } from '../utils/gql/gqlFolders';
import { Context as AuthContext, AuthContextTypes } from '../context/authContext';
import { GridSortModel } from '@mui/x-data-grid';
import utils from '../utils/utils';
import { checkCreatedAtField } from '../containers/search/searchUtils';
import { Record } from '../containers/search/types';
import useUsers from '../hooks/useUsers';
import GeneralSnackbar, { HandleClose, StatusProps } from '../components/SnackBar';
import {
  generateRows,
  getHeaders,
  getReportVariables,
} from '../containers/reporting/utils';

const Reporting = () => {
  const methods = useForm();
  const { users } = useUsers();
  const [{ lastReportFolder }] = useLocalStorage('userSettings');
  const [userData] = useLocalStorage('userData');
  const { suser, selOrg } = userData;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const isSuser = authContext.state.suser || suser;
  const { setScreen } = useContext(StatusContext) as StatusContextTypes;
  const [rows, setRows] = useState<Row[] | Record[]>([]);
  const [cols, setCols] = useState<any[]>([]);
  const [querySort] = useState<GridSortModel>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [variables, setVariables] = useState<any>({});
  const [sorting, setSorting] = useState<string>('');
  // Keep track of the selected folder
  const [selectedFolder, setSelectedFolder] = useState<null | SelectedFolder>(null);
  const [type, setType] = useState('');
  const [data, setData] = useState(null); // data generated on submit
  // const [reportingType, setReportingType] = useState<'linked' | 'workflow' | null>(null);
  const openModalButtonRef = useRef(null);
  const [snackbar, setSnackbar] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'success',
  });

  //Set between all, synced and not synced
  const [viewMode, setViewMode] = useState<string>('all');
  const [getReport, { loading: reportLoading }] = useLazyQuery(
    gqlRecords.GET_RECORDS_REPORT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.Records_Report.records) {
          let rows = generateRows(data.Records_Report.records, users!, type);
          setRows(rows);
          getHeaders(data.Records_Report.records, setCols, type, selectedFolder);
          setTotalCount(data.Records_Report.totalCount);
        } else if (data.Records_Report.linked_Informations && selectedFolder) {
          const parsedData = utils.parseLinkedInfo(
            data.Records_Report.linked_Informations,
            selectedFolder
          );
          setRows(parsedData.rows);
          setCols(parsedData.col);
          setTotalCount(data.Records_Report.totalCount);
        } else {
          setRows([]);
          setTotalCount(0);
        }
      },
      onError: (error) =>
        setSnackbar({
          status: true,
          snackbarMessage: 'There was an error sending the request',
          severity: 'error',
        }),
    }
  );

  const [getFolder] = useLazyQuery(isSuser ? GET_FOLDER_BY_ID_BY_ORG : GET_FOLDER_BY_ID, {
    onCompleted: (data) => {
      if (data) {
        setSelectedFolder(isSuser ? data.FolderByOrg : data.Folder.folder);
      }
    },
  });

  const onSubmit = (data) => {
    if (!data) return;
    // When the report is submitted, we can choose which query will be used
    setType(data['workflow-status']);
    setData(data);

    let variables = getReportVariables(
      data.selectedTemplate.variables,
      limit,
      currentPage,
      selectedFolder,
      data.selectedTemplate.type,
      sorting
    );
    setVariables(variables);
    getReport({ variables });
  };

  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ status: false, snackbarMessage: '', severity: 'success' });
  };

  useEffect(() => {
    // set screen to context
    setScreen('reporting');

    if (lastReportFolder)
      getFolder({
        variables: { id: lastReportFolder, organisationId: isSuser ? selOrg.id : null },
      });
  }, []);

  useEffect(() => {
    data && onSubmit(data);
  }, [currentPage, limit, sorting]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} height="100%">
            <ReportingSideBar
              setSelectedFolder={setSelectedFolder}
              setViewMode={setViewMode}
              selectedFolder={selectedFolder}
              loading={reportLoading}
              buttonRef={openModalButtonRef}
              rows={rows}
              setCols={setCols}
              setRows={setRows}
              setData={setData}
            />
            {selectedFolder ? (
              <TableArea
                selectedFolder={selectedFolder}
                cols={cols}
                rows={rows}
                setLimit={setLimit}
                limit={limit}
                totalCount={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loading={reportLoading}
                setSorting={setSorting}
                buttonRef={openModalButtonRef}
                exportQuery={variables}
              />
            ) : (
              <Box
                sx={{
                  marginTop: 2,
                  width: '100%',
                  height: '60vh',
                  boxSizing: 'border-box',
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'black.main' : 'lightgray',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '3px',
                }}
              >
                <Typography variant="subtitle1">
                  Please start by selecting a folder
                </Typography>
              </Box>
            )}
          </Stack>
        </form>
      </FormProvider>
      <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
    </>
  );
};

export default Reporting;
