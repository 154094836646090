import React, { useState } from 'react';

import ToolbarBase from '../../components/toolbarScreen/ToolbarBase';
import ToolbarButtonLeft from '../../components/toolbarScreen/ToolbarButtonLeft';
import ToolbarTitle from '../../components/toolbarScreen/ToolbarTitle';
import ToolbarActionRight from '../../components/toolbarScreen/ToolbarActionRight';
import FolderPopup from './foldersPopup/FolderPopup';
import { useHistory } from 'react-router-dom';
import FontAwesomeIcon from '../../components/FAIcon';
import { modifyLocalStorageObject, useLocalStorage } from '../../hooks/useLocalStorage';
import { FolderToolbarProps } from './types';

const FolderToolbar = ({
  setSearchQuery,
  refetch,
  setFolderCompact,
  folderCompact,
}: FolderToolbarProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  let history = useHistory();

  const handleOpenModal = () => setIsOpenModal(true);

  const handleCloseModal = () => setIsOpenModal(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  const actions = [
    {
      buttonOnClick: () => {
        setFolderCompact(!folderCompact);
        setUserSettings(
          modifyLocalStorageObject(
            {
              ...userSettings,
              folderIsCompact: !folderCompact,
            },
            `folderIsCompact`
          )
        );
      },
      buttonIcon: <FontAwesomeIcon icon="fa-thin fa-list" size={18} />,
      tooltipTitle: 'Change folders display',
    },
    {
      buttonOnClick: handleOpenModal,
      buttonIcon: <FontAwesomeIcon icon="fas fa-folder-plus" />,
      tooltipTitle: 'Create folder',
    },
  ];
  return (
    <>
      <ToolbarBase>
        <ToolbarButtonLeft buttonOnClick={() => history.goBack()}>
          <FontAwesomeIcon icon="fas fa-angle-left" />
        </ToolbarButtonLeft>
        <ToolbarTitle title="Folders" />
        <ToolbarActionRight
          actions={actions}
          handleSearch={handleSearch}
          setSearchQuery={setSearchQuery}
        />
      </ToolbarBase>
      <FolderPopup
        handleCloseModal={handleCloseModal}
        isOpenModal={isOpenModal}
        refetch={refetch}
      />
    </>
  );
};

export default FolderToolbar;
