import { TextField, styled } from '@mui/material';

export const SecondStyledTexfield = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '6px',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.common.white
        : theme.palette.common.greyblueDarker,
    border: theme.palette.mode === 'dark' ? 'solid 1px' : 'thin 0.5px',
    borderColor:
      theme.palette.mode === 'light' ? '#778E9F' : theme.palette.common.greyblueDarker,

    '&.Mui-disabled': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.common.lightGrey
          : theme.palette.common.blueGrey[900],
      borderColor: theme.palette.grey[600],
    },
  },
  '.MuiFormLabel-root.Mui-error': {
    fontWeight: theme.palette.mode === 'light' ? 400 : 600,
  },
}));
