import { Stack, Typography } from '@mui/material';
import FontAwesomeIcon from '../../../components/FAIcon';

export default function NoFolders() {
  return (
    <Stack
      height="80%"
      direction="column"
      justifyContent="center"
      alignItems="center"
      color="grey.300"
    >
      <FontAwesomeIcon icon="fas fa-folder" size="10rem" color="inherit" />
      <Typography variant="subtitle1" fontWeight={300} color="grey.400">
        No Folders Found
      </Typography>
    </Stack>
  );
}
