import { format } from 'date-fns';

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

// PODNumber => POD Number
export const splitByUpperCase = (text: string) => {
  return text
    .trim() //  remove any leading or trailing spaces from the original string
    .split(/(?=[A-Z]{1}[a-z]{1})/) // uses a positive lookahead statement to split the string when there is a capital letter followed by a small letter. Example: 'PODNo' = 'POD No'
    .map((element) => element.trim()) // remove any leading or trailing spaces from the strings in the array.
    .join(' ');
};

// update_records => Update Records
export const splitByUnderscore = (text: string) => {
  return text
    .trim()
    .split('_')
    .map((element) => element.trim())
    .join(' ');
};

export function parseUnixDate(unixTimestamp: string) {
  const date = new Date(parseInt(unixTimestamp));
  return format(date, 'dd/MM/yyyy');
}
