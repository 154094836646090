import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { CREATE_ORG } from '../../../utils/gql/gqlOrg';
import { useForm } from 'react-hook-form';
import { Org } from '../OrgMain';
import {
  modifyLocalStorageObject,
  useLocalStorage,
} from '../../../hooks/useLocalStorage';
import StyledSwitch from '../../../components/form/StyledSwitch';
import { StatusProps } from '../../../components/SnackBar';
import { ApolloQueryResult, OperationVariables } from 'apollo-client';
import Button from '../../../components/Button/StyledButton';
import { StyledTextField } from '../../../components/Textfield/StyledTextfield';
import FontAwesomeIcon from '../../../components/FAIcon';

interface OrgPopupProps {
  setSnackbarStatus: React.Dispatch<React.SetStateAction<StatusProps>>;
  selectedOrg: Org | false;
  setSelectedOrg: React.Dispatch<React.SetStateAction<Org | false>>;
  refetch: (
    variables?: OperationVariables | undefined
  ) => Promise<ApolloQueryResult<any>>;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProcessPathEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  processPathEnabled: boolean;
  isDrawerOpen: boolean;
}
const styles = {
  formItem: {
    marginBottom: 2,
  },
  title: {
    textAlign: 'center',
    padding: { xs: '5px 0', md: '6px 0' },
    fontSize: { xs: 17, md: 20 },
    backgroundColor: 'common.greyblueDark',
    fontWeight: 'bold',
    display: 'block',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 10,
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent',
    },
    color: 'common.white',
  },
};

const OrgPopup = ({
  setSnackbarStatus,
  selectedOrg,
  setSelectedOrg,
  refetch,
  setIsDrawerOpen,
  setProcessPathEnabled,
  processPathEnabled,
  isDrawerOpen,
}: OrgPopupProps) => {
  const { register, handleSubmit, reset } = useForm<Org>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [createOrgMutation] = useMutation(CREATE_ORG);
  const [userData, setUserData] = useLocalStorage('userData');
  const createOrg = (values: Org) => {
    return createOrgMutation({
      variables: {
        name: values.name,
        address: values.address,
        phoneNumber: values.phoneNumber,
        country: values.country,
        active: values.active,
        processPathEnabled: values.processPathEnabled,
        logo: `${Math.random()}`,
      },
    });
  };
  const onOrgCreation = async (values: Org) => {
    createOrg({ ...values })
      .then(({ data }) => {
        if (data.createOrganisation.error) {
          setSnackbarStatus({
            status: true,
            snackbarMessage: 'There was a problem creating the organisation',
            severity: 'error',
          });
        } else {
          userData.organisations.push(data.createOrganisation.organisation);
          setUserData(modifyLocalStorageObject({ ...userData }, 'userData'));
          setSelectedOrg({
            name: data.createOrganisation.organisation.name,
            id: data.createOrganisation.organisation.id,
          });
          setSnackbarStatus({
            status: true,
            snackbarMessage: 'Organisation created successfully',
            severity: 'success',
          });
          setIsDrawerOpen(false);
        }
        refetch().then(() => {
          reset();
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <Dialog
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
        setSelectedOrg(false);
      }}
      maxWidth="md"
      sx={{ height: '900px', margin: 'auto' }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography sx={styles.title} variant="subtitle2" component={'span'}>
          Create Org
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsDrawerOpen(false)}
          sx={styles.closeButton}
        >
          <FontAwesomeIcon icon="fas fa-times" size="14px" color="#fff" padding="2px" />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          bgcolor: 'background.paper',
        }}
      >
        <form onSubmit={handleSubmit(onOrgCreation)} style={{ height: '100%' }}>
          <Grid container direction="column" spacing={4} sx={{ p: 2, paddingTop: 0 }}>
            <Grid item>
              <Grid item sx={styles.formItem}>
                <StyledTextField
                  {...register('name', { required: true })}
                  fullWidth
                  label="Organisation name"
                  name="name"
                  variant="outlined"
                  defaultValue={''}
                />
              </Grid>
              <Grid item sx={styles.formItem}>
                <StyledTextField
                  {...register('address', { required: true })}
                  fullWidth
                  label="Address"
                  name="address"
                  variant="outlined"
                  defaultValue={''}
                />
              </Grid>
              <Grid item sx={styles.formItem}>
                <StyledTextField
                  {...register('country', { required: true })}
                  fullWidth
                  label="Country"
                  name="country"
                  variant="outlined"
                  defaultValue={''}
                />
              </Grid>
              <Grid item sx={styles.formItem}>
                <StyledTextField
                  {...register('phoneNumber', { required: true })}
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  variant="outlined"
                  defaultValue={''}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <StyledSwitch
                      {...register('active')}
                      name="active"
                      checked={isActive}
                      onChange={() => setIsActive(!isActive)}
                      defaultChecked={false}
                    />
                  }
                  label="Active"
                />
              </Grid>
              <Grid item sx={styles.formItem}>
                <FormControlLabel
                  control={
                    <StyledSwitch
                      {...register('processPathEnabled')}
                      name="processPathEnabled"
                      checked={processPathEnabled}
                      onChange={() => setProcessPathEnabled(!processPathEnabled)}
                      defaultChecked={false}
                    />
                  }
                  label="Workflow Enabled"
                />
              </Grid>
              <Grid item container spacing={1} sx={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'dark'
                          ? 'common.white'
                          : 'common.greyblueDarker',
                    }}
                    onClick={() => {
                      setIsDrawerOpen(false);
                      setSelectedOrg(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" variant="contained">
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OrgPopup;
