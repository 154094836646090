import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  ToggleButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { ApprovalCardProps } from '../../types';
import Base from './Base';
import CheckboxUsersList from './CheckboxUsersList';
import _ from 'lodash';
import StyledSwitch from '../../../../components/form/StyledSwitch';

const styles = {
  type: {
    textAlign: 'center',
    marginRight: '30px',
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'uppercase',
    color: 'common.lightblue',
  },
  remove: {
    color: 'common.white',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 10,
    '&:hover': {
      color: 'common.negativeDark',
    },
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    with: 20,
    color: 'common.white',
  },
  cardActions: {
    backgroundColor: 'common.greyblueDark',
    height: 30,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
  },
};

const ApprovalCard = ({
  handleRemoveStep,
  step,
  handleUpdateStep,
  users,
}: ApprovalCardProps) => {
  let { stepOrder, required, toBeApprovedBy, __typename, allUsersMustApprove } = step;

  const handleChange = () => {
    !allUsersMustApprove
      ? handleUpdateStep(stepOrder, 'allUsersMustApprove', true)
      : handleUpdateStep(stepOrder, 'allUsersMustApprove', false);
  };

  // useEffect(() => {
  //   if (!toBeApprovedBy || !users) return;
  //   let allUsersAreChecked = toBeApprovedBy.length === users.length;
  //   setAllUserMustApproveState(allUsersAreChecked);
  //   !allUsersAreChecked && allUsersMustApprove
  //     ? handleUpdateStep(stepOrder, 'allUsersMustApprove', false)
  //     : handleUpdateStep(stepOrder, 'allUsersMustApprove', true);
  // }, [toBeApprovedBy, allUserMustApproveState]);

  return (
    <Base step={step}>
      <>
        <CardHeader
          titleTypographyProps={{ sx: { color: 'common.white' } }}
          action={
            <Box sx={styles.action}>
              <ToggleButton
                onChange={() => handleUpdateStep(stepOrder, 'required', !required)}
                value={required}
                selected={required}
                disableRipple={true}
                aria-label="required"
                sx={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  '&.Mui-selected': { backgroundColor: 'transparent' },
                }}
              >
                <FontAwesomeIcon
                  icon="fal fa-check-circle"
                  color={required ? 'common.positive' : '#ffffff'}
                />
              </ToggleButton>
              <Typography
                variant="button"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 400,
                  fontSize: 11,
                }}
              >
                Required
              </Typography>
            </Box>
          }
          title={<Typography sx={styles.type}>{__typename.slice(0, -4)}</Typography>}
          sx={{
            flexDirection: 'row-reverse',
            backgroundColor: 'common.greyblueDarker',
          }}
        />
        <CardContent sx={{ flex: 10, overflowY: 'auto', ...styles.scrollbar }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="button"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 500,
                fontSize: 12,
              }}
            >
              to be approved by
            </Typography>
            <FormControlLabel
              checked={allUsersMustApprove}
              control={
                <StyledSwitch color="primary" size="small" onChange={handleChange} />
              }
              label="All selected users must approve"
              sx={{
                m: 0,

                '.MuiFormControlLabel-label': {
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? 'common.white'
                      : 'common.greyblueDarker',
                  textTransform: 'uppercase',
                  fontWeight: 500,
                  fontSize: 9,
                  width: 95,
                },
              }}
            />
          </Box>
          <CheckboxUsersList
            users={users}
            handleUpdateStep={handleUpdateStep}
            stepOrder={stepOrder}
            toBeApprovedBy={toBeApprovedBy}
          />
        </CardContent>
        <CardActions sx={styles.cardActions}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            onClick={() => handleRemoveStep(step.stepOrder, step.id!)}
          >
            <Button
              size="small"
              endIcon={
                <Box>
                  <FontAwesomeIcon
                    icon="fal fa-trash"
                    color="common.negativeDark"
                    size={11}
                  />
                </Box>
              }
              sx={styles.remove}
            >
              Remove
            </Button>
          </Box>
        </CardActions>
      </>
    </Base>
  );
};

export default ApprovalCard;
