import { fabric } from 'fabric';

// getClientPosition function is used to get the coordinates where the user clicked or touched on the screen.
export function getClientPosition(e: fabric.IEvent) {
  const positionSource = e.touches ? e.touches[0] : e;
  const { clientX, clientY } = positionSource;
  return {
    clientX,
    clientY,
  };
}

// zoomDelta function adjusts the zoom level of a given canvas.
// delta represents the zoom amount proviced by the wheel
// x and y are the coordinates at where the view should be centered
export function zoomDelta(
  canvas: fabric.Canvae,
  delta: number,
  x: number,
  y: number,
  maxZoom = 10,
  minZoom = 1
) {
  let zoom = canvas.getZoom();
  zoom *= 0.999 ** delta;
  zoom = Math.min(zoom, maxZoom);
  zoom = Math.max(zoom, minZoom);
  const point = {
    x,
    y,
  };
  canvas.zoomToPoint(point, zoom);
}
// enclose function adjusts the position of a given object on a canvas so that the object
// is fully visible within the canvas boundaries and don't fall outisde the boundaries.
export function enclose(canvas: fabric.Canvas, object: fabric.Image) {
  const { br: brRaw, tl: tlRaw } = object.aCoords;
  const T = canvas.viewportTransform;
  T[4] = T[4] || 0;
  T[5] = T[5] || 0;
  const br = fabric.util.transformPoint(brRaw, T);
  const tl = fabric.util.transformPoint(tlRaw, T);
  const { x: left, y: top } = tl;
  const { x: right, y: bottom } = br;
  const { width, height } = canvas;
  // calculate how far to translate to line up the edge of the object with
  // the edge of the canvas
  const dLeft = Math.abs(right - width);
  const dRight = Math.abs(left);
  const dUp = Math.abs(bottom - height);
  const dDown = Math.abs(top);
  // if the object is larger than the canvas, clamp translation such that
  // we don't push the opposite boundary past the edge
  const maxDx = Math.min(dLeft, dRight);
  const maxDy = Math.min(dUp, dDown);
  const leftIsOver = left < 0;
  const rightIsOver = right > width;
  const topIsOver = top < 0;
  const bottomIsOver = bottom > height;
  const translateLeft = rightIsOver && !leftIsOver;
  const translateRight = leftIsOver && !rightIsOver;
  const translateUp = bottomIsOver && !topIsOver;
  const translateDown = topIsOver && !bottomIsOver;
  const dx = translateLeft ? -maxDx : translateRight ? maxDx : 0;
  const dy = translateUp ? -maxDy : translateDown ? maxDy : 0;
  if (dx || dy) {
    T[4] += dx;
    T[5] += dy;
    canvas.requestRenderAll();
  }
}
