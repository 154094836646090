import * as React from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import CustomPagination from './CustomPagination';
import ErrorHandler from '../../../utils/errorHandler';
import { Context as StatusContext } from '../../../context/statusContext';
import { StatusContextTypes } from '../../../context/statusContextTypes';

export default function CustomFooter() {
  const { state } = React.useContext(StatusContext) as StatusContextTypes;
  const [width, setWidth] = React.useState(0);
  const ref = React.createRef<HTMLDivElement>();
  let WIDTH_LIMIT = 700;

  React.useEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  return (
    <ErrorHandler>
      <GridToolbarContainer
        ref={ref}
        sx={{
          justifyContent: 'space-between',
          height: width > WIDTH_LIMIT ? '44px' : 'auto',
          p: '5px',
          backgroundColor:
            state.paletteMode === 'light' ? 'common.white' : 'common.greyblueDarker',
          borderRadius: '0px 0px 5px 5px',
        }}
      >
        <div>
          <GridToolbarColumnsButton
            sx={{
              color:
                state.paletteMode === 'light' ? 'common.greyblueDarker' : 'common.white',
            }}
          />
          {/* <GridToolbarDensitySelector /> */}
          {/* <GridToolbarExport
          printOptions={{ allColumns: true }}
          csvOptions={{ allColumns: true, disableToolbarButton: true }}
        /> */}
        </div>
        <CustomPagination paletteMode={state.paletteMode}></CustomPagination>
      </GridToolbarContainer>
    </ErrorHandler>
  );
}
