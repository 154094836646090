import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledTextfield = styled(TextField)(({ theme }) => ({
  fontWeight: '800',
  height: 40,
  width: '100%',
  borderRadius: 4,
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.common.lightblue
      : theme.palette.common.greyblueDarker,
  '& .MuiInputLabel-root': {
    transform: 'translate(0, 14px) scale(1)',
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.greyblueDarker + ' !important'
        : theme.palette.common.white,
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(0, -1.5px) scale(0.75)',
  },
  '& input': {
    paddingLeft: 14,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.greyblueDarker
        : theme.palette.common.white,
  },
  '& label': {
    paddingLeft: 14,
  },
  '& label.Mui-focused': {
    color: theme.palette.common.blue,
  },
  '& :hover:not(.Mui-disabled):before': {
    borderBottom: '2px solid ' + theme.palette.common.greyblueDark + ' !important',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'transparent',
    '& :hover': {
      borderBottom: (theme) =>
        theme.palette.mode === 'light'
          ? '2px solid ' + theme.palette.common.greyblueDark
          : '2px solid ' + theme.palette.common.lightblue,
    },
  },
  '& .MuiInput-underline:after': {
    '& :hover': {
      borderBottom: (theme) =>
        theme.palette.mode === 'light'
          ? '2px solid ' + theme.palette.common.greyblueDark
          : '2px solid ' + theme.palette.common.lightblue,
    },
    borderBottomColor: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.common.greyblueDark
        : theme.palette.common.lightblue,
  },
  '& .MuiSvgIcon-root': {
    color: (theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.greyblueDark,
  },
}));
