import React, { useContext, useEffect, useState } from 'react';
import usersUtils from '../../../utils/usersUtils';
import {
  TextField,
  Box,
  Collapse,
  Alert,
  InputAdornment,
  IconButton,
  Button,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import FontAwesomeIcon from '../../../components/FAIcon';
import { Controller } from 'react-hook-form';
import { Context as FormContext } from '../userPopup/formContext';
import StyledSwitch from '../../../components/form/StyledSwitch';
import { SecondStyledTexfield } from '../../../components/form/StyledTextfield';
import { StyledSelect } from '../../../components/form/StyledSelect';

export const TextInput = ({ register, errors, options, handleOnBlur = () => {} }) => {
  const {
    id,
    label,
    field,
    required,
    pattern,
    minLength,
    maxLength,
    minNumber,
    maxNumber,
  } = options;
  const { updateForm, state, setErrorForm } = useContext(FormContext);

  const onChangeFun = (event) => {
    if (state) setErrorForm({ value: errors });
    updateForm({ field, value: event.target.value });
  };

  useEffect(() => {
    if (errors[field]) setErrorForm({ value: errors });
  }, [errors]);

  return (
    <SecondStyledTexfield
      {...usersUtils.registerOptions(
        register,
        label,
        field,
        required,
        pattern,
        minLength,
        maxLength,
        minNumber,
        maxNumber,
        onChangeFun,
        handleOnBlur
      )}
      id={id}
      helperText={errors[field] ? errors[field].message : ''}
      label={label}
      name={field}
      variant="outlined"
      aria-invalid={errors[field] ? 'true' : 'false'}
      error={errors[field] ? true : false}
      sx={{ flex: 1 }}
    />
  );
};

export const PasswordInput = ({
  register,
  errors,
  passwordCheck,
  setError,
  setValue,
  genButton,
  clearErrors,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState(true);

  const { state, updateForm, setErrorForm } = useContext(FormContext);

  const [labelPassword, setLabelPassword] = useState(false);
  const onChangeFun = (value) => {
    updateForm({ field: 'password', value });
    setErrorForm({ value: errors });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    state.formValues.password.length > 0
      ? setLabelPassword(true)
      : setLabelPassword(false);
  }, [state.formValues.password]);

  useEffect(() => {
    if (errors['password']) setErrorForm({ value: errors });
  }, [errors]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <FormControl sx={{ flex: '3' }}>
          <SecondStyledTexfield
            {...register('password', {
              required: {
                value: true,
                message: 'The Password is required.',
              },
              validate: (value) =>
                usersUtils.validatePassword(value) ||
                'The Password must contain a minimum of 8 characters, at least one upper case letter, one lower case letter, one number and one special character.',
              onChange: (e) => {
                onChangeFun(e.target.value);
                setValue('password', e.target.value);
                if (usersUtils.validatePassword(e.target.value)) {
                  clearErrors('password');
                }
              },
              onBlur: (e) => {
                if (!usersUtils.validatePassword(e.target.value)) {
                  setError('password', {
                    type: 'error',
                    message:
                      'The Password must contain a minimum of 8 characters, at least one upper case letter, one lower case letter, one number and one special character.',
                  });
                } else {
                  clearErrors('password');
                }
              },
            })}
            error={errors.password ? true : false}
            id="password-input"
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            defaultValue=""
            label="Password"
            InputLabelProps={{ shrink: labelPassword }}
            aria-invalid={errors.password ? true : false}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon="fas fa-eye-slash" />
                    ) : (
                      <FontAwesomeIcon icon="fas fa-eye" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        {genButton ? (
          <Button
            id="generate-password"
            variant="text"
            sx={{
              flex: '1',
              fontSize: '12px',
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblueDark',
            }}
            onClick={() => {
              genButton();
            }}
          >
            Generate Password
          </Button>
        ) : null}
      </Box>
      <Box>
        {errors.password && (
          <Collapse
            in={passwordInfo}
            sx={{ margin: '5px 0', '& .MuiPaper-root': { alignItems: 'center' } }}
          >
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setPasswordInfo(false);
                  }}
                >
                  <FontAwesomeIcon icon="fas fa-times-circle" size="14px" />
                </IconButton>
              }
              sx={{
                fontSize: { xs: 11, md: 12 },
                padding: { xs: '2px 10px' },
                '& .MuiAlert-action': { padding: 0 },
              }}
            >
              {errors.password.message}
            </Alert>
          </Collapse>
        )}
        {usersUtils.validatePassword(state.formValues.password) && (
          <Collapse
            in={usersUtils.validatePassword(state.formValues.password)}
            sx={{ margin: '5px 0', '& .MuiPaper-root': { alignItems: 'center' } }}
          >
            <Alert
              severity="success"
              sx={{
                fontSize: { xs: 11, md: 12 },
                padding: { xs: '2px 10px' },
                '& .MuiAlert-action': { padding: 0 },
              }}
            >
              {/* {message.message} */}
              Your password is strong enough.
            </Alert>
          </Collapse>
        )}
      </Box>
    </Box>
  );
};

export const SwitchPW = ({ register }) => {
  const { state, updateForm } = useContext(FormContext);
  const [isChangePw, setIsChangePw] = useState(state.formValues.changePW);

  const onChangeFun = (event) => {
    updateForm({ field: 'changePW', value: event.target.checked });
    setIsChangePw(!isChangePw);
  };

  return (
    <FormControlLabel
      sx={{ margin: '10px 0' }}
      control={
        <StyledSwitch
          // {...usersUtils.registerOptions(register, 'changePW', 'changePW')}
          {...register('changePW', { onChange: onChangeFun })}
          checked={isChangePw}
          // onChange={() => setIsChangePw(!isChangePw)}
          defaultValue={false}
        />
      }
      label="Request user to stablish a new password on first login"
    />
  );
};

export const SwitchActive = ({ register }) => {
  const { state, updateForm } = useContext(FormContext);
  const [isActive, setIsActive] = useState(state.formValues.active);

  const onChangeFun = (event) => {
    updateForm({ field: 'active', value: event.target.checked });
    setIsActive(!isActive);
  };

  return (
    <FormControlLabel
      sx={{ color: 'common.greyblueDark', margin: 0 }}
      control={
        <StyledSwitch
          {...register('active', { onChange: onChangeFun })}
          checked={isActive}
          // onChange={() => setIsActive(!isActive)}
          defaultValue={true}
        />
      }
      label="Active"
    />
  );
};

export const SelectRole = ({ register, control }) => {
  const { state, updateForm } = useContext(FormContext);

  const onChangeFun = (event) => updateForm({ field: 'role', value: event.target.value });
  return (
    <FormControl variant="outlined" sx={{ margin: 0, width: '100%' }}>
      <InputLabel id="role-select-label">User Role</InputLabel>
      <Controller
        render={() => (
          <StyledSelect
            // {...usersUtils.registerOptions(register, 'role', 'role')}
            {...register('role', { onChange: onChangeFun })}
            id="role-select"
            defaultValue="USER"
            value={state.formValues.role}
            label="User Role"
          >
            <MenuItem value="ADMIN">ADMIN</MenuItem>
            <MenuItem value="EDITOR">EDITOR</MenuItem>
            <MenuItem value="USER">USER</MenuItem>
          </StyledSelect>
        )}
        control={control}
        name="role"
      />
    </FormControl>
  );
};

export const SelectTitle = ({ register, control }) => {
  const { state, updateForm } = useContext(FormContext);

  const onChangeFun = (event) =>
    updateForm({ field: 'title', value: event.target.value });
  return (
    <FormControl variant="outlined" sx={{ flex: 1 }}>
      <InputLabel id="title-outlined-label">Title</InputLabel>
      <Controller
        render={() => (
          <StyledSelect
            {...register('title', { onChange: onChangeFun })}
            labelId="title-outlined-label"
            id="title-select"
            label="Title"
            defaultValue="None"
            value={state.formValues.title}
          >
            <MenuItem value="None">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Mr">Mr</MenuItem>
            <MenuItem value="Mrs">Mrs</MenuItem>
            <MenuItem value="Ms">Ms</MenuItem>
          </StyledSelect>
        )}
        name="title"
        control={control}
      />
    </FormControl>
  );
};
