import {
  Box,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import React, { useEffect } from 'react';
import FontAwesomeIcon from '../../components/FAIcon';
import { User } from './ListItemOrg';

interface ListItemUserProps {
  usersList: Array<string>;
  allChecked: boolean;
  blackList: Array<string>;
  setBlackList: React.Dispatch<React.SetStateAction<string[]>>;
  user: User;
  orgChecked: boolean;
  setUsersList: React.Dispatch<React.SetStateAction<string[]>>;
  setOrgChecked: React.Dispatch<React.SetStateAction<boolean>>;
  allUsersOrgChecked: () => void;
}

const ListItemUser = ({
  user,
  usersList,
  setUsersList,
  orgChecked,
  setOrgChecked,
  allChecked,
  blackList,
  setBlackList,
  allUsersOrgChecked,
}: ListItemUserProps) => {
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (allChecked && orgChecked) return setChecked(true);
    if (usersList.length === 0) return setChecked(false);
    let isChecked = usersList.includes(user.id);
    isChecked ? setChecked(true) : setChecked(false);
  }, [usersList, orgChecked, allChecked]);

  useEffect(() => {
    if (blackList.length === 0) return;
    let noChecked = blackList.includes(user.id);
    if (noChecked) setChecked(false);
  }, [blackList]);

  let filterUsers = (arr: string[]) => arr.filter((id) => id !== user.id);

  const handleClick = () => {
    // debugger;
    if (allChecked && checked) {
      setOrgChecked(false);
      setBlackList((prevState) => [...prevState, user.id]);
    } else if (!checked) {
      let filter = filterUsers(blackList);
      setBlackList(filter);
      setUsersList((prevState) => [...prevState, user.id]);
      allUsersOrgChecked();
    } else {
      let newList = filterUsers(usersList);
      setUsersList(newList);
      if (allChecked) setBlackList((prev) => [...prev, user.id]);
      allUsersOrgChecked();
    }
  };

  return (
    <ListItem
      key={user.id}
      secondaryAction={
        <Box sx={{ marginTop: 0.5 }}>
          <FontAwesomeIcon
            icon="fa-solid fa-user"
            color="common.greyblueDark"
            size={15}
          />
        </Box>
      }
      disablePadding
      sx={{
        p: 0,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? 'black.light' : 'common.white',
        '&:hover': {
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? 'black.dark' : 'common.lightblue',
        },
      }}
    >
      <ListItemButton role={undefined} dense>
        <ListItemIcon>
          <Checkbox
            name={user.id}
            value={user.id}
            checked={checked}
            disableRipple
            inputProps={{ 'aria-labelledby': user.id }}
            size="small"
            onClick={handleClick}
          />
        </ListItemIcon>
        <ListItemText
          id={user.id}
          primary={user.firstName + ' ' + user.lastName}
          primaryTypographyProps={{ sx: { fontSize: 11 } }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ListItemUser;
