import { Box, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import { getDataByFrecuency, getTotal } from '../../../../../utils/dashboardUtils';
import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_BY_ORG,
} from '../../../../../utils/gql/gqlDashboard';
import { Folder } from '../../../../folder/types';
import { Frecuency } from '../../../DashboardMain';
import { folderDePrueba, folderDePrueba2 } from '../../../MockupData';
import CustomTooltip from './CustomTooltip';
import FolderSwitcher from './FolderSwitcher';

const classes = {
  titulo: {
    fontSize: 13,
    fontWeight: 400,
  },
  total: {
    color: 'common.greyblueDark',
    fontSize: 15,
    margin: 0,
    fontWeight: 500,
  },
  position: {
    position: 'relative',
    height: '45px',
    margin: 0,
    left: 10,
    top: 4,
  },
  paper: {
    margin: 0,
    padding: 0,
    width: 'inherit',
    height: '125px',
  },
};

interface Props {
  color: string;
  title: string;
  stepper: boolean;
  data: any;
  type: string;
  frecuency: Frecuency;
  folders?: Folder[];
  isSuser?: boolean;
  selectedOrg?: string;
}

export const CustomAreaChart = ({
  color,
  title,
  stepper = false,
  data,
  type = 'scanned',
  frecuency,
  folders,
  isSuser = false,
  selectedOrg,
}: Props) => {
  const initialData = getDataByFrecuency(folderDePrueba2, type, frecuency.type);

  const [datos, setDatos] = useState(initialData);
  const [getData, { loading, error, data: dataFolder }] = useLazyQuery(
    isSuser ? GET_DASHBOARD_DATA : GET_DASHBOARD_DATA_BY_ORG
  );

  let total = getTotal(datos, type);

  const getFolderData = (id: string, type: string, frecuency: Frecuency) => {
    let { points } = frecuency;

    type Peticion = {
      folderId: string;
      content: { type: string; points: number }[];
      organisationId?: string | { id: string; name?: string };
    };

    const peticion: Peticion = {
      folderId: id,
      content: [{ type, points }],
    };
    if (isSuser) {
      peticion['organisationId'] = selectedOrg;
    }
    window.log(peticion);
    getData({ variables: peticion });

    // setDatos(dataFolder);
    const dataByFrecuency = getDataByFrecuency(folderDePrueba, type, frecuency.type);
    setDatos(dataByFrecuency);
  };

  return (
    <Paper sx={classes.paper}>
      <Box sx={classes.position}>
        <Typography variant="subtitle1" sx={classes.titulo}>
          {title}
        </Typography>
        <Typography variant="subtitle1" sx={classes.total}>
          {total}
        </Typography>
        {stepper && (
          <FolderSwitcher
            folder={data.name}
            folders={folders}
            getFolderData={getFolderData}
            type={type}
            frecuency={frecuency}
          />
        )}
      </Box>
      <ResponsiveContainer width="100%" height={80}>
        <AreaChart width={270} height={80} data={datos} margin={{ bottom: 0 }}>
          <defs>
            <linearGradient id={`data-${color}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.7} />
              <stop offset="100%" stopColor={color} stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey={type === 'users' ? 'value.scanned' : `value.${type}`}
            stroke={color}
            strokeWidth={4}
            fillOpacity={1}
            fill={`url(#data-${color})`}
          />
          <Tooltip content={<CustomTooltip type={type} color={color} />} offset={45} />
        </AreaChart>
      </ResponsiveContainer>
    </Paper>
  );
};
