import React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
// Mui
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// Utils
import { UPDATE_USER } from '../../../../utils/gql/gqlUsers';
import usersUtils from '../../../../utils/usersUtils';

// Components
import UserAvatar from './UserAvatar';
import StatusChip from '../../../../components/StatusChip';
import GeneralSnackbar, { StatusProps } from '../../../../components/SnackBar';
import { Box, FormControl, InputLabel, Select } from '@mui/material';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { User } from '../../../../components/masterTable/masterTableTypes';
import { SecondStyledTexfield } from '../../../../components/form/StyledTextfield';
import { StyledSelect } from '../../../../components/form/StyledSelect';
import StyledSwitch from '../../../../components/form/StyledSwitch';
import { StyledPrimaryButton } from '../../../../components/form/StyledButtons';

interface FormInputs {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  emailaddress: string;
  role: string;
  isChangePw: boolean;
  active: boolean;
  password: string;
  mobilenumber?: string;
}

interface IUser extends User {
  status?: 'online' | 'offline' | 'await' | undefined;
  mobilenumber?: string;
}

interface UserProfileTabProps {
  user: IUser;
}

export default function UserProfileTab({ user }: UserProfileTabProps) {
  const titles = ['Mr', 'Mrs', 'Ms'];
  const [currentUser] = useLocalStorage('userData');
  const [isActive, setIsActive] = useState<boolean>(true);
  // used by react-hook-form to handle the form
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormInputs>();

  const [snackbar, setSnackbar] = React.useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'warning',
  });

  const handleCloseSnackbar = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };

  const [updateUserMutation] = useMutation(UPDATE_USER);
  const updateUser = (values: FormInputs) => {
    let variables: any = {
      id: user.id,
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailaddress.toLowerCase(),
      role: values.role,
      changePw: values.isChangePw,
      active: values.active,
    };
    if (values.password !== '') {
      variables.password = values.password;
    }
    return updateUserMutation({ variables });
  };

  const onUserUpdate = async () => {
    const values = getValues();
    updateUser({ ...values })
      .then(({ data }) => {
        setSnackbar({
          status: true,
          snackbarMessage: 'User Updated Successfully',
          severity: 'success',
        });
      })
      .catch((e) => {
        // window.log(e);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onUserUpdate)}>
        <Grid container spacing={2}>
          <Grid container item spacing={2} direction="row-reverse">
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              spacing={1}
              width="min-content"
              xs={12}
              sm={6}
              md={8}
            >
              <Grid item>
                <UserAvatar
                  firstName={user.firstName}
                  lastName={user.lastName}
                  fontSize="3rem"
                  width="5rem"
                  height="5rem"
                  connected={false}
                />
              </Grid>
              <Grid item>
                <StatusChip status={user.status} width="3.5rem" height="1.2rem" />
              </Grid>
            </Grid>

            <Grid item alignSelf="end" xs={12} sm={6} md={4}>
              <FormControl variant="outlined" sx={{ margin: 0, width: '100%' }}>
                <InputLabel id="title-simple-select-outlined">Title</InputLabel>
                <Controller
                  render={() => (
                    <StyledSelect
                      fullWidth
                      {...usersUtils.registerOptions(register, 'Title', 'title')}
                      id="title-select"
                      defaultValue={user.title ? user.title : 'None'}
                      label="Title"
                    >
                      <MenuItem value="None">
                        <em>None</em>
                      </MenuItem>
                      {titles.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  )}
                  control={control}
                  name="title"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <SecondStyledTexfield
                fullWidth
                label="First Name"
                variant="outlined"
                defaultValue={user.firstName}
                control={control}
                {...usersUtils.registerOptions(
                  register,
                  'first name',
                  'firstName',
                  true,
                  /^[A-Za-zÁÉÍÓÚáéíóúñÑ -]+$/g,
                  3,
                  25
                )}
                aria-invalid={errors.firstName ? 'true' : 'false'}
                error={errors.firstName ? true : false}
                helperText={errors.firstName ? errors.firstName.message : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SecondStyledTexfield
                fullWidth
                label="Last Name"
                variant="outlined"
                errors={errors}
                defaultValue={user.lastName}
                control={control}
                {...usersUtils.registerOptions(
                  register,
                  'last name',
                  'lastName',
                  true,
                  /^[A-Za-zÁÉÍÓÚáéíóúñÑ -]+$/g,
                  3,
                  25
                )}
                aria-invalid={errors.lastName ? 'true' : 'false'}
                error={errors.lastName ? true : false}
                helperText={errors.lastName ? errors.lastName.message : ''}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <SecondStyledTexfield
              label="Mobile Number"
              type="tel"
              variant="outlined"
              fullWidth
              defaultValue={user.mobilenumber}
              control={control}
              {...usersUtils.registerOptions(
                register,
                'mobile number',
                'mobilenumber',
                false,
                /^[0-9]+$/,
                6,
                30,
                0,
                10000000000000
              )}
              aria-invalid={errors.mobilenumber ? 'true' : 'false'}
              error={errors.mobilenumber ? true : false}
              helperText={errors.mobilenumber ? errors.mobilenumber.message : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SecondStyledTexfield
              label="Email Address"
              variant="outlined"
              fullWidth
              defaultValue={user.email}
              control={control}
              {...usersUtils.registerOptions(
                register,
                'email',
                'emailaddress',
                true,
                /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i,
                8,
                50
              )}
              aria-invalid={errors.emailaddress ? 'true' : 'false'}
              error={errors.emailaddress ? true : false}
              helperText={errors.emailaddress ? errors.emailaddress.message : ''}
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            alignItems="center"
            justifyContent={currentUser.role !== 0 ? 'flex-end' : undefined}
          >
            {currentUser.id !== user.id ? (
              <Grid item xs={6} sm={4}>
                <FormControl variant="outlined" sx={{ margin: 0, width: '100%' }}>
                  <InputLabel id="role-simple-select-outlined">Role</InputLabel>
                  <Controller
                    render={() => (
                      <StyledSelect
                        fullWidth
                        {...usersUtils.registerOptions(register, 'role', 'role', true)}
                        id="role-select"
                        defaultValue={user.role}
                        label="Role"
                      >
                        <MenuItem value="ADMIN">ADMIN</MenuItem>
                        <MenuItem value="EDITOR">EDITOR</MenuItem>
                        <MenuItem value="USER">USER</MenuItem>
                      </StyledSelect>
                    )}
                    control={control}
                    name="role"
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={6} sm={4}>
                <Box sx={{ width: '100%' }}></Box>
              </Grid>
            )}
            {currentUser.id !== user.id ? (
              <Grid item xs={6}>
                <FormControlLabel
                  sx={{ color: 'common.greyblueDark' }}
                  control={
                    <StyledSwitch
                      {...register('active', {
                        onChange: () => setIsActive(!isActive),
                      })}
                      checked={isActive}
                      defaultChecked={user && user.active}
                    />
                  }
                  label="Active"
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid item container justifyContent="end">
            <StyledPrimaryButton type="submit" variant="contained">
              Update
            </StyledPrimaryButton>
          </Grid>
        </Grid>
      </form>
      <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
    </>
  );
}
