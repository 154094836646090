import * as React from 'react';
import { loadCSS } from 'fg-loadcss';
import Icon from '@mui/material/Icon';

interface FAIconProps {
  icon?: string;
  size?: number | string;
  padding?: number | string;
  color?: string;
}

export default function FontAwesomeIcon({ icon, size, padding, color }: FAIconProps) {
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.14.0/css/all.css',
      // Inject before JSS
      document.querySelector('#font-awesome-css') || document.head.firstChild
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <Icon
      baseClassName="fas"
      className={icon}
      sx={{ fontSize: size, padding: padding, color: color, width: 'auto' }}
    />
  );
}
