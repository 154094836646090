import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import gqlEmails from '../utils/gql/gqlEmails';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import {
  TextField,
  Grid,
  Box,
  Chip,
  Collapse,
  Alert,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { createFab } from './collectionBin/utils';
import FontAwesomeIcon from './FAIcon';

const classes = {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '4px',
    margin: 0,
    maxHeight: 40,
    overflowY: 'scroll',
  },
  helperText: {
    color: 'blue',
  },
  progress: {
    height: '10px',
  },
  chip: {
    fontFamily: 'inherit',
    color: 'common.white',
    backgroundColor: 'common.greyblueDark',
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: '10px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
  },
};

const error = (collapse, message, handleClose) => {
  return (
    <Collapse in={collapse} sx={{ margin: '5px 0' }}>
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon="fas fa-times-circle" size="14px" />
          </IconButton>
        }
        sx={{ fontSize: { xs: 12, md: 14 }, padding: { xs: '2px 10px' } }}
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const Emailing = ({ collection, collectionBin = false, setSnackbar, styles, value }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();
  const [createEmailMutation, { loading }] = useMutation(gqlEmails.SEND_EMAIL);

  const [chipData, setChipData] = useState(collection);

  const createEmail = (values, recordIds) => {
    return createEmailMutation({
      variables: {
        to: values.recipients,
        subject: values.subject,
        body: values.body,
        recordIds: recordIds,
        html: `<p>${values.body}</p>`,
      },
    });
  };

  const onEmailCreation = async (values) => {
    let recordIds = [];
    let emailAddresses = [];
    emailAddresses.push(values.recipients);

    chipData.map((record) => {
      recordIds.push({ recordId: record.id });
      return null;
    });
    createEmail({ ...values, recipient: emailAddresses }, recordIds)
      .then(({ data }) => {
        window.log('Created Email....', data);
        // refetch().then(() => {
        // });
        setSnackbar({
          status: true,
          snackbarMessage: `Email sent.`,
          severity: 'success',
        });
      })
      .catch((e) => {
        window.log(e);
      });
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
  };
  const customButton = {
    color: '',
    className: classes.fab,
    icon: <SendIcon fontSize="small" />,
    label: 'Send Email',
    onClick: handleSubmit(onEmailCreation),
  };
  useEffect(() => {
    setChipData(collection);
  }, [collection]);
  return (
    <>
      <Grid container direction="column">
        <form onSubmit={handleSubmit(onEmailCreation)} className={classes.form}>
          <Grid
            container
            direction="column"
            justify="space-around"
            spacing={2}
            className={classes.userCreationContainer}
          >
            <Grid item>
              <TextField
                {...register('recipients', {
                  required: true,
                })}
                fullWidth
                label="Recipients"
                name="recipients"
                size="small"
                type="email"
                variant="outlined"
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                error={errors && errors.recipients ? true : false}
                sx={styles.textfield}
              />
              {errors && errors.recipients
                ? error(true, 'Please add a valid email', () => clearErrors('recipients'))
                : null}
            </Grid>
            <Grid item>
              <TextField
                {...register('subject')}
                fullWidth
                label="Subject"
                size="small"
                name="subject"
                variant="outlined"
                defaultValue="Please find your documents attached"
                helperText={errors && errors.subject && 'Please add a subject'}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                sx={styles.textfield}
              />
            </Grid>

            <Grid item>
              <TextField
                {...register('body')}
                fullWidth
                multiline
                id="body"
                label="Body"
                name="body"
                type="text"
                size="small"
                rows={3}
                variant="outlined"
                sx={{ ...styles.textfield, backgroundColor: '#0a54875c' }}
              />
            </Grid>
            {loading ? (
              <>
                <Grid item>
                  <LinearProgress sx={classes.progress} />
                </Grid>
              </>
            ) : null}
            <Grid item sx={classes.scrollbar}>
              <Box
                sx={{
                  ...classes.root,
                  ...classes.scrollbar,
                  gap: 1,
                  maxHeight: '150px',
                  minHeight: '65px',
                  height: 'auto',
                  overflowY: chipData.length > 10 ? 'scroll' : 'auto',
                  display: chipData.length > 0 ? 'flex' : 'none',
                }}
              >
                {chipData.map((record, i) => {
                  const icon = <AttachFileIcon />;
                  if (record) {
                    const mainField = record.fields.filter((field, j) => {
                      if (record.folderId.primaryFolderFieldId === field.field.id) {
                        return field;
                      }
                      if (!record.folderId.primaryFolderFieldId && j === 0) {
                        return field;
                      } else {
                        return null;
                      }
                    });
                    if (mainField[0]) {
                      return (
                        <Box
                          sx={{
                            '&:hover': {
                              transform: 'scale(1.05)',
                              transition: 'transform .5s ease',
                            },
                          }}
                          key={i}
                        >
                          <Chip
                            size="small"
                            icon={icon}
                            label={`${mainField[0].value}`}
                            onDelete={
                              mainField[0].value === 'React'
                                ? undefined
                                : handleDelete(record)
                            }
                            sx={classes.chip}
                            key={i}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })}
              </Box>
            </Grid>
          </Grid>
        </form>
        {createFab(customButton, 0, value)}
      </Grid>
    </>
  );
};

export default Emailing;
