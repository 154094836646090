import { Box } from '@mui/material';
import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { User } from '../../../components/masterTable/masterTableTypes';
import { Step, Workflow } from '../types';
import StepCard from './StepsCards/StepCard';
import { Note } from '../../../components/Notes/notesTypes';

interface StepsContainerProps {
  selectedWorkflow: Workflow | null;
  users: User[];
  currentProcessStep: Step;
  refCardsContainer: any;
  recordPage?: boolean;
  handleUpdateStep: (stepOrder: number, key: string, value: any) => void;
  handleRemoveStep: (stepOrder: number, id: string) => void;
  toggleNotesDrawer?: (note: Note | null) => void;
}

const StepsContainer = ({
  selectedWorkflow,
  users,
  refCardsContainer,
  handleUpdateStep,
  handleRemoveStep,
  currentProcessStep,
  recordPage,
  toggleNotesDrawer= () => {},
}: StepsContainerProps) => {
  const handleOnDragEnd = (data: DropResult) => {
    // if (data.destination)
    //   handleUpdateStepOrders(data.source.index, data.destination.index);
  };

  return (
    <Box ref={refCardsContainer} sx={{ height: '100%' }}>
      <DragDropContext onDragEnd={(data) => handleOnDragEnd(data)}>
        <Droppable
          droppableId="droppable"
          direction="vertical"
          mode="standard"
          isDropDisabled={true}
        >
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '100%',
                gap: '8px',
                maxHeight: '95%',
                paddingRight: 2,
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
                },

                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#79909f',
                  borderRadius: '4px',
                },
              }}
            >
              {selectedWorkflow &&
                selectedWorkflow.processSteps &&
                selectedWorkflow.processSteps.map((step: Step, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: recordPage ? '95%' : '100%',
                    }}
                  >
                    <StepCard
                      step={step}
                      handleRemoveStep={handleRemoveStep}
                      handleUpdateStep={handleUpdateStep}
                      users={users}
                      currentProcessStep={currentProcessStep}
                      toggleNotesDrawer={toggleNotesDrawer}
                    />
                  </Box>
                ))}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default StepsContainer;
