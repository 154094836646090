import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from '../../LoadingOverlay';

interface RenderedPDFViewerProps {
  value:
    | {
        url: string;
        blob: Blob;
      }
    | undefined;
  error: any;
  pdfIsRendered: boolean;
  setPdfIsRendered: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RenderedPDFViewer: React.FC<RenderedPDFViewerProps> = ({ ...props }) => {
  const [onLoadIframe, setOnLoadIframe] = useState<number>(0);
  const [iframeIsRendered, setIframeIsRendered] = useState(false);
  const { value, error, setPdfIsRendered, pdfIsRendered } = props;

  const src = value?.url ? `${value.url}#toolbar=${1}` : undefined;

  useEffect(() => {
    if (onLoadIframe === 2) {
      setPdfIsRendered(true);
    }
  }, [onLoadIframe]);

  if (error) {
    console.log({ error });
    return <div style={{ backgroundColor: '#ffffff' }}>{JSON.stringify(error)}</div>;
  }

  return (
    <>
      {!src ? (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingX: '10rem',
            textAlign: 'center',
          }}
        >
          <Typography variant="body2" sx={{ color: 'common.white', fontWeight: 'bold' }}>
            The report is to big to generate a preview, please use the button bellow to
            download a CSV copy of your generated report
          </Typography>
        </Box>
      ) : (
        <iframe
          title="pdf"
          src={src}
          style={{
            height: '100%',
            width: '100%',
            border: 'none',
            visibility: pdfIsRendered ? 'visible' : 'hidden',
            display: pdfIsRendered ? 'block' : 'none',
          }}
          {...props}
          onLoad={() => setOnLoadIframe((prev) => prev + 1)}
        />
      )}
    </>
  );

  // if (iframeIsRendered) {
  //   return (
  //     <>
  //       <LoadingOverlay text="Loading Preview" backgroundColor={'rgb(242 242 242)'} />
  //       <iframe
  //         title="pdf"
  //         src={src}
  //         style={{
  //           height: '100%',
  //           width: '100%',
  //           border: 'none',
  //           visibility: iframeIsRendered ? 'visible' : 'hidden',
  //         }}
  //         {...props}
  //         onLoad={() => setOnLoadIframe((prev) => prev + 1)}
  //       />
  //     </>
  //   );
  // } else {
  //   return <div>Rendering PDF...</div>;
  // }
};
