import { useEffect, useState } from 'react';
import { Box, Card, CardActions, CardHeader, Checkbox, Typography } from '@mui/material';
import { PermissionsGroupCardProps } from './permissionsTypes';
import FontAwesomeIcon from '../../../../../components/FAIcon';

const PermissionsGroupCard = ({
  onDeleteTemplate,
  onApplyTemplateToUser,
  permission,
  userHasTemplate,
  onRemoveTemplateToUser,
}: PermissionsGroupCardProps) => {
  let hasTemplate =
    userHasTemplate && userHasTemplate?.templateName === permission.templateName;
  const [displayTrash, setDisplayTrash] = useState(false);
  const [checkValue, setCheckValue] = useState(hasTemplate ? true : false);

  useEffect(() => {
    setCheckValue(hasTemplate ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasTemplate]);

  return (
    <Box
      onMouseLeave={() => setDisplayTrash(false)}
      onMouseOver={() => setDisplayTrash(true)}
    >
      <Card
        sx={{
          height: '100px',
          width: '100px',
          backgroundColor: '#f0f0f0',
        }}
      >
        <CardHeader
          title={
            <Typography
              sx={{ fontWeight: 500, color: 'common.greyblueDark', fontSize: 11 }}
              title={
                permission.templateName && permission?.templateName?.length > 9
                  ? permission.templateName
                  : undefined
              }
            >
              {permission.templateName && permission?.templateName?.length > 9
                ? permission?.templateName?.slice(0, 9 - 1) + '...'
                : permission?.templateName}
            </Typography>
          }
          action={
            <Checkbox
              aria-label="settings"
              size="small"
              checked={checkValue}
              sx={{
                color: 'common.greyblueDark',
                '&.Mui-checked': {
                  color: 'common.greyblueDark',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onChange={(e) => {
                checkValue
                  ? onRemoveTemplateToUser(e)
                  : onApplyTemplateToUser(permission);
                setCheckValue(e.target.checked);
              }}
            />
          }
          sx={{
            '&.MuiCardHeader-root': {
              padding: '0 5px 0 5px',
            },
            '.MuiCardHeader-title': {
              fontSize: 11,
              color: 'common.greyblueDarker',
            },
          }}
        />
        <Box
          sx={{
            width: '32px',
            height: '32px',
            margin: 'auto',
            marginTop: 1,
          }}
        >
          <FontAwesomeIcon
            icon="far fa-users-cog"
            size="30px"
            padding={0}
            color={'#cccccc4b'}
          />
        </Box>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Box
            sx={{
              visibility: displayTrash ? 'visible' : 'hidden',
              '&:hover': { cursor: 'pointer' },
            }}
            onClick={() => onDeleteTemplate(permission.id)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" size={11} padding={0} color="red" />
          </Box>
        </CardActions>
      </Card>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: '2px',
        }}
      >
        {/* <Typography
          sx={{ fontWeight: 500, color: 'common.greyblueDark' }}
          title={
            permission.templateName && permission?.templateName?.length > 12
              ? permission.templateName
              : undefined
          }
        >
          {permission.templateName && permission?.templateName?.length > 12
            ? permission?.templateName?.slice(0, 12 - 1) + '...'
            : permission?.templateName}
        </Typography> */}
        {/* <Box
          sx={{
            display: 'block',
            visibility: displayTrash ? 'visible' : 'hidden',
            position: 'relative',
            top: '-20px',
            left: -4,
            '&:hover': { cursor: 'pointer' },
          }}
          onClick={() => onDeleteTemplate(permission.id)}
        >
          <FontAwesomeIcon icon="fa-solid fa-trash" size={11} padding={0} color="red" />
        </Box> */}
      </Box>
    </Box>
  );
};

export default PermissionsGroupCard;
