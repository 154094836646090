import { Box } from '@mui/material';
import { User } from '../../../../components/masterTable/masterTableTypes';
import { Step } from '../../types';
import Approval from './Approval';
import { Note } from '../../../../components/Notes/notesTypes';

interface StepCardProps {
  step: Step;
  handleRemoveStep: (stepOrder: number, id: string) => void;
  handleUpdateStep: (stepOrder: number, key: string, value: any) => void;
  users: User[];
  currentProcessStep: Step;
  toggleNotesDrawer: (note: Note | null) => void;
}

const StepCard = ({
  handleRemoveStep,
  step,
  handleUpdateStep,
  users,
  currentProcessStep,
  toggleNotesDrawer,
}: StepCardProps) => {
  switch (step.__typename) {
    case 'ApprovalStep':
      return (
        <Approval
          step={step}
          handleUpdateStep={handleUpdateStep}
          users={users}
          currentProcessStep={currentProcessStep}
          toggleNotesDrawer={toggleNotesDrawer}
        />
      );
    case 'signature':
      return (
        <Box>signature</Box>
        // <SignatureCard
        //   handleRemoveStep={handleRemoveStep}
        //   step={step}
        //   handleUpdateStep={handleUpdateStep}
        //   users={users}
        // />
      );
    case 'picture':
      return (
        <Box>picture</Box>
        // <PictureCard
        //   handleRemoveStep={handleRemoveStep}
        //   step={step}
        //   handleUpdateStep={handleUpdateStep}
        //   users={users}
        // />
      );

    default:
      return (
        <Approval
          step={step}
          handleUpdateStep={handleUpdateStep}
          users={users}
          currentProcessStep={currentProcessStep}
          toggleNotesDrawer={toggleNotesDrawer}
        />
      );
  }
};

export default StepCard;
