import {
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UseFormResetField } from 'react-hook-form';
import FontAwesomeIcon from '../../components/FAIcon';
import { OperationVariables, useLazyQuery } from 'react-apollo';
import { GET_USERS_BY_ORG } from '../../utils/gql/gqlUsers';
import ListItemUser from './ListItemUser';
import GroupIcon from '@material-ui/icons/Group';
import { FormValues } from './CreateNotificationTab';

export type User = {
  title: string;
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  createdAt: any;
  updatedAt: any;
  email: string;
  active: boolean;
  userPreferences: {
    theme: string;
    emailNotifications: boolean;
  };
};

export interface ListItemOrgProps {
  org: {
    id: string;
    name: string;
  };
  usersList: Array<string>;
  setUsersList: React.Dispatch<React.SetStateAction<string[]>>;
  allChecked: boolean;
  blackList: Array<string>;
  setBlackList: React.Dispatch<React.SetStateAction<string[]>>;
  orgsChecked: Array<string>;
  setOrgsChecked: React.Dispatch<React.SetStateAction<string[]>>;
  setAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
  resetField: UseFormResetField<FormValues>;
}

interface QueryVariablesProps extends OperationVariables {
  checkUsers?: boolean;
  organisationId?: string;
}

const ListItemOrg = ({
  org,
  usersList,
  setUsersList,
  allChecked,
  blackList,
  setBlackList,
  orgsChecked,
  setOrgsChecked,
  setAllChecked,
  resetField,
}: ListItemOrgProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [usersID, setUsersID] = useState<string[]>([]);
  const [orgChecked, setOrgChecked] = React.useState<boolean>(false);

  const [getUsers, { data, variables }] = useLazyQuery<any, QueryVariablesProps>(
    GET_USERS_BY_ORG,
    {
      variables: { organisationId: org.id },
      // skip: users.length !== 0, // if there are already users uploaded, the query is not submitted
      onCompleted: () => {
        if (data) {
          let users = data.UsersByOrg.users;
          let usersID = users.map((usr: User) => usr.id);
          setUsersID(usersID);
          setUsers(users);
          // the query can be to select the whole organization or to open only a tab
          if (variables.checkUsers) {
            let usersID = users.map((usr: User) => usr.id);
            let usersListArr = [...usersID, ...usersList];
            let newUsersList = usersListArr.filter(
              (id, index) => usersListArr.indexOf(id) === index
            );
            setUsersList(newUsersList);
          }
        }
      },
    }
  );

  const deleteAllUsersFromOrg = () => {
    let usersListArr = usersList;
    let newList = usersListArr.filter((usr) => {
      let res = usersID.find((usrID) => usrID === usr);

      return res === undefined;
    });
    setUsersList(newList);
  };

  const allUsersOrgChecked = () => {
    let usersListArr = usersList;
    let usersOrgByOrg = [];
    usersListArr.forEach((usr) =>
      usersID.forEach((usrID) => usrID === usr && usersOrgByOrg.push(usrID))
    );
    if (usersOrgByOrg.length === 1) setOrgChecked(false);
  };

  const checkAllUsersFromOrg = () =>
    setUsersList((prevState) => [...prevState, ...usersID]);

  const handleClick = () => {
    setOpen(!open);
    getUsers();
  };

  const checkHandleClick = (e: React.SyntheticEvent<Element, Event>) => {
    if (orgChecked) {
      setOrgChecked(false);
      deleteAllUsersFromOrg();
      let filter = orgsChecked.filter((id) => id !== org.id);
      setOrgsChecked(filter);
      setAllChecked(false);
      resetField('sendToAllUsers');
    } else {
      setOrgChecked(true);
      users.length === 0
        ? getUsers({ variables: { checkUsers: true } })
        : checkAllUsersFromOrg();
      setOrgsChecked((prev) => [...prev, org.id]);
    }
  };

  useEffect(() => {
    if (allChecked) {
      setOpen(false);
      setOrgChecked(true);
    } else {
      setOrgChecked(false);
    }
  }, [allChecked]);

  useEffect(() => {
    let orgIsChecked = orgsChecked.includes(org.id);
    setOrgChecked(orgIsChecked);
    getUsers({ variables: { checkUsers: orgIsChecked } });
  }, [orgsChecked]);

  return (
    <>
      <ListItem
        sx={{
          p: 0,
          backgroundColor: 'common.greyblueDark',
          color: '#fff',
          '&:hover': { backgroundColor: 'common.greyblueDarker' },
        }}
      >
        <Checkbox
          name={org.id}
          value={org.id}
          checked={orgChecked}
          disableRipple
          onClick={(e) => checkHandleClick(e)}
          size="small"
          sx={{
            color: 'common.white',
            '&.Mui-checked': { color: 'common.white' },
          }}
        />
        <ListItemButton onClick={handleClick}>
          <ListItemText
            primary={org.name}
            primaryTypographyProps={{ sx: { color: '#fff', fontWeight: '500' } }}
          />
          {open ? (
            <FontAwesomeIcon icon="fas fa-angle-up" size={18} />
          ) : (
            <FontAwesomeIcon icon="fas fa-angle-down" size={18} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {users.length > 0 ? (
            users.map((user, index) => (
              <ListItemUser
                key={user.id}
                user={user}
                usersList={usersList}
                setUsersList={setUsersList}
                orgChecked={orgChecked}
                setOrgChecked={setOrgChecked}
                allChecked={allChecked}
                blackList={blackList}
                setBlackList={setBlackList}
                allUsersOrgChecked={allUsersOrgChecked}
              />
            ))
          ) : (
            <ListItem
              disablePadding
              disabled={true}
              secondaryAction={
                <IconButton edge="end" aria-label="users">
                  <GroupIcon fontSize="small" />
                </IconButton>
              }
            >
              <ListItemButton role={undefined} dense>
                <ListItemText
                  primary={'No users'}
                  primaryTypographyProps={{ sx: { fontSize: 11 } }}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Collapse>
    </>
  );
};

export default ListItemOrg;
