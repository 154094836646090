import styled from "styled-components";
import { setColor } from "./colorScheme";

export const buttonStyle = {
    primary: styled.button`
        background-color: ${setColor.secondary};
        border-radius: 3px;
        box-shadow: 5px 5px 8px #0000001c, -5px -5px 8px #ffffff;
        color: ${setColor.mainWhite};
        :hover {
            background-color: ${setColor.secondaryDark};
        }
    `,
    secondary: styled.button`
        background-color: ${setColor.tertiary};
        border-radius: 3px;
        box-shadow: 5px 5px 8px #0000001c, -5px -5px 8px #ffffff;
        color: ${setColor.mainWhite};
        :hover {
            background-color: ${setColor.secondaryDark};
        }
    `,
    positive: styled.button`
        background-color: ${setColor.positive};
        border-radius: 3px;
        box-shadow: 5px 5px 8px #0000001c, -5px -5px 8px #ffffff;
        color: ${setColor.mainWhite};
        :hover {
            background-color: ${setColor.positiveDark};
        }
    `,
    negative: styled.button`
        background-color: ${setColor.negative};
        border-radius: 3px;
        box-shadow: 5px 5px 8px #0000001c, -5px -5px 8px #ffffff;
        color: ${setColor.mainWhite};
        :hover {
            background-color: ${setColor.negativeDark};
        }
    `,
}