import { Box, FormControlLabel, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { UPDATE_ORG } from '../../../utils/gql/gqlOrg';
import { Org } from '../OrgMain';
import { useForm } from 'react-hook-form';
import StyledSwitch from '../../../components/form/StyledSwitch';
import Button from '../../../components/Button/StyledButton';
import { StatusProps } from '../../../components/SnackBar';
import { StyledTextField } from '../../../components/Textfield/StyledTextfield';

interface ProfileTabProps {
  org: Org;
  setSnackbarStatus: React.Dispatch<React.SetStateAction<StatusProps>>;
  setOrganisation: React.Dispatch<React.SetStateAction<Org | null>>;
}

const styles = {
  formItem: {
    marginBottom: 2,
  },
};

const ProfileTab = ({ org, setSnackbarStatus, setOrganisation }: ProfileTabProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Omit<Org, 'id'>>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [processPathEnabled, setProcessPathEnabled] = useState<boolean>(
    org && org.processPathEnabled ? org.processPathEnabled : false
  );

  const handleProcessPathEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProcessPathEnabled(event.target.checked);
  };

  const [updateOrgMutation] = useMutation(UPDATE_ORG);
  const updateOrg = (values: Omit<Org, 'id'>) => {
    return updateOrgMutation({
      variables: {
        id: (org && org.id) || '',
        name: values.name,
        address: values.address,
        phoneNumber: values.phoneNumber,
        country: values.country,
        active: values.active,
        processPathEnabled: values.processPathEnabled,
      },
    });
  };

  const onOrgUpdate = async (values: Omit<Org, 'id'>) => {
    updateOrg({ ...values })
      .then(({ data }) => {
        setSnackbarStatus({
          status: true,
          snackbarMessage: 'Organisation updated successfully',
          severity: 'success',
        });
        setOrganisation(data.updateOrganisationByOrg.organisation);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onOrgUpdate)} style={{ height: '100%' }}>
        <Grid container direction="column" spacing={4} sx={{ p: 2 }}>
          <Grid item>
            <Grid item sx={styles.formItem}>
              <StyledTextField
                {...register('name', {
                  required: { value: true, message: 'This field is required' },
                })}
                fullWidth
                label="Organisation name"
                name="name"
                variant="outlined"
                defaultValue={org ? org.name : ''}
                aria-invalid={errors.name ? 'true' : 'false'}
                error={errors.name ? true : false}
                helperText={errors.name ? (errors.name.message as React.ReactNode) : ''}
              />
            </Grid>
            <Grid item sx={styles.formItem}>
              <StyledTextField
                {...register('address', {
                  required: { value: true, message: 'This field is required' },
                })}
                fullWidth
                label="Address"
                name="address"
                variant="outlined"
                defaultValue={org ? org.address : ''}
                aria-invalid={errors.address ? 'true' : 'false'}
                error={errors.address ? true : false}
                helperText={
                  errors.address ? (errors.address.message as React.ReactNode) : ''
                }
              />
            </Grid>
            <Grid item sx={styles.formItem}>
              <StyledTextField
                {...register('country', {
                  required: { value: true, message: 'This field is required' },
                })}
                fullWidth
                label="Country"
                name="country"
                variant="outlined"
                defaultValue={org ? org.country : ''}
                aria-invalid={errors.country ? 'true' : 'false'}
                error={errors.country ? true : false}
                helperText={
                  errors.country ? (errors.country.message as React.ReactNode) : ''
                }
              />
            </Grid>
            <Grid item sx={styles.formItem}>
              <StyledTextField
                {...register('phoneNumber', {
                  required: { value: true, message: 'This field is required' },
                })}
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                variant="outlined"
                defaultValue={org ? org.phoneNumber : ''}
                aria-invalid={errors.phoneNumber ? 'true' : 'false'}
                error={errors.phoneNumber ? true : false}
                helperText={
                  errors.phoneNumber
                    ? (errors.phoneNumber.message as React.ReactNode)
                    : ''
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <StyledSwitch
                    {...register('active')}
                    name="active"
                    color="secondary"
                    checked={isActive}
                    disabled
                    onChange={() => setIsActive(!isActive)}
                    defaultChecked={org ? org.active : false}
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item sx={styles.formItem}>
              <FormControlLabel
                control={
                  <StyledSwitch
                    {...register('processPathEnabled')}
                    name="processPathEnabled"
                    color="secondary"
                    checked={processPathEnabled}
                    onChange={handleProcessPathEnabledChange}
                  />
                }
                label="Workflow Enabled"
              />
            </Grid>
            <Grid item container spacing={1} sx={{ justifyContent: 'flex-end' }}>
              <Grid item>
                <Button variant="outlined" onClick={() => reset()}>
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" type="submit" variant="contained">
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ProfileTab;
