import React, { useState } from 'react';

import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';

import FieldList from './components/FieldList';
import FieldEditView from './components/FieldEditView';
import FontAwesomeIcon from '../../../components/FAIcon';
import {
  FolderFields as FolderFieldsType,
  FolderFieldsProps,
  UpdateFieldProps,
} from '../types';
import { InitialFieldUpdates } from '../FolderPage';
import {
  StyledPrimaryButton,
  StyledSecondaryButton,
} from '../../../components/form/StyledButtons';

const styles = {
  header: {
    backgroundColor: 'common.greyblueDark',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: 'common.white',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '19px',
  },
  searchInput: {
    width: '273px',
    borderBottom: '1px solid rgba(255,255,255,0.42)',
    '& .MuiInputLabel-root': {
      color: 'common.white',
      '&.Mui-focused': {
        color: 'common.white',
      },
    },
    '& .MuiInput-root': {
      '& .MuiInput-input': {
        color: 'common.white',
      },
    },
  },
  paperContainer: {
    height: 'inherit',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
  },
};

export default function FolderFields({
  fields,
  updateField,
  setSelectedFieldId,
  fieldUpdates,
  setFieldUpdates,
  setSelectedField,
  selectedField,
  selectedFieldId,
  folder,
  updateFolder,
  refetch,
}: FolderFieldsProps) {
  const [showEditView, setShowEditView] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<null | string>(null);
  const [isMainField, setIsMainField] = useState<boolean>(false);

  const filterFields = (fields: FolderFieldsType[]) => {
    return fields.filter((item: FolderFieldsType) => {
      return searchQuery
        ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
        : item;
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(e.target.value);
  };

  if (searchQuery && fields) {
    fields = filterFields(fields);
  }

  const onSubmit = () => {
    if (!selectedField) return;
    const updates: UpdateFieldProps = {
      folderFieldId: selectedField.id,
      ...fieldUpdates,
    };

    updateField(updates).then(() => {
      //Add here the folder update
      if (isMainField) {
        updateFolder({
          variables: {
            folderId: folder.id,
            primaryFolderFieldId: selectedFieldId,
          },
        })
          .then(() => {
            setSelectedFieldId(null);
            setIsMainField(false);
            setFieldUpdates(InitialFieldUpdates);
            // refetch();
          })
          .catch((e: Error) => {
            console.error('Could not update folder name: ', e);
          });
      }
    });
  };

  return (
    <Stack
      width="inherit"
      sx={{
        height: { xs: '28vh', md: '35vh', lg: '55vh' },
      }}
    >
      <Stack direction="row" height="min-content">
        <Box minWidth="310px" sx={{ ...styles.header, borderRadius: '4px 0 0 0' }}>
          <TextField
            label="Search field"
            onChange={handleSearch}
            variant="standard"
            InputProps={{
              startAdornment: (
                <Box marginRight="8px">
                  <FontAwesomeIcon
                    icon="fas fa-search"
                    color="common.white"
                    size="16px"
                  />
                </Box>
              ),
              type: 'search',
              disableUnderline: true,
            }}
            sx={styles.searchInput}
          />
        </Box>
        <Box width="100%" sx={{ ...styles.header, borderRadius: '0 4px 0 0' }}>
          <Typography sx={{ ...styles.title }}>Field Options</Typography>
        </Box>
      </Stack>

      <Stack direction="row" height="inherit" minHeight="100%" width="inherit">
        <Paper
          elevation={3}
          sx={{
            ...styles.paperContainer,
            minWidth: '310px',
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#141d27' : 'common.white',
            borderRadius: '0px 0px 0px 4px',
          }}
        >
          <Stack spacing={2} overflow="auto" marginTop="10px">
            <FieldList
              selectedFieldId={selectedFieldId}
              setSelectedFieldId={setSelectedFieldId}
              setSelectedField={setSelectedField}
              setShowEdit={setShowEditView}
              fields={fields}
              updateField={updateField}
            />
          </Stack>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            ...styles.paperContainer,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'background.default' : 'common.white',
            width: 'inherit',
            position: 'relative',
            borderRadius: '0px 0px 4px 0px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {showEditView && (
            <>
              <FieldEditView
                setFieldUpdates={setFieldUpdates}
                setSelectedField={setSelectedField}
                selectedField={selectedField}
                fieldUpdates={fieldUpdates}
                selectedFieldId={selectedFieldId}
                folder={folder}
                updateField={updateField}
              />
              <Stack
                direction="row"
                justifyContent="end"
                spacing={1}
                marginRight="10px"
                paddingBottom="10px"
              >
                <StyledSecondaryButton
                  variant="outlined"
                  onClick={() => {
                    setSelectedFieldId(null);
                    setFieldUpdates(InitialFieldUpdates);
                    setShowEditView(false);
                  }}
                >
                  Cancel
                </StyledSecondaryButton>
                {onSubmit && (
                  <StyledPrimaryButton variant="contained" onClick={onSubmit}>
                    Submit
                  </StyledPrimaryButton>
                )}
              </Stack>
            </>
          )}
        </Paper>
      </Stack>
    </Stack>
  );
}
