import { MutableRefObject, useState } from 'react';
import { Tooltip, Button } from '@mui/material';
import FontAwesomeIcon from '../../../FAIcon';
import { styled } from '@mui/material/styles';
import { SelectedFolder } from '../../masterTableTypes';
import ReportModal from './ReportModal';
import useUsers from '../../../../hooks/useUsers';

interface PDFReportToolProps {
  rows: any[];
  cols: {
    [key: string]: boolean;
  }[];
  selectedFolder: SelectedFolder;
  screen: string;
  selectedTemplate: any;
  buttonReportRef: MutableRefObject<null | HTMLButtonElement>;
  exportQuery: any;
}

const StyledButtons = styled(Button)(({ theme }) => ({
  height: '32px',
  width: '32px',
  minWidth: '32px',
  borderRadius: '0',
  ':hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.common.greyblueDarker,
  },
}));

const PDFReportTool = ({
  rows,
  cols,
  selectedFolder,
  screen,
  selectedTemplate,
  buttonReportRef,
  exportQuery,
}: PDFReportToolProps) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { users } = useUsers();

  const handelOpenModal = () => setModalIsOpen(true);

  return (
    <>
      <Tooltip title="Export this table" arrow>
        <StyledButtons
          onClick={handelOpenModal}
          id="report-modal-button"
          ref={buttonReportRef}
          sx={{ visibility: screen === 'reporting' ? 'hidden' : undefined }}
        >
          <FontAwesomeIcon size="16px" icon="fas fa-file-export" color="#fff" />
        </StyledButtons>
      </Tooltip>
      {modalIsOpen && (
        <ReportModal
          rows={rows}
          cols={cols}
          selectedFolder={selectedFolder}
          screen={screen}
          selectedTemplate={selectedTemplate}
          exportQuery={exportQuery}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          users={users}
        />
      )}
    </>
  );
};
export default PDFReportTool;
