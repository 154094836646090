import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isValid, parseISO } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Context as RTCContext } from '../../context/RTCContext';
import useMaintenance from '../../hooks/useMaintenance';
import { db } from '../../utils/indexedDB/indexeddb';
import AlertDialog from './AlertDialog';
import { add } from 'date-fns';
import StyledSwitch from '../../components/form/StyledSwitch';
export interface FormValues {
  message: string;
  startDate: any;
  endDate: any;
  restart: boolean;
}

const generateEndDate = (timeToAdd: number, sendLater: any) => {
  let now = Date.now();
  if (sendLater) now = new Date(sendLater).getTime();
  let res = add(now, { seconds: timeToAdd });
  let expirationTime: string = new Date(res).toISOString();
  return expirationTime;
};

const initialFormValues = {
  message: 'Data Capture is currently under a scheduled maintenance or update.',
  startDate: new Date(Date.now()).toISOString(), // add 2 min
  endDate: generateEndDate(28800, false),
  restart: false,
};

const styles = {
  switch: {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: 'common.greyblueDarker',
        '& + .MuiSwitch-track': { backgroundColor: 'common.greyblue' },
      },
    },
  },
  title: {
    backgroundColor: 'common.greyblueDarker',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderInlineStart: '3px solid #cea323',
    boxShadow: { sm: '0px 1px 0px 0px #ffffff96 inset', md: 'none' },
    padding: '5px 15px',
    height: '30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 30px)',
    width: '100%',
    flex: 30,
    p: 6,
    gap: 4,
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  durationSwitch: {
    '& .MuiSwitch-switchBase': {
      color: 'common.greyblueDarker',
      '& + .MuiSwitch-track': { backgroundColor: 'common.greyblue' },
    },
  },
  scheduledPicker: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
};

const UpdateTab = () => {
  const {
    maintenance,
    scheduledMaintenance,
    data,
    setMaintenance,
    setScheduledMaintenance,
  } = useMaintenance();
  const [isSchedule, setIsSchedule] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [duration, setDuration] = useState(28800);
  const rtcContext = useContext(RTCContext);
  const [openConfirmDialog, setOpenConfirmDialog] = useState({
    send: false,
    finish: false,
  });

  const handleClickOpen = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenConfirmDialog({ send: true, finish: false });
  };

  const handleCloseDialog = () => setOpenConfirmDialog({ send: false, finish: false });

  const handleSubmit = () => {
    rtcContext.setMaintenanceMode(formValues);
    handleCloseDialog();
  };

  const handleChangeValues = (value: any, key: string) => {
    if (isSchedule) {
      if (key === 'endDate') value = generateEndDate(value, formValues.startDate);

      if (key === 'startDate') {
        let endDate = generateEndDate(duration, value);
        return setFormValues({ ...formValues, [key]: value, endDate });
      }
    } else {
      let endDate = generateEndDate(value, Date.now());
      let startDate = Date.now();
      return setFormValues({ ...formValues, endDate, startDate });
    }
  };

  const handleTurnOffMaintenance = () => {
    rtcContext.setMaintenanceMode(false);
    setMaintenance(false);
    setScheduledMaintenance(false);
    setFormValues(initialFormValues);
    setIsSchedule(false);
    handleCloseDialog();
  };

  let now = Date.now();

  const getData = async () => {
    let data = await db.table('maintenance').toArray();
    if (data.length) {
      let endDate = new Date(data[0].endDate);
      let timestamp = endDate.getTime();
      if (timestamp <= Date.now()) return handleTurnOffMaintenance();
      setFormValues(data[0]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box sx={styles.title}>
        <Typography variant="caption" color="WHITE">
          Setup an update
        </Typography>
      </Box>
      <Box sx={styles.form} onSubmit={(e) => handleClickOpen(e)} component="form">
        {(maintenance || scheduledMaintenance) && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={styles.text}>Maintenance Mode </Typography>
            <StyledSwitch
              checked={maintenance || scheduledMaintenance}
              onChange={(e) =>
                maintenance || scheduledMaintenance
                  ? setOpenConfirmDialog({ send: false, finish: true })
                  : setMaintenance(e.target.checked as boolean)
              }
            />
            {scheduledMaintenance ? (
              <Typography sx={styles.text}>
                {`Data Capture will go into maintenance mode on ${
                  data ? format(parseISO(data.startDate), 'dd-MM-yy k:mm') : ''
                } hs`}
              </Typography>
            ) : (
              <Typography sx={styles.text}>
                {`is active until ${
                  data ? format(parseISO(data.endDate), 'dd-MM-yy k:mm') : ''
                } hs`}
              </Typography>
            )}
          </Box>
        )}
        <>
          <Box sx={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 3 }}>
            <Box sx={{ display: 'flex', gap: { sm: 4, md: 15, lg: 20 } }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={styles.text}>Now</Typography>
                <StyledSwitch
                  checked={isSchedule}
                  onChange={(e) => setIsSchedule(e.target.checked as boolean)}
                />
                <Typography sx={styles.text}>Schedule</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography sx={styles.text}>Duration</Typography>
                <FormControl variant="outlined" sx={{ margin: '0 auto', width: '100%' }}>
                  <Select
                    defaultValue={28800}
                    size="small"
                    onChange={(e) => setDuration(e.target.value as number)}
                  >
                    <MenuItem value={30}>30 sec</MenuItem>
                    <MenuItem value={300}>5 min</MenuItem>
                    <MenuItem value={900}>15 min</MenuItem>
                    <MenuItem value={1800}>30 min</MenuItem>
                    <MenuItem value={3600}>1 hour</MenuItem>
                    <MenuItem value={5400}>1 hour 30 min</MenuItem>
                    <MenuItem value={7200}>2 hours</MenuItem>
                    <MenuItem value={10800}>3 hours</MenuItem>
                    <MenuItem value={14400}>4 hours</MenuItem>
                    <MenuItem value={18000}>5 hours</MenuItem>
                    <MenuItem value={28800}>8 hours</MenuItem>
                    <MenuItem value={43200}>12 hours</MenuItem>
                    <MenuItem value={86400}>1 day</MenuItem>
                    <MenuItem value={172800}>2 days</MenuItem>
                    <MenuItem value={259200}>3 days</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                ...styles.scheduledPicker,
                visibility: isSchedule ? 'visible' : 'hidden',
              }}
            >
              <Typography sx={styles.text}>Start</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: false }} />
                  )}
                  value={parseISO(formValues.startDate)}
                  onChange={(newValue: Date | null) => {
                    if (isValid(newValue))
                      handleChangeValues(`${newValue?.toISOString()}`, 'startDate');
                  }}
                  minDateTime={new Date(now)}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <Typography sx={styles.text}>Message</Typography>
              <TextField
                name="message"
                variant="outlined"
                type="text"
                placeholder="Data Capture is currently under a scheduled maintenance or update."
                multiline
                fullWidth
                inputProps={{ style: { height: '130px' } }}
                value={formValues.message}
                InputLabelProps={{ shrink: false }}
                onChange={(e) =>
                  setFormValues((prev) => {
                    return { ...prev, message: e.target.value };
                  })
                }
                sx={{ '.MuiFormHelperText-root': { textAlign: 'right' }, pt: 2 }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={styles.text}> Restart after update </Typography>
              <StyledSwitch
                checked={formValues.restart}
                onChange={(e) =>
                  setFormValues((prev) => {
                    return { ...prev, restart: e.target.checked };
                  })
                }
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignSelf: 'self-end' }}>
            <Button variant="outlined" type="reset" sx={{ marginRight: 1 }}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: 'common.greyblueDarker' }}
              onClick={() => handleChangeValues(duration, 'endDate')}
            >
              Send
            </Button>
          </Box>
        </>
      </Box>
      {openConfirmDialog.send && (
        <AlertDialog
          open={openConfirmDialog.send}
          handleClose={handleCloseDialog}
          handleSubmit={handleSubmit}
          formValues={formValues}
          title={'Data Capture will be put into maintenance mode'}
        />
      )}
      {openConfirmDialog.finish && (
        <AlertDialog
          open={openConfirmDialog.finish}
          handleClose={handleCloseDialog}
          handleSubmit={handleTurnOffMaintenance}
          title={'¿Turn off maintenance mode?'}
        />
      )}
    </>
  );
};

export default UpdateTab;
