import { Box, Drawer, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  Context as AuthContext,
  AuthContextTypes,
} from '../../../../context/authContext';
import { Context as StatusContext } from '../../../../context/statusContext';
import {
  modifyLocalStorageObject,
  useLocalStorage,
} from '../../../../hooks/useLocalStorage';
import FontAwesomeIcon from '../../../FAIcon';
import StepAction from './StepAction';

import { hasPerformWorkflowPermissions } from '../../../../containers/search/searchUtils';
import { Record } from '../../../../containers/search/types';
import { Step, Workflow } from '../../../../containers/workflow/types';
import { StatusContextTypes } from '../../../../context/statusContextTypes';
import { SetMessage, SetSnackBarState } from '../../../masterTable/masterTableTypes';

const classes = {
  toggleButton: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
    backgroundColor: '#fda808',
    height: 15,
    width: '50%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0B72B9',
    },
  },
};

interface StepActionContainerProps {
  processPath: Workflow;
  currentProcessStep: Step;
  setSnackOpen: SetSnackBarState;
  setMessage: SetMessage;
  record?: Record;
}

const drawerHasToShowStepActions = (
  processPath: Workflow,
  currentProcessStep: Step,
  id: string
): boolean => {
  if (!processPath || !currentProcessStep) return false;

  if (currentProcessStep) {
    let { usersWhoHavePerformedApproval, toBeApprovedBy, completed } = currentProcessStep;
    let userHasAlreadyApproved = _.find(usersWhoHavePerformedApproval || [], {
      userId: id,
    });

    let userMustApprove = toBeApprovedBy.some((el: any) => el === id);

    if (!userMustApprove) return false; // user has no actions in the current step
    if (completed || userHasAlreadyApproved) return false; // current Step is completed or user has already approved
    if (!completed && userMustApprove) return true; // current Step is not completed and user must approve

    return false;
  } else {
    return false;
  }
};

const StepActionContainer = ({
  currentProcessStep,
  processPath,
  setMessage,
  setSnackOpen,
  record,
  refetch,
}: StepActionContainerProps) => {
  const [{ id }] = useLocalStorage('userData');
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const { state: authState } = useContext(AuthContext) as AuthContextTypes;
  const [assignedAction, setAssignedAction] = useState(false);

  useEffect(() => {
    let showActions = drawerHasToShowStepActions(processPath, currentProcessStep, id);
    setAssignedAction(showActions);
  }, [state.selectedRecord, currentProcessStep, processPath, id]);

  return state.workflow &&
    state.currentOrg &&
    state.currentOrg.processPathEnabled &&
    assignedAction &&
    hasPerformWorkflowPermissions(
      authState.permissions || null,
      id,
      state.screen === 'record' ? record?.folderId : state.selectedFolder[state.screen],
      false
    ) ? (
    <>
      {assignedAction && (
        <Box sx={{ padding: '20px' }}>
          <StepAction
            step={currentProcessStep}
            setMessage={setMessage}
            setSnackOpen={setSnackOpen}
            setAssignedAction={setAssignedAction}
            refetch={refetch}
            record={record}
          />
        </Box>
      )}
    </>
  ) : null;
};

export default StepActionContainer;
