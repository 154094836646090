import { Box, Paper, Stack, Typography } from '@mui/material';

// components
import TooltipHeader from './components/TooltipHeader';
import TooltipContentDataValue from './components/TooltipContentDataValue';

interface Props {
  payload?: any[];
  active: boolean;
}

export default function CustomTooltip({ payload, active }: Props) {
  if (active && payload && payload.length) {
    const payloadData = payload[0].payload;
    let date;

    let firstDay = payloadData.startEndDay
      ? new Date(payloadData.startEndDay[0]).toLocaleDateString()
      : null;
    let endDay = payloadData.startEndDay
      ? new Date(payloadData.startEndDay[1]).toLocaleDateString()
      : null;

    if (!firstDay && !endDay) {
      date = new Date(payloadData.date).toLocaleDateString();
    } else {
      date = payloadData.date;
    }

    return (
      <Paper elevation={2}>
        <TooltipHeader content={date} />
        <Box sx={{ backgroundColor: '#fff', padding: '2px 5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Stack>
              {payload.map((obj) => {
                return (
                  <TooltipContentDataValue
                    key={obj.name}
                    type={obj.name.slice(6)}
                    value={obj.value}
                    color={obj.color}
                  />
                );
              })}
            </Stack>
          </Box>
          {firstDay && endDay && (
            <Typography
              variant="subtitle1"
              sx={{ fontSize: 12 }}
            >{`${firstDay} - ${endDay}`}</Typography>
          )}
        </Box>
      </Paper>
    );
  }
  return null;
}
