import {
  IconButton,
  Tooltip,
  Typography,
  Stack,
  LinearProgress,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FontAwesomeIcon from '../FAIcon';
import { format } from 'date-fns';
import { TaskContent } from '../../context/tasksContext';

const styles = {
  primary: {
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '15px',
    color: '#fff',
  },
  secondary: {
    fontSize: '10px',
    fontWeight: 300,
    lineHeight: '12px',
    color: 'rgba(255, 255, 255, 0.4)',
  },
  buttonNegative: {
    color: 'rgba(255, 255, 255, 0.22)',
    '& :hover': {
      color: 'common.negative',
    },
  },
  buttonPositive: {
    color: 'rgba(255, 255, 255, 0.22)',
    '& :hover': {
      color: 'common.positive',
    },
  },
};

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: '5px',
  borderRadius: '5px',
  backgroundColor: '#3a5463',
  boxShadow: 'inset 0px 2px 3px rgba(0, 0, 0, 0.25)',
  '& .MuiLinearProgress-barColorPrimary': {
    background: `linear-gradient(90deg, ${theme.palette.common.green} 46%, rgba(54,187,69,1) 53%, ${theme.palette.common.green} 59%)`,
    backgroundSize: '200% 100%',
    borderRadius: 'inherit',
    animation: 'progress 1s ease-in-out alternate infinite',
  },
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0%',
    },
    '100%': {
      backgroundPosition: '100%',
    },
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '7px',
  height: 'auto',
}));

interface TaskProps {
  task: TaskContent;
  bgColor: string;
  completed?: boolean;
  removeTask: (task: TaskContent) => void;
}

export default function Task({
  removeTask,
  task,
  task: { id, folderName, action, ammount, fail, progress, success, timeStamp },
  bgColor,
  completed = false,
}: TaskProps) {
  let status = '';
  switch (action) {
    case 'scan_barcode_result':
      action = 'Barcode Scanning';
      status = 'Records indexed';
      break;
    case 'delete_records':
      action = 'Deleting Records';
      status = 'Records deleted';
      break;
    case 'update_records':
      action = 'Updating Records';
      status = 'Records updated';
      break;
    case 'upload_files':
      action = 'Uploading Records';
      status = 'Records Uploaded';
      break;
    case 'textract_record':
      action = 'OCRing record';
      status = 'OCRing record';
      break;
    default:
      status = 'Records processed';
      break;
  }

  let formatted = {
    action,
    timeStamp: format(new Date(timeStamp), 'dd/MM/yyyy HH:mm:ss'),
  };

  return completed ? (
    <Stack direction="row" bgcolor={bgColor} spacing={1}>
      <StyledDivider
        orientation="vertical"
        flexItem
        sx={{
          backgroundColor: fail === ammount ? 'common.negative' : 'common.green',
        }}
      />
      <Stack flex={1} spacing={1} justifyContent="center" padding="7px 0">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography sx={styles.primary}>{formatted.action}</Typography>
              <Typography sx={styles.secondary}>{folderName}</Typography>
            </Stack>
            <Typography sx={styles.primary}>
              {ammount} <FontAwesomeIcon icon="fas fa-file-alt" size={9} />
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={2}
            sx={{
              '& .fa-times-circle': { color: 'common.negative' },
              '& .fa-check-circle': { color: 'common.positive' },
            }}
          >
            {fail > 0 && (
              <Typography sx={styles.primary}>
                {fail} <FontAwesomeIcon icon="fas fa-times-circle" size={9} />
              </Typography>
            )}
            {success > 0 && (
              <Typography sx={styles.primary}>
                {success} <FontAwesomeIcon icon="fas fa-check-circle" size={9} />
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={styles.secondary}>Task ID: {id}</Typography>
          <Typography sx={styles.secondary}>Started: {formatted.timeStamp}</Typography>
        </Stack>
      </Stack>
      <Stack justifyContent="center">
        <Tooltip title="Clear" arrow>
          <span>
            <IconButton
              onClick={() => {
                removeTask(task);
              }}
              sx={styles.buttonPositive}
            >
              <FontAwesomeIcon icon="fas fa-check-circle" size="25px" />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  ) : (
    <Stack direction="row" bgcolor={bgColor} spacing={1} padding=" 7px 10px 7px 15px">
      <Stack flex={1} spacing={0.5} justifyContent="center">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography sx={styles.primary}>{formatted.action}</Typography>
            <Typography sx={styles.secondary}>{folderName}</Typography>
          </Stack>
          <Typography sx={styles.secondary}>{`${success + fail}/${ammount}`}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <StyledLinearProgress variant="determinate" value={progress} />
          <Typography sx={styles.secondary}>{`${Math.round(progress)}%`}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={styles.secondary}>Task ID: {id}</Typography>
          <Typography sx={styles.secondary}>Started: {formatted.timeStamp}</Typography>
        </Stack>
      </Stack>
      <Stack justifyContent="center">
        <Tooltip title="Cancel" arrow>
          <span>
            <IconButton
              //disabled={true}
              onClick={() => {
                removeTask(task);
              }}
              sx={styles.buttonNegative}
            >
              <FontAwesomeIcon icon="fas fa-ban" size="25px" />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
