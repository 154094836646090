import { gql } from 'apollo-boost';

export const GET_ORGS = gql`
  query {
    Organisations {
      organisations {
        id
        name
        address
        phoneNumber
        createdBy {
          firstName
        }
        country
        processPathEnabled
      }
    }
  }
`;
export const GET_ORG_BY_ID = gql`
  query OrganisationByOrg($Id: ID!) {
    OrganisationByOrg(Id: $Id) {
      organisation {
        id
        name
        address
        phoneNumber
        country
        processPathEnabled
      }
      error
    }
  }
`;
export const CREATE_ORG = gql`
  mutation createOrganisation(
    $name: String!
    $country: String!
    $address: String!
    $phoneNumber: String!
    $logo: String
    $processPathEnabled: Boolean
  ) {
    createOrganisation(
      data: {
        name: $name
        country: $country
        address: $address
        phoneNumber: $phoneNumber
        logo: $logo
        processPathEnabled: $processPathEnabled
      }
    ) {
      organisation {
        id
        name
      }
      error
    }
  }
`;
export const UPDATE_ORG = gql`
  mutation updateOrganisationByOrg(
    $id: ID!
    $name: String!
    $country: String!
    $address: String!
    $phoneNumber: String!
    $logo: String
    $processPathEnabled: Boolean
  ) {
    updateOrganisationByOrg(
      data: {
        name: $name
        country: $country
        address: $address
        phoneNumber: $phoneNumber
        logo: $logo
        processPathEnabled: $processPathEnabled
      }
      organisationId: $id
    ) {
      organisation {
        id
        name
        address
        phoneNumber
        createdBy {
          firstName
        }
        country
        processPathEnabled
      }
      error
    }
  }
`;
