import { FieldValues, useForm, UseFormRegister } from 'react-hook-form';

const usersUtils = {
  passwordGenerator: () => {
    const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialCharacters = '#?!@$%^&*-';
    const allCharacters = lowerCase + upperCase + numbers + specialCharacters;

    let password = '';
    let hasLowerCase = false;
    let hasUpperCase = false;
    let hasNumber = false;
    let hasSpecialCharacter = false;

    while (
      password.length < 8 ||
      !(hasLowerCase && hasUpperCase && hasNumber && hasSpecialCharacter) ||
      password.includes(' ')
    ) {
      password = '';
      hasLowerCase = false;
      hasUpperCase = false;
      hasNumber = false;
      hasSpecialCharacter = false;

      for (let i = 0; i < 16; i++) {
        password += allCharacters.charAt(
          Math.floor(Math.random() * allCharacters.length)
        );
      }

      for (const char of password) {
        if (lowerCase.includes(char)) {
          hasLowerCase = true;
        } else if (upperCase.includes(char)) {
          hasUpperCase = true;
        } else if (numbers.includes(char)) {
          hasNumber = true;
        } else if (specialCharacters.includes(char)) {
          hasSpecialCharacter = true;
        }
      }
    }
    return password.trim();
  },
  validatePassword: (password: string) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$ %^&*-])[A-Za-z\d#?!@$ %^&*-]{8,}$/;
    return regex.test(password.trim());
  },
  registerOptions: (
    registerFunction: UseFormRegister<FieldValues>,
    name: string,
    inputName: string,
    isRequired = false,
    patternToValid = false,
    minLength = false,
    maxLength = false,
    minNumber = false,
    maxNumber = false,
    onChange = null,
    onBlur = null
  ) => {
    const rules = {
      required: isRequired
        ? {
            value: isRequired,
            message: 'This field is required',
          }
        : false,
      pattern: patternToValid
        ? {
            value: patternToValid,
            message: `Please enter a valid ${name}`,
          }
        : false,
      minLength: minLength
        ? {
            value: minLength,
            message: `This field must be longer than ${minLength} characters`,
          }
        : false,
      maxLength: maxLength
        ? {
            value: maxLength,
            message: `This field must not exceed ${maxLength} characters`,
          }
        : false,
      min: minNumber
        ? {
            value: minNumber,
            message: `This field must not be less than ${minNumber}`,
          }
        : false,
      max: maxNumber
        ? {
            value: maxNumber,
            message: `This field must not be greater than ${maxNumber}`,
          }
        : false,
      onChange: onChange,
      onBlur: onBlur,
    };
    if (registerFunction) {
      return {
        ...registerFunction(`${inputName}`, rules),
      };
    } else {
      window.log(rules);
      return rules;
    }
  },
};

export default usersUtils;
