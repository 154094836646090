import { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import FontAwesomeIcon from '../../../components/FAIcon';
import { Folder } from '../../../containers/folder/types';
import { Frecuency } from '../DashboardMain';

const classes = {
  stepper: {
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    gap: 0.7,
  },
  activeFolder: {
    fontSize: 17,
    fontWeight: 300,
    color: '#79909f',
  },
  buttonFolder: {
    minWidth: 10,
    padding: 0.2,
    '&:disabled': {
      opacity: 0,
    },
  },
};

interface Props {
  folders: Folder[];
  getFolderData: (id: string, type: string, frecuency: Frecuency) => void;
  type?: string;
  frecuency?: Frecuency;
}

const FolderSwitcherMain = ({
  folders,
  getFolderData = () => {},
  type = 'scanned',
  frecuency = { type: 'week', points: 42 },
}: Props) => {
  const [activeFolder, setActiveFolder] = useState(0);

  if (!folders) return null;

  //   window.log('datos de la carpeta: ' + folders[activeFolder].id);

  const handleNext = () => {
    setActiveFolder((prevActiveFolder) => prevActiveFolder + 1);
    getFolderData(folders[activeFolder + 1].id, type, frecuency);
  };

  const handleBack = () => {
    setActiveFolder((prevActiveFolder) => prevActiveFolder - 1);
    getFolderData(folders[activeFolder - 1].id, type, frecuency);
  };

  return (
    <Box sx={classes.stepper}>
      <Button
        size="small"
        onClick={handleBack}
        disabled={activeFolder === 0}
        sx={classes.buttonFolder}
      >
        <FontAwesomeIcon icon="fa-solid fa-caret-left" size={17} color="#79909f" />
      </Button>
      <Typography sx={classes.activeFolder}>{folders[activeFolder].name}</Typography>
      <Button
        size="small"
        onClick={handleNext}
        disabled={activeFolder === folders.length - 1}
        sx={classes.buttonFolder}
      >
        <FontAwesomeIcon icon="fa-solid fa-caret-right" size={17} color="#79909f" />
      </Button>
    </Box>
  );
};

export default FolderSwitcherMain;
