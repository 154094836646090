import { Box, Typography } from '@mui/material';

export default function TooltipHeader({ content }: { content: string }) {
  return (
    <Box sx={{ backgroundColor: '#eee', padding: '2px 5px' }}>
      <Typography variant="caption" sx={{ fontSize: 12, color: 'common.greyblueDarker' }}>
        {content}
      </Typography>
    </Box>
  );
}
