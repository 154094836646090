import { Badge, Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import CommToolbar from '../containers/comm/CommToolbar';
import Card from '@mui/material/Card';
import FontAwesomeIcon from '../components/FAIcon';
import { GET_ORGS } from '../utils/gql/gqlOrg';
import { useQuery } from 'react-apollo';
import {
  a11yProps,
  StyledCardContent,
  StyledTab,
  StyledTabPanel,
  StyledTabs,
} from '../containers/comm/CommStyled';
import CreateNotificationTab from '../containers/comm/CreateNotificationTab';

export type Organization = {
  id: string;
  name: string;
  address: string;
  phoneNumber: string;
  createdBy: {
    firstName: string;
  };
  country: string;
};

export type OrganizationItemList = {
  id: string;
  name: string;
};

const Communication = () => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [orgList, setOrgList] = useState<Array<OrganizationItemList>>([]);

  const { data: orgs } = useQuery(GET_ORGS, {
    onCompleted: () => {
      if (orgs) {
        let orgList = orgs.Organisations.organisations.map((org: Organization) => {
          return { id: org.id, name: org.name };
        });
        setOrgList(orgList);
      }
    },
  });

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) =>
    setTabValue(newValue);

  return (
    <Box sx={{ padding: '10px 10px' }}>
      <CommToolbar />
      <Card variant="outlined" sx={{ marginTop: '15px' }}>
        <StyledCardContent>
          <StyledTabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            <StyledTab
              icon={<FontAwesomeIcon icon="fas fa-user" />}
              label="Notifications"
              {...a11yProps(0)}
            />
            <StyledTab
              icon={<FontAwesomeIcon icon="fas fa-shield-alt" />}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  Chat
                  <Badge
                    badgeContent={4}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    color="success"
                  />
                </Box>
              }
              {...a11yProps(1)}
            />
          </StyledTabs>
          <>
            <StyledTabPanel value={tabValue} index={0} fullWidth>
              <CreateNotificationTab orgList={orgList} />
            </StyledTabPanel>
            <StyledTabPanel value={tabValue} index={1} fullWidth>
              <Typography>CHAT</Typography>
            </StyledTabPanel>
          </>
        </StyledCardContent>
      </Card>
    </Box>
  );
};

export default Communication;
