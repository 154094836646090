import React, { ReactNode } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Titles from './Titles';

interface ModalContentBaseProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  onCancel: () => void;
  onSubmit?: () => void;
  contentMinHeight?: string;
  img?: string;
  cancelButtonText?: string;
  submitButtonText?: string;
}

const ModalContentBase = ({
  title,
  subtitle,
  children,
  onCancel,
  onSubmit,
  contentMinHeight = '400px',
  img,
  cancelButtonText = 'Cancel',
  submitButtonText = 'Save',
}: ModalContentBaseProps) => {
  return (
    <Card sx={{ height: '100%', borderRadius: 'inherit' }}>
      <CardHeader
        title={<Titles title={title} subtitle={subtitle || ''} />}
        sx={{
          padding: 0,
        }}
      />
      {img && (
        <CardMedia
          component="img"
          height="auto"
          image={img}
          alt="new feature"
          sx={{ maxHeight: '300px' }}
        />
      )}
      <Box sx={{ padding: '10px' }}>
        <CardContent
          sx={{
            minHeight: contentMinHeight,
            minWidth: { xs: '80vw', md: '600px' },
          }}
        >
          {children}
        </CardContent>
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            onClick={onCancel}
            sx={{ backgroundColor: 'common.negative' }}
          >
            {cancelButtonText}
          </Button>
          {onSubmit && (
            <Button
              variant="contained"
              onClick={onSubmit}
              sx={{ backgroundColor: 'common.greyblueDarker' }}
            >
              {submitButtonText}
            </Button>
          )}
        </CardActions>
      </Box>
    </Card>
  );
};

export default ModalContentBase;
