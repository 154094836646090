import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContent, FormControlLabel, Stack, Switch, TextField } from '@mui/material';
import StyledSwitch from '../../../components/form/StyledSwitch';
import { SecondStyledTexfield } from '../../../components/form/StyledTextfield';

const classes = {
  stack: {
    gap: 1.5,
    padding: { xs: '16px 0', md: '24px 0' },
    width: { xs: '250px', sm: '300px', md: '400px' },
  },
  dialogContent: {
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 'auto',
    gap: 2,
  },
};

function FolderCreationForm() {
  const {
    register,
    formState: { errors },
    resetField,
    setValue,
  } = useFormContext();
  const [isActive, setIsActive] = useState<boolean>(true);

  return (
    <>
      <Stack
        display="flex"
        direction="column"
        justifyContent="space-around"
        sx={classes.stack}
      >
        <DialogContent sx={classes.dialogContent}>
          <SecondStyledTexfield
            {...register('details.name', {
              required: {
                value: true,
                message: 'Folder name is required.',
              },
            })}
            label="Folder Name"
            name="name"
            type="text"
            id="createFolderInput"
            fullWidth
            autoFocus={true}
            variant="outlined"
            error={errors && errors.details && errors.details.name ? true : false}
            onBlur={(e) => {
              const newValue = e.target.value;
              if (newValue !== '') {
                if (errors && errors.details && errors.details.name) {
                  resetField('details.name', {
                    keepDirty: true,
                    defaultValue: e.target.value,
                  });
                }
                setValue('details.name', newValue);
              }
            }}
          />
          <FormControlLabel
            control={
              <StyledSwitch
                {...register('details.active')}
                name="active"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
                sx={{ color: 'common.greyblueDark' }}
              />
            }
            label="Active"
            sx={{ margin: 0 }}
          />
        </DialogContent>
      </Stack>
    </>
  );
}

export default FolderCreationForm;
