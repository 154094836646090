import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

import ErrorHandler from '../../../utils/errorHandler';

const CustomPagination = ({ paletteMode }: { paletteMode: string }) => {
  const apiRef = useGridApiContext();
  const { state } = apiRef.current;
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const handleOnRowsPerPageChange = (event: SelectChangeEvent<any>) => {
    const size = typeof event.target.value === 'number' ? event.target.value : 10;
    apiRef.current.setPageSize(size);
  };

  const RowsPerPageSelector = () => {
    return (
      <FormControl
        variant="standard"
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}
      >
        <Box>
          <Typography
            variant="button"
            sx={{
              color: paletteMode === 'light' ? 'common.greyblueDark' : 'common.white',
              fontSize: 11,
            }}
          >
            total records:{' '}
          </Typography>
          <Typography
            data-testid="total-records"
            variant="button"
            sx={{
              color: paletteMode === 'light' ? 'common.greyblueDark' : 'common.white',
              fontSize: 11,
            }}
          >
            {apiRef.current.state.rows.totalRowCount}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="button"
            sx={{
              color: paletteMode === 'light' ? 'common.greyblueDark' : 'common.white',
              fontSize: 11,
              mr: 1,
            }}
          >
            Rows per page:
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            inputProps={{ id: 'rows-per-page' }}
            // id="rows-per-page"
            value={apiRef.current.state.pagination.pageSize}
            label="Size"
            size="small"
            onChange={(e) => handleOnRowsPerPageChange(e)}
            sx={{
              '.MuiSelect-select.MuiInputBase-input': { p: '0 20px 0 0' },
              '.MuiSelect-icon': {
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'common.white'
                    : 'common.greyblueDarker',
              },
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
      </FormControl>
    );
  };

  return (
    <ErrorHandler>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
        }}
      >
        <RowsPerPageSelector />
        <Pagination
          shape="rounded"
          size="small"
          color="secondary"
          count={pageCount}
          showFirstButton
          showLastButton
          siblingCount={2}
          boundaryCount={0} // 0 = removes the first page and last page number.
          page={page + 1}
          renderItem={(item) => {
            if (item.type === 'end-ellipsis' || item.type === 'start-ellipsis') return;
            return (
              <PaginationItem
                sx={{
                  color: paletteMode === 'light' ? 'common.greyblueDark' : 'common.white',
                }}
                {...item}
              />
            );
          }}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
          sx={{
            '& .MuiPaginationItem-root': {
              borderRadius: '50%',
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              '&:hover': {
                backgroundColor: 'common.greyblueDarker',
              },
              backgroundColor:
                paletteMode === 'light' ? 'common.greyblueDark' : 'common.greyblueDarker',
              color: 'common.white',
              fontWeight: 600,
            },
          }}
        />
      </Box>
    </ErrorHandler>
  );
};

export default CustomPagination;
