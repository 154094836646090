import React, { useContext, useState } from 'react';
import { Context as AuthContext } from '../../../context/authContext';
import { Context as StatusContext } from '../../../context/statusContext';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_USER, CREATE_USER_BY_ORG } from '../../../utils/gql/gqlUsers';
import { Context as FormContext } from './formContext';
import { StatusProps } from '../../../components/SnackBar';
import ModalActionsTabs from '../../../components/modal/ModalActionsTabs';
import { RefetchQueriesFunction } from 'react-apollo';

const passwordCheck = new RegExp(
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.{2,}?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$'
);

interface ActionsProps {
  handleCloseModal: () => void;
  formRef: any;
  setHasErrors: React.Dispatch<React.SetStateAction<any>>;
  setUserCreatedSnackbar: React.Dispatch<React.SetStateAction<any>>;
  tabsValue: number;
  refetch: RefetchQueriesFunction;
}

const Actions = ({
  handleCloseModal,
  formRef,
  setHasErrors,
  setUserCreatedSnackbar,
  tabsValue,
  refetch,
}: ActionsProps) => {
  const authContext = useContext(AuthContext);
  const { state } = useContext(StatusContext);
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;
  const [createUserMutation] = useMutation(isSuser ? CREATE_USER_BY_ORG : CREATE_USER);
  const [snackbar, setSnackbar] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'warning',
  });

  const {
    state: { formValues, errors },
    setErrorForm,
  } = useContext(FormContext);

  const hasProfileErrors = (errors: any, formValues: any) => {
    let values = ['firstName', 'lastName', 'password', 'emailaddress'];
    let hasError = values.some((value) => {
      return Object.keys(errors).includes(value);
    });

    let isEmpty = values.some((value) => {
      if (formValues[value] === '') {
        setErrorForm({
          value: { ...errors, [value]: { message: 'This field is required' } },
        });
        return true;
      } else return false;
    });

    if (hasError || isEmpty) {
      setHasErrors((prev: any) => ({ ...prev, profile: true }));
      return true;
    } else {
      setHasErrors((prev: any) => ({ ...prev, profile: false }));
      return false;
    }
  };

  const handleCloseSnackbar = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: 'warning',
    });
  };

  const createUser = (values: any) => {
    return createUserMutation({
      variables: {
        // avatar: values.image,
        //mobile: values.mobilenumber
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.emailaddress.toLowerCase(),
        permissions: values.permissions,
        password: values.password,
        role: values.role,
        changePw: values.changePW,
        organisationId: isSuser
          ? state.selectedOrg
            ? state.selectedOrg.id
            : selOrg.id
          : null,
      },
    });
  };

  const onUserCreation = async () => {
    const hasErrors = hasProfileErrors(errors, formValues);
    if (formRef && tabsValue === 0) formRef.current.click();
    if (hasErrors) return;
    if (passwordCheck.test(formValues.password)) {
      createUser(formValues)
        .then(({ data }) => {
          // window.log('Created user....', data);
          if (
            (isSuser ? !data.createUserByOrg.user : !data.createUser.user) ||
            (isSuser ? data.createUserByOrg.error : data.createUser.error)
          ) {
            setSnackbar({
              status: true,
              snackbarMessage: 'There was a problem creating the user',
              severity: 'error',
            });
          } else {
            setUserCreatedSnackbar({
              status: true,
              snackbarMessage: 'User Created Successfully',
              severity: 'success',
            });
            // reset();
            refetch();
            handleCloseModal();
          }
        })
        .catch((e) => {
          // window.log(e);
          setSnackbar({
            status: true,
            snackbarMessage: `${e}`,
            severity: 'error',
          });
        });
    }
  };
  return (
    <ModalActionsTabs
      submitText="Create user"
      handleSubmit={onUserCreation}
      handleCancel={handleCloseModal}
      snackbar={snackbar}
      handleCloseSnackbar={handleCloseSnackbar}
    />
  );
};

export default Actions;
