import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState } from 'react';
import { db } from '../../utils/indexedDB/indexeddb';
import ShowMaintenance from './ShowInMaintenance';

interface props {
  data: {
    startDate: string;
    endDate: string;
    message: string;
    restart: boolean;
  };
}

const InMaintenance = ({ data }: props) => {
  const { endDate, message } = data;

  return <ShowMaintenance endDate={endDate} message={message} />;
};

export default InMaintenance;
