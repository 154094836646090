import { Box, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { CustomAreaChart } from './components/AreaChart';

import {
  AuthContextTypes,
  Context as AuthContext,
} from '../../../../context/authContext';

import { folderDePrueba } from '../../MockupData';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';

import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_BY_ORG,
} from '../../../../utils/gql/gqlDashboard';
import { useQuery } from 'react-apollo';
import ChartHeader from '../ChartHeader';
import { Folder } from '../../../folder/types';

interface Props {
  folders: Folder[];
  gridLayout: number;
  selectedOrg: string;
}

const AreaChartGrid = ({ folders, selectedOrg, gridLayout }: Props) => {
  const [dataFolders, setDataFolders] = useState(folderDePrueba);
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;

  let queryVariables;

  if (folders) {
    queryVariables = {
      folderId: folders[0].id, // folder id,
      content: [
        { type: 'scanned', value: 42 },
        { type: 'indexed', value: 42 },
        { type: 'deleted', value: 42 },
      ],
    };
  }

  const { data, error, refetch } = useQuery(
    isSuser ? GET_DASHBOARD_DATA_BY_ORG : GET_DASHBOARD_DATA,
    {
      variables: queryVariables ? queryVariables : null,
      fetchPolicy: 'network-only',
    }
  );

  let dataFolder;

  if (data) {
    dataFolder = isSuser ? data.UsersByOrg.dataFolders : data.Users.dataFolders;
    setDataFolders(dataFolder);
    window.log(dataFolder);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>
      <ChartHeader title="Data Grid" />
      {gridLayout === 1 ? (
        <Grid
          container
          sx={{
            height: 'auto',
            backgroundColor: 'transparent',
          }}
          columnSpacing={1.1}
          rowSpacing={1.1}
        >
          <Grid item xs={12} sm={6} md={6}>
            <CustomAreaChart
              color="#B8F3FF"
              title="Scanned Records"
              stepper={true}
              data={dataFolders}
              folders={folders}
              type="scanned"
              frecuency={{ type: 'week', points: 42 }}
              selectedOrg={selectedOrg}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomAreaChart
              color="#7AE6A0"
              title="Connected Users"
              stepper={false}
              data={dataFolders}
              frecuency={{ type: 'week', points: 42 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomAreaChart
              color="#E6EB67"
              title="Indexed Records"
              stepper={true}
              data={dataFolders}
              folders={folders}
              type="indexed"
              frecuency={{ type: 'day', points: 42 }}
              selectedOrg={selectedOrg}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomAreaChart
              color="#FFC929"
              title="Deleted Records"
              stepper={true}
              data={dataFolders}
              folders={folders}
              type="deleted"
              frecuency={{ type: 'day', points: 42 }}
              selectedOrg={selectedOrg}
            />
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '10px',
          }}
        >
          <CustomAreaChart
            color="#B8F3FF"
            title="Scanned Records"
            stepper={true}
            data={dataFolders}
            folders={folders}
            type="scanned"
            frecuency={{ type: 'week', points: 42 }}
            selectedOrg={selectedOrg}
          />
          <CustomAreaChart
            color="#7AE6A0"
            title="Connected Users"
            stepper={false}
            data={dataFolders}
            frecuency={{ type: 'week', points: 42 }}
          />
          <CustomAreaChart
            color="#E6EB67"
            title="Indexed Records"
            stepper={true}
            data={dataFolders}
            folders={folders}
            type="indexed"
            frecuency={{ type: 'day', points: 42 }}
            selectedOrg={selectedOrg}
          />
          <CustomAreaChart
            color="#FFC929"
            title="Deleted Records"
            stepper={true}
            data={dataFolders}
            folders={folders}
            type="deleted"
            frecuency={{ type: 'day', points: 42 }}
            selectedOrg={selectedOrg}
          />
        </Box>
      )}
    </Box>
  );
};

export default AreaChartGrid;
