import { Button } from '@mui/material';
import React from 'react';

export interface ToolbarButtonLeftProps {
  buttonOnClick?: () => void;
  children: React.ReactNode;
  hasBackground?: boolean;
}

const ToolbarButtonLeft = ({
  buttonOnClick,
  children,
  hasBackground = true,
}: ToolbarButtonLeftProps) => {
  return (
    <Button
      sx={{
        minWidth: '10px',
        padding: '10px',
        marginRight: 2,
        color: hasBackground ? 'common.lightblue' : 'common.greyblueDark',
        '&:hover': {
          color: 'common.blue',
          backgroundColor: 'common.white',
        },
      }}
      onClick={buttonOnClick}
    >
      {children} {/* <- Icon */}
    </Button>
  );
};

export default ToolbarButtonLeft;
