import { Box } from '@mui/material';
import React from 'react';
import GeneralSnackbar, { StatusProps } from '../SnackBar';
import { StyledNegativeButton, StyledPositiveButton } from '../form/StyledButtons';

interface ModalActionsTabsProps {
  submitText: string;
  handleSubmit?: () => void;
  handleCancel: () => void;
  snackbar?: StatusProps;
  handleCloseSnackbar?: (event: Event | React.SyntheticEvent, reason?: string) => void;
}

const ModalActionsTabs = ({
  submitText,
  handleSubmit,
  handleCancel,
  snackbar,
  handleCloseSnackbar,
}: ModalActionsTabsProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'common.greyblueDarker',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        p: 1.5,
        gap: 1,
      }}
    >
      <StyledNegativeButton
        variant="contained"
        type="reset"
        size="medium"
        onClick={handleCancel}
      >
        Cancel
      </StyledNegativeButton>
      <StyledPositiveButton
        data-testid="submit-button"
        type="submit"
        onClick={handleSubmit}
        variant="contained"
        size="medium"
      >
        {submitText}
      </StyledPositiveButton>
      {snackbar && handleCloseSnackbar && (
        <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
      )}
    </Box>
  );
};

export default ModalActionsTabs;
