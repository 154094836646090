import React, { MutableRefObject, useContext, useEffect, useState } from 'react';

import { Box, Stack, DialogContent } from '@mui/material';

import { useForm } from 'react-hook-form';
import {
  PasswordInput,
  SelectRole,
  SelectTitle,
  SwitchActive,
  SwitchPW,
  TextInput,
} from './FormInput';
import AvatarInput from './FormAvatarInput';
import usersUtils from '../../../utils/usersUtils';
import GeneralSnackbar, { StatusProps } from '../../../components/SnackBar';
import { Context as FormContext, FormContextTypes } from './formContext';

type Errors = { profile: boolean; permissions: boolean };

interface UserCreationFormProps {
  formRef: MutableRefObject<null>;
  setHasErrors: React.Dispatch<React.SetStateAction<Errors>>;
}

export interface FormValuesCreateUser {
  firstName: string;
  lastName: string;
  password: string;
  emailaddress: string;
  avatar?: any;
  mobilenumber?: number;
  role: string;
  active: boolean;
  changePW: boolean;
  title: string;
}

const classes = {
  flexRow: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: 1, sm: 1.5 },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 1, sm: 1.5 },
  },
};

const passwordCheck = new RegExp(
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.{2,}?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$'
);

const UserCreationForm = ({ formRef, setHasErrors }: UserCreationFormProps) => {
  const { state: FormState, updateForm } = useContext(FormContext) as FormContextTypes;
  const {
    clearErrors,
    resetField,
    setValue,
    register,
    setError,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValuesCreateUser>({
    defaultValues: FormState.formValues ? FormState.formValues : {},
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [avatarImage, setAvatarImage] = useState<null | string>(null);
  const [snackbar, setSnackbar] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'warning',
  });
  const onChangeFun = (value: string) => updateForm({ field: 'password', value });

  const onGenPassword = () => {
    let password = usersUtils.passwordGenerator();
    clearErrors('password');
    resetField('password');
    setValue('password', password);
    onChangeFun(password);
  };

  const handleCloseSnackbar = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;

    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };

  const onSubmit = () => {};

  useEffect(() => {
    if (Object.keys(FormState.errors).length) {
      Object.entries(FormState.errors).forEach((entry) => {
        const [error, value] = entry;
        let customError = error as keyof FormValuesCreateUser;
        let customValue = value as { message: string };
        setError(customError, { message: customValue.message });
      });
    }
  }, []);

  useEffect(() => {
    if (isValid) setHasErrors((prev) => ({ ...prev, profile: false }));
  }, [isValid]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          display="flex"
          direction="column"
          justifyContent="space-around"
          sx={{
            height: '600px', // before 525px
            padding: { xs: '16px 0', md: '20px 0' },
            width: { xs: '300px', sm: '500px', md: '700px' },
          }}
        >
          <DialogContent
            sx={{
              padding: '5px 0',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              '& .MuiFormHelperText-root': {
                fontSize: '10px',
                marginLeft: 0,
              },
            }}
          >
            <Box sx={classes.flexColumn}>
              <Box
                sx={{
                  ...classes.flexRow,
                  gap: 2,
                  flexDirection: { xs: 'row' },
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
              >
                <SelectTitle register={register} control={control} />
                <Box sx={{ flex: 1, textAlign: 'center' }}>
                  <AvatarInput
                    register={register}
                    setError={setError}
                    classes={classes}
                    errors={errors}
                    avatarImage={avatarImage}
                    setAvatarImage={setAvatarImage}
                    resetField={resetField}
                  />
                </Box>
              </Box>
              <Box sx={classes.flexColumn}>
                <Box sx={{ ...classes.flexRow, flexDirection: 'row' }}>
                  <TextInput
                    register={register}
                    errors={errors}
                    options={{
                      id: 'firstName',
                      label: 'First Name',
                      field: 'firstName',
                      required: true,
                      pattern: /^[A-Za-zÁÉÍÓÚáéíóúñÑ' -]+$/g,
                      minLength: 3,
                      maxLength: 25,
                    }}
                  />
                  <TextInput
                    register={register}
                    errors={errors}
                    options={{
                      id: 'lastName',
                      label: 'Last Name',
                      field: 'lastName',
                      required: true,
                      pattern: /^[A-Za-zÁÉÍÓÚáéíóúñÑ' -]+$/g,
                      minLength: 3,
                      maxLength: 25,
                    }}
                  />
                </Box>
              </Box>
              <Box sx={classes.flexColumn}>
                <Box sx={classes.flexRow}>
                  <TextInput
                    register={register}
                    errors={errors}
                    options={{
                      id: 'mobilenumber',
                      label: 'Mobile number',
                      field: 'mobilenumber',
                      required: false,
                      pattern: /^[0-9]+$/,
                      minLength: 6,
                      maxLength: 30,
                      minNumber: 0,
                      maxNumber: 10000000000000,
                    }}
                  />
                  <TextInput
                    register={register}
                    errors={errors}
                    options={{
                      id: 'emailaddress',
                      label: 'Email',
                      field: 'emailaddress',
                      required: true,
                      pattern:
                        /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i,
                      minLength: 8,
                      maxLength: 50,
                    }}
                  />
                </Box>
              </Box>
              <PasswordInput
                register={register}
                errors={errors}
                passwordCheck={passwordCheck}
                setValue={setValue}
                setError={setError}
                genButton={onGenPassword}
                clearErrors={clearErrors}
              />
            </Box>
            <SwitchPW register={register} />
            <Box sx={{ ...classes.flexRow, flexDirection: 'row' }}>
              <SelectRole register={register} control={control} />
              <SwitchActive register={register} />
            </Box>
          </DialogContent>
        </Stack>
        <button type="submit" style={{ visibility: 'hidden' }} ref={formRef}></button>
      </form>
      <GeneralSnackbar
        status={snackbar}
        handleClose={handleCloseSnackbar}
      ></GeneralSnackbar>
    </>
  );
};
export default UserCreationForm;
