import { IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { useRef, useState, useEffect } from 'react';

import FontAwesomeIcon from '../../../../components/FAIcon';
import { FieldDropdownOptionsProps, Options } from '../../types';
import { SecondStyledTexfield } from '../../../../components/form/StyledTextfield';

const classes = {
  optionsWrapper: {
    maxHeight: '300px',
    overflow: 'auto',
    // Give the scrollbar some visibility
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#efefef',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bdbcbc',
      borderRadius: '10px',
    },
  },
};

export default function FieldDropdownOptions({
  options = [],
  fieldUpdates,
  updateField,
  newDropdownOption,
  context,
  selectedField,
  setSelectedField,
}: FieldDropdownOptionsProps) {
  const optionsEndRef = useRef<HTMLDivElement>(null);
  const [newOption, setNewOption] = useState(newDropdownOption || '');

  const scrollToBottom = () => {
    optionsEndRef.current && optionsEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const onDelete = (option: Options) => {
    if (!selectedField) return;
    const updatedOptions = selectedField.options
      .filter((item) => item.key !== option.key)
      .map((o) => ({ key: o.key, value: o.value }));

    updateField({
      ...fieldUpdates,
      folderFieldId: selectedField.id,
      options: updatedOptions,
    });
    setSelectedField &&
      setSelectedField((prev) => prev && { ...prev, options: updatedOptions });
  };

  const onAddNew = (newOption: string) => {
    if (!selectedField) return;
    const updatedOptions = [
      ...selectedField.options.map((o) => ({
        key: o.key,
        value: o.value,
      })),
      {
        key: newOption.split(' ').join('-').toLowerCase(),
        value: newOption,
      },
    ];

    updateField({
      ...fieldUpdates,
      folderFieldId: selectedField.id,
      options: updatedOptions,
    });
    setSelectedField &&
      setSelectedField((prev) => prev && { ...prev, options: updatedOptions });
  };

  useEffect(scrollToBottom, [options]);

  return (
    <>
      <div style={classes.optionsWrapper}>
        {options.map((option) => (
          <Stack key={option.key} direction="row" sx={{ padding: '10px' }}>
            <SecondStyledTexfield
              type="text"
              disabled
              name={option.key}
              value={option.value || ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {context !== 'table' && (
              <Tooltip title="Delete" onClick={() => onDelete(option)}>
                <IconButton
                  aria-label="delete-dropdown-option"
                  sx={{ width: '50px', marginLeft: '10px' }}
                >
                  <FontAwesomeIcon size="16px" icon="fas fa-trash" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        ))}
        <div ref={optionsEndRef}></div>
      </div>
      <Stack direction="row" sx={{ padding: '10px' }}>
        <SecondStyledTexfield
          type="text"
          placeholder="ex. Option 1"
          value={newOption || ''}
          onChange={(e) => {
            const value = e.target.value;
            setNewOption(value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Tooltip title="Add">
          <IconButton
            aria-label="add-dropdown-option"
            sx={{ width: '50px', marginLeft: '10px' }}
            disabled={newOption.trim() === ''}
            onClick={() => {
              onAddNew(newOption);
              setNewOption('');
            }}
          >
            <FontAwesomeIcon size="16px" icon="fas fa-plus" />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
}
