import { useState, useEffect } from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';

type StatusChipProps = {
  status?: 'online' | 'offline' | 'await';
  height?: string;
  width?: string;
};

const StatusChip = ({
  status,
  size,
  variant,
  height,
  width,
}: StatusChipProps & ChipProps) => {
  const [statusColor, setStatusColor] = useState('');
  status = 'online';

  useEffect(() => {
    if (status === 'online') setStatusColor('common.positive');
    if (status === 'offline') setStatusColor('common.negative');
    if (status === 'await') setStatusColor('common.orange');
  }, [status]);

  return (
    <>
      <Chip
        label={status.toUpperCase()}
        color="primary"
        size={size}
        variant={variant}
        sx={{
          backgroundColor: statusColor,
          fontSize: '8px',
          height: { height },
          width: { width },
        }}
      />
    </>
  );
};

export default StatusChip;
