import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import FontAwesomeIcon from '../FAIcon';
import { getBGColor } from './NotesForm';
import CardActions from './components/CardActions';
import Corner from './components/Corner';
import { NotesCardProps } from './notesTypes';

const styles = {
  content: {
    p: 2,
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '2px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
      margin: '30px 0',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
      margin: '10px 0',
    },
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    p: '1px 0px',
    alignItems: 'center',
  },
  accordion: {
    height: '100%',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  expandedButton: {
    position: 'absolute',
    top: 2,
    right: -20,
    transform: 'rotate(90deg)',
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent',
    },
    color: 'common.white',
  },
  userInfo: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};

const NotesCard = ({
  note,
  index,
  deleteNote,
  setMessage,
  setSnackOpen,
  isExpanded,
  allowManualExpand,
  longDate = true,
  actionButtonsText = true,
  allowShowActions = true,
}: NotesCardProps) => {
  const initialState = index !== undefined && index < 3 && note.id ? note.id : false;
  const [expanded, setExpanded] = useState<string | boolean>(isExpanded ?? initialState);
  const [showActions, setShowActions] = useState<boolean>(false);
  const theme = useTheme();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  let date = note.createdAt && new Date(note.createdAt).toLocaleDateString(); // e.g 13/9/2022
  let dateDetail =
    note.createdAt && new Date(note.createdAt).toLocaleString().replace(',', ''); // e.g 13/9/2022 12:46:09

  return (
    <Box
      data-testid="note-card"
      onMouseEnter={() => allowShowActions && setShowActions(true)}
      onMouseLeave={() => allowShowActions && setShowActions(false)}
      sx={{
        '& .MuiPaper-root': {
          minHeight: allowManualExpand ? '' : '180px',
          minWidth: '150px',
        },
      }}
    >
      <Accordion
        expanded={expanded === note.id}
        onChange={note.id ? handleChange(note.id) : undefined}
        elevation={0}
        sx={{
          ...styles.accordion,
          backgroundColor: expanded
            ? getBGColor(note.priority || 'Normal', theme.palette.mode)
            : '',
          marginBottom: expanded ? 0 : '10px',
        }}
      >
        {expanded ? null : (
          <AccordionSummary
            sx={{
              backgroundColor: expanded
                ? ''
                : getBGColor(note.priority || 'Normal', theme.palette.mode),
              marginBottom: '5px',
            }}
            expandIcon={
              allowManualExpand && (
                <FontAwesomeIcon
                  icon="fas fa-angle-right"
                  size={12}
                  padding={0}
                  color={
                    theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblueDark'
                  }
                />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                ...styles.userInfo,
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblue',
              }}
            >
              <FontAwesomeIcon
                icon="fa-sticky-note"
                size={14}
                padding={0}
                color={
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblueDark'
                }
              />
              {`${date} - ${note.createdBy?.firstName} ${note.createdBy?.lastName.charAt(
                0
              )}`}
            </Typography>
          </AccordionSummary>
        )}
        <AccordionDetails
          sx={{
            p: 0,
            opacity: expanded ? 1 : 0,
          }}
        >
          <Box>
            <Typography
              data-testid="note-content"
              sx={{
                ...styles.content,
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'common.white'
                    : 'common.greyblueDarker',
                overflowY: note.content?.length > 800 ? 'scroll' : 'hidden',
                wordBreak: 'break-all',
                whiteSpace: 'pre-line',
              }}
            >
              {note.content}
            </Typography>

            {allowManualExpand && (
              <Button
                onClick={() => setExpanded(false)}
                sx={{ ...styles.expandedButton, opacity: expanded ? 1 : 0 }}
                size="small"
              >
                <FontAwesomeIcon
                  icon="fas fa-angle-right"
                  size={12}
                  padding={0}
                  color="#8399a8"
                />
              </Button>
            )}
            <Corner
              priority={note.priority || 'Normal'}
              backgroundColor={
                theme.palette.mode === 'dark' ? 'black.main' : 'common.white'
              }
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      {expanded && note.createdBy && note.id && (
        <Box sx={styles.actionBox}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography
              data-testid="note-date"
              sx={{
                marginLeft: 1.5,
                fontSize: '10px',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblue',
              }}
            >
              {longDate ? dateDetail : date}
            </Typography>
            <Typography
              data-testid="note-author"
              sx={{
                fontSize: '10px',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblue',
              }}
            >
              by {note.createdBy?.firstName} {note.createdBy?.lastName.charAt(0)}
            </Typography>
          </Box>
          <CardActions
            showActions={showActions}
            note={note}
            setSnackOpen={setSnackOpen}
            setMessage={setMessage}
            deleteNote={deleteNote}
            actionButtonsText={actionButtonsText}
          />
        </Box>
      )}
    </Box>
  );
};

export default NotesCard;
