import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { useParams, useHistory } from 'react-router-dom';
import { GET_ORG_BY_ID } from '../../../utils/gql/gqlOrg';
import { Org } from '../OrgMain';
import { Box, Card } from '@mui/material';
import ToolbarBase from '../../../components/toolbarScreen/ToolbarBase';
import ToolbarButtonLeft from '../../../components/toolbarScreen/ToolbarButtonLeft';
import FontAwesomeIcon from '../../../components/FAIcon';
import ToolbarTitle from '../../../components/toolbarScreen/ToolbarTitle';
import {
  StyledCardContent,
  StyledTab,
  StyledTabPanel,
  StyledTabs,
  a11yProps,
} from '../../comm/CommStyled';
import ProfileTab from './ProfileTab';
import GeneralSnackbar, { HandleClose, StatusProps } from '../../../components/SnackBar';

interface Params {
  id: string;
}

const OrgProfile = () => {
  const [organisation, setOrganisation] = useState<Org | null>(null);
  const [tabValue, setTabValue] = useState<string>('profile'); // used to switch tabs
  const [snackbarStatus, setSnackbarStatus] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: undefined,
  });
  const { id } = useParams<Params>();
  let history = useHistory();

  useQuery(GET_ORG_BY_ID, {
    variables: { Id: id },
    onCompleted: (data) => {
      data?.OrganisationByOrg?.organisation &&
        setOrganisation(data.OrganisationByOrg.organisation);
    },
  });

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setTabValue(newValue);
  };

  const handleCloseSnack: HandleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarStatus({
      status: false,
      snackbarMessage: '',
      severity: 'success',
    });
  };

  return (
    <>
      <Box>
        <ToolbarBase hasBackground={true}>
          <ToolbarButtonLeft buttonOnClick={() => history.goBack()}>
            <FontAwesomeIcon icon="fas fa-angle-left" />
          </ToolbarButtonLeft>
          <ToolbarTitle title={organisation?.name || 'Profile'} />
        </ToolbarBase>

        <Card
          variant="outlined"
          sx={{
            marginTop: '15px',
            overflow: 'hidden',
          }}
        >
          <StyledCardContent>
            <StyledTabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
            >
              <StyledTab
                icon={<FontAwesomeIcon icon="far fa-building" />}
                label="Profile"
                value={'profile'}
                {...a11yProps('profile')}
              />
            </StyledTabs>
            {organisation && (
              <StyledTabPanel value={tabValue} index="profile" sx={{ p: 3 }}>
                <ProfileTab
                  setSnackbarStatus={setSnackbarStatus}
                  org={organisation}
                  setOrganisation={setOrganisation}
                />
              </StyledTabPanel>
            )}
          </StyledCardContent>
        </Card>
      </Box>

      <GeneralSnackbar status={snackbarStatus} handleClose={handleCloseSnack} />
    </>
  );
};

export default OrgProfile;
