import { Typography, Button, Box } from '@mui/material';
import { useState } from 'react';
import FontAwesomeIcon from '../../../../../components/FAIcon';
import { Folder } from '../../../../folder/types';

const classes = {
  stepper: {
    zIndex: 9,
    position: 'absolute',
    right: 5,
    bottom: 5,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  activeFolder: {
    fontSize: 11,
    fontWeight: 100,
    color: '#79909f',
  },
  buttonFolder: {
    minWidth: 10,
    padding: 0,
    '&:disabled': {
      opacity: 0,
    },
  },
};

interface Props {
  getFolderData: () => void;
  loadedFolders: Folder[];
}

const FolderSwitcher = ({ loadedFolders, getFolderData }: Props) => {
  const [activeFolder, setActiveFolder] = useState(0);

  if (!loadedFolders) return null;

  const handleNext = () => {
    setActiveFolder((prevActiveFolder) => prevActiveFolder + 1);
    // getFolderData(loadedFolders[activeFolder + 1].id);
  };

  const handleBack = () => {
    setActiveFolder((prevActiveFolder) => prevActiveFolder - 1);
    // getFolderData(loadedFolders[activeFolder - 1].id);
  };

  return (
    <Box sx={classes.stepper}>
      <Button
        size="large"
        onClick={handleBack}
        disabled={activeFolder === 0}
        sx={classes.buttonFolder}
      >
        <FontAwesomeIcon icon="fa-solid fa-caret-left" size={15} color="#79909f" />
      </Button>
      <Typography sx={classes.activeFolder}>
        {loadedFolders[activeFolder].name}
      </Typography>
      <Button
        size="small"
        onClick={handleNext}
        disabled={activeFolder === loadedFolders.length - 1}
        sx={classes.buttonFolder}
      >
        <FontAwesomeIcon icon="fa-solid fa-caret-right" size={15} color="#79909f" />
      </Button>
    </Box>
  );
};

export default FolderSwitcher;
