import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { Folder, FolderFields } from '../containers/folder/types';
import {
  GET_FOLDER_BY_ID,
  GET_FOLDER_BY_ID_BY_ORG,
  UPDATE_FOLDER,
  UPDATE_FOLDER_FIELD,
} from '../utils/gql/gqlFolders';
import useUserStorageData from './user/userUserStorageData';

function useFolder(id: string) {
  const { isSuser, selOrg } = useUserStorageData();
  const [folder, setFolder] = useState<Folder>();
  const [fields, setFields] = useState<FolderFields[]>();
  const [globalOptions, setGlobalOptions] = useState();

  const [getFolder, { data, error, loading }] = useLazyQuery(
    isSuser ? GET_FOLDER_BY_ID_BY_ORG : GET_FOLDER_BY_ID,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
        if (data) {
          const { FolderByOrg, Folder } = data;
          const folder = isSuser ? FolderByOrg : Folder.folder;
          const folderFields = folder?.folderFields;
          setFolder({ ...folder });
          setFields(folderFields);
          setGlobalOptions({
            active: folder?.active || false,
            scanning: folder?.scanning || false,
            autoAppendRecords: folder?.autoAppendRecords || false,
            workFlow: folder?.workFlow || false,
            ocrEnabled: folder?.ocrEnabled || false,
            enforceWorkflowNotes: false,
          });
        }
      },
    }
  );

  useEffect(() => {
    getFolder({
      variables: isSuser ? { id, organisationId: selOrg.id } : { id },
    });
  }, [id, isSuser, selOrg, getFolder]);

  const [updateFolder] = useMutation(UPDATE_FOLDER);
  const [updateFolderField] = useMutation(UPDATE_FOLDER_FIELD);

  return {
    folder,
    setFolder,
    error,
    loading,
    fields,
    setFields,
    globalOptions,
    setGlobalOptions,
    updateFolder,
    updateFolderField,
  };
}

export default useFolder;
