import styled from 'styled-components';
import { setColor } from './colorScheme';

export const dashStyle = {
  StyledDash: styled.div`
    width: 100%;
    height: 100%;
    background-color: ${setColor.mainWhite};
    display: flex;
  `,
};

export const StyledUsersPage = styled.div`
  height: 100%;
`;

export const StyledIndexingPage = styled.div`
  height: 100%;
`;

export const StyledFoldersPage = styled.div`
  height: 100%;
`;

export const StyledRecordsPage = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

export const StyledListView = styled.div`
  height: 90%;
  overflow-y: scroll;
  background: white;
  margin-bottom: 20px;
  ul {
    width: 100%;
    list-style: none;
    border-radius: 5px;
    /* box-shadow: inset 3px 3px 8px #00000014, inset -3px -3px 8px #ffff; */
    padding-bottom: 30px;
    max-height: 65vh;
    overflow-y: scroll;
  }
  ul::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }
  ul::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }
  ul::-webkit-scrollbar-thumb {
    background-color: ${setColor.primary}; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
  ul a {
    text-decoration: none;
    color: inherit;
  }
  ul li,
  .listTitles {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }
  ul li span,
  .listTitles span {
    width: 100%;
  }
  ul li:nth-of-type(odd) {
    background-color: black;
  }
  .listTitles {
    position: sticky;
    top: 0;
    z-index: 9;
    background: ${setColor.primary};
    font-family: 'PTSans-Bold', sans-serif;
    font-weight: 800;
    color: ${setColor.mainWhite};
    border-radius: 5px;
    /* box-shadow: 5px 5px 8px #0000001c, -5px -5px 8px #ffffff; */
    text-transform: uppercase;
    margin-bottom: 20px;
    border: 5px solid ${setColor.mainGrey};
  }
  ul li {
    cursor: pointer;
    :hover {
      border-radius: 5px;
      box-shadow: 3px 3px 8px #0000001c, -3px -3px 8px #ffffff;
    }
  }
`;
export const StyledActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-area: bottombar;
`;
