import React from 'react';
import FontAwesomeIcon from '../../components/FAIcon';
import ToolbarActionRight from '../../components/toolbarScreen/ToolbarActionRight';
import ToolbarBase from '../../components/toolbarScreen/ToolbarBase';
import ToolbarButtonLeft from '../../components/toolbarScreen/ToolbarButtonLeft';
import ToolbarTitle from '../../components/toolbarScreen/ToolbarTitle';

const CommToolbar = () => {
  return (
    <ToolbarBase background={true}>
      <ToolbarButtonLeft>
        <FontAwesomeIcon icon="fas fa-angle-left" />
      </ToolbarButtonLeft>
      <ToolbarTitle title="Comm" />
    </ToolbarBase>
  );
};

export default CommToolbar;
