import { Stack } from '@mui/material';
import { Step } from '../../../../containers/workflow/types';
import { SetMessage, SetSnackBarState } from '../../../masterTable/masterTableTypes';
import Approve from './actionTypes/Approve';

interface StepActionProps {
  step: Step;
  setMessage: SetMessage;
  setSnackOpen: SetSnackBarState;
  setAssignedAction: React.Dispatch<React.SetStateAction<boolean>>;
  record: any;
}

const renderStepAction = (
  step: StepActionProps['step'],
  setMessage: StepActionProps['setMessage'],
  setSnackOpen: StepActionProps['setSnackOpen'],
  setAssignedAction: StepActionProps['setAssignedAction'],
  refetch,
  record: StepActionProps['record']
) => {
  if (!step) return;
  switch (step.__typename) {
    case 'ApprovalStep':
      return (
        <Approve
          step={step}
          setMessage={setMessage}
          setSnackOpen={setSnackOpen}
          setAssignedAction={setAssignedAction}
          refetch={refetch}
          record={record}
        />
      );
    default:
      break;
  }
};

const StepAction = ({
  step,
  setMessage,
  setSnackOpen,
  setAssignedAction,
  refetch,
  record,
}: StepActionProps) => {
  return (
    <Stack spacing={1}>
      {renderStepAction(
        step,
        setMessage,
        setSnackOpen,
        setAssignedAction,
        refetch,
        record
      )}
    </Stack>
  );
};

export default StepAction;
