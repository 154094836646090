import { Box, Grid, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Row, User } from '../components/masterTable/masterTableTypes';
import GeneralSnackbar, { HandleClose, StatusProps } from '../components/SnackBar';
import WorkFlowMain from '../containers/workflow/Main/WorkFlowMain';
import ToolbarLeft from '../containers/workflow/ToolbarLeft/ToolbarLeft';
import { Folder, StepError, Workflow } from '../containers/workflow/types';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Context as StatusContext } from '../context/statusContext';
import { Context as AuthContext, AuthContextTypes } from '../context/authContext';
import { Context as TaskContext } from '../context/tasksContext';
import {
  ADD_PROCESS_PATH_TO_FOLDER,
  CREATE_PROCESS_PATH,
  // CREATE_PROCESS_STEP,
  DELETE_PROCESS_PATH,
  // DELETE_PROCESS_STEP,
  GET_PROCESS_PATHS,
  // ADD_PROCESS_PATH_TEMPLATE_TO_RECORD,
  UPDATE_PROCESS_PATH,
} from '../utils/gql/gqlProcessPaths';
import gqlRecords from '../utils/gql/gqlRecords';
import { checkForErrorsInSteps, generateSteps } from '../containers/workflow/utils';
import utils from '../utils/utils';
import { useWorkflow } from '../containers/workflow/useWorkflow';
import { StatusContextTypes } from '../context/statusContextTypes';
import { TasksContextTypes } from '../context/tasksContextTypes';
import _ from 'lodash';
import { GET_FOLDER_BY_ID, GET_FOLDER_BY_ID_BY_ORG } from '../utils/gql/gqlFolders';

interface WorkFlowProps {
  context: string;
  recordId?: string;
  setWorkflowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  records: Row[];
  refresh?: (values: any, isSearch?: boolean) => void;
  canSetWorkflow?: boolean;
  setModalHasModifications: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFolder: Folder | null;
  recordsToSet: string[];
}

const WorkFlow = ({
  context = 'screen',
  recordId,
  records,
  setWorkflowModal,
  canSetWorkflow,
  setModalHasModifications = () => {},
  selectedFolder,
  recordsToSet,
}: WorkFlowProps) => {
  const widthMatches = useMediaQuery('(min-width:768px)');
  const [folder, setFolder] = useState<Folder | null>(selectedFolder || null);
  const refCardsContainer = useRef<null | HTMLAnchorElement>(null);
  const { state, setScreen } = useContext(StatusContext) as StatusContextTypes;
  const { addTask } = useContext(TaskContext) as TasksContextTypes;
  const [users, setUsers] = useState<User[]>([]);
  const [snackbarStatus, setSnackbarStatus] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: undefined,
  });
  // const [stepsWithErrors, setStepsWithErrors] = useState<StepError[]>([]);
  const [userData] = useLocalStorage('userData');
  const [{ lastWorkflowFolder }] = useLocalStorage('userSettings');
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const isSuser = authContext.state.suser || suser;
  const { refetch } = useQuery(GET_PROCESS_PATHS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setWorkflows(data.processPaths),
  });

  const {
    selectedWorkflow,
    setSelectedWorkflow,
    handleRemoveStep,
    handleUpdateStep,
    handleAddStep,
    steps,
    setSteps,
    stepsWithErrors,
    setStepsWithErrors,
    modifiedTemplate,
    setWorkflows,
    workflows,
    setModifiedTemplate,
    isLoading,
    handleUpdateStepOrders,
  } = useWorkflow(refCardsContainer, setModalHasModifications);

  useQuery(isSuser ? GET_FOLDER_BY_ID_BY_ORG : GET_FOLDER_BY_ID, {
    skip: folder === null,
    variables: isSuser
      ? {
          id: folder?.id || '',
          organisationId:
            state.selectedOrg && _.isObject(state.selectedOrg)
              ? state.selectedOrg.id
              : selOrg.id,
        }
      : { id: folder?.id || '' },
    onCompleted: (data) => {
      if (data && (isSuser ? data.FolderByOrg : data.Folder.folder)) {
        setUsers(
          isSuser
            ? data.FolderByOrg.performProcessStepUsers
            : data.Folder.folder.performProcessStepUsers
        );
      }
    },
    fetchPolicy: 'network-only',
  });

  const [CREATE_WORKFLOW] = useMutation(CREATE_PROCESS_PATH);
  const [UPDATE_WORKFLOW] = useMutation(UPDATE_PROCESS_PATH);
  const [DELETE_WORKFLOW] = useMutation(DELETE_PROCESS_PATH);
  // const [CREATE_STEP] = useMutation(CREATE_PROCESS_STEP);
  // const [DELETE_STEP] = useMutation(DELETE_PROCESS_STEP);
  const [APPLY_TEMPLATE_TO_FOLDER] = useMutation(ADD_PROCESS_PATH_TO_FOLDER);
  // const [APPLY_TEMPLATE_TO_RECORD] = useMutation(ADD_PROCESS_PATH_TEMPLATE_TO_RECORD);
  const [SET_WORKFLOW_TO_RECORD] = useMutation(gqlRecords.UPDATE_RECORD_ASYNC);

  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarStatus({
      status: false,
      snackbarMessage: '',
      severity: snackbarStatus.severity,
    });
  };

  const updateTemplate = (workflow: Workflow | null) => {
    if (!workflow) return;
    UPDATE_WORKFLOW({
      variables: {
        processPathId: workflow.id,
        ProcessPath: {
          name: workflow.name,
          processSteps: generateSteps(
            selectedWorkflow ? selectedWorkflow.processSteps : steps
          ),
        },
      },
    }).then((res) => console.log('res', res));
    // modifiedTemplate.forEach((step) => {
    //   if (step.modType === 'add') {
    //     let user = { userLname, userName, id };
    //     let newStep = addStep(user, step.__typename, [], workflow?.processSteps.length);
    //     let steps = generateSteps(newStep);
    //     return CREATE_STEP({
    //       variables: {
    //         processPathId: step.pathId,
    //         processStep: steps[0],
    //       },
    //     });
    //   } else if (step.modType === 'delete') {
    //     return DELETE_STEP({
    //       variables: {
    //         processPathId: step.pathId,
    //         processStepId: step.id,
    //       },
    //     });
    //   }
    // });
    setSnackbarStatus({
      status: true,
      snackbarMessage: 'Template updated successfully',
      severity: 'success',
    });
  };

  const applyTemplateToFolder = (pathId: string) => {
    if (folder && Object.keys(folder).length) {
      APPLY_TEMPLATE_TO_FOLDER({ variables: { id: folder && folder?.id!, pathId } })
        .then((data) => {
          setSnackbarStatus({
            status: true,
            snackbarMessage: 'Template applied successfully',
            severity: 'success',
          });
        })
        .catch((error) => console.log('error', error));
    }
  };

  const applyTemplateToRecord = () => {
    if (context === 'screen' && !folder) return;

    if (!canSetWorkflow)
      return setSnackbarStatus({
        status: true,
        snackbarMessage: 'You cannot set workflow',
        severity: 'error',
      });

    if (!recordsToSet.length && !state.selectedRecord) return;

    if (steps.length || _.isArray(selectedWorkflow?.processSteps)) {
      let stepsWithError = checkForErrorsInSteps(
        selectedWorkflow?.processSteps || steps,
        setStepsWithErrors
      );

      if (!stepsWithError) {
        let taskId = utils.generateTaskID(userData);
        addTask({
          id: taskId,
          folderName: (folder && folder.name) || '',
          action: 'update_records',
          ammount: recordsToSet.length || 1,
          screen: state.screen || 'search',
        });

        let processSteps = generateSteps(selectedWorkflow?.processSteps || steps);

        SET_WORKFLOW_TO_RECORD({
          variables: {
            recordsToUpdate: {
              updateRecordInputs: recordsToSet.length // if multiple records are selected, we create an array to set the processSteps to all of them.
                ? recordsToSet.map((id) => ({
                    recordId: id,
                    processPath: { processSteps },
                  }))
                : [
                    // if only one records is selected, we simply take the ID from state
                    {
                      recordId: _.isObject(state.selectedRecord)
                        ? state.selectedRecord.id
                        : state.selectedRecord,
                      processPath: { processSteps },
                    },
                  ],
            },
            taskId: taskId,
          },
        })
          .then(() => {})
          .then(() => setWorkflowModal && setWorkflowModal(false));
      }
    }
  };

  const handleSaveTemplate = (name: string, applyToFolder: boolean) => {
    CREATE_WORKFLOW({
      variables: {
        ProcessPath: {
          name,
          processSteps: generateSteps(
            selectedWorkflow ? selectedWorkflow.processSteps : steps
          ),
        },
      },
    })
      .then((data) => {
        if (applyToFolder)
          applyTemplateToFolder(data.data.createProcessPath.processPath.id);
        refetch().then((data) => setWorkflows(data.data.processPaths));
        setSnackbarStatus({
          status: true,
          snackbarMessage: 'Template created successfully',
          severity: 'success',
        });
        setModifiedTemplate([]);
        setSelectedWorkflow(data.data.createProcessPath.processPath);
      })
      .catch((error) => console.log('error', error));
  };

  const deleteWorkflow = (id: string) => {
    DELETE_WORKFLOW({ variables: { processPathId: id } })
      .then((data) => {
        refetch().then((data) => setWorkflows(data.data.processPaths));
        setSnackbarStatus({
          status: true,
          snackbarMessage: 'Template deleted successfully',
          severity: 'error',
        });
        setSelectedWorkflow(null);
      })
      .catch((error) => console.log('error', error));
  };

  const handleSaveWorkflow = () => {
    // add process path to folder
    // addProcessPathToFolder
    console.log(
      'SAVE WORKFLOW',
      selectedWorkflow ? selectedWorkflow.processSteps : steps
    );
  };

  useEffect(() => {
    if (context === 'screen') setScreen('workflow');
  }, []);

  useEffect(() => {
    if (
      state.selectedRecord &&
      _.isObject(state.selectedRecord) &&
      state.selectedRecord.processPath &&
      recordsToSet.length <= 1
    ) {
      setSelectedWorkflow(state.selectedRecord.processPath);
    } else {
      setSelectedWorkflow(null);
    }
  }, [state.selectedRecord]);

  useEffect(() => {
    if (context === 'screen' && lastWorkflowFolder) setFolder({ id: lastWorkflowFolder });
  }, [lastWorkflowFolder]);

  useEffect(() => {
    console.log('selected ' + recordId);
    if (records) {
      const record = records.find((el) => el.id === recordId);
      // console.log(record);
    }
  }, [recordId]);

  console.log('is loading', isLoading);

  return (
    <Box
      sx={{
        padding: 0,
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Grid
        container
        direction={widthMatches ? 'row' : 'column'}
        sx={{ height: '100%', flexWrap: 'nowrap', gap: 2 }}
      >
        <ToolbarLeft
          steps={steps}
          handleAddStep={handleAddStep}
          workflows={workflows}
          setWorkflows={setWorkflows}
          setSelectedWorkflow={setSelectedWorkflow}
          selectedWorkflow={selectedWorkflow}
          deleteWorkflow={deleteWorkflow}
          folder={folder}
          setFolder={setFolder}
          applyTemplateToFolder={applyTemplateToFolder}
          context={context}
        />
        <WorkFlowMain
          refCardsContainer={refCardsContainer}
          steps={selectedWorkflow ? selectedWorkflow.processSteps : steps}
          handleRemoveStep={handleRemoveStep}
          handleAddStep={handleAddStep}
          handleUpdateStep={handleUpdateStep}
          handleUpdateStepOrders={handleUpdateStepOrders}
          isLoading={isLoading}
          handleSaveTemplate={handleSaveTemplate}
          handleSaveWorkflow={handleSaveWorkflow}
          selectedWorkflow={selectedWorkflow}
          modifiedTemplate={modifiedTemplate}
          updateTemplate={updateTemplate}
          context={context}
          applyTemplateToRecord={applyTemplateToRecord}
          workflows={workflows}
          setSelectedWorkflow={setSelectedWorkflow}
          setWorkflowModal={setWorkflowModal}
          applyTemplateToFolder={applyTemplateToFolder}
          users={users}
          stepsWithErrors={stepsWithErrors}
          setSteps={setSteps}
          setStepsWithErrors={setStepsWithErrors}
          setModalHasModifications={setModalHasModifications}
        />
      </Grid>
      <GeneralSnackbar status={snackbarStatus} handleClose={handleCloseSnackbar} />
    </Box>
  );
};

export default WorkFlow;
