import { MutableRefObject, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Step, StepError, Workflow } from './types';
import {
  addStep,
  checkForErrorsInSteps,
  removeStep,
  reorderSteps,
  updateStep,
} from './utils';

export const useWorkflow = (
  refCardsContainer: MutableRefObject<HTMLAnchorElement | null>,
  setModalHasModifications?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<null | Workflow>(null);
  const [modifiedTemplate, setModifiedTemplate] = useState<any[]>([]);
  const [steps, setSteps] = useState<Step[]>([]);
  const [stepsWithErrors, setStepsWithErrors] = useState<StepError[]>([]);
  const [{ userLname, userName, id }] = useLocalStorage('userData');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemoveStep = (stepOrder: number, id: string) => {
    if (selectedWorkflow) {
      setModifiedTemplate((prev) => [
        ...prev,
        { id, modType: 'delete', pathId: selectedWorkflow.id },
      ]);
      setSelectedWorkflow((workflow) => {
        let filtered = removeStep(workflow?.processSteps!, stepOrder);
        checkForErrorsInSteps(filtered, setStepsWithErrors);
        return workflow && filtered ? { ...workflow, processSteps: filtered } : workflow;
      });
      setWorkflows((prev) => {
        return prev.map((work) => {
          return work.id === selectedWorkflow?.id
            ? (work = { ...work, processSteps: removeStep(work.processSteps, stepOrder) })
            : work;
        });
      });
      setSteps((prev) => removeStep(prev, stepOrder));
    } else {
      setSteps((prev) => {
        let newSteps = removeStep(prev, stepOrder);
        checkForErrorsInSteps(newSteps, setStepsWithErrors);
        return newSteps;
      });
    }
    setModalHasModifications && setModalHasModifications(true);
  };

  const handleAddStep = (type: string) => {
    let user = { userLname, userName, id };
    if (selectedWorkflow) {
      let newStep = addStep(user, type, [], selectedWorkflow.processSteps.length);
      setModifiedTemplate((prev) => [
        ...prev,
        { ...newStep[0], modType: 'add', pathId: selectedWorkflow.id },
      ]);
      setSelectedWorkflow((workflow) => {
        return workflow
          ? {
              ...workflow,
              processSteps: addStep(user, type, workflow?.processSteps!),
            }
          : workflow;
      });
      setWorkflows((prev) => {
        return prev.map((work) => {
          return work.id === selectedWorkflow?.id
            ? (work = {
                ...work,
                processSteps: addStep(user, type, work.processSteps),
              })
            : work;
        });
      });
      setSteps((prev: Step[]) => addStep(user, type, prev));
    } else {
      setSteps((prev: Step[]) => addStep(user, type, prev));
    }

    if (refCardsContainer && refCardsContainer.current) {
      refCardsContainer.current.scrollLeft += 1000;
    }
    setModalHasModifications && setModalHasModifications(true);
  };

  const handleUpdateStep = (stepOrder: number, key: string, value: any) => {
    if (selectedWorkflow) {
      setModifiedTemplate((prev) => [
        ...prev,
        { id, modType: 'key', pathId: selectedWorkflow.id },
      ]);

      setSelectedWorkflow((prev: any) => {
        let processSteps = updateStep(prev.processSteps, key, value, stepOrder);
        checkForErrorsInSteps(processSteps, setStepsWithErrors);
        return { ...prev, processSteps };
      });
      setWorkflows((prev) => {
        return prev.map((work) => {
          return work.id === selectedWorkflow?.id
            ? {
                ...work,
                processSteps: updateStep(work.processSteps, key, value, stepOrder),
              }
            : work;
        });
      });
      setSteps((prev) => updateStep(prev, key, value, stepOrder));
    } else {
      setSteps((prev) => {
        let newSteps = updateStep(prev, key, value, stepOrder);
        checkForErrorsInSteps(newSteps, setStepsWithErrors);
        return newSteps;
      });
    }

    setModalHasModifications && setModalHasModifications(true);
  };

  const handleUpdateStepOrders = (startIndex: number, endIndex: number) => {
    setIsLoading(true);
    const newSteps = reorderSteps(
      selectedWorkflow ? selectedWorkflow.processSteps : steps,
      startIndex,
      endIndex
    );

    checkForErrorsInSteps(newSteps, setStepsWithErrors);

    if (selectedWorkflow) {
      setModifiedTemplate((prev) => [
        ...prev,
        { id, modType: 'stepOrder', pathId: selectedWorkflow.id },
      ]);
      setSelectedWorkflow((prev: any) => ({ ...prev, processSteps: newSteps }));
      setWorkflows((prev) => {
        return prev.map((work) =>
          work.id === selectedWorkflow?.id ? { ...work, processSteps: newSteps } : work
        );
      });
      setSteps(newSteps);
    } else {
      setSteps(newSteps);
    }
    setIsLoading(false);
    setModalHasModifications && setModalHasModifications(true);
  };

  return {
    workflows,
    setWorkflows,
    selectedWorkflow,
    setSelectedWorkflow,
    stepsWithErrors,
    setStepsWithErrors,
    modifiedTemplate,
    steps,
    isLoading,
    setSteps,
    handleAddStep,
    handleUpdateStep,
    handleRemoveStep,
    handleUpdateStepOrders,
    setModifiedTemplate,
  };
};
