import { Typography } from '@mui/material';

interface ToolbarTitleProps {
  title: string;
  hasBackground?: boolean;
  fontSize?: number | string;
}

const ToolbarTitle = ({
  title,
  hasBackground = true,
  fontSize = 18,
}: ToolbarTitleProps) => {
  return (
    <Typography
      id="toolbar-title"
      variant="h6"
      noWrap
      sx={{
        flexGrow: 1,
        fontSize: fontSize,
        color: hasBackground ? '#fff' : 'common.greyblueDark',
      }}
    >
      {title}
    </Typography>
  );
};

export default ToolbarTitle;
