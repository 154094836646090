import { Typography, Button, Box } from '@mui/material';
import React, { useState } from 'react';
import FontAwesomeIcon from '../../../../../components/FAIcon';
import { Folder } from '../../../../folder/types';
import { Frecuency } from '../../../DashboardMain';

const classes = {
  stepper: {
    zIndex: 9,
    position: 'absolute',
    right: 15,
    bottom: -75,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  activeFolder: {
    fontSize: 11,
    fontWeight: 100,
    color: '#79909f',
    marginBottom: '.5px',
  },
  buttonFolder: {
    minWidth: 10,
    padding: 0.2,
    '&:disabled': {
      opacity: 0,
    },
  },
};

interface Props {
  folder: string;
  folders?: Folder[];
  type: string;
  frecuency: Frecuency;
  getFolderData: (id: string, type: string, frecuency: Frecuency) => void;
}

const FolderSwitcher = ({ folders, getFolderData, type, frecuency }: Props) => {
  const [activeFolder, setActiveFolder] = useState(0);

  if (!folders) return null;

  //   window.log('datos de la carpeta: ' + folders[activeFolder].id);

  const handleNext = () => {
    setActiveFolder((prevActiveFolder) => prevActiveFolder + 1);
    getFolderData(folders[activeFolder + 1].id, type, frecuency);
  };

  const handleBack = () => {
    setActiveFolder((prevActiveFolder) => prevActiveFolder - 1);
    getFolderData(folders[activeFolder - 1].id, type, frecuency);
  };

  return (
    <Box sx={classes.stepper}>
      <Button
        size="small"
        onClick={handleBack}
        disabled={activeFolder === 0}
        sx={classes.buttonFolder}
      >
        <FontAwesomeIcon icon="fa-solid fa-caret-left" size={15} color="#79909f" />
      </Button>
      <Typography sx={classes.activeFolder}>{folders[activeFolder].name}</Typography>
      <Button
        size="small"
        onClick={handleNext}
        disabled={activeFolder === folders.length - 1}
        sx={classes.buttonFolder}
      >
        <FontAwesomeIcon icon="fa-solid fa-caret-right" size={15} color="#79909f" />
      </Button>
    </Box>
  );
};

export default FolderSwitcher;
