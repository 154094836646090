import React, { useContext } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Context as StatusContext } from '../../../context/statusContext';
import { Context as AuthContext } from '../../../context/authContext';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import FontAwesomeIcon from '../../FAIcon';
import {
  ContextMenuObject,
  DefaultVisibleRows,
  MasterTableProps,
  Scan,
} from '../masterTableTypes';
import { hasSetWorkflowPermissions } from '../../../containers/search/searchUtils';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.common.greyblueDarker,
    overflow: 'visible',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '-8px',
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderRight: '10px solid',
      borderRightColor: theme.palette.common.greyblueDarker,
    },
  },
  '& .MuiMenuItem-root': {
    color: theme.palette.common.white,
    '& .MuiIcon-root': {
      fontSize: 18,
      marginRight: theme.spacing(1.5),
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.common.orange,
  },
}));

const DeleteMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.common.negative,
  },
}));

interface ContextualMenuProps {
  defaultVisibleRows?: DefaultVisibleRows;
  scan?: Scan;
  rows: MasterTableProps['rows'];
  onSaveRows: MasterTableProps['onSaveRows'];
  contextMenu: ContextMenuObject | null;
  handleDelete: () => void;
  handleAddToCollection: () => Promise<void>;
  handleCloseContextMenu: () => void;
  setWorkflowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleReapplyLinkedInformations: (selected: string[]) => void;
  selectionModel: string[];
  selected: string[];
}

function ContextualMenu(props: ContextualMenuProps) {
  const {
    defaultVisibleRows,
    scan,
    rows,
    onSaveRows,
    contextMenu,
    handleDelete,
    handleAddToCollection,
    handleCloseContextMenu,
    setWorkflowModal,
    handleReapplyLinkedInformations,
    selected,
    selectionModel,
  } = props;
  const { state } = useContext(StatusContext);
  const { state: authState } = useContext(AuthContext);
  const [currentUser] = useLocalStorage('userData');

  const checkMainField = () => {
    const mainField = defaultVisibleRows?.filter((header) => header.mainField === true);
    if (mainField && mainField[0]) {
      return mainField[0].type === 'string';
    } else return false;
  };

  return (
    <StyledMenu
      open={contextMenu !== null}
      onClose={handleCloseContextMenu}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? {
              top: contextMenu.mouseY,
              left: contextMenu.mouseX,
            }
          : undefined
      }
      componentsProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleCloseContextMenu();
          },
        },
      }}
    >
      {state.screen === 'indexing' && (
        <Box>
          <StyledMenuItem
            onClick={(e) => {
              onSaveRows(e, true, rows);
              handleCloseContextMenu();
            }}
          >
            <FontAwesomeIcon icon="fas fa-file-upload" size="18px" />
            Index Modified
          </StyledMenuItem>
          {checkMainField() && state.selectedFolder.indexing.scanning && (
            <StyledMenuItem
              onClick={() => {
                scan?.({
                  preview: false,
                });
                handleCloseContextMenu();
              }}
            >
              <FontAwesomeIcon icon="fas fa-file-upload" size="18px" />
              Index By Barcode
            </StyledMenuItem>
          )}
        </Box>
      )}
      {currentUser.role < 1 && state.screen !== 'reporting' && (
        <DeleteMenuItem onClick={handleDelete}>
          <FontAwesomeIcon icon="far fa-trash-alt" size="18px" />
          Delete Record/s
        </DeleteMenuItem>
      )}
      <StyledMenuItem
        onClick={() => {
          handleAddToCollection();
          handleCloseContextMenu();
        }}
      >
        <FontAwesomeIcon icon="fas fa-clipboard-list" size="18px" />
        Add To Collection Bin
      </StyledMenuItem>
      {state.screen !== 'reporting' && (
        <StyledMenuItem
          onClick={(e) => {
            props.openNotes ? props.handleCloseNotes() : props.handleOpenNotes();
            props.handleCloseContextMenu();
          }}
        >
          <FontAwesomeIcon icon="fa-sticky-note" size="18px" />
          Notes
        </StyledMenuItem>
      )}

      {state.screen === 'search' &&
        state.selectedFolder[state.screen].linked_Information_Mapping && (
          <StyledMenuItem
            onClick={(e) => {
              let ids = rows
                .filter((row) => {
                  return selectionModel.length
                    ? selectionModel.includes(row.id)
                    : selected.includes(row.id);
                })
                .map((row) => row.id);
              props.handleCloseContextMenu();
              handleReapplyLinkedInformations(ids);
            }}
          >
            <FontAwesomeIcon icon="fas fa-sync" size="18px" />
            Re-sync
          </StyledMenuItem>
        )}

      {state.workflow &&
        state.currentOrg &&
        state.currentOrg.processPathEnabled &&
        state.screen !== 'reporting' &&
        hasSetWorkflowPermissions(
          authState.permissions,
          currentUser,
          state.selectedFolder[state.screen],
          false
        ) && (
          <StyledMenuItem
            onClick={() => {
              setWorkflowModal(true);
              handleCloseContextMenu();
            }}
          >
            <FontAwesomeIcon icon="fa-project-diagram" size="18px" />
            Workflow
          </StyledMenuItem>
        )}
    </StyledMenu>
  );
}

export default ContextualMenu;
