import _ from 'lodash';
import {
  FolderField,
  SelectedFolder,
} from '../../components/masterTable/masterTableTypes';
import { StatusContextState } from '../../context/statusContextTypes';
import { Permissions } from '../user/userProfile/components/UserPermissionsTab/permissionsTypes';
import { Header, Record, UserData, Variables, WorkflowStatusOptions } from './types';
import { format } from 'date-fns';

export const generateSearchFilters = (
  values: any,
  isSearch: boolean,
  folder: SelectedFolder | null
) => {
  // function that transforms the received values into an array of filters to be sent to backend
  let filters: any[] = [];
  let usedFilters: any[] = [];
  const fieldBasedFilters: any[] = []; // folder fields
  const dateRangeFilters: any[] = []; // folder fields type date
  const staticFilters: any[] = []; // values coming from the date popup
  if (isSearch && folder && folder.folderFields && Object.keys(folder).length > 0) {
    let indexedDateRange: any = {};
    if (values && values.indexedFrom) {
      if (values.indexedFrom !== '') {
        indexedDateRange['from'] = values.indexedFrom;
        if (values.indexedTo === '') {
          let timeElapsed = new Date();
          timeElapsed.setHours(23);
          timeElapsed.setMinutes(59);
          timeElapsed.setSeconds(59);
          indexedDateRange['to'] = new Date(timeElapsed).toISOString().split('T')[0];
        }
      }
      if (values.indexedTo !== '') {
        indexedDateRange['to'] = values.indexedTo;
        if (values.indexedFrom === '') {
          indexedDateRange['from'] = new Date(0).toISOString().split('T')[0];
        }
      }
    }
    // Filter only the user filters
    for (let key in values) {
      if (values[key] && !key.startsWith('indexed')) {
        usedFilters.push(key);
      }
    }
    folder.folderFields.forEach((field) => {
      if (usedFilters.includes(field.id)) {
        let value = values[field.id];
        let searchType: string | false = false;
        if (field.type === 'dropdown') {
          value = value.value || value;
        }
        if (field.type === 'string' || 'integer' || 'currency') {
          if (value[value.length - 1] === '%') {
            let newValue = value;
            value = newValue.slice(0, newValue.length - 1);
            searchType = 'like';
          }
        }

        fieldBasedFilters.push({
          type: 'folderField',
          folderFieldId: field.id,
          folderFieldType: field.type,
          value,
          searchType: !searchType ? 'equals' : searchType,
        });
      }
    });
    const dateRanges: any = {};
    usedFilters.forEach((filter) => {
      const id = filter.split('-')[0];
      if (filter.endsWith('from')) {
        dateRanges[id] = {
          ...dateRanges[id],
          from: new Date(Date.parse(values[filter] + ' 00:00:00')).toISOString(),
        };
      } /* else {
        let date = new Date(0).toISOString();
        dateRanges[id] = {
          ...dateRanges[id],
          from: date,
        };
      } */
      if (filter.endsWith('to')) {
        dateRanges[id] = {
          ...dateRanges[id],
          // to: new Date(Date.parse(values[filter] + 'T23:59:00.000Z')).toISOString(),
          to: `${values[filter]}T23:59:00.000Z`,
        };
      } /* else {
        let date = new Date().toISOString();
        dateRanges[id] = {
          ...dateRanges[id],
          to: date,
        };
      } */
    });
    for (let id in dateRanges) {
      dateRangeFilters.push({
        type: 'folderField',
        folderFieldId: id,
        folderFieldType: 'datetime',
        dateRange: dateRanges[id],
      });
    }

    // Parse the static filters
    if (indexedDateRange.from || indexedDateRange.to) {
      let from = indexedDateRange.from || format(new Date(), 'yyyy-MM-dd');
      let to = indexedDateRange.to || format(new Date(), 'yyyy-MM-dd');
      staticFilters.push({
        type: 'static',
        dateRange: {
          from: new Date(Date.parse(from + ' 00:00')).toISOString(),
          to: `${to}T23:59:00.000Z`,
          // to: new Date(Date.parse(to + ' 23:59')).toISOString(),
        },
        fieldName: 'createdAt',
      });
    }
  }
  filters = [...fieldBasedFilters, ...dateRangeFilters, ...staticFilters];
  return filters;
};

export const generateRecordVariables = (
  folder: SelectedFolder,
  isSuser: boolean,
  state: StatusContextState,
  selOrg: any,
  filters: any[],
  limit: number,
  currentPage: number,
  sorting: any,
  folderHasWorkflowEnabled: boolean,
  processPath: string
) => {
  let variables: Variables = {
    folderId: folder.id,
    filters,
    limit: limit,
    currentPage: currentPage,
    sortByField: sorting,
  };
  if (isSuser) {
    variables['org'] =
      state.selectedOrg && _.isObject(state.selectedOrg)
        ? state.selectedOrg.id
        : selOrg.id;
  }

  if (folderHasWorkflowEnabled) {
    variables.processPath = processPath;
  }

  return variables;
};

export const openTab = (url: string) => window.open(url, '_blank');

export const checkIfFieldSaved = (
  id: string,
  values: any,
  folder: SelectedFolder,
  date = false
) => {
  //checks if there are values stored in each input
  if (values[folder.id]) {
    let fieldIDs = Object.keys(values[folder.id]);
    let valuesArr = Object.values(values[folder.id]);

    let result = fieldIDs.filter((searchFieldID) => {
      if (searchFieldID === id) {
        return valuesArr;
      } else {
        return false;
      }
    });
    if (result[0]) {
      if (date) {
        return values[folder.id][id];
      } else {
        return values[folder.id][result[0]];
      }
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const generateActiveFields = (fields: FolderField[]) => {
  return fields.filter((f) => f.active && f.name !== 'createdAt');
};

export const handleCloseSnack = (
  event: React.SyntheticEvent<Element, Event> | Event,
  setSnack: React.Dispatch<React.SetStateAction<boolean>>,
  reason?: string
) => {
  if (reason === 'clickaway') {
    return;
  }
  setSnack(false);
};

export const checkForDateRange = (
  index: string,
  selectedFolder: SelectedFolder | null,
  fieldValues: any
) => {
  if (!selectedFolder) return;
  let result;
  // when entering the folder, check if there are filters and generate the search
  if (
    selectedFolder &&
    fieldValues[selectedFolder.id] &&
    fieldValues[selectedFolder.id][index]
  ) {
    result = fieldValues[selectedFolder.id][index];
  } else {
    result = '';
  }
  return result;
};

export const checkCreatedAtField = (
  records: Record[],
  folder: SelectedFolder | null,
  setHeaders: React.Dispatch<React.SetStateAction<Header[]>>
) => {
  // When the page starts for the first time, this field is not reloaded
  if (records.length === 0 || !folder || Object.keys(folder).length === 0) return;
  let createdAt = folder.folderFields.filter((field) => field.name === 'createdAt');
  if (createdAt.length === 0) {
    let created = {
      __typename: 'FolderField',
      id: 'CreatedAtID',
      active: true,
      name: 'createdAt',
      type: 'datetime',
      isCreatedAt: 'true',
    };
    setHeaders((prevState) => [...prevState, created]);
  }
};

export const getHeaders = (
  folder: SelectedFolder | null,
  setHeaders: React.Dispatch<React.SetStateAction<Header[]>>
) => {
  let visibleHeaders: any;
  if (folder && folder.folderFields) {
    visibleHeaders = folder.folderFields.filter((field) => field.active);
    setHeaders([...visibleHeaders]);
  }
};

export const hasUnindexPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 1) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    if (folder && folder.folderPermissions) {
      return folder.folderPermissions.canRemoveIndex;
    } else {
      return defaultValue;
    }
  }
};
export const hasDeleteRecordPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  /* if (userData.role <= 0) {
    return true;
  } else  */ if (/* userData.role >= 1 && */ userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    console.log(folder);
    if (folder && folder?.folderPermissions) {
      return folder.folderPermissions.canDeleteRecord;
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};

export const hasDeleteNotesPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 1) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};
export const hasCollectionBinPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 4) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};

export const hasIndexByBarcodePermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 4) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};
export const hasIndexModifiedPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 4) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};
export const hasIndexByOCRPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 4) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};
export const hasExportPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 4) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};
export const hasUploadPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 4) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};
export const hasDocumentScannerPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 4) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};
export const hasQuickViewPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userData.role <= 4) {
    return true;
  } else if (userData.role === 2 && userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );
    return folder && folder.folderPermissions
      ? folder.folderPermissions.canDeleteNotes
      : defaultValue;
  } else {
    return defaultValue;
  }
};
export const hasEditIndexedRecordsPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );

    if (
      folder &&
      folder.folderPermissions &&
      folder.folderPermissions.browsePermissions
    ) {
      return folder.folderPermissions.browsePermissions.canEditIndexedRecords;
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};
export const hasSetWorkflowPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );

    if (folder && folder.folderPermissions) {
      return folder.folderPermissions.processPathPermissions?.canSetProcessPath;
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};
export const hasPerformWorkflowPermissions = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );

    if (folder && folder.folderPermissions) {
      return folder.folderPermissions.processPathPermissions?.canPerformProcessStep;
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};
export const hasRequireNoteWhenApproving = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );

    if (folder && folder.folderPermissions) {
      return folder.folderPermissions.processPathPermissions?.requireNoteWhenApproving;
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};
export const hasRequireNoteWhenRejecting = (
  userPermissions: Permissions | null,
  userData: UserData,
  selectedFolder: SelectedFolder | null,
  defaultValue: boolean
) => {
  if (!userData || !userPermissions || !selectedFolder) return defaultValue;
  if (userPermissions) {
    let folder = userPermissions.folderAccessDetails.find(
      (folder) => folder.folderID === selectedFolder.id
    );

    if (folder && folder.folderPermissions) {
      return folder.folderPermissions.processPathPermissions?.requireNoteWhenRejecting;
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};
export const checkWorkflowFilterPermissions = (
  folderPermissions: {
    canAccess: boolean;
    folderID: string;
    folderPermissions: any;
    fullAccess: boolean;
  },
  statuses: { value: string; label: string; permission: string }[],
  userSettings: any,
  workflowFilter: string | null
) => {
  if (!folderPermissions.folderPermissions) {
    return;
  }
  const calculateAvailableOptions = () => {
    let availableOptions = [];
    let permissions = folderPermissions.folderPermissions;
    for (let i = 0; i < statuses.length; i++) {
      const statusValue = statuses[i];
      if (statusValue.permission && permissions.processPathPermissions) {
        if (
          permissions.processPathPermissions &&
          permissions.processPathPermissions[statusValue.permission]
        ) {
          availableOptions.push(statusValue);
        }
      } else {
        availableOptions.push(statusValue);
      }
    }
    return availableOptions;
  };

  let availableOptions = calculateAvailableOptions();
  let includesWorkflowOption = false;
  let includesUserSettingsOption = false;
  for (let i = 0; i < availableOptions.length; i++) {
    const option = availableOptions[i];
    if (option.value === workflowFilter) {
      includesWorkflowOption = true;
    }
    if (option.value === userSettings.workflowFilter) {
      includesUserSettingsOption = true;
    }
  }
  if (workflowFilter) {
    if (includesWorkflowOption) {
      return workflowFilter;
    } else if (includesUserSettingsOption) {
      return userSettings?.workflowFilter;
    } else {
      return availableOptions[0]?.value || 'Any';
    }
  } else {
    if (includesUserSettingsOption) {
      return userSettings?.workflowFilter;
    } else {
      return availableOptions[0]?.value || 'Any';
    }
  }
};

export const calculateAvailableOptions = (
  folderPermissions: {
    canAccess: boolean;
    folderID: string;
    folderPermissions: any;
    fullAccess: boolean;
  },
  statuses: WorkflowStatusOptions[]
) => {
  let availableOptions = [];
  let permissions = folderPermissions.folderPermissions;
  for (let i = 0; i < statuses.length; i++) {
    const statusValue = statuses[i];
    if (statusValue.permission) {
      if (permissions.processPathPermissions[statusValue.permission]) {
        availableOptions.push(statusValue);
      }
    } else {
      availableOptions.push(statusValue);
    }
  }
  return availableOptions;
};
