import { useQuery } from '@apollo/react-hooks';
import Box from '@mui/material/Box';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { User } from '../../components/masterTable/masterTableTypes';
import WholePageMessage from '../../components/wholePageMessage';
import { Context as AuthContext, AuthContextTypes } from '../../context/authContext';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { GET_USERS, GET_USERS_BY_ORG } from '../../utils/gql/gqlUsers';
import utils from '../../utils/utils';
import UsersGrid from './UsersGrid';
import UsersToolbar from './UsersToolbar';

const UsersMain = () => {
  const { state, setScreen } = useContext(StatusContext) as StatusContextTypes;
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser: boolean = authContext.state.suser || suser;
  const { data, error, refetch, loading } = useQuery(
    isSuser ? GET_USERS_BY_ORG : GET_USERS,
    {
      variables: isSuser
        ? {
            organisationId:
              state.selectedOrg && _.isObject(state.selectedOrg)
                ? state.selectedOrg.id
                : selOrg.id,
          }
        : null,
      fetchPolicy: 'network-only',
    }
  );
  let users;

  if (data) {
    users = isSuser ? data.UsersByOrg.users : data.Users.users;
  }

  // Match users data field names for filtering
  const keys = ['firstName', 'lastName', 'role', 'email'];

  // This function filters through an array of objects and uses a keys array for object filtering
  // More fields names can be added to the keys array to filter through more fields
  const filterUsers = (data: User[]) => {
    if (!data || !searchQuery) return;
    return data.filter((item: any) =>
      keys.some((key) => item[key].toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  // If there is a searchQuery calls the filter function and asigns the result to users
  if (searchQuery) {
    users = filterUsers(users);
  }

  if (error) {
    utils.clearUserData();
  }

  useEffect(() => {
    setScreen('users');
  }, [data]);

  return (
    <Box sx={{ padding: '10px 10px' }}>
      <UsersToolbar
        refetch={refetch}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
      />

      {loading ? <WholePageMessage isLoading={loading} /> : <UsersGrid users={users} />}
    </Box>
  );
};

export default UsersMain;
