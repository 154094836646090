import React, { useEffect, useState, useContext } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';

// Mui
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

// context & hooks
import { AuthContextTypes, Context as AuthContext } from '../../context/authContext';
import { useLocalStorage, modifyLocalStorageObject } from '../../hooks/useLocalStorage';

// app utils
import {
  GET_FOLDER_BY_ID,
  GET_FOLDER_BY_ID_BY_ORG,
  UPDATE_FOLDER,
} from '../../utils/gql/gqlFolders';
import { UPDATE_FOLDER_FIELD } from '../../utils/gql/gqlFolders';
import utils from '../../utils/utils';

// app components
import FieldEditModal from './FolderFieldEditModal';
import GlobalOptionsTab from './GlobalOptionsTab';
import ToolbarBase from '../../components/toolbarScreen/ToolbarBase';
import ToolbarTitle from '../../components/toolbarScreen/ToolbarTitle';
import ToolbarButtonLeft from '../../components/toolbarScreen/ToolbarButtonLeft';
import TabPanel from '../../components/tabs/Tabpanel';
import FieldCreationForm from './FieldCreationForm';
import CSVImport from './subcomponents/CSVImport';
import FontAwesomeIcon from '../../components/FAIcon';
import LinkedInfo from './subcomponents/linkedInfo';
import GeneralSnackbar, { HandleClose, StatusProps } from '../../components/SnackBar';
import { Folder, FolderFields, UpdateFieldProps } from './types';
import OCRSetup from './subcomponents/OCRSetup';
import { FabricProvider } from '../../hooks/useFabricCanvas';
import WholePageMessage from '../../components/wholePageMessage';
import OCRSetupTab from './ocr/OCRSetupTab';
import { Tab, Tabs, Tooltip } from '@mui/material';
import { hasOCRSetupPermissions } from '../search/searchUtils';
type TParams = { id: string };

export interface GlobalOptionsState {
  active: boolean;
  scanning: boolean;
  autoAppendRecords: boolean;
  workFlow: boolean;
  ocrEnabled: boolean;
  enforceWorkflowNotes: boolean;
  apProcessingSettings?: {
    ocrTemplateNameFolderFieldId?: string;
  };
}

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: '20px',
  '& > .MuiBox-root': {
    height: '100%',
  },
}));

export const InitialFieldUpdates = {
  id: '',
  name: '',
  type: '',
  active: false,
  options: [],
  matchRequiredField: false,
  mainField: false,
  indexRequirements: null,
};

const TABS = [
  {
    label: 'FOLDER FIELDS',
    icon: 'fas fa-folder-open',
    value: 'Fields',
  },
  {
    label: 'GLOBAL OPTIONS',
    icon: 'fas fa-list-alt',
    value: 'Global',
  },
  {
    label: 'EXTERNAL DATA',
    icon: 'fas fa-file-upload',
    value: 'External',
  },
  {
    label: 'OCR Setup',
    icon: 'fas fa-tasks',
    value: 'OCR',
  },
  {
    label: 'PROCESS STEPS',
    icon: 'fas fa-tasks',
    value: 'Process',
  },
  {
    label: 'PERMISSIONS',
    icon: 'fas fa-lock',
    value: 'Permissions',
  },
];

function deleteTypename(obj: any, match: string) {
  if (obj === null || obj === undefined) return;
  obj[match] && delete obj[match];
  for (let v of Object.values(obj)) {
    if (typeof v === 'object') deleteTypename(v, match);
  }
  return obj;
}

const FolderPage = ({ match }: RouteComponentProps<TParams>) => {
  const [tabValue, setTabValue] = useState<string>('Fields'); // used to switch tabs
  const folderId = match.params.id;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const authState = authContext.state;
  const [userData] = useLocalStorage('userData');
  const { suser, selOrg } = userData;
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const isSuser = authContext.state.suser || suser;
  const [currentFolder, setCurrentFolder] = useState<Folder | null>(null);
  const [fields, setFields] = useState<FolderFields[]>([]);
  const [folderName, setFolderName] = useState<string | null>(
    currentFolder && currentFolder.name
  );
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);
  const [fieldUpdates, setFieldUpdates] = useState({});
  const [selectedField, setSelectedField] = useState<FolderFields | null>(null);
  const [editFieldModalOpen, setEditFieldModalOpen] = useState<boolean>(false);
  const [folderGlobalOptions, setFolderGlobalOptions] = useState<GlobalOptionsState>({
    active: currentFolder?.active || false,
    scanning: currentFolder?.scanning || false,
    autoAppendRecords: currentFolder?.autoAppendRecords || false,
    workFlow: currentFolder?.workFlow || false,
    ocrEnabled: currentFolder?.ocrEnabled || false,
    enforceWorkflowNotes: false,
    apProcessingSettings: deleteTypename(
      currentFolder?.apProcessingSettings,
      '__typename'
    ),
  });

  const { error, refetch, loading } = useQuery(
    isSuser ? GET_FOLDER_BY_ID_BY_ORG : GET_FOLDER_BY_ID,
    {
      fetchPolicy: 'network-only',
      variables: isSuser ? { id: folderId, organisationId: selOrg.id } : { id: folderId },
      onCompleted: (data) => {
        if (data) {
          const { FolderByOrg, Folder } = data;
          const folder = isSuser ? FolderByOrg : Folder.folder;
          const folderFields = folder?.folderFields;
          setCurrentFolder({ ...folder });
          setFields(folderFields);
          setFolderGlobalOptions({
            active: folder?.active || false,
            scanning: folder?.scanning || false,
            autoAppendRecords: folder?.autoAppendRecords || false,
            workFlow: folder?.workFlow || false,
            ocrEnabled: folder?.ocrEnabled || false,
            enforceWorkflowNotes: false,
            apProcessingSettings: folder.apProcessingSettings,
          });
        }
      },
    }
  );
  const [updateFolderField] = useMutation(UPDATE_FOLDER_FIELD);
  const [updateFolder] = useMutation(UPDATE_FOLDER);
  const [snackbar, setSnackbar] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'warning',
  });
  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };

  const handleEditGlobalOptions = (key: string, value: boolean | string) => {
    setFolderGlobalOptions((prev) => ({ ...prev, [key]: value }));
  };

  const updateField = (updates: UpdateFieldProps) => {
    let variables = { ...updates };
    return updateFolderField({ variables })
      .then((data) => {
        setSnackbar({
          status: true,
          snackbarMessage: 'Folder field successfully updated',
          severity: 'success',
        });
        refetch();
        let folderField = data.data.updateFolderField.folderField;
        setFields((prev) =>
          prev.map((ff) => (ff.id === folderField.id ? folderField : ff))
        );
        return Promise.resolve();
      })
      .catch((e) => {
        console.error('Could not update folder field: ', e);
        setSnackbar({
          status: true,
          snackbarMessage: 'Could not update folder field',
          severity: 'error',
        });
      })
      .finally(() => setFieldUpdates({}));
  };

  const handleFolderUpdate = () => {
    if (!currentFolder) return;
    if (folderName !== currentFolder.name) {
      updateFolder({
        variables: {
          folderId: currentFolder.id,
          name: folderName,
        },
      })
        .then((data) => {
          setSnackbar({
            status: true,
            snackbarMessage: 'Folder successfully updated',
            severity: 'success',
          });
          refetch();
        })
        .catch((e) => {
          console.error('Could not update folder name: ', e);
          setSnackbar({
            status: true,
            snackbarMessage: 'Could not update folder',
            severity: 'error',
          });
        });
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleFolderOptionsUpdate = () => {
    if (!currentFolder) return;
    updateFolder({
      variables: {
        folderId: currentFolder.id,
        ...folderGlobalOptions,
      },
    }).catch((e) => {
      console.error('Could not update folder name: ', e);
    });
  };

  if (error) utils.clearUserData();

  useEffect(() => {
    if (!currentFolder) return;
    setFolderName(currentFolder && currentFolder.name);
  }, [currentFolder, selectedField]);

  useEffect(() => {
    if (!currentFolder || !userSettings.lastRecordsFolder) return;
    if (currentFolder.id === userSettings.lastRecordsFolder.id) {
      setUserSettings(
        modifyLocalStorageObject(
          {
            ...userSettings,
            lastRecordsFolder: currentFolder.id,
            lastIndexedFolder: { ...fields },
          },
          'userSettings'
        )
      );
    }
  }, [currentFolder]);

  useEffect(() => {
    handleFolderOptionsUpdate();
  }, [folderGlobalOptions]);

  useEffect(() => {
    if (selectedFieldId) setFieldUpdates({});
  }, [selectedFieldId]);

  if (loading) {
    return <WholePageMessage isLoading={loading} />;
  }
  console.log('user permissions: ', authState.permissions);
  return currentFolder ? (
    <>
      <FieldEditModal
        editFieldModalOpen={editFieldModalOpen}
        setEditFieldModalOpen={setEditFieldModalOpen}
        setSelectedFieldId={setSelectedFieldId}
        fieldUpdates={fieldUpdates}
        setSelectedField={setSelectedField}
        setFieldUpdates={setFieldUpdates}
        selectedField={selectedField}
        updateField={updateField}
        selectedFieldId={selectedFieldId}
        folder={currentFolder}
        updateFolder={updateFolder}
        refetch={refetch}
      />
      <ToolbarBase hasBackground={true}>
        <Link style={{ textDecoration: 'none' }} to="/dash/folders">
          <ToolbarButtonLeft>
            <FontAwesomeIcon icon="fas fa-angle-left" />
          </ToolbarButtonLeft>
        </Link>
        <ToolbarTitle title={`Folder - ${currentFolder.name}`} />
      </ToolbarBase>
      <Card sx={{ marginTop: '20px', height: '85vh' }}>
        <CardContent
          sx={{
            height: '100%',
            padding: 0,
            display: { md: 'flex' },
            '&:last-child': {
              paddingBottom: 0,
            },
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            orientation="vertical"
            indicatorColor="secondary"
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
            sx={{
              height: '100%',
              minWidth: '50px',
              backgroundColor: 'common.greyblueDarker',
              '& .Mui-selected::before': {
                color: 'common.orange',
                content: '""',
                position: 'absolute',
                bottom: '50%',
                right: 3,
                transform: 'rotate(90deg)',
                width: 0,
                height: 0,
                borderLeft: '9px solid transparent',
                borderRight: '9px solid transparent',
                borderTop: '9px solid',
                borderTopColor: 'common.orange',
              },
            }}
          >
            {TABS.map(({ label, icon, value }, index) => {
              if (
                (label === 'OCR Setup' && !folderGlobalOptions?.ocrEnabled) ||
                (label === 'OCR Setup' && !authState.permissions?.ocrTemplateCreator)
              )
                return null;

              return (
                <Tab
                  key={label}
                  value={value}
                  disabled={label === 'PROCESS STEPS' || label === 'PERMISSIONS'}
                  sx={{
                    height: '80px',
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? 'common.greyblueDarker'
                        : 'common.greyblueDarker',
                  }}
                  label={
                    <Tooltip title={label}>
                      <div>
                        <FontAwesomeIcon
                          icon={icon}
                          color={
                            label === 'PROCESS STEPS' || label === 'PERMISSIONS'
                              ? 'common.greyblueDark'
                              : tabValue === value
                              ? 'common.orange'
                              : 'common.white'
                          }
                          size={20}
                        />
                      </div>
                    </Tooltip>
                  }
                />
              );
            })}
          </Tabs>
          <StyledTabPanel value={tabValue} index={'Fields'}>
            <FieldCreationForm
              folderDetail={currentFolder}
              refetch={refetch}
              fields={fields}
              updateField={updateField}
              fieldUpdates={fieldUpdates}
              setFieldUpdates={setFieldUpdates}
              setSelectedFieldId={setSelectedFieldId}
              setSelectedField={setSelectedField}
              selectedField={selectedField}
              selectedFieldId={selectedFieldId}
              setFields={setFields}
              updateFolder={updateFolder}
            />
          </StyledTabPanel>
          <StyledTabPanel value={tabValue} index={'Global'}>
            <GlobalOptionsTab
              folderName={folderName}
              folder={currentFolder}
              setFolderName={setFolderName}
              handleFolderUpdate={handleFolderUpdate}
              handleEditGlobalOptions={handleEditGlobalOptions}
              folderGlobalOptions={folderGlobalOptions}
            />
          </StyledTabPanel>
          <StyledTabPanel
            value={tabValue}
            index={'External'}
            sx={{
              paddingBottom: '10rem',
              '& .css-5ph6si': {
                height: { xs: '80%', md: '100%' },
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'flex-start',
                height: { xs: '80%', sm: '100%', md: '110%' },
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              }}
            >
              <Box sx={{ flex: 1 }}>
                <LinkedInfo
                  folder={currentFolder}
                  fields={fields}
                  setSnackbar={setSnackbar}
                  refetch={refetch}
                  setCurrentFolder={setCurrentFolder}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                {currentFolder?.linked_Information_Mapping ? (
                  <CSVImport
                    folder={currentFolder}
                    mapping={currentFolder?.linked_Information_Mapping}
                  />
                ) : null}
              </Box>
            </Box>
          </StyledTabPanel>
          {/* <StyledTabPanel value={tabValue} index={'OCR'}>
            <FabricProvider>
              <OCRSetup folder={currentFolder} updateField={updateField} />
            </FabricProvider>
          </StyledTabPanel> */}
          <StyledTabPanel
            value={tabValue}
            index={'OCR'}
            sx={{ padding: '0px!important' }}
          >
            <OCRSetupTab
              folder={currentFolder}
              handleEditGlobalOptions={handleEditGlobalOptions}
              folderGlobalOptions={folderGlobalOptions}
              setSnackbar={setSnackbar}
              refetch={refetch}
              setCurrentFolder={setCurrentFolder}
            />
          </StyledTabPanel>
        </CardContent>
      </Card>
      <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
    </>
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="button">No Folder Found</Typography>
    </Box>
  );
};

export default FolderPage;
