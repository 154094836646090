import { Box, CardContent, styled, Switch, Tab, Tabs } from '@mui/material';

export const UISwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2216px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23ffffff%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M12%207V3H2v18h20V7H12zM6%2019H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4%2012H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10%2012h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0%204h-2v2h2v-2z%22%2F%3E%3C%2Fsvg%3E')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    // backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    backgroundColor: theme.palette.common.greyblueDark,
    width: 24,
    height: 24,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2216px%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M16%2011c1.66%200%202.99-1.34%202.99-3S17.66%205%2016%205c-1.66%200-3%201.34-3%203s1.34%203%203%203zm-8%200c1.66%200%202.99-1.34%202.99-3S9.66%205%208%205C6.34%205%205%206.34%205%208s1.34%203%203%203zm0%202c-2.33%200-7%201.17-7%203.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8%200c-.29%200-.62.02-.97.05%201.16.84%201.97%201.97%201.97%203.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z%22%2F%3E%3C%2Fsvg%3E')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, width: '100%', height: { xs: '90%', md: '100%', lg: '100%' } }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.common.greyblueDarker,
  border: 'none',
  transition: '.3s',
  minWidth: '180px',
  '& .MuiTabs-flexContainer': {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
  },
  '& .Mui-selected': {
    [theme.breakpoints.up('md')]: {
      borderRight: '2px solid',
    },
    [theme.breakpoints.down('md')]: {
      borderBottom: '2px solid',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  backgroundColor: theme.palette.common.greyblueDarker,
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    justifyContent: 'start',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  transition: '0.3s',
  '& .MuiIcon-root': {
    marginRight: '1rem',
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  '& .MuiTab-label': {
    display: 'none',
  },
  '&.Mui-selected': {
    color: theme.palette.common.orange,
  },
  '& .MuiCardHeader-content': {
    display: 'none',
  },
}));

export const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: '80vh',
  padding: 0,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  '&:last-child': {
    paddingBottom: 0,
  },
}));
