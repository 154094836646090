import React from "react";
import PropTypes from "prop-types";
import { Box, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 10,
  },
});

export default function LinearProgressBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{display: props.open ? "block" : "none"}}>
      <LinearProgress />
    </div>
  );
}
