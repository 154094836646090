import { gql } from 'apollo-boost';
export async function EXPORT_LINKED(variables: any) {
  return JSON.stringify({
    operationName: 'Linked_Informations',
    variables: variables,
    query:
      'query Linked_Informations($folderId: ID, $orgId: ID, $limit: Int, $currentPage: Int, $sortBy: String, $synced: Boolean) {\n Linked_Informations(folderId: $folderId, orgId: $orgId, limit: $limit, currentPage: $currentPage, sortBy: $sortBy, synced: $synced) {\n linked_Informations {\n id\n primaryLinkValue {\n name\n value\n fieldId\n fieldType\n __typename\n }\n linkedValues {\n name\n value\n fieldId\n fieldType\n __typename\n }\n synced\n __typename\n }\n error\n totalCount\n __typename\n }\n}\n',
  });
}
export const LINKED_INFORMATIONS = gql`
  query Linked_Informations(
    $folderId: ID!
    $orgId: ID
    $limit: Int
    $currentPage: Int
    $sortBy: String
    $synced: Boolean
  ) {
    Linked_Informations(
      folderId: $folderId
      orgId: $orgId
      limit: $limit
      currentPage: $currentPage
      sortBy: $sortBy
      synced: $synced
    ) {
      linked_Informations {
        id
        primaryLinkValue {
          name
          value
          fieldId
          fieldType
        }
        linkedValues {
          name
          value
          fieldId
          fieldType
        }
        synced
      }
      error
      totalCount
    }
  }
`;
export const ADDLINKED_INFORMATION = gql`
  mutation AddLinked_Information(
    $data: [Linked_Information_Input]
    $folderId: ID!
    $orgId: ID
  ) {
    AddLinked_Information(data: $data, folderId: $folderId, orgId: $orgId) {
      linked_Informations {
        id
        primaryLinkValue {
          name
          value
          fieldId
          fieldType
        }
        linkedValues {
          name
          value
          fieldId
          fieldType
        }
        synced
      }
    }
  }
`;

export const REAPPLY_LINKED_INFORMATIONS = gql`
  mutation ReApplyLinked_Informations($ids: [ID], $folderId: ID) {
    ReApplyLinked_Informations(ids: $ids, folderId: $folderId)
  }
`;
