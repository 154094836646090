import { FormControl, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useUsers from '../../../hooks/useUsers';
import { StyledCheckbox } from '../../../components/Checkbox/StyledCheckbox';
import { Filter } from '../ReportingSideBar';
import { useFormContext } from 'react-hook-form';

interface WorkflowStatusChangedByFilterProps {
  filter: Filter;
}

const styles = {
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: '5px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'common.greyblueDarker',
      borderRadius: '4px',
    },
  },
};
const WorkflowStatusChangedByFilter = ({
  filter,
}: WorkflowStatusChangedByFilterProps) => {
  const { users } = useUsers();
  const { setValue: setFormValue, watch } = useFormContext();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked, id },
    } = event;
    checked
      ? setSelectedUsers((prev) => [...prev, id])
      : setSelectedUsers((prev) => prev.filter((userId) => userId !== id));
  };

  useEffect(() => {
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex(
      (filter) => filter.id === 'workflow-status-changed-by'
    );
    index !== -1 &&
      setFormValue(`selectedTemplate.variables.filters[${index}].value`, selectedUsers);
  }, [selectedUsers]);
  return (
    <FormControl
      sx={{ width: '100%', maxHeight: '110px', overflowY: 'scroll', ...styles.scrollbar }}
    >
      {users &&
        users.map((user) => {
          let userIsSelected = selectedUsers.includes(user.id);
          return (
            <FormControlLabel
              key={user.id}
              control={
                <StyledCheckbox
                  id={user.id}
                  checked={userIsSelected}
                  onChange={handleChange}
                  name={user.firstName + user.lastName}
                  sx={{ transform: 'scale(0.6)', p: 0 }}
                />
              }
              label={user.firstName + ' ' + user.lastName}
              sx={{
                paddingTop: 2,
                marginLeft: 0,
                paddingLeft: 1,
                height: '20px',
                '& .MuiFormControlLabel-label': {
                  fontSize: 11,
                  color: userIsSelected ? 'common.blue' : 'common.greyblueDarker',
                  fontWeight: 500,
                  marginTop: '1px',
                  textTransform: 'capitalize',
                },
              }}
            />
          );
        })}
    </FormControl>
  );
};

export default WorkflowStatusChangedByFilter;
