import { LoadingButton } from '@mui/lab';
import { Button as MuiButton, ButtonProps, styled } from '@mui/material';

export interface StyledButtonProps extends ButtonProps {
  error?: 'false' | 'true';
  success?: 'false' | 'true';
  loading?: boolean;
}

const StyledButton = styled(MuiButton)<StyledButtonProps>(
  ({ theme, color = 'primary', variant = 'contained', error, success }) => {
    let greyblueDarker = theme.palette.common.greyblueDarker;
    let greyblueDark: string = theme.palette.common.greyblueDark;
    let greyblue: string = theme.palette.common.greyblue;
    let white = theme.palette.common.white;
    let lightblue: string = theme.palette.common.lightblue;
    let negative = theme.palette.common.negative;
    let negativeDark: string = theme.palette.common.negativeDark;
    let positive: string = theme.palette.common.positive;
    let positiveDark = theme.palette.common.positiveDark;
    let blue = theme.palette.common.blue;

    return {
      fontWeight: 600,
      fontSize: 12,
      ...(color === 'primary' &&
        variant === 'contained' && {
          color: white,
          backgroundColor: greyblueDarker,
          '&:hover': {
            color: white,
            backgroundColor: greyblueDark,
          },
        }),
      ...(color === 'secondary' &&
        variant === 'contained' && {
          color: greyblueDark,
          backgroundColor: lightblue,
          '&:hover': {
            color: white,
            backgroundColor: greyblue,
          },
        }),
      ...(color === 'primary' && variant === 'text' && { color: greyblueDark }),
      ...(color === 'secondary' && variant === 'text' && { color: lightblue }),
      ...(color === 'primary' &&
        variant === 'outlined' && {
          color: greyblueDark,
          borderColor: greyblueDark,
        }),
      ...(color === 'secondary' &&
        variant === 'outlined' && {
          color: white,
          borderColor: white,
          '&:hover': {
            borderColor: lightblue,
            backgroundColor: lightblue,
            color: greyblueDark,
          },
        }),
      ...(color === 'inherit' && {
        color: variant === 'contained' ? white : blue,
        backgroundColor: variant === 'contained' ? blue : '',
        '&:hover': {
          backgroundColor: blue,
          color: white,
        },
      }),
      ...(error === 'true' && {
        color: white,
        backgroundColor: negative,
        '&:hover': { backgroundColor: negativeDark },
      }),
      ...(success === 'true' && {
        color: white,
        backgroundColor: positive,
        '&:hover': { backgroundColor: positiveDark },
      }),
    };
  }
) as typeof MuiButton;

const Button = ({ children, loading, ...rest }: StyledButtonProps) => {
  if (loading)
    return (
      <LoadingButton loading {...rest}>
        {children}
      </LoadingButton>
    );
  return <StyledButton {...rest}>{children}</StyledButton>;
};

export default Button;
