import { Box } from '@mui/material';
import FontAwesomeIcon from '../../FAIcon';
import { getBGColor } from '../NotesForm';
import { useTheme } from '@mui/material/styles';
interface Props {
  preview?: boolean;
  priority: string;
  backgroundColor?: string;
}

const Corner = ({
  preview = false,
  priority = 'MEDIUM',
  backgroundColor = undefined,
}: Props) => {
  const theme = useTheme();
  let colorDarker = getBGColor(priority, theme.palette.mode)?.slice(0, -2);
  return (
    <Box
      sx={{
        position: preview ? 'relative' : 'absolute',
        right: '-1px',
        bottom: preview ? '-48px' : '-57px',
      }}
    >
      <Box sx={{ position: 'absolute', bottom: 47, right: 0 }}>
        <FontAwesomeIcon
          icon="far fa-square"
          color={
            backgroundColor
              ? backgroundColor
              : theme.palette.mode === 'dark'
              ? 'common.greyblueDarker'
              : 'common.white'
          }
          size={16}
          padding={0}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 51,
          right: 4,
          transform: 'rotate(135deg)',
        }}
      >
        <FontAwesomeIcon
          icon="far fa-caret-down"
          color={colorDarker}
          size={24}
          padding={0}
        />
      </Box>
    </Box>
  );
};

export default Corner;
