import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Input,
  InputBase,
  FormHelperText,
  styled,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';

import { SubmitHandler } from 'react-hook-form';
import { ADD_NOTE_TO_RECORD } from '../../../utils/gql/gqlNotes';
import FontAwesomeIcon from '../../FAIcon';
import Corner from './Corner';
import { AddNotesProps, FormValues, NotesFormProps } from '../notesTypes';

const styles = {
  noteInput: {
    border: 'none',
    height: 'auto',
    padding: 2,
    marginBottom: 1,
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'common.white',
  },
  helperText: {
    display: 'inline',
    position: 'relative',
    right: '-360px',
    bottom: '28px',
    fontSize: 10,
  },
  priorityBox: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    color: 'common.white',
  },
};

export const getBGColor = (priority: string) => {
  switch (priority) {
    // case 'Low':
    // return 'rgb(232, 245, 255)';
    // return '#f2f2f289';
    case 'Normal':
      return '#FDF5DA';
    case 'High':
      return '#FEDCCF';
    default:
      break;
  }
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.common.white,
    position: 'relative',
    border: 'none',
    borderBottom: '1px solid #fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const AltForm = ({
  submitButtonRef,
  register,
  handleSubmit,
  errors,
  resetField,
  clearErrors,
  setShowNotesList,
  reset,
  notesRefetch,
  record,
  setNotes,
  setMessage,
  setSnackOpen,
  setFocus,
}: NotesFormProps) => {
  const [content, setContent] = useState('');
  const [priority, setPriority] = useState<string>('Normal');

  const handleChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value);
  };

  const [addNoteToRecord] = useMutation(ADD_NOTE_TO_RECORD);

  const addNote = ({ content, priority, recordId }: AddNotesProps) => {
    return addNoteToRecord({
      variables: {
        recordId,
        documentNote: {
          content,
          priority,
        },
      },
    })
      .then((res) => {
        // the reverse method is used to make the last note come first
        let newNotes = res.data.addDocumentNoteToRecord.record.documentNotes.reverse();
        setNotes(newNotes);
        setShowNotesList(true);
      })
      .catch((error) => {
        setMessage({
          status: true,
          message: 'Note could not be added',
          severity: 'error',
        });
        setSnackOpen(true);
      });
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const newNote = {
      content: data.content,
      priority,
      recordId: record.id,
    };
    addNote(newNote);
    notesRefetch();
    resetField('content');
  };

  useEffect(() => {
    // when the component is rendered, the focus must be on content textfield
    setFocus('content');
  }, [setFocus]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
        backgroundColor: 'common.greyblueDarker',
        borderRadius: '5px',
        padding: '20px 35px',
        borderTop: (theme) =>
          theme.palette.mode === 'dark' ? '1px solid white' : 'none',
      }}
    >
      <Box sx={styles.titleBox}>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: 16,
            color: 'common.white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          Add a note
        </Typography>

        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          {errors && errors.content && (
            <Typography
              variant="caption"
              sx={{ fontSize: 10, color: 'red', margin: 0, width: '100%', flex: 2 }}
            >
              {errors.content.message}
            </Typography>
          )}
          <Box sx={styles.priorityBox}>
            <Typography variant="subtitle1" sx={{ fontSize: 14, color: 'common.white' }}>
              Priority:
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
              <Select
                value={priority}
                onChange={handleChange}
                size="small"
                MenuProps={{ style: { zIndex: 999999, position: 'absolute' } }}
                sx={{
                  '& .MuiSelect-icon': {
                    color: 'common.white',
                  },
                }}
                input={<BootstrapInput />}
              >
                <MenuItem value={'Normal'}>Medium</MenuItem>
                <MenuItem value={'High'}>High</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('content', {
            required: {
              value: true,
              message: 'This note cannot be added empty',
            },
            maxLength: {
              value: 500,
              message: 'This note cannot be longer than 500 characters',
            },
            validate: {
              isNotEmpty: (string: string) =>
                (typeof string === 'string' && string.trim() !== '') ||
                'This note cannot be added empty',
            },
          })}
          onChange={(e) => {
            clearErrors('content');
            setContent(e.target.value);
          }}
          placeholder="Note"
          multiline
          fullWidth
          inputProps={{
            sx: {
              height: 'auto',
              maxHeight: '400px',
              minHeight: '100px',
              color: 'common.greyblueDarker',
            },
          }}
          disableUnderline={true}
          error={errors.content ? true : false}
          sx={{ ...styles.noteInput, backgroundColor: getBGColor(priority) }}
        />
        <Corner
          preview={true}
          priority={priority}
          backgroundColor="common.greyblueDarker"
        />
        <FormHelperText
          sx={{ ...styles.helperText, color: content.length > 500 ? 'red' : '#27070786' }}
        >
          {content.length}
        </FormHelperText>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            aria-label={'add notes'}
            variant="greyblueDark"
            type="submit"
            startIcon={
              <FontAwesomeIcon
                icon="fas fa-check-circle"
                size="12px"
                color="common.green"
              />
            }
          >
            Add a note
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AltForm;
