import React, { useState, useEffect } from 'react';
import { Typography, TextField, Box, Tooltip, IconButton, Popover } from '@mui/material';
import FontAwesomeIcon from '../../components/FAIcon';
import { checkForDateRange } from './searchUtils';
import { FieldValues, FilterPanelToolBarProps } from './types';

const styles = {
  setDates: {
    fontSize: '12px',
    backgroundColor: 'common.greyblueDark',
    '&:hover': {
      backgroundColor: 'common.lightblue',
      color: 'common.greyblueDarker',
    },
    marginRight: 1,
    fontWeight: 400,
  },
  textfield: {
    '& .MuiInputBase-input': {
      backgroundColor: (theme) =>
        theme.palette.mode === 'dark' ? 'common.greyblueDark' : '#79909f',
      color: '#fff',
      borderRadius: '4px',
    },
    '&.Mui-focused': {
      boxShadow: `blue 0 0 0 2px`,
      borderColor: 'rgb(232, 245, 255)',
    },
    '& label.Mui-focused': {
      color: '#fff',
      fontWeight: 700,
      fontSize: 16,
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        borderColor: 'rgb(232, 245, 255)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(232, 245, 255)',
      },
    },
    '& .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: 2,
    },
    '.MuiInputLabel-root': {
      color: '#fff',
    },
  },
};

const FilterPanelToolBar = ({
  selectedFolder,
  clearSearch,
  reset,
  register,
  resetField,
  onSubmit,
  handleSubmit,
  setResetted,
  setIsFiltered,
  isFiltered,
  setFieldValues,
  fieldValues,
}: FilterPanelToolBarProps) => {
  const [indexedDateOpen, setIndexedDateOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] =
    React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [indexedFrom, setIndexedFrom] = useState<string>(
    checkForDateRange('indexedFrom', selectedFolder, fieldValues)
  );
  const [indexedTo, setIndexedTo] = useState<string>(
    checkForDateRange('indexedTo', selectedFolder, fieldValues)
  );

  const handleClickDatesPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleCloseDatesPopover = () => setAnchorEl(null);

  const handleSetDates = () => {
    setResetted(false);
    setIndexedDateOpen(false);
    setIsFiltered({ indexedFrom, indexedTo });
    handleCloseDatesPopover();
  };

  const handleClearIndexedDates = () => {
    if (!selectedFolder) return;
    setIndexedTo('');
    setIndexedFrom('');
    setResetted(true);
    setIsFiltered(false);

    setFieldValues((prevState: any) => {
      delete prevState[selectedFolder.id]?.indexedFrom;
      delete prevState[selectedFolder.id]?.indexedTo;
      return prevState;
    });
    resetField(`indexedTo`);
    resetField(`indexedFrom`);
  };

  const handleChangeDates = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: string
  ) => {
    if (!selectedFolder) return;
    index === 'indexedTo' ? setIndexedTo(e.target.value) : setIndexedFrom(e.target.value);
    setFieldValues((prevState: any) => {
      return {
        ...prevState,
        [selectedFolder.id]: {
          ...prevState[selectedFolder.id],
          [index]: e.target.value,
        },
      };
    });
  };

  useEffect(() => {
    let to = checkForDateRange('indexedTo', selectedFolder, fieldValues);
    let from = checkForDateRange('indexedFrom', selectedFolder, fieldValues);
    setIsFiltered(false);
    if (to || from) {
      setIsFiltered({ indexedFrom: from, indexedTo: to });
      setIndexedFrom(from);
      setIndexedTo(to);
    }
  }, [selectedFolder]);

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: 'auto',
        flexDirection: 'row',
      }}
    >
      <Box>
        <Tooltip title="Clear filters">
          <IconButton
            sx={{
              borderRadius: 0,
              backgroundColor: 'common.greyblueDarker',
              padding: '15px 10px',
              color: 'common.white',
              ':hover ': {
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.blue',
              },
            }}
            aria-label="Clear filters"
            disabled={!selectedFolder}
            onClick={() => {
              handleClearIndexedDates();
              clearSearch();
              setResetted(true);
              reset();
              setIsFiltered(false);
            }}
          >
            <FontAwesomeIcon icon="fas fa-undo-alt" size="14px"></FontAwesomeIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip
          title={
            isFiltered
              ? `Filtered from ${indexedFrom} to ${indexedTo}`
              : 'Filter by indexed date'
          }
        >
          <IconButton
            sx={{
              borderRadius: 0,
              backgroundColor: isFiltered ? 'common.orange' : 'common.greyblueDark',
              padding: '15px 10px',
              color: 'common.white',
              ':hover ': {
                backgroundColor: (theme) =>
                  isFiltered ? 'common.orange' : theme.palette.common.blueShadow['600'],
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.blue',
              },
            }}
            aria-label="Filter by indexed dates"
            disabled={!selectedFolder}
            onClick={handleClickDatesPopover}
          >
            <FontAwesomeIcon icon="far fa-calendar-alt" size="14px"></FontAwesomeIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: -5,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleCloseDatesPopover}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            background: 'none',
            // padding: '10px 10px 0',
          },
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit((data: FieldValues) => {
            onSubmit(data);
          })}
        >
          <Box
            sx={{
              padding: 2,
              width: '250px',
              // boxShadow: '2px 5px 20px -4px #0000002900',
              backgroundColor: 'common.greyblueDarker',
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="body1"
              noWrap
              style={{
                color: 'white',
                textAlign: 'center',
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 18,
              }}
            >
              Select the dates to filter the results.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                {...register('indexedFrom')}
                fullWidth
                disabled={!selectedFolder}
                label="From"
                id="indexed-from"
                type="date"
                variant="outlined"
                value={checkForDateRange('indexedFrom', selectedFolder, fieldValues)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChangeDates(e, 'indexedFrom')}
                sx={styles.textfield}
              />
              <TextField
                {...register('indexedTo')}
                fullWidth
                disabled={!selectedFolder}
                label="To"
                id="indexed-to"
                type="date"
                value={checkForDateRange('indexedTo', selectedFolder, fieldValues)}
                variant="outlined"
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChangeDates(e, 'indexedTo')}
                sx={styles.textfield}
              />
            </Box>
            <Box
              sx={{
                padding: 1,
                paddingBottom: 0,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '4px',
              }}
            >
              {/* <Button

                type="submit"
                size="small"
                variant="contained"
                disabled={!selectedFolder}
                onClick={() => {
                  setResetted(false);
                  setIndexedDateOpen(false);
                }}
                sx={styles.setDates}
              >
                Ok
              </Button> */}
              <Tooltip title="Set dates">
                <IconButton
                  size="small"
                  type="submit"
                  aria-label="Set Dates"
                  disabled={
                    !selectedFolder || !fieldValues[selectedFolder.id]?.indexedFrom
                  }
                  onClick={() => {
                    handleSetDates();
                  }}
                  sx={{
                    color: 'white.main',
                    '&:hover': {
                      color: 'common.positive',
                      backgroundColor: 'common.white',
                      borderColor: '#0062cc',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <FontAwesomeIcon icon="fas fa-check" size={15}></FontAwesomeIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Reset dates">
                <IconButton
                  size="small"
                  aria-label="Clear indexed filters"
                  disabled={!selectedFolder || !isFiltered}
                  onClick={() => {
                    handleClearIndexedDates();
                    clearSearch('indexed');
                    setResetted(true);
                    reset();
                  }}
                  sx={{
                    color: 'white.main',
                    '&:hover': {
                      color: 'common.negative',
                      backgroundColor: 'common.white',
                      borderColor: '#0062cc',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <FontAwesomeIcon icon="fas fa-undo-alt" size={15}></FontAwesomeIcon>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{
              position: 'relative',
              width: '2px',
              margin: 'auto',
              border: '10px solid',
              borderColor: 'common.greyblueDarker',
              borderRightColor: 'transparent',
              borderLeftColor: 'transparent',
              borderBottomColor: 'transparent',
              left: { sm: '45%', md: '45%', lg: '0%' },
            }}
          ></Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default FilterPanelToolBar;
