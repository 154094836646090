import { GridEditRowsModel } from '@mui/x-data-grid';
import {
  CopiedRows,
  Field,
  FolderField,
  Row,
  SelectedFolder,
} from '../../components/masterTable/masterTableTypes';
import { FieldsToSaveMap, FieldValue, ProcessingOrder } from './indexingTypes';

export function checkIDBForExistingValues(records: Row[], dataDB: any[]) {
  //List of not allowed props coming in the response of the ocr preview
  //This will be ignored and only the floatValue, dateValue, etc will be considered value props
  let notAllowedProps = ['id', 'currencyString', 'foundOnPageIndex'];
  let newRecords = records.map((record) => {
    dataDB.forEach((data) => {
      if (data && data.recordID === record.id) {
        // Iterates over the fields in DB and loaded records to compare
        //If there is any value in the field of this record, it is added to value key.
        record.fields.forEach((fields: Field) => {
          for (let field in data.fields) {
            if (field === fields.field.id) {
              let valueType = '';
              for (let typeOfField in data.fields[field]) {
                if (!notAllowedProps.includes(typeOfField)) {
                  return (fields.value = data.fields[field][typeOfField]);
                }
              }
            }
          }
        });
        /* let fieldsDB: any[] = Object.entries(data.fields);
        record.fields.forEach((fields: Field) => {
          fieldsDB.forEach((fieldDB) => {
            if (fieldDB[0] === fields.field.id) {
              let valueType = Object.keys(fieldDB[1]); // floatValue, stringValue, intValue, etc.
              return (fields.value = fieldDB[1][valueType[0]]);
            }
          });
        }); */
      }
    });
    let newField = {
      _typename: 'Fields',
      field: {
        _typename: 'Field',
        id: 'createdAtField',
        name: 'createdAt',
      },
      value: record.createdAt,
    };
    record.fields.push(newField);
    return record;
  });
  return newRecords;
}

export function getModifiedFieldsData(
  headers: FolderField[],
  model: GridEditRowsModel,
  newValues: any,
  copiedRows: CopiedRows[]
) {
  let value: any[] = [],
    recordId: string | undefined = undefined,
    modifiedFields: FolderField[];

  // The model only tracks the cell that has been clicked so the copiedRows state
  // has the data of the cells that has been copied but not directly clicked,
  // for the recordId in the model, each columns is checked if there is data for the column
  // in the model that data is used, if not the copied rows is checked before the next column
  // The copiedRowsState has the same structure as the GridEditRowsModel
  modifiedFields = headers.filter((col) => {
    for (let key in model) {
      if (model[key][col.name]) {
        // if (!model[key][col.name].value) return null;
        recordId = key;
        value.push(model[key][col.name].value);
        if (!newValues.get(key)) {
          newValues.set(key, new Map());
        }
        return col;
      }

      if (copiedRows[key]?.[col.name]) {
        value.push(copiedRows[key][col.name].value);
        if (!newValues.get(key)) {
          newValues.set(key, new Map());
        }
        return col;
      }
    }
  });

  return { modifiedFields, recordId, value };
}

export function getOrderForProcessing(
  modifiedFields: FieldValue[],
  recordID: string,
  processingOrder: ProcessingOrder,
  fieldsToSave: FieldsToSaveMap,
  selectedFolder: SelectedFolder | undefined,
  setProcessingOrder: React.Dispatch<React.SetStateAction<ProcessingOrder>>
) {
  window.log(selectedFolder);
  let newEnteredValue: any;
  const newProcessingOrder = processingOrder;

  // Get The entered value
  for (const [key, value] of Object.entries(modifiedFields[0])) {
    if (key !== 'id') {
      newEnteredValue = value;
    }
  }

  // Loop the fieldsToSave map state that includes the fields that have been entered before
  fieldsToSave.forEach(function (value: any) {
    // gets only the primaryfield data from the map, it contains the value
    const field = value.get(selectedFolder.primaryFolderFieldId);
    if (field !== undefined) {
      // loops the field object to get only the value property
      for (const [property, value] of Object.entries(field)) {
        // ignores if the property is id, that is the folder id, not needed
        if (property !== 'id') {
          // if the value current value array doesn't exist, creates it and adds the recordID
          if (!newProcessingOrder[newEnteredValue] && newEnteredValue !== '') {
            newProcessingOrder[newEnteredValue] = [recordID];
          }
          // if the current value array already exists, push the new recordID to the array if it doesn't exists
          if (
            newProcessingOrder[newEnteredValue] &&
            !newProcessingOrder[newEnteredValue].includes(recordID)
          ) {
            newProcessingOrder[newEnteredValue].push(recordID);
          }
        }
      }
    }
  });

  // if the record has changed its value, delete and place it on the new value array
  for (const [key, value] of Object.entries(processingOrder)) {
    if (value.includes(recordID) && key !== newEnteredValue) {
      newProcessingOrder[key] = value.filter((value, index) => value !== recordID);
    }
  }

  setProcessingOrder(newProcessingOrder);
}
