import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import gqlShared from '../../utils/gql/gqlShared';
import SharedPdfViewer from '../../components/SharedPdfViewer';
import ImageViewer from '../../components/ReactImage';
import SimpleTable from '../../components/simpleTable';
import { Typography, Grid, Tabs, Tab, Button } from '@mui/material';
import { TabPanel, a11yProps } from '../../components/collectionBin/utils';
import { Record } from '../search/types';
import { Row } from '../../components/masterTable/masterTableTypes';
import _ from 'lodash';

interface MatchParams {
  name: string;
  link: string;
}
interface SharedRecordProps extends RouteComponentProps<MatchParams> {
  id: string;
}

export interface AttachmentsArr {
  DocumentAttachment: {
    type: string;
    name: string;
    folderId?: string;
    getUrl?: null | string;
  };
  SignatureAttachment: {
    type: string;
    name?: string;
    folderId?: string;
    getUrl?: null | string;
  };
  PictureAttachment: {
    type: string;
    name?: string;
    folderId?: string;
    getUrl?: null | string;
  };
}

const styles = {
  root: {
    padding: '16px',
    boxSizing: 'border-box',
    height: '100%',
  },
  fieldsContainer: {
    background: 'white',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    maxHeight: '88%',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#eaeaea',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bdbcbc',
      borderRadius: '10px',
    },
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '25%',
  },
  rightContainer: {
    width: '75%',
  },
};

const SharedRecord = (props: SharedRecordProps) => {
  let recordId = props.id;
  console.log('SHARED RECORDS WORKING');
  const [selectedRecord, setSelectedRecord] = useState<number | false>(0);
  const [rows, setRows] = useState<Row[]>([]);
  const [value, setValue] = useState(0);
  const [org, setOrg] = useState<string | number>(0);
  const [folderDetail, setFolderDetail] = useState<string | null>(null);
  const [attachments, setAttachments] = useState<any[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [loadSharedRecord, { data }] = useLazyQuery(gqlShared.GET_RECORD, {
    variables: {
      link: props.match.params.link,
    },
    onCompleted: async () => {
      if (data.getRecord) {
        const { records } = data.getRecord;
        setRows(records);
        //ensure objects with valid information get set as fields
        setFolderDetail(records[0].folderId);

        let attachmentsArr:
          | {
              DocumentAttachment: any[];
              SignatureAttachment: any[];
              PictureAttachment: any[];
            }
          | any[] = {
          DocumentAttachment: [],
          SignatureAttachment: [],
          PictureAttachment: [],
        };
        //THIS IS TEMPORARY
        //This will get automatically replace when the record has a real attachment
        //and we can delete it when we move to production
        let attachmentsTemp = [
          {
            type: 'DocumentAttachment',
            getUrl: 'https://s25.q4cdn.com/967830246/files/doc_downloads/test.pdf',
          },
          {
            type: 'SignatureAttachment',
            getUrl: 'https://www.focusitinc.com/wp-content/uploads/2017/05/signature.png',
          },
          {
            type: 'PictureAttachment',
            getUrl:
              'https://securityintelligence.com/wp-content/uploads/2019/08/package-delivery-cybercriminals-at-your-doorstep.jpg',
          },
        ];
        let arrAttachments;
        if (records[0].attachments.length > 0) {
          arrAttachments = records.map((record: Record) => {
            attachmentsArr = {
              DocumentAttachment: [],
              SignatureAttachment: [],
              PictureAttachment: [],
            };
            record.attachments.forEach((att) => {
              if (!_.isArray(attachmentsArr)) {
                switch (att.type) {
                  case 'DocumentAttachment':
                    attachmentsArr.DocumentAttachment.push({
                      type: att.type,
                      name: att.name,
                      folderId: record.folderId.id,
                    });
                    break;
                  case 'SignatureAttachment':
                    attachmentsArr.SignatureAttachment.push({
                      type: att.type,
                    });
                    break;
                  case 'PictureAttachment':
                    attachmentsArr.PictureAttachment.push({
                      type: att.type,
                    });
                    break;
                  default:
                    break;
                }
              }
            });
            return attachmentsArr;
          });
        } else {
          attachmentsArr = attachments;
          attachmentsTemp.forEach((attachment) => {
            if (!_.isArray(attachmentsArr)) {
              switch (attachment.type) {
                case 'DocumentAttachment':
                  attachmentsArr.DocumentAttachment.push({
                    type: attachment.type,
                    getUrl: attachment.getUrl,
                  });
                  break;
                case 'SignatureAttachment':
                  attachmentsArr.SignatureAttachment.push({
                    type: attachment.type,
                    getUrl: attachment.getUrl,
                  });
                  break;
                case 'PictureAttachment':
                  attachmentsArr.PictureAttachment.push({
                    type: attachment.type,
                    getUrl: attachment.getUrl,
                  });
                  break;
                default:
                  break;
              }
            }
            return;
          });
        }
        setAttachments([...arrAttachments]);
        setOrg(data.getRecord.organisationId);
      }
    },
    fetchPolicy: 'network-only',
    onError: (err) => {
      console.error('Cannot load folder records: ', err);
    },
  });
  const handleSelectingRecord = (i: number) => {
    if (rows.length > 1) {
      setSelectedRecord(i);
      setFolderDetail(rows[i].folderId);
    }
  };
  useEffect(() => {
    loadSharedRecord({
      variables: {
        link: props.match.params.link,
      },
    });
  }, [loadSharedRecord, recordId]);
  if (attachments.length > 0) {
    return (
      <Grid container sx={styles.root}>
        <Grid item container sx={styles.leftContainer}>
          <Grid item>
            <Typography variant="h4">Record Details </Typography>
          </Grid>
          <Grid item container sx={styles.fieldsContainer}>
            <Grid item direction="column">
              {rows.length ? (
                <SimpleTable
                  selectedRow={selectedRecord}
                  action={handleSelectingRecord}
                  rows={rows}
                ></SimpleTable>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container sx={styles.rightContainer}>
          <Grid item md={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {selectedRecord !== false &&
              attachments[selectedRecord].DocumentAttachment.length ? (
                <Tab label="Document" {...a11yProps(0)} />
              ) : null}
              {selectedRecord !== false &&
              attachments[selectedRecord].SignatureAttachment.length ? (
                <Tab label="Signature" {...a11yProps(1)} />
              ) : null}
              {selectedRecord !== false &&
              attachments[selectedRecord].PictureAttachment.length ? (
                <Tab label="Image" {...a11yProps(2)} />
              ) : null}
            </Tabs>
            <TabPanel value={value} index={0}>
              {selectedRecord !== false &&
                attachments[selectedRecord].DocumentAttachment.map(
                  (attachment: AttachmentsArr['DocumentAttachment'], i: number) => {
                    if (i === attachments[selectedRecord].DocumentAttachment.length - 1) {
                      return (
                        <SharedPdfViewer
                          key={i}
                          attachment={{
                            folderId: folderDetail?.id,
                            name: attachment.name,
                            org: org,
                          }}
                        />
                      );
                    } else return null;
                  }
                )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {selectedRecord !== false &&
                attachments[selectedRecord].SignatureAttachment.map(
                  (attachment: AttachmentsArr['SignatureAttachment'], i: number) => {
                    return <ImageViewer key={i} url={attachment.getUrl} />;
                  }
                )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {selectedRecord !== false &&
                attachments[selectedRecord].PictureAttachment.map(
                  (attachment: AttachmentsArr['PictureAttachment'], i: number) => {
                    return <ImageViewer key={i} url={attachment.getUrl} />;
                  }
                )}
            </TabPanel>
            {/* <ReactPdf pdf={samplePDF}/> */}
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid>
        <Typography>This link has expired</Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ maxWidth: '25%' }}
          onClick={() => {
            setSelectedRecord(false);
            document.location.href = '/';
          }}
        >
          {'< '}Go to login
        </Button>
      </Grid>
    );
  }
};

export default SharedRecord;
