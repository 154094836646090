import { useCallback, useEffect, useState } from 'react';
import { StyledDeleteIconButton, StyledTextField } from './StyledComponents';
import { Stack, Tooltip } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';
import { debounce } from 'lodash';
import { id } from 'date-fns/locale';

interface Props {
  identifier: any;
  identifierIndex: number;
  identifiersToConfirm: any[];
  setIdentifiersToConfirm: any;
}

const ConfirmIdentifiersInput = (props: Props) => {
  const { identifier, identifierIndex, identifiersToConfirm, setIdentifiersToConfirm } =
    props;
  const [inputValue, setInputValue] = useState(identifier.identifierText || '');

  const handleDelete = () => {
    const newIdentifiersToConfirm = [...identifiersToConfirm];
    newIdentifiersToConfirm.splice(identifierIndex, 1);
    setIdentifiersToConfirm(newIdentifiersToConfirm);
  };

  const handleChange = useCallback(
    debounce((value: string) => {
      setIdentifiersToConfirm((prevIdentifiersToConfirm: any) => {
        const newIdentifiersToConfirm = prevIdentifiersToConfirm.map((item, index) =>
          index !== identifierIndex ? item : { ...item, identifierText: value }
        );
        return newIdentifiersToConfirm;
      });
    }, 300),
    [identifierIndex, setIdentifiersToConfirm]
  );

  return (
    <Stack direction="row" width="100%" gap={4} data-testid="identifier-to-confirm">
      <StyledTextField
        id={''}
        fullWidth
        variant={'outlined'}
        size={'small'}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          handleChange(e.target.value);
        }}
      />

      <Tooltip title="Delete identifier">
        <span>
          <StyledDeleteIconButton aria-label="clear" onClick={handleDelete}>
            <FontAwesomeIcon icon="fas fa-trash" color="common.greyblueDark" size={''} />
          </StyledDeleteIconButton>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default ConfirmIdentifiersInput;
