import React, { useRef } from 'react';
import { useField } from 'formik';
export const MyTextInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  if (props.isdynamic) {
    field.onBlur = (e) => {
      props.isdynamic.value = e.target.value;
      window.log(field);
      setValue(props.isdynamic);
    };
  }
  return (
    <div className={`formInput${props.orientation ? props.orientation : ''}`}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
export const MyTextInputWithOutLabel = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {/* <label htmlFor={props.id || props.name}>{label}</label> */}
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
export const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  if (props.selectedfolder) {
    field.value = props.selectedfolder;
  }
  if (props.changefolder) {
    field.onChange = (e) => {
      props.changefolder.selectionHandler(e);
    };
  }
  return (
    <div className="formInput">
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const MyCheckbox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta, helpers] = useField({ ...props, type: 'checkbox' });
  const { setValue } = helpers;
  if (props.isdynamic) {
    field.onChange = (e) => {
      props.isdynamic.value = e.target.value;
      window.log(field);
      setValue(props.isdynamic);
    };
  }
  let active = false;
  const checkbox = useRef();
  const bolSlider = useRef();
  const activateCheckbox = () => {
    const checkboxInput = checkbox.current;
    if (!active) {
      active = true;
      checkboxInput.checked = true;
      bolSlider.current.classList.add('true');
    } else {
      active = false;
      checkboxInput.checked = false;
      bolSlider.current.classList.remove('true');
    }
  };
  return (
    <div className="formInputHorizontal">
      <label className="checkbox">{props.label}</label>
      <div ref={bolSlider} className="booleanSlider" onClick={activateCheckbox}>
        <div className="knob"></div>
      </div>
      <input ref={checkbox} type="checkbox" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
