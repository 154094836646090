import { useState, useContext, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gqlRecords from '../../utils/gql/gqlRecords';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  useMediaQuery,
  TabsProps,
  TabProps,
  AlertColor,
} from '@mui/material';
import PdfViewer from '../../components/PdfViewer';
import ImageViewer from '../../components/ReactImage';
import { Context as AuthContext } from '../../context/authContext';
import { Context as StatusContext } from '../../context/statusContext';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import DateFnsUtils from '@date-io/date-fns';
import utils from '../../utils/utils';
import { styled } from '@mui/material/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { a11yProps } from '../comm/CommStyled';
import CustomTabPanel from '../../components/tabs/Tabpanel';
import WorkflowTab from '../workflow/Tab/WorkflowTab';
import { Record, Field as SearchField } from '../search/types';
import { RouteComponentProps } from 'react-router-dom';
import { SecondStyledTexfield } from '../../components/form/StyledTextfield';
import { GET_FOLDER_BY_ID, GET_FOLDER_BY_ID_BY_ORG } from '../../utils/gql/gqlFolders';
import _ from 'lodash';

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    sx={{ backgroundColor: 'common.greyblue' }}
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 60,
    width: '100%',
    backgroundColor: theme.palette.common.greyblueDarker,
  },
}));
const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  })
);

const classes = {
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '100%',
  },
  rootMobile: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'nowrap',
  },
  fieldsContainer: {
    padding: '16px',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    height: 'calc(100% - 48px)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#eaeaea',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bdbcbc',
      borderRadius: '10px',
    },
  },
};

type Attachments = {
  DocumentAttachment: any[];
  SignatureAttachment: any[];
  PictureAttachment: any[];
};

type FolderDetail = {
  id: string;
};

const generateAttachments = (
  record: Record,
  attachments: Attachments,
  folderDetail: FolderDetail
) => {
  let attachmentsArr: Attachments = {
    DocumentAttachment: [],
    SignatureAttachment: [],
    PictureAttachment: [],
  };
  //THIS IS TEMPORARY
  //This will get automatically replace when the record has a real attachment
  //and we can delete it when we move to production
  let attachmentsTemp = [
    {
      type: 'DocumentAttachment',
      getUrl: 'https://s25.q4cdn.com/967830246/files/doc_downloads/test.pdf',
    },
    {
      type: 'SignatureAttachment',
      getUrl: 'https://www.focusitinc.com/wp-content/uploads/2017/05/signature.png',
    },
    {
      type: 'PictureAttachment',
      getUrl:
        'https://securityintelligence.com/wp-content/uploads/2019/08/package-delivery-cybercriminals-at-your-doorstep.jpg',
    },
  ];

  if (record.attachments.length > 0) {
    attachmentsArr = attachments;
    record.attachments.forEach((attachment) => {
      switch (attachment.type) {
        case 'DocumentAttachment':
          attachmentsArr.DocumentAttachment.push({
            type: attachment.type,
            name: attachment.name,
            folderId: folderDetail.id,
          });
          break;
        case 'SignatureAttachment':
          attachmentsArr.SignatureAttachment.push({
            type: attachment.type,
          });
          break;
        case 'PictureAttachment':
          attachmentsArr.PictureAttachment.push({
            type: attachment.type,
          });
          break;
        default:
          break;
      }
      return;
    });
  } else {
    attachmentsArr = attachments;
    attachmentsTemp.forEach((attachment) => {
      switch (attachment.type) {
        case 'DocumentAttachment':
          attachmentsArr.DocumentAttachment.push({
            type: attachment.type,
            getUrl: attachment.getUrl,
          });
          break;
        case 'SignatureAttachment':
          attachmentsArr.SignatureAttachment.push({
            type: attachment.type,
            getUrl: attachment.getUrl,
          });
          break;
        case 'PictureAttachment':
          attachmentsArr.PictureAttachment.push({
            type: attachment.type,
            getUrl: attachment.getUrl,
          });
          break;
        default:
          break;
      }
      return;
    });
  }

  return attachmentsArr;
};

interface Field {
  id: string;
  name: string;
  type: string;
  value: string;
}

interface Props {
  match: {
    params: {
      folder: any;
    };
  };
}

const RecordPage = (props: RouteComponentProps | Props) => {
  const mobile = useMediaQuery('(max-width:600px)');
  let recordId = props.id;
  const authContext = useContext(AuthContext);
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const [snackOpen, setSnack] = useState(false);
  const [message, setMessage] = useState({ message: '', severity: '' });
  const isSuser = authContext.state.suser || suser;
  const [record, setRecord] = useState<Record | any>({});
  const { state, setSelectedRecord, setScreen, setSelectedContextFolder } =
    useContext(StatusContext);
  const [fields, setFields] = useState<Field[] | undefined>();
  const [value, setValue] = useState('document');
  const [attachments, setAttachments] = useState<Attachments>({
    DocumentAttachment: [],
    SignatureAttachment: [],
    PictureAttachment: [],
  });

  let folderDetail: any = {};
  if (props.location && props.location.state) {
    folderDetail = props.location.state.folderDetail;
  } else if (props.match) {
    folderDetail['id'] = props.match.params.folder;
    recordId = props.match.params.id;
  } else {
    folderDetail = props.folder;
  }
  const handleChange = (event: any, newValue: any) => setValue(newValue);

  const [getFolder] = useLazyQuery(isSuser ? GET_FOLDER_BY_ID_BY_ORG : GET_FOLDER_BY_ID, {
    onCompleted: (data) => {
      let contextFolder: any = { ...state.selectedFolder };
      const folder = isSuser ? data.FolderByOrg : data.Folder.folder;
      contextFolder[state.screen] = folder;
      setSelectedContextFolder(contextFolder);
    },
  });

  const [getRecord, { data }] = useLazyQuery(
    isSuser ? gqlRecords.GET_RECORD_BY_ORG : gqlRecords.GET_RECORD_BY_ID,
    {
      variables: isSuser
        ? {
            id: recordId,
            organisationId: state.selectedOrg ? state.selectedOrg.id : selOrg.id,
          }
        : { id: recordId },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data) {
          const { record } = isSuser ? data.RecordByOrg : data.Record;
          //ensure objects with valid information get set as fields
          setRecord(record);
          setSelectedRecord(record);

          let fieldArray = record.fields.map((field: SearchField) => {
            return {
              type: field.field.type,
              id: field.field.id,
              name: field.field.name,
              value: field.value,
            };
          });

          let attachmentsArr = generateAttachments(record, attachments, folderDetail);

          setFields([...fieldArray]);
          setAttachments({ ...attachmentsArr });
          getFolder({
            variables: isSuser
              ? {
                  id: record.folderId.id,
                  organisationId:
                    state.selectedOrg && _.isObject(state.selectedOrg)
                      ? state.selectedOrg.id
                      : selOrg.id,
                }
              : {
                  id: record.folderId.id,
                },
          });
        }
      },
      onError: (err) => {
        console.error('Cannot load folder records: ', err);
      },
    }
  );

  const refetchRecord = () => {
    getRecord();
  };

  const handleCloseSnack = (event: any, setSnack: any, reason: any) => {
    if (reason === 'clickaway') return;
    setSnack(false);
  };

  useEffect(() => {
    // set screen to context
    setScreen('record');
    getRecord();
  }, []);

  if (
    attachments &&
    attachments.DocumentAttachment &&
    attachments.DocumentAttachment.length &&
    folderDetail
  ) {
    return (
      <>
        <Box sx={mobile ? classes.rootMobile : classes.root}>
          <Box
            sx={{
              height: 'calc(100vh - 68px)',
              maxWidth: mobile ? 'none' : '200px',
              paddingTop: mobile && '20px',
            }}
          >
            <Box
              sx={{
                height: '48px',
                backgroundColor: 'common.greyblue',
                borderRight: '5px solid',
                borderRightColor: 'common.greyblueDark',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="tab">Record Details </Typography>
            </Box>
            <Box
              sx={{
                ...classes.fieldsContainer,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.black.ultraLight
                    : undefined,
              }}
            >
              <Grid item container direction="row" spacing={2}>
                {fields &&
                  fields.map((field: Field, i) => {
                    switch (field.type) {
                      case 'datetime':
                        if (field.value && !field.value.includes('/')) {
                          let date: number | string | Date = parseInt(field.value);
                          date = new Date(date);
                          date = utils.convertDate(date);
                          field.value = date;
                        }
                        window.log(field.value);
                        return (
                          <Grid item width="100%" key={field.value + i}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <SecondStyledTexfield
                                label={field.name}
                                variant="outlined"
                                value={field.value}
                                fullWidth
                                sx={{
                                  '& .MuiInputBase-root': {
                                    backgroundColor: (theme) =>
                                      theme.palette.mode === 'light'
                                        ? theme.palette.background.default
                                        : theme.palette.common.greyblueDarker,
                                  },
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        );
                      default:
                        return (
                          <Grid item width="100%" key={field.value + i}>
                            <SecondStyledTexfield
                              name={field.id}
                              label={field.name}
                              value={field.value || ''}
                              fullWidth
                              variant="outlined"
                              onChange={() => {}}
                              sx={{
                                '& .MuiInputBase-root': {
                                  backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                      ? theme.palette.background.default
                                      : theme.palette.common.greyblueDarker,
                                },
                              }}
                            />
                          </Grid>
                        );
                    }
                  })}
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              height: 'calc(100vh - 68px)',
              width: mobile ? '100%' : 'calc(100% - 205px)',
            }}
          >
            <StyledTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="tabs"
            >
              {attachments.DocumentAttachment.length ? (
                <StyledTab label="Document" value="document" {...a11yProps('document')} />
              ) : null}
              {attachments.SignatureAttachment.length ? (
                <StyledTab
                  label="Signature"
                  value="signature"
                  {...a11yProps('signature')}
                />
              ) : null}
              {attachments.PictureAttachment.length ? (
                <StyledTab label="Image" value="picture" {...a11yProps('picture')} />
              ) : null}
              <StyledTab
                label="Workflow"
                value="workflow"
                {...a11yProps('workflow')}
                // disabled={!record.currentProcessStep}
              />
            </StyledTabs>
            <CustomTabPanel value={value} index={'document'} label="document" padding={1}>
              {attachments.DocumentAttachment.map((attachment, i) => {
                if (i === 0) {
                  return (
                    <PdfViewer
                      height={'calc(100vh - 200px)'}
                      key={i}
                      attachment={{
                        folderId: folderDetail.id,
                        name: attachment.name,
                      }}
                      record={record}
                      setMessage={setMessage}
                      setSnackOpen={setSnack}
                      refetch={refetchRecord}
                    />
                  );
                } else return null;
              })}
            </CustomTabPanel>
            <CustomTabPanel
              value={value}
              index={'signature'}
              label="signature"
              padding={1}
            >
              {attachments.SignatureAttachment.map((attachment, i) => {
                return <ImageViewer key={i} url={attachment.getUrl} />;
              })}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={'picture'} label="picture">
              {attachments.PictureAttachment.map((attachment, i) => {
                return <ImageViewer key={i} url={attachment.getUrl} />;
              })}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={'workflow'} label="workflow">
              <WorkflowTab
                currentProcessStep={record.currentProcessStep}
                recordPage={true}
              />
            </CustomTabPanel>
            {/* <ReactPdf pdf={samplePDF}/> */}
          </Box>
        </Box>
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={(e, reason) => handleCloseSnack(e, setSnack, reason)}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={(e) => handleCloseSnack(e, setSnack, '')}
            severity={message.severity as AlertColor}
          >
            {message.message}
          </Alert>
        </Snackbar>
      </>
    );
  } else {
    return null;
  }
};

export default RecordPage;
