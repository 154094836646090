import { useState } from 'react';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { OCRSetup } from './types';

const useOCRSetup = (): OCRSetup | null => {
  const [isActive, setIsActive] = useState(false);
  const [areaSelectionActive, setAreaSelectionActive] = useState(false);
  const [scale, setScale] = useState(1.2);
  const zoomPluginInstance = zoomPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();
  const [currentInputName, setCurrentInputName] = useState('');

  const toggleOCRSetup = () => {
    setIsActive((curr) => !curr);
  };

  const toggleAreaSelection = () => {
    setAreaSelectionActive((curr) => !curr);
  };

  const scaleUp = () => {
    const newScale = scale + 0.2;
    setScale(newScale);
  };

  const scaleDown = () => {
    const newScale = scale - 0.2;
    setScale(newScale);
  };

  return {
    isActive,
    toggleOCRSetup,
    areaSelectionActive,
    toggleAreaSelection,
    scale,
    scaleUp,
    scaleDown,
    zoomPluginInstance,
    thumbnailPluginInstance,
    currentInputName,
    setCurrentInputName,
  };
};

export default useOCRSetup;
