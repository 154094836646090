import Dexie, { Table } from 'dexie';

export const db = new Dexie('dataCapture');
db.version(1).stores({
  searchScreen: '++id, recordID, status, fields', // Primary key and indexed props
  indexingScreen: '++id, recordID, status, fields, textract', // Primary key and indexed props
  taskNotifications: '++id,date,message,code,screen,action,type,read',
});
db.version(2).stores({
  tasks: 'id, action, ammount, success, fail, progress, screen, timestamp',
});
db.version(3).stores({
  maintenance: 'startDate, endDate, message, restart',
});
db.version(4).stores({
  taskNotifications: '++id,date,message,code,screen,action,type,read,_id',
});
db.version(5).stores({
  folders:
    'id, name, createdBy.firstName, createdBy.lastName, createdAt, autoAppendRecords, scanning, primaryFolderFieldId, ocrEnabled, active, workFlow',
});
db.version(6).stores({
  tasks: 'id, action, ammount, success, fail, progress, screen, timestamp, isDone',
});
