import React from 'react';

// mui
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import FontAwesomeIcon from '../../../../../components/FAIcon';

interface Props {
  values: string[];
  colors: string[];
  setDisabled: React.Dispatch<React.SetStateAction<never[]>>;
  disabled: string[];
  folder: string;
}

export default function CustomLegend({ values, colors, setDisabled, disabled }: Props) {
  // When a button is clicked,
  // sets a new disabled array with the button value if it doesn't exists
  // otherwise it generates the array without that value
  // this allows to toggle the line visibility
  const handleClick = (e) => {
    const value = e.target.textContent;
    setDisabled((prev: any[]) =>
      prev.includes(value) ? prev.filter((i) => i !== value) : [...prev, value]
    );
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      paddingLeft="3rem"
    >
      <Stack direction="row">
        {values.map((value, index) => {
          return (
            <Button
              startIcon={<FontAwesomeIcon icon="fas fa-circle" color={colors[index]} />}
              onClick={handleClick}
              size="small"
              sx={{
                color: !disabled.includes(value) ? 'common.grey' : '#bdbdbd',
                fontWeight: 300,
              }}
              key={value}
            >
              {value}
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
}
