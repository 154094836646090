import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import InputSelect from '../../../components/inputs/InputSelect';
import { Filter } from '../ReportingSideBar';

interface WorkflowStatusFilterProps {
  filter: Filter;
  handleSelection: (option: Filter) => void;
}

const FIELD_ID = 'workflow-status';

const STATUS = [
  { label: 'Completed', value: 'Approved' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Incomplete', value: 'Unapproved' },
  { label: 'UsersApprovalInfo', value: 'UserApprovalInfo' },
  { label: 'AllApprovalInfo', value: 'AllApprovalInfo' },
];
const WorkflowStatusFilter = ({ filter, handleSelection }: WorkflowStatusFilterProps) => {
  const [value, setValue] = useState<string>(filter && filter.value ? filter.value : '');
  const { setValue: setFormValue, watch } = useFormContext();
  const handleChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(event.target.value as string);
  };

  useEffect(() => {
    if (value === 'UserApprovalInfo') {
      handleSelection({ label: 'User', id: 'user', type: 'userId', value: '' });
    }
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex((filter) => filter.id === 'workflow-status');
    index !== -1 &&
      setFormValue(`selectedTemplate.variables.filters[${index}].value`, value);
  }, [value]);

  useEffect(() => {
    filter && setValue(filter.value);
  }, [filter]);

  return (
    <InputSelect
      options={STATUS}
      inputName={FIELD_ID}
      helperText="Select an option"
      required={true}
      size="small"
      backgroundColor="transparent"
      handleChange={handleChange}
      value={value}
      textfieldProps={{
        sx: {
          '& .MuiSelect-select': {
            color: 'common.greyblueDarker',
            fontSize: 14,
            fontWeight: 500,
          },
          '& .MuiInputBase-input': {
            textAlign: 'end',
            paddingBottom: 1.5,
            paddingTop: '16px',
          },
        },
      }}
    />
  );
};

export default WorkflowStatusFilter;
