import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Background from '../../../../images/background-blue.webp';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { Folder } from '../../types';
import { useOCRTemplate } from '../../../../hooks/OCRTemplateSetup/useOCRTemplate';
import SearchInput from '../../../../components/toolbarScreen/SearchInput';
import TemplateIdentifier from '../../subcomponents/TemplateIdentifier';
import { GlobalOptionsState } from '../../FolderPage';
import { StatusProps } from '../../../../components/SnackBar';

interface TemplatesTabProps {
  folder: Folder;
  folderGlobalOptions: GlobalOptionsState;
  handleEditGlobalOptions: (key: string, value: boolean | string) => void;
  setSelectedTemplate: Dispatch<SetStateAction<string | null>>;
  setSnackbar: React.Dispatch<React.SetStateAction<StatusProps>>;
  refetch: any;
}

const TemplatesTab = ({
  folder,
  folderGlobalOptions,
  handleEditGlobalOptions,
  setSelectedTemplate,
  setSnackbar,
  refetch,
  setCurrentFolder,
}: TemplatesTabProps) => {
  const [showDeleteMessage, setShowDeleteMessage] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [showHoverMenu, setShowHoverMenu] = useState<string | false>(false);
  const options = folder.folderFields.map((obj) => ({ id: obj.id, label: obj.name }));

  const {
    deleteTemplate,
    templates: templatesState,
    getTemplate,
    refetchTemplates,
  } = useOCRTemplate();

  const templates = useMemo(
    () =>
      templatesState.filter((temp) =>
        temp.templateName.toLowerCase().includes((searchQuery || '').toLowerCase())
      ),
    [searchQuery, templatesState]
  );

  const handleDeleteTemplate = (id: string) => {
    deleteTemplate({ id }).then((data) => {
      getTemplate(folder.id);
    });
  };

  const handleEditTemplate = (template: any) => {
    setSelectedTemplate(template);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    getTemplate(folder.id);
  }, [folder]);

  return (
    <Stack sx={{ gap: 3, height: '100%' }}>
      <Stack sx={{ gap: 2, width: '25%' }}>
        <Typography sx={{ color: 'common.greyblue', fontWeight: 700 }}>
          TEMPLATE IDENTIFIER FIELD
        </Typography>
        <TemplateIdentifier
          dataTestId="OCR Template Identifier"
          folder={folder}
          options={options}
          folderGlobalOptions={folderGlobalOptions}
          handleEditGlobalOptions={handleEditGlobalOptions}
          setSnackbar={setSnackbar}
          refetch={refetch}
          setCurrentFolder={setCurrentFolder}
        />
      </Stack>
      <Stack sx={{ gap: 2, height: '100%' }}>
        <Stack sx={{ flexDirection: 'row', gap: 2, justifyContent: 'space-between' }}>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Typography sx={{ color: 'common.greyblue', fontWeight: 700 }}>
              ASSIGNED TEMPLATES
            </Typography>
            <Divider
              sx={{ height: '70%', borderRightWidth: 'medium' }}
              orientation="vertical"
              variant="middle"
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              {!showDeleteMessage && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <FontAwesomeIcon
                    icon="fas fa-trash"
                    size="12px"
                    color="common.negative"
                  />
                  <Button
                    onClick={() => setShowDeleteMessage((prev) => !prev)}
                    size="small"
                    sx={{ color: 'common.negative', fontSize: '10px' }}
                  >
                    Delete All
                  </Button>
                </Box>
              )}
              <Collapse
                in={showDeleteMessage}
                orientation="horizontal"
                timeout={{ exit: 0, appear: 400, enter: 400 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <FontAwesomeIcon
                    icon="fas fa-trash"
                    size="12px"
                    color="common.negative"
                  />
                  {showDeleteMessage && (
                    <Typography sx={{ width: '430px', color: 'common.negative' }}>
                      This action will permanently delete all templates, are you sure to
                      continue?
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setShowDeleteMessage((prev) => !prev)}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'common.positive',
                      '&:hover': {
                        backgroundColor: 'common.positiveDark',
                      },
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setShowDeleteMessage((prev) => !prev)}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'common.negative',
                      '&:hover': {
                        backgroundColor: 'common.negativeDark',
                      },
                    }}
                  >
                    No
                  </Button>
                </Box>
              </Collapse>
            </Box>
          </Stack>
          <SearchInput
            handleSearch={handleSearch}
            setSearchQuery={setSearchQuery}
            width="12%!important"
            colorIcon="common.greyblue"
            sx={{
              root: {
                backgroundColor: (theme: any) =>
                  theme.palette.mode === 'dark' ? 'transparent' : 'common.lightblue',
                '&:hover': {
                  backgroundColor: (theme: any) =>
                    theme.palette.mode === 'dark' ? 'transparent' : 'common.lightblue',
                  cursor: 'pointer',
                },
              },
              input: {
                '& .MuiInputBase-input': {
                  color: (theme: any) =>
                    theme.palette.mode === 'dark'
                      ? 'common.greyblue'
                      : 'common.greyblueDarker',
                  '&::placeholder': {
                    fontWeight: 800,
                    color: 'common.greyblue',
                  },
                },
              },
            }}
          />
        </Stack>
        <Paper
          elevation={2}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#2D333A' : 'common.white',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            p: 4,
            overflowY: 'auto',
          }}
        >
          {templates.map((temp) => (
            <Card
							data-testid="template-card"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#26292E' : 'common.greyblueDark',
                height: '240px',
                width: '170px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: '15px',
              }}
              key={temp.id}
              onMouseEnter={() => setShowHoverMenu(temp.id)}
              onMouseLeave={() => setShowHoverMenu(false)}
            >
              {showHoverMenu && showHoverMenu === temp.id ? (
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      height: '50%',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'common.greyblueDarker',
                      gap: 1.5,
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => handleEditTemplate(temp)}
                  >
                    <FontAwesomeIcon
                      icon="fas fa-trash"
                      size="14px"
                      color="common.white"
                    />
                    <Typography sx={{ fontWeight: 500, color: 'common.white' }}>
                      EDIT
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: '50%',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#ff475730',
                      gap: 1.5,
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => handleDeleteTemplate(temp.id)}
                  >
                    <FontAwesomeIcon icon="fas fa-trash" size="14px" color="#ff4757" />
                    <Typography sx={{ color: '#ff4757', fontWeight: 500 }}>
                      DELETE
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    backgroundImage: `url(${Background})`,
                    height: '100%',
                    width: '100%',
                  }}
                ></Box>
              )}
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'common.greyblue' : 'common.white',
                  fontWeight: 700,
                  marginTop: '8px',
                }}
              >
                {temp.templateName}
              </Typography>
            </Card>
          ))}
        </Paper>
      </Stack>
    </Stack>
  );
};

export default TemplatesTab;
