import React, { useEffect, useState } from 'react';
import {
  Modal,
  Paper,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Checkbox,
  Avatar,
} from '@mui/material';
import SearchInput from '../../toolbarScreen/SearchInput';
import {
  AssignRecord,
  AssignToModalProps,
  AssignToModalRow,
  ItemModalProps,
} from './AssignToModalTypes';
import { Row, User, UserKey } from '../masterTableTypes';
import { GridRowsProp } from '@mui/x-data-grid';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const checkItem = (arr: AssignRecord[], value: string) => {
  let times = arr.length;
  let isCheckedArr = [];
  let isChecked = false;
  let isIndeterminate = false;
  arr.forEach((record: AssignRecord) => {
    if (record?.toBeIndexedBy?.includes(value)) {
      isCheckedArr.push(1);
    }
  });
  if (isCheckedArr.length === times) {
    // if the user is selected in all selected files
    isChecked = true;
    isIndeterminate = false;
  } else if (isCheckedArr.length > 0) {
    // if the user is selected only in some files
    isChecked = false;
    isIndeterminate = true;
  } else {
    // if the user is not selected in any file
    isChecked = false;
    isIndeterminate = false;
  }
  return [isChecked, isIndeterminate];
};

const ItemModal = ({
  i,
  value,
  checkedList,
  setCheckedList,
  setMessage,
  setSnackOpen,
}: ItemModalProps) => {
  let [isChecked, isIndeterminate] = checkItem(checkedList, value.id);
  const [checked, setChecked] = useState(isChecked);
  const [indeterminate, setIndeterminate] = useState(isIndeterminate);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (indeterminate) setIndeterminate(false);
    setChecked(event.target.checked);
    if (event.target.checked) {
      let newState = checkedList.map((record) => ({
        ...record,
        toBeIndexedBy:
          // if there is only one user selected, it arrivas as string
          typeof record.toBeIndexedBy === typeof 'string'
            ? [value.id]
            : typeof record.toBeIndexedBy === typeof undefined
            ? [value.id]
            : [...record.toBeIndexedBy, value.id],
      }));
      setCheckedList(newState);
    } else {
      window.log(checkedList);
      let filterList: AssignRecord[] = checkedList.map((record) => {
        let filter: string[] = [];
        record.toBeIndexedBy.length === 0
          ? (filter = [])
          : (filter = record.toBeIndexedBy.filter((id: string) => id !== value.id));
        if (filter.length === 0) {
          // if the file does not have any user selected, it cannot be seen in the table
          setMessage({
            message: 'The file must have at least one user assigned to it',
            severity: 'warning',
          });
          setSnackOpen(true);
        }
        return {
          ...record,
          toBeIndexedBy: filter,
        };
      });
      setCheckedList(filterList);
    }
  };

  const labelId = `checkbox-list-secondary-label-${i}`;

  return (
    <ListItem
      key={i}
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={handleChange}
          checked={checked}
          indeterminate={indeterminate}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      }
      disablePadding
    >
      <ListItemButton
        sx={{
          padding: '10px 20px',
          boxShadow: '0px 10px 10px -10px #00000045',
        }}
      >
        <ListItemAvatar>
          <Avatar
            alt={`${value.firstName[0]} ${value.lastName[0]}`}
            src={`/static/images/avatar/${i + 1}.jpg`}
          />
        </ListItemAvatar>
        <ListItemText id={labelId} primary={`${value.firstName} ${value.lastName}`} />
      </ListItemButton>
    </ListItem>
  );
};

const AssignToModal = (props: AssignToModalProps) => {
  const { open, action, selected, setOpen, users, rows, setMessage, setSnackOpen } =
    props;
  const [checkedList, setCheckedList] = useState<AssignRecord[]>([]);
  const [searchField, setSearchField] = useState<string>();

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearchField(e.target.value);

  const handleClose = () => setOpen(false);

  const filterUsers = (data: User[]) => {
    return data.filter((item) =>
      ['firstName', 'lastName'].some((key) =>
        item[key].toLowerCase().includes(searchField.toLowerCase())
      )
    );
  };

  let filteredUsers: User[] | boolean = false;

  searchField ? (filteredUsers = filterUsers(users)) : (filteredUsers = users);

  useEffect(() => {
    if (selected === undefined || selected.length === 0) return;
    let selectedRows: AssignToModalRow[] = [];
    rows.forEach((row) => {
      selected.forEach((select) => {
        if (row.id === select) {
          selectedRows.push(row);
        }
      });
    });
    window.log(selectedRows);
    let checkedUsers = selectedRows.map((row) => ({
      recordId: row.id,
      toBeIndexedBy: row.toBeIndexedBy,
    }));

    window.log(checkedUsers);
    setCheckedList(checkedUsers);
  }, [selected]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <Paper
        sx={{
          ...style,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? 'common.greyblueDark'
              : 'common.greyblueDarker',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <Typography variant="subtitle2">To be indexed by</Typography>
          <Box>
            <SearchInput
              handleSearch={handleSearch}
              setSearchQuery={setSearchField}
            ></SearchInput>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'black.main' : 'white.main',
            maxHeight: '20vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '10px',
            },

            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#79909f',
              borderRadius: '4px',
            },
          }}
        >
          <List
            dense
            sx={{
              width: '100%',
              minHeight: 200,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {filteredUsers.length ? (
              filteredUsers.map((value, i) => {
                return (
                  <ItemModal
                    key={value + i}
                    i={i}
                    value={value}
                    checkedList={checkedList}
                    setCheckedList={setCheckedList}
                    setMessage={setMessage}
                    setSnackOpen={setSnackOpen}
                  />
                );
              })
            ) : searchField ? (
              <Typography variant="button">No results found</Typography>
            ) : (
              <Typography variant="button">No users on this folder</Typography>
            )}
          </List>
        </Box>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? 'common.greyblueDark'
                : 'common.greyblueDarker',
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{
              color: 'common.white',
              backgroundColor: 'common.negative',
              '&:hover': { backgroundColor: 'common.negativeDark' },
            }}
            onClick={() => setOpen(false)}
            size="small"
          >
            Cancel
          </Button>
          <Button
            disabled={checkedList.length > 0 && checkedList[0].toBeIndexedBy.length === 0}
            onClick={() => {
              let selectedUsers = checkedList.flat();
              // window.log(users);
              action({ recordIds: selected, toBeIndexedBy: selectedUsers });
              setOpen(false);
            }}
            size="small"
            sx={{
              color: 'common.white',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.common.blueShadow['500']
                  : theme.palette.primary.main,
              '&:hover': {
                color: 'common.white',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.blueShadow['600']
                    : theme.palette.primary.dark,
              },
            }}
          >
            Assign user/s
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default AssignToModal;
