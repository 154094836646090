import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { Step, StepContainerProps, StepError } from '../types';
import AddStepCard from './StepsCards/AddStepCard';
import StepCard from './StepCard';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

const styles = {
  container: {
    flex: 33,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '15px',
    height: '100%',
    overflowX: 'auto',
    padding: '0 30px',
    '&::-webkit-scrollbar': {
      height: '12px',
      backgroundColor: 'common.greyblueDarker',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
  },
};

const StepsContainer = ({
  steps,
  handleRemoveStep,
  handleAddStep,
  refCardsContainer,
  handleUpdateStep,
  handleUpdateStepOrders,
  isLoading,
  stepsWithErrors,
  context,
  users,
}: StepContainerProps) => {
  const handleOnDragEnd = (data: DropResult) => {
    if (data.destination)
      handleUpdateStepOrders(data.source.index, data.destination.index);
  };

  let results: Step[] | undefined;
  if (stepsWithErrors.length) {
    results = steps.filter(({ stepOrder: id1 }) =>
      stepsWithErrors.some(({ stepOrder: id2 }) => id2 === id1)
    );
  }

  return (
    <Box
      ref={refCardsContainer}
      sx={{
        ...styles.container,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.black.dark
            : theme.palette.common.white,
      }}
    >
      <Backdrop
        sx={{
          color: '#fff',
          backgroundColor: 'rgb(0 0 0 / 15%)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minWidth: steps.length * 352, // minWidth -> steps.length  * card width
        }}
      >
        {steps.map((step, index) => {
          let withError =
            results && results.find((res) => res.stepOrder === step.stepOrder);
          let error;
          if (withError) {
            error = stepsWithErrors.find(
              (errorStep) => errorStep.stepOrder === step.stepOrder
            );
          }
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                key={index}
                variant="button"
                sx={{
                  // color: '#8686865a',
                  color: withError ? 'common.negative' : '#868686',
                  display: 'flex',
                  justifyContent: 'center',
                  width: 352,
                  fontSize: 25,
                  fontWeight: 500,
                }}
              >
                {`Step ${index + 1}`}
              </Typography>
              {error && (
                <Typography
                  sx={{ color: withError ? 'common.negative' : '#868686', height: 0 }}
                >
                  {error.error}
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <DragDropContext onDragEnd={(data) => handleOnDragEnd(data)}>
          <Droppable droppableId="droppable" direction="horizontal" mode="standard">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: 'flex', minWidth: steps.length * 352 }} // minWidth -> steps.length  * card width
              >
                {steps &&
                  steps.map((step: Step, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <StepCard
                        step={step}
                        handleRemoveStep={handleRemoveStep}
                        handleUpdateStep={handleUpdateStep}
                        users={users}
                      />
                    </Box>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {context !== 'tab' && <AddStepCard handleAddStep={handleAddStep} />}
      </Box>
    </Box>
  );
};

export default StepsContainer;
