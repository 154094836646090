import type { Plugin, PluginFunctions, ViewerState } from '@react-pdf-viewer/core';
import * as React from 'react';
import { Context as StatusContext } from '../../context/statusContext';
interface props {
  url: string;
  screen: string;
  trackPosition: React.MutableRefObject<boolean>;
}

export const TrackPositionPlugin = ({ url, screen, trackPosition }: props): Plugin => {
  const { state, setViewer } = React.useContext(StatusContext);
  let { scale, offset } = state.viewer;
  const [pageContainer, setPageContainer] = React.useState();
  const updateOffset = (ofst: { top: number; left: number }) => {
    offset = ofst;
  };
  const updateScale = (scl: number) => {
    scale = scl;
  };

  const handleViewerStateChanged = (state: ViewerState) => {
    updateScale(state.scale);
    return state;
  };

  const handlePagesContainer = (getPagesContainer) => {
    if (!getPagesContainer) {
      return;
    }

    const ele = getPagesContainer();
    ele.addEventListener('scroll', handleScroll, {
      capture: false,
      passive: true,
    });
    window.log(screen);
    if (screen === 'indexing' || screen === 'search') {
      ele.addEventListener(
        'mouseleave',
        () => {
          window.log('mouse out');
          setViewer({ scale, offset });
        },
        {
          capture: false,
          passive: true,
        }
      );
    }
  };

  const handleScroll = (e: Event) => {
    if (!trackPosition.current) return;
    const target = e.target;
    const top = target.scrollTop;
    const left = target.scrollLeft;
    updateOffset({ top, left });
  };

  const scrollToLatestPosition = async () => {
    if (!trackPosition.current) return;
    const latestOffset = state.viewer.offset;
    if (!pageContainer || !latestOffset) {
      return;
    }
    const pagesEle = pageContainer;
    if (pagesEle) {
      pagesEle.scrollTop = latestOffset.top;
      pagesEle.scrollLeft = latestOffset.left;
    }
  };

  React.useEffect(() => {
    if (url && pageContainer) {
      scrollToLatestPosition();
    }
  }, [pageContainer]);

  React.useEffect(() => {
    updateScale(state.scale);
  }, [trackPosition.current]);

  return {
    install: (pluginFunctions: PluginFunctions) => {
      setPageContainer(pluginFunctions.getPagesContainer);
      handlePagesContainer(pluginFunctions.getPagesContainer);
    },
    onViewerStateChange: handleViewerStateChanged,
  };
};
