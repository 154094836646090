import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  borderRadius: '8px',
  border: 'none',
}));

interface ModalWrapperProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
}

const ModalWrapper = ({ isOpen, setIsOpen, children }: ModalWrapperProps) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      closeAfterTransition
    >
      <Fade sx={{ outline: 0 }} in={isOpen}>
        <Box elevation={0}>{children}</Box>
      </Fade>
    </StyledModal>
  );
};

export default ModalWrapper;
