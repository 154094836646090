import React, { useRef, useState } from 'react';

import {
  Box,
  Dialog,
  Tabs,
  Tab,
  Typography,
  IconButton,
  DialogContent,
  AlertColor,
} from '@mui/material';
import ProfileTab from './userPopup/ProfileTab';

import GeneralSnackbar from '../../components/SnackBar';
import PermissionsTab from './userPopup/PermissionsTab';
import FontAwesomeIcon from '../../components/FAIcon';
import Actions from './userPopup/Actions';

import { Provider as FormProvider } from './userPopup/formContext';
import { RefetchQueriesFunction } from 'react-apollo';

interface SnackBar {
  status: boolean;
  snackbarMessage: string;
  severity: AlertColor;
}

interface UserPopupProps {
  handleCloseModal: () => void;
  isOpenModal: boolean;
  refetch: RefetchQueriesFunction;
}

const initialStateSnackbar: SnackBar = {
  status: false,
  snackbarMessage: '',
  severity: 'warning',
};

function a11yProps(index: number) {
  return {
    id: `user-create-tab-${index}`,
    'aria-controls': `user-create-tabpanel-${index}`,
  };
}

const classes = {
  box: {
    display: 'flex',
    height: 'auto',
    flexDirection: { xs: 'column', md: 'row' },
    p: 0,
  },
  tabs: {
    // borderRight: 1,
    paddingTop: { xs: '0', md: '43px' },
    backgroundColor: 'common.greyblueDarker',
    '& .MuiTabs-flexContainer': {
      flexDirection: { xs: 'row', md: 'column' },
    },
    '& .Mui-selected': {
      borderRight: { md: '2px solid', xs: '0' },
      borderBottom: { md: '0', xs: '2px solid' },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
};

export const TabTitle = ({
  title,
  handleClose,
}: {
  title: string;
  handleClose: () => void;
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        sx={{
          textAlign: 'center',
          padding: { xs: '5px 0', md: '6px 0' },
          fontSize: { xs: 17, md: 20 },
          backgroundColor: 'common.greyblueDark',
          fontWeight: 'bold',
          display: 'block',
        }}
        variant="subtitle2"
        component={'span'}
      >
        {title}
      </Typography>

      <IconButton
        size="small"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: 10,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
          color: 'common.white',
        }}
      >
        <FontAwesomeIcon icon="fas fa-times" size="14px" color="#fff" padding="2px" />
      </IconButton>
    </Box>
  );
};

const UserPopup = ({ handleCloseModal, isOpenModal, refetch }: UserPopupProps) => {
  const [tabsValue, setTabsValue] = useState(0);
  const [hasErrors, setHasErrors] = useState({
    profile: false,
    permissions: false,
  });
  const [userCreatedSnackbar, setUserCreatedSnackbar] = useState(initialStateSnackbar);
  const formRef = useRef(null);

  const handleCloseSnackbar = (
    event: Event | React.SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === 'clickaway') return;
    setUserCreatedSnackbar(initialStateSnackbar);
  };

  const handleChangeTabs = (event: React.SyntheticEvent<Element, Event>, newValue: any) =>
    setTabsValue(newValue);

  return (
    <>
      <Dialog
        open={isOpenModal}
        onClose={handleCloseModal}
        maxWidth="md"
        sx={{
          height: '900px',
          margin: 'auto',
        }}
      >
        <DialogContent
          sx={{
            ...classes.box,
            bgcolor: (theme) =>
              theme.palette.mode === 'dark' ? 'background.paper' : 'common.white',
          }}
        >
          <FormProvider>
            <Tabs
              value={tabsValue}
              onChange={handleChangeTabs}
              aria-label="Create User"
              textColor="secondary"
              indicatorColor="secondary"
              sx={classes.tabs}
            >
              <Tab
                label="Profile"
                sx={{
                  color: '#fff',
                  gap: 1,
                  minHeight: { xs: '48px', md: '42px', lg: '42px' },
                }}
                {...a11yProps(0)}
                icon={
                  hasErrors && hasErrors.profile ? (
                    <FontAwesomeIcon
                      icon="fas fa-exclamation-triangle"
                      size={12}
                      color="#d0424f"
                      padding={0}
                    />
                  ) : (
                    ''
                  )
                }
                iconPosition="start"
              />
              <Tab
                label="Permissions"
                sx={{
                  color: '#fff',
                  gap: 1,
                  minHeight: { xs: '48px', md: '42px', lg: '42px' },
                }}
                {...a11yProps(1)}
                icon={
                  hasErrors && hasErrors.permissions ? (
                    <FontAwesomeIcon
                      icon="fas fa-exclamation-triangle"
                      size={12}
                      color="#d0424f"
                      padding={0}
                    />
                  ) : (
                    ''
                  )
                }
                iconPosition="start"
              />
            </Tabs>
            <Box>
              <Box>
                <ProfileTab
                  tabsValue={tabsValue}
                  index={0}
                  handleCloseModal={handleCloseModal}
                  formRef={formRef}
                  setHasErrors={setHasErrors}
                />
                <PermissionsTab
                  tabsValue={tabsValue}
                  index={1}
                  handleCloseModal={handleCloseModal}
                />
              </Box>
              <Actions
                handleCloseModal={handleCloseModal}
                formRef={formRef}
                setHasErrors={setHasErrors}
                setUserCreatedSnackbar={setUserCreatedSnackbar}
                tabsValue={tabsValue}
                refetch={refetch}
              />
            </Box>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <GeneralSnackbar status={userCreatedSnackbar} handleClose={handleCloseSnackbar} />
    </>
  );
};

export default UserPopup;
