import React, { useContext } from 'react';
import ErrorLogCard from '../containers/errorLog/errorLogCard';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ERRORLOGS } from '../utils/gql';
import { Context as StatusContext } from '../context/statusContext';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
}));

const ErrorLogs = () => {
  const classes = useStyles();
  const { state } = useContext(StatusContext);
  const [{ selectedOrg, organisations }] = useLocalStorage('userData');
  window.log(organisations);
  const { data, loading } = useQuery(GET_ERRORLOGS, {
    variables: {
      org: state.selectedOrg
        ? state.selectedOrg.id
        : selectedOrg
        ? selectedOrg.id
        : organisations[0].id,
    },
  });

  const createBugCards = (bugs) => {
    return data.getLogs.logs.map((log, i) => {
      return <ErrorLogCard log={log} key={i} />;
    });
  };
  return (
    <div className={classes.root}>
      {data ? createBugCards(data) : <Typography>No ErrorLogs</Typography>}
    </div>
  );
};

export default ErrorLogs;
