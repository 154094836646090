import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FontAwesomeIcon from '../FAIcon';
import Task from './Task';
import _ from 'lodash';
import { TaskContent } from '../../context/tasksContextTypes';

// Types
type PanelStates = {
  panel1: boolean;
  panel2: boolean;
};

type PanelKey = keyof PanelStates;

// Styled Components
const AccordionStyled = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '450px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<FontAwesomeIcon icon="fas fa-angle-right" color="white.main" />}
    {...props}
  />
))(({ theme }) => ({
  borderTop: '1px solid rgba(255, 255, 255, .05)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  maxHeight: '30vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '10px',
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#79909f',
    borderRadius: '4px',
  },
}));

// Main Component
export default function TasksTrayContent({
  tasks,
  removeTask,
}: {
  tasks: TaskContent[];
  removeTask: (task: TaskContent) => void;
}) {
  const [expanded, setExpanded] = useState<PanelStates>({ panel1: false, panel2: false });

  /*   const sortByDate = (arr: TaskContent[], key: string) => _.orderBy(arr, [key], ['desc']);

  if (tasks.length !== 0) tasks = sortByDate(tasks, 'timeStamp'); */

  useEffect(() => {
    if (tasks.length && tasks.filter((task) => task.progress === 100).length) {
      setExpanded({ panel1: true, panel2: true });
    } else if (tasks.length) {
      setExpanded((prev) => ({ ...prev, panel1: true }));
    } else if (tasks.filter((task) => task.progress === 100).length) {
      setExpanded((prev) => ({ ...prev, panel2: true }));
    }
  }, [tasks]);

  const handleChangeContent =
    (panel: PanelKey) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      let expandedState = { ...expanded };
      expandedState[panel] = !expandedState[panel];
      setExpanded({ ...expandedState });
    };

  return (
    <Box>
      <AccordionStyled
        expanded={expanded.panel1 === true}
        onChange={handleChangeContent('panel1')}
        disabled={tasks && tasks.length ? false : true}
      >
        <AccordionSummaryStyled aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="subtitle4">Current Tasks</Typography>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>
          {tasks
            .filter((task) => task.progress < 100)
            .map((task, index) => (
              <Task
                key={task.id}
                task={task}
                bgColor={index % 2 === 0 ? '#3a5463' : 'none'}
                removeTask={removeTask}
              />
            ))}
        </AccordionDetailsStyled>
      </AccordionStyled>
      <AccordionStyled
        expanded={expanded.panel2 === true}
        onChange={handleChangeContent('panel2')}
        disabled={
          tasks && tasks.filter((task) => task.progress === 100).length ? false : true
        }
      >
        <AccordionSummaryStyled aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant="subtitle4">Finished Tasks</Typography>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>
          {tasks
            .filter((task) => task.progress >= 100)
            .map((task, index) => (
              <Task
                key={task.id}
                task={task}
                bgColor={index % 2 === 0 ? '#3a5463' : 'none'}
                completed={true}
                removeTask={removeTask}
              />
            ))}
        </AccordionDetailsStyled>
      </AccordionStyled>
    </Box>
  );
}
