import { useContext } from 'react';
import { MaintenanceData, RTCContextTypes } from '../../context/RTCContextTypes';
import { Context as RTCContext } from '../../context/RTCContext';
import RTC from '../../utils/socketComm';

const useSocketList = () => {
  const {
    removeAllTaskNotification,
    removeTaskNotification,
    imgUploadFailed,
    imgUploadSuccess,
    maintenanceMode,
  } = useContext(RTCContext) as RTCContextTypes;

  const SOCKET_LIST = [
    {
      label: 'clear-notifications (All)',
      name: 'clear-notifications',
      body: '{\n  clearAll: true\n}',
      function: (data: { clearAll: boolean }) => removeAllTaskNotification(),
    },
    {
      label: 'clear-notifications',
      name: 'clear-notifications',
      body: '{\n  clearAll: true,\n  idsToClear: ""\n}',
      function: (data: { clearAll: boolean; idsToClear: string }) =>
        removeTaskNotification(data.idsToClear),
    },
    {
      label: 'image-upload-success',
      name: 'image-upload-success',
      body: '{\n  id: "",\n  taskId: ""\n}',
      function: imgUploadSuccess,
    },
    {
      label: 'image-upload-failed',
      name: 'image-upload-failed',
      body: '{\n  id: "",\n  taskId: ""\n}',
      function: imgUploadFailed,
    },
    {
      label: 'maintenance-mode',
      name: 'maintenance-mode',
      body: `{\n  message: "",\n  startDate: "${new Date().toISOString()}",\n  endDate: "${new Date().toISOString()}",\n  restart: false\n}`,
      function: (data: false | MaintenanceData) => {
        maintenanceMode(data);
      },
    },
    {
      label: 'ocr-record',
      name: 'ocr-record',
      body: '{\n recordId: "",\n  folderId: ""\n}',
      function: (data: { recordId: string; folderId: string }) => RTC.ocrRecord(data),
    },
    {
      label: 'draw-box-for-identifiers',
      name: 'draw-box-for-identifiers',
      body: '{\n  textractId: "",\n  bounds: { left: 0, top: 0, width: 0, height: 0 },\n  folderFieldId: "",\n  individual: false,\n  pageIndex: 0\n}',
    },
    {
      label: 'analyse-ocr-area',
      name: 'analyse-ocr-area',
      body: '{\n  textractId: "",\n  bounds: { left: 0, top: 0, width: 0, height: 0 },\n  folderFieldId: "",\n  individual: false,\n  pageIndex: 0\n}',
    },
    {
      label: 'validate-ocr-area',
      name: 'validate-ocr-area',
      body: '{\n  textractId: "",\n  pageIndex: 0,\n  text: "" \n }',
    },
    {
      label: 'text-on-document-count',
      name: 'text-on-document-count',
      body: '{\n  textractId: "",\n  pageIndex: 0,\n  text: ""\n }',
    },
    {
      label: 'update-user-preferences',
      name: 'update-user-preferences',
      body: '{\n  theme: "",\n  emailNotifications: false\n }',
    },
  ];

  return { socketList: SOCKET_LIST };
};

export default useSocketList;
