import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
const Th_FiltersInputs = styled(InputBase)(({ theme }) => ({
  height: 40,
  borderRadius: 0,
  backgroundColor: (theme) =>
    theme.palette.mode === 'dark'
      ? theme.palette.common.greyblueDark
      : theme.palette.common.lightblue,
  '& .MuiInputBase-input': {
    height: 25,
    borderRadius: 2,
    position: 'relative',
    backgroundColor: (theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.common.greyblueDark
        : theme.palette.common.lightblue,
    fontSize: 12,
    padding: '14px 0px 0px 14px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: (theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.greyblueDark,
    borderColor: 'transparent',
    '&:focus': {
      borderRadius: 0,
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));
export default Th_FiltersInputs;
