import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Tab, { TabProps } from '@mui/material/Tab';

const StyledTab = styled(Tab)(({ theme }) => ({
  backgroundColor: theme.palette.common.greyblueDarker,
  color: theme.palette.common.white,
  padding: '1.5rem 0',
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'start',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
  transition: '0.3s',
  '& .MuiIcon-root': {
    marginRight: '1rem',
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  '&.Mui-selected': {
    color: theme.palette.common.orange,
  },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CustomTab({
  iconPosition = 'start',
  index,
  ...props
}: { index: number } & TabProps) {
  const allProps = { iconPosition, ...props, ...a11yProps(index) };
  return <StyledTab {...allProps} />;
}
