import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import gqlRecords from '../../utils/gql/gqlRecords';
import save from '../Saving';
import { TextField, Typography, Grid } from '@mui/material';
import { createFab } from './utils';
import { makeStyles } from '@mui/styles';
import { Collection } from './Popover';
import FontAwesomeIcon from '../FAIcon';
const yup = require('yup');

interface DownloadMenuProps {
  collection: Collection[];
  styles: {
    textfield: {};
  };
  value: number;
}

type FormValues = {
  name: string;
};

const schema = yup.object().shape({
  name: yup.string().required(),
  pages: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 2,
    margin: 0,
    width: '100%',
  },
  hr: {
    display: 'flex',
    padding: '0 40px',
    justifyContent: 'space-between',
    margin: '20px 0px',
  },
  dialog: {
    padding: 20,
  },
  pageContainer: {
    marginTop: 12,
    marginBottom: 8,
  },
  textFields: {
    marginBottom: 20,
  },
  fab: {},
  helperText: {},
}));

export default function DownloadMenu({ collection, styles, value }: DownloadMenuProps) {
  const classes = useStyles();
  const [fileName, setFileName] = useState<string | boolean>(false);
  const { register, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const [getRecordsUrls] = useLazyQuery(gqlRecords.GET_URLs, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => data && save.do(data.GenerateGetURLs, collection, fileName),
  });
  const handleSaving = async (values: FormValues) => {
    let attachments: string[] = [];
    setFileName(values.name);
    collection.forEach((record) =>
      attachments.push(record.attachments[record.attachments.length - 1].name)
    );
    getRecordsUrls({
      variables: {
        keys: attachments,
        onlyLast: true,
      },
    });
  };
  const customButton = {
    color: 'primary',
    className: classes.fab,
    icon: <FontAwesomeIcon icon="fas fa-file-download" size={22} />,
    label: 'Download',
    onClick: handleSubmit(handleSaving),
  };

  return (
    <Grid container direction="column">
      <form className={classes.pageContainer}>
        <TextField
          className={classes.textFields}
          fullWidth
          label="File Name"
          type="text"
          variant="outlined"
          defaultValue={save.getNewName()}
          {...register('name')}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          sx={styles.textfield}
        />
      </form>
      <Typography variant="caption" sx={{ color: '#fff', fontWeight: 500 }}>
        The files will be downloaded as a zip file.
      </Typography>
      {createFab(customButton, 1, value)}
    </Grid>
  );
}
