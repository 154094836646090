import React, { useEffect, useState } from 'react';
import { Filter } from '../ReportingSideBar';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import {
  FolderField,
  SelectedFolder,
} from '../../../components/masterTable/masterTableTypes';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

const styles = {
  fieldInput: {
    '& .MuiTextField-root': {
      width: '100%',
      '& .MuiInputBase-root': {
        borderRadius: 0,
        color: 'common.greyblueDarker',
        '& .MuiInputBase-input': {
          textAlign: 'end',
          fontWeight: 'bold',
        },
      },
      '& .MuiInputLabel-root': {
        color: 'common.greyblueDarker',
        '&.Mui-focused': {
          color: 'common.greyblueDarker',
        },
      },
      '& .MuiSvgIcon-root': {
        color: 'common.greyblueDarker',
      },
    },
  },
  datePicker: {
    '& fieldset': { border: 'none' },
    '& .MuiInputBase-input': {
      textAlign: 'end',
      fontSize: 14,
      fontWeight: 500,
      color: 'common.greyblueDarker',
    },
    '& .MuiInputLabel-root': {
      color: 'common.greyblueDarker',
    },
    '& .MuiIconButton-root': {
      color: 'common.greyblueDarker',
    },
  },
  input: {
    border: 'none',
    '& .MuiOutlinedInput-root': {
      border: 'none',
      p: '0',
    },
    '& .MuiFormHelperText-root': {
      backgroundColor: 'common.white',
      width: '100%',
      margin: 0,
      paddingX: '10px',
    },
    '& .MuiInputBase-input': {
      textAlign: 'end',
      paddingBottom: 1.5,
      paddingTop: '16px',
      color: 'common.greyblueDarker',
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiSvgIcon-root': {
      color: 'common.greyblueDarker',
    },
  },
};

const Input = ({
  field,
  value,
  setValue,
}: {
  field: FolderField;
  value: any;
  setValue: any;
}) => {
  const INPUT_FORMAT = 'dd/MM/yyyy';
  const currencyRegex = /^\d+(\.\d{0,2})?$/;

  useEffect(() => {
    setValue(null);
  }, [field]);

  switch (field.type) {
    case 'currency':
      return (
        <TextField
          type="currency"
          placeholder="Currency field"
          fullWidth
          sx={styles.input}
          value={value || ''}
          onChange={(e) => {
            if (!currencyRegex.test(e.target.value)) return;
            setValue(e.target.value);
          }}
        />
      );
    case 'boolean':
      return (
        <FormControl fullWidth>
          <Select
            id="field-search-boolean"
            sx={styles.input}
            value={value === null ? 'placeholder' : value}
            defaultValue={'placeholder'}
            onChange={(e) => {
              setValue(e.target.value as string);
            }}
          >
            <MenuItem value={'placeholder'} disabled>
              Select an option
            </MenuItem>
            <MenuItem value={'false'}>False</MenuItem>
            <MenuItem value={'true'}>True</MenuItem>
          </Select>
        </FormControl>
      );
    case 'datetime':
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={value}
            onChange={(newValue) => newValue && setValue(newValue)}
            inputFormat={INPUT_FORMAT}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: false }}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'Select a date',
                }}
                fullWidth
                sx={styles.datePicker}
              />
            )}
          />
        </LocalizationProvider>
      );
    case 'string':
      return (
        <TextField
          type="text"
          fullWidth
          placeholder="String field"
          sx={styles.input}
          value={value || ''}
          onChange={(e) => setValue(e.target.value)}
        />
      );
    case 'multistring':
      return (
        <TextField
          type="text"
          fullWidth
          placeholder="Multistring field"
          sx={styles.input}
          value={value || ''}
          onChange={(e) => setValue(e.target.value)}
        />
      );
    case 'integer':
      return (
        <TextField
          placeholder="Integer field"
          fullWidth
          sx={styles.input}
          value={value || ''}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={(e) => {
            const is = Number(e.target.value);
            const isNotANumber = _.isNaN(is);
            if (isNotANumber) return;
            setValue(e.target.value);
          }}
        />
      );
    case 'dropdown':
      return (
        <FormControl fullWidth>
          <Select
            id="field-search-dropdown"
            fullWidth
            sx={styles.input}
            value={value === null ? 'placeholder' : value}
            defaultValue={'placeholder'}
            onChange={(e) => {
              setValue(e.target.value as string);
            }}
          >
            <MenuItem value={'placeholder'} disabled>
              Select an option
            </MenuItem>
            {field.options.map((opt) => (
              <MenuItem value={opt.value}>{opt.key}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    default:
      break;
  }
};

const FieldSearch = ({
  filter,
  selectedFolder,
}: {
  filter: Filter;
  selectedFolder: SelectedFolder | null;
}) => {
  const [selectedField, setSelectedField] = useState<FolderField | null>(() => {
    if (filter?.value?.folderFieldId && selectedFolder?.folderFields) {
      return (
        selectedFolder.folderFields.find((ff) => ff.id === filter.value.folderFieldId) ||
        null
      );
    }
    return null;
  });
  const { setValue: setFormValue, watch } = useFormContext();

  const [value, setValue] = useState<string | number | null>(
    filter?.value?.inputValue || null
  );

  useEffect(() => {
    filter && setValue(filter.value?.inputValue);
  }, [filter]);

  useEffect(() => {
    if (!filter) return;
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex((flt) => flt?.id === filter.id);
    index !== -1 &&
      setFormValue(
        `selectedTemplate.variables.filters[${index}].value.inputValue`,
        value
      );
  }, [value]);

  // useEffect(() => {
  //   if (!filter || !selectedField) return;
  //   const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
  //   let index = currentFilters.findIndex((flt) => flt?.id === filter.id);
  //   index !== -1 &&
  //     setFormValue(
  //       `selectedTemplate.variables.filters[${index}].value.folderFieldId`,
  //       selectedField.id
  //     );
  // }, [selectedField]);

  const options =
    selectedFolder?.folderFields.map((field) => ({
      label: field.name,
      id: field.id,
    })) || [];

  useEffect(() => {
    if (!filter?.value) return;
    let folderField = selectedFolder?.folderFields.find(
      (ff) => ff.id === filter?.value.folderFieldId
    );
    folderField && setSelectedField(folderField);
  }, [filter?.value]);

  return (
    <div>
      {/* <Autocomplete
        disableClearable={true}
        id="field-selector"
        options={[{ label: 'Select a field', id: null, disabled: true }, ...options]}
        value={selectedOption || { label: 'Select a field', id: null, disabled: true }}
        onChange={(e, value) => {
          let folderField = selectedFolder?.folderFields.find((ff) => ff.id === value.id);
          folderField && setSelectedField(folderField);
        }}
        isOptionEqualToValue={(option, value) => option.label === value}
        getOptionDisabled={(opt) => {
          if (opt.label === 'Select a field') return true;
          if (currentFilters.length) {
            let isSelected = currentFilters.find(
              (flt) => flt?.value?.folderFieldId === opt.id
            );
            if (isSelected) return true;
          }
          return false;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiFormHelperText-root': {
                backgroundColor: 'common.white',
                width: '100%',
                margin: 0,
                paddingX: '10px',
              },
            }}
          />
        )}
        sx={styles.fieldInput}
      /> */}
      {selectedField && <Input field={selectedField} value={value} setValue={setValue} />}
    </div>
  );
};

export default FieldSearch;
