import { setColor } from "../styles/colorScheme";
import styled from "styled-components";
export const StyledFormWrapper = styled.div`
  padding: 25px 0;
  /* rgba(36,28,21,0.5) */
  background-color: #fff;
  z-index: 2000;
  form {
    display: flex;
    margin-bottom: 35px;
    input,
    select {
      background: ${setColor.inputGrey};
      width: 100%;
      padding: 13px;
      margin: 0 0 15px;
      box-sizing: border-box;
      border: 0;
      font-size: 13px;
      color: ${setColor.lightGrey};
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
    }
    select {
      padding: 12px;
    }
    label {
      margin-bottom: 15px;
      margin-right: 20px;
      display: block;
    }
    input[type="checkbox"] {
        display: none;
    }
    input::-webkit-input-placeholder {
        color: ${setColor.veryLightGrey}
    }
    .secondaryInput {
      background: ${setColor.lightGrey};
      color: white;
    }
    .formInputHorizontal {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .formInputHorizontal input {
      margin-bottom: 7px;
      padding: 8px;
    }
    button {
      font-family: "Roboto", sans-serif;
      text-transform: uppercase;
      outline: 0;
      background: ${setColor.primary};
      width: 100%;
      border: 0;
      padding: 15px;
      color: #ffffff;
      font-size: 14px;
      -webkit-transition: all 0.3 ease;
      transition: all 0.3 ease;
      cursor: pointer;
      :hover {
        opacity: 0.8;
      }
    }
  }
  form.column {
    flex-direction: column;
    input {
      width: 60%;
    }
  }
`;
