import React, { useState, useEffect, useContext } from 'react';

// Querys
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import gqlRecords from '../../utils/gql/gqlRecords';
import {
  GET_FOLDERS,
  GET_FOLDERS_BY_ORG,
  GET_FOLDER_BY_ID,
  GET_FOLDER_BY_ID_BY_ORG,
} from '../../utils/gql/gqlFolders';
import _ from 'lodash';

// Components
import RecordResults from './SearchResults';
import WholePageMessage from '../../components/wholePageMessage';
import SearchForm from './SearchForm';
import CreationDrawer, {
  ContainerWithDrawer,
} from '../../components/documentDrawer/CreationDrawer';

// UI
import { useMediaQuery, Snackbar, Alert, AlertColor, Box } from '@mui/material';

import { useLocalStorage, modifyLocalStorageObject } from '../../hooks/useLocalStorage';
import utils from '../../utils/utils';
import { Context as StatusContext } from '../../context/statusContext';
import { AuthContextTypes, Context as AuthContext } from '../../context/authContext';
import {
  generateRecordVariables,
  generateSearchFilters,
  handleCloseSnack,
} from './searchUtils';
import {
  SelectedFolder,
  SetMessage,
} from '../../components/masterTable/masterTableTypes';
import {
  AttachmentState,
  Filter,
  Folder,
  Record,
  Variables,
  WorkflowStatusOptions,
} from './types';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { GridEditMode } from '@mui/x-data-grid';
import { checkWorkflowFilterPermissions } from './searchUtils';

export const STATUSES: WorkflowStatusOptions[] = [
  { value: 'Any', label: 'Any', permission: 'canSearchUsingAnyFilter' },
  { value: 'Unassigned', label: 'Unassigned', permission: 'canSetProcessPath' },
  { value: 'Incomplete', label: 'Incomplete', permission: 'canSetProcessPath' },
  { value: 'Complete', label: 'Complete', permission: 'canSetProcessPath' },
  { value: 'Rejected', label: 'Rejected', permission: 'canSetProcessPath' },
  {
    value: 'Assigned_To_Me',
    label: 'Assigned to me',
    permission: 'canPerformProcessStep',
  },
  {
    value: 'Complete_For_Me',
    label: 'Complete for me',
    permission: 'canPerformProcessStep',
  },
  {
    value: 'Rejected_By_Me',
    label: 'Rejected by me',
    permission: 'canPerformProcessStep',
  },
];

function SearchMain() {
  const [{ latestRecordsSearch, tableSettings, searchScreenSearch, lastRecordsFolder }] =
    useLocalStorage('userSettings');
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const [setIsLoggedIn] = useLocalStorage('isLoggedIn', true);
  const [searchScreenQuickView, setQuickView] = useState<boolean>(true);
  const [searchScreenQuickEdit] = useState<boolean>(true);
  const [userData] = useLocalStorage('userData');

  // TABLE SETTINGS
  const [limit, setLimit] = useState<number>(
    tableSettings?.search?.perPage ? tableSettings.search.perPage : 100
  );
  const [sorting, setSorting] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [snackOpen, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<{ message: string; severity: AlertColor }>({
    message: '',
    severity: 'warning',
  });

  const [workflowFilter, setWorkflowFilter] = useState<string | null>(null);
  const [folder, setFolder] = useState<SelectedFolder | null>(null); // selected folder
  const [isLoadingFolders, setIsLoadingFolders] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filter[]>([]); // search filters
  const [fieldValues, setFieldValues] = useState<any>({}); //saves the values of each input
  const [queryVariables, setVariables] = useState<Variables>({
    folderId: null,
    filters: [],
    limit: 100,
    currentPage: 1,
    sortByField: '',
    processPath: '',
  }); // saves the variables for making requests
  const [records, setRecords] = useState<Record[]>([]); // saves the records list (filtered or not)
  const [recordCount, setRecordCount] = useState<number>(0);
  const [activeRecordId, setActiveRecordId] = useState<null | string>(null); // selected record ID
  const [activeAttachment, setActiveAttachment] = useState<null | AttachmentState>(null); // selected record
  const [isFiltered, setIsFiltered] =
    useState<{ indexedFrom: string; indexedTo: string } | false>(false); // indicates whether filtering is active
  const [isResetted, setResetted] = useState<boolean>(false);
  const [fullScreenRecord, setFullScreenRecord] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const widthMatches = useMediaQuery('(min-width:1280px)');
  const { state, setScreen, setVisible, setErrorStatus, setSelectedRecord } = useContext(
    StatusContext
  ) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const isSuser = authContext.state.suser || userData.suser;
  // const [clickedRow, setClickedRow] = useState(false);

  let folderPermissions: any = utils.checkFolderPermissions(
    authContext.state.permissions,
    folder
  );

  let workflowPermissionsEnabled =
    folder &&
    folder.workFlow &&
    state?.currentOrg?.processPathEnabled &&
    folderPermissions &&
    (folderPermissions.folderPermissions?.processPathPermissions?.canPerformProcessStep ||
      folderPermissions.folderPermissions?.processPathPermissions?.canSetProcessPath);

  const [loadFolders, { data: loadedFolders }] = useLazyQuery(
    isSuser ? GET_FOLDERS_BY_ORG : GET_FOLDERS,
    {
      variables: isSuser
        ? {
            organisationId:
              state.selectedOrg && _.isObject(state.selectedOrg)
                ? state.selectedOrg?.id
                : userData.selOrg?.id,
          }
        : null,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!isSuser ? loadedFolders.Folders.length : loadedFolders.FoldersByOrg.length) {
          setIsLoadingFolders(false);
          setVisible(true);
          // if the user logs in for the first time to app, its has not data in 'lastRecordsFolder'
          if (userSettings.lastRecordsFolder === '') {
            setUserSettings(
              modifyLocalStorageObject(
                {
                  ...userSettings,
                  lastRecordsFolder: isSuser
                    ? loadedFolders.FoldersByOrg[0].id
                    : loadedFolders.Folders[0].id,
                },
                'userSettings'
              )
            );
            setFolder(
              !isSuser ? loadedFolders.Folders[0] : loadedFolders.FoldersByOrg[0]
            );
          } else {
            setIsLoadingFolders(false);
            let folderList = !isSuser
              ? loadedFolders.Folders
              : loadedFolders.FoldersByOrg;
            let exist = folderList.find(
              (folder: Folder) => folder.id === userSettings.lastRecordsFolder
            );
            // Its possible that the folder saved in localStorage doesn't exist now, maybe because it has been deleted, so we have to check if it exists, if doesn't,  we have to select the first folder and prevent errors
            setFolder(exist || folderList[0]);
          }
        }
      },
      onError: (err) => {
        if (err.graphQLErrors) {
          err.graphQLErrors.forEach(({ message }, i) => {
            if (message === 'You are not authorized!' || message === 'Invalid Token') {
              utils.clearUserData();
              setIsLoggedIn(false);
            } else {
              setErrorStatus({ triggered: true, errorInfo: err });
              console.error(err);
            }
          });
        }
      },
    }
  );

  useQuery(
    isSuser ? GET_FOLDER_BY_ID_BY_ORG : GET_FOLDER_BY_ID,
    {
      // skip: userSettings.lastRecordsFolder ? false : true, // If no data is saved, cancel the request.
      variables: isSuser
        ? {
            id: userSettings.lastRecordsFolder,
            organisationId:
              state.selectedOrg && _.isObject(state.selectedOrg)
                ? state.selectedOrg.id
                : userData.selOrg.id,
          }
        : {
            id: userSettings.lastRecordsFolder,
          },
      onCompleted: (data) => {
        if (
          (data && data.Folder && data.Folder.folder) ||
          (data && data.FolderByOrg && isSuser)
        ) {
          setFolder(isSuser ? data.FolderByOrg : data.Folder.folder);
        }
      },
      onError: (err) => {
        if (err.graphQLErrors) {
          err.graphQLErrors.forEach(({ message }, i) => {
            if (message === 'You are not authorized!' || message === 'Invalid Token') {
              utils.clearUserData();
              setIsLoggedIn(false);
            }
          });
        }
      },
    }
  );

  const [editMode, setEditMode] = useState<GridEditMode>('cell');

  const handleSetEditMode = () =>
    editMode === 'cell' ? setEditMode('row') : setEditMode('cell');

  // Fetch the records based on a selected folder and filters
  const [loadRecords, { loading, data: recordsQueryData }] = useLazyQuery(
    isSuser ? gqlRecords.FILTER_RECORDS_BY_ORG : gqlRecords.FILTER_RECORDS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        let dataName = isSuser ? 'RecordsByOrg' : 'Records';
        if (
          recordsQueryData &&
          recordsQueryData[dataName] &&
          recordsQueryData[dataName].records
        ) {
          let data = recordsQueryData[dataName].records;

          data = data.map((record: Record) => {
            let newField = {
              _typename: 'Fields',
              field: {
                _typename: 'Field',
                id: 'createdAtField',
                name: 'createdAt',
              },
              value: record.createdAt,
            };
            record.fields.push(newField);
            return record;
          });
          setRecords(data);
          setRecordCount(recordsQueryData[dataName].totalCount);

          if (isDrawerOpen) {
            // closes the drawer if the file does not exist
            let existRecord = data.find((rec: Record) => rec.id === activeRecordId);
            !existRecord && setIsDrawerOpen(false);
          }
        }
      },
      onError: (err) => {
        if (err.graphQLErrors) {
          err.graphQLErrors.forEach(({ message }, i) => {
            if (message === 'Permission Denied') {
              setRecords([]);
              setRecordCount(0);
            }
            if (message === 'You are not authorized!' || message === 'Invalid Token') {
              utils.clearUserData();
              setIsLoggedIn(false);
            }
          });
        }
      },
    }
  );
  const onSubmit = (values: any, isSearch = true) => {
    if (!folder) return;
    let valuesToSubmit;
    !values
      ? (valuesToSubmit = latestRecordsSearch[folder.id])
      : (valuesToSubmit = values);
    let filters = generateSearchFilters(valuesToSubmit, isSearch, folder);
    let folderPermissions = utils.checkFolderPermissions(
      authContext.state.permissions,
      folder
    );
    let workflowValue = checkWorkflowFilterPermissions(
      folderPermissions,
      STATUSES,
      userSettings,
      workflowFilter
    );
    if (Object.keys(fieldValues).length || workflowFilter) {
      setUserSettings(
        modifyLocalStorageObject(
          {
            ...userSettings,
            lastRecordsFolder: folder.id,
            latestRecordsSearch: { ...fieldValues },
            workflowFilter: workflowPermissionsEnabled ? workflowValue : null,
          },
          'userSettings'
        )
      );
    }

    let variables = generateRecordVariables(
      folder,
      isSuser,
      state,
      userData.selOrg,
      filters,
      limit,
      currentPage,
      sorting,
      workflowPermissionsEnabled,
      workflowValue
    );

    setVariables(variables);
    setFilters(filters);
    if (Object.entries(folder).length > 0) {
      // When search screen loads for the first time, it should not make this request since there is no folder selected.
      loadRecords({
        variables,
      });
    }
  };

  const handleSetQuickView = () => {
    setQuickView(!searchScreenQuickView);
  };

  const handleClickOnRecord = (record: Record) => {
    const recordSelected = records.filter((re) => re.id === record.id);
    if (searchScreenQuickView && !searchScreenQuickEdit) {
      setIsDrawerOpen(true);
    } else if (searchScreenQuickEdit && searchScreenQuickView) {
      setIsDrawerOpen(true);
    }

    const attachment = {
      folderId: folder?.id,
      name: recordSelected[0].attachments[recordSelected[0].attachments.length - 1].name,
    };

    setSelectedRecord(recordSelected[0]);
    setActiveRecordId(record.id);
    setActiveAttachment(attachment);
  };

  const closeDrawer = () => {
    if (fullScreenRecord) {
      setFullScreenRecord(false);
    } else {
      setActiveAttachment(null);
      setIsDrawerOpen(false);
    }
  };

  useEffect(() => {
    if (folder && folder.id) {
      setVisible(true);
      let filters = [];
      if (folder && fieldValues[folder.id]) {
        filters = generateSearchFilters(fieldValues[folder.id], true, folder);
      }
      let folderPermissions = utils.checkFolderPermissions(
        authContext.state.permissions,
        folder
      );

      let variables = generateRecordVariables(
        folder,
        isSuser,
        state,
        userData.selOrg,
        filters,
        limit,
        currentPage,
        sorting,
        workflowPermissionsEnabled,
        checkWorkflowFilterPermissions(
          folderPermissions,
          STATUSES,
          userSettings,
          workflowFilter
        )
      );
      setVariables(variables);
      if (
        latestRecordsSearch[folder.id] &&
        Object.keys(latestRecordsSearch[folder.id])?.length !== 0
      ) {
        loadRecords({ variables });
      }
    }
    if (!searchScreenQuickView) {
      setIsDrawerOpen(false);
    }
    if (!state.visible) {
      setIsLoadingFolders(true);
      loadFolders();
    }
  }, [
    folder,
    loadRecords,
    loadFolders,
    state.screen,
    state.visible,
    searchScreenQuickEdit,
    searchScreenQuickView,
  ]);
  useEffect(() => {
    setScreen('search');
  }, [state.screen]);
  useEffect(() => {
    //When loading the screen, check if there are filters stored in local storage
    if (latestRecordsSearch && lastRecordsFolder) {
      if (userSettings.workflowFilter) {
        setWorkflowFilter(userSettings.workflowFilter);
      }
      setFieldValues(latestRecordsSearch);
      onSubmit(latestRecordsSearch[lastRecordsFolder]);
    }
  }, []);

  useEffect(() => {
    if (activeRecordId) {
      const selected = records.find((el) => el.id === activeRecordId);
      setSelectedRecord(selected);
    }
  }, [records]);

  if (state.visible) {
    return (
      <>
        <ContainerWithDrawer>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              width: '100%',
              flexDirection: { xs: 'column', md: 'row', lg: 'row' },
            }}
          >
            {!isDrawerOpen && folder && Object.keys(folder).length > 0 && (
              <SearchForm
                setFieldValues={setFieldValues}
                fieldValues={fieldValues}
                limit={limit}
                currentPage={currentPage}
                sorting={sorting}
                isSuser={isSuser}
                state={state}
                selOrg={userData.selOrg}
                setVariables={setVariables}
                loadRecords={loadRecords}
                folder={folder}
                setFolder={setFolder}
                setFilters={setFilters}
                widthMatches={widthMatches}
                setResetted={setResetted}
                isResetted={isResetted}
                setIsFiltered={setIsFiltered}
                isFiltered={isFiltered}
                onSubmit={onSubmit}
                setWorkflowFilter={setWorkflowFilter}
                workflowPermissionsEnabled={workflowPermissionsEnabled}
                workflowFilter={workflowFilter}
                folderPermissions={folderPermissions}
              />
            )}
            <RecordResults
              filters={filters}
              isSuser={isSuser}
              selectedFolder={folder}
              selOrg={userData.selOrg}
              loading={loading}
              setSorting={setSorting}
              sorting={sorting}
              folderDetail={folder}
              loadRecords={loadRecords}
              latestRecordsSearch={latestRecordsSearch}
              records={records}
              recordCount={recordCount}
              setLimit={setLimit}
              limit={limit}
              setActiveRecordId={setActiveRecordId}
              setActiveAttachment={setActiveAttachment}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setQuickView={handleSetQuickView}
              editMode={editMode}
              setEditMode={handleSetEditMode}
              onClickedRow={handleClickOnRecord}
              searchScreenQuickView={searchScreenQuickView}
              defaultTableOptions={{
                quickView: searchScreenQuickView,
                edit: true,
                search: searchScreenSearch,
              }}
              exportQuery={queryVariables}
              fieldValues={fieldValues}
              notesRefetch={onSubmit}
              setIsDrawerOpen={setIsDrawerOpen}
              loadFolders={setFolder}
              workflowFilter={workflowFilter}
            />
            {isDrawerOpen && searchScreenQuickView && (
              <CreationDrawer
                goBack={closeDrawer}
                open={isDrawerOpen}
                title={fullScreenRecord ? false : 'Preview record'}
                records={records}
                attachment={activeAttachment}
                folder={folder}
                activeRecordId={activeRecordId}
                setMessage={setMessage as SetMessage}
                setSnackOpen={setSnack}
                notesRefetch={onSubmit}
                screen={'search'}
                refresh={onSubmit}
                queryVariables={queryVariables}
              />
            )}
          </Box>
        </ContainerWithDrawer>
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={(e) => handleCloseSnack(e, setSnack)}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={(e) => handleCloseSnack(e, setSnack)}
            severity={message.severity}
          >
            {message.message}
          </Alert>
        </Snackbar>
      </>
    );
  } else {
    return (
      <WholePageMessage
        isLoading={isLoadingFolders}
        message="Please Contact your Administrator of the System to gain access to
      Folders"
        title="No Folders found"
      />
    );
  }
}

export default SearchMain;
