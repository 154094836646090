import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/styles';
import Emailing from '../Emailing';
import DownloadMenu from './downloadMenu';
import PDFShareTo from '../pdfViewer/pdfShareTo';
import GeneralSnackbar, { HandleClose, StatusProps } from '../SnackBar';
import { Tooltip, Tabs, Tab, Box, styled, AppBar } from '@mui/material';
import { a11yProps, TabPanel } from './utils';
import FontAwesomeIcon from '../FAIcon';
import { Collection } from './Popover';

const StyledTabs = styled((props) => <Tabs {...props} indicatorColor="secondary" />)(
  ({ theme }) => ({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
) as typeof Tabs;

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  // marginRight: theme.spacing(1),
  color: '#fff',
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.greyblueDark,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'yellow',
  },
})) as typeof Tab;

const styles = {
  root: {
    display: 'flex',
    backgroundColor: 'common.greyblueDarker',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'end',
  },
  textfield: {
    '& .MuiInputBase-input': {
      backgroundColor: '#79909f',
      color: '#fff',
      borderRadius: '4px',
      padding: 2,
    },
    '&.Mui-focused': {
      boxShadow: `blue 0 0 0 2px`,
    },
    '& label.Mui-focused': {
      color: '#fff',
      fontWeight: 700,
      fontSize: 16,
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        borderColor: 'rgb(232, 245, 255)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(232, 245, 255)',
      },
    },
    '.MuiInputLabel-root': {
      color: '#fff',
    },
  },
  tabs: {
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
  appBar: {
    backgroundColor: 'common.greyblueDark',
  },
  fab: {
    position: 'absolute',
    bottom: 16,
    right: 16,
  },
  arrow: {
    marginRight: 82,
    alignSelf: 'right',
    height: 0,
    width: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #0B72B9',
  },
  body: {
    width: '100%',
  },
};

interface ShareMenuProps {
  collection: Collection[];
  handleSaving: () => void;
  isShareMenuOpen: boolean;
}

const ShareMenu = ({ collection, handleSaving, isShareMenuOpen }: ShareMenuProps) => {
  const theme: { direction: string } = useTheme();
  const [snackbarStatus, setSnackbarStatus] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'warning',
  });
  const [value, setValue] = useState<number>(0);
  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    setSnackbarStatus({
      status: false,
      snackbarMessage: '',
      severity: snackbarStatus.severity,
    });
  };
  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) =>
    setValue(newValue);

  const handleChangeIndex = (index: number) => setValue(index);

  return (
    <Box
      sx={{
        ...styles.root,
        opacity: isShareMenuOpen ? '1' : '0',
        transition: 'all .3s ease-in-out',
        transform: isShareMenuOpen ? 'translate(-450px)' : 'translate(0)',
        minWidth: '450px',
        maxHeight: isShareMenuOpen ? 'auto' : '10px',
      }}
    >
      <AppBar position="static" sx={styles.appBar}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <StyledTab
            icon={
              <Tooltip title="Email" arrow>
                <span>
                  <FontAwesomeIcon icon="fa fa-envelope" size={20} />
                </span>
              </Tooltip>
            }
            {...a11yProps(0)}
          />
          <StyledTab
            icon={
              <Tooltip title="Download" arrow>
                <span>
                  <FontAwesomeIcon icon="fa fa-download" size={20} />
                </span>
              </Tooltip>
            }
            {...a11yProps(1)}
          />

          <StyledTab
            icon={
              <Tooltip title="Share Link" arrow>
                <span>
                  <FontAwesomeIcon icon="fa fa-link" size={18} />
                </span>
              </Tooltip>
            }
            {...a11yProps(2)}
          />
        </StyledTabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={styles.body}
      >
        <TabPanel value={value} index={0}>
          <Emailing
            setSnackbar={setSnackbarStatus}
            collection={collection}
            styles={styles}
            value={value}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DownloadMenu collection={collection} styles={styles} value={value} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PDFShareTo
            setSnackbar={setSnackbarStatus}
            collection={collection}
            styles={styles}
            value={value}
            context='collectionBin'
          />
        </TabPanel>
      </SwipeableViews>
      <GeneralSnackbar status={snackbarStatus} handleClose={handleCloseSnackbar} />
    </Box>
  );
};

export default ShareMenu;
