import utils from '../../../utils/utils';
import { Permissions } from '../userProfile/components/UserPermissionsTab/permissionsTypes';
import { initialPermissions } from '../userProfile/components/UserPermissionsTab/structure';
interface State {
  formValues: {
    firstName: string;
    lastName: string;
    emailaddress: string;
    password: string;
    role: string;
    title: string;
    permissions: Permissions;
    active: true;
    changePW: false;
    avatar: string;
    mobilenumber: number;
  };
  errors: any[];
}

export type FormContextTypes = {
  state: State;
  setErrorForm: (error: { value: any }) => void;
  updateForm: (field: { field: string; value: any }) => void;
};

const formReducer = (state: any, action: any) => {
  let { field, value } = action.payload;
  switch (action.type) {
    case 'updateForm':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [field]: value,
        },
      };
    case 'setErrorForm':
      return {
        ...state,
        errors: value,
      };
    default:
      break;
  }
};

const updateForm = (dispatch: any) => async (data: any) => {
  try {
    dispatch({ type: 'updateForm', payload: data });
  } catch (error) {}
};

const setErrorForm = (dispatch: any) => async (data: any) => {
  try {
    dispatch({ type: 'setErrorForm', payload: data });
  } catch (error) {}
};

export const { Provider, Context } = utils.createDataContext(
  formReducer,
  { updateForm, setErrorForm },
  {
    formValues: {
      firstName: '',
      lastName: '',
      emailaddress: '',
      password: '',
      role: 'USER',
      title: 'None',
      permissions: initialPermissions,
      active: true,
      changePW: false,
      avatar: '',
      mobilenumber: '',
    },
    errors: [],
  }
);
