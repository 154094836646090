import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import FontAwesomeIcon from '../../../components/FAIcon';

interface FilterItemContainerProps {
  id: string;
  label: string;
  children: JSX.Element;
  expandedFilters: string[];
  setExpandedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  handleDelete: (id: string) => void;
}

const styles = {
  summary: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: 'common.greyblueDark',
    color: 'common.white',
    minHeight: '40px',
    height: '40px',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    '& .Mui-expanded': {
      overflow: 'hidden',
    },
  },
  deleteButton: {
    color: 'white.main',
    paddingX: '15px',
    '&:hover': {
      color: 'common.negative',
      backgroundColor: 'transparent',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
  },
};

const FilterItemContainer = ({
  identifier,
  id,
  label,
  children,
  expandedFilters,
  setExpandedFilters,
  handleDelete,
}: FilterItemContainerProps) => {
  const toggleExpanded = () => {
    if (expandedFilters.includes(id)) {
      setExpandedFilters(expandedFilters.filter((el) => el !== id));
    } else {
      setExpandedFilters((current) => [...current, id]);
    }
  };

  return (
    <>
      <Accordion
        expanded={expandedFilters.includes(id)}
        disableGutters
        sx={{ borderRadius: '5px' }}
      >
        <AccordionSummary
          expandIcon={
            <FontAwesomeIcon icon="fas fa-caret-down" size="16px" color="#fff" />
          }
          aria-controls={`${id}-filter`}
          id={`${id}-filter`}
          onClick={toggleExpanded}
          sx={styles.summary}
        >
          <Typography fontWeight={500} sx={{ color: 'common.white' }}>
            {label}
          </Typography>
          <Tooltip title="Delete" arrow>
            <IconButton
              sx={styles.deleteButton}
              aria-label="Delete filter"
              onClick={() => handleDelete(id, identifier)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash" size={12} />
            </IconButton>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'common.lightblue', padding: 0 }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FilterItemContainer;
