const response = {
  records: [
    {
      action: 'deleted',
      folder: {
        name: 'folder1',
      },
      record: {
        id: '123192391237',
        createdBy: {
          firstName: 'Lucas',
          lastName: 'Cubilla',
        },
        fields: [
          {
            value: '123',
            field: {
              name: 'PODNumber',
              mainfield: true,
            },
          },
          {
            value: '2345',
            field: {
              name: 'PODNumber',
              mainfield: false,
            },
          },
        ],
      },
    },
    {
      action: 'scanned',
      folder: {
        name: 'folder2',
      },
      record: {
        id: '23452435',
        createdBy: {
          firstName: 'Leandro',
          lastName: 'Palacios',
        },
        fields: [
          {
            value: '52351',
            field: {
              name: 'PODNumber',
              mainfield: true,
            },
          },
        ],
      },
    },
    {
      action: 'indexed',
      folder: {
        name: 'someName',
      },
      record: {
        id: '534562346',
        createdBy: {
          firstName: 'Oscar',
          lastName: 'Hasengruber',
        },
        fields: [
          {
            value: '36564',
            field: {
              name: 'PODNumber',
              mainfield: true,
            },
          },
        ],
      },
    },
    {
      action: 'scanned',
      folder: {
        name: 'name',
      },
      record: {
        id: '3576243452',
        createdBy: {
          firstName: 'Eoin',
          lastName: 'Melly',
        },
        fields: [
          {
            value: '356354',
            field: {
              name: 'PODNumber',
              mainfield: true,
            },
          },
        ],
      },
    },
    {
      action: 'indexed',
      folder: {
        name: 'folder3',
      },
      record: {
        id: '713423414351',
        createdBy: {
          firstName: 'Paul',
          lastName: 'Mcinerney',
        },
        fields: [
          {
            value: '62345',
            field: {
              name: 'PODNumber',
              mainfield: true,
            },
          },
        ],
      },
    },
  ],
};

export { response };
