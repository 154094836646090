import { useContext, useMemo } from 'react';
import { Context as RTCContext } from '../../context/RTCContext';
import { RTCContextTypes } from '../../context/RTCContextTypes';
import NotificationPopup, { Notification } from './NotificationPopup';
import utils from '../../utils/utils';

const NotificationPopupHandler = () => {
  const {
    state: { notifications: notificationsState },
  } = useContext(RTCContext) as RTCContextTypes;

  const notifications = useMemo(() => {
    let notifications: Notification[] = [];
    if (notificationsState.tasksPopup.length) {
      let res = notificationsState.tasksPopup.filter(
        (notification) => notification.first
      );
      notifications = [...res, ...notifications];
    }
    if (notificationsState.comm.length) {
      let res = notificationsState.comm.filter((notification) => notification.first);
      notifications = [...res, ...notifications];
    }

    return notifications;
  }, [notificationsState.comm, notificationsState.tasksPopup]);

  if (notifications.length) {
    return notifications.map((noti, i) => {
      if (noti.read) return null;

      const recordInfo = utils.getNotificationRecordInfo(noti);

      return (
        <NotificationPopup
          key={'notification_' + i}
          pop={true}
          data={noti}
          recordInfo={recordInfo}
          index={i}
        />
      );
    });
  } else {
    return null;
  }
};

export default NotificationPopupHandler;
