import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { Permissions, PermissionsGroupProps } from './permissionsTypes';
import PermissionsGroupCard from './PermissionsGroupCard';
import {
  DEL_PERMISSION_TEMPLATE_BY_ID,
  GET_PERMISSION_TEMPLATES,
  APPLY_TEMPLATE_TO_USER,
} from '../../../../../utils/gql/gqlPermissions';
import { useMutation, useQuery } from 'react-apollo';
import SearchInput from '../../../../../components/toolbarScreen/SearchInput';

const PermissionsGroup = ({
  user,
  generateSubmitButton,
  isLoading,
  handleChangeTemplateName,
  setSnackbar,
  setAssignToUser,
  templateName,
  handleSubmit,
  setPermissionsTemplates,
  permissionsTemplates,
  setUserHasTemplate,
  userHasTemplate,
  onRemoveTemplateToUser,
  setGlobalState,
  setUserData,
  handleCloseModal,
  handleOpenModal,
  openModal,
  assignToUser,
  updatePermissionsValues,
}: PermissionsGroupProps) => {
  const [searchQuery, setSearchQuery] = useState<null | string>(null);
  const [error, setError] = useState({ status: false, message: '' });
  const [deletePermissionTemplateById] = useMutation(DEL_PERMISSION_TEMPLATE_BY_ID, {
    refetchQueries: [{ query: GET_PERMISSION_TEMPLATES }, 'permissionTemplates'],
  });
  const [applyTemplateToUser] = useMutation(APPLY_TEMPLATE_TO_USER);
  const { data } = useQuery(GET_PERMISSION_TEMPLATES, {
    onCompleted: (data) => {
      if (data) setPermissionsTemplates(data.permissionTemplates.permissionsTemplates);
    },
  });

  const onDeleteTemplate = async (id: string) => {
    if (user && user.id) {
      deletePermissionTemplateById({
        variables: {
          templateId: id,
        },
      })
        .then(({ data }) => {
          let deletedPermission = data.deletePermissionTemplateById.permissionsTemplate;
          setSnackbar({
            status: true,
            snackbarMessage: 'Template deleted successfully',
            severity: 'warning',
          });
          let filtered = permissionsTemplates.filter(
            (permission: Permissions) => permission.id !== deletedPermission.id
          );
          setPermissionsTemplates([...filtered]);
        })
        .catch((error) => {
          // window.log(error);
          setSnackbar({
            status: true,
            snackbarMessage: 'Template can not added successfully',
            severity: 'error',
          });
        });
    } else {
      let deletedPermission = data.deletePermissionTemplateById.permissionsTemplate;
      let filtered = permissionsTemplates.filter(
        (permission: Permissions) => permission.id !== deletedPermission.id
      );
      setPermissionsTemplates([...filtered]);
    }
  };

  const onApplyTemplateToUser = async (permission: Permissions) => {
    updatePermissionsValues(permission);
    if (user && user.id) {
      applyTemplateToUser({
        variables: {
          templateId: permission.id,
          userId: user?.id,
        },
      })
        .then(({ data }) => {
          setSnackbar({
            status: true,
            snackbarMessage: `Template added to ${user?.firstName} ${user?.lastName} successfully`,
            severity: 'success',
          });
          setUserHasTemplate(permission);
          setGlobalState(permission);
          setUserData({ ...user, permissions: permission });
        })
        .catch((error) => {
          setSnackbar({
            status: true,
            snackbarMessage: 'Template can not added successfully',
            severity: 'error',
          });
        });
    } else {
      setUserHasTemplate(permission);
      setGlobalState(permission);
      setSnackbar({
        status: true,
        snackbarMessage: `Template added successfully`,
        severity: 'success',
      });
    }
  };

  const handleSearch = (e: Event) => {
    const { value } = e.target as HTMLInputElement;
    setSearchQuery(value as React.SetStateAction<string | null>);
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let hasSameName = permissionsTemplates.some(
      (permission) => permission.templateName === templateName
    );
    if (hasSameName) {
      setError({
        status: true,
        message: 'Template name already exists. Please try with another one.',
      });
    } else {
      handleSubmit(e, 'template');
    }
  };

  const filteredTemplates = (templates: Permissions[], query: string) => {
    return templates.filter((permission) => {
      return permission.templateName?.toLowerCase().includes(query.toLowerCase());
    });
  };

  useEffect(() => {
    if (!searchQuery && data) {
      setPermissionsTemplates(data.permissionTemplates.permissionsTemplates);
    } else if (searchQuery) {
      let filtered = filteredTemplates(
        data.permissionTemplates.permissionsTemplates,
        searchQuery
      );
      setPermissionsTemplates(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <>
      <Box
        sx={{
          height: '30%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'common.greyblueDarker',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderInlineStart: '3px solid #cea323',
            padding: '5px 15px',
            flex: 1.2,
          }}
        >
          <Typography variant="caption" color="WHITE">
            TEMPLATES
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box>
              <SearchInput
                setSearchQuery={setSearchQuery}
                handleSearch={handleSearch}
                margin="0"
                height="23px"
                buttonSize={'12px'}
                inputFontSize={12}
              />
            </Box>
            {generateSubmitButton(
              'Save as template',
              isLoading.template,
              'template',
              'button',
              handleOpenModal
            )}
          </Box>
        </Box>

        <Stack
          direction="row"
          spacing={2}
          flex={10}
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            padding: '0 30px',
            overflowX: 'auto',
            maxWidth: '100%',
            '&::-webkit-scrollbar': { height: '5px' },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#79909f',
              borderRadius: '4px',
            },
          }}
        >
          {permissionsTemplates.length > 0 &&
            permissionsTemplates.map((permission) => (
              <PermissionsGroupCard
                key={permission.templateName}
                onDeleteTemplate={onDeleteTemplate}
                onApplyTemplateToUser={onApplyTemplateToUser}
                permission={permission}
                userHasTemplate={userHasTemplate}
                onRemoveTemplateToUser={onRemoveTemplateToUser}
              />
            ))}
        </Stack>
      </Box>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        BackdropProps={{ sx: { backgroundColor: 'rgb(0 0 0 / 22%)' } }}
      >
        <DialogTitle sx={{ width: '350px', paddingLeft: '20px' }}>
          Template name
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ padding: '0 20px' }}>
            <TextField
              autoFocus
              error={error.status}
              helperText={error.status && error.message}
              margin="dense"
              label="Enter template name"
              onChange={(e) => {
                if (error.status) setError({ status: false, message: '' });
                handleChangeTemplateName(e);
              }}
              type="text"
              fullWidth
              required={true}
              name="input-template"
              variant="standard"
              sx={{
                '& label.Mui-focused': { color: 'common.greyblueDarker' },
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'common.greyblueDarker',
                },
                '& .MuiFormHelperText-root': { fontSize: 11 },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '30px',
            }}
          >
            <Typography variant="caption">
              Assign this template to {user?.firstName} {user?.lastName}
            </Typography>
            <Checkbox
              onChange={(e) => setAssignToUser(e.target.checked)}
              checked={assignToUser}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            size="small"
            variant="outlined"
            sx={{ p: '2px', fontSize: 12 }}
          >
            Cancel
          </Button>
          <Button
            disabled={templateName.length === 0 ? true : false}
            variant="contained"
            onClick={(e) => handleSave(e)}
            name="save-template"
            size="small"
            sx={{
              backgroundColor: 'common.greyblueDarker',
              '&:hover': { backgroundColor: 'common.greyblueDark' },
              p: '2px',
              fontSize: 12,
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PermissionsGroup;
