import React, { useEffect, useContext } from 'react';
import { GET_FOLDERS, GET_FOLDERS_BY_ORG } from '../../utils/gql/gqlFolders';
import { useQuery } from '@apollo/react-hooks';
import InputLabel from '@mui/material/InputLabel';
import utils from '../../utils/utils';
import { Context as StatusContext } from '../../context/statusContext';
import { AuthContextTypes, Context as AuthContext } from '../../context/authContext';
import ErrorHandler from '../../utils/errorHandler';
import { useLocalStorage, modifyLocalStorageObject } from '../../hooks/useLocalStorage';
import Th_InputBase from '../../components/themedComponents/Th_InputBase';
import { common } from '@mui/material/colors';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { Folder } from './types';
import _ from 'lodash';
import { db } from '../../utils/indexedDB/indexeddb';
import { Paper, MenuItem, Select, Box } from '@mui/material';
import { SelectedFolder } from '../../components/masterTable/masterTableTypes';

interface FolderSelectorProps {
  title: string;
  handleChangeFolder: (selected: SelectedFolder) => void;
  selectedFolder: Folder | { id?: string } | null;
  setUserSettings: (settings: any) => void;
  userSettings: any;
  filter?: (folders: SelectedFolder[]) => SelectedFolder[]; // Custom function where we can filter folders
}

const FolderSelector = ({
  title,
  handleChangeFolder,
  selectedFolder,
  setUserSettings = () => {},
  userSettings,
  filter,
}: FolderSelectorProps) => {
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const [setIsLoggedIn] = useLocalStorage('isLoggedIn', true);
  const isSuser = suser === undefined ? authContext.state.suser : suser;
  const { state, setSelectedContextFolder, setVisible } = useContext(
    StatusContext
  ) as StatusContextTypes;
  const { data, loading } = useQuery(isSuser ? GET_FOLDERS_BY_ORG : GET_FOLDERS, {
    fetchPolicy: 'cache-and-network',
    variables: isSuser
      ? {
          organisationId:
            state.selectedOrg && _.isObject(state.selectedOrg)
              ? state.selectedOrg.id
              : selOrg.id,
        }
      : null,
    onError: (err) => {
      if (err.graphQLErrors) {
        err.graphQLErrors.forEach(({ message }) => {
          if (message === 'You are not authorized!' || message === 'Invalid Token') {
            utils.clearUserData();
            setIsLoggedIn(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (data) {
      let folders = isSuser ? data.FoldersByOrg : data.Folders;
      utils.saveDataToIndexedDB(folders);
    }
  }, [data, selectedFolder, isSuser]);

  const compareFolderNames = (a: Folder, b: Folder) => {
    const name1 = a.name.toLowerCase();
    const name2 = b.name.toLowerCase();
    if (name1 > name2) return 1;
    if (name1 < name2) return -1;
    return 0;
  };

  const displayFolders = () => {
    let folders = isSuser === true ? data.FoldersByOrg : data.Folders;
    folders = filter ? filter(folders) : folders;
    return folders.sort(compareFolderNames).map((folder: Folder) => {
      return (
        <MenuItem key={folder.id} value={folder.id}>
          {folder.name}
        </MenuItem>
      );
    });
  };
  const selectFolder = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    let folders = isSuser ? data.FoldersByOrg : data.Folders;
    const folderId = event.target.value;
    let folder = folders.filter((f: Folder) => f.id === folderId);
    folder = utils.addCreatedAtField(folder[0]);
    let contextFolder: any = {};
    contextFolder[state.screen] = folder;
    setSelectedContextFolder(contextFolder);
    modifyLocalStorageObject(folder, 'selFolder');
    handleChangeFolder(folder);
  };
  useEffect(() => {
    if (!selectedFolder && !loading && !data) {
      setVisible(false);
    } else if (data) {
      setVisible(true);
      if (
        selectedFolder &&
        Object.keys(selectedFolder).length !== 0 &&
        selectedFolder.constructor === Object
      ) {
        let folders = isSuser ? data.FoldersByOrg : data.Folders;

        let newSelectedFolder = folders.filter((f: Folder) => f.id === selectedFolder.id);
        if (newSelectedFolder.length !== 0) {
          newSelectedFolder = utils.addCreatedAtField(newSelectedFolder[0]);
          // setSelectedFolder({ ...newSelectedFolder });
          // setUserSettings({ ...userSettings, lastIndexedFolder: { ...newSelectedFolder } });
          switch (state.screen) {
            case 'indexing':
              setUserSettings(
                modifyLocalStorageObject(
                  {
                    ...userSettings,
                    lastIndexedFolder: newSelectedFolder ? newSelectedFolder.id : '',
                  },
                  'userSettings'
                )
              );
              break;
            case 'search':
              setUserSettings(
                modifyLocalStorageObject(
                  {
                    ...userSettings,
                    lastRecordsFolder: newSelectedFolder ? newSelectedFolder.id : '',
                  },
                  'userSettings'
                )
              );
              break;
            case 'workflow':
              setUserSettings(
                modifyLocalStorageObject(
                  { ...userSettings, lastWorkflowFolder: newSelectedFolder.id },
                  'userSettings'
                )
              );
              break;
            default:
              setUserSettings(
                modifyLocalStorageObject(
                  { ...userSettings, lastReportFolder: newSelectedFolder.id },
                  'userSettings'
                )
              );
              break;
          }

          modifyLocalStorageObject({ ...newSelectedFolder }, 'selFolder');
          let contextFolder: any = {};
          contextFolder[state.screen] = newSelectedFolder;
          setSelectedContextFolder(contextFolder);
        }
      }
    }
  }, [data, suser, selectedFolder]);

  const getSelectedOption = () => {
    if (!userSettings) return '';
    switch (state.screen) {
      case 'indexing':
        return userSettings && userSettings.lastIndexedFolder
          ? userSettings.lastIndexedFolder
          : '';
      case 'search':
        return userSettings && userSettings.lastRecordsFolder
          ? userSettings.lastRecordsFolder
          : '';
      case 'workflow':
        return userSettings && userSettings.lastWorkflowFolder
          ? userSettings.lastWorkflowFolder
          : '';
      default:
        return userSettings && userSettings.lastReportFolder
          ? userSettings.lastReportFolder
          : '';
    }
  };
  return (
    <Paper
      sx={{
        background: 'none',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? 'common.greyblueDarker' : 'common.greyblueDark',
        color: 'common.white',
      }}
      elevation={0}
    >
      <ErrorHandler>
        <Box sx={{ width: '100%' }}>
          <InputLabel
            sx={{
              height: '32px',
              color: common.white,
              p: '10px',
              fontSize: 11,
              fontWeight: '400',
              textTransform: 'uppercase',
            }}
            htmlFor="folder-name-select-label"
          >
            {title}
          </InputLabel>
          <Select
            fullWidth
            inputProps={{
              name: 'folderName',
              id: 'folder-name-select-label',
            }}
            labelId="folder-name-select-label"
            value={getSelectedOption()}
            onChange={(e) => selectFolder(e)}
            input={<Th_InputBase />}
            sx={{
              '& .MuiSelect-icon': {
                color: 'common.white',
              },
              '& .MuiSelect-select': {
                height: '20px!important',
              },
            }}
          >
            {data ? displayFolders() : <MenuItem value={''}>Loading folders...</MenuItem>}
          </Select>
        </Box>
      </ErrorHandler>
    </Paper>
  );
};

export default FolderSelector;
