import { useMutation } from '@apollo/react-hooks';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import FontAwesomeIcon from '../components/FAIcon';
import GeneralSnackbar, { StatusProps } from '../components/SnackBar';
import { Context as RTCContext } from '../context/RTCContext';
import { RTCContextTypes } from '../context/RTCContextTypes';
import { Context as StatusContext } from '../context/statusContext';
import { StatusContextTypes } from '../context/statusContextTypes';
import { Context as tasksContext } from '../context/tasksContext';
import { TasksContextTypes } from '../context/tasksContextTypes';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { LOGOUT } from '../utils/gql';
import { SET_PW } from '../utils/gql/gqlUsers';
import { clearIndexedDB } from '../utils/indexedDB/clearIndexedDB';
import utils from '../utils/utils';
import Image from '../images/form-image.png';
import Background from '../images/background-blue.webp';

interface FormInputs {
  password: string;
}

const Welcome = () => {
  const passwordCheck = new RegExp(
    '(?=.*?[A-Z])(?=.*?[a-z])(?=.{2,}?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}'
  );
  const [snackbar, setSnackbar] = React.useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'success',
  });
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage('isLoggedIn');
  const [showPassword, setShowPassword] = useState(false);
  const [pass, setPass] = useState('');
  const [logoutUser] = useMutation(LOGOUT);
  const history = useHistory();
  const { clearTasksNotifications } = useContext(RTCContext) as RTCContextTypes;
  const { clearTasks } = useContext(tasksContext) as TasksContextTypes;
  const { setVisible } = useContext(StatusContext) as StatusContextTypes;
  const [setPwMutation] = useMutation(SET_PW);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<FormInputs>();

  const handleChangePw = (e: React.ChangeEvent<HTMLInputElement>) => {
    let password = e.target.value;
    setPass(e.target.value);
    if (!passwordCheck.test(password)) {
      setError('password', {
        type: 'manual',
        message:
          'The Password must contain a minimum of 8 characters, at least one upper case letter, one lower case letter, two numbers and one special character.',
      });
    } else {
      setError('password', {});
    }
  };
  const logout = async () => {
    logoutUser()
      .then(({ data }) => {
        let userData = window.localStorage.getItem('userData');
        let currentStorage = JSON.parse(userData!);
        if (currentStorage.suser) {
          utils.clearUserData();
          history.push('/');
          clearIndexedDB();
          clearTasksNotifications();
          clearTasks();
          setVisible(false);
          window.location.reload();
        } else {
          utils.clearUserData();
          window.localStorage.setItem('isLoggedIn', 'false');
          history.push('/');
          clearIndexedDB();
          clearTasksNotifications();
          clearTasks();
          setVisible(false);
          window.location.reload();
        }
      })
      .catch((e) => console.log(e));
  };

  const handleCloseSnackbar = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ status: false, snackbarMessage: '', severity: 'success' });
  };

  const changePw = () => {
    if (!passwordCheck.test(pass)) {
      setError('password', {
        type: 'manual',
        message:
          'The Password must contain a minimum of 8 characters, at least one upper case letter, one lower case letter, two numbers and one special character.',
      });
    } else {
      setPwMutation({
        variables: {
          password: pass,
        },
      })
        .then(({ data }) => {
          if (data.userSetPassword.success) {
            setIsLoggedIn(true);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  function Copyright() {
    return (
      <Typography
        variant="body2"
        sx={{
          // color: (theme) =>
          //   theme.palette.mode === 'dark'
          //     ? theme.palette.common.white
          //     : theme.palette.common.blue,
          // '& > :visited': {
          //   color: (theme) =>
          //     theme.palette.mode === 'dark'
          //       ? theme.palette.common.white
          //       : theme.palette.common.blue,
          // },
          color: 'common.white',
          '& > :visited': {
            color: 'common.white',
          },
        }}
        align="center"
      >
        {'Copyright © '}
        <Link color="inherit" href="https://www.DataCapture.ie">
          www.DataCapture.ie
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  if (isLoggedIn === 'pw') {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.black.main
              : theme.palette.common.white,
          backgroundImage: `url(${Background})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          gap: '20px',
        }}
      >
        <Typography variant="button" sx={{ color: 'common.white', fontSize: 30 }}>
          Welcome to DataCapture
        </Typography>
        <Paper
          sx={{
            display: 'flex',
            width: '70%',
            height: { xs: '50%', md: '60%', lg: '60%' },
          }}
        >
          <Box
            sx={{
              flex: { xs: 6, md: 8, lg: 10 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Paper
              sx={{
                width: '90%',
                height: { xs: '100%', md: '80%', lg: '80%' },
                position: 'relative',
                padding: '5px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              elevation={20}
            >
              <Stack
                flex={{ xs: 1, md: 0.4 }}
                justifyContent="center"
                alignItems="center"
                gap={6}
                sx={{ width: '80%' }}
              >
                <Stack gap={1}>
                  <Typography
                    component="h1"
                    variant="button"
                    align="center"
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.common.white
                          : theme.palette.common.blue,
                      fontSize: 16,
                    }}
                  >
                    This is your first log in
                  </Typography>
                  <Typography
                    align="center"
                    variant="button"
                    sx={{ margin: '10px 0', fontSize: 11 }}
                  >
                    Please set your new password
                  </Typography>
                </Stack>
                <form
                  style={{
                    width: '100%', // Fix IE 11 issue.
                    maxWidth: '500px',
                    marginTop: '10px',
                  }}
                  noValidate
                  onSubmit={handleSubmit(changePw)}
                >
                  <Stack gap={1}>
                    <TextField
                      {...register('password', { required: true })}
                      sx={{
                        marginRight: 20,
                        '& input:-webkit-autofill': {
                          boxShadow: 'none',
                        },
                      }}
                      fullWidth
                      id="password-input"
                      label="Password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      value={pass}
                      autoComplete="current-password"
                      onChange={handleChangePw}
                      variant="standard"
                      error={errors && errors.password?.message ? true : false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ top: '-9px', color: 'common.greyblueDarker' }}
                              size={'small'}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon="fas fa-eye" size="18px" />
                              ) : (
                                <FontAwesomeIcon icon="fas fa-eye-slash" size="18px" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.password && errors.password.message ? (
                      <Typography
                        sx={{ fontWeight: 'bold', color: 'red', fontSize: '12px' }}
                      >
                        {errors.password.message}
                      </Typography>
                    ) : (
                      ''
                    )}

                    {errors.password && !errors.password.message ? (
                      <Typography
                        sx={{ fontWeight: 'bold', color: 'green', fontSize: '12px' }}
                      >
                        Your password is strong enough
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Stack>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      marginTop: '35px',
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.common.blue
                          : theme.palette.common.blue,
                      color: 'common.white',
                      '&:hover': {
                        opacity: 0.9,
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'dark'
                            ? theme.palette.common.secondblue
                            : theme.palette.common.blue,
                      },
                    }}
                  >
                    Save
                  </Button>

                  <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
                  <Button
                    type="button"
                    startIcon={<FontAwesomeIcon icon={'fas fa-arrow-left'} />}
                    onClick={() => logout()}
                    variant="text"
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'dark' ? 'common.orange' : 'common.blue',
                      alignSelf: 'flex-start',
                      marginTop: '25px',
                    }}
                  >
                    Go back
                  </Button>
                </form>
              </Stack>
            </Paper>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex', lg: 'flex' },
              width: '100%',
              height: '100%',
              backgroundImage: `url(${Image})`,
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              borderRadius: '5px',
              flex: { xs: 8, md: 10, lg: 14 },
            }}
          ></Box>
        </Paper>
        <Box>
          <Copyright />
        </Box>
      </Stack>
    );
  } else {
    return <Redirect to="/dash/search" />;
  }
};

export default Welcome;
