import React, { useContext } from 'react';

// mui
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// App components
import FieldMainInputs from './FieldMainInputs';
import FieldSettings from './FieldSettings';
import FieldDropdownOptions from './FieldDropdownOptions';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { FieldEditViewProps } from '../../types';
import { Context as StatusContext } from '../../../../context/statusContext';
import { StatusContextTypes } from '../../../../context/statusContextTypes';

// Styled components
const styles = {
  accordion: {
    boxShadow: 'none',
    borderRadius: '4px',
    overflow: 'hidden',
  },
};

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="15p" />}
    {...props}
  />
))(({ theme }) => ({
  height: '52px',
  '& .MuiTypography-root': {
    color: theme.palette.common.white,
    fontWeight: 500,
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.common.lightblue,
  },
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.common.greyblueDark
      : theme.palette.common.greyblueDark,
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
})) as typeof AccordionSummary;

export default function FieldEditView({
  setFieldUpdates,
  fieldUpdates,
  setSelectedField,
  selectedField,
  updateField,
  folder
}: FieldEditViewProps) {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const { state } = useContext(StatusContext) as StatusContextTypes;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Stack
      spacing={1}
      bgcolor={state.paletteMode === 'light' ? 'common.white' : 'transparent'}
      padding="20px"
      maxWidth="500px"
      marginTop="20px"
      marginLeft="20px"
    >
      <Accordion
        disableGutters
        sx={styles.accordion}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <StyledAccordionSummary
          expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="15px" />}
          aria-label="Expand"
          aria-controls="-content"
          id="-header"
        >
          <Typography>Field</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <FieldMainInputs
            fieldUpdates={fieldUpdates}
            setFieldUpdates={setFieldUpdates}
            setSelectedField={setSelectedField}
            selectedField={selectedField}
            folder={folder}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        sx={styles.accordion}
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <StyledAccordionSummary
          expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="15px" />}
          aria-label="Expand"
          aria-controls="-content"
          id="-header"
        >
          <Typography>Settings</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <FieldSettings
            setFieldUpdates={setFieldUpdates}
            fieldUpdates={fieldUpdates}
            setSelectedField={setSelectedField}
            selectedField={selectedField}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        sx={styles.accordion}
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        disabled={selectedField && selectedField.type === 'dropdown' ? false : true}
      >
        <StyledAccordionSummary
          expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="15px" />}
          aria-label="Expand"
          aria-controls="-content"
          id="-header"
        >
          <Typography>Dropdown Options</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          {selectedField && selectedField.type === 'dropdown' && (
            <FieldDropdownOptions
              options={selectedField.options}
              fieldUpdates={fieldUpdates}
              selectedField={selectedField}
              setSelectedField={setSelectedField}
              updateField={updateField}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
