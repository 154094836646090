import { fabric } from 'fabric';
import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { BoundingBoxInput } from './OCRTemplateSetup/types';

const FabricContext = createContext<FabricContextValue>({} as FabricContextValue);

export const useFabricCanvas = () => {
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const [objects, setObjects] = useState<fabric.Object[]>([]);
  const [canDraw, setCanDraw] = useState(false);
  const [selectedObject, setSelectedObject] = useState('');
  const [ocrTemplateFolderField, setOcrTemplateFolderField] = useState();
  const [folderFieldId, setFolderFieldId] = useState<string>('');
  const boundingBox = useRef<BoundingBoxInput>();
  const [inputType, setInputType] = useState<string | undefined>();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [action, setAction] = useState<string | undefined>();
  const [analyzedResponseObjects, setAnalyzedResponseObjects] = useState({});
  const [identifierId, setIdentifierId] = useState<number | undefined>();
  const [panning, setPanning] = useState(false);
  const [textractId, setTextractId] = useState(null);
  const [validateAreaData, setValidateAreaData] = useState(null);
  const [modifiedFields, setModifiedFields] = useState([]);
  const [template, setTemplate] = useState<any>();
  const [valuesOnlyFields, setValuesOnlyFields] = useState<string[]>([]);
  const [ocrState, setOcrState] = useState<any>(new Map());
  const [backdrop, setBackdrop] = useState(false);
  const [templateIdentifiers, setTemplateIdentifiers] = useState<
    { identifierText: string; boundsToFindIdentifier?: any }[]
  >([]);
  const [modTempIdentifiers, setModTempIdentifiers] = useState<any>(false);
  const [modIdentifiersIds, setModIdentifiersIds] = useState([]);
  const [recordId, setRecordId] = useState<string>();

  useEffect(() => {
    if (totalPages > 0) {
      setCurrentPage(1);
    }
  }, [totalPages]);

  const deleteObject = (objectId: string) => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const object = canvas.getObjects().find((object: fabric.Object) => {
      return object.name === objectId;
    });

    if (object) {
      canvas.remove(object);
      canvas.renderAll();
      // After the object is deleted from the instance, we set the new array so it's not added again
      const newObjects = objects.filter(
        (element) => element.object.name !== selectedObject
      );
      setObjects(newObjects);
    }
  };

  const deleteObjectGroup = (fieldName: string) => {
    console.log(fieldName);
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    // first we need the objects to delete
    const objectsToDelete = canvas
      .getObjects()
      .filter(
        (object: fabric.Object) => object.name && object.name.startsWith(fieldName)
      );

    console.log(objectsToDelete);
    if (objectsToDelete.length > 0) {
      // then we delete them, passing the objects to canvas remove method
      objectsToDelete.forEach((object: fabric.Object) => canvas.remove(object));
      canvas.renderAll();

      // After the objects are deleted from the instance, the objects state from the context needs
      // to be updated to be in sync with the canvas
      const newObjects = objects.filter(
        (el: { object: fabric.Object; page: number }) =>
          el.object.name && !el.object.name.startsWith(fieldName)
      );
      setObjects(newObjects);
    }
  };

  const deleteAllObjects = () => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    canvas.getObjects().forEach(
      (object: fabric.Object, index: number) => index !== 0 && canvas.remove(object) // Delete everything except the background image
    );
    canvas.renderAll();
    // After the objects are deleted from the instance, we set the new array so they're not added again
    setObjects([]);
  };

  const toggleObjectVisibility = (objectId: string) => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const object = canvas
      .getObjects()
      .find((object: fabric.Object) => object.name === objectId);

    if (object) {
      object.visible = !object.visible;
      canvas.renderAll();
    }
  };

  const hideAllObjects = (objectId: string) => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    canvas
      .getObjects()
      .forEach(
        (object: fabric.Object, index: number) => object.name && (object.visible = false)
      );

    canvas.renderAll();
  };

  const findObject = (objectId: string): boolean => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const object = canvas
      .getObjects()
      .find((object: fabric.Object) => object.name === objectId);

    return object !== undefined;
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((curr) => curr - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((curr) => curr + 1);
    }
  };

  const togglePanning = (value: boolean | undefined) => {
    if (value === true || value === false) {
      // Set panning state directly
      setPanning(value);
      if (value) {
        setCanDraw(false);
      }
    } else {
      // Toggle panning state
      setPanning((curr) => {
        const newState = !curr;
        if (newState) {
          setCanDraw(false);
        }
        return newState;
      });
    }
  };

  return {
    canvasRef,
    objects,
    setObjects,
    selectedObject,
    setSelectedObject,
    folderFieldId,
    setFolderFieldId,
    canDraw,
    setCanDraw,
    deleteObject,
    deleteAllObjects,
    toggleObjectVisibility,
    findObject,
    boundingBox,
    inputType,
    setInputType,
    totalPages,
    setTotalPages,
    currentPage,
    handlePreviousPage,
    handleNextPage,
    deleteObjectGroup,
    action,
    setAction,
    ocrTemplateFolderField,
    setOcrTemplateFolderField,
    analyzedResponseObjects,
    setAnalyzedResponseObjects,
    identifierId,
    setIdentifierId,
    panning,
    togglePanning,
    textractId,
    setTextractId,
    validateAreaData,
    setValidateAreaData,
    modifiedFields,
    setModifiedFields,
    template,
    setTemplate,
    valuesOnlyFields,
    setValuesOnlyFields,
    ocrState,
    setOcrState,
    hideAllObjects,
    backdrop,
    setBackdrop,
    templateIdentifiers,
    setTemplateIdentifiers,
    modTempIdentifiers,
    setModTempIdentifiers,
    modIdentifiersIds,
    setModIdentifiersIds,
    setCurrentPage,
    recordId,
    setRecordId,
  };
};

export interface FabricContextValue {
  canvasRef: MutableRefObject<any>;
  objects: fabric.Object[];
  setObjects: Dispatch<SetStateAction<fabric.Object[]>>;
  selectedObject: string;
  setSelectedObject: Dispatch<SetStateAction<string>>;
  folderFieldId: string;
  setFolderFieldId: Dispatch<SetStateAction<string>>;
  canDraw: boolean;
  setCanDraw: Dispatch<SetStateAction<boolean>>;
  deleteObject: (objectId: string) => void;
  deleteAllObjects: () => void;
  deleteObjectGroup: (fieldName: string) => void;
  toggleObjectVisibility: (objectId: string) => void;
  findObject: (objectId: string) => boolean;
  boundingBox: MutableRefObject<BoundingBoxInput | undefined>;
  inputType: string | undefined;
  setInputType: Dispatch<SetStateAction<string | undefined>>;
  totalPages: number;
  setTotalPages: Dispatch<SetStateAction<number>>;
  currentPage: number;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  action: string | undefined;
  setAction: Dispatch<SetStateAction<string | undefined>>;
  ocrTemplateFolderField: any;
  setOcrTemplateFolderField: Dispatch<SetStateAction<any>>;
  identifierId: number | undefined;
  setIdentifierId: Dispatch<SetStateAction<number | undefined>>;
  panning: boolean;
  togglePanning: () => void;
  textractId: string | null;
  setTextractId: Dispatch<SetStateAction<string | null>>;
  validateAreaData: any;
  setValidateAreaData: Dispatch<SetStateAction<any>>;
  modifiedFields: any[];
  setModifiedFields: Dispatch<SetStateAction<any[]>>;
  template: any;
  setTemplate: Dispatch<SetStateAction<any>>;
  valuesOnlyFields: string[];
  setValuesOnlyFields: Dispatch<SetStateAction<string[]>>;
  ocrState: any;
  setOcrState: Dispatch<SetStateAction<any>>;
  hideAllObjects: (objectId: string) => void;
  backdrop: boolean;
  setBackdrop: Dispatch<SetStateAction<boolean>>;
  templateIdentifiers: { identifierText: string; boundsToFindIdentifier?: any }[];
  setTemplateIdentifiers: any;
  modTempIdentifiers: any;
  setModTempIdentifiers: any;
  modIdentifiersIds: any;
  setModIdentifiersIds: any;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setRecordId: Dispatch<SetStateAction<string>>;
  recordId: string;
}

type ProviderProps = {
  children: ReactNode;
};

export const FabricProvider = ({ children }: ProviderProps) => {
  const value = useFabricCanvas();

  return <FabricContext.Provider value={value}>{children}</FabricContext.Provider>;
};

export const useFabricContext = () => {
  return useContext(FabricContext);
};
