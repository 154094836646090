import {
  Box,
  Button,
  IconButton,
  Stack,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { PageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useOCRSetupContext } from '../../context/OCRSetupContext';
import { useFabricContext } from '../../hooks/useFabricCanvas';
import { OCRSetup } from '../../hooks/OCRTemplateSetup/useOCRSetup';
import FontAwesomeIcon from '../FAIcon';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  borderRadius: 0,
  fontSize: '16px',
  '& .MuiIcon-root': {
    margin: '0 2px',
  },
  '&:hover': {
    backgroundColor: theme.palette.common.greyblueDarker,
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.common.white,
  borderRadius: 0,
  fontSize: '16px',
  padding: '0 10px',
  height: '100%',
  '& .MuiIcon-root': {
    margin: '0 2px',
  },
  '&:hover': {
    backgroundColor: theme.palette.common.greyblueDarker,
  },
}));

interface Props {
  fileURL?: string;
  setFileURL: Dispatch<SetStateAction<string | undefined>>;
}

const OCRSetupToolbar = ({ fileURL }: Props) => {
  const {
    deleteAllObjects,
    canDraw,
    action,
    setCanDraw,
    handlePreviousPage,
    handleNextPage,
    totalPages,
    currentPage,
    togglePanning,
    panning,
  } = useFabricContext();

  const setArea = () => {
    setCanDraw((current) => !current);
  };

  return (
    <Stack
      id={'ocr-setup-toolbar'}
      direction="row"
      justifyContent="space-between"
      width="100%"
      spacing={1}
      sx={{
        backgroundColor: 'common.greyblueDark',
        height: '32px',
      }}
    >
      <Box>
        <Tooltip title="Toggle panning">
          <span>
            <StyledIconButton onClick={togglePanning} disabled={!fileURL}>
              <FontAwesomeIcon
                icon="fas fa-hand-paper"
                size="16px"
                color={panning ? 'common.orange' : 'common.white'}
              />
            </StyledIconButton>
          </span>
        </Tooltip>
      </Box>

      <Stack direction="row" justifyContent="center" alignItems={'center'}>
        <Tooltip title="Previous Page">
          <span>
            <StyledIconButton
              onClick={handlePreviousPage}
              disabled={!fileURL || (canDraw && action === 'set-identifier')}
            >
              <FontAwesomeIcon icon="fas fa-chevron-left" size="16px" />
            </StyledIconButton>
          </span>
        </Tooltip>
        <Typography
          variant="body2"
          sx={{ color: 'common.white' }}
        >{`${currentPage}/${totalPages}`}</Typography>
        <Tooltip title="Next Page">
          <span>
            <StyledIconButton
              onClick={handleNextPage}
              disabled={!fileURL || (canDraw && action === 'set-identifier')}
            >
              <FontAwesomeIcon icon="fas fa-chevron-right" size="16px" />
            </StyledIconButton>
          </span>
        </Tooltip>
      </Stack>

      <Tooltip title="Clear view">
        <span>
          <StyledIconButton onClick={() => deleteAllObjects()} disabled={!fileURL}>
            <FontAwesomeIcon icon="fas fa-trash" size="16px" />
          </StyledIconButton>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default OCRSetupToolbar;
