import { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Context as StatusContext } from '../context/statusContext';
import { Context as AuthContext, AuthContextTypes } from '../context/authContext';
import { useLocalStorage } from './useLocalStorage';
import _ from 'lodash';
import { GET_USERS, GET_USERS_BY_ORG } from '../utils/gql/gqlUsers';
import { User } from '../components/masterTable/masterTableTypes';
import { StatusContextTypes } from '../context/statusContextTypes';

const useUsers = () => {
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;

  const [users, setUsers] = useState<User[]>();

  useQuery(isSuser ? GET_USERS_BY_ORG : GET_USERS, {
    variables: isSuser
      ? {
          organisationId:
            state.selectedOrg && _.isObject(state.selectedOrg)
              ? state.selectedOrg.id
              : selOrg.id,
        }
      : null,
    onCompleted: (data) => setUsers(isSuser ? data.UsersByOrg.users : data.Users.users),
    fetchPolicy: 'cache-first',
  });

  return { users };
};

export default useUsers;
