import { gql } from 'apollo-boost';

export const GET_DASHBOARD_DATA = gql`
  query getDashboardData($folderId: ID!, $content: [DashboardContent]) {
    getDashboardData(folderId: $folderId, content: $content) {
      folderId
      content
    }
  }
`;
export const GET_DASHBOARD_DATA_BY_ORG = gql`
  query getDashboardDataByOrg(
    $organisationId: ID
    $folderId: ID!
    $content: [DashboardContent]
  ) {
    getDashboardDataByOrg(
      organisationId: $organisationId
      folderId: $folderId
      content: $content
    ) {
      folderId
      content
    }
  }
`;
