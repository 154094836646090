import { useContext, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';
import {
  CREATE_OCR_TEMPLATE,
  DELETE_OCR_TEMPLATE,
  GET_OCR_TEMPLATES,
  GET_OCR_TEMPLATE_BY_ID,
  REMOVE_OCR_TEMPLATE_FOLDER_FIELD_DEFINITIONS,
  UPDATE_OCR_TEMPLATE,
  UPDATE_OCR_TEMPLATE_FOLDER_FIELD_DEFINITIONS,
  UPDATE_OCR_TEMPLATE_IDENTIFIERS,
} from '../../utils/gql/gqlOCRTemplate';
import RTC from '../../utils/socketComm';
import useUserStorageData from '../user/userUserStorageData';
import { OcrTemplateInput } from './types';

interface OcrTemplate {
  id: string;
  templateName: string;
}

export const useOCRTemplate = () => {
  const [templates, setTemplates] = useState<OcrTemplate[]>([]);
  const [createOcrTemplate] = useMutation(CREATE_OCR_TEMPLATE);
  const [updateOcrTemplate] = useMutation(UPDATE_OCR_TEMPLATE);
  const [updateOcrTemplateIdentifiers] = useMutation(UPDATE_OCR_TEMPLATE_IDENTIFIERS);
  const [updateOcrFieldDefinitions] = useMutation(
    UPDATE_OCR_TEMPLATE_FOLDER_FIELD_DEFINITIONS
  );
  const [removeOcrTemplateFolderFieldDefinitions] = useMutation(
    REMOVE_OCR_TEMPLATE_FOLDER_FIELD_DEFINITIONS
  );
  const [deleteOcrTemplate] = useMutation(DELETE_OCR_TEMPLATE);
  const { userSettings } = useUserStorageData();
  const [textractId, setTextractId] = useState();
  const {
    state: { selectedRecord },
  } = useContext(StatusContext) as StatusContextTypes;

  const [getTemplates, { data, refetch }] = useLazyQuery<{
    OcrTemplates: { ocrTemplates: OcrTemplate[] };
  }>(GET_OCR_TEMPLATES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTemplates(data.OcrTemplates.ocrTemplates);
    },
  });

  const [getTemplateById] = useLazyQuery<{
    OcrTemplates: { ocrTemplates: OcrTemplate[] };
  }>(GET_OCR_TEMPLATE_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTemplates(data.OcrTemplates.ocrTemplates);
    },
  });

  const getTemplate = async (id: null | string) => {
    // if id is null get all templates
    !id ? getTemplates() : getTemplateById({ variables: { folderId: id } });
  };

  const refetchTemplates = async () => {
    refetch().then((res) => setTemplates(res.data.OcrTemplates.ocrTemplates));
  };

  const createTemplate = async (variables: OcrTemplateInput) => {
    try {
      const res = await createOcrTemplate({
        variables: {
          ocrTemplate: variables,
        },
      });

      const recordId = selectedRecord?.id;
      RTC.ocrRecord({ recordId: recordId, folderId: userSettings.lastIndexedFolder });

      return res?.data?.CreateOcrTemplate;
    } catch (error) {
      console.error(error);
    }
  };

  const updateTemplate = async (variables: {
    id: string;
    ocrTemplateUpdates: OcrTemplateInput;
  }) => {
    try {
      const res = await updateOcrTemplate({
        variables,
      });

      window.log('updateTemplate ', res);

      const recordId = selectedRecord?.id;
      RTC.ocrRecord({ recordId: recordId, folderId: userSettings.lastIndexedFolder });

      return res?.data?.UpdateOcrTemplate;
    } catch (error) {
      console.error(error);
    }
  };

  const updateTemplateIdentifiers = async (variables: {
    templateId: string;
    templateIdentifiers: any;
  }) => {
    try {
      const res = await updateOcrTemplateIdentifiers({
        variables,
      });

      window.log('updateTemplateIdentifiers ', res);

      const recordId = selectedRecord?.id;
      RTC.ocrRecord({ recordId: recordId, folderId: userSettings.lastIndexedFolder });

      return res?.data?.UpdateOcrTemplateIdentifiers;
    } catch (error) {
      console.error(error);
    }
  };

  const updateTemplateFieldDefinitions = async (variables: {
    templateId: string;
    ocrFieldDefinitions: any;
  }) => {
    try {
      const res = await updateOcrFieldDefinitions({
        variables,
      });

      window.log('updateOcrFieldDefinitions', res);

      const recordId = selectedRecord?.id;
      RTC.ocrRecord({ recordId: recordId, folderId: userSettings.lastIndexedFolder });

      return res?.data?.UpdateOcrTemplateFolderFieldDefinitions;
    } catch (error) {
      console.error(error);
    }
  };

  const removeFieldDefinitions = async (variables: {
    templateId: string;
    folderFieldID: string;
  }) => {
    try {
      const res = await removeOcrTemplateFolderFieldDefinitions({
        variables,
      });

      window.log('remove field definitions', res);

      const recordId = selectedRecord?.id;
      RTC.ocrRecord({ recordId: recordId, folderId: userSettings.lastIndexedFolder });

      return res?.data?.RemoveOcrTemplateFolderFieldDefinitions;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTemplate = async (variables: { id: string }) => {
    console.log(variables);
    try {
      const res = await deleteOcrTemplate({
        variables,
      });

      window.log('delete template', res);

      return res?.data?.DeleteOcrTemplate;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    createTemplate,
    textractId,
    updateTemplate,
    updateTemplateIdentifiers,
    updateTemplateFieldDefinitions,
    removeFieldDefinitions,
    deleteTemplate,
    templates,
    getTemplate,
    refetchTemplates,
  };
};
