import { useAsync } from 'react-use';
import { proxy, wrap } from 'comlink';
import { useEffect } from 'react';
import { WorkerType } from './pdf.worker';

const worker = new Worker('./pdf.worker', {
  name: 'pdf-worker',
  type: 'module',
});

export const pdfWorker = wrap<WorkerType>(worker);
pdfWorker.onProgress(proxy((info: any) => console.log(info)));

export const useRenderPDF = (props: Parameters<WorkerType['renderPDFInWorker']>[0]) => {
  const { value, loading, error } = useAsync(async () => {
    if (props.rows && props.rows.length > 1000)
      return { value: undefined, loading: false, error: undefined };
    return pdfWorker.renderPDFInWorker(props);
  }, [props.rows, props.orientation]);

  useEffect(
    () => (value?.url ? () => URL.revokeObjectURL(value?.url) : undefined),
    [value?.url]
  );

  return { value, loading, error };
};
