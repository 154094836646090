import { gql } from 'apollo-boost';

const AP_PROCESSING_SETTINGS = `
	apProcessingSettings {
		duplicate_Check_Fields
		ocrTemplateNameFolderFieldId
		ocrTemplateNameSupplierCodePairs {
			name
			code
		}
	}
`;

export const GET_FOLDERS = gql`
  query ($includeInactive: Boolean) {
    Folders(includeInactive: $includeInactive) {
      name
      id
      createdBy {
        firstName
        lastName
      }
      createdAt
      autoAppendRecords
      scanning
      primaryFolderFieldId
      ocrEnabled
      active
      workFlow
			${AP_PROCESSING_SETTINGS}
      linked_Information_Mapping {
        id
        linkType
        primaryLink {
          linkedFieldName
          fieldId
        }
        linkedValues {
          linkedFieldName
          fieldId
        }
      }
      folderFields {
        id
        folderId
        name
        active
        mainField
        matchRequiredField
        type
        indexRequirements {
          isMandatory
          regexMatch
        }
        barcodeRequirements {
          regexMatch
          boundingBox {
            top
            left
            height
            width
          }
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
        }
        options {
          key
          value
        }
      }
    }
  }
`;
export const GET_FOLDERS_BY_ORG = gql`
  query FoldersByOrg($organisationId: ID!) {
    FoldersByOrg(organisationId: $organisationId) {
      name
      id
      createdBy {
        firstName
        lastName
      }
      createdAt
      autoAppendRecords
      scanning
      workFlow
      folderFields {
        id
        folderId
        name
        active
        type
        mainField
        matchRequiredField
        indexRequirements {
          isMandatory
          regexMatch
        }
        barcodeRequirements {
          regexMatch
          boundingBox {
            top
            left
            height
            width
          }
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
        }
        active
        options {
          key
          value
        }
      }
    }
  }
`;
export const CREATE_FOLDER = gql`
  mutation createFolder(
    $name: String!
    $active: Boolean!
    $autoAppendRecords: Boolean
    $scanning: Boolean
    $ocrEnabled: Boolean
    $workFlow: Boolean
  ) {
    createFolder(
      data: {
        name: $name
        active: $active
        autoAppendRecords: $autoAppendRecords
        scanning: $scanning
        ocrEnabled: $ocrEnabled
        workFlow: $workFlow
      }
    ) {
      id
    }
  }
`;
export const CREATE_FOLDER_ON_ORG = gql`
  mutation createFolderByOrg(
    $name: String!
    $active: Boolean!
    $autoAppendRecords: Boolean
    $organisationId: ID!
  ) {
    createFolderByOrg(
      data: { name: $name, active: $active, autoAppendRecords: $autoAppendRecords }
      organisationId: $organisationId
    ) {
      id
    }
  }
`;
export const DELETE_FOLDER_FIELD = gql`
  mutation deleteFolderField($folderFieldId: ID!, $org: ID) {
    deleteFolderField(id: $folderFieldId, org: $org) {
      folderField {
        id
        name
        type
        folderId
      }
    }
  }
`;

export const UPDATE_FOLDER_FIELD = gql`
  mutation updateFolderField(
    $folderFieldId: ID!
    $active: Boolean
    $name: String
    $type: dataTypeEnum
    $options: [FolderFieldOptionInput]
    $mainField: Boolean
    $matchRequiredField: Boolean
    $indexRequirements: FolderFieldIndexRequirementsInput
    $textractRequirements: TextractRequirementsInput
    $barcodeRequirements: BarcodeRequirementsInput
  ) {
    updateFolderField(
      data: {
        id: $folderFieldId
        active: $active
        name: $name
        type: $type
        options: $options
        matchRequiredField: $matchRequiredField
        mainField: $mainField
        indexRequirements: $indexRequirements
        textractRequirements: $textractRequirements
        barcodeRequirements: $barcodeRequirements
      }
    ) {
      folderField {
        id
        name
        type
        active
        folderId
        matchRequiredField
        mainField
        options {
          key
          value
        }
        indexRequirements {
          isMandatory
          regexMatch
        }
        textractRequirements {
          regexMatch
          textType
          blockType
          boundingBox {
            top
            left
            height
            width
          }
          confidenceMinimum
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
          headerValuePair {
            textToFind
            textType
            blockType
            textToFindConfidenceMinimum
            boxToFindValue {
              top
              left
              height
              width
            }
          }
        }
        barcodeRequirements {
          regexMatch
          boundingBox {
            top
            left
            height
            width
          }
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
        }
      }
    }
  }
`;
export const UPDATE_FOLDER = gql`
  mutation updateFolder(
    $folderId: ID!
    $name: String
    $active: Boolean
    $autoAppendRecords: Boolean
    $scanning: Boolean
    $primaryFolderFieldId: ID
    $infoMapping: LinkedInfoMappingInput
    $ocrEnabled: Boolean
    $workFlow: Boolean
		$apProcessingSettings: apProcessingSettingsInput
  ) {
    updateFolder(
      data: {
        name: $name
        active: $active
        autoAppendRecords: $autoAppendRecords
        scanning: $scanning
        primaryFolderFieldId: $primaryFolderFieldId
        infoMapping: $infoMapping
        ocrEnabled: $ocrEnabled
        workFlow: $workFlow
				apProcessingSettings: $apProcessingSettings
      }
      id: $folderId
    ) {
      id
      active
      autoAppendRecords
      scanning
      primaryFolderFieldId
      ocrEnabled
      workFlow
			${AP_PROCESSING_SETTINGS}
      linked_Information_Mapping {
        id
        linkType
        primaryLink {
          linkedFieldName
          fieldId
        }
        linkedValues {
          linkedFieldName
          fieldId
        }
      }
      name
      folderFields {
        id
        name
        type
        active
        folderId
        matchRequiredField
        mainField
        options {
          key
          value
        }
        indexRequirements {
          isMandatory
          regexMatch
        }
        textractRequirements {
          regexMatch
          textType
          blockType
          boundingBox {
            top
            left
            height
            width
          }
          confidenceMinimum
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
          headerValuePair {
            textToFind
            textType
            blockType
            textToFindConfidenceMinimum
            boxToFindValue {
              top
              left
              height
              width
            }
          }
        }
        barcodeRequirements {
          regexMatch
          boundingBox {
            top
            left
            height
            width
          }
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
        }
      }
    }
  }
`;
export const GET_FOLDER_BY_ID_BY_ORG = gql`
  query FolderByOrg($id: ID!, $organisationId: ID!) {
    FolderByOrg(id: $id, organisationId: $organisationId) {
      name
      id
      createdBy {
        firstName
        lastName
      }
      ocrEnabled
      folderFields {
        active
        id
        folderId
        name
        type
        mainField
        matchRequiredField
        indexRequirements {
          isMandatory
          regexMatch
        }
        textractRequirements {
          regexMatch
          textType
          blockType
          boundingBox {
            top
            left
            height
            width
          }
          confidenceMinimum
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
          headerValuePair {
            textToFind
            textType
            blockType
            textToFindConfidenceMinimum
            boxToFindValue {
              top
              left
              height
              width
            }
          }
        }
        options {
          key
          value
        }
        barcodeRequirements {
          regexMatch
          boundingBox {
            top
            left
            height
            width
          }
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
        }
      }
      linked_Information_Mapping {
        id
        linkType
        primaryLink {
          linkedFieldName
          fieldId
        }
        linkedValues {
          linkedFieldName
          fieldId
        }
      }
      active
      autoAppendRecords
      primaryFolderFieldId
      scanning
      createdAt
      updatedAt
			${AP_PROCESSING_SETTINGS}
      setProcessPathUsers {
        firstName
        lastName
        id
      }
      performProcessStepUsers {
        firstName
        lastName
        id
      }
    }
  }
`;
export const GET_FOLDER_BY_ID = gql`
  query Folder($id: ID!) {
    Folder(id: $id) {
      folder {
        name
        id
        createdBy {
          firstName
          lastName
        }
        folderFields {
          active
          id
          folderId
          name
          type
          mainField
          matchRequiredField
          indexRequirements {
            isMandatory
            regexMatch
          }
          textractRequirements {
            regexMatch
            textType
            blockType
            boundingBox {
              top
              left
              height
              width
            }
            confidenceMinimum
            postRegexProcessing {
              regexSearch
              postRegexType
              replaceWith
            }
            headerValuePair {
              textToFind
              textType
              blockType
              textToFindConfidenceMinimum
              boxToFindValue {
                top
                left
                height
                width
              }
            }
          }
          barcodeRequirements {
            regexMatch
            boundingBox {
              top
              left
              height
              width
            }
            postRegexProcessing {
              regexSearch
              postRegexType
              replaceWith
            }
          }
          options {
            key
            value
          }
        }
        linked_Information_Mapping {
          id
          linkType
          primaryLink {
            linkedFieldName
            fieldId
          }
          linkedValues {
            linkedFieldName
            fieldId
          }
        }
        active
        ocrEnabled
        autoAppendRecords
        primaryFolderFieldId
        scanning
        workFlow
        createdAt
        updatedAt
				${AP_PROCESSING_SETTINGS}
        setProcessPathUsers {
          firstName
          lastName
          id
        }
        performProcessStepUsers {
          firstName
          lastName
          id
        }
      }
    }
  }
`;
export const ADD_FOLDER_FIELD = gql`
  mutation createFolderField(
    $fieldName: String!
    $dataType: dataTypeEnum
    $folderId: ID!
    $options: [FolderFieldOptionInput]
    $indexRequirements: FolderFieldIndexRequirementsInput
  ) {
    createFolderField(
      data: {
        name: $fieldName
        type: $dataType
        folderId: $folderId
        options: $options
        indexRequirements: $indexRequirements
      }
    ) {
      folderField {
        active
        id
        folderId
        name
        type
        mainField
        matchRequiredField
        indexRequirements {
          isMandatory
          regexMatch
        }
        barcodeRequirements {
          regexMatch
          boundingBox {
            top
            left
            height
            width
          }
          postRegexProcessing {
            regexSearch
            postRegexType
            replaceWith
          }
        }
        options {
          key
          value
        }
      }
    }
  }
`;
