import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TabPanelProps } from '@mui/lab';

// Styled components
function TabPanel({
  index,
  children,
  value,
  ...other
}: { index: string | number; value: string | number } & TabPanelProps) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width: '100%', maxHeight: 'min-content' }}>{children}</Box>
      )}
    </Box>
  );
}

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

export default function CustomTabPanel({
  index,
  label,
  padding,
  ...props
}: {
  index: string | number;
  value: string | number;
  label?: string;
  padding?: number;
} & TabPanelProps) {
  return (
    <StyledTabPanel index={index} label={label} {...props}>
      {props.children}
    </StyledTabPanel>
  );
}
