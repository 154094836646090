import { Box, IconButton, Modal, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useForm } from 'react-hook-form';
import { DELETE_NOTE_FROM_RECORD } from '../../utils/gql/gqlNotes';
import FontAwesomeIcon from '../FAIcon';
import NotesCard from './NotesCard';
import NotesForm from './NotesForm';
import { Field, Inputs, Note, NotesModalProps } from './notesTypes';

const styles = {
  boxStyles: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
  },
  title: {
    backgroundColor: 'common.greyblueDarker',
    color: 'common.white',
    borderRadius: '8px',
    textAlign: 'left',
    padding: '5px 10px 5px 15px',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    minHeight: '500px',
    maxHeight: '500px',
    overflow: 'auto',
    p: 2,
    m: 1,
    marginBottom: 0,
    '&::-webkit-scrollbar': {
      width: '12px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#436072',
      borderRadius: '2px',
    },
  },
  footer: {
    borderTop: '1px solid #ccc',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    boxShadow: '0px 3px 17px 0px rgba(51,51,51,0.38)',
  },
  noNotesYet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 15,
    opacity: 0.5,
  },
};

const getMainFieldValue = (record: any) => {
  let title;
  let mainField = record.fields.find(
    (field: Field) => field.id === record.primaryFolderFieldId
  );

  let [value, type] = [mainField.value, mainField.field.type];

  if (type === 'datetime') {
    // value arrives as timestap, e.g '232142132958325'
    value = Number(value);
    title = new Date(value).toLocaleDateString(); // it is necessary to convert it, e.g '13/09/2022'
  } else {
    title = value;
  }
  if (title.length > 35) title = `${title.slice(0, 35)}...`;
  return title;
};

const NotesModal = ({
  record,
  openNotes,
  handleCloseNotes,
  setMessage,
  setSnackOpen,
}: NotesModalProps) => {
  const [notes, setNotes] = useState<Array<Note>>([]);
  const [showAddANote, setShowAddANote] = useState<boolean>(false);
  const [showNotesList, setShowNotesList] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    resetField,
    reset,
    setFocus,
  } = useForm<Inputs>();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const createNoteButtonRef = useRef<HTMLButtonElement>(null);

  const [deleteNoteFromRecord] = useMutation(DELETE_NOTE_FROM_RECORD);

  // get the mainfield values to include it in the title
  let title = getMainFieldValue(record);

  const deleteNote = (id: string) => {
    deleteNoteFromRecord({
      variables: {
        recordId: record.id,
        documentNoteId: id,
      },
    })
      .then((res) => {
        let newNotes = // the reverse method is used to make the last note come first
          res.data.deleteDocumentNoteFromRecord.record.documentNotes.reverse();
        setNotes(newNotes);
      })
      .catch(() => {
        setMessage({
          status: true,
          message: 'Note could not be deleted',
          severity: 'error',
        });
        setSnackOpen(true);
      });
  };

  const handleAddNote = () => {
    // generates a submit request in the form component
    if (!showNotesList && submitButtonRef.current !== null)
      submitButtonRef.current.click();
  };

  useEffect(() => {
    // updates the notes when the user changes record
    if (!record) return;
    setNotes(record.documentNotes?.reverse());
  }, [record]);

  useEffect(() => {
    // if the record has no notes, the screen should start with the form
    // if done with state change (setShowNotesList), autofocus does not work
    if (notes.length === 0) createNoteButtonRef?.current?.click();
  }, [notes, createNoteButtonRef]);

  return (
    <Modal
      open={openNotes}
      onClose={handleCloseNotes}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 9999,
        '.MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Box sx={styles.boxStyles}>
        <Box sx={styles.title}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: 'common.white' }}
          >
            {`Notes - ${title}`}
          </Typography>
          <IconButton
            id="close-notes"
            size="small"
            onClick={() => {
              setShowNotesList(true);
              clearErrors();
              reset();
              handleCloseNotes();
            }}
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
              color: 'common.white',
            }}
          >
            <FontAwesomeIcon icon="fas fa-times" size="14px" color="#fff" padding="2px" />
          </IconButton>
        </Box>
        <Box sx={styles.content} id="modal-modal-description">
          {showNotesList ? (
            notes && notes.length ? (
              notes.map((note, index) => (
                <NotesCard
                  note={note}
                  key={note.id}
                  index={index}
                  deleteNote={deleteNote}
                  setMessage={setMessage}
                  setSnackOpen={setSnackOpen}
                  allowManualExpand={true}
                />
              ))
            ) : (
              <Box sx={styles.noNotesYet}>
                <FontAwesomeIcon
                  icon="fa-sticky-note"
                  size={180}
                  padding={0}
                  color="#96c2dfb0"
                />
                <Typography variant="subtitle1" sx={{ fontSize: 18 }}>
                  No notes yet
                </Typography>
              </Box>
            )
          ) : (
            <NotesForm
              resetField={resetField}
              submitButtonRef={submitButtonRef}
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
              clearErrors={clearErrors}
              setShowNotesList={setShowNotesList}
              reset={reset}
              record={record}
              setNotes={setNotes}
              setMessage={setMessage}
              setSnackOpen={setSnackOpen}
              setFocus={setFocus}
            />
          )}
        </Box>

        <Box sx={{ ...styles.footer, backgroundColor: 'common.greyblueDarker' }}>
          <Box
            sx={{
              marginLeft: showNotesList ? 8 : 6.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {showNotesList ? (
              <>
                <IconButton
                  aria-label="create notes"
                  onMouseEnter={() => setShowAddANote(true)}
                  onMouseLeave={() => setShowAddANote(false)}
                  ref={createNoteButtonRef}
                  onClick={() => {
                    showNotesList ? setShowNotesList(false) : setShowNotesList(true);
                  }}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <FontAwesomeIcon
                    icon="fas fa-plus-circle"
                    size="48px"
                    color="common.lightblue"
                    padding="0px"
                  />
                </IconButton>
                <Typography
                  sx={{
                    opacity: showAddANote ? 1 : 0,
                    fontSize: '12px',
                    color: 'common.white',
                  }}
                >
                  Create a note
                </Typography>
              </>
            ) : (
              <>
                <IconButton
                  aria-label="add notes"
                  onMouseEnter={() => setShowAddANote(true)}
                  onMouseLeave={() => setShowAddANote(false)}
                  onClick={handleAddNote}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <FontAwesomeIcon
                    icon="fas fa-check-circle"
                    size="48px"
                    color={errors.content ? '#fc0000' : 'common.lightblue'}
                    padding="0px"
                  />
                </IconButton>
                <Typography
                  sx={{
                    opacity: showAddANote ? 1 : 0,
                    fontSize: '12px',
                    color: 'common.white',
                  }}
                >
                  Add a note
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotesModal;
