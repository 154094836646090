import { io } from 'socket.io-client';
import { db } from './indexedDB/indexeddb';
import utils from './utils';

const RTC = {
  socket: false,
  addNotification: () => {},
  init: (namespace, rtcContext, tasksContext, tableContext, userData) => {
    const {
      setRTCstatus,
      addTaskNotification,
      addNotification,
      maintenanceMode,
      setSocketEvents,
      setMaintenanceMode,
      setTextractId,
      setOcrTemplate,
      setIdentifiersResponse,
      setAnalyzedArea,
    } = rtcContext;
    let token = JSON.parse(utils.getCookie('data').split('j:')[1]).ws;
    const url = process.env.REACT_APP_API;
    window.log('Initializing socket communications on: ', url);
    if (!RTC.socket) {
      RTC.socket = io(url, {
        auth: {
          token: token,
        },
      });
    }

    const changeState = () => {
      setRTCstatus({ state: true });
    };

    if (process.env.REACT_APP_SOCKET_DEBUG === 'true') {
      RTC.socket.prependAny((event, ...args) => {
        setSocketEvents({
          type: 'incoming',
          time: new Date().toLocaleTimeString(),
          event,
          args,
        });
      });

      RTC.socket.prependAnyOutgoing((event, ...args) => {
        setSocketEvents({
          type: 'outgoing',
          time: new Date().toLocaleTimeString(),
          event,
          args,
        });
      });
    }
    RTC.socket.on('connect', function () {
      window.log('connected!');
      RTC.socket.emit('get-notifications');
      changeState();
    });
    RTC.socket.on('connect_error', (error) => {
      window.log(error);
    });
    RTC.socket.on('maintenance-mode:enable', (data) => {
      window.log('maintenance mode enabled ...', data);
      maintenanceMode(data);
    });
    RTC.socket.on('maintenance-mode:disable', (data) => {
      window.log('maintenance mode disabled ...');
      maintenanceMode(false);
      setMaintenanceMode(false);
    });
    if (tasksContext && tableContext) {
      const { updateTask, updateTaskVisualization, finishTask } = tasksContext;
      const { setRecordToUpdate, removeRowLoader } = tableContext;
      RTC.socket.on('task-update', (data) => {
        window.log('Task update message received: ', data);
        if (
          (data.TaskDescription === 'ocr-records' ||
            data.TaskDescription === 'scan-barcode-result') &&
          data.taskResult === 'Error'
        ) {
          removeRowLoader(data.recordId, false);
        }
        updateTask({ data, addTaskNotification, updateTaskVisualization });
        return;
      });
      RTC.socket.on('record-fields-preview', async (data) => {
        window.log('Barcode update received: ', data);
        removeRowLoader(data.payload.record._id, true);
        if (!data.error) {
          const { payload } = data;
          const existingRecord = await db
            .table('indexingScreen')
            .get({ recordID: payload.record._id });
          const payloadFields = payload.fields.map((field) => {
            if (field.dateValue) {
              return {
                ...field,
                dateValue: utils.formatDateWithMoment(field.dateValue),
              };
            }
            return field;
          });
          const fields = utils.generateOCRScanValuesToSaveInIndexedDB(payloadFields);
          const newRecordData = {
            recordID: payload.record._id,
            fields: fields,
            status: 'unindexed',
          };
          window.log('newRecordData: ', newRecordData);
          if (existingRecord) {
            window.log('record already exist');
            await db
              .table('indexingScreen')
              .where({ recordID: payload.record._id })
              .modify(newRecordData)
              .then((res) => window.log(res));
          } else {
            await db
              .table('indexingScreen')
              .add(newRecordData)
              .then((res) => window.log(res));
          }
          setRecordToUpdate(newRecordData);
          //updateTask({ data, addTaskNotification, updateTaskVisualization });
          return;
        }
      });
      RTC.socket.on('record-ready', (data) => {
        window.log('record-ready', data);
        data.taskResult = 'Success';
        updateTask({ data, addTaskNotification, updateTaskVisualization });
      });
      RTC.socket.on('record-failed', (data) => {
        window.log('record failed!', data);
        data.taskResult = 'Error';
        updateTask({ data, addTaskNotification, updateTaskVisualization });
      });
      RTC.socket.on('task-complete', (data) => {
        window.log('task completed!', data);
        data.taskResult = 'Success';
        finishTask({ data, addTaskNotification, updateTaskVisualization });
      });
      RTC.socket.on('record-being-textracted', (data) => {
        window.log('record-being-textracted!', data);
        tableContext.addTextractProcessLoader(data);
      });
      RTC.socket.on('add-notification', (data) => {
        addNotification({
          newNoti: data,
          setInIndexedDB: !window.document.hidden && window.document.hasFocus(),
        });
      });
      RTC.socket.on('record-textract-complete', async (data) => {
        window.log('record-textract-complete', data);
        let { recordId, taskId } = data;
        const { removeTextractProcessLoader } = tableContext;
        data.taskResult = 'Success';
        updateTask({ data, addTaskNotification, updateTaskVisualization });
        removeTextractProcessLoader({ recordId, taskId });
        await db
          .table('indexingScreen')
          .where({ recordID: recordId })
          .modify({ textract: true });
      });

      RTC.socket.on('ocr-record-response', (data) => {
        const { info, error, textractId, template } = data;
        if (error) {
          setOcrTemplate(null);
          setIdentifiersResponse(null);
          setAnalyzedArea(null);
          setTextractId(null);
          window.log('ocr-record-response error', error);
          window.log(error);
        } else if (info === 'No Templates Found') {
          setOcrTemplate(null);
          setIdentifiersResponse(null);
          setAnalyzedArea(null);
          setTextractId(textractId);
        } else {
          window.log('ocr-record-response', data);
          setTextractId(textractId);
          template && setOcrTemplate(template);
        }
      });

      RTC.socket.on('draw-box-for-identifiers-response', (res) => {
        window.log('draw-box-for-identifiers-response', res);
        setIdentifiersResponse(res);
      });

      RTC.socket.on('analyse-ocr-area-response', (res) => {
        window.log('analyse-ocr-area-response', res);
        setAnalyzedArea(res);
      });
    }
    /* RTC.socket.on('scan-barcode-result', (data) => {
      updateTaks(data);
      handleNotifications(data);
      window.log(data);
    });
    RTC.socket.on('record-deleted', (data) => {
      updateTaks(data);
      handleNotifications(data);
      window.log('record-deleted notification: ', data);
    });
    RTC.socket.on('task-error', (data) => {
      handleNotifications(data);
      window.log('task-error: ', data);
    }); */
  },
  addTask: (data) => {
    window.log('Task created with socket');
    RTC.socket.emit('task-created', data, (response) => {
      window.log('Task created response', response);
    });
  },
  sendData: (e, data) => {
    RTC.socket.emit(e, data, (response) => {
      window.log('SEND DATA RESPONSE', response);
    });
  },
  imgUploadSuccessful: (data) => {
    RTC.socket.emit('image-upload-success', data, (response) => {
      window.log('SEND DATA RESPONSE', response);
    });
  },
  imgUploadFailed: (data) => {
    RTC.socket.emit('image-upload-failed', data, (response) => {
      window.log('SEND DATA RESPONSE', response);
    });
  },
  createNotification: (data) => {
    RTC.socket.emit('create-notification', data, (e) => {
      window.log('create notifications', data, e);
    });
  },
  clearNotification: (data) => {
    RTC.socket.emit('clear-notifications', { clearAll: false, idsToClear: data });
  },
  clearAllNotifications: () => {
    RTC.socket.emit('clear-notifications', { clearAll: true });
  },
  setMaintenanceMode: (data) => {
    window.log('maintenance-mode', data);
    RTC.socket.emit('maintenance-mode', data, (e) => {
      window.log('maintenance-mode', data);
    });
  },
  ocrRecord: (data) => {
    window.log('ocr-record', data);
    RTC.socket.emit('ocr-record', data, (response) => {
      window.log(response);
    });
  },
  drawBoxForIdentifiers: (data) => {
    window.log('draw-box-for-identifiers', data);
    RTC.socket.emit('draw-box-for-identifiers', data, (response) => {
      window.log('draw-box-for-identifiers', data, response);
    });
  },
  analyseOcrArea: (data) => {
    window.log('analyse-ocr-area', data);
    RTC.socket.emit('analyse-ocr-area', data, (response) => {
      window.log('analyse-ocr-area', data, response);
    });
  },
  validateOcrArea: (data) => {
    window.log('validate-ocr-area', data);
    RTC.socket.emit('validate-ocr-area', data, (response) => {
      window.log('validate-ocr-area', data, response);
    });
  },
  textOnDocumentCount: (data) => {
    window.log('text-on-document-count', data);
    RTC.socket.emit('text-on-document-count', data, (response) => {
      window.log('text-on-document-count', data, response);
    });
  },
  logout: () => {
    window.log('DISCONNECT');
    RTC.socket.on('disconnect', (e) => {
      window.log('EEE', e);
    });
  },
  updateUserSettings: (userSettings) => {
    window.log(userSettings);
  },
  updateUserPreferences: (userPreferences) => {
    console.log('Updating user preferences', userPreferences);
    RTC.socket.emit('update-user-preferences', userPreferences, (e) => {
      window.log('Updating user preferences 2', e);
    });
  },
  testRegex: (regexObject) => {
    RTC.socket.emit('test-regex', regexObject);
  },
};
export default RTC;
