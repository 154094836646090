import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import FontAwesomeIcon from '../../components/FAIcon';
import { Org } from './OrgMain';

const iconProps = {
  size: '18px',
  color: 'inherit',
};

const styles = {
  card: {
    height: '240px',
    width: '200px',
    transition: '.3s',
    '&:hover': {
      boxShadow: '0px 5px 5px rgb(0 0 0 / 20%)',
    },
  },
  avatar: {
    fontSize: 20,
    width: 52,
    height: 52,
    bgcolor: 'common.greyblueDarker',
    color: 'lightblue',
  },
  iconButton: {
    transition: '.3s',
    height: '38px',
    width: '38px',
    '&:hover': {
      color: 'common.greyblue',
    },
  },
};

interface OrgCardProps {
  org: Org;
}

const stringAvatar = (name: string) => ({ children: `${name.split(' ')[0][0]}` });
const OrgCard = ({ org }: OrgCardProps) => {
  const history = useHistory();

  const { id, name, country, address, phoneNumber } = org;

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={4}
      lg={2.4}
      xl={2}
      marginBottom="15px"
      justifyContent="center"
    >
      <Card sx={styles.card}>
        <Box
          sx={{
            height: '35px',
            background:
              'radial-gradient(circle, rgba(121,144,159,1) 0%, rgba(52,107,144,1) 83%)',
          }}
        />
        <Box marginTop="-35px" marginBottom="0">
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid item>
              <Avatar {...stringAvatar(name.toUpperCase())} sx={styles.avatar} />
            </Grid>
          </Grid>
        </Box>
        <CardContent
          sx={{ p: '0!important', display: 'flex', height: '75%', alignItems: 'center' }}
        >
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={0.4}
            justifyContent="space-around"
            sx={{ height: '100%' }}
          >
            <Grid item sx={{ textAlign: 'center', width: '100%' }}>
              <Typography
                variant="h6"
                fontSize={15}
                color={(theme) =>
                  theme.palette.mode === 'dark' ? 'common.blueGrey.0' : 'common.blue'
                }
                sx={{ overflow: 'hidden', height: 'auto' }}
              >
                {name.toUpperCase()}
              </Typography>
              <Typography
                variant="h6"
                fontSize={10}
                sx={{
                  overflow: 'hidden',
                  height: '20px',
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? 'common.blueGrey.100'
                      : 'common.greyblueDark',
                }}
              >
                {country?.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item sx={{ textAlign: 'center', width: '80%' }}>
              <Typography
                variant="h6"
                fontSize={9}
                sx={{
                  overflow: 'hidden',
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? 'common.blueGrey.200'
                      : 'common.greyblueDark',
                }}
              >
                {address?.toUpperCase()}
              </Typography>
              <Typography
                variant="h6"
                fontSize={9}
                sx={{
                  overflow: 'hidden',
                  height: '20px',
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? 'common.blueGrey.200'
                      : 'common.greyblueDark',
                }}
              >
                Phone: {phoneNumber?.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center">
              <Tooltip title="Edit" placement="top" arrow>
                <span>
                  <IconButton
                    id="edit-profile"
                    disableRipple={true}
                    onClick={() => history.push(`/dash/orgs/${id}`)}
                    sx={{
                      ...styles.iconButton,
                      color: (theme) =>
                        theme.palette.mode === 'dark'
                          ? 'common.blueGrey.100'
                          : 'common.greyblueDarker',
                      '&:hover': {
                        color: (theme) =>
                          theme.palette.mode === 'dark'
                            ? 'common.greyblue'
                            : 'common.greyblueDark',
                      },
                    }}
                  >
                    <FontAwesomeIcon icon="far fa-building" {...iconProps} />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrgCard;
