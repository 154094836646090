import React, { useContext, useEffect, useState, useRef } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import gqlRecords from '../utils/gql/gqlRecords';
// Import the main component
import { Viewer, Worker, LoadError, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Context as StatusContext } from '../context/statusContext';
import { Context as AuthContext } from '../context/authContext';
import { useLocalStorage } from '../hooks/useLocalStorage';
import renderToolbar from './pdfViewer/pdfViewerToolbar';
import pagesPlugin from './pdfViewer/pagesPlugin';
import { Box } from '@mui/material';
import { TrackPositionPlugin } from './pdfViewer/trackPositionPlugin';
import { Attachment, SetMessage, SetSnackBarState } from './masterTable/masterTableTypes';
import StepActionContainer from './documentDrawer/components/stepAction/StepActionContainer';

interface PdfViewerProps {
  attachment: {
    folderId: string;
    name: string;
  };
  height?: string;
  setSnackOpen: SetSnackBarState;
  setMessage: SetMessage;
  record: any;
  refetch: () => void;
}

const PdfViewer = ({
  attachment,
  height = 'calc(100vh - 155px)',
  record,
  setMessage,
  setSnackOpen,
  refetch,
}: PdfViewerProps) => {
  const selectPages = pagesPlugin();
  const { state, setViewer } = useContext(StatusContext);
  const authContext = useContext(AuthContext);
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser: boolean = authContext.state.suser || suser;
  //const [scale, setScale] = useState<number>();
  //const [offset, setOffset] = useState<{ top: number; left: number }>();
  const [isDocumentChanged, setIsDocumentChanged] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState<Attachment | null>(null);
  const trackPosition = useRef(false);

  const customToolbar = defaultLayoutPlugin({
    renderToolbar: (ToolbarProps) => renderToolbar(ToolbarProps, trackPosition),
    sidebarTabs: (defaultTabs) => [defaultTabs[0], defaultTabs[1]],
  });

  const [url, setUrl] = useState<null | string>(null);
  const [retry, setRetry] = useState<boolean>(false);

  const [getURL, { data }] = useLazyQuery(gqlRecords.GET_URL, {
    fetchPolicy: 'network-only',
    variables: {
      folderId: attachment && attachment.folderId,
      key: attachment && attachment.name.split('/')[2],
      org: isSuser ? (state.selectedOrg ? state.selectedOrg.id : selOrg.id) : null,
    },
  });

  const refreshExpiredLink = () => {
    setUrl(null);
    setRetry(true);
    getURL();
  };

  const handleDocumentLoad = (e) => {
    setIsDocumentChanged(true);
  };

  const trackPositionPluginInstance = TrackPositionPlugin({
    url,
    screen: state.screen,
    trackPosition,
  });

  //useEffect(() => setViewer({ scale, offset }), []);
  //

  const renderError = (error: LoadError) => {
    let message = '';

    switch (error.name) {
      case 'InvalidPDFException':
        message = 'The document is invalid or corrupted';
        break;
      case 'MissingPDFException':
        refreshExpiredLink();
        message = 'The document is missing';
        break;
      case 'UnexpectedResponseException':
        refreshExpiredLink();
        message = 'Unexpected server response';
        break;
      case 'UnknownErrorException':
        refreshExpiredLink();
        message = 'Unexpected server response';
        break;
      case 'AbortException':
        refreshExpiredLink();
        message = 'Unexpected server response';
        break;
      case 'PasswordException':
        message = 'Password was not valid';
        break;
      default:
        //refreshExpiredLink();
        console.log(error);
        message = error.name;
        break;
    }

    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#e53e3e',
            borderRadius: '0.25rem',
            color: '#fff',
            padding: '0.5rem',
          }}
        >
          {message}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (currentAttachment && attachment && attachment.name === currentAttachment.name)
      return;
    if (attachment) {
      getURL();
      setCurrentAttachment(attachment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  useEffect(() => {
    if (data) {
      setUrl(data.GenerateGetURL);
      setRetry(false);
    }
  }, [data]);

  // useEffect(() => {
  //   if (state.viewer.scale !== 1 && !trackPosition.current) {
  //     setViewer({ scale: 1, offset: { top: 0, left: 0 } });
  //   }
  // }, [url]);

  console.log(state.viewer);
  if (attachment && url) {
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <Box sx={{ height, position: 'relative' }}>
          <Viewer
            fileUrl={url}
            renderError={renderError}
            RenderPageProps={{ width: '100%' }}
            plugins={[customToolbar, selectPages, trackPositionPluginInstance]}
            defaultScale={
              state.viewer.scale === 1 ? SpecialZoomLevel.PageFit : state.viewer.scale
            }
            onDocumentLoad={handleDocumentLoad}
            theme={state.paletteMode}
          />
          {record && (
            <StepActionContainer
              currentProcessStep={record.currentProcessStep}
              processPath={record.processPath}
              setMessage={setMessage}
              setSnackOpen={setSnackOpen}
              record={record}
              refetch={refetch}
            />
          )}
        </Box>
      </Worker>
    );
  } else {
    return null;
  }
};

export default PdfViewer;
