import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.common.lightblue
        : theme.palette.common.blueGrey['200'],
    '&.Mui-checked': {
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.common.blueShadow['300']
          : theme.palette.common.greyblueDarker,
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.common.blueGrey['200']
            : theme.palette.common.greyblue,
      },
    },
  },
}));

export default StyledSwitch;
