import {
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowClassNameParams,
  GridRowModel,
  GridSelectionModel,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import * as React from 'react';

import { Alert, AlertColor, Backdrop, Paper, Snackbar, Typography } from '@mui/material';
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { hasSetWorkflowPermissions } from '../../containers/search/searchUtils';
import { Context as AuthContext, AuthContextTypes } from '../../context/authContext';
import { Context as StatusContext } from '../../context/statusContext';
import { Context as TableContext } from '../../context/tableContext';
import { modifyLocalStorageObject, useLocalStorage } from '../../hooks/useLocalStorage';
import tableBgIndexing from '../../images/tablebg_indexing.jpg';
import ErrorHandler from '../../utils/errorHandler';
import utils from '../../utils/utils';
import FontAwesomeIcon from '../FAIcon';
import NotesModal from '../Notes/NotesModal';
import PopUp from '../PopUp';
import { PopUpState } from '../PopUp.types';
import StyledMasterTable from './StyledMasterTable';
import AddDropDownOption from './components/AddDropDownOption';
import ContextualMenu from './components/ContextualMenu';
import CustomFooter from './components/CustomFooter';
import CustomNoRowOverlay from './components/CustomNoRowsOverlay';
import CustomToolbar from './components/CustomToolbar';
import CustomLoadingOverlay from './components/LoadingOverlay';
import { WorkflowModal } from './components/WorkflowModal';
import {
  addRegexErrorClass,
  addRightClickedRowToSelected,
  catchDropdownSubmitKeys,
  emptyMainField,
  getColumnRegex,
  getColumnWithCellType,
  isTheSameValue,
  keepTrackOfEnteredValues,
  preventNextRowOnEnter,
  renderHeader,
  setHoldedValues,
  valueMatchesRegex,
} from './masterTableHelpers';
import {
  ContextMenuObject,
  FolderField,
  HandleUpload,
  MasterTableProps,
  Row,
} from './masterTableTypes';
import { debounce, isString } from 'lodash';

/**
 * @param {Object} props Array of functions and data
 * @param {Array} props.rows Array of rows, the objects must contain fields
 * @param {Function} props.setRows Function to allow modification of the rows
 * @param {Array} props.defaultVisibleRows - Array of the fields to use as headers
 * @param {Function} props.setLimit sets the limit of rows per page
 * @param {Number} props.limit current limit of rows per page
 * @param {Function} props.setCurrentPage Sets the page that is being fetched
 * @param {Number} props.recordCount the total amount of rows on the db
 * @param {Boolean} props.loading true when waiting for fetching result
 * @param {Function} props.onRecordClick Action to perform when clicking a row
 * @param {Function} props.onRowEdit Action to perform when editing a row
 * @param {Object} props.defaultTableOptions Determines which options are enabled
 * @param {Boolean} props.defaultTableOptions.quickView Allows quickview module
 * @param {Boolean} props.defaultTableOptions.edit Allows edit module
 * @param {Boolean} props.defaultTableOptions.search Allows search module
 * @param {Function} props.onDeleteButton Action to perform when pressing the delete button
 * @param {Function} props.setEditMode Function to turn off and on the edit module
 * @param {Function} props.setQuickView Function to turn off and on the quickView module
 * @param {Object} props.extraActions extra functions with buttons on the toolbar
 * @param {Function} props.createRecord Function to add a new row
 */

export default function MasterTable(props: MasterTableProps) {
  const {
    rows,
    setRows,
    defaultVisibleRows,
    setPageSize,
    pageSize,
    setCurrentPage,
    rowCount,
    loading,
    count,
    setAnchorUploadEl,
    anchorUploadEl,
    onRowEdit,
    defaultTableOptions,
    onDeleteButton,
    editMode,
    setEditMode,
    setErrorFiles,
    setLoadingFiles,
    handleUnindexRecords,
    handleReapplyLinkedInformations,
    loadingFiles,
    setQuickView = () => {},
    extraActions,
    createRecord,
    onSaveRows,
    selectedFolder,
    onEditRowsModelChange,
    onSortModelChange,
    onClickedRow = () => {},
    exportQuery,
    notesRefetch,
    fieldValues,
    copiedRows,
    setCopiedRows,
    rowsToUpdate,
    refetchFolder,
    clearFields,
    selectedTemplate,
    buttonReportRef,
    permissions: tablePermissions,
    paginationMode,
  } = props;
  const { state: authState } = React.useContext(AuthContext) as AuthContextTypes;
  const { RefreshCollectionBin, state } = React.useContext(StatusContext);
  const [{ tableSettings }, setTableSettings] = useLocalStorage('userSettings');
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const [userData] = useLocalStorage('userData');
  const [screenSelectedData, setScreenSelectedData] =
    useLocalStorage('screenSelectedData');
  const { selected, scroll } = screenSelectedData[`${state.screen}ScreenSelected`];
  const [rowState, setRowState] = React.useState<any[]>([]);
  const [colState, setColState] = React.useState<any[]>([]);
  const [isUploadFromDrag, setIsUploadFromDrag] = React.useState(false);
  const [contextMenu, setContextMenu] = React.useState<ContextMenuObject | null>(null);
  const [clickedRow, setClickedRow] = React.useState<string | null>();
  const [visibleCols, setVisibleCols] = React.useState(defaultVisibleRows);
  const [visibleColsFromLocal, setVisibleColsFromLocal] = React.useState<
    DataGridProps['columnVisibilityModel']
  >(
    utils.getVisibleColumnsData(
      tableSettings,
      state.screen,
      selectedFolder,
      defaultVisibleRows
    )
  );
  const [popUp, setPopUp] = React.useState({
    state: false,
    extra: {},
    action: 'saveMainField',
  });
  const [completedRow, setCompletedRow] = React.useState<GridRowModel>({});
  const [openNotes, setOpenNotes] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [snackOpen, setSnackOpen] = React.useState(false);
  // Message is the text that will go on the snack, and severity determines
  // the color of the message //success = green //warning = yellow //error = red
  const [message, setMessage] =
    React.useState<{ message: string; severity: AlertColor | undefined } | null>(null);
  // Triggers the popup for confirmation when editing the mainField
  const [editingMainField, setEditingMainField] = React.useState<any[]>([]);
  // Keeps track of the selected records
  const [selectionModel, setSelectionModel] = React.useState<any[]>([]);
  // Variable to keep the rows that were modified externaly i.e: indexing screen
  // Barcode scanning
  const [popUpText, setPopUpText] =
    React.useState(`Modifying this field will unlink all imported data from this record.
  Are you sure you want to continue?`);
  const [modeChange, setModeChange] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  const [dropdownModalOpen, setDropdownModalOpen] = React.useState(false);
  const [clickedField, setClickedField] = React.useState();
  const [newDropdownOption, setNewDropdownOption] = React.useState<string>();
  // This section have states that are used for the holdValue feature ===================
  const {
    setLastEnteredValues,
    setRecordToUpdate,
    restartCount,
    state: {
      lastEnteredValues,
      rowLoader,
      recordToUpdate,
      updatedRecord,
      textractProcessLoader,
    },
  } = React.useContext(TableContext);
  const [OCRRowsCompleted, setOCRRowsCompleted] = React.useState([]);
  const editingRow = React.useRef<GridRowModel>(null);
  const holdFieldValue = React.useRef<string[]>([]);
  // ==================================================================================================

  const el = document.querySelector('.MuiDataGrid-virtualScroller');

  const [workflowModal, setWorkflowModal] = React.useState(false);

  const handleUploadClick: HandleUpload = (event, isDrag) => {
    setAnchorUploadEl && setAnchorUploadEl(event.currentTarget);
    setIsUploadFromDrag(isDrag);
  };
  const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => {
    setOpenNotes(false);
    state.screen === 'search'
      ? notesRefetch(fieldValues[selectedFolder.id], false, true)
      : notesRefetch();

    setScreenSelectedData(
      modifyLocalStorageObject(
        {
          ...screenSelectedData,
          [`${state.screen}ScreenSelected`]: {
            ...screenSelectedData[`${state.screen}ScreenSelected`],
            selected: selectionModel,
          },
        },
        `${state.screen}ScreenSelected`
      )
    );
  };

  const handleCellKeyDown: DataGridProps['onCellKeyDown'] = (params, event, details) => {
    if (event.key === ' ') return (event.defaultMuiPrevented = true);
    preventNextRowOnEnter(event, params);
    // if (params.value === params.formattedValue) return;
    catchDropdownSubmitKeys(event, params, setDropdownModalOpen, setNewDropdownOption);
    const parent = document.querySelector('.MuiDataGrid-virtualScroller');
    const parentRect = parent?.getBoundingClientRect();
    let index = rows.findIndex((row) => row.id === params.id);
    if (index === -1) return;
    switch (event.key) {
      case 'ArrowDown':
        if (index + 1 <= rows.length - 1 && params.cellMode === 'view') {
          onClickedRow(rows[index + 1], event, details);
          const nextRowElement = document.querySelector(`[data-rowindex="${index + 1}"]`);
          if (nextRowElement) {
            const rect = nextRowElement.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
              nextRowElement.scrollIntoView({ block: 'end' });
            }
          }
        }
        break;
      case 'ArrowUp':
        if (index - 1 >= 0 && params.cellMode === 'view') {
          onClickedRow(rows[index - 1], event, details);
          const nextRowElement = document.querySelector(`[data-rowindex="${index - 1}"]`);
          const rowRect = nextRowElement?.getBoundingClientRect();
          if (
            nextRowElement &&
            rowRect &&
            parentRect &&
            parent &&
            rowRect.top < parentRect.top
          ) {
            const parentScrollTop = parent.scrollTop;
            const distanceOutside = parentRect.top - rowRect.top;
            parent.scrollTop = parentScrollTop - distanceOutside;
          }
        }
        break;
      case 'Enter':
        if (index + 1 <= rows.length - 1) {
          onClickedRow(rows[index + 1], event, details);
        }
        break;
      default:
        break;
    }
    if (event.key !== 'Tab') {
      return;
    }
    /* const parent = event.target.parentNode;
    const nextEl = event.target.nextElementSibling;
  
    if (!nextEl) {
      const mainParent = parent.parentNode.parentNode.parentNode;
      const nextRowSibling = parent.nextElementSibling;
      const nextRowSiblingFirstCol = nextRowSibling.childNodes[1];
      nextRowSiblingFirstCol.focus();
      const rowOffset = nextRowSiblingFirstCol.offsetLeft;
      mainParent.scrollLeft = rowOffset - 50;
      window.log(mainParent);
    } */
    /* const rowIds = gridVisibleSortedRowIdsSelector(apiRef.current.state);
    const visibleColumns = apiRef.current.getVisibleColumns();
  
    const nextCell = {
      rowIndex: rowIds.findIndex((id) => id === params.id),
      columnIndex: apiRef.current.getColumnIndex(params.field),
    };
  
    if (
      nextCell.columnIndex === visibleColumns.length - 1 &&
      nextCell.rowIndex === rowIds.length - 1 &&
      !event.shiftKey
    ) {
      // Do nothing if we are at the last cell of the last row
      return;
    }
  
    if (nextCell.columnIndex === 0 && nextCell.rowIndex === 0 && event.shiftKey) {
      // Do nothing if we are at the first cell of first row
      return;
    }
  
    event.preventDefault();
    event.defaultMuiPrevented = true;
  
    if (!event.shiftKey) {
      if (nextCell.columnIndex < visibleColumns.length - 1) {
        nextCell.columnIndex += 1;
      } else {
        nextCell.rowIndex += 1;
        nextCell.columnIndex = 0;
      }
    } else if (nextCell.columnIndex > 0) {
      nextCell.columnIndex -= 1;
    } else {
      nextCell.rowIndex -= 1;
      nextCell.columnIndex = visibleColumns.length - 1;
    }
    apiRef.current.scrollToIndexes(nextCell);
  
    const field = visibleColumns[nextCell.columnIndex].field;
    const id = rowIds[nextCell.rowIndex];
    apiRef.current.setCellFocus(id, field); */
  };

  const handleEditRowsModelChange: DataGridProps['onCellEditStart'] = React.useCallback(
    (model: GridCellParams, event: MuiEvent<MuiBaseEvent>) => {
      editingRow.current = rows.find((row) => row.id === model.id);

      let target = event.target;

      let parent = event.target.parentNode.parentNode.parentNode.parentNode;

      setHoldedValues(
        holdFieldValue,
        lastEnteredValues,
        model,
        setCopiedRows,
        copiedRows,
        rows
      );

      //event.target.focus();
      if (rows.length && !loading) {
        /* let record = rows.filter((row) => {
          return model.id === row.id;
        })[0]; */
        onClickedRow(model, event);

        if (target.offsetLeft + target.offsetWidth > parent.clientWidth) {
          parent.scrollTo(target.offsetLeft, 0);
        }
      }
    },
    [rows, loading, onClickedRow]
  );

  const handleSelectionModelChange: DataGridProps['onSelectionModelChange'] = (
    newModel: GridSelectionModel,
    details,
    e
  ) => {
    let selectedRows = rows.filter((row) => newModel.includes(row.id));
    setScreenSelectedData(
      modifyLocalStorageObject(
        {
          ...screenSelectedData,
          [`${state.screen}ScreenSelected`]: {
            selected: newModel,
            selectedRows: selectedRows,
          },
        },
        `${state.screen}ScreenSelected`
      )
    );
    // if (selectedRows.length) {
    //   const filter = selectedRows.filter((row) => !selectionModel.includes(row.id));
    //   if (filter.length > 0) {
    //     const lastID = filter[0].id;
    //     const attachment = {
    //       folderId: filter[0].folderId.id,
    //       name: filter[0].attachments[filter[0].attachments.length - 1].name,
    //     };
    //     if (setActiveRecordId) setActiveRecordId(lastID);
    //     setActiveAttachment(attachment);
    //     setIsDrawerOpen(true);
    //   }
    // }
    const checkedRecord = selectedRows.filter((row) => !selectionModel.includes(row.id));
    if (checkedRecord[0]) {
      const lastID = checkedRecord[0].id;
      let index: number = 0;
      rows.forEach((row, i) => (row.id === lastID ? (index = i) : false));
      onClickedRow(rows[index], e, details);
    }
    setSelectionModel(newModel);
  };

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const handleDelete = () => {
    handleCloseContextMenu();
    setPopUp({ state: true, extra: {}, action: 'delete' });
    const deleteCount =
      selectionModel.length > 0 ? selectionModel.length : selected.length;
    setPopUpText(`You're about to delete ${deleteCount} ${utils.getTypeOfEl(
      state.screen
    )}, this action can't be undone.
    Are you sure you want to continue?`);
  };

  const cleanSelected = () => {
    setScreenSelectedData(
      modifyLocalStorageObject(
        {
          ...screenSelectedData,
          [`${state.screen}ScreenSelected`]: {
            selected: [],
            selectedRows: [],
          },
        },
        `${state.screen}ScreenSelected`
      )
    );
  };

  const processRows = (rows: Row[]) => {
    return rows.map((row) => {
      let rowObj: any = { id: row.id };
      let multistringValues: { [id: string]: any } = {};
      for (let i = 0; i < row.fields.length; i++) {
        const field = row.fields[i];
        rowObj[field.field.name] = field.value;
        rowObj['fieldID'] = field.field.id;
        rowObj['i'] = i;
        rowObj.documentNotes = row.documentNotes;
        rowObj.fields = row.fields;
        utils.checkForMultistringFieldsAndGenerateValues(
          field,
          multistringValues,
          rowObj
        );
      }

      return rowObj;
    });
  };

  const processCols = (cols: FolderField[]) => {
    return cols.map((col, index) => {
      const regex = getColumnRegex(col);
      let column = getColumnWithCellType(
        col,
        defaultTableOptions,
        regex,
        state,
        setDropdownModalOpen,
        setNewDropdownOption,
        tableSettings,
        index === 0,
        selectedFolder,
        refetchFolder
      );

      addRegexErrorClass(col, column, regex);

      renderHeader(
        col,
        column,
        holdFieldValue,
        lastEnteredValues,
        state,
        setDropdownModalOpen,
        setClickedField,
        setTableSettings,
        selectedFolder,
        state.screen
      );

      return column;
    });
  };

  const handleEditCommit: DataGridProps['onCellEditCommit'] = async (params) => {
    if (isTheSameValue(params, editingRow)) return;

    keepTrackOfEnteredValues(lastEnteredValues, params, setLastEnteredValues, rows);

    let folderField = defaultVisibleRows?.filter((col) => {
      return col.name === params.field;
    })[0];

    if (!valueMatchesRegex(folderField, params, setSnackOpen, setMessage, state)) return;

    if (emptyMainField(folderField, params, setSnackOpen, setMessage, state)) return;

    let type = utils.determineFolderFieldType(folderField?.type);

    let value = params.value;
    if (isString(value)) value = value.trim();
    if (type === 'dateValue' && value instanceof Date !== true) {
      if (value === '' || !value) {
        value = null;
      } else {
        const date = utils.formatDateWithMoment(params.value);
        value = date !== 'Invalid date' ? moment(date, 'DD/MM/YYYY').toDate() : null;
      }
    }

    let values = [
      {
        folderId: selectedFolder.id,
        recordId: params.id,
        overwriteFields: false,
        fields: [
          {
            id: folderField?.id,
            [type]: value,
          },
        ],
      },
    ];
    // Check if the edited row has linked information or not
    const row = rows.filter((row) => row.id === values[0].recordId);
    const notLinkedInformation = !row[0].linkedInformation;

    if (folderField?.mainField && !notLinkedInformation && state.screen !== 'indexing') {
      setPopUp({ state: true, extra: params, action: 'saveMainField' });
      setPopUpText(`Modifying this field will unlink all imported data from this record.
      Are you sure you want to continue?`);

      setEditingMainField(values);
      return;
    } else if (state.screen !== 'indexing') {
      await onRowEdit?.(values);
      setRowState((prev) =>
        prev.map((row) => {
          return row.id === params.id ? { ...row, [params.field]: params.value } : row;
        })
      );
    } else {
      return;
    }
  };

  const handleAddToCollection = async () => {
    let rowsToAdd = rows.filter((row) => {
      return selectionModel.length
        ? selectionModel.includes(row.id)
        : selected.includes(row.id);
    });
    utils.addRecordToCollectionBin(rowsToAdd, selectedFolder ? selectedFolder : '');
    RefreshCollectionBin();
    cleanSelected();
  };

  const popUpAction = async (data: PopUpState) => {
    const { action, extra } = data;
    if (action === 'saveMainField') {
      await onRowEdit(editingMainField);
      setRowState((prev) =>
        prev.map((row) => {
          return row.id === extra.id ? { ...row, [extra.field]: extra.value } : row;
        })
      );
    }
    if (action === 'delete') {
      let rowsToDelete = selectionModel.length > 0 ? selectionModel : selected;

      await onDeleteButton(rowsToDelete);
      setProgress(100);
    }

    // add the right clicked row to the selected ones to perform an operation
    if (action === 'includeRow') {
      addRightClickedRowToSelected(
        selectionModel,
        clickedRow,
        setSelectionModel,
        rows,
        setScreenSelectedData,
        modifyLocalStorageObject,
        screenSelectedData,
        state
      );
    }
    setPopUp({ state: false, extra: {}, action: '' });
  };
  const popUpClose = (data: PopUpState) => {
    const { action } = data;
    setPopUp({ state: false, extra: {}, action: '' });
    setRowState((prev) => [...prev]);
  };
  const handleChangeMode = () => {
    setModeChange(!modeChange);
    setEditMode();
  };

  const onColumnVisibilityChange: DataGridProps['onColumnVisibilityModelChange'] = (
    params,
    details
  ) => {
    setVisibleColsFromLocal(params);
    setVisibleColsOnLocal(params);
  };

  const setVisibleColsOnLocal = (visibleCols: GridColumnVisibilityModel) => {
    let newSettings = { ...tableSettings };
    if (!newSettings[state.screen].visibleColumns)
      newSettings[state.screen].visibleColumns = {};
    newSettings[state.screen].visibleColumns[selectedFolder.id] = visibleCols;

    setTableSettings(
      modifyLocalStorageObject(
        {
          tableSettings: newSettings,
        },
        'userSettings'
      )
    );
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    // The row on which the contextual menu has been oppened
    const clickedRow = event.currentTarget.getAttribute('data-id');
    const getRecord = rows.find((record) => record.id === clickedRow);
    setSelectedRecord(getRecord);
    setClickedRow(clickedRow);
    onClickedRow(getRecord);

    // If the are selected rows but the menu has been opened on one that
    // hasn't been selected, ask if want to include for the next operation
    if (selectionModel.length && !selectionModel.includes(clickedRow)) {
      setPopUp({ state: true, extra: {}, action: 'includeRow' });
      setPopUpText(`Do you want to include this row?`);
    }

    // if the selected rows includes the clicked row
    if (selectionModel.includes(clickedRow)) {
      setScreenSelectedData(
        modifyLocalStorageObject(
          {
            ...screenSelectedData,
          },
          `${state.screen}ScreenSelected`
        )
      );

      // If there are no selected rows, the one on which the menu has been opened
      // will be used for the operation
    } else {
      const clickedRowData = rows.filter((row) => row.id === clickedRow);

      setScreenSelectedData(
        modifyLocalStorageObject(
          {
            ...screenSelectedData,
            [`${state.screen}ScreenSelected`]: {
              selected: [clickedRow],
              selectedRows: clickedRowData,
            },
          },
          `${state.screen}ScreenSelected`
        )
      );
    }

    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handlePageSizeChange: DataGridProps['onPageSizeChange'] = (pz, details) => {
    let noConfig = !tableSettings[state.screen]
      ? true
      : !tableSettings[state.screen].rowsPerPage
      ? true
      : !tableSettings[state.screen].rowsPerPage[selectedFolder.id]
      ? true
      : false;
    if (noConfig) {
      if (!tableSettings[state.screen].rowsPerPage) {
        tableSettings[state.screen]['rowsPerPage'] = {};
      }
      tableSettings[state.screen].rowsPerPage[selectedFolder.id] = pz;
    } else {
      tableSettings[state.screen].rowsPerPage = {
        ...tableSettings[state.screen].rowsPerPage,
        [selectedFolder.id]: pz,
      };
    }
    setUserSettings(
      modifyLocalStorageObject(
        {
          ...userSettings,
          tableSettings: { ...tableSettings },
        },
        'userSettings'
      )
    );
    setPageSize(pz);
  };

  const disableSorting = (data: GridColDef[]) => {
    const columns = data.map((column) => {
      if (column.field !== 'createdAt') {
        return {
          ...column,
          sortable: false,
        };
      } else {
        return {
          ...column,
          sortingOrder: ['desc', 'asc', null],
        };
      }
    });
    setColState(columns);
  };

  const generateRowClassName = React.useCallback(
    (params: GridRowClassNameParams) => {
      // we can add conditionals to set customs className and style according its status
      // the styles are added in RENDER CELL
      if (textractProcessLoader) {
        let row = textractProcessLoader.find((row) => row.recordId === params.row.id);
        return row ? 'row-status--textract-loading' : 'row-status--normal';
      }
      if (rowLoader && rowLoader.length) {
        let find = rowLoader.find((id: string) => id === params.row.id);
        return find ? 'row-status--row-loading' : 'row-status--normal';
      } else if (completedRow && completedRow.id === params.row.id) {
        return 'row-status--row-completed';
      } else if (selected[0] === params.row.id) {
        return 'Mui-selected';
      } else {
        return `row-status--${'normal' /* params.row.status */}`;
      }
    },
    [rowLoader, completedRow, selected, textractProcessLoader]
  );

  const clearFieldsAndHoldedValues = () => {
    if (lastEnteredValues && lastEnteredValues.length > 0) {
      const cleanEnteredValues = lastEnteredValues.map((field) => ({
        ...field,
        value: '',
      }));
      setLastEnteredValues(cleanEnteredValues);
    }
    clearFields(selectionModel);
  };

  React.useEffect(() => {
    // check for data stored in local storage
    if (Object.keys(tableSettings[state.screen]).length === 0) return;
    let pageLimit = !tableSettings[state.screen].rowsPerPage
      ? 100
      : tableSettings[state.screen].rowsPerPage[selectedFolder.id]
      ? tableSettings[state.screen].rowsPerPage[selectedFolder.id]
      : 100;
    setPageSize(pageLimit);
  }, [selectedFolder, setPageSize, tableSettings, state.screen]);

  React.useEffect(() => {
    if (state.screen === 'indexing') {
      disableSorting(processCols(defaultVisibleRows));
    } else {
      setColState(processCols(defaultVisibleRows));
    }
    setRowState(processRows(rows));

    // window.log(tableSettings[state.screen].visibleColumns);
    // setSelectionModel(selected);
  }, [rows, modeChange, defaultVisibleRows, selectedFolder]);

  React.useEffect(() => {
    if (!rowsToUpdate) return;
    // window.log('record to update: ', rowsToUpdate, rows, rowState);
    const newRows = rows.map((row) => {
      if (rowsToUpdate.recordID && row.id === rowsToUpdate.recordID) {
        row.fields = row.fields.map((field) => {
          if (rowsToUpdate.fields[field.field.id]) {
            for (let typeOfValue in rowsToUpdate.fields[field.field.id]) {
              window.log(typeOfValue);
              if (typeOfValue !== 'id') {
                field.value = rowsToUpdate.fields[field.field.id][typeOfValue];
              }
            }
          }
          return field;
        });
        setCompletedRow(row);
      }
      return row;
    });
    // window.log('new rows: ', newRows);
    setRowState(processRows(newRows));
  }, [rowsToUpdate]);

  React.useEffect(() => {
    // when the last record is completed, we create an Interval to clear that variable and delete styles
    let interval: NodeJS.Timer;
    if (selectionModel.length && selectionModel.length === updatedRecord) {
      interval = setInterval(() => {
        setCompletedRow({});
        setRecordToUpdate({});
        restartCount(0);
      }, 1500);
    }
    return () => clearInterval(interval);
  }, [completedRow, updatedRecord]);

  const handleScroll = debounce((e) => {
    setScreenSelectedData(
      modifyLocalStorageObject(
        {
          ...screenSelectedData,
          [`${state.screen}ScreenSelected`]: {
            ...screenSelectedData[`${state.screen}ScreenSelected`],
            scroll: e.target.scrollTop,
          },
        },
        `${state.screen}ScreenSelected`
      )
    );
  }, 100);

  React.useEffect(() => {
    if (!el) return;

    el.addEventListener('scroll', handleScroll);

    if (scroll) {
      el.scrollTop = scroll;
    }

    return () => {
      el.removeEventListener('scroll', handleScroll);
    };
  }, [el]);

  React.useEffect(() => {
    if (!selectedRecord) return;
    const obj = modifyLocalStorageObject(
      {
        ...screenSelectedData,
        [`${state.screen}ScreenSelected`]: {
          ...screenSelectedData[`${state.screen}ScreenSelected`],
          selected: [selectedRecord.id],
        },
      },
      `${state.screen}ScreenSelected`
    );

    setScreenSelectedData(obj);
  }, [selectedRecord]);

  let bg;
  switch (state.screen) {
    case 'indexing':
      bg = tableBgIndexing;
      break;
    default:
      bg = tableBgIndexing;
      break;
  }
  return (
    <Paper
      onDragEnter={(e) => {
        if (!anchorUploadEl) {
          handleUploadClick(e, true);
        }
      }}
      sx={{
        height: '100%',
        width: '100%',
        backgroundImage: state.screen === 'indexing' ? `url(${bg})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <ErrorHandler>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledMasterTable
            disableVirtualization
            //experimentalFeatures={{ newEditingApi: true }}
            columnVisibilityModel={visibleColsFromLocal}
            onColumnVisibilityModelChange={onColumnVisibilityChange}
            onSortModelChange={onSortModelChange}
            rows={rowState}
            columns={colState}
            pagination
            components={{
              Footer: CustomFooter,
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: CustomNoRowOverlay,
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              footer: {
                rows: rowState,
              },
              noRowsOverlay: {
                screen: state.screen,
              },
              toolbar: {
                screen: state.screen,
                rows: rows,
                setRows: setRows,
                selected: selectionModel,
                defaultTableOptions: defaultTableOptions,
                editMode: editMode,
                setEditMode: handleChangeMode,
                setQuickView: setQuickView,
                onDeleteButton: handleDelete,
                addToCollection: handleAddToCollection,
                handleReapplyLinkedInformations: handleReapplyLinkedInformations,
                unindexRecords: handleUnindexRecords,
                selectedFolder: selectedFolder,
                setWorkflowModal: setWorkflowModal,
                setSnackOpen: setSnackOpen,
                setMessage: setMessage,
                setErrorFiles: setErrorFiles,
                setLoadingFiles: setLoadingFiles,
                loadingFiles: loadingFiles,
                createRecord: createRecord,
                extraActions: extraActions,
                cols: visibleColsFromLocal,
                onSaveRows,
                exportQuery,
                anchorUploadEl,
                setAnchorUploadEl,
                handleUploadClick,
                isUploadFromDrag,
                count,
                clearFields: clearFieldsAndHoldedValues,
                selectedTemplate,
                buttonReportRef,
                tablePermissions: tablePermissions,
              },
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: 'context-menu' },
              },
            }}
            onPageChange={(page: number, details: any) => {
              setCurrentPage(page);
            }}
            onPageSizeChange={(pageSize, details) => {
              handlePageSizeChange(pageSize, details);
            }}
            onCellEditCommit={handleEditCommit}
            onSelectionModelChange={(newSelectionModel, details) => {
              handleSelectionModelChange(newSelectionModel, details);
            }}
            onEditRowsModelChange={onEditRowsModelChange}
            selectionModel={selectionModel}
            onCellEditStart={handleEditRowsModelChange}
            paginationMode={paginationMode || 'server'}
            sortingMode={paginationMode || 'server'}
            rowCount={rowCount}
            onCellKeyDown={handleCellKeyDown}
            onCellClick={(params, event, details) => {
              setSelectedRecord(params.row);
              setClickedField(params.field);
            }}
            onRowClick={(model, event, details) => {
              //handleEditRowsModelChange(model, event);
              onClickedRow(model, event, details);
            }}
            //Future change to suport indexing screen row editing
            editMode={editMode}
            showCellRightBorder={true}
            disableColumnFilter
            checkboxSelection
            disableSelectionOnClick
            pageSize={pageSize}
            BooleanCellTrueIcon={<FontAwesomeIcon icon="far fa-check-square" />}
            BooleanCellFalseIcon={<FontAwesomeIcon icon="far fa-minus-square" />}
            loading={loading}
            onRowDoubleClick={handleEditRowsModelChange}
            getRowClassName={generateRowClassName}
            disableColumnMenu
          />
        </LocalizationProvider>

        <Backdrop
          sx={{
            color: 'common.blue',
            position: 'absolute',
            backgroundColor: 'rgba(119, 142, 159, .5)',
            zIndex: 1,
          }}
          open={loading}
        />
        <ContextualMenu
          rows={rows}
          scan={extraActions?.scan}
          onSaveRows={onSaveRows}
          selectionModel={selectionModel}
          selectedRecord={selectedRecord}
          contextMenu={contextMenu}
          handleDelete={handleDelete}
          handleAddToCollection={handleAddToCollection}
          handleCloseContextMenu={handleCloseContextMenu}
          handleReapplyLinkedInformations={handleReapplyLinkedInformations}
          defaultVisibleRows={defaultVisibleRows}
          handleOpenNotes={handleOpenNotes}
          handleCloseNotes={handleOpenNotes}
          openNotes={openNotes}
          selectionModel={selectionModel}
          selected={selected}
          setWorkflowModal={setWorkflowModal}
        ></ContextualMenu>
      </ErrorHandler>
      <PopUp
        title="Attention!"
        isOpen={popUp.state}
        action={() => {
          popUpAction(popUp);
        }}
        close={() => {
          popUpClose(popUp);
        }}
        progress={progress}
      >
        <Typography>{popUpText}</Typography>
      </PopUp>
      {message && (
        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleCloseSnack}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnack}
            severity={message.severity}
          >
            {message.message}
          </Alert>
        </Snackbar>
      )}
      {openNotes && (
        <NotesModal
          openNotes={openNotes}
          handleCloseNotes={handleCloseNotes}
          setMessage={setMessage}
          setSnackOpen={setSnackOpen}
          record={selectedRecord}
          selectedFolder={selectedFolder}
        />
      )}
      <WorkflowModal
        workflowModal={workflowModal}
        setWorkflowModal={setWorkflowModal}
        selectedRecord={selectedRecord}
        records={rows}
        refresh={notesRefetch}
        selectionModel={selectionModel}
        selectedFolder={selectedFolder}
        canSetWorkflow={hasSetWorkflowPermissions(
          authState.permissions || null,
          userData,
          selectedFolder,
          false
        )}
      />
      <AddDropDownOption
        dropdownModalOpen={dropdownModalOpen}
        setDropdownModalOpen={setDropdownModalOpen}
        selectedFolder={selectedFolder}
        field={clickedField}
        refetchFolder={refetchFolder}
        setMessage={setMessage}
        setSnackOpen={setSnackOpen}
        newDropdownOption={newDropdownOption}
        setNewDropdownOption={setNewDropdownOption}
        context={'table'}
      />
    </Paper>
  );
}
