import { Box, Typography } from '@mui/material';
import React from 'react';

const DateTimeDisplay = ({ value, type }: { value: number; type: string }) => {
  return (
    <Box>
      <Typography fontSize={'40px'}>{String(value).padStart(2, '0')}</Typography>
      <Typography fontSize={'20px'}>{type}</Typography>
    </Box>
  );
};

export default DateTimeDisplay;
