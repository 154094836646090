import Typography from '@mui/material/Typography';

interface Props {
  title: string;
}

export default function ChartHeader({ title }: Props) {
  return (
    <Typography
      variant="subtitle1"
      component="h2"
      sx={{
        fontSize: 16,
        fontWeight: '500',
        padding: '5px 0',
        color: 'common.greyblueDark',
      }}
    >
      {title}
    </Typography>
  );
}
