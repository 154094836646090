import { useEffect, useMemo, useState } from 'react';
import FontAwesomeIcon from '../../FAIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  Input,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import { StyledLabel, StyledSelect } from './AdvancedSettingsModal';
import { generateScrollbar } from '../../theme';
import _ from 'lodash';
import { FolderFields } from '../../../containers/folder/types';

interface CalculatedPropertyAcordProps {
  generalStyles: any;
  disabled: boolean;
  folderFields: FolderFields[] | undefined;
  handleChangeSettings: any;
  settings: any;
}

type CalculatedStep = {
  stepNumber: number;
  stepType: 'FIELD_VALUE' | 'FIXED_VALUE' | 'OPERATION';
  value: string;
};

const ICONS = {
  '+': 'fas fa-plus',
  '-': 'fas fa-minus',
  '/': 'fas fa-divide',
  '%': 'fas fa-percentage',
  '*': 'fas fa-times',
};

const OPERATORS = {
  Math: [
    { label: 'plus', icon: ICONS['+'], value: '+' },
    {
      label: 'minus',
      icon: ICONS['-'],
      value: '-',
    },
    {
      label: 'divide',
      icon: ICONS['/'],
      value: '/',
    },
    {
      label: 'percentage',
      icon: ICONS['%'],
      value: '%',
    },
    {
      label: 'multiply',
      icon: ICONS['*'],
      value: '*',
    },
  ],
};

const scrollbar = generateScrollbar('2px', '2px');

const styles = {
  fieldBox: {
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.lightblue,
    flex: 1.2,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    overflow: 'auto',
    height: '170px',
    p: 1,
    borderRadius: '3px',
    ...scrollbar,
  },
  fieldItem: {
    fontSize: 13,
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#26292E' : theme.palette.common.greyblue,
    p: '0 4px',
    borderRadius: '3px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  operationButton: {
    padding: 1,
    borderRadius: '3px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%',
    '&:hover': { cursor: 'pointer' },
  },
  stepsContainer: {
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.lightblue,
    width: '80%',
    height: '170px',
    borderRadius: '3px',
    display: 'flex',
    alignContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    p: 1,
    gap: 1,
    overflow: 'auto',
    flex: 1.2,
    ...scrollbar,
  },
  operatorsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,
  },
};

const CalculatedPropertyAcord = ({
  generalStyles,
  disabled,
  folderFields,
  handleChangeSettings,
  settings,
}: CalculatedPropertyAcordProps) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedOperators, setSelectedOperators] =
    useState<keyof typeof OPERATORS>('Math');
  const [steps, setSteps] = useState<CalculatedStep[]>(
    settings?.calculated_Value ? settings.calculated_Value.formulaSteps : []
  );

  const nextStepShouldBe: CalculatedStep['stepType'] = useMemo(() => {
    let lastStep = _.last(steps);
    if (!lastStep) return 'FIELD_VALUE';
    return lastStep?.stepType === 'OPERATION' ? 'FIELD_VALUE' : 'OPERATION';
  }, [steps]);

  const handleAddStep = (
    stepType: CalculatedStep['stepType'],
    value: CalculatedStep['value']
  ) => {
    setSteps((prev) => {
      let newStep = { stepNumber: prev.length + 1, stepType, value };
      handleChangeSettings('calculated_Value.formulaSteps', [...prev, newStep]);
      return [...prev, newStep];
    });
  };

  const currencyFields: { [id: string]: string } = useMemo(() => {
    return folderFields
      ? folderFields?.reduce((acc, current) => {
          if (current.type === 'currency') {
            return { ...acc, [current.id]: current.name };
          } else {
            return acc;
          }
        }, {})
      : {};
  }, [folderFields]); // { "65f49f880663ca00124c8a97": "Currency"}

  const handleRemoveStep = (stepNumber: CalculatedStep['stepNumber']) => {
    const filteredSteps = _.reject(steps, (step) => {
      if (
        step.stepNumber === stepNumber ||
        (step.stepNumber === stepNumber + 1 && step.stepType === 'OPERATION')
      ) {
        return true;
      }
      return false;
    }).map((step, index) => ({
      ...step,
      stepNumber: index + 1,
    }));
    handleChangeSettings('calculated_Value.formulaSteps', filteredSteps);
    setSteps(filteredSteps);
  };

  const formulaToArray = (formula: string) => {
    // Convert "[Field One] + 20 + [Field Two]" => [ '[Field One]', '+', '20', '+', '[Field Two]' ]
    const regex = /\[[^\]]+\]|\S+/g;
    const matches = formula.match(regex);
    const result = matches ? matches.map((match) => match.trim()) : [];
    return result;
  };

  const handleAddStepFromInput = (value: any) => {
    const formula = formulaToArray(value);

    formula.forEach((item) => {
      if (OPERATORS[selectedOperators].some((operator) => operator.value === item)) {
        handleAddStep('OPERATION', item);
      } else if (item.startsWith('[') && item.endsWith(']')) {
        const fieldName = item.replace('[', '').replace(']', '');
        let fieldId = _.findKey(currencyFields, (value) => value === fieldName);
        fieldId && handleAddStep('FIELD_VALUE', fieldId);
      } else {
        handleAddStep('FIXED_VALUE', item);
      }
    });
  };

  const handleClearInput = () => {
    setSteps([]);
    handleChangeSettings('calculated_Value', null);
  };

  useEffect(() => {
    if (!disabled) return;
    setExpanded(false);
  }, [disabled]);

  return (
    <Accordion
      disableGutters
      disabled={disabled}
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="13px" />}
        sx={generalStyles.summary}
      >
        <Typography variant="button" sx={generalStyles.summaryTitle}>
          Calculated Property
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          ...generalStyles.details,
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '148px',
          }}
        >
          <StyledLabel>CALCULATION</StyledLabel>
          <IconButton
            disabled={steps.length === 0}
            onClick={handleClearInput}
            data-testId="clearSteps"
          >
            <FontAwesomeIcon icon="fas fa-redo" size={9} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
          <Box sx={styles.stepsContainer}>
            {steps.map((step) => {
              if (step.stepType === 'OPERATION') {
                return (
                  <Box
                    sx={{
                      ...styles.operationButton,
                      height: '16px',
                      width: '16px',
                      backgroundColor: 'common.greyblue',
                      color: 'common.white',
                    }}
                    key={step.stepNumber}
                  >
                    <FontAwesomeIcon
                      icon={`${ICONS[step.value as keyof typeof ICONS]}`}
                      size={8}
                    />
                  </Box>
                );
              } else {
                return (
                  <Box sx={{ width: '100%' }}>
                    <Chip
                      label={
                        step.stepType === 'FIXED_VALUE'
                          ? step.value
                          : currencyFields[step.value]
                      }
                      size="small"
                      onDelete={() => handleRemoveStep(step.stepNumber)}
                      sx={{
                        borderRadius: '3px',
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'dark'
                            ? '#26292E'
                            : theme.palette.common.greyblueDark,
                        color: 'common.white',
                        '& .MuiChip-deleteIcon path': { fill: '#B71C1C' },
                        '& .MuiChip-deleteIcon': { fontSize: 12 },
                        '.MuiChip-label': { fontSize: 11 },
                      }}
                    />
                  </Box>
                );
              }
            })}
            <Input
              sx={{ backgroundColor: 'transparent', width: '100%' }}
              disableUnderline
              onKeyDownCapture={(e) => {
                if (e && e.key === 'Enter') {
                  let target = e.target as HTMLInputElement;
                  handleAddStepFromInput(target.value);
                  return ((e.target as HTMLInputElement).value = '');
                }
              }}
            />
          </Box>
          <Box sx={styles.fieldBox}>
            {Object.entries(currencyFields).map(([id, label]) => (
              <Box
                key={id}
                sx={{
                  ...styles.fieldItem,
                  color:
                    nextStepShouldBe === 'OPERATION' ? 'common.grey' : 'common.white',
                }}
                onClick={() =>
                  nextStepShouldBe === 'FIELD_VALUE' && handleAddStep('FIELD_VALUE', id)
                }
              >
                {label}
              </Box>
            ))}
          </Box>
          <Box sx={styles.operatorsContainer}>
            <StyledSelect
              disableUnderline
              value={selectedOperators}
              onChange={(e) =>
                setSelectedOperators(e.target.value as keyof typeof OPERATORS)
              }
              size="small"
              sx={{ width: '100%' }}
            >
              {Object.keys(OPERATORS).map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </StyledSelect>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '5px 5px',
                // p: '5px 7px',
              }}
            >
              {OPERATORS[selectedOperators].map((item) => (
                <Box
                  sx={{
                    ...styles.operationButton,
                    backgroundColor:
                      nextStepShouldBe === 'FIELD_VALUE'
                        ? 'common.greyblueDark'
                        : 'common.greyblue',
                    color: (theme) =>
                      nextStepShouldBe === 'FIELD_VALUE'
                        ? theme.palette.mode === 'dark'
                          ? 'common.grey'
                          : 'common.lightblue'
                        : 'common.white',
                  }}
                  key={item.label}
                  onClick={() =>
                    nextStepShouldBe === 'OPERATION' &&
                    handleAddStep('OPERATION', item.value)
                  }
                >
                  <FontAwesomeIcon icon={item.icon} size={12} />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CalculatedPropertyAcord;
