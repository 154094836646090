import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Collapse,
  IconButton,
  IconButtonProps,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import FontAwesomeIcon from '../FAIcon';
import utils from '../../utils/utils';
import { Context as RTCContext } from '../../context/RTCContext';
import { Notification } from '../notificationPopup/NotificationPopup';
import { RTCContextTypes } from '../../context/RTCContextTypes';
import { TransitionGroup } from 'react-transition-group';

const StyledIconButton = styled((props: IconButtonProps) => <IconButton {...props} />)(
  ({ theme }) => ({
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    backgroundColor: theme.palette.common.greyblueDarker,
    boxShadow: 'inset 0px 5px 10px #00000045',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
  })
);

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.common.greyblueDarker,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.common.white,
    '& .Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}`,
  backgroundColor: theme.palette.common.greyblueDarker,
}));

interface NotificationContentProps {
  notifications: Notification[];
  closeNotifications: () => void;
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
}

const styles = {
  notificationContainer: {
    padding: '10px',
    boxShadow: '0px 10px 10px -10px #00000045',
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  iconContainer: {
    width: '6px',
    height: '6px',
    backgroundColor: 'common.orange',
    borderRadius: '50%',
    margin: '0 10px',
    position: 'absolute',
    top: '50%',
    left: 1,
  },
  icon: {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    backgroundColor: 'common.greyblueDarker',
    boxShadow: 'inset 0px 5px 10px #00000045',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '14px',
    marginLeft: '14px',
  },
  contentContainer: {
    width: '240px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actionContainer: {
    width: '20px',
    maxWidth: '20px',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

const NotificationContent = ({
  closeNotifications,
  notifications,
  setNotifications,
}: NotificationContentProps) => {
  const { state, removeTaskNotification, markOneAsRead } = useContext(
    RTCContext
  ) as RTCContextTypes;
  const [folders, setFolders] = useState();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string, notificationId: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      markOneAsRead(notificationId);
    };

  const handleRecordClick = (noti) => {
    if (noti.type === 'ApprovalRequired') {
      window.open(
        `${window.location.protocol}//${window.location.host}/dash/search/${noti.folderId}/${noti.recordId}`,
        '_blank',
        'noreferrer'
      );
      removeTaskNotification(noti.id);
    }
    // history.push('/dash/' + utils.determineScreenByCode(noti.code));
    markOneAsRead(noti._id);
    closeNotifications();
  };

  useEffect(() => {
    if (state.notifications.comm.length === 0) return setNotifications([]);
    let notificationsPopup = state.notifications.comm.filter(
      (noti) => noti.notificationType === 'popup'
    );
    setNotifications(notificationsPopup);
  }, [state]);

  const createNotificationList = (notificationsObj: Notification[]) => {
    return notificationsObj.map((noti, i) => {
      const recordInfo = utils.getNotificationRecordInfo(noti);

      return (
        <Collapse key={noti._id} timeout={520}>
          <Box sx={styles.notificationContainer}>
            {!noti.read && <Box sx={styles.iconContainer} />}
            {(noti.type === 'ApprovalRequired' || noti.type === 'ApprovalPerformed') &&
            noti.recordInfo ? (
              <StyledIconButton color="primary" aria-label="Go to record">
                {utils.notificationIcons(noti.code)}
              </StyledIconButton>
            ) : (
              <Box sx={styles.icon}>{utils.notificationIcons(noti.code)}</Box>
            )}
            <Box sx={styles.contentContainer}>
              <StyledAccordion
                expanded={expanded === `panel${i}`}
                onChange={handleChange(`panel${i}`, noti._id)}
              >
                <StyledAccordionSummary
                  expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="15px" />}
                  aria-controls="notification-message"
                  id="notification-message"
                >
                  <Typography sx={{ color: 'common.lightblue', fontWeight: 'bold' }}>
                    {noti.message}
                  </Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  {recordInfo && (
                    <Stack direction="row" justifyContent="space-between">
                      <Box>
                        <Stack direction="row" spacing={0.5}>
                          <Typography
                            sx={{ color: 'common.lightblue', fontWeight: 'bold' }}
                          >
                            {`${recordInfo.mainFieldName}:`}
                          </Typography>{' '}
                          <Typography sx={{ color: 'common.lightblue' }}>
                            {`${recordInfo.mainFieldValue}`}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography
                            sx={{ color: 'common.lightblue', fontWeight: 'bold' }}
                          >
                            Folder:&nbsp;
                          </Typography>
                          <Typography
                            sx={{ color: 'common.lightblue' }}
                          >{`${recordInfo.folderName}`}</Typography>
                        </Stack>
                      </Box>
                      <Tooltip title="Go to record" arrow>
                        <IconButton
                          onClick={() => handleRecordClick(noti)}
                          sx={{
                            color: 'white.main',
                            '& :hover': { color: 'common.orange' },
                          }}
                        >
                          <FontAwesomeIcon icon="fas fa-search" size="12px" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                </StyledAccordionDetails>
              </StyledAccordion>
            </Box>
            <Box sx={styles.actionContainer}>
              <Tooltip title="Delete" arrow>
                <span>
                  <IconButton
                    onClick={(e) => {
                      removeTaskNotification(noti._id);
                      let filter = notifications.filter(
                        (notification) => notification.id !== noti.id
                      );
                      setNotifications(filter);
                      e.stopPropagation();
                    }}
                    sx={{
                      color: 'white.main',
                      '& :hover': { color: 'common.negative' },
                    }}
                  >
                    <FontAwesomeIcon icon="fas fa-trash" size="12px" />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Collapse>
      );
    });
  };
  return (
    <Box>
      {state.notifications.comm.length > 0 ? (
        <TransitionGroup id="notifications-content">
          {createNotificationList(state.notifications.comm)}
        </TransitionGroup>
      ) : (
        <Box sx={{ textAlign: 'center', padding: '30px 0' }}>
          <Typography variant="subtitle4" sx={{ fontSize: 14 }}>
            No message notification
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificationContent;
