import { useQuery } from '@apollo/react-hooks';
import { useCallback, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { GET_ORG } from '../utils/gql';

import Header from '../components/Header';
import SideDrawer from '../components/SideDrawer';
import utils from '../utils/utils';
import ErrorLogs from './ErrorLogs';
import Folders from './Folders';
import Organisations from './Organisations';
import Search from './Search';
import Users from './Users';
import Indexing from './indexing/Indexing';

import { Box, LinearProgress, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useLiveQuery } from 'dexie-react-hooks';
import NotificationPopupHandler from '../components/notificationPopup/NotificationPopupHandler';
import Timer from '../components/timer';
import DashboardMain from '../containers/dashboard/DashboardMain';
import { Context as RTCContext } from '../context/RTCContext';
import { RTCContextTypes } from '../context/RTCContextTypes';
import { Context as AuthContext, AuthContextTypes } from '../context/authContext';
import { Context as StatusContext } from '../context/statusContext';
import { StatusContextTypes } from '../context/statusContextTypes';
import { Context as TableContext } from '../context/tableContext';
import { Context as TaskContext } from '../context/tasksContext';
import { TasksContextTypes } from '../context/tasksContextTypes';
import useInterval from '../hooks/useInterval';
import { checkUS, initLocalStorage, useLocalStorage } from '../hooks/useLocalStorage';
import useMaintenance from '../hooks/useMaintenance';
import ErrorHandler from '../utils/errorHandler';
import { GET_SUSER, GET_USER } from '../utils/gql/gqlUsers';
import { db } from '../utils/indexedDB/indexeddb';
import RTC from '../utils/socketComm';
import Communication from './Communication';
import InMaintenance from './InMaintenance/InMaintenance';
import Maintenance from './Maintenance';
import Reporting from './Reporting';
import WorkFlow from './WorkFlow';

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: '10px 10px',
  overflowY: 'auto',
  height: 'auto',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    height: 'auto',
    flexDirection: 'row',
  },
}));
const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    backgroundColor: 'background.default',
    overflow: 'hidden',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: 'calc(100% - 44px)',
    backgroundColor: 'background.default',
  },
  content: {
    padding: 3,
  },
  versioning: {
    position: 'absolute',
    color: '#ccc',
    fontSize: 10,
    bottom: 10,
    right: 10,
  },
  logo: {
    color: 'common.white',
    textAlign: 'center',
  },
  underlogo: {
    textAlign: 'center',
    marginBottom: 30,
    color: 'common.white',
  },
  version: {
    color: 'white',
  },
  loading: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingBar: {
    width: 400,
  },
  notificationStack: {
    height: '90%',
    position: 'absolute',
    top: '50px',
    right: '20px',
    gap: '10px',
    overflow: 'hidden',
  },
};

function Dashboard() {
  let localIsLogged = localStorage.getItem('isLoggedIn');
  let history = useHistory();
  if (!utils.getCookie('data')) {
    if (history.location.pathname !== '/') {
      localStorage.setItem('previousPage', history.location.pathname);
    }

    utils.clearUserData(history.location.pathname);
    history.push('/');
    window.location.reload();
  }
  if (localIsLogged === null || localIsLogged === 'false') {
    if (history.location.pathname !== '/')
      localStorage.setItem('previousPage', history.location.pathname);
    history.push('/');
    window.location.reload();
  }

  // hook that generate an interval where indexedDB o whatever is necessary is checked
  useInterval();
  const mobile = useMediaQuery('(max-width:600px)');
  const { maintenance, data: maintenanceData } = useMaintenance();
  const rtcContext = useContext(RTCContext) as RTCContextTypes;
  const tasksContext = useContext(TaskContext) as TasksContextTypes;
  const tableContext = useContext(TableContext);
  const { setPermissions } = useContext(AuthContext) as AuthContextTypes;
  const { state, setCurrentOrg, setPaletteMode } = useContext(
    StatusContext
  ) as StatusContextTypes;
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage('isLoggedIn', true);
  const [appversion, setappversion] = useLocalStorage('appversion');
  const [userData] = useLocalStorage('userData');
  const { selOrg, id, role } = userData;
  const { loading, data } = useQuery(GET_ORG, {
    onCompleted: () => {
      if (data) {
        setCurrentOrg(data.Organisation.organisation);
        setIsLoggedIn(true);
      }
      if (data.errors) {
        data.errors.forEach(({ message }: { message: string }) => {
          if (message === 'You are not authorized!' || message === 'Invalid Token') {
            utils.clearUserData();
            setIsLoggedIn(false);
          }
        });
      }
    },
    onError: (err) => {
      if (err.graphQLErrors) {
        err.graphQLErrors.forEach(({ message }: { message: string }) => {
          if (message === 'You are not authorized!' || message === 'Invalid Token') {
            utils.clearUserData();
            setIsLoggedIn(false);
          }
        });
      }
      if (err) {
        utils.clearUserData();
        setIsLoggedIn(false);
      }
    },
  });
  useQuery(role < 0 ? GET_SUSER : GET_USER, {
    variables: { id: id },
    onCompleted: (data) => {
      if (data && id) {
        setPermissions(
          role < 0 ? data.SUser.superUser.permissions : data.User.user.permissions
        );
      }
    },
  });
  const items = useLiveQuery(async () => {
    return {
      taskNotifications: await db.table('taskNotifications').toArray(),
      searchScreen: await db.table('searchScreen').toArray(),
      indexingScreen: await db.table('indexingScreen').toArray(),
    };
  }, []);

  const sendData = useCallback(() => {
    if (!items || !rtcContext) return;
    let lsData = { ...window.localStorage };
    let blackList = ['userData']; // this information should not be sent to the DB.
    blackList.forEach((item) => delete lsData[item]);
    Object.entries(lsData).forEach(([key, value]) => {
      lsData[key] = JSON.parse(lsData[key]);
    });
    lsData = checkUS(lsData);
    RTC.sendData('update-user-settings', {
      indexedDB: JSON.stringify(items),
      localStorageSettings: JSON.stringify(lsData),
    });
  }, [items]);

  useEffect(() => {
    // window.log(appversion);
    let versions = {
      'v2.0.1': 1,
      'v2.0.2': 2,
      'v2.0.3': 3,
    };
    if (
      process.env.REACT_APP_VERSION &&
      versions[appversion as keyof typeof versions] <
        versions[process.env.REACT_APP_VERSION as keyof typeof versions]
    ) {
      console.log('your version of the app is older');
      localStorage.setItem('appversion', `"${process.env.REACT_APP_VERSION}"`);
      initLocalStorage();
    }
    if (!items) return;
    sendData();
  }, [items, sendData]);

  useEffect(() => {
    window.addEventListener('storageChange', sendData);
    return () => window.removeEventListener('storageChange', sendData);
  });

  useEffect(() => {
    RTC.init(selOrg, rtcContext, tasksContext, tableContext, userData);
    rtcContext.setSocketConnection(RTC);

    const getNotifications = async () => {
      const taskNotifications = await db.table('taskNotifications').toArray();

      if (taskNotifications) {
        rtcContext.addTaskNotificationAsync(taskNotifications);
      }
    };
    const getTasks = async () => {
      const task = await db.table('tasks').toArray();

      if (task) {
        tasksContext.addTasksAsync(task);
      }
    };

    //delete task older than 24hrs
    db.table('tasks')
      .where('timestamp')
      .below(Date.now() - 86400000)
      .delete();

    getTasks();
    getNotifications();
  }, []);

  if (maintenance && role >= 0 && maintenanceData) {
    return <InMaintenance data={maintenanceData} />;
  }

  if ((loading && localIsLogged) || (isLoggedIn && !data))
    return (
      <Box
        sx={{
          ...classes.loading,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? 'common.greyblueDarker' : 'common.blue',
        }}
      >
        <Box sx={classes.loadingBar}>
          <Typography variant="h2" sx={classes.logo}>
            DataCapture
          </Typography>
          <Typography variant="h6" sx={classes.underlogo}>
            Loading Application
          </Typography>
          {process.env.ENVIRONMENT === 'beta' ||
          process.env.ENVIRONMENT === 'development' ? (
            <Typography sx={classes.version}>BETA 2.0</Typography>
          ) : null}
          <LinearProgress color="secondary" />
        </Box>
      </Box>
    );

  if (isLoggedIn && data && localIsLogged) {
    return (
      <ErrorHandler>
        <Stack sx={classes.notificationStack}>
          <NotificationPopupHandler />
        </Stack>

        <Box sx={classes.root}>
          <Header appversion={appversion} setappversion={setappversion}></Header>
          {/* <SideNav /> */}
          <Box sx={classes.main}>
            {mobile && state.screen === 'record' ? '' : <SideDrawer />}
            <StyledContainer maxWidth={'100%'}>
              <Switch>
                <Route path="/dash" exact component={DashboardMain} />
                <Route path="/dash/search" component={Search} />
                <Route path="/dash/users" component={Users} />
                <Route path="/dash/folders" component={Folders} />
                <Route path="/dash/indexing" component={Indexing} />
                <Route path="/dash/orgs" component={Organisations} />
                <Route path="/dash/errorLogs" component={ErrorLogs} />
                <Route path="/dash/reports" component={Reporting} />
                <Route path="/dash/communication" component={Communication} />
                <Route path="/dash/maintenance" component={Maintenance} />
                {state.workflow && <Route path="/dash/workflow" component={WorkFlow} />}
                {/* <Route path="/dash" component={Statistics} /> */}
                <Route
                  render={() => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant="button" sx={{ fontSize: 18 }}>
                        There's a problem with the URL you're trying to access.
                      </Typography>
                      <Typography variant="button" sx={{ fontSize: 14 }}>
                        <a href="/dash/search" style={{ color: 'red' }}>
                          click here
                        </a>{' '}
                        to go back to Search
                      </Typography>
                    </Box>
                  )}
                />
              </Switch>
              {process.env.ENVIRONMENT === 'beta' ||
              process.env.ENVIRONMENT === 'development' ? (
                <Typography sx={classes.versioning}>BETA 2.0</Typography>
              ) : null}
            </StyledContainer>
          </Box>
        </Box>
        <Timer></Timer>
      </ErrorHandler>
    );
  } else if (!isLoggedIn || !localIsLogged) {
    return <Redirect to="/" />;
  }
}

export default Dashboard;
