import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import FontAwesomeIcon from '../FAIcon';
import { Box, Collapse } from '@mui/material';

interface SearchInputProps {
  handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSearchQuery?: React.Dispatch<React.SetStateAction<string | null>>;
  margin?: number | string;
  height?: number | string;
  width?: number | string;
  buttonSize?: number | string;
  inputFontSize?: number | string;
  sx?: any;
  colorIcon?: any;
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  height: 'auto',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    cursor: 'pointer',
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}));

const SearchInput = ({
  handleSearch,
  setSearchQuery,
  margin = '10px 0',
  height = '30px',
  width = '100%',
  buttonSize = '15px',
  inputFontSize = 12,
  colorIcon = 'common.white',
  sx = { root: {}, input: {} },
  ...props
}: SearchInputProps) => {
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [value, setValue] = useState('');

  const styles = {
    marginLeft: '10px',
    flex: 1,
    paddingTop: 3,
    width: { xs: '65%', sm: 'auto' },
    padding: 1,
    '& .MuiInputBase-input': {
      color: '#fff',
      width: '100%',
      fontSize: inputFontSize,
      backgroundColor: 'transparent',
      '&::placeholder': {
        color: 'white',
      },
    },
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let target = e.target as HTMLInputElement;
    if (target.name === 'input') return;
    if (searchIsActive) {
      setSearchIsActive(false);
      setSearchQuery && setSearchQuery(null);
    } else {
      setSearchIsActive(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setSearchIsActive(true);
    handleSearch && handleSearch(e);
  };

  return (
    <Search onClick={(e) => handleClick(e)} sx={{ margin, height, width, ...sx['root'] }}>
      <InputBase
        autoComplete="off"
        id="search-input"
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search...' }}
        onChange={handleChange}
        sx={{ ...styles, ...sx['input'] }}
        value={value}
        name="input"
      />
      <Box>
        <IconButton
          sx={{ color: colorIcon, width: '32px' }}
          aria-label="search"
          onClick={searchIsActive ? () => setValue('') : () => {}}
          id="search-button"
        >
          {searchIsActive ? (
            <FontAwesomeIcon icon="far fa-times" size={buttonSize} />
          ) : (
            <FontAwesomeIcon icon="fal fa-search" size={buttonSize} />
          )}
        </IconButton>
      </Box>
    </Search>
  );
};

export default SearchInput;
