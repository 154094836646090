import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { User } from '../../../../components/masterTable/masterTableTypes';

interface CheckboxGroupProps {
  users: User[];
  handleUpdateStep: (stepOrder: number, key: string, value: any) => void;
  stepOrder: number;
  toBeApprovedBy: string[];
}

const CheckboxUsersList = ({
  users,
  handleUpdateStep,
  stepOrder,
  toBeApprovedBy,
}: CheckboxGroupProps) => {
  const isChecked = (id: User['id']) =>
    toBeApprovedBy ? toBeApprovedBy.includes(id) : false;

  const handleChange = (id: User['id']) => {
    if (!isChecked(id)) {
      return handleUpdateStep(stepOrder, 'toBeApprovedBy', [
        ...(toBeApprovedBy || []),
        id,
      ]);
    } else {
      let res = toBeApprovedBy.filter((userID) => userID !== id);
      return handleUpdateStep(stepOrder, 'toBeApprovedBy', res);
    }
  };

  return (
    <FormGroup sx={{ marginLeft: 2, marginTop: 1 }}>
      {users.length ? (
        users.map((user) => (
          <FormControlLabel
            key={user.id}
            control={
              <Checkbox
                checked={toBeApprovedBy ? toBeApprovedBy.includes(user.id) : false}
                name={`${user.firstName} ${user.lastName}`}
                onChange={() => handleChange(user.id)}
                size="small"
                sx={{
                  transform: 'scale(0.8)',
                  p: '4px',
                  // color: 'common.greyblueDark',
                  '&.Mui-checked': {
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? 'common.lightblue'
                        : 'common.greyblueDarker',
                  },
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              />
            }
            label={`${user.firstName} ${user.lastName}`}
            id={`${user.firstName} ${user.lastName}`}
            sx={{
              '.MuiFormControlLabel-label': {
                textTransform: 'uppercase',
                fontWeight: 400,
                fontSize: 11,
              },
            }}
          />
        ))
      ) : (
        <Typography
          sx={{
            color: 'common.greyblueDark',
            textTransform: 'uppercase',
            fontWeight: 400,
            fontSize: 10,
            marginTop: 2,
            width: '180px',
          }}
        >
          There are no users with permissions on this folder
        </Typography>
      )}
    </FormGroup>
  );
};

export default CheckboxUsersList;
