import { Box, Divider, Stack, Typography, IconButton } from '@mui/material';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import _ from 'lodash';

interface FieldItem {
  id: string;
  label: string;
  property: string;
  draggableId: string;
}

const styles = {
  box: {
    width: '238px',
    height: '285px',
    borderRadius: '3px',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    overflowY: 'auto',
  },
  title: {
    color: '#8399A8',
    fontSize: '12px',
  },
};

const FIELDS: FieldItem[] = [
  { id: '1', draggableId: '1', label: 'POD No', property: 'field-list' },
  { id: '2', draggableId: '2', label: 'Delivery Date', property: 'field-list' },
  { id: '3', draggableId: '3', label: 'Customer Code', property: 'field-list' },
  { id: '4', draggableId: '4', label: 'Invoice No', property: 'field-list' },
  { id: '5', draggableId: '5', label: 'POD No2 ', property: 'field-list' },
  { id: '6', draggableId: '6', label: 'Delivery Date 2', property: 'field-list' },
  { id: '7', draggableId: '7', label: 'Invoices', property: 'field-list' },
  { id: '8', draggableId: '8', label: 'Tracking', property: 'field-list' },
  { id: '9', draggableId: '9', label: 'POD No3', property: 'field-list' },
];

const BOXES = [
  { id: 'default', label: 'Default Properties' },
  { id: 'non-ocr', label: 'Non OCR Properties' },
  { id: 'restricted', label: 'Restricted verification properties' },
  { id: 'predefined', label: 'Predefined properties (PDF READ)' },
  { id: 'duplicate', label: 'Duplicate check properties' },
];

const FieldComponent = ({
  field,
  index,
  box,
  removeFieldFromProperties,
}: {
  field: FieldItem;
  index: number;
  box?: string;
  removeFieldFromProperties: (draggableId: string) => void;
}) => {
  return (
    <Draggable
      draggableId={`${field.draggableId}`}
      index={index}
      isDragDisabled={field.property !== 'field-list' && box === 'field-list'}
    >
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            width: '100%',
            height: '50px',
            backgroundColor: '#8399A8',
            borderRadius: '3px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1.5,
          }}
        >
          <Typography
            variant="button"
            sx={{
              fontSize: '11px',
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'common.greyblueDarker' : 'common.white',
            }}
          >
            {field.label}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Divider orientation="vertical" />
            {field.property === 'field-list' ? (
              <FontAwesomeIcon
                icon="fa-solid fa-list"
                size="12px"
                color="common.greyblueDarker"
              />
            ) : (
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => removeFieldFromProperties(field.draggableId)}
              >
                <FontAwesomeIcon
                  icon="fas fa-trash"
                  size="12px"
                  color="common.greyblueDarker"
                />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

const ProcessingTab = () => {
  const [fields, setFields] = useState(FIELDS);
  const [groups, setGroups] = useState(_.groupBy(FIELDS, 'property'));

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const sourceField = fields.find((field) => field.draggableId === result.draggableId);
    if (!sourceField) return;

    const updatedFields = [...fields];
    const filteredFields = updatedFields.filter((field) => field.id !== sourceField.id);
    sourceField.property = result.destination.droppableId;
    filteredFields.splice(result.destination.index, 0, sourceField);
    setGroups(_.groupBy(filteredFields, 'property'));
    setFields(filteredFields);
  };

  const removeFieldFromProperties = (draggableId: string) => {
    const newFields = fields.map((ff) =>
      ff.draggableId === draggableId
        ? { ...ff, property: 'field-list', draggableId: `field-list-${draggableId}` }
        : ff
    );
    setGroups(_.groupBy(newFields, 'property'));
    setFields(newFields);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box sx={{ height: '100%', display: 'flex', gap: 7 }}>
        <Stack
          sx={{
            height: '100%',
            width: '238px',
            gap: 1.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Typography sx={{ color: '#8399A8' }}>Search</Typography>
            <FontAwesomeIcon icon="fas fa-search" size="15px" color="#8399A8" />
          </Box>
          <Stack
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#2D333A' : 'common.greyblueDarker',
              p: 3,
              height: '100%',
            }}
          >
            <Droppable droppableId="field-list">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                >
                  {fields.map((field, index) => (
                    <FieldComponent
                      key={`field-list-${field.draggableId}`}
                      index={index}
                      field={field}
                      box="list"
                      removeFieldFromProperties={removeFieldFromProperties}
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Stack>
        </Stack>
        <Stack
          sx={{
            height: '100%',
            flex: 4,
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 7,
            maxWidth: '1000px',
          }}
        >
          {BOXES.map((box) => (
            <Droppable droppableId={box.id} key={box.id}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ transition: 'none' }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.2 }}>
                    <Typography variant="button" sx={styles.title}>
                      {box.label}
                    </Typography>
                    <Box
                      sx={{
                        ...styles.box,
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'dark'
                            ? '#2D333A'
                            : 'common.greyblueDarker',
                      }}
                    >
                      {groups[box.id] ? (
                        groups[box.id].map((field, index) => (
                          <FieldComponent
                            key={`field-${field.draggableId}`}
                            index={index}
                            field={field}
                            removeFieldFromProperties={removeFieldFromProperties}
                          />
                        ))
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            gap: 2,
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fas fa-hand-paper"
                            size="20px"
                            color="#8399A8"
                          />
                          <Typography
                            sx={{
                              color: '#8399A8',
                              textAlign: 'center',
                              fontWeight: 700,
                            }}
                          >
                            Drag a field from the left bar to add them to this properties
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </Stack>
      </Box>
    </DragDropContext>
  );
};

export default ProcessingTab;
