import React, { useContext, useState } from 'react';
import { Box, Badge, IconButton, Tooltip, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import FontAwesomeIcon from '../FAIcon';
import NotificationToolbar from './NotificationToolbar';
import NotificationContent from './NotificationContent';
import { Context as RTCContext } from '../../context/RTCContext';
import { RTCContextTypes } from '../../context/RTCContextTypes';
import { Notification } from '../notificationPopup/NotificationPopup';

interface NotificationTrayProps {
  setAnchorNotification: React.Dispatch<
    React.SetStateAction<(EventTarget & HTMLButtonElement) | null>
  >;
  anchorNotification: HTMLAnchorElement | null;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 15,
    fontSize: '0.5rem',
    color: theme.palette.common.greyblueDarker,
    height: '1rem',
    width: '1rem',
    minWidth: '1rem',
    padding: '0',
  },
}));
const StyledPopover = styled(Popover)(({ theme }) => ({
  '& 	.MuiPopover-paper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '0px 10px 20px',
  },
}));

const notify = [
  {
    message: 'A record has finished scanning',
    screen: 'Indexing',
    code: 'T001',
    action: 'go to Indexing',
    type: 'Tasks',
  },
  {
    message: 'A record has finished scanning',
    screen: 'Indexing',
    code: 'T001',
    action: 'go to Indexing',
    type: 'Tasks',
  },
  {
    message: 'A record has been added to the indexing list',
    screen: 'Indexing',
    code: 'T002',
    action: 'go to Indexing',
    type: 'Tasks',
  },
];

export default function NotificationTray({
  setAnchorNotification,
  anchorNotification,
}: NotificationTrayProps) {
  const {
    removeAllTaskNotification,
    removeTaskNotification,
    markAllAsRead,
    markOneAsRead,
    state,
  } = useContext(RTCContext) as RTCContextTypes;
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const openNotifications = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorNotification(event.currentTarget);
  };

  const closeNotifications = () => {
    setAnchorNotification(null);
  };

  const open = Boolean(anchorNotification);
  const id = open ? 'notificationTray' : undefined;
  let unreadNotifications = state.notifications.comm.filter(
    (noti) => noti.read !== true && noti.notificationType === 'popup'
  );
  return (
    <>
      <Tooltip title="Notifications" arrow>
        <IconButton
          onClick={anchorNotification ? closeNotifications : openNotifications}
          aria-label="Notifications"
          sx={{
            padding: '4px 10px',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:hover .MuiIcon-root': {
              color: 'common.blue',
            },
          }}
        >
          <StyledBadge badgeContent={unreadNotifications.length} color="secondary">
            <FontAwesomeIcon icon="fas fa-bell" size="18px" color="#fff" />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorNotification}
        onClose={closeNotifications}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => e.stopPropagation()}
        elevation={0}
        PaperProps={{ sx: { backgroundColor: 'transparent' } }}
      >
        <Box
          sx={{
            border: '10px solid transparent',
            borderBottomColor: 'common.greyblueDarker',
            width: '0',
            maxWidth: '0',
            position: 'relative',
            left: '80px',
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: 'common.greyblueDarker',
            borderRadius: '5px',
            boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
            color: 'white.main',
            minWidth: '350px',
            paddingBottom: '10px',
          }}
        >
          <NotificationToolbar
            removeAllTaskNotification={removeAllTaskNotification}
            markAllAsRead={markAllAsRead}
            commNotifications={state.notifications.comm}
          ></NotificationToolbar>
          <NotificationContent
            closeNotifications={closeNotifications}
            notifications={notifications}
            setNotifications={setNotifications}
          />
        </Box>
      </StyledPopover>
    </>
  );
}
