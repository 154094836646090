import React, { useContext } from 'react';
import { Button, MobileStepper, Typography } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';
import { Features } from './NewFeatures';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';

interface StepperProps {
  data: Features;
  activeStep: number;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: () => void;
  handleBack: () => void;
  appversion: keyof Features;
}

const Stepper = ({
  data,
  activeStep,
  setModalIsOpen,
  handleNext,
  handleBack,
  appversion,
}: StepperProps) => {
  const { state } = useContext(StatusContext) as StatusContextTypes;
  return (
    <MobileStepper
      variant="dots"
      steps={data[appversion].length}
      position="static"
      activeStep={activeStep}
      sx={{
        width: '100%',
        flexGrow: 1,
        backgroundColor: 'transparent',
        padding: 0,
        '& .MuiMobileStepper-dotActive': {
          backgroundColor:
            state.paletteMode === 'dark' ? 'common.white' : 'common.greyblueDark',
        },
      }}
      nextButton={
        <Button
          variant={activeStep === data[appversion].length - 1 ? 'contained' : 'text'}
          size="small"
          onClick={
            activeStep === data[appversion].length - 1
              ? () => setModalIsOpen(false)
              : handleNext
          }
          sx={{
            gap: 1,
            backgroundColor:
              activeStep === data[appversion].length - 1 ? '#79909f' : 'transparent',
            '&:hover': {
              backgroundColor:
                activeStep === data[appversion].length - 1 ? '#79909f' : 'transparent',
            },
          }}
        >
          <Typography
            variant="button"
            sx={{
              color:
                activeStep === data[appversion].length - 1
                  ? 'common.white'
                  : state.paletteMode === 'dark'
                  ? 'common.white'
                  : 'common.greyblueDark',
            }}
          >
            {activeStep === data[appversion].length - 1 ? 'Close' : 'Next'}
          </Typography>
          {activeStep !== data[appversion].length - 1 && (
            <FontAwesomeIcon
              icon="fa-solid fa-caret-right"
              size={15}
              color={
                state.paletteMode === 'dark' ? 'common.white' : 'common.greyblueDark'
              }
            />
          )}
        </Button>
      }
      backButton={
        <Button
          variant="text"
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ gap: 1, visibility: activeStep === 0 ? 'hidden' : 'visible' }}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-caret-left"
            size={15}
            color={state.paletteMode === 'dark' ? 'common.white' : 'common.greyblueDark'}
          />
          <Typography
            variant="button"
            sx={{
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblueDark',
            }}
          >
            Back
          </Typography>
        </Button>
      }
    />
  );
};

export default Stepper;
