import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import DebugConsoleBody from './DebugConsoleBody';
import DebugConsoleCaller from './DebugConsoleCaller';
import React, { useState } from 'react';
import FontAwesomeIcon from '../FAIcon';

interface DebugConsoleMainProps {
  isOpen: boolean;
  handleClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const styles = {
  container: {
    width: 700,
    height: '100%',
    boxShadow: 24,
  },
  box: {
    height: 'calc(100% - 54px)', // title height
  },
  title: {
    color: 'common.orange',
    fontSize: 20,
    fontWeight: 700,
  },
  titleContainer: {
    boxShadow: '-1px 3px 6px 0px rgba(0,0,0,0.35)',
    padding: '12px 32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

const DebugConsoleMain = ({ isOpen, handleClose }: DebugConsoleMainProps) => {
  const [debugMode, setDebugMode] = useState<boolean>(false);

  return (
    <Drawer
      data-testid="console-drawer"
      anchor={'left'}
      open={isOpen}
      onClose={handleClose}
      sx={{ zIndex: 9999 }}
    >
      <Stack bgcolor="#1D1F21" sx={styles.container}>
        <Stack sx={styles.titleContainer}>
          <Typography sx={styles.title}>{'DataCapture <BETA> Console'}</Typography>
          <IconButton sx={{ p: 0 }} disableRipple onClick={handleClose}>
            <FontAwesomeIcon icon="fas fa-times" size={14} />
          </IconButton>
        </Stack>
        <Stack sx={styles.box}>
          <DebugConsoleBody />
          <DebugConsoleCaller debugMode={debugMode} setDebugMode={setDebugMode} />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default DebugConsoleMain;
