import { Box, Card } from '@mui/material';
import { Step } from '../../types';
import { Draggable } from 'react-beautiful-dnd';

const styles = {
  cardContainer: {
    height: '100%',
    backgroundColor: 'common.white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
};

interface BaseProps {
  children: JSX.Element;
  step: Step;
}

const Base = ({ children, step }: BaseProps) => {
  return (
    <Box
      sx={{
        ...styles.cardContainer,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? 'transparent'
            : theme.palette.common.white,
      }}
    >
      <Box>
        <Draggable
          key={step.stepOrder}
          draggableId={`step-${step.stepOrder}`}
          index={step.stepOrder}
        >
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Card
                  sx={{
                    width: 320,
                    height: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    m: 2,
                  }}
                >
                  {children}
                </Card>
              </div>
            );
          }}
        </Draggable>
      </Box>
    </Box>
  );
};

export default Base;
