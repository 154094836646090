import React, { useContext } from 'react';

import { Box, DialogContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import UserPermissions from '../userProfile/components/UserPermissionsTab/UserPermissions';
import { TabTitle } from '../UserPopup';
import { Context as FormContext, FormContextTypes } from './formContext';
import { TabPanelProps } from '@mui/lab';

const classes = {
  dialogTitle: {
    textAlign: 'center',
    padding: { xs: '5px 0', md: '6px 0' },
    fontSize: { xs: 17, md: 20 },
    backgroundColor: 'common.greyblueDark',
    fontWeight: 'bold',
  },
  container: {
    maxHeight: '680px',
    height: '630px',
    width: { xs: '600px', md: '750px', lg: '750px' },
  },
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={'span'} sx={{ display: 'block' }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

interface PermissionsTabProps {
  handleCloseModal: () => void;
  tabsValue: number;
  index: number;
}

const PermissionsTab = ({ handleCloseModal, tabsValue, index }: PermissionsTabProps) => {
  const { updateForm } = useContext(FormContext) as FormContextTypes

  const updatePermissionsValues = (permissions: Permissions) =>
    updateForm({ field: 'permissions', value: permissions });

  return (
    <>
      <TabPanel value={tabsValue} index={index}>
        <TabTitle title="Permissions" handleClose={handleCloseModal} />
        <Box sx={classes.container}>
          <DialogContent sx={{ height: '100%', p: 0 }}>
            <UserPermissions
              updatePermissionsValues={updatePermissionsValues}
              isCreateUserModal={true}
            />
          </DialogContent>
        </Box>
      </TabPanel>
    </>
  );
};

export default PermissionsTab;
