import { Dispatch, SetStateAction } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import FontAwesomeIcon from '../../components/FAIcon';

interface Props {
  drawer: boolean;
  setDrawer: Dispatch<SetStateAction<boolean>>;
}
export const NoTemplate = (props: Props) => {
  const { setDrawer } = props;
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.black.ultraLightTransp
            : '#D9D9D9',
        flexDirection: 'column',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2.5,
        gap: 4,
        cursor: 'pointer',
      }}
      onClick={() => setDrawer(true)}
    >
      <Typography
        variant="caption"
        sx={{
          textAlign: 'center',
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.greyblueDark,
        }}
      >
        Select a template or add a filter by clicking anywhere on this box to create your
        report
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 1,
          minWidth: '220px',
        }}
      >
        <IconButton
          color="secondary"
          sx={{ width: '50px', height: '50px', margin: '0 auto' }}
          onClick={() => setDrawer(true)}
        >
          <FontAwesomeIcon icon="fas fa-plus" size={40} />
        </IconButton>
        <Typography
          variant="button"
          sx={{
            color: (theme) =>
              theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblueDarker',
            margin: '0 auto',
          }}
        >
          ADD A FILTER
        </Typography>
      </Box>
    </Box>
  );
};
