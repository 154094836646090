import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Tabs, { TabsProps } from '@mui/material/Tabs';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.common.greyblueDarker,
  border: 'none',
  transition: '.3s',
  minWidth: '180px',
  '& .MuiTabs-flexContainer': {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  '& .Mui-selected': {
    [theme.breakpoints.up('md')]: {
      borderRight: '2px solid',
    },
    [theme.breakpoints.down('md')]: {
      borderBottom: '2px solid',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export default function CustomTabs(props: TabsProps) {
  return <StyledTabs {...props}>{props.children}</StyledTabs>;
}
