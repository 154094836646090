import React, { ReactNode } from 'react';
import ToolbarBase from '../../components/toolbarScreen/ToolbarBase';
import ToolbarButtonLeft from '../../components/toolbarScreen/ToolbarButtonLeft';
import FontAwesomeIcon from '../../components/FAIcon';
import ToolbarTitle from '../../components/toolbarScreen/ToolbarTitle';
import ToolbarActionRight from '../../components/toolbarScreen/ToolbarActionRight';
import { useHistory } from 'react-router-dom';

interface Action {
  tooltipTitle: string;
  buttonIcon: ReactNode | JSX.Element;
  buttonOnClick: () => void;
}

interface OrgToolbarProps {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
  actions?: Action[] | null;
}
const OrgToolbar = ({ actions, handleSearch, setSearchQuery }: OrgToolbarProps) => {
  let history = useHistory();
  return (
    <ToolbarBase>
      <ToolbarButtonLeft buttonOnClick={() => history.goBack()}>
        <FontAwesomeIcon icon="fas fa-angle-left" />
      </ToolbarButtonLeft>
      <ToolbarTitle title="Organisations" />
      <ToolbarActionRight
        actions={actions}
        handleSearch={handleSearch}
        setSearchQuery={setSearchQuery}
      />
    </ToolbarBase>
  );
};

export default OrgToolbar;
