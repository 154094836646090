import {
  Box,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { User } from '../../../../components/masterTable/masterTableTypes';
import { Step } from '../../types';
import Base from './Base';
import UsersList from './UsersList';
import { Note } from '../../../../components/Notes/notesTypes';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import useWorkflowStatus from '../../../../hooks/useWorkflowStatus';
import utils from '../../../../utils/utils';

interface ApprovalProps {
  step: Step;
  users: User[];
  handleUpdateStep: (stepOrder: number, key: string, value: any) => void;
  currentProcessStep: Step;
  toggleNotesDrawer: (note: Note | null) => void;
}

const Approval = ({
  step,
  users,
  handleUpdateStep,
  currentProcessStep,
  toggleNotesDrawer,
}: ApprovalProps) => {
  const widthMatches = useMediaQuery('(min-width:1280px)');
  let user = _.find(users, { id: step.assignedBy ? step.assignedBy.userId : '' });

  const { status, getWorkflowStatus } = useWorkflowStatus();

  useEffect(() => {
    getWorkflowStatus(step, currentProcessStep);
  }, [step, currentProcessStep]);

  return (
    <>
      {step.assignedBy && (
        <Typography
          sx={{
            display: 'block',
            textAlign: 'initial',
            color: (theme) =>
              theme.palette.mode === 'dark' ? 'common.lightblue' : 'common.greyblueDark',
            fontSize: 10,
            margin: '3px 0',
          }}
        >{`Assigned by: ${user?.firstName} ${user?.lastName} on ${
          step.assignedBy && utils.getDateTime(step.assignedBy.timeStamp)
        }`}</Typography>
      )}
      <Base step={step}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            p: '10px 0',
          }}
        >
          <CardContent
            sx={{
              flex: '1 0 auto',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%',
              p: '12px!important',
            }}
          >
            <Box
              id="step-order"
              sx={{ flex: 0.5, display: 'flex', flexDirection: 'column' }}
            >
              <Typography
                variant="button"
                sx={{
                  fontSize: widthMatches ? '0.9vw' : '12px',
                  fontWeight: 400,
                  textAlign: 'center',
                  color: 'common.blueGrey.100',
                }}
              >
                Step
              </Typography>
              <Typography
                variant="button"
                sx={{
                  fontSize: 26,
                  lineHeight: 1,
                  textAlign: 'center',
                  color: 'common.blueGrey.100',
                }}
              >
                {step.stepOrder + 1}
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: '#ffffff20' }}
            />
            <Box sx={{ flex: 1, textAlign: 'center' }} id="step-type">
              <Typography
                variant="button"
                sx={{
                  fontSize: widthMatches ? '0.9vw' : '12px',
                  fontWeight: 500,
                  color: 'common.blueGrey.100',
                }}
              >
                {step.__typename.slice(0, -4)}
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: '#ffffff20' }}
            />
            <Box sx={{ flex: 3 }} id="step-user-list">
              <Typography
                variant="button"
                sx={{
                  textAlign: 'center',
                  display: 'block',
                  fontSize: '0.65rem',
                  fontWeight: '700',
                  marginBottom: '8px',
                  color: 'common.blueGrey.100',
                }}
              >
                To be approved by {step.allUsersMustApprove ? 'all users' : ''}
              </Typography>
              {/* Only shows users that must be approved this record, not all users */}
              <UsersList
                users={users}
                handleUpdateStep={handleUpdateStep}
                step={step}
                currentProcessStep={currentProcessStep}
                toggleNotesDrawer={toggleNotesDrawer}
              />
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: '#ffffff20', marginLeft: 1 }}
            />

            <Box
              id="step-status"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                with: 20,
                color: 'common.white',
                flex: 0.8,
                marginTop: '4px',
              }}
            >
              {status && (
                <>
                  <Box sx={{ color: status.color, textAlign: 'center' }}>
                    <FontAwesomeIcon icon={status.icon} />
                  </Box>
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                      textAlign: 'center',
                      color: 'common.blueGrey.100',
                    }}
                  >
                    {status.label}
                  </Typography>
                </>
              )}
            </Box>
          </CardContent>
        </Box>
      </Base>
    </>
  );
};

export default Approval;
