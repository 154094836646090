import React from 'react'
import {Img} from 'react-image'
const myComponent = (props) => {
  const {url} = props
  return (
    <Img
      src={url}
    />
  )
}

export default myComponent