import { Autocomplete, Box, Button, Switch, TextField, Typography } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';
import { useState } from 'react';
import AceEditor from 'react-ace';
import RTC from '../../utils/socketComm';
import { Socket } from 'socket.io-client';
import useSocketList from './useSocketList';
import _ from 'lodash';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/ext-language_tools';

interface DebugConsoleCallerProps {
  debugMode: boolean;
  setDebugMode: React.Dispatch<React.SetStateAction<boolean>>;
}

type SelectedSocketCallType = {
  label: string;
  name: string;
  body?: string;
  custom?: boolean;
  function?: (data: any) => void;
} | null;

const styles = {
  main: { flex: 1.4, backgroundColor: '#7E6321', height: '100%' },
  text: {
    color: 'common.orange',
    fontWeight: 600,
  },
  textfield: {
    backgroundColor: '#000',
    color: 'common.white',
    borderRadius: '4px',
  },
  bodyText: {
    color: 'common.orange',
    fontWeight: 400,
    display: 'inline-block',
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: 'common.orange',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#000',
      opacity: 0.8,
    },
    '.MuiSwitch-track': {
      backgroundColor: '#000',
      opacity: 1,
    },
  },
  button: {
    backgroundColor: '#000',
    color: 'common.orange',
    p: '5px 10px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#000',
      color: '#ffc92999',
    },
  },
};

const DebugConsoleCaller = ({ setDebugMode, debugMode }: DebugConsoleCallerProps) => {
  const { socketList } = useSocketList();
  const [bodyValue, setBodyValue] = useState<string>('');
  const [socketCall, setSocketCall] = useState<string>('');
  const [selectedSocketCall, setSelectedSocketCall] =
    useState<SelectedSocketCallType>(null);
  let socket = RTC.socket as unknown as Socket;

  const handleBodyChange = (newValue: string) => setBodyValue(newValue);

  const handleSubmit = () => {
    // eslint-disable-next-line no-new-func
    const body = new Function('return ' + bodyValue)();

    if (
      debugMode ||
      !selectedSocketCall ||
      (selectedSocketCall && !selectedSocketCall.function)
    ) {
      socket.emit(socketCall, body, (res: any) => console.log('socket res...', res));
    } else {
      selectedSocketCall.function?.(body);
    }
  };

  const handleClearInput = () => {
    setBodyValue('');
    setSocketCall('');
    setSelectedSocketCall(null);
  };

  return (
    <Box sx={styles.main}>
      <Box sx={{ p: '10px 30px' }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography sx={{ ...styles.text, fontSize: 16, fontWeight: 900 }}>
            Socket call debugger
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="button" sx={styles.text}>
              socket call
            </Typography>
            <Autocomplete
              size="small"
              value={selectedSocketCall as any}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setSelectedSocketCall({
                    name: newValue,
                    label: newValue,
                    custom: true,
                  });
                } else {
                  setSelectedSocketCall(newValue);
                  newValue && newValue.body && setBodyValue(newValue?.body);
                }
              }}
              onInputChange={(e, value, reason) => {
                if (reason === 'clear') return handleClearInput();
                setSocketCall(value);
              }}
              disablePortal
              options={socketList}
              getOptionLabel={(option) => (_.isString(option) ? option : option.label)}
              renderOption={(props, option) => <li {...props}>{option.label}</li>}
              sx={{ width: 220, ...styles.textfield }}
              freeSolo
              renderInput={(params) => <TextField {...params} label="Select a call" />}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px' }}>
          <Typography variant="button" sx={styles.bodyText}>
            Body
          </Typography>
          <AceEditor
            mode="javascript"
            theme="terminal"
            name="body-socket"
            onChange={handleBodyChange}
            fontSize={14}
            showGutter={false}
            highlightActiveLine={true}
            value={bodyValue}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: false,
              tabSize: 2,
            }}
            style={{ width: '100%', height: '200px' }}
          />
          <Box sx={{ alignSelf: 'flex-end', marginTop: 1 }}>
            <Typography variant="button" sx={{ color: 'common.orange', fontWeight: 400 }}>
              DEBUG
            </Typography>
            <Switch
              sx={styles.switch}
              checked={debugMode}
              onChange={(e) => setDebugMode(e.target.checked)}
            />
            <Button
              size="small"
              sx={styles.button}
              onClick={handleSubmit}
              disabled={socketCall === '' || bodyValue === ''}
              endIcon={
                <Box>
                  <FontAwesomeIcon icon="fas fa-paper-plane" size={12} />
                </Box>
              }
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DebugConsoleCaller;
