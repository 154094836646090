import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { Filter } from '../ReportingSideBar';
import { useFormContext } from 'react-hook-form';

interface WorkflowStatusDateFilterProps {
  filter: Filter;
}

const WorkflowStatusDateFilter = ({ filter }: WorkflowStatusDateFilterProps) => {
  const [value, setValue] = useState<string | null>(null);
  const { setValue: setFormValue, watch } = useFormContext();

  useEffect(() => {
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex((filter) => filter.id === 'workflow-status-date');
    index !== -1 &&
      setFormValue(`selectedTemplate.variables.filters[${index}].value`, value);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={(newValue) => setValue(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: false }}
            inputProps={{
              ...params.inputProps,
              placeholder: 'Select a date',
            }}
            fullWidth
            sx={{
              '& fieldset': { border: 'none' },
              '& .MuiInputBase-input': {
                textAlign: 'end',
                fontSize: 14,
                fontWeight: 500,
                color: 'common.greyblueDarker',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default WorkflowStatusDateFilter;
