import { Box, Typography } from '@mui/material';
import React from 'react';

const ServerLogTab = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'common.greyblueDarker',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderInlineStart: '3px solid #cea323',
        boxShadow: { sm: '0px 1px 0px 0px #ffffff96 inset', md: 'none' },
        padding: '5px 15px',
        height: '30px',
      }}
    >
      <Typography variant="caption" color="WHITE">
        Server logs
      </Typography>
    </Box>
  );
};

export default ServerLogTab;
