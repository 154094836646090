import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
const Th_InputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    '& input': {},
    fontWeight: '800',
    height: 25,
    borderRadius: 0,
    position: 'relative',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.common.greyblueDark
        : theme.palette.common.greyblue,
    fontSize: 14,
    padding: '20px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: theme.palette.common.white,
    borderColor: 'transparent',
    '&:focus': {
      borderRadius: 0,
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));
export default Th_InputBase;
