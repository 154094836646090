import React, { DOMAttributes, MouseEventHandler, ReactNode } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PDFEmailing from './pdfEmailing';
import PDFDownload from './pdfDownload';
import PDFShareTo from './pdfShareTo';
import GeneralSnackbar, { HandleClose, StatusProps } from '../SnackBar';
import { styled } from '@mui/material/styles';
import { Box, Typography, Popover, Fab, Zoom, Button, IconButton } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import FontAwesomeIcon from '../FAIcon';
import { Direction } from '@mui/system';
import { useTheme } from '@mui/styles';

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '15px 10px',
    fontSize: '16px',
    fontWeight: 300,
    backgroundColor: theme.palette.common.black,
  },
}));

interface TabPanel {
  children: ReactNode;
  index: any;
  value: any;
  dir: Direction;
}
function TabPanel(props: TabPanel) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index: number) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const classes = {
  root: {
    backgroundColor: 'theme.palette.background.paper',
    width: 500,
    position: 'relative',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  fab: {
    position: 'absolute',
    bottom: '50px',
    right: '50px',
  },
  arrow: {
    marginRight: 10,
    alignSelf: 'right',
    height: 0,
    width: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #0B72B9',
  },
  body: {
    width: '100%',
  },
};

const StyledTabs = styled((props: TabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 5,
    '& > span': {
      width: '100%',
      backgroundColor: '#fda808',
    },
  },
  '& .Mui-selected': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.blue,
  },
}));

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.blue,
    },
  })
);

export interface CustomButton {
  color: string;
  className: string;
  icon: ReactNode;
  label: string;
  onClick: () => Promise<void>;
}

export default function SharePopOver() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [snackbarStatus, setSnackbarStatus] = React.useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: undefined,
  });
  const [value, setValue] = React.useState(0);
  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarStatus({
      status: false,
      snackbarMessage: '',
      severity: snackbarStatus.severity,
    });
  };
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const fabs = [
    {
      color: 'primary',
      className: classes.fab,
      icon: <FileCopyIcon />,
      label: 'Expand',
    },
  ];
  const createFab = (customButton: CustomButton | undefined, order: number = 0) => {
    if (customButton) {
      return (
        <Zoom
          in={value === order}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === order ? transitionDuration.exit : 0}ms`,
            alignSelf: 'flex-end',
          }}
          unmountOnExit
        >
          <Fab
            aria-label={customButton.label}
            className={customButton.className}
            color={customButton.color}
            onClick={customButton.onClick}
          >
            {customButton.icon}
          </Fab>
        </Zoom>
      );
    } else {
      return fabs.map((fab, index) => (
        <Zoom
          key={index}
          in={value === index + order}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === index + order ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          <Fab aria-label={fab.label} sx={fab.className} color={fab.color}>
            {fab.icon}
          </Fab>
        </Zoom>
      ));
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <DarkTooltip arrow title="Sharing options">
        <IconButton
          sx={{
            border: 'none',
            borderRadius: '4px',
            color: (theme) => (theme.palette.mode === 'light' ? '#464646' : '#c0c0c0'),
            cursor: 'pointer',
            padding: '8px',
            '&:hover': {
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? '#d6d6d6' : '#121212',
            },
          }}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon="fas fa-share-alt" color="inherit" size="16px" />
        </IconButton>
      </DarkTooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 100,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={classes.root}>
          <Box sx={classes.arrow}></Box>
          <AppBar position="static" color="primary">
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="action tabs example"
            >
              <StyledTab
                label="Email"
                icon={<FontAwesomeIcon icon="fas fa-envelope" />}
                {...a11yProps(0)}
              />
              <StyledTab
                label="Download"
                icon={<FontAwesomeIcon icon="fas fa-save" />}
                {...a11yProps(1)}
              />
              <StyledTab
                label="Share Link"
                icon={<FontAwesomeIcon icon="fas fa-link" />}
                {...a11yProps(2)}
              />
            </StyledTabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
            sx={classes.body}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <PDFEmailing fab={createFab} setSnackbar={setSnackbarStatus}></PDFEmailing>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <PDFDownload fab={createFab} setSnackbar={setSnackbarStatus}></PDFDownload>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <PDFShareTo fab={createFab} setSnackbar={setSnackbarStatus}></PDFShareTo>
            </TabPanel>
          </SwipeableViews>
          <GeneralSnackbar
            status={snackbarStatus}
            handleClose={handleCloseSnackbar}
          ></GeneralSnackbar>
        </Box>
      </Popover>
    </div>
  );
}
