import { FormControlLabel, Grid, Typography } from '@mui/material';
import StyledSwitch from '../../../../components/form/StyledSwitch';
import { FolderFields, UpdateFieldProps } from '../../types';

const styles = {
  description: {
    paddingTop: '10px',
  },
  switch: {
    marginLeft: '10px',
  },
};

interface FieldSettingsProps {
  setFieldUpdates: React.Dispatch<React.SetStateAction<UpdateFieldProps>>;
  fieldUpdates: UpdateFieldProps;
  setSelectedField: React.Dispatch<React.SetStateAction<FolderFields | null>>;
  selectedField: FolderFields | null;
}

export default function FieldSettings({
  setFieldUpdates,
  fieldUpdates,
  setSelectedField,
  selectedField,
}: FieldSettingsProps) {
  if (!selectedField) return null;
  return (
    <>
      <Grid container>
        <Grid item>
          <FormControlLabel
            control={
              <StyledSwitch
                name="active"
                color="secondary"
                checked={selectedField.active || true}
                onChange={(e) => {
                  let active = e.target.checked;
                  setFieldUpdates({ ...fieldUpdates, active });
                  setSelectedField((prev) => prev && { ...prev, active });
                }}
                sx={styles.switch}
              />
            }
            label="Active"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <FormControlLabel
            control={
              <StyledSwitch
                name="mainField"
                color="secondary"
                checked={selectedField.mainField || false}
                onChange={(e) => {
                  let mainField = e.target.checked;
                  setFieldUpdates({ ...fieldUpdates, mainField });
                  setSelectedField((prev) => prev && { ...prev, mainField });
                }}
                sx={styles.switch}
              />
            }
            label="Main Field"
          />
        </Grid>
        <Grid item sx={styles.description}>
          <Typography variant="caption">Is this the main field?</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <FormControlLabel
            control={
              <StyledSwitch
                name="matchRequiredField"
                color="secondary"
                checked={selectedField.matchRequiredField || false}
                onChange={(e) => {
                  let matchRequiredField = e.target.checked;
                  setFieldUpdates({ ...fieldUpdates, matchRequiredField });
                  setSelectedField((prev) => prev && { ...prev, matchRequiredField });
                }}
                sx={styles.switch}
              />
            }
            label="Match Required"
          />
        </Grid>

        <Grid item sx={styles.description}>
          <Typography variant="caption">
            Is this field defining linked data that needs to be matched?
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <FormControlLabel
            control={
              <StyledSwitch
                name="isMandatory"
                color="secondary"
                checked={selectedField?.indexRequirements?.isMandatory || undefined}
                onChange={(e) => {
                  let isMandatory = e.target.checked;
                  setFieldUpdates({
                    ...fieldUpdates,
                    indexRequirements: {
                      regexMatch: fieldUpdates.indexRequirements?.regexMatch || '',
                      isMandatory,
                    },
                  });
                  setSelectedField(
                    (prev) =>
                      prev && {
                        ...prev,
                        indexRequirements: { ...prev.indexRequirements, isMandatory },
                      }
                  );
                }}
                sx={styles.switch}
              />
            }
            label="Mandatory"
          />
        </Grid>
        <Grid item sx={styles.description}>
          <Typography variant="caption">
            Is it mandatory to fill out this field?
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
