import {
  Box,
  Collapse,
  IconButton,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { Row } from './IdentifierConfigModal';
import { ChangeEvent, useEffect, useState } from 'react';

const createStyles = (theme: Theme) => {
  const mode = theme.palette.mode;
  return {
    box: {
      width: '100%',
      minHeight: '40px',
      height: 'auto',
      backgroundColor: mode === 'light' ? 'common.lightblue' : '#2D333A',
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
      borderRadius: '4px',
    },
    input: {
      width: '40%',
      '& .MuiInputBase-input': {
        borderRadius: '2px',
        padding: '3px',
        paddingLeft: '10px',
        backgroundColor: mode === 'light' ? 'rgb(205,210,220)' : '#26292E',
      },
    },
  };
};

const EditableRowItem = ({
  row,
  setEditableRowIndex,
  handleEditRow,
  index,
  rows,
  setError,
  error,
}: {
  row: Row;
  setEditableRowIndex: React.Dispatch<React.SetStateAction<number | null>>;
  handleEditRow: (newRow: Row, index: number) => void;
  index: number;
  rows: Row[];
  setError: React.Dispatch<React.SetStateAction<{ error: boolean; message: string }>>;
  error: { error: boolean; message: string };
}) => {
  const theme = useTheme();
  const styles = createStyles(theme);
  const [currentRow, setCurrentRow] = useState<Row>(row);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentRow((row) => ({ ...row, [e.target.name]: e.target.value }));
  };
  const handleSave = () => {
    let isDuplicated = rows.find((row) => row.code === currentRow.code);
    if (isDuplicated) {
      setError({ error: true, message: 'This code is already created' });
    } else {
      handleEditRow(currentRow, index);
      setEditableRowIndex(null);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (error.error) {
      timeout = setTimeout(() => {
        setError({ error: false, message: '' });
      }, 2500);
    }
    return () => timeout && clearTimeout(timeout);
  }, [error]);

  return (
    <Box sx={{ ...styles.box, padding: error.error ? '10px 20px' : '5px 20px' }}>
      <Box sx={{ display: 'flex', gap: '10px', flex: 6 }}>
        <Box
          sx={{
            width: '40%',
            display: 'flex',
            gap: error.error ? 0.5 : 0,
            flexDirection: 'column',
          }}
        >
          <TextField
            InputProps={{
              disableUnderline: true,
              style: {
                border: error.error ? '1px solid red' : 'none',
                borderRadius: '4px',
              },
            }}
            sx={{ ...styles.input, width: '100%' }}
            defaultValue={row.code}
            variant="filled"
            name="code"
            autoFocus
            onChange={handleChange}
            error={error.error}
          />
          <Collapse in={error.error} timeout={{ exit: 1000, appear: 500, enter: 500 }}>
            <Typography sx={{ fontSize: 13, color: 'common.negative', fontWeight: 700 }}>
              {error.message}
            </Typography>
          </Collapse>
        </Box>
        <TextField
          sx={styles.input}
          InputProps={{ disableUnderline: true }}
          defaultValue={row.name}
          variant="filled"
          name="name"
          onChange={handleChange}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: error.error ? '16px' : '0',
        }}
      >
        <IconButton
          onClick={handleSave}
          sx={{ borderRadius: '4px', color: 'common.positive' }}
        >
          <FontAwesomeIcon icon="fas fa-save" size="16px" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default EditableRowItem;
