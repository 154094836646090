import { Component, ErrorInfo } from 'react';
import { Context as StatusContext } from '../context/statusContext';
import FontAwesomeIcon from '../components/FAIcon';

class ErrorHandler extends Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorInfo: null, isOpen: false };
  }
  static contextType = StatusContext;
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true, isOpen: true, errorInfo: errorInfo });
    this.context.setErrorStatus({ triggered: true, errorInfo: errorInfo });
    window.log(error, errorInfo.componentStack);
  }
  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <FontAwesomeIcon icon="fas fa-exclamation-triangle" color="common.negative" />
      );
    } else {
      return this.props.children;
    }
  }
}
export default ErrorHandler;
