import { Box, Typography, useTheme } from '@mui/material';
import FontAwesomeIcon from '../../../../components/FAIcon';
import { Row } from './IdentifierConfigModal';

interface AddButtonProps {
  addRow: () => void;
  rows: Row[];
}

const AddButton = ({ addRow, rows }: AddButtonProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: mode === 'light' ? 'common.lightblue' : '#2D333A',
        alignItems: 'center',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        padding: '5px 0',
        borderRadius: '4px',
        gap: '10px',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: mode === 'light' ? 'rgb(200, 215, 225)' : '#2D333A80',
        },
      }}
      onClick={addRow}
    >
      <FontAwesomeIcon
        icon="fas fa-plus"
        size="12px"
        color={mode === 'light' ? 'common.greyblueDark' : 'common.greyblue'}
      />
      <Typography
        variant="button"
        sx={{
          fontSize: 12,
          color: mode === 'light' ? 'common.greyblueDark' : 'common.greyblue',
        }}
      >
        {`Add ${rows.length ? 'another' : 'a'} Code/name pair`}
      </Typography>
    </Box>
  );
};

export default AddButton;
