import { Box, CircularProgress, Fade, Stack, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useReducer } from 'react';
import { expandN } from 'regex-to-strings';
import FontAwesomeIcon from '../components/FAIcon';
import { getBGColor } from '../components/Notes/NotesForm';
import { Note } from '../components/Notes/notesTypes';
import MultistringCell from '../components/masterTable/components/MultistringCell';
import {
  Field,
  FolderField,
  Row,
  SelectedFolder,
} from '../components/masterTable/masterTableTypes';
import { LinkedInformations, LinkedValues } from '../containers/folder/types';
import { FolderFields, Record } from '../containers/search/types';
import { TaskContent } from '../context/tasksContextTypes';
import { db } from './indexedDB/indexeddb';
import { capitalize, splitByUpperCase } from './text';
import { generateTaskIDProps } from './utilsTypes';
import { isString } from 'lodash';

const LoaderLinear = () => {
  return (
    <Box
      sx={{
        opacity: '0.2',
        width: '100%',
        height: '50px',
        display: 'inline-block',
        backgroundColor: '#FFF',
        backgroundImage:
          'linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent)',
        fontSize: '30px',
        backgroundSize: '1em 1em',
        boxSizing: 'border-box',
        animation: 'progress 1s linear infinite',
        '@keyframes progress': {
          '0%': {
            backgroundPosition: '1em 0',
          },
          '100%': {
            backgroundPosition: '0 0',
          },
        },
      }}
    ></Box>
  );
};

const utils = {
  parseJSON: (jsonString: string) => {
    try {
      var o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {}

    return false;
  },
  getTaskNotificationDetails: (task: TaskContent) => {
    let actionMessage = '';
    switch (task.action) {
      case 'delete_records':
        actionMessage = `Record deleting task completed.`;
        break;
      case 'update_records':
        actionMessage = `Record updating task completed.`;
        break;
      case 'ocr_records':
        actionMessage = `OCR scan task completed.`;
        break;
      case 'scan_barcode_result':
        actionMessage = `Barcode scanning task completed.`;
        break;
      case 'unindex_records':
        actionMessage = 'Unindexing records task completed';
        break;
      case 'textract_records':
        actionMessage = 'OCR records task completed';
        break;
      case 'default':
        actionMessage = 'Task completed.';
        break;
      default:
        actionMessage = 'Task completed.';
        break;
    }
    switch (task.action) {
      case 'ocr_records':
        actionMessage += ` ${task.ammount} Record/s scanned`;
        break;
      case 'update_records':
        actionMessage += ` ${task.ammount} Record/s assigned`;
        break;
      case 'unindex_records':
        break;
      default:
        actionMessage += ` ${task.ammount} Record/s processed`;
        break;
    }

    if (task.fail) {
      actionMessage += ` ${task.fail} of which have failed.`;
    } else {
      actionMessage += ', no errors registered.';
    }
    return actionMessage;
  },
  generateTaskID: (user: generateTaskIDProps) => {
    window.log(user);
    const userIDSection = user.id.slice(0, 4);
    const userSection = user.userName.slice(0, 2) + user.userLname.slice(0, 2);
    const orgSection = user.suser
      ? user.selOrg.name.slice(0, 3)
      : user.organisations.slice(0, 3);
    const taskIDSection = nanoid(6);
    const completeID = `${userIDSection}_${userSection}_${orgSection}_${taskIDSection}`;
    window.log(completeID);
    return completeID;
  },
  notificationIcons: (code = 'T001', color = 'common.white') => {
    type icons = {
      T001: Element;
      T002: Element;
      E002: Element;
    };
    let icons = {
      T001: <FontAwesomeIcon icon="fas fa-file-invoice" color={color} size="20px" />,
      T002: <FontAwesomeIcon icon="fas fa-qrcode" color={color} size="22px" />,
      E002: (
        <FontAwesomeIcon
          icon="fas fa-exclamation-triangle"
          color="common.negative"
          size="22px"
        />
      ),
    };
    return icons[code as keyof icons];
  },
  getCookie: (cName: string) => {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  },
  determineScreenByCode: (code: string) => {
    interface screens {
      indexing: string[];
      search: string[];
    }
    const screens = {
      indexing: ['T001', 'T003', 'T002', 'S001', 'S002', 'E002'],
      search: ['T004', 'T009', 'T010'],
    };

    for (let screen in screens) {
      if (screens[screen as keyof screens].includes(code)) {
        return screen;
      }
    }
  },
  determineActionByCode: (code: string) => {
    const actions = {
      goToScreen: ['T001', 'T002', 'T003'],
      search: ['T001', 'T009', 'T010'],
    };

    for (let action in actions) {
    }
  },
  determineTypeByCode: (code: string) => {
    if (code.startsWith('T')) return 'Tasks';
    if (code.startsWith('C')) return 'Comm';
    if (code.startsWith('E')) return 'Error';
    return 'default';
  },
  determineStateByCode: (code: string) => {
    switch (code) {
      case 'S001':
        return 'deleted';
      case 'S002':
        return 'scanned';
    }
  },
  determinePermissions: (roleinfo: number) => {
    let role = roleinfo;
    if (!role) {
      let userData: any = localStorage.getItem('userData');
      userData = userData ? JSON.parse(userData) : { role: 2 };
      role = userData.role;
    }
    window.log(role);
    switch (role) {
      case -1:
        return {
          delete: true,
          edit: true,
          view: true,
          quickView: true,
        };
      case 0:
        return {
          delete: true,
          edit: true,
          view: true,
          quickView: true,
        };
      case 1:
        return {
          delete: false,
          edit: true,
          view: true,
          quickView: true,
        };
      default:
        return {
          delete: false,
          edit: false,
          view: false,
          quickView: false,
        };
    }
    if (role > 0) {
    }
  },
  createDataContext: (reducer, actions, initialState) => {
    const Context = React.createContext();
    const Provider = ({ children }) => {
      const [state, dispatch] = useReducer(reducer, initialState);

      //actions is an object liek {addDocument: (dispatch)=>{return () =>{}}}
      const boundActions = {};
      for (let key in actions) {
        // key === 'addDocument'

        boundActions[key] = actions[key](dispatch);
      }

      return (
        <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>
      );
    };

    return { Context, Provider };
  },
  wait: (ms: number | undefined) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  clearUserData: (previousPage: string | null = null) => {
    // if it has a previous page, we avoid deleting that item from local storage
    let previous = null;
    if (previousPage) previous = localStorage.getItem('previousPage');
    localStorage.clear();
    previous && localStorage.setItem('previousPage', previous);
  },
  convertDate: (inputFormat: string | number | Date, format = 'std') => {
    function pad(s: number) {
      return s < 10 ? '0' + s : s;
    }
    var d = new Date(inputFormat);
    switch (format) {
      case 'eng':
        return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
      default:
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
    }
  },
  checkForRepeated: (collectionBin, record) => {},
  capitalize: function (string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  checkCollectionBinLocalStorage: () => {
    if (!localStorage.getItem('collectionBin')) {
      localStorage.setItem('collectionBin', JSON.stringify([]));
    }
    let collectionBin = JSON.parse(localStorage.getItem('collectionBin') as string);
    return collectionBin;
  },
  addRecordToCollectionBin: (records: Row[], folder: FolderField) => {
    let collectionBin: any = localStorage.getItem('collectionBin');
    if (!collectionBin) {
      window.log('collection bin not initialized, initializing...');
      localStorage.setItem('collectionBin', JSON.stringify([]));
    }
    collectionBin = JSON.parse(localStorage.getItem('collectionBin') as string);
    records = records.map((record) => {
      record.folder = folder.name;
      record.folderId = folder.id;
      return record;
    });
    records = records.filter((record) => {
      let exist = collectionBin?.map((collectionBinEl: { id: string }) => {
        return collectionBinEl.id === record.id;
      });
      if (exist.includes(true)) {
        return false;
      } else {
        return true;
      }
    });
    collectionBin = collectionBin?.concat(records);
    localStorage.setItem('collectionBin', JSON.stringify(collectionBin));
    window.log(JSON.parse(localStorage.getItem('collectionBin') as string));
  },
  clearCollectionBinLocalStorage: () => {
    localStorage.setItem('collectionBin', JSON.stringify([]));
  },
  updateCollectionBin: (collection: string) => {
    localStorage.setItem('collectionBin', JSON.stringify(collection));
  },
  initializeLocalStorage: () => {
    let collectionBin = localStorage.getItem('collectionBin');
    if (!collectionBin) {
      window.log('collection bin not initialized, initializing...');
      localStorage.setItem('collectionBin', JSON.stringify([]));
    }
  },
  getContentAndTableWidth: (headersLength: number) => {
    if (headersLength <= 1) {
      return 3;
    } else if (headersLength <= 2) {
      return 3;
    } else if (headersLength >= 4) {
      return 5;
    } else if (headersLength > 2) {
      return 4;
    } else {
      return 4;
    }
  },
  getWidthDifference: (width: number) => {
    let max = 12;
    return max - width;
  },
  getRowsPerPage: (rowsLength: number) => {
    if (rowsLength > 50) {
      return 100;
    } else if (rowsLength > 25) {
      return 50;
    } else {
      return 25;
    }
  },
  addCreatedAtField: (folder: { folderFields: FolderField[] }) => {
    let containCreatedAt = false;
    if (folder) {
      for (let i = 0; i < folder.folderFields.length; i++) {
        const field = folder.folderFields[i];
        if (field.id === 'CreatedAtID') {
          containCreatedAt = true;
          break;
        }
      }
      if (!containCreatedAt) {
        folder.folderFields.push({
          __typename: 'FolderField',
          id: 'CreatedAtID',
          active: true,
          name: 'createdAt',
          type: 'string',
        } as FolderField);
      }
      return folder;
    }
  },
  sanitizePagesInput: (p: string) => {
    let newPages: number[] = [];
    let pagesInput = p.split(',');
    pagesInput.forEach((page) => {
      if (page.includes(' ')) {
        page = page.replace(' ', '');
      }
      if (page.includes('-')) {
        let pagesRange = page.split('-');
        let totalRange = Number(pagesRange[1]);
        let initialRange = Number(pagesRange[0]) - 1;
        window.log(totalRange);
        for (let i = initialRange; i < totalRange; i++) {
          newPages.push(i);
        }
      } else {
        newPages.push(Number(page) - 1);
      }
    });
    window.log(newPages);
    return newPages;
  },
  checkOutOfRange: (p: any, pages: number) => {
    for (let x of p) {
      if (x > pages) return true;
    }
    return false;
  },
  /**
   *
   * @param {string} val The string that needs to be parsed as currency type
   * @param {Obj} cur (optional) Currency object defining {locale: 'en-US', code: USD}
   */
  parseCurrency: (
    val: string,
    cur: { locale: string; code: string } = { locale: 'en-IE', code: 'USD' }
  ) => {
    if (!val) return null;
    let thisFloat: number | string = parseFloat(val);
    thisFloat = parseFloat(thisFloat.toFixed(2));
    //thisFloat = thisFloat.toFixed(2).replace(".", ",");
    if (cur) {
      thisFloat = new Intl.NumberFormat(cur.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(thisFloat);
    }
    return thisFloat;
  },
  /**
   *
   * @param {Array} rows The string that needs to be parsed as currency type
   * @param {String} folderName (optional) Folder the report is based on
   * @param {String} OrgName (optional) prints Org name on the header
   */
  createPDFReport: (props: {
    rows: any;
    cols: any;
    folder: SelectedFolder;
    screen: string;
    orientation: any;
    selectedTemplate: any;
  }) => {
    const { rows, cols, folder, screen, orientation, selectedTemplate } = props;
    const styles = StyleSheet.create({
      page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        color: '#555',
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        flexDirection: 'row',
        fontSize: 10,
        width: '100%',
        borderLeft: '1px solid #aaa',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      head: {
        padding: 14,
        backgroundColor: '#79909f',
        color: 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      footer: {
        fontSize: 10,
        color: '#ccc',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      title: {
        fontSize: 15,
      },
      col: {
        width: 100,
        height: 'auto',
      },
      row: {
        minHeight: 18,
        width: 'auto',
        padding: '2px 5px',
        textAlign: 'left',
        borderBottom: '1px solid #aaa',
        borderRight: '1px solid #aaa',
      },
      rowOdd: {
        minHeight: 18,
        width: 'auto',
        padding: '2px 5px',
        textAlign: 'left',
        backgroundColor: '#ccc',
        borderBottom: '1px solid #aaa',
        borderRight: '1px solid #aaa',
      },
      rowWithHeader: {},
      rowHeader: {
        color: 'white',
        backgroundColor: '#8399a8',
        borderBottom: '1px solid #ccc',
        padding: '2px 5px',
        textAlign: 'left',
      },
      pageNumber: {
        fontSize: 12,
        textAlign: 'right',
        color: 'grey',
      },
    });

    if (rows.length > 1000) {
      return (
        <Document>
          <Page size="A4" style={{ ...styles.page, padding: 12 }}>
            <Text>
              Report too large to generate PDF and preview, please download the CSV
              version
            </Text>
          </Page>
        </Document>
      );
    }
    const processCols = (start: number, limit: number, page: number) => {
      return cols.map((col: FolderField, i: number) => {
        return (
          <View key={`${page}${i}-col`} style={styles.col}>
            {processRows(start, limit, col, page)}
          </View>
        );
      });
    };
    const processRows = (
      start: number,
      limit: number,
      col: {
        name:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined;
      },
      page: number
    ) => {
      if (limit > rows.length) limit = rows.length;
      let newRows = [];
      for (let j = start; j < limit; j++) {
        const row = rows[j];
        const field = row.fields.find((f: Field) => f.field.name === col.name);
        const value = field?.value ?? '';
        const formattedValue =
          value && value['$$typeof'] === Symbol.for('react.element')
            ? value.props.children[0] +
              (value.props.children[1] !== null
                ? ` +${
                    value.props.children[1].props.title.filter(
                      (item: any) => item !== null
                    ).length - 1
                  }`
                : '')
            : col.name === 'createdAt'
            ? screen === 'reporting'
              ? utils.convertDate(parseInt(row.createdAt))
              : row.createdAt
            : value;
        const rowKey = j === start ? `${page}${j}-row` : `${j}-row`;
        const rowStyle = j % 2 ? styles.rowOdd : styles.row;

        if (j === start) {
          newRows.push(
            <View key={rowKey} style={styles.rowWithHeader}>
              <View style={styles.rowHeader}>
                <Text>{capitalize(splitByUpperCase(col.name as string))}</Text>
              </View>
              <View style={rowStyle}>
                <Text>{formattedValue}</Text>
              </View>
            </View>
          );
        } else {
          newRows.push(
            <View key={rowKey} style={rowStyle}>
              <Text style={{ maxWidth: '250px', height: '22px' }}>{formattedValue}</Text>
            </View>
          );
        }
      }
      return newRows;
    };
    const getReportName = (selectedTemplate) => {
      switch (screen) {
        case 'search':
          return 'Records Search Report';
        case 'indexing':
          return 'Unindexed Records Report';
        case 'reporting':
          return selectedTemplate?.name
            ? `${selectedTemplate.name} Report`
            : 'Linked Information Report';
        default:
          return 'Report';
      }
    };
    const generatePages = (selectedTemplate) => {
      let pages = [];
      const maxHeight = 18;
      const amountOfPages = Math.ceil(rows.length / maxHeight);
      for (let i = 0; i < amountOfPages; i++) {
        let start = maxHeight * i;
        const data = processCols(start, start + maxHeight, i);
        pages.push(
          <Page key={`page${i}`} size="A4" orientation={orientation} style={styles.page}>
            <View style={styles.head}>
              <Text style={styles.title}>{`Folder Name: ${folder.name}`}</Text>
              <Text style={styles.title}>
                {getReportName(selectedTemplate) + ' ' + utils.convertDate(new Date())}
              </Text>
            </View>
            <View style={styles.section}>{data}</View>
            <View style={styles.footer} fixed>
              <Text>Generated by Datacapture.ie</Text>
              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${getReportName(selectedTemplate)} ${utils.convertDate(
                    new Date()
                  )} ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        );
      }

      return pages;
    };
    if (cols.length) {
      return <Document>{generatePages(selectedTemplate)}</Document>;
    } else {
      return (
        <Document>
          <Page size="A4" style={styles.page}>
            <Text>No data available</Text>
          </Page>
        </Document>
      );
    }
  },
  determineFolderFieldType: (fftype = 'none') => {
    let type;
    switch (fftype) {
      case 'datetime':
        type = 'dateValue';
        break;
      case 'boolean':
        type = 'booleanValue';
        break;
      case 'currency':
        type = 'floatValue';
        break;
      case 'integer':
        type = 'intValue';
        break;
      case 'multistring':
        type = 'multistring';
        break;
      default:
        type = 'stringValue';
        break;
    }
    return type;
  },
  parseRecordToUpdateToSave: (recordsToUpdate: Row, fieldsToSave: Map<any, any>) => {
    if (!fieldsToSave.size) {
      fieldsToSave = new Map();
    }
    if (!fieldsToSave.get(recordsToUpdate.recordID)) {
      fieldsToSave.set(recordsToUpdate.recordID, new Map());
    }
    let recordToSave = fieldsToSave.get(recordsToUpdate.recordID);
    for (let f in recordsToUpdate.fields) {
      let field = recordsToUpdate.fields[f];
      let type;
      for (let t in recordsToUpdate.fields[f]) {
        if (t !== 'id') {
          type = t;
        }
      }
      let newField = {};
      newField[type] = field[type];
      newField['id'] = field.id;
      recordToSave.set(field.id, newField);
    }
    return fieldsToSave;
  },
  parseScannedRecordsToSave: (records: Row[], fieldsToSave: Map<any, any>) => {
    if (!fieldsToSave.size) {
      fieldsToSave = new Map();
    }
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (!fieldsToSave.get(record.id)) {
        fieldsToSave.set(record.id, new Map());
      }
      let recordToSave = fieldsToSave.get(record.id);
      for (let j = 0; j < record.fields.length; j++) {
        let field = record.fields[j];
        let type = utils.determineFolderFieldType();
        let newField = {};
        newField[type] = field.value;
        newField['id'] = field.field.id;
        recordToSave.set(field.field.id, newField);
      }
      window.log(recordToSave);
    }
    return fieldsToSave;
  },
  getTypeOfEl: (screen: string) => {
    switch (screen) {
      case 'users':
        return 'user/s';
      case 'folders':
        return 'folder/s';
      case 'organisations':
        return 'organisation/s';
      default:
        return 'record/s';
    }
  },
  performQuery: async (query: any) => {
    const res = await fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: query,
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

    return await res;
  },
  parseFieldValue: (field: Field) => {
    let value: string[] | string = field.value;
    switch (field.field.type) {
      case 'datetime':
        value = utils.convertDate(parseInt(value));
        break;
      default:
        if (value.indexOf(',') > -1) {
          value = value.split(',');
          let newVal = '';
          value.forEach((val, i) => {
            newVal += val;
            if (i < value.length - 1) {
              newVal += ' - ';
            }
          });
          value = newVal;
        }
        break;
    }
    return value;
  },
  parseSearchInfo: (data: Row, folderData: SelectedFolder) => {
    let col: any[] = [];
    folderData.folderFields.forEach((ff, i) => {
      if (!ff.active) return null;
      //set index of mainField to order the rows

      col.push(ff);
    });

    return { col: col, rows: data };
  },
  parseLinkedInfo: (data: any, folderData: SelectedFolder) => {
    let col: FolderField[] = [];
    let mainIndex: number;
    folderData.folderFields.forEach((ff, i: number) => {
      if (!ff.active || ff.name === 'createdAt') return null;
      //set index of mainField to order the rows

      col.push(ff);
      if (ff.mainField) {
        mainIndex = col.length - 1;
      }
    });
    let rows: Row[] = [];
    data.forEach((li, index: number) => {
      let rowObj: any = { fields: [] };
      col.map((c, i) => {
        rowObj['id'] = index;

        if (i === mainIndex) {
          rowObj.fields.push({
            field: {
              name: c.name,
              id: li.primaryLinkValue.fieldId,
            },
            value: li.primaryLinkValue.value,
          });
        } else {
          li.linkedValues.forEach((lv: any) => {
            if (lv.fieldId !== c.id) return;
            rowObj[c.name] = lv.value;
            rowObj.fields.push({
              field: {
                name: c.name,
                id: lv.fieldId,
                type: c.type,
              },
              value:
                c.type !== 'datetime' ? lv.value : utils.formatDateWithMoment(lv.value),
            });
          });
        }
      });
      rows.push(rowObj);
    });
    return { col: col, rows: rows };
  },
  getParsedModel: (folder: any, linkedData: any, newModel: GridSortModel) => {
    let parsedModel = [{ field: '', sort: newModel[0].sort }];
    folder.folderFields.forEach((ff: FolderFields) => {
      if (!ff.active) return null;
      //set index of mainField to order the rows
      if (ff.name === newModel[0].field) {
        linkedData.Linked_Informations.linked_Informations.forEach(
          (li: LinkedInformations) => {
            if (li.primaryLinkValue.fieldId === ff.id) {
              parsedModel[0].field = 'primaryLinkValue';
              return;
            } else {
              li.linkedValues.forEach((lv: LinkedValues) => {
                if (lv.fieldId === ff.id) {
                  parsedModel[0].field = lv.name;
                  return;
                }
              });
              return;
            }
          }
        );
      }
    });
    return parsedModel;
  },
  getNotePriority: (params: GridRenderCellParams<any, any, any>) => {
    let lastNote, hasHighPriorityNote;
    if (params.row && params.row.documentNotes?.length > 0) {
      // check if the record has any note with high priority, if it has, place the red icon, even if it is not the last note.
      hasHighPriorityNote = params.row.documentNotes.some(
        (note: Note) => note.priority === 'High'
      );
      if (!hasHighPriorityNote)
        // if does not have any note with high priority, gets the last note to determine the color
        lastNote = params.row.documentNotes[params.row.documentNotes.length - 1];
    }
    return hasHighPriorityNote ? 'High' : lastNote?.priority;
  },
  GenerateRenderCell: (
    params: GridRenderCellParams<any, any, any>,
    regex: RegExp | string,
    mainField: boolean,
    firstIndex: boolean,
    type: any,
    state: any,
    col
  ) => {
    const expandedRegex = expandN(regex, 2);
    let index = params.api.getAllRowIds().findIndex((row) => row === params.id);
    // Render a custom content for the cell
    let priority = utils.getNotePriority(params);
    if (regex === undefined || params.value === '') {
      if (type === 'multistring') {
        return (
          <MultistringCell
            params={params}
            screen={state.screen}
            fieldID={col.id}
            colName={col.name}
          />
        );
      }
      return (
        <>
          {mainField &&
            params.row.documentNotes &&
            params.row.documentNotes.length > 0 &&
            priority && ( // ICON IS ONLY ADDED WHEN IT IS MAINFIELD and has notes
              <FontAwesomeIcon
                icon="fas fa-sticky-note"
                color={getBGColor(priority)?.slice(0, -2)}
                size={12}
              /> // ICON NOTES
            )}
          <Typography sx={{ marginLeft: 0.5 }}>
            {isString(params.value) ? params.value.trim() : params.value}
          </Typography>
          {/*BACKDROPS OCR LOADER & COMPLETED*/}
          {params.field !== 'createdAt' && firstIndex && (
            <Box
              sx={{
                position: 'absolute',
                height: '51px',
                width: '150%',
                left: '-20px',
                display: params.api
                  .getRowElement(params.id)
                  ?.className.includes('row-loading')
                  ? 'flex'
                  : 'none',
              }}
            >
              <LoaderLinear />
            </Box>
          )}
          {params.field !== 'createdAt' &&
            firstIndex && ( // TEXTRACT PROCESS LOADER
              <Box
                sx={{
                  position: 'absolute',
                  left: '5px',
                  display: params.api
                    .getRowElement(params.id)
                    ?.className.includes('textract-loading')
                    ? 'flex'
                    : 'none',
                }}
              >
                <CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? 'common.white'
                        : 'common.greyblueDark',
                  }}
                />
              </Box>
            )}
          {params.field !== 'createdAt' && (
            <Fade
              in={params.api
                .getRowElement(params.id)
                ?.className.includes('row-completed')}
              timeout={{
                enter: 1200,
                appear: 1200,
                exit: 1200,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  height: '51px',
                  width: '150%',
                  left: '-20px',
                  backgroundColor: '#2ed57310',
                }}
              />
            </Fade>
          )}
        </>
      );
    }
    if (regex instanceof RegExp) {
      const valid = regex.test(params.value);
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
          >
            {mainField &&
              params.row.documentNotes &&
              params.row.documentNotes.length > 0 &&
              priority && (
                <FontAwesomeIcon
                  icon="fas fa-sticky-note"
                  color={getBGColor(priority)?.slice(0, -2)}
                  size={12}
                />
              )}
            <Typography color={valid ? '' : '#ff4343'}>{params.value}</Typography>
          </Box>
          {valid ? (
            <Tooltip title="The input matches the pattern required" arrow>
              <Box data-testid="regex-success-icon">
                <FontAwesomeIcon
                  icon="fas fa-check-circle"
                  size={15}
                  color="common.positive"
                />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <>
                  {`The input doesn't match the pattern required, try with something like `}
                  <b>{expandedRegex[0]}</b>
                  {' or '}
                  <b>{expandedRegex[1]}</b>
                </>
              }
              arrow
            >
              <Box data-testid="regex-error-icon">
                <FontAwesomeIcon
                  icon="fas fa-times-circle"
                  size={15}
                  color="common.negative"
                />
              </Box>
            </Tooltip>
          )}
        </Stack>
      );
    }
  },
  hasSetWorkflowInAnyFolder: (userPermissions: any) => {
    for (let i = 0; i < userPermissions.folderAccessDetails.length; i++) {
      const folderAccess = userPermissions.folderAccessDetails[i];
      if (folderAccess.folderPermissions?.processPathPermissions?.canSetProcessPath) {
        return true;
      }
    }
    return false;
  },
  checkFolderPermissions: (userPermissions: any, folder: SelectedFolder | null) => {
    if (!userPermissions || !userPermissions.folderAccessDetails || !folder) {
      return false;
    }
    let selectedFolderPermissions = userPermissions.folderAccessDetails.filter(
      (f) => f.folderID === folder.id
    );
    if (!selectedFolderPermissions.length) return false;
    return selectedFolderPermissions[0];
  },
  saveDataToIndexedDB: async (data: any) => {
    if (!data) return;
    await db.table('folders').clear();
    await db
      .table('folders')
      .bulkPut(data)
      .catch((e) => window.log(e));
  },

  getNotificationRecordInfo: (data) => {
    if (
      !data.folder ||
      (data.type !== 'ApprovalRequired' && data.type !== 'ApprovalPerformed')
    )
      return;

    let mainField =
      data.folder.folderfields &&
      data.folder.folderFields.find((field) => field.mainField);

    if (!mainField) {
      mainField = data.folder.folderFields[0];
    }

    const folderName = data.folder.name ? data.folder.name : '';
    const mainFieldName = mainField.name ? mainField.name : '';
    const recordMainFieldData = data.record.fields
      ? data.record.fields.find((obj) => obj.field.isMainField)
      : '';
    const mainFieldValue = recordMainFieldData ? recordMainFieldData.value : '';

    return {
      folderName,
      mainFieldName,
      mainFieldValue,
    };
  },
  getDateTime: (timeStamp: string) => {
    const newDate = new Date(timeStamp);
    const date = newDate.toLocaleDateString();
    const hoursMin = newDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${date} ${hoursMin}`;
  },
  getVisibleColumnsData: (tableSettings, screen, selectedFolder, defaultVisibleRows) => {
    let tableScreen = tableSettings[screen];
    let visibleColumns =
      tableScreen &&
      tableScreen.visibleColumns &&
      tableScreen.visibleColumns[selectedFolder.id];

    return visibleColumns || defaultVisibleRows;
  },
  recordHasMultistringFolderField: (record: Record) => {
    return record.fields.some((field) => field.field.type === 'multistring');
  },
  checkForMultistringFieldsAndGenerateValues: (
    field: Field,
    multistringValues: { [id: string]: any },
    rowObj: any
  ) => {
    if (field.field.type === 'multistring') {
      if (!multistringValues[field.field.id]) {
        multistringValues[field.field.id] = [field.value];
      } else {
        multistringValues[field.field.id].push(field.value);
      }
      rowObj.multistring = multistringValues;
      rowObj.multistringFieldID = field.field.id;
    }
  },
  checkForMultistringFieldBeforeUploadRecord: (arr) => {
    return arr.forEach((obj) => {
      if (obj.hasOwnProperty('fields') && Array.isArray(obj.fields)) {
        obj.fields.forEach((field) => {
          if (field.hasOwnProperty('multistring')) {
            let multistringFields = field.multistring.map((val) => ({
              // create new field for each value
              id: field.id,
              stringValue: val,
            }));
            obj.fields = [...obj.fields, ...multistringFields];
            obj.fields = obj.fields.filter((ff) => !ff.multistring); // remove multistring field that contains values array
          }
        });
      }
    });
  },
  generateOCRScanValuesToSaveInIndexedDB: (
    fields: Array<{ id: string; [key: string]: any }>
  ) => {
    let res: { [key: string]: any } = {};
    fields.forEach((field) => {
      let ff = res[field.id];
      if (ff) {
        let value, newValue;
        const valueKey = Object.keys(field).find((key) => key !== 'id') || 'stringValue'; // We need to find the key value, it could be 'stringValue', 'floatValue', 'dateValue', etc.
        ff.hasOwnProperty(valueKey)
          ? (value = [ff[valueKey]]) // if this field.id is repeated, it means it's a multistring field, so we convert its value to array
          : (value = ff[Object.keys(ff)[1]]); // if doesn't have this key, we get the array

        newValue = field[valueKey];
        res[field.id] = { id: field.id, multistring: [...value, newValue] }; // multiple fields with the same id, now they are in an array with 'multistring' key
      } else {
        const valueKey =
          Object.keys(field).find((key) => key.includes('Value')) || 'stringValue';
        res[field.id] = {
          id: field.id,
          [valueKey]: field[valueKey],
        };
      }
    });
    return res; // Output multistring => {[field.id]: {id: field.id, multistring: Array<values>}}
  },
  formatDateWithMoment: (value) => {
    if (value === null || value === undefined || value === '') return '';
    const possibleFormats = [
      'YYYY-MM-DD',
      'dddd, MMMM Do YYYY',
      'MMMM D, YYYY',
      'DD/MM/YYYY',
      'DD-MM-YYYY',
      'DD.MM.YYYY',
      'YYYY/MM/DD',
    ];

    // check if the value is a unix timestamp
    if (typeof value === 'number' || /^\d+$/.test(value)) {
      // convert to number if it's a string
      const valueAsNumber = Number(value);
      // handle timestamps in milliseconds and seconds
      const lengthOfTimestamp = value.toString().length;
      if (lengthOfTimestamp === 13) {
        return moment(valueAsNumber).format('DD/MM/YYYY');
      } else if (lengthOfTimestamp === 10) {
        return moment.unix(valueAsNumber).format('DD/MM/YYYY');
      } else {
        return 'Invalid date';
      }
    }

    // if it's not a unix timestamp check possible formats
    const d = moment(value, possibleFormats, true);

    if (!d.isValid()) {
      // Try parsing as ISO 8601 format
      const isoFormat = moment(value);
      if (isoFormat.isValid()) {
        return isoFormat.format('DD/MM/YYYY');
      }
    }

    if (d.isValid()) {
      return d.format('DD/MM/YYYY');
    } else {
      return 'Invalid date';
    }
  },
};

export default utils;
