import { gql } from 'apollo-boost';

export const GET_PROCESS_PATHS = gql`
  query {
    processPaths {
      id
      name
      processSteps {
        id
        stepOrder
        completed
        required
        ... on ApprovalStep {
          toBeApprovedBy
          allUsersMustApprove
          approvalStatus
          usersWhoHavePerformedApproval {
            userId
            timeStamp
            actionPerformed
          }
        }
      }
    }
  }
`;

export const CREATE_PROCESS_PATH = gql`
  mutation createProcessPath($ProcessPath: ProcessPathInput) {
    createProcessPath(ProcessPath: $ProcessPath) {
      processPath {
        id
        processSteps {
          id
          stepOrder
          completed
          required
          ... on ApprovalStep {
            toBeApprovedBy
            allUsersMustApprove
            approvalStatus
            usersWhoHavePerformedApproval {
              userId
              timeStamp
              actionPerformed
            }
          }
        }
      }
      error
    }
  }
`;

export const DELETE_PROCESS_PATH = gql`
  mutation deleteProcessPath($processPathId: ID) {
    deleteProcessPath(processPathId: $processPathId) {
      processPath {
        id
        processSteps {
          id
          stepOrder
          completed
          required
        }
      }
      error
    }
  }
`;

export const CREATE_PROCESS_STEP = gql`
  mutation createProcessStep($processPathId: ID!, $processStep: IProcessStepInput) {
    createProcessStep(processPathId: $processPathId, processStep: $processStep) {
      processPath {
        id
        name
        processSteps {
          id
          stepOrder
          completed
          required
        }
      }
      error
    }
  }
`;

export const DELETE_PROCESS_STEP = gql`
  mutation deleteProcessStep($processPathId: ID!, $processStepId: ID!) {
    deleteProcessStep(processPathId: $processPathId, processStepId: $processStepId) {
      processPath {
        id
        name
        processSteps {
          id
          stepOrder
          completed
          required
        }
      }
      error
    }
  }
`;

export const UPDATE_PROCESS_PATH = gql`
  mutation updateProcessPath($processPathId: ID!, $ProcessPath: ProcessPathInput) {
    updateProcessPath(processPathId: $processPathId, ProcessPath: $ProcessPath) {
      processPath {
        name
      }
      error
    }
  }
`;

// export const REMOVE_PROCESS_PATH_FROM_FOLDER = gql`
//   mutation removeProcessPathFromFolder($id: ID!, $pathId: ID!) {
//     removeProcessPathFromFolder(id: $id, pathId: $pathId) {
//       folder {
//         #...
//       }
//       error
//     }
//   }
// `;

export const ADD_PROCESS_PATH_TO_FOLDER = gql`
  mutation addProcessPathToFolder($id: ID!, $pathId: ID!) {
    addProcessPathToFolder(id: $id, pathId: $pathId) {
      folder {
        name
        id
      }
      error
    }
  }
`;

export const ADD_PROCESS_PATH_TEMPLATE_TO_RECORD = gql`
  mutation addProcessPathTemplateToRecord($recordId: ID!, $processPathId: ID!) {
    addProcessPathTemplateToRecord(recordId: $recordId, processPathId: $processPathId) {
      record {
        id
        processPath {
          id
          name
        }
      }
      error
    }
  }
`;

// export const ADD_PROCESS_PATH_TEMPLATE_TO_RECORD = gql`
//   mutation addProcessPathTemplateToRecord($recordId: ID!, $processPathId: ID!) {
//     addProcessPathTemplateToRecord(recordId: $recordId, processPathId: $processPathId) {
//       record {
//         #...
//       }
//       error
//     }
//   }
// `;
