import { Dispatch, SetStateAction } from 'react';
import Typography from '@mui/material/Typography';
import TemplateSelector from './TemplateSelector';
import { Stack } from '@mui/material';
import { Workflow } from '../types';

interface HeaderProps {
  workflows: Workflow[];
  setSelectedWorkflow: Dispatch<SetStateAction<Workflow | null>>;
}

export default function Header({ workflows, setSelectedWorkflow }: HeaderProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="0 10px"
      sx={{ backgroundColor: 'common.greyblueDarker' }}
    >
      <Typography variant="h6">SET WORKFLOW</Typography>
      <TemplateSelector workflows={workflows} setSelectedWorkflow={setSelectedWorkflow} />
    </Stack>
  );
}
