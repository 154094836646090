import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { Attachment } from '../components/masterTable/masterTableTypes';
import { Context as StatusContext } from '../context/statusContext';
import { StatusContextTypes } from '../context/statusContextTypes';
import gqlRecords from '../utils/gql/gqlRecords';
import useUserStorageData from './user/userUserStorageData';

interface Props {
  attachment: Attachment;
}

const usePDF = ({ attachment }: Props) => {
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const { isSuser, selOrg } = useUserStorageData();
  const [url, setUrl] = useState<null | string>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachment | null>(null);

  const [getURL, { data, loading }] = useLazyQuery(gqlRecords.GET_URL, {
    fetchPolicy: 'network-only',
    variables: {
      folderId: attachment && attachment.folderId,
      key: attachment && attachment?.name?.split('/')[2],
      org: isSuser ? (state.selectedOrg ? state.selectedOrg.id : selOrg.id) : null,
    },
    onCompleted: (data) => {
      if (!data) return;
      setUrl(data.GenerateGetURL);
    },
  });

  useEffect(() => {
    if (currentAttachment && attachment && attachment.name === currentAttachment.name)
      return;
    if (attachment && attachment.name && attachment.folderId) {
      getURL();
      setCurrentAttachment(attachment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  return {
    url,
    loading,
  };
};

export default usePDF;
