import { createTheme } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';

const blue = '#0a5487';
const secondBlue = '#1190E8';
const orange = '#ffc929';
const grey = '#868686';
const lightGrey = '#f4f4f4';
const white = '#FFF';
const green = '#44F540';
const positive = '#2ed573';
const positiveDark = '#2ea760';
const negative = '#ff4757';
const negativeDark = '#d0424f';
const darkgrey = 'rgb(111, 111, 111)';
const greyblue = '#8399a8';
const lightblue = 'rgb(210, 225, 235)';
const greyblueDark = '#79909f';
const greyblueDarker = '#436072';
const greyblueDarkerTransp = 'rgba(67,96,114,0.7)';
const blueGrey = {
  0: '#eaeff1',
  100: '#CFD8DC',
  200: '#B0BEC5',
  300: '#90A4AE',
  400: '#78909C',
  500: '#607D8B',
  600: '#546E7A',
  700: '#455A64',
  800: '#37474F',
  900: '#263238',
  1000: '#20292d',
};
const blueShadow = {
  0: '#2EA2EF',
  100: '#1B9AEE',
  200: '#118FE4',
  300: '#1083D1',
  400: '#0E78BE',
  500: '#0D6CAB',
  600: '#0B6098',
  700: '#095486',
  800: '#084872',
  900: '#073C5F',
  1000: '#06304C',
};

type ColorMode = 'dark' | 'light';

const generatePalette = (mode: ColorMode) => {
  let palette = {
    mode,
    common: {
      blue,
      secondBlue,
      orange,
      grey,
      lightGrey,
      lightblue,
      green,
      greyblueDarkerTransp,
      blueGrey,
      blueShadow,
    },
    primary: {
      main: blue,
    },
    secondary: {
      main: orange,
    },
    white: {
      main: white,
    },
    black: {
      ultraLightTransp: '#2B333B50',
      ultraLight: '#2B333B',
      light: '#22282F',
      main: '#22272E',
      dark: '#1A1E23',
    },
  };

  return mode === 'light'
    ? {
        ...palette,
        mode,
        common: {
          ...palette.common,
          positive,
          positiveDark,
          negative,
          negativeDark,
          greyblue,
          greyblueDark,
          greyblueDarker,
        },
        negative: {
          main: `${negative}`,
        },
        positive: {
          main: `${positive}`,
        },
        background: {
          default: '#f2f2f2',
          paper: '#f2f2f2',
        },
      }
    : {
        ...palette,
        mode,
        common: {
          ...palette.common,
          positive: '#51CF57',
          positiveDark: '#388F3C',
          negative: '#D32F2F',
          negativeDark: '#B71C1C',
          greyblue: '#607D8B',
          greyblueDark: '#344855',
          greyblueDarker: '#313f48',
        },
        negative: {
          main: '#D32F2F',
        },
        positive: {
          main: '#51CF57',
        },
        background: {
          default: '#22282F',
          paper: '#22282F',
        },
      };
};

export const generateThemeV4 = (mode: ColorMode) => {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
    overrides: {
      MuiDropzoneArea: {
        icon: {
          color: greyblueDark,
        },
        root: {
          height: 'calc(100% - 95px)',
        },
      },
    },
    palette: generatePalette(mode),

    typography: {
      tab: {
        fontFamily: 'Raleway',
        textTransform: 'none',
        fontWeight: '700',
        fontSize: '1rem',
      },
      estimate: {
        fontFamily: 'Pacifico',
        fontSize: '1rem',
        textTransform: 'none',
        color: 'white',
      },
      p: {
        fontFamily: 'Raleway',
        fontWeight: '300',
        color: '#333',
      },
      body1: {
        fontFamily: 'Roboto',
        fontWeight: '300',
        color: 'rgb(91, 91, 91)',
        fontSize: '0.8rem',
      },
      body2: {
        fontFamily: 'Roboto',
        fontWeight: '300',
        color: greyblueDark,
        fontSize: '0.8rem',
      },
      h2: {
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize: '2.5rem',
        color: blue,
        lineHeight: 1.5,
      },
      h3: {
        fontFamily: 'Raleway',
        fontSize: '0.8em',
        color: darkgrey,
      },
      h4: {
        fontFamily: 'Raleway',
        fontSize: '1.75rem',
        color: blue,
        fontWeight: 700,
      },
      h5: {
        fontFamily: 'Raleway',
        fontSize: '1.25rem',
        color: blue,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: '1.25rem',
        fontWeight: 300,
        color: grey,
      },
      subtitle2: {
        fontSize: '1.25rem',
        fontWeight: 300,
        color: 'white',
      },
      subtitle3: {
        fontSize: '1.1rem',
        fontWeight: 300,
        color: 'white',
      },
      caption: {
        fontSize: '0.8rem',
        fontWeight: 800,
        fontFamily: 'Raleway',
      },
      learnButton: {
        borderColor: blue,
        color: blue,
        borderWidth: 2,
        textTransform: 'none',
        borderRadius: 50,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
      },
      alert: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        color: negative,
      },
    },
  });
};

// Configuration for `createTheme`
declare module '@mui/material/styles/' {
  interface TypographyVariantsOptions {
    alert?: React.CSSProperties;
    tab?: React.CSSProperties;
    estimate?: React.CSSProperties;
    p?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    subtitle5?: React.CSSProperties;
    learnButton?: React.CSSProperties;
  }
  interface PaletteOptions {
    negative: {
      main: string;
    };
    positive: {
      main: string;
    };
    white: {
      main: string;
    };
    black: {
      ultraLightTransp: string;
      ultraLight: string;
      light: string;
      main: string;
      dark: string;
    };
  }

  interface CommonColors {
    blue: string;
    orange: string;
    grey: string;
    lightGrey: string;
    green: string;
    positive: string;
    positiveDark: string;
    negative: string;
    negativeDark: string;
    greyblue: string;
    lightblue: string;
    greyblueDark: string;
    greyblueDarker: string;
    greyblueDarkerTransp: string;
    blueGrey: {
      0: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      1000: string;
    };
    blueShadow: {
      0: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      1000: string;
    };
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    poster: true;
    tab: true;
    estimate: true;
    p: true;
    subtitle3: true;
    subtitle4: true;
    subtitle5: true;
    learnButton: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    greyblueDark: true;
    greyblueDarker: true;
  }
}

export const generateTheme = (mode: 'light' | 'dark') => {
  return createThemeV5({
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        standard: 300,
        // this is to be used in complex animations
        complex: 375,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
      easing: {
        // This is the most common easing curve.
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        // Objects enter the screen at full velocity from off-screen and
        // slowly decelerate to a resting point.
        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
        // Objects leave the screen at full velocity. They do not decelerate when off-screen.
        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
        // The sharp curve is used by objects that may return to the screen at any time.
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
      },
    },
    components: {
      /* MuiAutocomplete: {
        defaultProps: {
          disablePortal: true,
        },
        styleOverrides: {
          paper: {
            maxHeight: "10px",
          },
          popper: {
            maxHeight: "10px",
          },
        },
      }, */ MuiSelect: {
        styleOverrides: {
          icon: ({ theme }) => ({
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.common.greyblueDarker,
          }),
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popupIndicator: ({ theme }) => ({
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.common.greyblueDark
                : theme.palette.common.greyblueDarker,
          }),
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.common.lightblue
                : theme.palette.common.greyblueDark,
            '&.Mui-checked': {
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.common.greyblueDarker,
            },
          }),
        },
      },
      MuiIconButton: {
        variants: [
          {
            props: { variant: 'topBar' },
            style: {
              padding: '4px 10px',
              borderRadius: 0,
              '&:hover': {
                backgroundColor: '#f2f2f2',
                boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 35%), 0px 9px 3px -1px #f2f2f2',
              },
              '&:hover .MuiIcon-root': {
                color: blue,
              },
            },
          },
          {
            props: { color: 'primary' },
            style: ({ theme }) => ({
              color:
                mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.common.greyblueDark,
            }),
          },
        ],
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: mode === 'dark' ? white : ownerState.style?.color,
            backgroundColor:
              ownerState.severity === 'success' && mode === 'dark'
                ? positiveDark
                : ownerState.severity === 'error' && mode === 'dark'
                ? negativeDark
                : ownerState.style?.backgroundColor,
            '& .MuiAlert-icon': {
              color: mode === 'dark' ? white : ownerState.style?.color,
            },
          }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            '& input:-webkit-autofill': {
              boxShadow:
                mode === 'dark'
                  ? '0 0 0 100px #1E1E1E99 inset'
                  : ownerState.style?.boxShadow,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: blueGrey[700],
              },
              '&.Mui-focused fieldset': {
                borderColor: mode === 'dark' ? lightblue : greyblueDarker,
              },
            },
          }),
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: mode === 'light' ? greyblueDarker : white,
            '&.MuiInputLabel-root': {
              '&.Mui-focused': {
                color: mode === 'dark' ? white : ownerState.style?.color,
              },
            },
          }),
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: mode === 'light' ? greyblueDarker : white,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: (props) => ({
            color: mode === 'light' ? props.ownerState.style?.color : white,
          }),
          primary: (props) => ({
            color: mode === 'light' ? props.ownerState.style?.color : white,
          }),
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: (props) => ({
            color: mode === 'light' ? greyblueDarker : white,
          }),
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'greyblueDark' },
            style: {
              backgroundColor: greyblueDark,
              textTransform: 'uppercase',
              color: white,
            },
          },
          {
            props: { variant: 'greyblueDarker' },
            style: {
              backgroundColor: greyblueDarker,
              textTransform: 'uppercase',
              color: white,
            },
          },
        ],
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            color:
              ownerState.variant === 'outlined'
                ? theme.palette.mode === 'dark'
                  ? lightblue
                  : greyblueDark
                : ownerState.style?.color,
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: mode === 'light' ? white : theme.palette.black.dark,
          }),
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.mode === 'dark' ? lightblue : greyblueDark,
          }),
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },

    typography: {
      alert: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        color: negative,
      },
      tab: {
        fontFamily: 'Roboto',
        textTransform: 'none',
        fontWeight: '700',
        fontSize: '1rem',
        color: white,
      },
      estimate: {
        fontFamily: 'Pacifico',
        fontSize: '1rem',
        textTransform: 'none',
        color: white,
      },
      p: {
        fontFamily: 'Raleway',
        fontWeight: '300',
        color: '#333',
      },
      body1: {
        fontFamily: 'Roboto',
        fontWeight: '300',
        color: mode === 'dark' ? white : 'rgb(91, 91, 91)',
        fontSize: '0.8rem',
      },
      body2: {
        fontFamily: 'Roboto',
        fontWeight: '300',
        color: mode === 'dark' ? white : greyblueDark,
        fontSize: '0.8rem',
      },
      h2: {
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize: '2.5rem',
        color: blue,
        lineHeight: 1.5,
      },
      h3: {
        fontFamily: 'Raleway',
        fontSize: '0.8em',
        color: mode === 'dark' ? white : darkgrey,
      },
      h4: {
        fontFamily: 'Raleway',
        fontSize: '1.75rem',
        color: blue,
        fontWeight: 700,
      },
      h5: {
        fontFamily: 'Raleway',
        fontSize: '1.25rem',
        color: blue,
        fontWeight: 700,
      },
      h6: {
        color: mode === 'light' ? grey : white,
      },
      subtitle1: {
        fontSize: '1.25rem',
        fontWeight: 300,
        color: mode === 'light' ? grey : white,
      },
      subtitle2: {
        fontSize: '1.25rem',
        fontWeight: 300,
        color: white,
      },
      subtitle3: {
        fontSize: '1.1rem',
        fontWeight: 300,
        color: 'white',
        fontFamily: 'Roboto',
      },
      subtitle4: {
        fontSize: '1rem',
        fontWeight: 300,
        color: mode === 'dark' ? 'white' : 'gray',
        fontFamily: 'Roboto',
      },
      subtitle5: {
        fontSize: '0.8rem',
        fontWeight: 400,
        color: 'white',
        fontFamily: 'Roboto',
      },
      caption: {
        fontSize: '0.8rem',
        fontWeight: 800,
        fontFamily: 'Raleway',
        color: mode === 'dark' ? white : greyblueDark,
      },
      learnButton: {
        borderColor: blue,
        color: blue,
        borderWidth: 2,
        textTransform: 'none',
        borderRadius: 50,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
      },
      button: {
        color: mode === 'light' ? greyblueDark : white,
      },
    },

    palette: generatePalette(mode),
  });
};

export const generateScrollbar = (
  width: number | string = '5px',
  height: number | string = 0
) => ({
  '&::-webkit-scrollbar': {
    width,
    height,
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#79909f',
    borderRadius: '4px',
  },
});
