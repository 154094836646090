import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  Theme,
  IconButton,
  MenuItem,
} from '@mui/material';
import FontAwesomeIcon from '../../FAIcon';
import { StyledLabel, StyledSelect } from './AdvancedSettingsModal';
import { StyledTextField } from '../StyledComponents';
import { generateScrollbar } from '../../theme';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import RTC from '../../../utils/socketComm';
import { Socket } from 'socket.io-client';

type RegexItem = {
  label: string;
  regex: string | RegExp;
};

type RegexInfo = {
  message: string;
  status: null | 'error' | 'success';
};

interface RegularExpressionAcordProps {
  generalStyles: any;
  disabled: boolean;
  handleChangeSettings: (key: string, value: any) => void;
  settings: any;
}

const styles = {
  multiline: {
    height: 'auto',
    width: '200px',
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.lightblue,
    '& fieldset': { border: 'none' },
    '& textarea': { height: '100px!important', padding: '5px 0' },
    '& .MuiOutlinedInput-root': { padding: '0 10px' },
    '& .MuiInputBase-root': {
      border: 'none',
      color: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.greyblueDarker,
    },
  },
  search: {
    '.MuiOutlinedInput-input': { padding: '4px 10px' },
    '& .MuiInputBase-root': {
      border: 'none',
      color: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.greyblueDarker,
    },
    borderRadius: '5px',
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.lightblue,
    '& fieldset': { border: 'none' },
  },
  searchBox: {
    height: '100%',
    // minHeight: '136px',
    overflow: 'auto',
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.lightblue,
    color: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.greyblueDarker,
    display: 'flex',
    gap: 0.7,
    flexDirection: 'column',
    p: 1,
    borderRadius: '5px',
  },
  searchBoxItem: {
    borderRadius: '5px',
    fontSize: 10,
    p: '2px 6px',
    color: 'common.white',
    width: '180px',
  },
  testRegexInput: {
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark' ? '#2D333A' : theme.palette.common.lightblue,

    '& .MuiInput-root': {
      p: '2px 10px',
      color: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.greyblueDarker,
    },
    '& fieldset': { border: 'none' },
    '& .MuiInputBase-root': { border: 'none' },
  },
  testRegexBtn: {
    backgroundColor: 'common.orange',
    color: 'common.greyblueDark',
    '&:hover': {
      backgroundColor: 'common.orange',
      color: 'common.white',
    },
    '&:disabled': { backgroundColor: 'common.greyblueDark' },
    '&.MuiButton-root': { minWidth: '32px' },
  },
};

const regexToString = (regex: RegExp | string) => {
  let regexString = typeof regex === 'string' ? regex : regex.source;
  regexString = regexString.replace(/^\/|\/[gimuy]*$|^\/$|$/g, '');
  return regexString;
};

const TYPE_OF_FUNCTIONS = ['SELECT', 'REPLACE', 'IGNORE'];

const REGEX: RegexItem[] = [
  {
    label: 'Date',
    regex: /([012]?[1-9]|[123]0|31)\/(0?[1-9]|1[012])\/(?:[123][0-9])?[0-9][0-9]/,
  },
  { label: 'Currency Value', regex: /^\d+(\.\d{0,2})?$/ },
  { label: 'Yes/No', regex: /^(Yes|No)$/ },
  { label: 'Letters Only', regex: /[A-Za-z]+/g },
  { label: 'Uppercase Letters', regex: /[A-Z]+/g },
  { label: 'Lowercase Letters', regex: /[a-z]+/g },
  { label: 'Numbers Only', regex: /\d+/ },
  { label: 'X Numbers Only', regex: /\d{X}/ },
  { label: 'Starts with X and Y More Digits', regex: /^X[0-9]{Y}$/ },
  {
    label: 'Starts with X Numbers, Slash, Letter and Y More Digits',
    regex: /^[0-9]{X}\/[A-Z][0-9]{Y}$/,
  },
  { label: 'Starts with XXX- and Y More Digits', regex: /^XXX-[0-9]{Y}/ },
  { label: 'X Digits Ending in a Y', regex: /^[0-9]{X}Y/ },
  { label: 'Starts with X or Y and Z More Digits', regex: /^((X)|(Y))[0-9]{Z}/ },
  { label: 'Remove All Whitespace', regex: /\s/g },
];

const generateInitialSelectedRegexValue = (settings: any) => {
  return settings?.postRegexProcessing?.regexSearch === ''
    ? null
    : REGEX.find((rgx) =>
        _.toString(rgx.regex).includes(settings?.postRegexProcessing?.regexSearch)
      ) || settings?.postRegexProcessing?.regexSearch
    ? {
        regex: settings?.postRegexProcessing?.regexSearch,
        label: 'Custom',
      }
    : null;
};

const RegularExpressionAcord = ({
  generalStyles,
  disabled,
  handleChangeSettings,
  settings,
}: RegularExpressionAcordProps) => {
  const scrollbar = generateScrollbar('3px', '3px');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [expanded, setExpanded] = useState(true);
  const [selectedRegex, setSelectedRegex] = useState<null | RegexItem>(
    generateInitialSelectedRegexValue(settings)
  );
  const [textToTest, setTextToTest] = useState('');
  const [regexTestInfo, setRegexTestInfo] = useState<RegexInfo>({
    message: '',
    status: null,
  });

  const handleTestRegex = () => {
    const socket = RTC.socket as unknown as Socket;
    RTC.testRegex({
      text: textToTest,
      regexObject: {
        regexSearch: regexToString(selectedRegex?.regex || '') || '',
        postRegexType: settings?.postRegexProcessing?.postRegexType || 'SELECT',
        replaceWith: settings?.postRegexProcessing?.replaceWith || '',
      },
    });
    socket.on('test-regex-response', (res) => {
      let status =
        res === ''
          ? { message: 'No match found', status: 'error' }
          : { message: res, status: 'success' };
      setRegexTestInfo(status);
    });
    socket.on('test-regex-failed-response', (res) =>
      setRegexTestInfo({
        message: "The text didn't pass the regex test",
        status: 'error',
      })
    );
  };

  const handleInputChange = (value: string) => {
    if (value === '') setRegexTestInfo({ message: '', status: null });
    setTextToTest(value);
  };

  const handleSelectRegex = (rgx: RegexItem) => {
    setSelectedRegex(rgx);
    handleChangeSettings('postRegexProcessing.regexSearch', regexToString(rgx.regex));
  };

  const handleClearSelected = () => {
    setSelectedRegex(null);
    setTextToTest('');
    setRegexTestInfo({ message: '', status: null });
    handleChangeSettings('postRegexProcessing', null);
  };

  const filteredPredefinedExpressions: RegexItem[] = useMemo(() => {
    return REGEX.filter((item) =>
      item.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery]);

  useEffect(() => {
    if (!disabled) return;
    setExpanded(false);
  }, [disabled]);

  return (
    <Accordion
      disableGutters
      sx={{ boxShadow: 'none' }}
      disabled={disabled}
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="13px" />}
        sx={generalStyles.summary}
      >
        <Typography variant="button" sx={generalStyles.summaryTitle}>
          Regular Expression (Post-Processing)
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={generalStyles.details}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '200px' }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  flex: 8,
                  height: '100%',
                  gap: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <StyledLabel>Post Regex</StyledLabel>
                <StyledTextField
                  multiline
                  rows={3}
                  sx={styles.multiline as any}
                  value={selectedRegex ? selectedRegex.regex : ''}
                  onChange={(e) =>
                    handleSelectRegex({ label: 'custom', regex: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <StyledLabel>Function</StyledLabel>
                <StyledSelect
                  disableUnderline
                  onChange={(e) =>
                    handleChangeSettings(
                      'postRegexProcessing.postRegexType',
                      e.target.value
                    )
                  }
                  defaultValue="SELECT"
                  size="small"
                  value={settings?.postRegexProcessing?.postRegexType}
                  sx={{ width: '100%', flex: 1 }}
                >
                  {TYPE_OF_FUNCTIONS.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Box>

              {selectedRegex && (
                <IconButton
                  sx={{ position: 'absolute', top: -5, right: 0 }}
                  onClick={handleClearSelected}
                >
                  <FontAwesomeIcon icon="fas fa-times" size={10} />
                </IconButton>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.6 }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <StyledLabel>Predefined Expressions</StyledLabel>
                <StyledTextField
                  size="small"
                  sx={styles.search as any}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    endAdornment: searchQuery ? (
                      <Box
                        onClick={() => setSearchQuery('')}
                        sx={{
                          width: '12px',
                          height: '15px',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          icon="fas fa-times"
                          color="common.lightblue"
                          size={12}
                        />
                      </Box>
                    ) : (
                      <Box sx={{ width: '12px', height: '15px' }}>
                        <FontAwesomeIcon
                          icon="fas fa-search"
                          color="common.lightblue"
                          size={12}
                        />
                      </Box>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ ...styles.searchBox, ...scrollbar }}>
                {filteredPredefinedExpressions.map((item) => (
                  <Box
                    sx={{
                      ...styles.searchBoxItem,
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark'
                          ? selectedRegex && selectedRegex.label === item.label
                            ? theme.palette.common.greyblueDark
                            : '#26292E'
                          : selectedRegex && selectedRegex.label === item.label
                          ? theme.palette.common.greyblueDark
                          : theme.palette.common.greyblue,
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => handleSelectRegex(item)}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: 'common.white',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {settings?.postRegexProcessing?.postRegexType === 'REPLACE' && (
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                  <StyledLabel>Replace with</StyledLabel>
                  <StyledTextField
                    onChange={(e) =>
                      handleChangeSettings(
                        'postRegexProcessing.replaceWith',
                        e.target.value
                      )
                    }
                    size="small"
                    value={settings?.postRegexProcessing?.replaceWith}
                    sx={{ width: '100%', flex: 1, ...styles.search } as any}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <StyledLabel> Test Regex on Text</StyledLabel>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <StyledTextField
                placeholder="Write down a text you want to test with the regular expression above"
                size="small"
                fullWidth
                variant="standard"
                value={textToTest}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.value)
                }
                sx={
                  {
                    ...styles.testRegexInput,
                    '& .MuiInput-underline:before': {
                      borderBottomColor:
                        regexTestInfo.status && regexTestInfo.status === 'success'
                          ? 'common.positive'
                          : 'common.greyblueDark',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor:
                        regexTestInfo.status && regexTestInfo.status === 'success'
                          ? 'common.positiveDark'
                          : 'common.greyblueDarker',
                    },
                  } as any
                }
                error={(regexTestInfo.status && regexTestInfo.status === 'error')!!}
              />
              <Button
                size="small"
                sx={styles.testRegexBtn}
                onClick={handleTestRegex}
                disabled={selectedRegex ? false : true}
              >
                <FontAwesomeIcon icon="fas fa-angle-right" size={12} />
              </Button>
            </Box>

            {regexTestInfo.status && (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '12px',
                  color:
                    regexTestInfo.status === 'error'
                      ? 'common.negative'
                      : 'common.positive',
                }}
              >
                {regexTestInfo.message}
              </Typography>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RegularExpressionAcord;
