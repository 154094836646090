import {
  Box,
  Button,
  Popover,
  Typography,
  IconButton,
  Tooltip,
  Modal,
  Alert,
  AlertTitle,
} from '@mui/material';
import React, { useState } from 'react';
import FontAwesomeIcon from '../../../components/FAIcon';
import { TemplateButtonProps, Workflow } from '../types';

const styles = {
  button: {
    gap: 1,
    justifyContent: 'space-between',
    color: 'common.greyblueDark',
    boxShadow: '-3px 3px 5px -4px rgba(0,0,0,0.45)',
    textTransform: 'capitalize',
    fontWeight: 400,
    '& .MuiButton-startIcon': {
      transform: 'rotate(-90deg)',
      color: 'common.greyblueDark',
    },
  },
  stepIcon: { transform: 'rotate(-90deg)', color: 'common.greyblueDark' },
  text: {
    color: '#79909F',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 15,
  },
  stepButton: {
    color: 'common.white',
    borderBottom: '1px solid #00000030',
    display: 'flex',
    justifyContent: 'space-between',
    width: 140,
    p: 0,
  },
  paper: {
    backgroundColor: 'common.greyblueDarker',
    color: 'common.white',
    p: 3,
    paddingY: 1,
    paddingTop: 2,
    marginLeft: 2,
  },
};

const TemplateButton = ({
  workflow,
  selectedWorkflow,
  setSelectedWorkflow,
  deleteWorkflow,
  applyTemplateToFolder,
}: TemplateButtonProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  function handleClose() {
    setAnchorEl(null);
  }
  const handleHover = (event: any) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClick = (workflow: Workflow) => {
    selectedWorkflow && selectedWorkflow.id === workflow.id
      ? setSelectedWorkflow(null)
      : setSelectedWorkflow(workflow);
  };

  const handleModal = () => setDeleteModalIsOpen(!deleteModalIsOpen);

  return (
    <>
      <Button
        key={workflow.id}
        sx={{
          ...styles.button,
          backgroundColor:
            selectedWorkflow && selectedWorkflow.id === workflow.id
              ? 'common.lightblue'
              : 'common.white',
          color: 'common.greyblueDarker',
          '&:hover': {
            color: 'common.greyblueDark',
            backgroundColor: (theme) =>
              selectedWorkflow && selectedWorkflow.id === workflow.id
                ? theme.palette.mode === 'dark'
                  ? 'common.lightblue'
                  : 'common.greyblueDarker'
                : 'common.lightblue',
          },
        }}
        fullWidth
        onClick={() => handleClick(workflow)}
        onMouseEnter={handleHover}
        onMouseLeave={handleClose}
      >
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          <Typography
            variant="button"
            sx={{ fontSize: 12, color: 'common.greyblueDark' }}
          >
            {workflow.processSteps.length}
          </Typography>
          <Box sx={styles.stepIcon}>
            <FontAwesomeIcon icon="fas fa-shoe-prints" size="11px" />
          </Box>
        </Box>
        <Tooltip title={workflow.name}>
          <Box
            component="span"
            sx={{ fontSize: 11, fontWeight: 500, textTransform: 'uppercase' }}
          >
            {workflow.name.length > 13
              ? workflow.name.slice(0, 13 - 1) + '...'
              : workflow.name}
          </Box>
        </Tooltip>
        <Box
          sx={{ display: 'flex', gap: 1, visibility: anchorEl ? 'visible' : 'hidden' }}
        >
          <Tooltip title="Apply template to folder">
            <IconButton
              sx={{
                p: 0,
                minWidth: 10,
                color: 'common.greyblueDark',
              }}
              onClick={(e) => {
                e.stopPropagation();
                applyTemplateToFolder(workflow.id);
              }}
            >
              <FontAwesomeIcon icon="fas fa-save" size={14} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete template">
            <IconButton
              sx={{ p: 0, minWidth: 10, color: 'common.negative' }}
              onClick={(e) => {
                e.stopPropagation();
                handleModal();
              }}
            >
              <FontAwesomeIcon icon="far fa-trash-alt" size={14} />
            </IconButton>
          </Tooltip>
        </Box>
      </Button>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        disableRestoreFocus
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ elevation: 1, sx: styles.paper }}
        sx={{ pointerEvents: 'none' }}
      >
        {workflow.processSteps.map((step) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }} key={step.stepOrder}>
            <Box sx={styles.stepButton} onClick={handleClose}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  marginBottom: '4px',
                  alignItems: 'center',
                }}
              >
                <Typography sx={styles.text}>{step.stepOrder + 1}</Typography>
                <Typography sx={styles.text}>{step.__typename.slice(0, -4)}</Typography>
              </Box>
              <Box>
                <FontAwesomeIcon
                  icon="fal fa-check-circle"
                  color={step.required ? 'common.positive' : '#ffffff'}
                  size={14}
                  padding={0}
                />
              </Box>
            </Box>
            <FontAwesomeIcon icon="fa-solid fa-caret-down" color="#79909F" />
          </Box>
        ))}
      </Popover>
      <Modal
        open={deleteModalIsOpen}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Alert
          icon={false}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            paddingBottom: 2,
            justifyContent: 'center',
            '& .MuiAlert-message': {
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              paddingBottom: 0,
            },
          }}
          severity="error"
        >
          <Box>
            <AlertTitle
              sx={{
                marginBottom: 2,
                fontWeight: 500,
                fontSize: 20,
                color: 'common.negative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <FontAwesomeIcon icon="far fa-exclamation-circle" />
              Are you sure?
            </AlertTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="button" sx={{ fontSize: 11 }}>
                {`The following template will be deleted:`}
              </Typography>
              <Typography variant="button">{`"${workflow.name}"`}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', margin: 'auto', gap: 1 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                margin: 0,
                backgroundColor: 'common.greyblueDark',
                color: 'common.white',
                '&:hover': {
                  backgroundColor: 'common.greyblueDarker',
                },
              }}
              onClick={handleModal}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              color="inherit"
              size="small"
              sx={{
                margin: 0,
                backgroundColor: 'common.negativeDark',
                color: 'common.white',
                '&:hover': {
                  backgroundColor: 'common.negative',
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                deleteWorkflow(workflow.id);
              }}
              variant="outlined"
            >
              OK
            </Button>
          </Box>
        </Alert>
      </Modal>
    </>
  );
};

export default TemplateButton;
