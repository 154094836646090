import { Box, Typography, Button, Stack } from '@mui/material';
import DrawerContainerPermissions from './DrawerContainerPermissions';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Permissions, SubmitEvent, UserPermissionsProps } from './permissionsTypes';
import { UPDATE_USER } from '../../../../../utils/gql/gqlUsers';
import { useMutation, useQuery } from 'react-apollo';
import { keyframes } from '@mui/system';
import GeneralSnackbar, {
  HandleClose,
  StatusProps,
} from '../../../../../components/SnackBar';
import {
  generateInitialPermissions,
  initial,
  initialPermissions as genericPermissions,
} from './structure';
import PermissionsGroup from './PermissionsGroup';
import {
  ADD_PERMISSION_TEMPLATE,
  GET_PERMISSION_TEMPLATES,
} from '../../../../../utils/gql/gqlPermissions';
import { GET_FOLDERS, GET_FOLDERS_BY_ORG } from '../../../../../utils/gql/gqlFolders';
import { useLocalStorage } from '../../../../../hooks/useLocalStorage';
import { Context as StatusContext } from '../../../../../context/statusContext';
import {
  Context as AuthContext,
  AuthContextTypes,
} from '../../../../../context/authContext';
import { StatusContextTypes } from '../../../../../context/statusContextTypes';
import _ from 'lodash';

function deleteTypename(obj: any, match: string) {
  if (obj === null || obj === undefined) return;
  obj[match] && delete obj[match];
  for (let v of Object.values(obj)) {
    if (typeof v === 'object') deleteTypename(v, match);
  }
  return obj;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const generateSubmitButton = (
  title: string,
  isLoading: boolean,
  id: string,
  type: 'button' | 'submit' | 'reset' | undefined = 'button',
  handleOnClick: () => void = () => {}
) => {
  return (
    <Button
      type={type}
      size="small"
      id={id}
      onClick={handleOnClick}
      sx={{
        color: 'common.greyblueDarker',
        backgroundColor: 'common.white',
        fontSize: 11,
        height: 23,
        p: '0 10px',
        '&:hover': {
          backgroundColor: 'common.lightblue',
        },
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            border: '4px solid #79909f',
            borderLeftColor: 'transparent',
            borderRadius: '50%',
            width: '7px',
            height: '7px',
            animation: `${spin} 1s linear infinite`,
            m: 0.5,
          }}
        />
      ) : (
        `${title}`
      )}
    </Button>
  );
};
const UserPermissions = ({
  user = null,
  setUserData = () => {},
  updatePermissionsValues = () => {},
  isCreateUserModal = false,
}: UserPermissionsProps) => {
  const [isLoading, setIsLoading] = useState<{ save: boolean; template: boolean }>({
    save: false,
    template: false,
  });
  const [templateName, setTemplateName] = useState<string>('');
  const [assignToUser, setAssignToUser] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [permissionsTemplates, setPermissionsTemplates] = useState<Permissions[]>([]);

  const { state } = useContext(StatusContext) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const [addEditPermissionTemplate] = useMutation(ADD_PERMISSION_TEMPLATE, {
    refetchQueries: [{ query: GET_PERMISSION_TEMPLATES }, 'permissionTemplates'],
  });
  const [userHasTemplate, setUserHasTemplate] = useState<Permissions | false>(
    user && user.permissions && user.permissions?.templateName !== ''
      ? user.permissions
      : false
  );
  const [snackbar, setSnackbar] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'success',
  });
  const { data } = useQuery(isSuser ? GET_FOLDERS_BY_ORG : GET_FOLDERS, {
    variables: isSuser
      ? {
          organisationId:
            state.selectedOrg && _.isObject(state.selectedOrg)
              ? state.selectedOrg.id
              : selOrg.id,
        }
      : null,
  });

  let initialPermissions: Permissions = genericPermissions;

  if (data) {
    initialPermissions =
      generateInitialPermissions(isSuser ? data.FoldersByOrg : data.Folders) ||
      genericPermissions;
  }

  const [globalState, setGlobalState] = useState<Permissions>(
    (user && user.permissions) || initialPermissions || genericPermissions
  );

  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({
      status: false,
      snackbarMessage: '',
      severity: snackbar.severity,
    });
  };

  const updateUser = (variables: any) => {
    return updateUserMutation({
      variables,
    });
  };

  const onUserUpdate = async (variables: any, isTemplate: boolean = false) => {
    
    let folders = variables.permissions.folderAccessDetails.filter(
      (folder: any) => folder.folderID !== null
    );
    variables.permissions = { ...variables.permissions, folderAccessDetails: folders };

    updatePermissionsValues(variables.permissions);
    if (user && user.id) {
      updateUser(variables)
        .then(({ data }) => {
          // window.log('Updated user....', data);
          setSnackbar({
            status: true,
            snackbarMessage: 'User updated successfully',
            severity: 'success',
          });
          isTemplate
            ? setUserHasTemplate(variables.permissions)
            : setUserHasTemplate(false);

          setGlobalState(variables.permissions);
          setUserData(data.updateUser.user);
        })
        .catch((e) => {
          setSnackbar({
            status: true,
            snackbarMessage: 'Error updating user',
            severity: 'error',
          });
        })
        .finally(() => setIsLoading({ save: false, template: false }));
    } else {
      setSnackbar({
        status: true,
        snackbarMessage: 'Permissions saved successfully ',
        severity: 'success',
      });
    }
  };

  const onAddTemplate = async (variables: Permissions) => {
    addEditPermissionTemplate({
      variables: {
        permissions: variables,
      },
    })
      .then(({ data }) => {
        let templateAdded = data.addEditPermissionTemplate.permissionsTemplate;
        setSnackbar({
          status: true,
          snackbarMessage: 'Template added successfully',
          severity: 'success',
        });
        setPermissionsTemplates([...permissionsTemplates, templateAdded]);
        setTemplateName('');
      })
      .catch((error) => {
        setSnackbar({
          status: true,
          snackbarMessage: 'Template can not added successfully',
          severity: 'error',
        });
      })
      .finally(() => setIsLoading({ save: false, template: false }));
  };

  const onRemoveTemplateToUser = (e: SyntheticEvent) => {
    setTemplateName('');
    setGlobalState(initialPermissions);
    if (user) setUserData({ ...user, permissions: initialPermissions });
    handleSubmit(e, 'save', initialPermissions);
    updatePermissionsValues(initialPermissions);
  };

  const handleSubmit = (
    e: SyntheticEvent,
    id?: string,
    permissions: Permissions = globalState
  ) => {
    e.preventDefault();

    let nativeEvent = e.nativeEvent as SubmitEvent;
    let { submitter } = nativeEvent;
    if (!id) id = submitter.id;

    // when values are received from DB, they arrive with the key '__typename'. this key must be deleted
    const values = deleteTypename(permissions, '__typename');
    if (values.hasOwnProperty('id')) delete values['id']; // remove permission ID
    Object.keys(values).forEach((k) => k === '' && delete values[k]); // remove empty keys
    let variables = {
      ...user,
      permissions: { ...values, templateName },
    };

    if (id === 'save') {
      onUserUpdate(variables);
    } else if (id === 'template') {
      onAddTemplate(variables.permissions);
      if (assignToUser) onUserUpdate(variables, true);
    }

    if (user && user.id) setIsLoading({ ...isLoading, [id]: true });
    handleCloseModal();
  };

  const handleChangeTemplateName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setTemplateName(e.target.value);

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModal = () => setOpenModal(true);

  useEffect(() => {
    updatePermissionsValues(globalState);
  }, [globalState]);

  return (
    <>
      <Box
        sx={{
          height: '100%',

          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          component="form"
          onSubmit={(e) => handleSubmit(e)}
          sx={{
            height: { xs: '60%', sm: '60%', md: '70%' },
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'common.greyblueDarker',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderInlineStart: '3px solid #cea323',
              boxShadow: { sm: '0px 1px 0px 0px #ffffff96 inset', md: 'none' },
              padding: '5px 15px',
              flex: 1.2,
            }}
          >
            <Typography
              variant="caption"
              component={'span'}
              sx={{ display: 'block' }}
              color="WHITE"
            >
              TOP LEVEL PERMISSIONS
            </Typography>
            {!isCreateUserModal && (
              <Stack gap={2} flexDirection="row">
                {generateSubmitButton('Save', isLoading.save, 'save', 'submit')}
              </Stack>
            )}
          </Box>
          <DrawerContainerPermissions
            initial={initial}
            initialSchema={initial[0].schema}
            setGlobalState={setGlobalState}
            globalState={globalState}
          />
          <GeneralSnackbar
            status={snackbar}
            handleClose={handleCloseSnackbar}
          ></GeneralSnackbar>
        </Box>
        <PermissionsGroup
          user={user}
          generateSubmitButton={generateSubmitButton}
          isLoading={isLoading}
          setSnackbar={setSnackbar}
          handleChangeTemplateName={handleChangeTemplateName}
          setAssignToUser={setAssignToUser}
          templateName={templateName}
          handleSubmit={handleSubmit}
          setPermissionsTemplates={setPermissionsTemplates}
          permissionsTemplates={permissionsTemplates}
          setUserHasTemplate={setUserHasTemplate}
          userHasTemplate={userHasTemplate}
          onRemoveTemplateToUser={onRemoveTemplateToUser}
          setGlobalState={setGlobalState}
          setUserData={setUserData}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          openModal={openModal}
          assignToUser={assignToUser}
          updatePermissionsValues={updatePermissionsValues}
          isCreateUserModal={isCreateUserModal}
        />
      </Box>
    </>
  );
};

export default UserPermissions;
