import { gql } from 'apollo-boost';

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(data: { email: $email, password: $password }) {
      user {
        firstName
        lastName
        role
      }
      error
      superUser {
        firstName
        lastName
        organisations {
          id
        }
      }
    }
  }
`;
export const GET_USERS = gql`
  query {
    Users {
      users {
        id
        firstName
        lastName
        role
        createdAt
        updatedAt
        email
        active
      }
    }
  }
`;
export const GET_USERS_BY_ORG = gql`
  query UsersByOrg($organisationId: ID!) {
    UsersByOrg(organisationId: $organisationId) {
      users {
        id
        firstName
        lastName
        role
        createdAt
        updatedAt
        email
        active
      }
    }
  }
`;
export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $title: String
    $password: String!
    $role: Role!
  ) {
    createUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        title: $title
        password: $password
        role: $role
      }
    ) {
      user {
        id
      }
      error
    }
  }
`;
export const GET_ORG = gql`
  query {
    Organisation {
      organisation {
        name
        processPathEnabled
      }
      error
    }
  }
`;

export const GET_FOLDERS_AND_USERS = gql`
  query {
    FoldersAndUsers {
      users {
        firstName
        lastName
        id
      }
      folders {
        name
        id
        folderFields {
          name
          type
          id
          options {
            key
            value
          }
        }
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation logoutUser {
    logoutUser {
      user {
        id
      }
    }
  }
`;

export const GET_RECORDS_BY_FOLDER = (id: string) => {
  return gql`
      query {
        RecordsByFolder(id: "${id}") {
          id
          createdAt
          updatedAt
          createdBy {
            firstName
            lastName
          }
          assignTo {
            firstName
            lastName
          }
        }
      }
    `;
};

export const CREATE_RECORD = gql`
  mutation createRecord(
    $name: String!
    $folderId: ID!
    $storageType: String!
    $assignTo: ID
    $folderTags: String
  ) {
    createRecord(
      data: {
        #name: $name
        folderId: $folderId
        storageType: $storageType
        assignTo: $assignTo
        folderTags: $folderTags
      }
    ) {
      id
    }
  }
`;

/*statistics for records*/
export const GET_RECORDS_STATS = gql`
  query {
    RecordsStats {
      folder {
        name
      }
      count
    }
  }
`;

export const GET_ERRORLOGS = gql`
  query getLogs($org: ID!) {
    getLogs(org: $org) {
      logs {
        message
        errorInfo
        reportedBy {
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      error
    }
  }
`;
export const PUSH_ERRORLOGS = gql`
  mutation sendErrorLog($message: String, $errorInfo: String!) {
    sendErrorLog(data: { message: $message, errorInfo: $errorInfo }) {
      log {
        message
        errorInfo
      }
      error
    }
  }
`;
