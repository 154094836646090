import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { User } from '../../../components/masterTable/masterTableTypes';
import StepsContainer from './StepsContainer';
import { Context as StatusContext } from '../../../context/statusContext';
import { Context as AuthContext, AuthContextTypes } from '../../../context/authContext';
import { useQuery } from 'react-apollo';
import { GET_USERS, GET_USERS_BY_ORG } from '../../../utils/gql/gqlUsers';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useWorkflow } from '../useWorkflow';
import { Step, Workflow } from '../types';
import FontAwesomeIcon from '../../../components/FAIcon';
import { StatusContextTypes } from '../../../context/statusContextTypes';
import NotesCard from '../../../components/Notes/NotesCard';
import { Note } from '../../../components/Notes/notesTypes';
import _ from 'lodash';

interface WorkflowTabProps {
  currentProcessStep: Step;
  recordPage?: boolean;
}

const classes = {
  toggleButton: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
    backgroundColor: '#fda808',
    height: 15,
    width: '50%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    top: 0,
    borderRadius: '0px 0px 10px 10px',
    '&:hover': {
      backgroundColor: '#0B72B9',
    },
  },
  scrollbar: {
    '&::-webkit-scrollbar': { width: '10px' },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#79909f',
      borderRadius: '4px',
    },
  },
  notesContainer: {
    padding: '10px',
    height: 'auto',
    minHeight: '220px',
    maxWidth: '450px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    marginTop: '18px',
  },
};

const WorkflowTab = ({ currentProcessStep, recordPage }: WorkflowTabProps) => {
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;
  const [users, setUsers] = useState<User[]>([]);
  const refCardsContainer = useRef<null | HTMLAnchorElement>(null);
  const [notesDrawer, setNotesDrawer] = useState<Note | null>(null);
  const { selectedWorkflow, setSelectedWorkflow, handleRemoveStep, handleUpdateStep } =
    useWorkflow(refCardsContainer);
  const [expanded, setExpanded] = useState<string | false>('currentWorkflow');

  useQuery(isSuser ? GET_USERS_BY_ORG : GET_USERS, {
    variables: isSuser
      ? {
          organisationId:
            state.selectedOrg && _.isObject(state.selectedOrg)
              ? state.selectedOrg.id
              : selOrg.id,
        }
      : null,
    onCompleted: (data) => setUsers(isSuser ? data.UsersByOrg.users : data.Users.users),
    fetchPolicy: 'cache-first',
  });

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const toggleNotesDrawer = (note: Note | null) => {
    setNotesDrawer(
      !note ? null : notesDrawer && notesDrawer.id === note.id ? null : note
    );
  };

  useEffect(() => {
    setSelectedWorkflow(
      (_.isObject(state.selectedRecord) && state?.selectedRecord?.processPath) || null
    );
  }, [state.selectedRecord]);

  return (
    <>
      <Box sx={{ padding: '25px' }}>
        {' '}
        <Accordion
          expanded={expanded === 'currentWorkflow'}
          onChange={handleChange('currentWorkflow')}
          id="steps-list"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'black.light' : undefined,
          }}
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="15px" />}
            aria-controls="steps-list-header"
            id="steps-list-header"
          >
            <Typography
              variant="button"
              sx={{
                textAlign: 'left',
                display: 'block',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblueDark',
              }}
            >
              {selectedWorkflow && selectedWorkflow.name
                ? selectedWorkflow.name
                : 'Steps list'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxHeight: '600px',
              overflowY: 'scroll',
              ...classes.scrollbar,
              ...classes.scrollbar,
            }}
            id="steps-list-content"
          >
            <StepsContainer
              selectedWorkflow={selectedWorkflow}
              users={users}
              refCardsContainer={refCardsContainer}
              handleRemoveStep={handleRemoveStep}
              handleUpdateStep={handleUpdateStep}
              currentProcessStep={currentProcessStep}
              recordPage={recordPage}
              toggleNotesDrawer={toggleNotesDrawer}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === `workflowHistory`}
          onChange={handleChange(`workflowHistory`)}
          disabled={
            _.isObject(state.selectedRecord) &&
            state.selectedRecord.processPath_Archive.length === 0
          }
          id="workflow-history"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'black.light' : undefined,
          }}
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon="fas fa-chevron-down" size="15px" />}
            aria-controls="workflow-history-header"
            id="workflow-history-header"
          >
            <Typography
              variant="button"
              sx={{
                textAlign: 'left',
                display: 'block',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.greyblueDark',
              }}
            >
              Workflow History
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ maxHeight: '600px', overflowY: 'scroll', ...classes.scrollbar }}
            id="workflow-history-content"
          >
            {state.selectedRecord &&
              _.isObject(state.selectedRecord) &&
              state.selectedRecord.processPath_Archive.map((processPath: Workflow) => {
                return (
                  <Fragment key={processPath.id}>
                    <Box sx={{ padding: '15px 0px' }}>
                      <StepsContainer
                        selectedWorkflow={processPath}
                        users={users}
                        refCardsContainer={refCardsContainer}
                        handleRemoveStep={handleRemoveStep}
                        handleUpdateStep={handleUpdateStep}
                        currentProcessStep={currentProcessStep}
                        recordPage={recordPage}
                      />
                    </Box>
                    <Divider />
                  </Fragment>
                );
              })}
          </AccordionDetails>
        </Accordion>
      </Box>
      {notesDrawer && (
        <Drawer
          id="action-drawer"
          variant="persistent"
          anchor={'bottom'}
          open={notesDrawer ? true : false}
          sx={{
            '& .MuiDrawer-paper': {
              position: 'absolute',
              zIndex: recordPage ? 0 : 1200,
            },
          }}
        >
          <Box sx={classes.notesContainer}>
            <NotesCard
              note={notesDrawer}
              allowManualExpand={false}
              actionButtonsText={false}
              isExpanded={true}
              allowShowActions={false}
            />
          </Box>
          <Tooltip title="Hide step action" arrow>
            <Box sx={classes.toggleButton} onClick={() => setNotesDrawer(null)}>
              <FontAwesomeIcon icon="fas fa-caret-down" size={'15px'} />
            </Box>
          </Tooltip>
        </Drawer>
      )}
    </>
  );
};

export default WorkflowTab;
