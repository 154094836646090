import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Grid,
  FormControl,
  MenuItem,
  Tooltip,
  TextField,
  FormControlLabel,
  IconButton,
  Stack,
  FormHelperText,
} from '@mui/material';

import { useMutation } from '@apollo/react-hooks';
import { ADD_FOLDER_FIELD } from '../../utils/gql/gqlFolders';

import ModalWrapper from '../../components/modal/ModalWrapper';
import ModalContentBase from '../../components/modal/ModalContentBase';
import FontAwesomeIcon from '../../components/FAIcon';
import FolderFields from './folderFields/FolderFields';
import {
  FieldCreationFormInputs,
  FieldCreationFormProps,
  FieldOptionsProps,
  Options,
} from './types';
import { StyledPrimaryButton } from '../../components/form/StyledButtons';
import { SecondStyledTexfield } from '../../components/form/StyledTextfield';
import StyledSwitch from '../../components/form/StyledSwitch';

interface Variables {
  fieldName: string;
  dataType: any;
  folderId: string;
  indexRequirements: {
    isMandatory: boolean | undefined;
    regexMatch: any;
  };
  options?: { key: string; value: any }[];
}

const classes = {
  creationHeader: {
    marginBottom: 2,
    alignItems: 'center',
  },
  formControl: {
    marginTop: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    fontFamily: 'typography.fontFamily',
    color: 'text.primary',
    backgroundColor: 'background.paper',
    outline: 'none',
    maxWidth: '600px',
    boxShadow: 'shadows[5]',
    padding: 'spacing(2, 4, 3)',
  },
  optionsWrapper: {
    maxHeight: '300px',
    overflow: 'auto',
    // Give the scrollbar some visibility
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#efefef',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bdbcbc',
      borderRadius: '10px',
    },
  },
  switch: {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: 'common.greyblueDarker',
        '& + .MuiSwitch-track': {
          backgroundColor: 'common.greyblue',
        },
      },
    },
  },
};

export const FieldOptions = ({ options = [], onDelete, onAddNew }: FieldOptionsProps) => {
  const optionsEndRef = useRef<HTMLDivElement>(null);
  const [newOption, setNewOption] = useState<string>('');

  const scrollToBottom = () => {
    optionsEndRef.current && optionsEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [options]);

  return (
    <>
      <div style={classes.optionsWrapper}>
        {options.map((option) => (
          <Stack key={option.key} direction="row" sx={{ padding: '10px' }}>
            <TextField
              type="text"
              disabled
              name={option.key}
              value={option.value || ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Tooltip title="Delete" onClick={() => onDelete(option)}>
              <IconButton aria-label="delete" sx={{ width: '50px', marginLeft: '10px' }}>
                <FontAwesomeIcon size="16px" icon="fas fa-trash" />
              </IconButton>
            </Tooltip>
          </Stack>
        ))}
        <div ref={optionsEndRef}></div>
      </div>
      <Stack direction="row" sx={{ padding: '10px' }}>
        <TextField
          type="text"
          placeholder="ex. Option 1"
          value={newOption || ''}
          onChange={(e) => {
            const value = e.target.value;
            setNewOption(value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Tooltip title="Add">
          <IconButton
            aria-label="add"
            sx={{ width: '50px', marginLeft: '10px' }}
            disabled={newOption.trim() === ''}
            onClick={() => {
              onAddNew(newOption);
              setNewOption('');
            }}
          >
            <FontAwesomeIcon size="16px" icon="fas fa-plus" />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
};

export default function FieldCreationForm({
  folderDetail,
  refetch,
  fields,
  updateField,
  setSelectedFieldId,
  setSelectedField,
  selectedField,
  fieldUpdates,
  setFieldUpdates,
  selectedFieldId,
  updateFolder,
  setFields,
}: FieldCreationFormProps) {
  const [dataType, setDataType] = useState('None');
  const dataTypes = [
    { name: 'None', value: '' },
    { name: 'Boolean', value: 'boolean' },
    { name: 'Currency', value: 'currency' },
    { name: 'DateTime', value: 'datetime' },
    { name: 'Integer', value: 'integer' },
    { name: 'Text', value: 'string' },
    { name: 'Drop Down List', value: 'dropdown' },
    { name: 'Multi string', value: 'multistring' },
  ];
  const defaultValues = {
    fieldName: '',
    dataType: '',
  };
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FieldCreationFormInputs>({ defaultValues });
  const [dropdownModalOpen, setDropdownModalOpen] = useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState<Options[]>([]);

  const [createFolderField] = useMutation(ADD_FOLDER_FIELD);

  const onSubmit: SubmitHandler<FieldCreationFormInputs> = (values, e) => {
    if (values && values.dataType === 'dropdown' && dropdownOptions.length === 0) {
      setDropdownModalOpen(true);
    } else {
      const variables: Variables = {
        fieldName: values.fieldName,
        dataType: values.dataType,
        folderId: folderDetail.id,
        indexRequirements: {
          isMandatory: values.isMandatory,
          regexMatch: values.regexMatch,
        },
      };

      if (values.dataType === 'dropdown') {
        variables.options = dropdownOptions.map((option) => ({
          key: option.key,
          value: option.value,
        }));
      }

      createFolderField({ variables })
        .then((data) => {
          setDropdownOptions([]);
          setDropdownModalOpen(false);
          setDataType('None');
          reset(defaultValues);
          setFields((prev) => [...prev, data.data.createFolderField.folderField]);
          // refetch();
        })
        .catch((e) => {
          // window.log('Failed to create folder field: ', e);
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalWrapper isOpen={dropdownModalOpen} setIsOpen={setDropdownModalOpen}>
          <ModalContentBase
            title="ADD DROP DOWN OPTIONS"
            subtitle="Use the options below to define your drop down list items."
            onCancel={() => {
              setDropdownOptions([]);
              setDropdownModalOpen(false);
              reset();
            }}
            onSubmit={() => {
              handleSubmit(onSubmit)();
            }}
          >
            <FieldOptions
              options={dropdownOptions}
              onDelete={(option) => {
                const updatedOptions = dropdownOptions.filter(
                  (item) => item.key !== option.key
                );
                setDropdownOptions([...updatedOptions]);
              }}
              onAddNew={(newOption) => {
                setDropdownOptions([
                  ...dropdownOptions,
                  {
                    key: newOption.split(' ').join('-').toLowerCase(),
                    value: newOption,
                  },
                ]);
              }}
            />
          </ModalContentBase>
        </ModalWrapper>
        <Grid item container spacing={{ xs: 4, lg: 2 }} sx={classes.creationHeader}>
          <Grid item xs={12} lg={4}>
            <SecondStyledTexfield
              {...register('fieldName', {
                required: true,
                pattern: /^([a-zA-Z0-9][^*/><?|:]*)$/,
                validate: {
                  duplicatedName: () =>
                    fields &&
                    fields.every((field) => field.name !== getValues().fieldName),
                },
              })}
              fullWidth
              label="Field Name"
              name="fieldName"
              placeholder="Write a name here"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors?.fieldName?.type === 'duplicatedName' && (
              <FormHelperText
                error={true}
                sx={{ position: 'absolute', top: '40', paddingLeft: '12px' }}
              >
                Fields with duplicated names are not allowed
              </FormHelperText>
            )}
            {errors?.fieldName?.type === 'required' && (
              <FormHelperText
                error={true}
                sx={{ position: 'absolute', top: '40', paddingLeft: '12px' }}
              >
                This field is required
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <Controller
                render={({ field }) => (
                  <>
                    <SecondStyledTexfield
                      fullWidth
                      {...field}
                      select
                      value={dataType}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setDataType(e.target.value);
                      }}
                      label="Data Type"
                    >
                      {dataTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value === '' ? <em>{option.name}</em> : option.name}
                        </MenuItem>
                      ))}
                    </SecondStyledTexfield>
                  </>
                )}
                name="dataType"
                control={control}
                defaultValue={defaultValues.dataType}
                rules={{ required: true }}
              />
              {errors.dataType && (
                <FormHelperText error={true} sx={{ position: 'absolute', top: '100%' }}>
                  A data type is required
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SecondStyledTexfield
              fullWidth
              {...register('regexMatch')}
              label="Regex Validation"
              name="regexMatch"
              type="text"
              placeholder="Write a regex here"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <FormControl fullWidth sx={classes.formControl}>
                <FormControlLabel
                  control={
                    <StyledSwitch {...register('isMandatory')} name="isMandatory" />
                  }
                  label="Mandatory"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <StyledPrimaryButton
                type="submit"
                variant="contained"
                aria-label="add"
                endIcon={
                  <FontAwesomeIcon
                    icon="fa-arrow-circle-right"
                    size={25}
                    padding={'1px'}
                  />
                }
              >
                Add field
              </StyledPrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid
        container
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <FolderFields
          fields={fields}
          updateField={updateField}
          setSelectedFieldId={setSelectedFieldId}
          fieldUpdates={fieldUpdates}
          setFieldUpdates={setFieldUpdates}
          selectedFieldId={selectedFieldId}
          folder={folderDetail}
          updateFolder={updateFolder}
          refetch={refetch}
          setSelectedField={setSelectedField}
          selectedField={selectedField}
        />
      </Grid>
    </>
  );
}
