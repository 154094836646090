import * as _ from 'lodash';
import { Dispatch } from 'react';
import { db } from '../utils/indexedDB/indexeddb';
import utils from '../utils/utils';
import {
  RTCContextPayload,
  RTCContextReducerAction,
  RTCContextState,
} from './RTCContextTypes';
import { TaskContent } from './tasksContextTypes';

const RTCReducer = (state: RTCContextState, action: RTCContextReducerAction) => {
  let rtcStatus = state.rtcStatus;
  let notifications = state.notifications;
  let refetch = state.refetch;
  let socketEvents = state.socketEvents;
  let RTC = state.RTC;
  switch (action.type) {
    case 'setStatus':
      rtcStatus = action.payload.state ? 'positive' : 'negative';
      window.log(rtcStatus);
      return {
        ...state,
        notifications,
        rtcStatus,
        refetch,
      };
    case 'addTaskNotification':
      let newNotification: any;
      const task = action.payload as TaskContent;
      if (task.progress >= 100) {
        let code = 'T001';
        newNotification = {
          date: Date.now(),
          message: utils.getTaskNotificationDetails(task),
          code: code,
          screen: utils.determineScreenByCode(code) as string,
          action: task.action,
          type: utils.determineTypeByCode(code),
          readed: false,
          first: true,
          onlyPopup: task.onlyPopup ? task.onlyPopup : false,
          taskId: task.id,
        };

        if (task.onlyPopup) {
          let exist = notifications.tasksPopup.find((noti) => noti.taskId === task.id);
          if (!exist) {
            notifications = {
              ...notifications,
              tasksPopup: [...notifications.tasksPopup, newNotification],
            };
          }
        } else {
          db.transaction(
            'rw',
            db.table('taskNotifications'),
            db.table('tasks'),
            async () => {
              if (!task.onlyPopup) {
                notifications.tasksPopup = [...notifications.tasksPopup, newNotification];
                notifications.tasks = [...notifications.tasks, newNotification];
                db.table('taskNotifications')
                  .add(newNotification)
                  .catch((e) =>
                    console.log('Error adding new notification to indexedDB', e)
                  );
              }
            }
          );
        }
        refetch = state.refetch + 1;
      }

      return {
        ...state,
        notifications,
        rtcStatus,
        refetch,
      };
    case 'setSocketEvents':
      return { ...state, socketEvents: [...socketEvents, action.payload] };
    case 'clearConsole':
      return { ...state, socketEvents: [] };
    case 'markNotificationAsNotFirstTime':
      // When popup is unmounted, we modify the key 'first' to false and when the user reload the app,
      // it doesn't render a popup again, just appear in notification tray.
      if (action.payload) {
        db.transaction('rw', db.table('taskNotifications'), async () => {
          db.table('taskNotifications')
            .where('_id')
            .equals(action.payload)
            .modify({ first: false })
            .then((res) => window.log('res', res))
            // .then(() => RTC.clearNotification([action.payload])) // if notification exists in indexedDB, then we remove it from DB
            .catch((error) => window.log('error', error));
        });
        let newNotifications = notifications.tasksPopup.map((noti) =>
          noti.taskId === action.payload ? { ...noti, first: false } : noti
        );
        // let newCommNotifications = notifications.comm.map((noti) =>
        //   noti._id === action.payload ? { ...noti, first: false } : noti
        // );

        notifications.tasksPopup = newNotifications;
        // notifications.comm = newCommNotifications;
      }
      break;
    case 'addTaskNotificationAsync':
      if (_.isArray(action.payload)) {
        action.payload.forEach((noti) => {
          let exist = state.notifications.comm.find((notif) => notif._id === noti._id);
          if (exist) return;
          if (noti.sendLater) {
            let sendLater = new Date(noti.sendLater).getTime();
            if (sendLater <= Date.now()) {
              db.table('taskNotifications')
                .where('id')
                .equals(noti.id)
                .modify({ sendLater: null });
              noti.sendLater = null;
            }
          }
          if (!noti.sendLater) {
            notifications.comm = [...notifications.comm, noti];
          }
        });
      }
      break;
    case 'addNotification':
      let { newNoti, setInIndexedDB } = action.payload;

      const noti = {
        ...newNoti,
        read: false,
        first: true,
        notificationType:
          newNoti.type === 'ApprovalRequired' || newNoti.type === 'ApprovalPerformed'
            ? 'popup'
            : newNoti.notificationType,
      };
      notifications.comm = [...notifications.comm, noti];
      break;
    case 'addTask':
      RTC.addTask(action.payload);

      // db.table('taskNotifications')
      //   .toArray()
      //   .then((res) => {
      //     let existInIndexedDB = res.find((not) => not._id === newNoti._id);
      //     let existInState = notifications.comm.find((not) => not._id === newNoti._id);
      //     if (existInIndexedDB && !existInState) {
      //       return (notifications.comm = [...notifications.comm, newNoti]);
      //     }
      //
      //     newNoti = {
      //       ...newNoti,
      //       read: false,
      //       first: true,
      //       notificationType:
      //         newNoti.type === 'ApprovalRequired' || newNoti.type === 'ApprovalPerformed'
      //           ? 'popup'
      //           : newNoti.notificationType,
      //     };
      //
      //     if (setInIndexedDB && !existInIndexedDB) {
      //       db.transaction('rw', db.table('taskNotifications'), async () => {
      //         await db.table('taskNotifications')
      //           .add(newNoti)
      //           .then()
      //           .catch((e) => window.log(e));
      //       });
      //     }
      //
      //     let sendLater = newNoti.sendLater
      //       ? new Date(newNoti.sendLater).getTime()
      //       : false;
      //     if (sendLater) return state;
      //     return existInState || existInIndexedDB
      //       ? state
      //       : (notifications.comm = [...notifications.comm, newNoti]);
      //   });
      break;
    case 'removeTaskNotification':
      db.transaction('rw', 'taskNotifications', async () => {
        await RTC.clearNotification([action.payload]);
        await db
          .table('taskNotifications')
          .where('_id')
          .equals(action.payload)
          .delete()
          .then((res) => console.log('notification deleted successfully', res))
          .catch((error) => console.log('error deleting notification', error));
      }).catch((err) => window.log('remove notification error', err));

      notifications.tasks = notifications.tasks.filter((el) => el._id !== action.payload);
      notifications.comm = notifications.comm.filter((el) => el._id !== action.payload);
      break;
    case 'removeAllTaskNotification':
      RTC.clearAllNotifications();
      db.table('taskNotifications').clear();
      notifications.comm = [];
      break;
    case 'markAllAsRead':
      db.table('taskNotifications').toCollection().modify({ read: true });
      notifications.comm = notifications.comm.map((task) => {
        RTC.clearNotification([task._id]);
        return { ...task, read: true };
      });
      break;
    case 'markOneAsRead':
      if (action.payload) {
        db.table('taskNotifications')
          .where('_id')
          .equals(action.payload)
          .modify({ read: true });
        notifications.comm = notifications.comm.map((task) => {
          let newTask = task;
          if (task._id === action.payload) {
            newTask.read = true;
            RTC.clearNotification([task._id]);
          }
          return { ...newTask };
        });
      }
      break;
    case 'maintenanceMode':
      if (action.payload === null) return state;
      try {
        action.payload = JSON.parse(action.payload);
      } catch (error) {}
      let maintenanceMode =
        action.payload === false || Object.keys(action.payload).length === 0
          ? false
          : action.payload;
      if (maintenanceMode || Object.keys(maintenanceMode).length !== 0) {
        let endTimeStamp = new Date(maintenanceMode.endDate).getTime();
        let startTimeStamp = new Date(maintenanceMode.startDate).getTime();
        if (endTimeStamp > Date.now()) {
          db.table('maintenance').put(maintenanceMode);
          notifications.comm = [
            {
              ...maintenanceMode,
              message:
                startTimeStamp > Date.now()
                  ? 'Data Capture will go into maintenance mode on'
                  : maintenanceMode.message,
              type: startTimeStamp > Date.now() ? 'maintenance-schedule' : 'maintenance',
              notificationType: 'toolbar',
            },
          ];
        }
      } else {
        db.table('maintenance').clear();
        let maintenance = notifications.comm.find((noti) => noti.type === 'maintenance');
        let filtered = notifications.comm.filter(
          (noti) => noti.type !== 'maintenance' && noti.type !== 'maintenance-schedule'
        );
        notifications.comm = filtered;
        if (maintenance && maintenance.restart) window.location.reload();
      }
      break;
    case 'setMaintenanceMode':
      if (RTC) {
        RTC.setMaintenanceMode(action.payload);
      }
      break;
    case 'setSettingsSaved':
      return {
        ...state,
        notifications,
        rtcStatus,
        refetch,
        settingsSaved: true,
      };
      break;
    case 'imgUploadSuccess':
      window.log('imgUploadSuccess', action.payload);
      RTC.imgUploadSuccessful(action.payload);
      break;
    case 'imgUploadFailed':
      RTC.imgUploadFailed(action.payload);
      break;
    case 'createNotification':
      RTC.createNotification(action.payload);
      break;
    case 'setSocketConnection':
      RTC = action.payload;
      break;
    case 'clearTasksNotifications':
      return {
        notifications: {
          tasks: [],
          comm: [],
        },
        rtcStatus,
        refetch: 0,
      };
    case 'setTextractId':
      return { ...state, textractId: action.payload };
    case 'setOcrTemplate':
      return { ...state, ocrTemplate: action.payload };
    case 'setIdentifiersResponse':
      return { ...state, ocrIdentifiersResponse: action.payload };
    case 'setAnalyzedArea':
      return { ...state, analyzedArea: action.payload };
    case 'setValidateOCRAreaResponse':
      return { ...state, validateOCRAreaResponse: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  window.log(RTC);
  return {
    ...state,
    notifications,
    rtcStatus,
    refetch,
    RTC,
  };
};
const setRTCstatus =
  (dispatch: Dispatch<RTCContextReducerAction>) => (status: RTCContextPayload) => {
    try {
      dispatch({ type: 'setStatus', payload: status });
    } catch (err) {
      window.log(err);
    }
  };
const addTaskRTC =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (task: RTCContextPayload) => {
    try {
      dispatch({ type: 'addTask', payload: task });
    } catch (err) {
      window.log(err);
    }
  };
const addTaskNotification =
  (dispatch: Dispatch<RTCContextReducerAction>) =>
  async (notification: RTCContextPayload) => {
    try {
      dispatch({ type: 'addTaskNotification', payload: notification });
    } catch (err) {
      window.log(err);
    }
  };
const addTaskNotificationAsync =
  (dispatch: Dispatch<RTCContextReducerAction>) => (notifications: RTCContextPayload) => {
    try {
      dispatch({ type: 'addTaskNotificationAsync', payload: notifications });
    } catch (err) {
      window.log(err);
    }
  };
const removeTaskNotification =
  (dispatch: Dispatch<RTCContextReducerAction>) => (id: RTCContextPayload) => {
    try {
      dispatch({ type: 'removeTaskNotification', payload: id });
    } catch (err) {
      window.log(err);
    }
  };
const removeAllTaskNotification =
  (dispatch: Dispatch<RTCContextReducerAction>) => (id: RTCContextPayload) => {
    try {
      dispatch({ type: 'removeAllTaskNotification', payload: id });
    } catch (err) {
      window.log(err);
    }
  };
const markAllAsRead =
  (dispatch: Dispatch<RTCContextReducerAction>) => (id: RTCContextPayload) => {
    try {
      dispatch({ type: 'markAllAsRead', payload: id });
    } catch (err) {
      window.log(err);
    }
  };

const markOneAsRead =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (id: RTCContextPayload) => {
    try {
      dispatch({ type: 'markOneAsRead', payload: id });
    } catch (err) {
      window.log(err);
    }
  };
const markNotificationAsNotFirstTime =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (id: RTCContextPayload) => {
    try {
      dispatch({ type: 'markNotificationAsNotFirstTime', payload: id });
    } catch (err) {
      window.log(err);
    }
  };
const setMaintenanceMode =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'setMaintenanceMode', payload: data });
    } catch (err) {
      window.log(err);
    }
  };

const clearTasksNotifications =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (id: RTCContextPayload) => {
    try {
      dispatch({ type: 'clearTasksNotifications' });
    } catch (err) {
      window.log(err);
    }
  };
const imgUploadSuccess =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'imgUploadSuccess', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const imgUploadFailed =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'imgUploadFailed', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const setTextractId =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'setTextractId', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const setOcrTemplate =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'setOcrTemplate', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const setIdentifiersResponse =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'setIdentifiersResponse', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const setAnalyzedArea =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'setAnalyzedArea', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const setValidateOCRAreaResponse =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'setValidateOCRAreaResponse', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const createNotification =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'createNotification', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const addNotification =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'addNotification', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const maintenanceMode =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      dispatch({ type: 'maintenanceMode', payload: data });
    } catch (err) {
      window.log(err);
    }
  };
const setSocketConnection =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (RTC: RTCContextPayload) => {
    try {
      dispatch({ type: 'setSocketConnection', payload: RTC });
    } catch (err) {
      window.log(err);
    }
  };
const setSettingsSaved =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (saved: RTCContextPayload) => {
    try {
      dispatch({ type: 'setSettingsSaved', payload: saved });
    } catch (err) {
      window.log(err);
    }
  };

const setSocketEvents =
  (dispatch: Dispatch<RTCContextReducerAction>) => async (data: RTCContextPayload) => {
    try {
      if ((data as RTCContextPayload['data']) === 'clearConsole') {
        dispatch({ type: 'clearConsole', payload: data });
      } else {
        dispatch({ type: 'setSocketEvents', payload: data });
      }
    } catch (err) {
      window.log(err);
    }
  };
export const { Provider, Context } = utils.createDataContext(
  RTCReducer,
  {
    setRTCstatus,
    addTaskRTC,
    addTaskNotification,
    addTaskNotificationAsync,
    removeTaskNotification,
    removeAllTaskNotification,
    markAllAsRead,
    markOneAsRead,
    markNotificationAsNotFirstTime,
    clearTasksNotifications,
    imgUploadSuccess,
    setSocketConnection,
    imgUploadFailed,
    createNotification,
    addNotification,
    setMaintenanceMode,
    maintenanceMode,
    setTextractId,
    setOcrTemplate,
    setIdentifiersResponse,
    setAnalyzedArea,
    setValidateOCRAreaResponse,
    setSocketEvents,
  },
  {
    notifications: {
      toolbar: [],
      tasksPopup: [],
      tasks: [],
      comm: [],
    },
    rtcStatus: 'negative',
    refetch: 0,
    RTC: null,
    settingsSaved: false,
    ocrTemplate: {
      template: false, // If template is false, we don't know yet if there is a template saved
    },
    socketEvents: [],
  }
);
