import { Stack, Tooltip, useTheme } from '@mui/material';
import { blueGrey, green, orange } from '@mui/material/colors';
import { MouseEvent, MouseEventHandler, useState } from 'react';
import { FolderFields } from '../../containers/folder/types';
import { useOCRTemplate } from '../../hooks/OCRTemplateSetup/useOCRTemplate';
import { useFabricContext } from '../../hooks/useFabricCanvas';
import FontAwesomeIcon from '../FAIcon';
import {
  StyledDeleteIconButton,
  StyledIconButton,
  StyledToggleAreaButton,
} from './StyledComponents';
import { FolderField } from '../masterTable/masterTableTypes';

export interface OCRSetupActionsTypes {
  canDeleteValue?: boolean;
  canDrawArea?: boolean;
  canHideArea?: boolean;
  canClear?: boolean;
  canEdit?: boolean;
  field?: FolderFields;
  inputType?: string;
  action?: string;
  identifierId?: string;
  hasAdvancedSettings: boolean;
  handleOpenSettingsModal: () => void;
  setSelectedField: React.Dispatch<React.SetStateAction<FolderField | null>>;
  valuesOnlyFields: any;
}

const OCRSetupActions = (props: OCRSetupActionsTypes) => {
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const {
    el,
    canDrawArea,
    canHideArea,
    canClear,
    canSave = false,
    inputType,
    field,
    action,
    identifierId,
    hasValue,
    onSave,
    handleRemoveFields,
    hasAdvancedSettings,
    handleOpenSettingsModal,
    setSelectedField,
  } = props;
  const {
    canDraw,
    setCanDraw,
    selectedObject,
    setSelectedObject,
    deleteObject,
    toggleObjectVisibility,
    objects,
    setInputType,
    setFolderFieldId,
    deleteObjectGroup,
    setAction,
    ocrTemplateFolderField,
    setIdentifierId,
    togglePanning,
    template,
    valuesOnlyFields,
    setValuesOnlyFields,
    hideAllObjects,
    setBackdrop,
    setTemplateIdentifiers,
    setModTempIdentifiers,
    modIdentifiersIds,
    setCurrentPage,
  } = useFabricContext();
  const [values, setValue] = useState();

  const { removeFieldDefinitions } = useOCRTemplate();

  // This function sets a name for the object that will be created on the canvas
  // The name is also used to identify on which object an action should be performed
  const objectName = (() => {
    if (!field || !field.id) return;

    // For the template name input
    if (action === 'initial-template-area') {
      return `${field.id}-identifier-0`;
    }
    // For identifiers in the ocr field
    if (action === 'set-identifier') {
      return `${field.id}-identifier-${identifierId}`;
    }
    //  main input of a field
    if (inputType === 'main' && field.id !== ocrTemplateFolderField.id) {
      return `${field.id}.main`;
    }
    // identifier or value of a field
    if (field.id !== ocrTemplateFolderField.id) {
      return `${field.id}.${inputType}-${identifierId}`;
    }
  })();

  const handleClear: MouseEventHandler<HTMLButtonElement> | undefined = async (e) => {
    e.stopPropagation();
    if (!field || !field.id) return;
    if (field.id === ocrTemplateFolderField?.id && inputType === 'identifier') {
      setTemplateIdentifiers((prevTemplateIdentifiers: any) =>
        prevTemplateIdentifiers.filter((item: any, index) => index !== identifierId)
      );
      setModTempIdentifiers(true);
    } else if (inputType === 'main') {
      if (valuesOnlyFields?.includes(field.id)) {
        const filtered = valuesOnlyFields.filter((item) => item !== field.id);
        setValuesOnlyFields(filtered);
      }
      handleRemoveFields(field.id, inputType);
      deleteObjectGroup(field.id);
    } else if (inputType === 'header' || inputType === 'value') {
      deleteObject(`${field.id}.${inputType}-0`);
      handleRemoveFields(field.id, inputType);
    }
  };

  const setArea = (e: MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();

    if (!field || !field.id || !objectName) return;
    if (action === 'set-identifier') setCurrentPage(1);

    hideAllObjects();
    setCanDraw(true);
    togglePanning(false);
    setSelectedObject(objectName);
    setFolderFieldId(field.id);
    setInputType(inputType);
    setAction(action);
    setIdentifierId(identifierId);
  };

  const toggleAreaVisibility: MouseEventHandler<HTMLButtonElement> | undefined = (e) => {
    e.stopPropagation();
    if (!objectName) return;
    toggleObjectVisibility(objectName);
  };
  const findObject = () => {
    if (!field || !field.id) return;

    return objects.find((el) => el.object.name.includes(field.id));
  };

  const checkToggleButtonState = () => {
    if (!field || !field.id) return;
    let objectExists = hasValue;
    if (field.id === ocrTemplateFolderField?.id) {
      if (inputType === 'template-name') {
        objectExists = hasValue;
      } else {
        objectExists = findObject() || el.identifierText;
      }
    } else {
      if (inputType === 'main') {
        objectExists =
          findObject() ||
          el.main ||
          el.headerValues[0].textToFind ||
          el.headerValues[0].value ||
          valuesOnlyFields?.includes(field.id);
      } else {
        objectExists =
          findObject() ||
          el.textToFind ||
          el.value ||
          valuesOnlyFields?.includes(field.id);
      }
    }

    const isSelected = selectedObject === objectName;
    if (objectExists && isSelected && canDraw) {
      return colorMode === 'light' ? theme.palette.common.orange : orange[300];
    }
    if (objectExists && !isSelected) {
      return colorMode === 'light' ? theme.palette.common.positive : green[300];
    }
    if (!objectExists) {
      return colorMode === 'light' ? theme.palette.common.greyblueDark : blueGrey[500];
    }
  };

  const isDisabled = () => {
    if (!field || !field.id) return;
    if (field.id === ocrTemplateFolderField?.id) {
      if (inputType === 'template-name') {
        // return findObject() || hasValue;
        return findObject();
      } else {
        return findObject() || el?.identifierText;
        // return findObject();
      }
    } else {
      if (inputType === 'main') {
        return (
          findObject() ||
          el.main ||
          el.headerValues[0].textToFind ||
          el.headerValues[0].value ||
          valuesOnlyFields?.includes(field.id)
        );
      } else {
        return (
          findObject() &&
          (el.textToFind || el.value || valuesOnlyFields?.includes(field.id))
        );
      }
    }
  };

  const isSetAreaDisabled = () => {
    if (inputType === 'main') {
      return false;
    }
    if (inputType === 'header' || inputType === 'value') {
      return !(
        findObject() &&
        (el.textToFind || el.value || valuesOnlyFields?.includes(field.id))
      );
    }
  };

  const setAreaTooltip = () => {
    if (inputType === 'main' || inputType === 'identifier') {
      return 'Set area';
    }
    if (inputType === 'header' || inputType === 'value') {
      return !findObject() ? 'You need to draw the general area to edit' : 'Set area';
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      {canDrawArea && (
        <Tooltip title={setAreaTooltip()}>
          <span>
            <StyledToggleAreaButton
              value={'area'}
              selected={objectName === selectedObject}
              onChange={setArea}
              sx={{ backgroundColor: checkToggleButtonState() }}
              disabled={isSetAreaDisabled()}
            >
              <FontAwesomeIcon icon="fas fa-vector-square" size="16px" />
            </StyledToggleAreaButton>
          </span>
        </Tooltip>
      )}

      {canClear && (
        <Tooltip title="clear">
          <span>
            <StyledDeleteIconButton
              aria-label="clear"
              onClick={handleClear}
              disabled={isDisabled() ? false : true}
            >
              <FontAwesomeIcon
                icon="fas fa-eraser"
                color="common.greyblueDark"
                size={''}
              />
            </StyledDeleteIconButton>
          </span>
        </Tooltip>
      )}

      {findObject() && canHideArea && (
        <Tooltip title="Hide area">
          <span>
            <StyledIconButton
              color="primary"
              aria-label="toggle area visibility"
              onClick={toggleAreaVisibility}
              disabled={isDisabled() ? false : true}
            >
              <FontAwesomeIcon
                icon="fas fa-eye"
                color="common.greyblueDark"
                size={'16px'}
              />
            </StyledIconButton>
          </span>
        </Tooltip>
      )}

      {template && inputType === 'template-name' && canSave && (
        <Tooltip title="Save template name">
          <span>
            <StyledIconButton
              color="primary"
              aria-label="save"
              onClick={onSave}
              disabled={hasValue ? false : true}
            >
              <FontAwesomeIcon
                icon="fas fa-save"
                color="common.greyblueDark"
                size={'16px'}
              />
            </StyledIconButton>
          </span>
        </Tooltip>
      )}
      {field.id === ocrTemplateFolderField.id &&
        inputType !== 'template-name' &&
        canSave && (
          <Tooltip title="Save identifier">
            <span>
              <StyledIconButton
                color="primary"
                aria-label="save"
                onClick={onSave}
                disabled={modIdentifiersIds?.includes(identifierId) ? false : true}
              >
                <FontAwesomeIcon
                  icon="fas fa-save"
                  color="common.greyblueDark"
                  size={'16px'}
                />
              </StyledIconButton>
            </span>
          </Tooltip>
        )}
      <Stack sx={{ marginRight: '10px' }}>
        {hasAdvancedSettings && (
          <Tooltip title="Open Advanced Settings">
            <span>
              <StyledIconButton
                color="primary"
                aria-label="save"
                onClick={() => {
                  handleOpenSettingsModal();
                  setSelectedField(field);
                }}
              >
                <FontAwesomeIcon
                  icon="fas fa-cog"
                  color="common.greyblueDark"
                  size={'16px'}
                />
              </StyledIconButton>
            </span>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default OCRSetupActions;
