import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import FontAwesomeIcon from '../../FAIcon';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';
import _ from 'lodash';

const classes = {
  arrow: {
    border: '10px solid transparent',
    borderBottomColor: 'common.greyblueDarker',
    width: '0',
    maxWidth: '0',
    position: 'relative',
    left: '46%',
  },
  box: {
    backgroundColor: 'common.greyblueDarker',
    borderRadius: '5px',
    boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
    color: 'white.main',
    width: 'auto',
    minWidth: '180px',
  },
  popover: {
    marginRight: 10,
  },
  paper: {
    backgroundColor: 'transparent',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  textfield: {
    '& .MuiInputBase-input': {
      backgroundColor: '#79909f',
      color: '#fff',
      borderRadius: '4px',
      padding: 1,
    },
    '&.Mui-focused': {
      boxShadow: `blue 0 0 0 2px`,
    },
    '& label.Mui-focused': {
      color: '#fff',
      fontWeight: 700,
      fontSize: 16,
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        borderColor: 'rgb(232, 245, 255)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(232, 245, 255)',
      },
    },
    '.MuiInputLabel-root': {
      color: '#fff',
    },
  },
};

const PopoverMultistring = ({
  anchor,
  setAnchor,
  params,
  values,
}: {
  anchor: any;
  setAnchor: Dispatch<SetStateAction<any>>;
  params: GridRenderCellParams<any, any, any>;
  values: string[];
}) => {
  const [value, setValue] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const apiRef = useGridApiContext();

  let { id, field } = params;

  const saveValueWithApi = (newValue: String[]) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    });
    apiRef.current.commitCellChange({ id, field });
  };

  const handleAddValue = () => {
    if (value === '') return;
    saveValueWithApi([...values, value]);
    setValue('');
  };

  const handleOnClose = () => {
    setAnchor(null);
    apiRef.current.setCellMode(id, field, 'view');
  };

  const handleDeleteValue = (index: number) => {
    if (values) {
      let filtered = values.filter((val, i) => i !== index);
      saveValueWithApi(filtered);
    }
  };

  const handleSetEditValue = (index: number, val: string) => {
    setMode('edit');
    setValue(val);
    setCurrentIndex(index);
  };

  const handleSaveEditedValue = () => {
    if (value === '') return;
    if (values && currentIndex !== null) {
      values[currentIndex] = value;
      saveValueWithApi(values);
      setValue('');
      setMode('add');
      setCurrentIndex(null);
    }
  };

  return (
    <Popover
      PaperProps={{ sx: classes.paper }}
      open={Boolean(anchor)}
      anchorEl={anchor}
      elevation={0}
      anchorOrigin={{
        vertical: 10,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleOnClose}
      onClick={(e) => e.stopPropagation()}
      sx={classes.popover}
    >
      <Box sx={classes.arrow}></Box>

      <Box sx={{ ...classes.box }}>
        <List>
          {values &&
            values.map((val: string, i: number) => (
              <Box key={i}>
                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="button" sx={{ fontSize: 11 }}>
                    {val}
                  </Typography>
                  <Box>
                    <IconButton onClick={() => handleDeleteValue(i)}>
                      <FontAwesomeIcon icon="fas fa-trash" size={11} color="#fff" />
                    </IconButton>
                    <IconButton onClick={() => handleSetEditValue(i, val)}>
                      <FontAwesomeIcon icon="fas fa-edit" size={11} color="#fff" />
                    </IconButton>
                  </Box>
                </ListItem>
                <Divider />
              </Box>
            ))}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: '10px',
              paddingBottom: '2px',
            }}
          >
            <TextField
              size="small"
              fullWidth
              onChange={(e) => setValue(e.target.value)}
              value={value}
              sx={classes.textfield}
            />
            <IconButton
              onClick={mode === 'edit' ? handleSaveEditedValue : handleAddValue}
            >
              <FontAwesomeIcon
                icon={mode === 'edit' ? 'fas fa-check' : 'fas fa-plus'}
                size={14}
                color="#fff"
              />
            </IconButton>
          </Box>
        </List>
      </Box>
    </Popover>
  );
};

const MultistringCell = ({
  params,
  screen,
  fieldID,
  colName,
}: {
  params: GridRenderCellParams<any, any, any>;
  screen: string;
  fieldID: string;
  colName: string;
}) => {
  const [anchor, setAnchor] = useState<any>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handlePopoverClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchor(e.currentTarget);
  };

  const values = [];

if (screen === 'indexing') {
    if (Array.isArray(params.row[colName])) {
        values.push(...params.row[colName]);
    } else {
        values.push(params.row[colName]);
    }
} else {
    if (params.row.multistring && params.row.multistring[fieldID]) {
        values.push(...params.row.multistring[fieldID]);
    }
}

  useEffect(() => {
    if (params.cellMode === 'edit' && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [params.cellMode]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Typography>
          {params.value === '' ? '' : _.truncate(values[0], { length: 14 })}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {values.length > 1 && (
            <Avatar sx={{ width: 20, height: 20, fontSize: '10px' }}>
              +{values.length - 1}
            </Avatar>
          )}
          <IconButton onClick={handlePopoverClick} ref={buttonRef}>
            <FontAwesomeIcon icon="fa-solid fa-list" size={12} />
          </IconButton>
        </Box>
      </Stack>
      <PopoverMultistring
        anchor={anchor}
        setAnchor={setAnchor}
        params={params}
        values={values}
      />
    </>
  );
};

export default MultistringCell;
