import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FontAwesomeIcon from '../../components/FAIcon';
import { SelectedFolder } from '../../components/masterTable/masterTableTypes';
import CreateTemplateDrawer from './CreateTemplateDrawer';
import { FilterSelectionDrawer } from './FilterSelectionDrawer';
import { NoTemplate } from './NoTemplate';
import { Filter, Template } from './ReportingSideBar';
import DateFilter from './filters/DateFilter';
import DateRangeFilter from './filters/DateRangeFilter';
import FilterItemContainer from './filters/FilterItemContainer';
import LinkedDataStatusFilter from './filters/LinkedDataStatusFilter';
import UserFilter from './filters/UserFilter';
import UsersFilter from './filters/UsersFilter';
import WorkflowAssignedByFilter from './filters/WorkflowAssignedByFilter';
import WorkflowStatusChangedByFilter from './filters/WorkflowStatusChangedByFilter';
import WorkflowStatusDateFilter from './filters/WorkflowStatusDateFilter';
import WorkflowStatusFilter from './filters/WorkflowStatusFilter';
import { nanoid } from 'nanoid';
import FieldSearch from './filters/FieldSearch';
import SearchFilterDrawer from './filters/SearchFilterDrawer';

interface FiltersProps {
  selectedTemplate: Template | null;
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  handleCreateTemplate: (template: Template) => void;
  selectedFolder: SelectedFolder | null;
  handleEditTemplate: (template: Template) => void;
}

// FILTER LIST
// type = KEY THAT BE/QUERY NEEDS
const options: Filter[] = [
  { label: 'Date', id: 'date', type: 'date', value: null },
  {
    label: 'Date Range',
    id: 'date-range',
    type: 'dateRange',
    value: { from: null, to: null },
  },
  { label: 'User', id: 'user', type: 'userId', value: '' },
  { label: 'Users', id: 'users', type: 'userId', value: null },
  {
    label: 'Workflow status',
    id: 'workflow-status',
    type: 'ReportTypeEnum',
    value: 'Approved',
  },
  {
    label: 'Workflow Status Date',
    id: 'workflow-status-date',
    type: 'workflowStatusDate',
    value: null,
  },
  {
    label: 'Workflow Status Changed by',
    id: 'workflow-status-changed-by',
    type: 'workflowStatusChangedBy',
    value: null,
  },
  {
    label: 'Workflow assigned by',
    id: 'workflow-assigned-by',
    type: 'workflowAssignedBy',
    value: null,
  },
  { label: 'Linked data status', id: 'linked-data-status', type: 'synced', value: null },
  { label: 'Field search', id: 'field-search', type: 'all', value: null },
];

const styles = {
  menuPaper: {
    maxHeight: '500px',
    backgroundColor: 'common.greyblueDarker',
    paddingX: '10px',
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: '5px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'common.greyblueDarker',
      borderRadius: '4px',
    },
  },
  addIcon: {
    borderRadius: '5px',
    backgroundColor: 'common.greyblue',
    height: '50px',
    '& .MuiIcon-root': {
      color: 'common.white',
    },

    '&:hover': {
      backgroundColor: 'common.greyblueDark',
      '& .MuiIcon-root': {
        color: 'common.orange',
      },
    },
  },
};

const Filters = ({
  selectedTemplate,
  filters,
  setFilters,
  handleCreateTemplate,
  selectedFolder,
  handleEditTemplate,
}: FiltersProps) => {
  const [drawer, setDrawer] = useState(false);
  const [searchDrawerIsOpen, setSearchDrawerIsOpen] = useState(false);
  const [templateDrawer, setTemplateDrawer] = useState(false);
  const { setValue, watch } = useFormContext();
  const [expandedFilters, setExpandedFilters] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setDrawer(true);
    // setAnchorEl(event.currentTarget);
  };

  const getRequiredFilters = (option: Filter) => {
    switch (option?.type) {
      case 'ReportTypeEnum':
        const required = options.filter(
          (el) => el?.type === 'dateRange' || el?.id === 'user'
        );
        return required;

      default:
        return [];
    }
  };

  const handleSelection = (option: Filter) => {
    const id = option?.label.toLowerCase().replace(/\s+/g, '-');
    if (id === 'field-search') {
      return openSearchFieldDrawer();
    }
    let includes = false;
    if (id !== 'date-range') {
      includes = filters.some((el: Filter) => el?.id === id);
    }
    if (!includes) {
      // get required filters and delete the ones that the user already had selected
      const required = getRequiredFilters(option).filter((el) => {
        if (!filters.some((obj) => obj?.type === el?.type)) {
          return el;
        }
      });
      // let filterID = option?.id === 'field-search' ? `field-search-${Date.now()}` : id;
      let filterID = option.id;
      const requiredIds = required.map((el) => el?.id);
      const updatedFilters: Filter[] = [
        ...filters,
        { ...option, id: filterID },
        ...required,
      ];
      let isATemplate = watch('selectedTemplate');
      if (isATemplate) {
        setFilters(updatedFilters);
        setValue('selectedTemplate.variables.filters', updatedFilters);
        setExpandedFilters((curr) => [...curr, filterID]);
        if (isATemplate.type === 'all' && option?.type !== 'all') {
          setValue(
            'selectedTemplate.type',
            option?.id === 'linked-data-status' ? 'linked_information' : 'workflow'
          );
        }
      } else {
        // Create template structure
        let structure = {
          label: 'SelectedFilters',
          id: 'SelectedFilters',
          name: 'SelectedFilters',
          type:
            option?.type === 'all'
              ? 'all'
              : option?.id === 'linked-data-status'
              ? 'linked_information'
              : 'workflow', // This must be changed
          variables: {
            filters: updatedFilters,
          },
        };
        setValue('selectedTemplate', structure);
        setFilters(updatedFilters);
        setExpandedFilters((curr) => [...curr, filterID, ...requiredIds]);
      }
    }
    setDrawer(false);
  };

  const handleDeleteFilter = (id: string, identifier: string) => {
    let updatedFilters: Filter[] = watch('selectedTemplate.variables.filters');

    if (id === 'date-range') {
      updatedFilters = updatedFilters.filter((el) => el?.identifier !== identifier);
    } else {
      updatedFilters = updatedFilters.filter((el) => el?.id !== id);
    }

    const updatedExpandedFilters = expandedFilters.filter((el) => el !== id);
    updatedFilters.length === 0
      ? setValue('selectedTemplate', '')
      : setValue('selectedTemplate.variables.filters', updatedFilters);
    setFilters(updatedFilters);
    setExpandedFilters(updatedExpandedFilters);
  };

  const getFilter = (filter: Filter) => {
    const identifier = nanoid();

    if (filter?.label.includes('Search Field')) {
      return <FieldSearch filter={filter} selectedFolder={selectedFolder} />;
    }

    switch (filter?.id) {
      case 'date':
        return <DateFilter />;
      case 'date-range':
        return (
          <DateRangeFilter
            filter={filter}
            selectedFolder={selectedFolder.id}
            identifier={identifier}
          />
        );
      case 'user': // Just accept one user, return an string
        return <UserFilter filter={filter} />;
      case 'users': // Dropdown, we can select several users, return an array
        return <UsersFilter filter={filter} />;
      case 'workflow-status':
        return <WorkflowStatusFilter filter={filter} handleSelection={handleSelection} />;
      case 'workflow-status-date':
        return <WorkflowStatusDateFilter filter={filter} />;
      case 'workflow-status-changed-by':
        return <WorkflowStatusChangedByFilter filter={filter} />;
      case 'workflow-assigned-by':
        return <WorkflowAssignedByFilter filter={filter} />;
      case 'linked-data-status':
        return <LinkedDataStatusFilter filter={filter} />;
      default:
        return <Box>Filter</Box>;
    }
  };

  const handleDrawerClose = () => {
    setDrawer(false);
  };

  const openSearchFieldDrawer = () => setSearchDrawerIsOpen(true);
  const closeSearchFieldDrawer = () => setSearchDrawerIsOpen(false);

  useEffect(() => {
    selectedTemplate ? setFilters(selectedTemplate.variables.filters) : setFilters([]);
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate && filters) setExpandedFilters(filters.map((el) => el?.id));
    if (!selectedTemplate && !filters) setExpandedFilters([]);
  }, [filters]);

  if (!drawer && !filters.length && !drawer)
    return <NoTemplate drawer={drawer} setDrawer={setDrawer} />;

  return (
    <Stack
      spacing={2}
      flex={1}
      sx={{
        ...styles.scrollbar,
        overflowY: 'scroll',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.black.main
            : theme.palette.common.white,
      }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row', md: 'row' }}
        padding="10px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="button"
          sx={{
            color: (theme) =>
              theme.palette.mode === 'dark' ? 'common.lightblue' : 'common.blue',
          }}
        >
          Filters
        </Typography>
        <Box>
          <Tooltip arrow title="Add filter">
            <IconButton
              color="primary"
              aria-label="add filter"
              component="label"
              onClick={handleClick}
            >
              <FontAwesomeIcon icon="fas fa-plus" aria-haspopup="true" size={15} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={selectedTemplate ? 'Edit template' : 'Save as template'}>
            <IconButton
              color="primary"
              aria-label={selectedTemplate ? 'edit template' : 'save as template'}
              component="label"
              onClick={() => setTemplateDrawer((prev) => !prev)}
            >
              <FontAwesomeIcon
                icon={selectedTemplate ? 'fas fa-edit' : 'fas fa-save'}
                aria-haspopup="true"
                size={15}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      <Stack
        sx={{
          maxHeight: '550px',
          marginTop: '0!important',
          paddingX: '10px',
        }}
        spacing={2}
        id="filter-list"
      >
        {filters.length > 0 &&
          filters.map((filter: Filter, index) => (
            <FilterItemContainer
              identifier={filter?.identifier}
              key={filter.id + index}
              id={filter.id}
              label={filter.label}
              expandedFilters={expandedFilters}
              setExpandedFilters={setExpandedFilters}
              handleDelete={handleDeleteFilter}
            >
              {getFilter(filter, openSearchFieldDrawer)}
            </FilterItemContainer>
          ))}
        <Tooltip arrow title="Add a filter">
          <IconButton
            color="primary"
            aria-label="add filter"
            component="label"
            onClick={handleClick}
            sx={styles.addIcon}
          >
            <FontAwesomeIcon icon="fas fa-plus" aria-haspopup="true" size={20} />
          </IconButton>
        </Tooltip>
      </Stack>
      <FilterSelectionDrawer
        options={options}
        onSelection={handleSelection}
        drawer={drawer}
        handleDrawerClose={handleDrawerClose}
        selectedFolder={selectedFolder}
      />
      <CreateTemplateDrawer
        templateDrawer={templateDrawer}
        handleDrawerClose={() => setTemplateDrawer(false)}
        handleCreateTemplate={handleCreateTemplate}
        selectedFolder={selectedFolder}
        selectedTemplate={selectedTemplate}
        handleEditTemplate={handleEditTemplate}
      />
      <SearchFilterDrawer
        searchDrawerIsOpen={searchDrawerIsOpen}
        onClose={closeSearchFieldDrawer}
        selectedFolder={selectedFolder}
        handleSelection={handleSelection}
      />
    </Stack>
  );
};

export default Filters;
