import React from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '400px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
const WholePageMessage = (props) => {
  const classes = useStyles();
  if (!props.isLoading) {
    return (
      <div
        style={{
          height: '50vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" color="textSecondary">
          {props.title}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {props.message}
        </Typography>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: '50vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" color="textSecondary">
          Hold on a second, we're loading the data.
        </Typography>
        <div className={classes.root}>
          <LinearProgress />
        </div>
      </div>
    );
  }
};

export default WholePageMessage;
