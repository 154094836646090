import { Plugin, PluginOnDocumentLoad } from '@react-pdf-viewer/core';

const pagesPlugin = (): Plugin => {
  const pages = (e: PluginOnDocumentLoad) => {
    window.localStorage.setItem('pdfPages', e.doc._pdfInfo.numPages);
    return true;
  };
  return { onDocumentLoad: pages };
};

export default pagesPlugin;
