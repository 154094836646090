import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Stack,
  styled,
  Tab,
  TabProps,
  Tabs,
  Theme,
  ToggleButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  hasPerformWorkflowPermissions,
  hasSetWorkflowPermissions,
} from '../../containers/search/searchUtils';
import { Permissions } from '../../containers/user/userProfile/components/UserPermissionsTab/permissionsTypes';
import WorkflowTab from '../../containers/workflow/Tab/WorkflowTab';
import { Step, Workflow } from '../../containers/workflow/types';
import { Context as AuthContext } from '../../context/authContext';
import { Context as TableContext } from '../../context/tableContext';
import { useOCRSetupContext } from '../../context/OCRSetupContext';
import { Context as StatusContext } from '../../context/statusContext';
import { modifyLocalStorageObject, useLocalStorage } from '../../hooks/useLocalStorage';
import usePDF from '../../hooks/usePDF';
import utils from '../../utils/utils';
import FontAwesomeIcon from '../FAIcon';
import {
  Attachment,
  Row,
  SelectedFolder,
  SetMessage,
  SetSnackBarState,
} from '../masterTable/masterTableTypes';
import OCRDocumentView from '../OCRSetup/OCRDocumentView';
import OCRSetupToolbar from '../OCRSetup/OCRSetupToolbar';
import PdfViewer from '../PdfViewer';
import { NotesTab } from './components/NotesTab';
import StepActionContainer from './components/stepAction/StepActionContainer';
import { TabPanel } from './components/TabPanel';
import { TableContextState, TableContextTypes } from '../../context/tableContextTypes';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const classes = {
  paper: {
    overflow: 'hidden',
    height: { lg: '100%' },
    borderLeft: {
      lg: '1px solid rgba(0, 0, 0, 0.12)',
    },
    backgroundColor: {
      lg: 'background.paper',
    },
    boxSizing: 'border-box',
    bottom: '0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  toggleButton: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    height: '50%',
    backgroundColor: '#fda808',
    width: 20,
    color: 'white',
    borderRadius: '0px 10px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0B72B9',
    },
  },
  contextButton: {
    backgroundColor: 'transparent',
    color: 'common.white',
    minWidth: '32px',
    height: '30px',
    padding: '5px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'common.blue',
    },
  },
  dragger: {
    width: '5px',
    cursor: 'ew-resize',
    padding: '4px 0 0',
    borderTop: '1px solid #ddd',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
  },
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  margin: 0,
  padding: '5px 10px',
  border: 0,
  color: '#fff',
  borderRadius: 0,
  '&.Mui-selected': {
    color: theme.palette.common.orange,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  height: '32px',
  minHeight: '32px',
  backgroundColor: theme.palette.common.greyblueDark,
  '& .MuiTabs-flexContainer': {
    height: 'inherit',
    minHeight: 'inherit',
    width: 'inherit',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

interface StyledTabProps extends TabProps {
  label: string | JSX.Element;
  disabled?: boolean;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    height: '32px',
    minHeight: '32px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      color: theme.palette.common.orange,
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.common.blue
          : theme.palette.common.white,
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.greyblueDark,
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

function a11yProps(label: string) {
  return {
    id: `${label}-tab`,
    'aria-controls': `${label}-tabpanel`,
  };
}

const showStepActions = (
  processPath: Workflow,
  currentProcessStep: Step,
  id: string
): boolean => {
  if (!processPath || !currentProcessStep) return false;

  if (currentProcessStep) {
    let { usersWhoHavePerformedApproval, toBeApprovedBy, completed } = currentProcessStep;
    let userHasAlreadyApproved = _.find(usersWhoHavePerformedApproval || [], {
      userId: id,
    });

    let userMustApprove = toBeApprovedBy.some((el: any) => el === id);

    if (!userMustApprove) return false; // user has no actions in the current step
    if (completed || userHasAlreadyApproved) return false; // current Step is completed or user has already approved
    if (!completed && userMustApprove) return true; // current Step is not completed and user must approve

    return false;
  } else {
    return false;
  }
};

export const ContainerWithDrawer = ({
  children,
  setOpen,
  open,
  hasAttachement,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} height="100%">
      <>
        {children}
        {hasAttachement && open === false && (
          <Box
            data-testid="toggle-drawer-button"
            sx={{
              ...classes.toggleButton,
              right: 0,
              width: 15,
              left: 'none',
              borderRadius: '10px 0px 0px 10px',
              padding: '2px',
            }}
            onClick={() => {
              setOpen();
            }}
          >
            <FontAwesomeIcon
              icon="fas fa-caret-left"
              size={'22px'}
              color="common.white"
            />
          </Box>
        )}
      </>
    </Stack>
  );
};

interface CollapsibleContentProps {
  children: React.ReactNode;
  width: number;
  fullScreenRecord: boolean;
}

export const CollapsibleContent = ({
  children,
  width,
  fullScreenRecord,
}: CollapsibleContentProps) => {
  let totalWidth = width + '%';
  return <>{children}</>;
};

interface CreationDrawerProps {
  open: boolean;
  goBack: () => void;
  fullScreenRecord: React.Dispatch<React.SetStateAction<boolean>>;
  records: Row[];
  recordId: string;
  attachment: Attachment;
  folder: SelectedFolder;
  activeRecordId: string;
  userPermissions: Permissions;
  setMessage: SetMessage;
  setSnackOpen: SetSnackBarState;
  notesRefetch: (values: any, isSearch?: boolean) => void;
  screen: string;
}

export default function CreationDrawer({
  open,
  goBack,
  fullScreenRecord,
  records,
  attachment,
  folder,
  activeRecordId,
  setMessage,
  setSnackOpen,
  notesRefetch,
  screen,
  toggleOCRSetup,
  ocrScanRecords,
}: CreationDrawerProps) {
  const { state } = useContext(StatusContext);
  const { state: authState } = useContext(AuthContext);
  const { state: tableState } = useContext(TableContext) as TableContextTypes;
  const textractProcessIsLoading = useMemo(
    () =>
      tableState.textractProcessLoader.find((text) => text.recordId === activeRecordId),
    [tableState, activeRecordId]
  );
  const ocrSetup = useOCRSetupContext();
  const { url: fileURL } = usePDF({ attachment });
  let {
    selectedRecord: { currentProcessStep, processPath, folderId },
    workflow,
    currentOrg,
  } = state;
  const widthMatches = useMediaQuery('(min-width:1280px)');
  const [selectedTab, setSelectedTab] = useState('preview');
  const [pinnedTab, setPinnedTab] = useState<string | undefined>();
  const [assignedAction, setAssignedAction] = useState(false);
  const [userData] = useLocalStorage('userData');
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const defaultDrawerWidth = userSettings.drawerWidth?.[screen] || 800;
  const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    if (newValue === 'preview' && toggleOCRSetup) {
      toggleOCRSetup(false);
    }
  };

  const minDrawerWidth = screen === 'indexing' ? 500 : 600;
  const maxDrawerWidth = 1250;

  const handlePermissions = (label: string): boolean => {
    // if (userPermissions && userPermissions.docDrawer) {
    //   return userPermissions.docDrawer[label];
    // }
    return true;
  };

  const handlePinTab = () => {
    if (pinnedTab === selectedTab) {
      return setPinnedTab('');
    }
    setPinnedTab(selectedTab);
  };

  useEffect(() => {
    pinnedTab ? setSelectedTab(pinnedTab) : setSelectedTab('preview');
  }, [attachment]);

  const saveDrawerWidthToLocalStorage = () => {
    setUserSettings(
      modifyLocalStorageObject(
        {
          ...userSettings,
          drawerWidth: { ...userSettings.drawerWidth, [screen]: drawerWidth },
        },
        'userSettings'
      )
    );
  };

  const debouncedSaveDrawerWidth = _.debounce(saveDrawerWidthToLocalStorage, 1500);

  const handleMouseDown = (e: React.MouseEvent) => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseMove = useCallback((e: any) => {
    const newWidth =
      document.body.offsetLeft + document.body.offsetWidth - e.clientX + 20;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) setDrawerWidth(newWidth);
  }, []);

  let folderPermissions = utils.checkFolderPermissions(authState.permissions, folder);

  let showWorkflowTab =
    screen !== 'indexing' &&
    workflow &&
    currentOrg &&
    currentOrg.processPathEnabled &&
    (hasPerformWorkflowPermissions(
      authState.permissions || null,
      userData.id,
      state.screen === 'record' ? folderId : state.selectedFolder[state.screen],
      false
    ) ||
      hasSetWorkflowPermissions(
        authState.permissions || null,
        userData.id,
        state.screen === 'record' ? folderId : state.selectedFolder[state.screen],
        false
      ));

  useEffect(() => {
    debouncedSaveDrawerWidth();

    return () => {
      // prevent multiple execution of debounce function
      debouncedSaveDrawerWidth.cancel();
    };
  }, [drawerWidth]);

  useEffect(() => {
    let showActions = showStepActions(processPath, currentProcessStep, userData?.id);
    setAssignedAction(showActions);
  }, [state.selectedRecord, currentProcessStep, processPath, userData?.id]);

  return (
    <>
      <Drawer
        data-testid="mastertable-drawer"
        open={open}
        anchor="right"
        variant="permanent"
        PaperProps={{
          style: {
            width: widthMatches ? drawerWidth : '100%',
            justifyContent: 'center',
            overflow: 'hidden',
            position: 'relative',
            borderRadius: '4px',
          },
          elevation: 1,
        }}
        sx={{ width: widthMatches ? drawerWidth : '100%', borderRadius: '4px' }}
      >
        {widthMatches && (
          <Box
            onMouseDown={(e) => handleMouseDown(e)}
            sx={{
              ...classes.dragger,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.common.greyblueDark
                  : theme.palette.common.lightblue,
            }}
          />
        )}
        {selectedTab !== 'ocr-setup' && (
          <Box
            data-testid="toggle-drawer-button"
            sx={classes.toggleButton}
            onClick={() => {
              goBack();
            }}
          >
            <ArrowRightIcon></ArrowRightIcon>
          </Box>
        )}

        <Stack height="100%" width="100%" sx={{ flexDirection: 'column' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              backgroundColor: 'common.greyblueDark',
              flexDirection: 'column',
              height: selectedTab === 'notes' ? '100%' : undefined,
            }}
            id="record-drawer"
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <StyledTabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="search tabs drawer"
              >
                <StyledTab label="Preview" value="preview" {...a11yProps('preview')} />

                {folder &&
                  folder.ocrEnabled &&
                  authState.permissions?.ocrTemplateCreator &&
                  screen === 'indexing' && (
                    <StyledTab
                      disabled={!!textractProcessIsLoading}
                      label={'OCR TEMPLATE SETUP'}
                      icon={
                        textractProcessIsLoading ? (
                          <CircularProgress
                            size={10}
                            sx={{
                              color: 'rgba(0, 0, 0, 0.38)',
                              marginLeft: '5px',
                            }}
                          />
                        ) : undefined
                      }
                      iconPosition="end"
                      value="ocr-setup"
                      onClick={() => toggleOCRSetup(true)}
                      {...a11yProps('ocr-setup')}
                    />
                  )}

                {screen !== 'indexing' && handlePermissions('notes') && (
                  <StyledTab label="Notes" value="notes" {...a11yProps('notes')} />
                )}
                {/* screen !== 'indexing' && handlePermissions('history') && (
                <StyledTab disabled label="History" {...a11yProps('history')} />
              ) */}
                {/* screen !== 'indexing' && handlePermissions('linkedInfo') && (
                <StyledTab
                  disabled
                  label="Linked Information"
                  {...a11yProps('linkedInfo')}
                />
              ) */}
                {showWorkflowTab && (
                  <StyledTab
                    label="Workflow"
                    value="workflow"
                    disabled={processPath ? false : true}
                    {...a11yProps('workflow')}
                  />
                )}
              </StyledTabs>
              <Stack direction="row">
                <Tooltip arrow title="Pin current tab">
                  <StyledToggleButton
                    value={selectedTab}
                    selected={pinnedTab === selectedTab}
                    onChange={handlePinTab}
                  >
                    <FontAwesomeIcon icon="fas fa-thumbtack" size="14px" />
                  </StyledToggleButton>
                </Tooltip>
                <Tooltip arrow title="Open this record on another tab or window">
                  <Button
                    sx={classes.contextButton}
                    variant="text"
                    onClick={() => {
                      let url = `/dash/search/${folder.id}/${activeRecordId}`;
                      window.open(url, '_blank');
                    }}
                  >
                    <FontAwesomeIcon size="18px" icon="fa-external-link-alt" />
                  </Button>
                </Tooltip>
              </Stack>
            </Box>
            <TabPanel
              value={selectedTab}
              index={'preview'}
              label="preview"
              screen={screen}
            >
              <>
                <Box sx={{ padding: '30px 35px' }}>
                  <PdfViewer
                    attachment={attachment}
                    height={
                      state.screen === 'search'
                        ? assignedAction
                          ? 'calc(100vh - 232px)'
                          : 'calc(100vh - 155px)'
                        : 'calc(100vh - 200px)'
                    }
                  ></PdfViewer>
                </Box>
              </>
            </TabPanel>
            <TabPanel
              value={selectedTab}
              index={'ocr-setup'}
              label="ocr-setup"
              screen={screen}
            >
              <Box sx={{ padding: '30px 35px', height: 'calc(100vh - 110px)' }}>
                <Stack height={'100%'}>
                  <OCRSetupToolbar
                    fileURL={fileURL}
                    ocrScanRecords={ocrScanRecords}
                    recordId={activeRecordId}
                  />
                  <Stack direction="row" height={'100%'}>
                    {fileURL && <OCRDocumentView fileURL={fileURL} />}
                  </Stack>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel value={selectedTab} index={'notes'} label="notes" screen={screen}>
              {screen === 'search' && (
                <NotesTab
                  setMessage={setMessage}
                  setSnackOpen={setSnackOpen}
                  recordId={activeRecordId}
                  records={records}
                  notesRefetch={notesRefetch}
                  folderPermissions={
                    folderPermissions && folderPermissions.folderPermissions
                  }
                />
              )}
            </TabPanel>
            <TabPanel
              value={selectedTab}
              index={'history'}
              label="history"
              screen={screen}
            >
              History
            </TabPanel>
            <TabPanel
              value={selectedTab}
              index={'linkedInfo'}
              label="linkedInfo"
              screen={screen}
            >
              Linked Information
            </TabPanel>
          </Stack>
          <TabPanel value={selectedTab} index={'history'} label="history" screen={screen}>
            History
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={'linkedInfo'}
            label="linkedInfo"
            screen={screen}
          >
            Linked Information
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index={'workflow'}
            label="workflow"
            screen={screen}
          >
            <WorkflowTab currentProcessStep={currentProcessStep} />
          </TabPanel>
          {selectedTab !== 'ocr-setup' && assignedAction && (
            <StepActionContainer
              currentProcessStep={currentProcessStep}
              processPath={processPath}
              setMessage={setMessage}
              setSnackOpen={setSnackOpen}
              refetch={notesRefetch}
            />
          )}
        </Stack>
      </Drawer>
    </>
  );
}
