import React from 'react';
import { Route } from 'react-router-dom';
import { StyledRecordsPage } from '../styles/dashStyle';
import SharedRecord from '../containers/shared/SharedRecord';

function SharingScreen() {
  console.log('sharing screen');
  return (
    <StyledRecordsPage>
      <Route exact path="/shared/:link" component={SharedRecord} />
    </StyledRecordsPage>
  );
}

export default SharingScreen;
