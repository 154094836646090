import React from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import ShowCounter from './ShowCounter';

const CountdownTimer = ({ endDate }: { endDate: string }) => {
  const [days, hours, minutes, seconds] = useCountdown(endDate);

  return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
};

export default CountdownTimer;
