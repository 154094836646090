import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { GET_USER, GET_USER_BY_ORG } from '../../../utils/gql/gqlUsers';
import { AuthContextTypes, Context as AuthContext } from '../../../context/authContext';
import { Context as StatusContext } from '../../../context/statusContext';

// Mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Components
import UserProfileTab from './components/UserProfileTab';
import UserSecurity from './components/UserSecurity';
import FontAwesomeIcon from '../../../components/FAIcon';
import ToolbarBase from '../../../components/toolbarScreen/ToolbarBase';
import ToolbarButtonLeft from '../../../components/toolbarScreen/ToolbarButtonLeft';
import ToolbarTitle from '../../../components/toolbarScreen/ToolbarTitle';
import UserPermissions from './components/UserPermissionsTab/UserPermissions';
import { Typography } from '@mui/material';
import UserPreferences from './components/UserPreferences';
import { TabPanelProps } from '@mui/lab';
import { User } from '../../../components/masterTable/masterTableTypes';
import { StatusContextTypes } from '../../../context/statusContextTypes';
import _ from 'lodash';

interface PanelProps extends TabPanelProps {
  index: number | string;
}

// Styled components
function TabPanel(props: PanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, height: '100%', maxWidth: '100%' }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: 'white',
  backgroundColor: theme.palette.common.greyblueDarker,
  fontWeight: 'bold',
  [theme.breakpoints.up('xs')]: {
    padding: '5px 0',
    fontSize: 17,
  },
  [theme.breakpoints.up('md')]: {
    padding: '10px 0',
    fontSize: 20,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.common.greyblueDarker,
  transition: '.3s',
  minWidth: '180px',
  '& .MuiTabs-flexContainer': {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
  },
  '& .Mui-selected': {
    [theme.breakpoints.up('md')]: {
      borderRight: '2px solid',
    },
    [theme.breakpoints.down('md')]: {
      borderBottom: '2px solid',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const StyledTab = styled(Tab)<{ isModal: boolean }>(({ theme, isModal = false }) => ({
  backgroundColor: theme.palette.common.greyblueDarker,
  color: theme.palette.common.white,
  minHeight: isModal ? '50px' : '72px',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'start',
    paddingLeft: '2rem',
  },
  transition: '0.3s',
  '& .MuiIcon-root': {
    marginRight: '1rem',
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  '& .MuiTab-label': { display: 'none' },
  '&.Mui-selected': {
    color: theme.palette.common.orange,
  },
  '& .MuiCardHeader-content': {
    display: 'none',
  },
}));

interface ITabPanel extends TabPanelProps {
  value: string;
  index: string;
}

const StyledTabPanel = styled(TabPanel)<ITabPanel>(({ theme }) => ({
  paddingRight: '3rem',
  height: '100%',
  width: '100%',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: '80vh',
  padding: 0,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  '&:last-child': {
    paddingBottom: 0,
  },
}));

function a11yProps(index: string) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface UserProfileProps {
  connected?: boolean;
  isModal: boolean;
}

interface Params {
  id: string;
}

export default function UserProfile({ connected, isModal = false }: UserProfileProps) {
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const [{ suser, selOrg, id: userID, role }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;
  const { id } = useParams<Params>();
  let userId: string = id || userID;
  let history = useHistory();
  const [userData, setUserData] = useState<User | null>(null);
  const [tabValue, setTabValue] = useState<string>('profile'); // used to switch tabs

  let user: User | null = null;

  const { data } = useQuery(isSuser ? GET_USER_BY_ORG : GET_USER, {
    fetchPolicy: 'network-only',
    variables: isSuser
      ? {
          organisationId:
            state.selectedOrg && _.isObject(state.selectedOrg)
              ? state.selectedOrg.id
              : selOrg.id,
          id: userId,
        }
      : { id: userId },
  });

  if (data) {
    user = isSuser ? data.UserByOrg.user : data.User.user;
  }

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setTabValue(newValue);
  };

  const permissionsMustBeDisabled = () => {
    if (!user) return;
    return userId === user.id || role > 0 ? true : false;
  };

  const title = userData ? `${userData.firstName} ${userData.lastName}` : 'User Profile';

  return (
    <Box padding={isModal ? '0px' : '10px'}>
      {isModal ? (
        <Title>{`${title}`}</Title>
      ) : (
        <ToolbarBase background={true}>
          <ToolbarButtonLeft buttonOnClick={() => history.goBack()}>
            <FontAwesomeIcon icon="fas fa-angle-left" />
          </ToolbarButtonLeft>
          <ToolbarTitle title={title} />
        </ToolbarBase>
      )}
      <Card
        variant={isModal ? 'outlined' : undefined}
        sx={{
          marginTop: isModal ? '0px' : '15px',
          border: isModal ? 'none' : '',
          overflow: isModal ? 'visible' : 'hidden',
        }}
      >
        <StyledCardContent>
          <StyledTabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            <StyledTab
              icon={<FontAwesomeIcon icon="fas fa-user" />}
              label="Profile"
              value={'profile'}
              {...a11yProps('profile')}
              isModal={isModal}
            />
            {isModal && (
              <StyledTab
                icon={<FontAwesomeIcon icon="fas fa-cog" />}
                label="Preferences"
                value="preferences"
                {...a11yProps('preferences')}
                isModal={isModal}
              />
            )}
            <StyledTab
              icon={<FontAwesomeIcon icon="fas fa-shield-alt" />}
              label="Security"
              value="security"
              {...a11yProps('security')}
              isModal={isModal}
            />
            <StyledTab
              icon={<FontAwesomeIcon icon="fas fa-lock" />}
              label="Permissions"
              value="permissions"
              {...a11yProps('permissions')}
              // disabled={permissionsMustBeDisabled()}
              disabled={false}
              isModal={isModal}
            />

            <StyledTab
              icon={<FontAwesomeIcon icon="fas fa-list-alt" />}
              label="Activity"
              value="activity"
              disabled
              {...a11yProps('activity')}
              isModal={isModal}
            />
          </StyledTabs>
          {userData && (
            <>
              <StyledTabPanel value={tabValue} index="profile" sx={{ p: 3 }}>
                <UserProfileTab user={userData} />
              </StyledTabPanel>
              <StyledTabPanel value={tabValue} index="security" sx={{ p: 3 }}>
                <UserSecurity user={userData} />
              </StyledTabPanel>
              <StyledTabPanel
                value={tabValue}
                index={'permissions'}
                sx={{
                  paddingRight: 0,
                  width: '100%',
                  maxWidth: { md: 'calc(100% - 180px)', lg: 'calc(100% - 180px)' }, // 180px = styles tab min width
                }}
              >
                <UserPermissions user={userData} setUserData={setUserData} />
              </StyledTabPanel>
              <StyledTabPanel value={tabValue} index="preferences" sx={{ padding: 0 }}>
                <UserPreferences user={userData} setUserData={setUserData} />
              </StyledTabPanel>
            </>
          )}
        </StyledCardContent>
      </Card>
    </Box>
  );
}
