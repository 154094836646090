import { Autocomplete, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import useDateRange from '../../../hooks/useDateRange';
import useFolders from '../../../hooks/useFolders';
import { Filter } from '../ReportingSideBar';

const INPUTFORMAT = 'dd/MM/yyyy';

interface DateRangeFilterProps {
  filter: Filter;
  selectedFolder: string;
}

const DateRangeFilter = ({ filter, selectedFolder }: DateRangeFilterProps) => {
  const { fields } = useFolders(selectedFolder);
  const { startDate, endDate } = useDateRange(30, false);
  const { setValue: setFormValue, watch, control } = useFormContext();
  const identifier = filter.identifier || nanoid();
  const [index, setIndex] = useState<number>(-1);
  const [options, setOptions] = useState<any[]>([]);

  const handleChangeDate = (value: any, type: string) => {
    setFormValue(`selectedTemplate.variables.filters[${index}].value.${type}`, value);
  };

  useEffect(() => {
    const currentFilters: Filter[] = watch('selectedTemplate.variables.filters');
    let index = currentFilters.findIndex(
      (filter) => filter.id === 'date-range' && !filter.identifier
    );
    setIndex(index);
    if (index !== -1) {
      setFormValue(`selectedTemplate.variables.filters[${index}].identifier`, identifier);
    }
  }, []);

  useEffect(() => {
    if (index !== -1 && options && options.length > 0) {
      setFormValue(
        `selectedTemplate.variables.filters[${index}].fieldname`,
        filter.fieldname || options[0].label
      );
      setFormValue(
        `selectedTemplate.variables.filters[${index}].value.from`,
        filter.value.from || startDate
      );
      setFormValue(
        `selectedTemplate.variables.filters[${index}].value.to`,
        filter.value.to || endDate
      );
    }
  }, [index, startDate, endDate, filter, options]);

  useEffect(() => {
    const options = fields
      ?.filter((field) => field.type === 'datetime')
      .map((field) => ({
        label: field.name,
        id: field.id,
      }));
    setOptions(options);
  }, [fields]);

  return (
    <>
      {options && options.length > 0 && (
        <Controller
          control={control}
          name={`selectedTemplate.variables.filters[${index}].fieldname`}
          defaultValue={filter.fieldname || options[0].label} // Default option here
          render={({ field }) => {
            return (
              <Autocomplete
                disableClearable={true}
                id="field-selector"
                options={options}
                value={field.value || options[0].label || null}
                onChange={(e, value) => {
                  field.onChange(value ? value.label : null);
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.label === value;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      '& .MuiFormHelperText-root': {
                        backgroundColor: 'common.white',
                        width: '100%',
                        margin: 0,
                        paddingX: '10px',
                      },
                    }}
                  />
                )}
                sx={{
                  '& .MuiTextField-root': {
                    width: '100%',
                    '& .MuiInputBase-root': {
                      color: 'common.greyblueDarker',
                      '& .MuiInputBase-input': {
                        textAlign: 'end',
                        fontWeight: 'bold',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'common.greyblueDarker',
                      '&.Mui-focused': {
                        color: 'common.greyblueDarker',
                      },
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'common.greyblueDarker',
                    },
                  },
                }}
              />
            );
          }}
        />
      )}
      {endDate && startDate ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            name={`selectedTemplate.variables.filters[${index}].value.from`}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                label="from"
                value={value || filter.value.from || startDate}
                onChange={(newValue) => handleChangeDate(newValue, 'from')}
                inputFormat={INPUTFORMAT}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: false }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select a date',
                    }}
                    fullWidth
                    sx={{
                      '& fieldset': { border: 'none' },
                      '& .MuiInputBase-input': {
                        textAlign: 'end',
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'common.greyblueDarker',
                      },
                      '& .MuiInputLabel-root': {
                        color: 'common.greyblueDarker',
                      },
                      '& .MuiIconButton-root': {
                        color: 'common.greyblueDarker',
                      },
                    }}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name={`selectedTemplate.variables.filters[${index}].value.to`}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <DatePicker
                  label="to"
                  value={value || filter.value.to || endDate}
                  onChange={(newValue) => handleChangeDate(newValue, 'to')}
                  inputFormat={INPUTFORMAT}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: false }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Select a date',
                      }}
                      fullWidth
                      sx={{
                        '& fieldset': { border: 'none' },
                        '& .MuiInputBase-input': {
                          textAlign: 'end',
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'common.greyblueDarker',
                        },
                        '& .MuiInputLabel-root': {
                          color: 'common.greyblueDarker',
                        },
                        '& .MuiIconButton-root': {
                          color: 'common.greyblueDarker',
                        },
                      }}
                    />
                  )}
                />
              );
            }}
          />
        </LocalizationProvider>
      ) : null}
    </>
  );
};

export default DateRangeFilter;
