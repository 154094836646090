import { gql } from 'apollo-boost';

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(data: { email: $email, password: $password }) {
      user {
        id
        firstName
        lastName
        role
        changePw
        userSettings {
          indexedDB
          localStorageSettings
        }
        userPreferences {
          theme
          emailNotifications
        }
        organisationId {
          id
          name
          processPathEnabled
        }
        permissions {
          templateName
          fullFolderAccess
          fullScreenAccess
          ocrTemplateCreator
          folderAccessDetails {
            folderID
            canAccess
            fullAccess
            folderPermissions {
              canDeleteNotes
              canDeleteRecords
              canRemoveIndex
              processPathPermissions {
                canSetProcessPath
                canPerformProcessStep
                canSearchUsingAnyFilter
                requireNoteWhenApproving
                requireNoteWhenRejecting
              }
              browsePermissions {
                canEditIndexedRecords
                folderFieldRestrictions {
                  folderFieldId
                  folderFieldRestrictionType
                  folderFieldFieldType
                  folderFieldValue
                }
              }
            }
          }
        }
      }
      error
      superUser {
        id
        firstName
        lastName
        userSettings {
          indexedDB
          localStorageSettings
        }
        organisations {
          id
          name
        }
      }
      scan
    }
  }
`;
export const GET_USERS = gql`
  query {
    Users {
      users {
        title
        id
        firstName
        lastName
        role
        createdAt
        updatedAt
        email
        active
        permissions {
          templateName
          fullFolderAccess
          fullScreenAccess
          ocrTemplateCreator
          folderAccessDetails {
            folderID
            canAccess
            fullAccess
            folderPermissions {
              canDeleteNotes
              canDeleteRecords
              canRemoveIndex
              processPathPermissions {
                canSetProcessPath
                canPerformProcessStep
                canSearchUsingAnyFilter
                requireNoteWhenApproving
                requireNoteWhenRejecting
              }
              browsePermissions {
                canEditIndexedRecords
                folderFieldRestrictions {
                  folderFieldId
                  folderFieldRestrictionType
                  folderFieldFieldType
                  folderFieldValue
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query User($id: ID) {
    User(id: $id) {
      user {
        id
        firstName
        lastName
        role
        createdAt
        updatedAt
        email
        active
        userPreferences {
          theme
          emailNotifications
        }
        permissions {
          templateName
          fullFolderAccess
          fullScreenAccess
          ocrTemplateCreator
          folderAccessDetails {
            folderID
            canAccess
            fullAccess
            folderPermissions {
              canDeleteNotes
              canDeleteRecords
              canRemoveIndex
              processPathPermissions {
                canSetProcessPath
                canPerformProcessStep
                canSearchUsingAnyFilter
                requireNoteWhenApproving
                requireNoteWhenRejecting
              }
              browsePermissions {
                canEditIndexedRecords
                folderFieldRestrictions {
                  folderFieldId
                  folderFieldRestrictionType
                  folderFieldFieldType
                  folderFieldValue
                }
              }
            }
          }
        }
      }
      error
    }
  }
`;
export const GET_SUSER = gql`
  query SUser($id: ID) {
    SUser(id: $id) {
      superUser {
        id
        firstName
        lastName
        createdAt
        updatedAt
        email
        active
      }
      error
    }
  }
`;

export const GET_USER_BY_ORG = gql`
  query UserByOrg($id: ID, $organisationId: ID!) {
    UserByOrg(id: $id, organisationId: $organisationId) {
      user {
        id
        firstName
        lastName
        role
        createdAt
        updatedAt
        email
        active
        userPreferences {
          theme
          emailNotifications
        }
        permissions {
          templateName
          fullFolderAccess
          fullScreenAccess
          ocrTemplateCreator
          folderAccessDetails {
            folderID
            canAccess
            fullAccess
            folderPermissions {
              canDeleteNotes
              canDeleteRecords
              canRemoveIndex
              processPathPermissions {
                canSetProcessPath
                canPerformProcessStep
                canSearchUsingAnyFilter
                requireNoteWhenApproving
                requireNoteWhenRejecting
              }
              browsePermissions {
                canEditIndexedRecords
                folderFieldRestrictions {
                  folderFieldId
                  folderFieldRestrictionType
                  folderFieldFieldType
                  folderFieldValue
                }
              }
            }
          }
        }
      }
      error
    }
  }
`;
export const GET_USERS_BY_ORG = gql`
  query UsersByOrg($organisationId: ID!) {
    UsersByOrg(organisationId: $organisationId) {
      users {
        title
        id
        firstName
        lastName
        role
        createdAt
        updatedAt
        email
        active
      }
    }
  }
`;
export const GET_USER_BY_FOLDER = gql`
  query UsersByFolder($folderId: ID) {
    UsersByFolder(folderId: $folderId) {
      users {
        title
        id
        firstName
        lastName
        role
        createdAt
        updatedAt
        email
        active
        changePw
      }
      error
    }
  }
`;
export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $title: String
    $password: String!
    $role: Role!
    $changePw: Boolean
    $permissions: PermissionsInput
  ) {
    createUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        title: $title
        password: $password
        role: $role
        changePw: $changePw
        permissions: $permissions
      }
    ) {
      user {
        id
        permissions {
          fullFolderAccess
          fullScreenAccess
          ocrTemplateCreator
          folderAccessDetails {
            folderID
            canAccess
            fullAccess
            folderPermissions {
              canDeleteNotes
              canRemoveIndex
              canDeleteRecords
              processPathPermissions {
                canSetProcessPath
                canPerformProcessStep
                canSearchUsingAnyFilter
                requireNoteWhenApproving
                requireNoteWhenRejecting
              }
              browsePermissions {
                canEditIndexedRecords
                folderFieldRestrictions {
                  folderFieldId
                  folderFieldRestrictionType
                  folderFieldFieldType
                  folderFieldValue
                }
              }
            }
          }
        }
      }
      error
    }
  }
`;
export const CREATE_USER_BY_ORG = gql`
  mutation createUserByOrg(
    $firstName: String!
    $lastName: String!
    $email: String!
    $title: String
    $password: String!
    $role: Role!
    $organisationId: ID!
  ) {
    createUserByOrg(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        title: $title
        password: $password
        role: $role
      }
      organisationId: $organisationId
    ) {
      user {
        id
      }
      error
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $email: String
    $title: String
    $password: String
    $role: Role
    $id: ID!
    $changePw: Boolean
    $permissions: PermissionsInput
  ) {
    updateUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        title: $title
        password: $password
        role: $role
        changePw: $changePw
        permissions: $permissions
      }
      id: $id
    ) {
      user {
        title
        id
        firstName
        lastName
        role
        createdAt
        updatedAt
        email
        active
        changePw
        permissions {
          fullFolderAccess
          fullScreenAccess
          ocrTemplateCreator
          folderAccessDetails {
            folderID
            canAccess
            fullAccess
            folderPermissions {
              canDeleteNotes
              canRemoveIndex
              canDeleteRecords
              processPathPermissions {
                canSetProcessPath
                canPerformProcessStep
                canSearchUsingAnyFilter
                requireNoteWhenApproving
                requireNoteWhenRejecting
              }
              browsePermissions {
                canEditIndexedRecords
                folderFieldRestrictions {
                  folderFieldId
                  folderFieldRestrictionType
                  folderFieldFieldType
                  folderFieldValue
                }
              }
            }
          }
        }
      }
      error
    }
  }
`;
export const SET_PW = gql`
  mutation userSetPassword($password: String!) {
    userSetPassword(data: { password: $password }) {
      success
      error
    }
  }
`;
