import { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import { Context as StatusContext } from '../../context/statusContext';
import { GlobalOptionsTabProps } from './types';
import { StatusContextTypes } from '../../context/statusContextTypes';
import StyledSwitch from '../../components/form/StyledSwitch';
import { SecondStyledTexfield } from '../../components/form/StyledTextfield';
import { MenuItem, TextField } from '@mui/material';

const classes = {
  switch: {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: 'common.greyblueDarker',
        '& + .MuiSwitch-track': {
          backgroundColor: 'common.greyblue',
        },
      },
    },
  },
};

const GlobalOptionsTab = ({
  folderName,
  folder,
  setFolderName,
  handleFolderUpdate,
  handleEditGlobalOptions,
  folderGlobalOptions,
}: GlobalOptionsTabProps) => {
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const [ocrTemplateIdentifier, setOcrTemplateIdentifier] = useState();

  const options = folder.folderFields.map((obj) => ({ id: obj.id, label: obj.name }));

  return (
    <Grid container>
      <Grid item xs={6}>
        <SecondStyledTexfield
          fullWidth
          style={{ marginBottom: 10 }}
          type="text"
          value={folderName}
          autoFocus
          onBlur={handleFolderUpdate}
          onChange={(e) => {
            const value = e.target.value;
            setFolderName(value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          label="Folder Name"
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid container item alignItems="center">
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="active"
                  color="secondary"
                  checked={folderGlobalOptions.active}
                  defaultChecked={folderGlobalOptions.active}
                  onClick={(e) => {
                    let target = e.target as HTMLInputElement;
                    handleEditGlobalOptions('active', target.checked);
                  }}
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item>
            <FormLabel>
              Set the folder as active, disabling this setting will effectively disable
              the folder
            </FormLabel>
          </Grid>
        </Grid>

        <Grid container item xs={12} alignItems="center">
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="autoAppendRecords"
                  color="secondary"
                  checked={folderGlobalOptions.autoAppendRecords}
                  onClick={(e) => {
                    let target = e.target as HTMLInputElement;
                    handleEditGlobalOptions('autoAppendRecords', target.checked);
                  }}
                />
              }
              label="Auto Append Records"
            />
          </Grid>
          <Grid item>
            <FormLabel>
              Records with the same main field will be automatically merged together
            </FormLabel>
          </Grid>
        </Grid>
        {state.currentOrg && state.currentOrg.processPathEnabled ? (
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name="workFlow"
                    color="secondary"
                    checked={folderGlobalOptions.workFlow}
                    onClick={(e) => {
                      let target = e.target as HTMLInputElement;
                      handleEditGlobalOptions('workFlow', target.checked);
                    }}
                  />
                }
                label="Workflow Functionality"
              />
            </Grid>
            <Grid item>
              <FormLabel>
                Enabling workflow allows the folder to have approval workflows and other
                kinds of workflows
              </FormLabel>
            </Grid>
          </Grid>
        ) : null}

        <Grid container item xs={12} alignItems="center">
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="scanning"
                  color="secondary"
                  checked={folderGlobalOptions.scanning}
                  onClick={(e) => {
                    let target = e.target as HTMLInputElement;
                    handleEditGlobalOptions('scanning', target.checked);
                  }}
                />
              }
              label="Barcode scan Enabled"
            />
          </Grid>
          <Grid item>
            <FormLabel>
              Enable the barcode scanning functionality on this folder
            </FormLabel>
          </Grid>
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="ocr"
                  color="secondary"
                  checked={folderGlobalOptions.ocrEnabled}
                  onClick={(e) => {
                    let target = e.target as HTMLInputElement;
                    handleEditGlobalOptions('ocrEnabled', target.checked);
                  }}
                />
              }
              label="OCR Enabled"
            />
          </Grid>
          <Grid item>
            <FormLabel>
              This option enables OCR automatic document reading to index records
              automatically
            </FormLabel>
          </Grid>
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name="enforceWorkflowNotes"
                  color="secondary"
                  checked={folderGlobalOptions.enforceWorkflowNotes}
                  onClick={(e) => {
                    let target = e.target as HTMLInputElement;
                    handleEditGlobalOptions('enforceWorkflowNotes', target.checked);
                  }}
                />
              }
              label="Enforce Workflow Notes"
            />
          </Grid>
          <Grid item>
            <FormLabel>Enforce workflow notes description</FormLabel>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GlobalOptionsTab;
