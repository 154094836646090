import { useState } from 'react';
import { MenuItem } from '@mui/material';
import { StyledSelect } from './AdvancedSettingsModal';
import { StyledTextField } from '../StyledComponents';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import _ from 'lodash';

interface InputProps {
  generalStyles: any;
  handleChangeSettings: (key: string, value: any) => void;
  field: any;
  settings: any;
}

const FixedValue = ({
  generalStyles,
  handleChangeSettings,
  field,
  settings,
}: InputProps) => {
  const [value, setValue] = useState(settings.predefined_Value || '');
  switch (field.type) {
    case 'string':
      return (
        <StyledTextField
          size="small"
          value={value}
          sx={{ ...generalStyles.textfield } as any}
          onChange={(e) => {
            setValue(e.target.value);
            handleChangeSettings('predefined_Value', e.target.value);
          }}
        />
      );
    case 'datetime':
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={value}
            renderInput={(params) => (
              <StyledTextField
                size="small"
                sx={
                  {
                    ...generalStyles.textfield,
                    '& .MuiSvgIcon-root': {
                      transform: 'scale(0.7)',
                      fill: '#8399A8',
                    },
                  } as any
                }
                {...params}
              />
            )}
            onChange={(value) => {
              setValue(value || '');
              handleChangeSettings('predefined_Value', value || '');
            }}
          />
        </LocalizationProvider>
      );
    case 'dropdown':
      return (
        <StyledSelect
          disableUnderline
          value={value}
          onChange={(e) => {
            setValue(e.target.value as string);
            handleChangeSettings('predefined_Value', e.target.value);
          }}
          size="small"
        >
          <MenuItem value={''} disabled={value === ''}>
            Clear
          </MenuItem>
          {field.options.map((opt: { value: any; key: string }) => (
            <MenuItem value={opt.value}>{opt.key}</MenuItem>
          ))}
        </StyledSelect>
      );
    case 'integer':
      return (
        <StyledTextField
          size="small"
          value={value}
          type="number"
          sx={{ ...generalStyles.textfield } as any}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={(e) => {
            let value = e.target.value;
            const is = Number(value);
            const isNotANumber = _.isNaN(is);
            if (isNotANumber) value = '';
            setValue(value);
            handleChangeSettings('predefined_Value', value);
          }}
        />
      );
    case 'multistring':
      return (
        <StyledTextField
          size="small"
          value={value}
          sx={{ ...generalStyles.textfield } as any}
          onChange={(e) => {
            setValue(e.target.value);
            handleChangeSettings('predefined_Value', e.target.value);
          }}
        />
      );
    case 'currency':
      const currencyRegex = /^\d+(\.\d{0,2})?$/;
      return (
        <StyledTextField
          size="small"
          value={value}
          sx={{ ...generalStyles.textfield } as any}
          onChange={(e) => {
            let value = e.target.value;
            if (!currencyRegex.test(value)) value = '';
            setValue(value);
            handleChangeSettings('predefined_Value', value);
          }}
        />
      );

    case 'boolean':
      return (
        <StyledSelect
          disableUnderline
          size="small"
          value={value}
          onChange={(e) => {
            setValue(e.target.value as string);
            handleChangeSettings('predefined_Value', e.target.value);
          }}
        >
          <MenuItem value={''} disabled={value === ''}>
            Clear
          </MenuItem>
          <MenuItem value={'false'}>False</MenuItem>
          <MenuItem value={'true'}>True</MenuItem>
        </StyledSelect>
      );

    default:
      break;
  }
};

export default FixedValue;
