import { useEffect, useState } from 'react';

import StyledMasterTable from '../../../../components/masterTable/StyledMasterTable';
import ChartHeader from '../ChartHeader';
import FontAwesomeIcon from '../../../../components/FAIcon';

import { response } from './ResponseData';
import { Paper, Stack } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  headers: string[];
  title?: string;
  density?: string;
  hideFooter?: boolean;
  disableColumnMenu?: boolean;
}
export default function DashTable({
  headers,
  title = 'Latest actions on Records',
  density = 'compact',
  hideFooter = true,
  disableColumnMenu = true,
}: Props) {
  const [data, setData] = useState(response.records);
  const [columns, setColumns] = useState([]);

  headers = ['Action', 'Main Field', 'Created By', 'Folder'];

  // Filter all fields and returns the main one
  const getMainField = (params: any) => {
    const fields = params.row.record.fields;

    const main = fields.filter((field: any) => field.field.mainfield);

    return main[0].value;
  };

  // Gets the fullName from the createdBy property
  const getFullName = (params: any) => {
    const createdBy = params.row.record.createdBy;

    return `${createdBy.firstName} ${createdBy.lastName}`;
  };

  // Returns a component with a colored icon and the action string
  const getAction = (params: any) => {
    const action = params.row.action;
    let color;

    switch (action) {
      case 'deleted':
        color = 'common.negative';
        break;
      case 'indexed':
        color = 'common.positive';
        break;
      case 'scanned':
        color = '#7FE5F8';
        break;
      default:
        break;
    }

    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <FontAwesomeIcon icon="fas fa-circle" color={color} size={15} />
        <Box>{action.toUpperCase()}</Box>
      </Stack>
    );
  };

  // Gets the columns for the datagrid from the items in headers prop
  const getColumns = (headers: string[]) => {
    const columns = headers.map((header) => {
      switch (header) {
        case 'Action':
          return { field: 'action', headerName: header, renderCell: getAction, flex: 1 };
        case 'Main Field':
          return {
            field: 'mainField',
            headerName: header,
            valueGetter: getMainField,
            flex: 1,
          };
        case 'Created By':
          return {
            field: 'createdBy',
            headerName: header,
            valueGetter: getFullName,
            flex: 1,
          };
        case 'Folder':
          return {
            field: 'folder',
            headerName: header,
            valueGetter: (params: any) => params.row.folder.name,
            flex: 1,
          };
        default:
          break;
      }
    });
    setColumns(columns);
  };

  useEffect(() => {
    getColumns(headers);
  }, []);

  return (
    <Box sx={{ height: '100%', width: '100%', marginTop: '0px' }}>
      <ChartHeader title={title} />

      <Paper
        sx={{
          height: '100%',
          margin: 0,
          padding: 0,
          minHeight: '170px',
          marginBottom: '10px',
        }}
      >
        <Paper
          sx={{ borderRadius: '4px', overflow: 'hidden', height: '100%' }}
          elevation={0}
        >
          <StyledMasterTable
            columns={columns}
            rows={data}
            getRowId={(row) => row.record.id}
            density={density}
            hideFooter={hideFooter}
            disableColumnMenu={disableColumnMenu}
          />
        </Paper>
      </Paper>
    </Box>
  );
}
