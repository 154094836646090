import { GridRowId } from '@mui/x-data-grid';
import { Field } from '../../masterTableTypes';
import { GridApiCommunity } from '@mui/x-data-grid/models/api/gridApiCommunity';
import { MutableRefObject } from 'react';
import utils from '../../../../utils/utils';

export const generateData = (rows: any[], fields: string[]) => {
  return rows.map((row) => {
    return fields.map((column) => {
      if (column === 'createdAt') return row.createdAt;
      const fieldValue = row.fields.find((f: Field) => f.field.name === column);
      if (fieldValue && column === 'toBePerformedBy') {
        return fieldValue.arrayValue.join('-');
      }
      return fieldValue ? fieldValue.value : '';
    });
  });
};

export const generateSelectedRows = (selectedRows: Map<GridRowId, any>, rows: any[]) => {
  let selected = Array.from(selectedRows.values(), (row) => ({ ...row }));
  return rows.filter((row) => selected.some((selected) => selected.id === row.id));
};

export const generateRowsByConfig = (
  apiRef: MutableRefObject<GridApiCommunity>,
  rows: any[],
  allPages: boolean
) => {
  let sortedRows = apiRef.current.getSortedRows();
  let selectedRows = apiRef.current.getSelectedRows();

  rows.sort((a, b) => {
    const indexA = sortedRows.findIndex((obj) => obj.id === a.id);
    const indexB = sortedRows.findIndex((obj) => obj.id === b.id);
    return indexA - indexB;
  });

  if (!allPages) {
    const currentPage = apiRef.current.state.pagination.page;
    const pageSize = apiRef.current.state.pagination.pageSize;
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    rows = rows.slice(startIndex, endIndex);
  }

  if (selectedRows.size) {
    rows = generateSelectedRows(selectedRows, rows);
  }
  // Convert date
  rows = rows.map((row) => {
    let hasDateTimeField = row.fields.some((field) => field.field.type === 'datetime');
    if (hasDateTimeField) {
      row = {
        ...row,
        fields: row.fields.map((field) =>
          field.field.type === 'datetime'
            ? { ...field, value: utils.convertDate(new Date(parseInt(field.value))) }
            : field
        ),
      };
    }
    return {
      ...row,
      createdAt: utils.convertDate(parseInt(row.createdAt)),
    };
  });
  return rows;
};
