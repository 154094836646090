import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Title = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.common.greyblueDarker,
  [theme.breakpoints.up('xs')]: {
    padding: '10px',
    fontSize: 17,
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px',
    fontSize: 20,
  },
}));

export default function Titles({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <>
      <Title variant="subtitle2" component="h2">
        {title}
      </Title>
      {subtitle && (
        <Typography variant="caption" color="textSecondary" padding="20px">
          {subtitle}
        </Typography>
      )}
    </>
  );
}
