import { Box, Grid } from '@mui/material';
import { WorkFlowMainProps } from '../types';
import Footer from './Footer';
import Header from './Header';
import StepsContainer from './StepsContainer';
import ToolbarMain from './ToolbarMain';

const styles = {
  gridItem: {
    height: { lg: '100%', xs: 'auto' },
    minHeight: { xs: '50%' },
    flexBasis: '100%',
    overflow: 'hidden',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '4px',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '100%',
  },
};

const WorkFlowMain = ({
  steps,
  handleRemoveStep,
  handleAddStep,
  refCardsContainer,
  handleUpdateStep,
  handleUpdateStepOrders,
  isLoading,
  handleSaveTemplate,
  handleSaveWorkflow,
  selectedWorkflow,
  modifiedTemplate,
  updateTemplate,
  context,
  applyTemplateToRecord,
  applyTemplateToFolder,
  workflows,
  setSelectedWorkflow,
  setWorkflowModal,
  users,
  stepsWithErrors,
  setStepsWithErrors,
  setSteps,
  setModalHasModifications,
}: WorkFlowMainProps) => {
  return (
    <Grid item xs={12} sx={styles.gridItem}>
      <Box sx={styles.box}>
        {context === 'modal' && (
          <Header workflows={workflows} setSelectedWorkflow={setSelectedWorkflow} />
        )}
        {context === 'screen' && (
          <ToolbarMain
            handleSaveTemplate={handleSaveTemplate}
            handleSaveWorkflow={handleSaveWorkflow}
            selectedWorkflow={selectedWorkflow}
            modifiedTemplate={modifiedTemplate}
            updateTemplate={updateTemplate}
            applyTemplateToFolder={applyTemplateToFolder}
            steps={steps}
            setStepsWithErrors={setStepsWithErrors}
          />
        )}
        <StepsContainer
          steps={steps}
          handleRemoveStep={handleRemoveStep}
          handleAddStep={handleAddStep}
          refCardsContainer={refCardsContainer}
          handleUpdateStep={handleUpdateStep}
          handleUpdateStepOrders={handleUpdateStepOrders}
          isLoading={isLoading}
          context={context}
          users={users}
          stepsWithErrors={stepsWithErrors}
        />
        {context === 'modal' && (
          <Footer
            context={context}
            handleSaveTemplate={handleSaveTemplate}
            applyTemplateToRecord={applyTemplateToRecord}
            workflows={workflows}
            setSelectedWorkflow={setSelectedWorkflow}
            selectedWorkflow={selectedWorkflow}
            setWorkflowModal={setWorkflowModal}
            stepsWithErrors={stepsWithErrors}
            setStepsWithErrors={setStepsWithErrors}
            steps={steps}
            setSteps={setSteps}
            setModalHasModifications={setModalHasModifications}
          />
        )}
      </Box>
    </Grid>
  );
};

export default WorkFlowMain;
