import { compareAsc, isWithinInterval } from 'date-fns';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState } from 'react';
import { db } from '../utils/indexedDB/indexeddb';
interface Maintenance {
  startDate: string;
  endDate: string;
  message: string;
  restart: boolean;
}

function useMaintenance() {
  const [maintenance, setMaintenance] = useState(false);
  const [scheduledMaintenance, setScheduledMaintenance] = useState(false);

  const data: Maintenance[] | undefined = useLiveQuery(() =>
    db.table('maintenance').toArray()
  );

  useEffect(() => {
    if (!data?.length) return;
    // true if the current date is between the two dates
    const isMaintenance = isWithinInterval(Date.now(), {
      start: new Date(data[0].startDate),
      end: new Date(data[0].endDate),
    });
    // 1 if the date is after the current date, otherwise -1
    const scheduledMaintenance = compareAsc(new Date(data[0].startDate), Date.now());

    if (scheduledMaintenance === 1) {
      setScheduledMaintenance(true);
      setMaintenance(false);
    }
    if (isMaintenance) {
      setMaintenance(true);
    }
  }, [data]);

  return {
    maintenance,
    scheduledMaintenance,
    data: data ? data[0] : null,
    setMaintenance,
    setScheduledMaintenance,
  };
}

export default useMaintenance;
