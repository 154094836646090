import { gql } from 'apollo-boost';

const gqlRecords = {
  GET_RECORD: gql`
    query getRecord($link: String!) {
      getRecord(link: $link) {
        records {
          id
          attachments {
            name
            type
            format
            getUrl
          }
          folderId {
            id
            primaryFolderFieldId
            name
            folderFields {
              id
              name
            }
          }
          fields {
            field {
              id
              name
              type
            }
            value
          }
          createdBy {
            firstName
            lastName
          }
          assignTo {
            firstName
            lastName
          }
          createdAt
        }
        organisationId
        error
      }
    }
  `,
  CREATE_SHARE: gql`
    mutation createShare($recordId: [ID!], $permissions: String!) {
      createShare(data: { records: $recordId, permissions: $permissions }) {
        url
        status
      }
    }
  `,
};

export default gqlRecords;
