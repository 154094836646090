import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import FontAwesomeIcon from '../../../components/FAIcon';
import { ToolbarMainProps } from '../types';
import { toBeApprovedByIsEmpty } from '../utils';
import TemplateDialog from './TemplateDialog';

const styles = {
  container: {
    backgroundColor: (theme: any) =>
      theme.palette.mode === 'dark' ? 'common.greyblueDarker' : 'common.greyblueDark',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: { sm: '0px 1px 0px 0px #ffffff96 inset', md: 'none' },
    flex: 1.2,
    overflow: 'hidden',
    minHeight: '31px',
  },
  button: {
    color: 'common.white',
    backgroundColor: 'common.greyblueDarker',
    fontSize: 11,
    height: 31,
    p: '0 10px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'common.white',
      color: 'common.greyblueDarker',
    },
  },
};

const ToolbarMain = ({
  handleSaveTemplate,
  handleSaveWorkflow,
  selectedWorkflow,
  modifiedTemplate,
  updateTemplate,
  applyTemplateToFolder,
  steps,
  setStepsWithErrors,
}: ToolbarMainProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    let check = toBeApprovedByIsEmpty(steps);
    if (check && check.length) {
      setStepsWithErrors(
        check.map((step) => ({
          stepOrder: step.stepOrder,
          error: 'Add users that need to approve this step.',
        }))
      );
    } else {
      setOpenModal(true);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: 'flex', flex: 8, justifyContent: 'center' }}>
        <Typography variant="caption" color="WHITE" sx={{ padding: '5px 15px' }}>
          WORKFLOW
        </Typography>
      </Box>
      <Stack flexDirection="row" sx={{ height: '100%' }}>
        {!selectedWorkflow && (
          <Button
            onClick={handleOpenModal}
            disabled={steps.length === 0}
            size="medium"
            sx={styles.button}
            startIcon={
              <Box>
                <FontAwesomeIcon icon="far fa-box" size={14} />
              </Box>
            }
          >
            Save template
          </Button>
        )}

        {modifiedTemplate.length > 0 && selectedWorkflow && (
          <Button
            size="medium"
            sx={styles.button}
            onClick={() => updateTemplate(selectedWorkflow)}
            startIcon={
              <Box>
                <FontAwesomeIcon icon="fas fa-save" size={14} />
              </Box>
            }
          >
            Update template
          </Button>
        )}
        {modifiedTemplate.length > 0 && selectedWorkflow && (
          <Button
            size="medium"
            sx={styles.button}
            onClick={handleOpenModal}
            startIcon={
              <Box>
                <FontAwesomeIcon icon="fas fa-save" size={14} />
              </Box>
            }
          >
            Save as new template
          </Button>
        )}
        {/* <Button
          onClick={
            selectedWorkflow
              ? () => applyTemplateToFolder(selectedWorkflow.id)
              : handleSaveWorkflow
          }
          disabled={selectedWorkflow ? false : true}
          size="medium"
          sx={styles.button}
          startIcon={
            <Box>
              <FontAwesomeIcon icon="fas fa-save" size={14} />
            </Box>
          }
        >
          {selectedWorkflow ? 'Apply workflow to folder' : 'Save workflow'}
        </Button> */}
      </Stack>
      <TemplateDialog
        handleSaveTemplate={handleSaveTemplate}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </Box>
  );
};

export default ToolbarMain;
