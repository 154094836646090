import { Box, IconButton } from '@mui/material';
import { useLazyQuery } from 'react-apollo';
import DrawerItem from './DrawerItem';
import { DrawerLabelProps } from './permissionsTypes';
import React, { useContext } from 'react';
import FontAwesomeIcon from '../../../../../components/FAIcon';
import { keyframes } from '@mui/system';

import { Context as StatusContext } from '../../../../../context/statusContext';
import {
  Context as AuthContext,
  AuthContextTypes,
} from '../../../../../context/authContext';
import { useLocalStorage } from '../../../../../hooks/useLocalStorage';
import {
  checkID,
  generateArr,
  generateContent,
  generateFetchData,
  hasItem,
} from './utils';
import { StatusContextTypes } from '../../../../../context/statusContextTypes';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const DrawerLabel = ({
  openChildren,
  i,
  item,
  index,
  setOpenChildren,
  setIndex,
  setContentChildren,
  setSchema,
  initial,
  schema,
  setGlobalState,
  setParentLabel,
  parentLabel,
  globalState,
  setParentItem,
  parentItem,
  setDropdownValue,
  dropdownValue,
  drawerNumber,
  setParentValue,
  parentValue,
  content,
}: DrawerLabelProps) => {
  if (initial) schema = item.schema;
  let component = item.component ? item.component : schema.component;
  let keyGenerator = item.key ? item.key : schema.key ? schema.key : () => '';
  let convertValue = item.convertValue
    ? item.convertValue
    : schema.convertValue
    ? schema.convertValue
    : () => '';

  const { state } = useContext(StatusContext) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;

  let fetchEndpoint = schema?.fetchEndpoint ? schema?.fetchEndpoint(isSuser) : null;

  const [getData, { loading }] = useLazyQuery(fetchEndpoint, {
    fetchPolicy: 'no-cache',
    variables:
      schema?.endpointVariables && schema?.endpointVariables(isSuser, state, selOrg),
    onCompleted: (data) => {
      if (schema && schema.dataName) {
        generateFetchData(
          data,
          schema,
          isSuser,
          item,
          setSchema,
          setContentChildren,
          state.currentOrg
        );
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });
  const handleClose = () => {
    // when closing a drawer, the item in the parent label and content must be removed
    setContentChildren([]);
    setParentValue(null);
    let content = generateContent(
      item,
      schema,
      schema?.children?.content || item.children.content,
      isSuser,
      state.currentOrg
    );
    setSchema((prev: any) => {
      return {
        ...prev,
        children: { ...(prev.children ? prev.children : item.children), content },
      };
    });
    setParentLabel((prev: Array<string>) => {
      let arrSlice = prev.slice(0, drawerNumber);
      initial ? (prev = ['']) : (prev = arrSlice);
      return prev;
    });
  };

  const handleOpen = () => {
    if (!item.keepParentItem && !schema.keepParentItem) setParentItem(item);
    let index: number, arr: any[], value: any;
    setGlobalState((prev: any) => {
      let newGlobalState = generateArr(
        component,
        prev,
        item,
        parentLabel,
        i,
        index,
        schema,
        keyGenerator,
        convertValue,
        value,
        parentItem,
        setGlobalState,
        globalState,
        content,
        arr
      );
      return newGlobalState;
    });
    setParentLabel((prev: Array<string>) => {
      if (prev[0] === '') {
        prev[0] = item.id ? item.id : schema.key;
      } else {
        //check if the item already exists, so as not to add it again
        let index = checkID(arr, item.id, item.keyID || schema.keyID || 'id');
        let has = hasItem(prev, item);
        !has && prev.push(index.toString());
      }
      return prev;
    });
    setIndex(i);
    if (schema.contentOrigin === 'fetch') {
      //if the source of the content is of type fetch, it makes the request
      getData();
    } else {
      let content = generateContent(
        item,
        schema,
        item?.children?.content || schema?.children?.content,
        isSuser,
        state.currentOrg
      );
      setSchema((prev: any) => {
        return {
          ...prev,
          children: { ...(item.children ? item.children : prev.children), content },
        };
      });

      if (content) setContentChildren(content);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let { type } = e.target as HTMLInputElement;
    if (type === 'checkbox' || item.hasChildren === false) return;
    initial && setSchema(item.schema);
    !openChildren ? handleOpen() : handleClose();
    setOpenChildren((prev) => !prev);
  };

  if (item.hide || item?.schema?.hide) {
    return null;
  }

  return (
    <Box
      sx={{
        bgcolor: openChildren && i === index ? 'common.greyblueDark' : 'white',
        display: 'flex',
        padding: '2px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:nth-of-type(even)': {
          bgcolor:
            openChildren && i === index
              ? 'common.greyblueDark'
              : state.paletteMode === 'light'
              ? '#cccccc34'
              : '#cccccc05',
        },
        '&:hover': { cursor: item.hasChildren === false ? 'default' : 'pointer' },
      }}
      onClick={(e) => handleClick(e)}
    >
      <DrawerItem
        setGlobalState={setGlobalState}
        item={item}
        i={i}
        index={index}
        openChildren={openChildren}
        schema={schema}
        parentLabel={parentLabel}
        component={component}
        globalState={globalState}
        setParentLabel={setParentLabel}
        parentItem={parentItem}
        setDropdownValue={setDropdownValue}
        dropdownValue={dropdownValue}
        setParentValue={setParentValue}
        parentValue={parentValue}
        initial={initial}
        content={content}
      />
      {loading ? (
        <Box
          sx={{
            border: '2px solid #ffffff',
            borderLeftColor: 'transparent',
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            animation: `${spin} 1s linear infinite`,
            ml: 2,
          }}
        />
      ) : (schema.children || item.children) && item.hasChildren !== false ? (
        <IconButton
          disabled={item.disabled ? item.disabled : false}
          sx={{
            flex: 1,
            padding: 1,
            margin: 0,
            marginRight: 1,
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          {openChildren && i === index ? (
            <FontAwesomeIcon
              icon="fas fa-chevron-left"
              size={12}
              color={
                (openChildren && i === index) || state.paletteMode === 'dark'
                  ? '#fff'
                  : 'common.greyblueDark'
              }
              padding={0}
            />
          ) : (
            <FontAwesomeIcon
              icon="fas fa-chevron-right"
              size={12}
              color={
                (openChildren && i === index) || state.paletteMode === 'dark'
                  ? '#fff'
                  : 'common.greyblueDark'
              }
              padding={0}
            />
          )}
        </IconButton>
      ) : null}
    </Box>
  );
};

export default DrawerLabel;
