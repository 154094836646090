import React, { forwardRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Button,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { FormValues } from './UpdateTab';
import FontAwesomeIcon from '../../components/FAIcon';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AlertDialogProps {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleSubmit: () => void;
  formValues?: FormValues;
}

const AlertDialog = ({
  open,
  handleClose,
  handleSubmit,
  formValues,
  title,
}: AlertDialogProps) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          backgroundColor: '#FBC441',
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <FontAwesomeIcon icon="fas fa-exclamation-triangle" size={14} />
        <Typography variant="button">{title}</Typography>
        <FontAwesomeIcon icon="fas fa-exclamation-triangle" size={14} />
      </DialogTitle>
      {formValues && (
        <DialogContent
          sx={{
            paddingTop: '20px!important',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography>
            <Typography variant="button">Start date</Typography>:
            {` ${new Date(formValues.startDate).toLocaleString().slice(0, -3)} hs`}
          </Typography>
          <Typography>
            <Typography variant="button">End date</Typography>:
            {` ${new Date(formValues.endDate).toLocaleString().slice(0, -3)} hs`}
          </Typography>
          <Typography>
            <Typography variant="button">Message</Typography>: {`${formValues.message}`}
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ backgroundColor: 'common.greyblueDarker' }}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
