import MasterTable from '../../components/masterTable/MasterTable';
import { Row, SelectedFolder } from '../../components/masterTable/masterTableTypes';
import ErrorHandler from '../../utils/errorHandler';
import { Box } from '@mui/material';
import { Record } from '../search/types';
import { GridSortModel } from '@mui/x-data-grid';
import { useFormContext } from 'react-hook-form';
import { MutableRefObject } from 'react';

interface TableAreaProps {
  rows: Row[] | Record[];
  cols: any;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  totalCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  selectedFolder: SelectedFolder | null;
  loading: boolean;
  setSorting: any;
  buttonRef: MutableRefObject<null | HTMLButtonElement>;
}

export default function TableArea({
  rows,
  cols,
  setLimit,
  limit,
  totalCount,
  currentPage,
  setCurrentPage,
  selectedFolder,
  loading,
  setSorting,
  buttonRef,
  exportQuery,
}: TableAreaProps) {
  const { watch } = useFormContext();
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (!newModel.length) {
      return;
    } else {
      setSorting(newModel[0].field + '|' + newModel[0].sort);
    }
  };
  if (rows) {
    let selectedTemplate = watch('selectedTemplate');
    return (
      <Box style={{ height: '100%', width: '100%' }}>
        <ErrorHandler>
          <MasterTable
            rows={rows}
            defaultVisibleRows={cols}
            setPageSize={setLimit}
            pageSize={limit}
            rowCount={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            defaultTableOptions={{
              edit: false,
              quickView: false,
              search: true,
            }}
            permissions={{
              collectionBin: selectedTemplate && selectedTemplate.type === 'workflow',
            }}
            loading={loading}
            selectedFolder={selectedFolder}
            onSortModelChange={handleSortModelChange}
            selectedTemplate={selectedTemplate}
            buttonReportRef={buttonRef}
            exportQuery={exportQuery}
          />
        </ErrorHandler>
      </Box>
    );
  } else {
    return null;
  }
}
