import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { debounce, update } from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { Context as RTCContext } from '../../context/RTCContext';
import { RTCContextTypes } from '../../context/RTCContextTypes';
import { Context as StatusContext } from '../../context/statusContext';
import { StatusContextTypes } from '../../context/statusContextTypes';
import { Context as TableContext } from '../../context/tableContext';
import {
  BlockTypeEnum,
  OcrTemplateInput,
  PagesToReadEnum,
  TextTypeEnum,
} from '../../hooks/OCRTemplateSetup/types';
import { useOCRTemplate } from '../../hooks/OCRTemplateSetup/useOCRTemplate';
import { useFabricContext } from '../../hooks/useFabricCanvas';
import useFolder from '../../hooks/useFolders';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import useUserStorageData from '../../hooks/user/userUserStorageData';
import gqlIndexes from '../../utils/gql/gqlIndexes';
import RTC from '../../utils/socketComm';
import utils from '../../utils/utils';
import FontAwesomeIcon from '../FAIcon';
import GeneralSnackbar, { HandleClose, StatusProps } from '../SnackBar';
import ModalContentBase from '../modal/ModalContentBase';
import ModalWrapper from '../modal/ModalWrapper';
import { InputWithActions } from './InputWithActions';
import OCRSetupHeaderValuePairs from './OCRSetupHeaderValuePairs';
import { StyledActionButton } from './StyledComponents';
import ConfirmIdentifiersInput from './ConfirmIdentifiersInput';
import { db } from '../../utils/indexedDB/indexeddb';
import { deleteTypename } from './AdvancedSettingsModal/AdvancedSettingsModal';

const DEFAULT_VALIDATE = {
  open: false,
  message: '',
};

const label = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'common.greyblueDark',
  borderRadius: '5px',
  color: 'common.white',
  padding: '0 20px',
};

const CancelButton = styled(Button)(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.common.negativeDark,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.common.negative,
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.common.greyblueDark,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.common.greyblueDarker,
  },
}));

interface OCRTemplateSetupProps {
  template: any;
  ocrScanRecords: (args?: { preview: boolean; selected: never[] }) => void;
}

const OCRTemplateSetup = ({ template, ocrScanRecords }: OCRTemplateSetupProps) => {
  const { id } = useParams();
  const {
    canvasRef,
    objects,
    selectedObject,
    setSelectedObject,
    setCanDraw,
    boundingBox,
    deleteObject,
    deleteAllObjects,
    action,
    folderFieldId,
    inputType,
    ocrTemplateFolderField,
    setOcrTemplateFolderField,
    analyzedResponseObjects,
    identifierId,
    setIdentifierId,
    textractId,
    validateAreaData,
    setValidateAreaData,
    setAction,
    modifiedFields,
    setModifiedFields,
    setTemplate,
    valuesOnlyFields,
    setValuesOnlyFields,
    togglePanning,
    setFolderFieldId,
    setInputType,
    backdrop,
    setBackdrop,
    templateIdentifiers,
    setTemplateIdentifiers,
    modTempIdentifiers,
    setModTempIdentifiers,
    setModIdentifiersIds,
    setObjects,
    totalPages,
    currentPage,
    setCurrentPage,
    setRecordId,
  } = useFabricContext();

  const [nameInputValue, setNameInputValue] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    createTemplate,
    updateTemplate,
    updateTemplateIdentifiers,
    updateTemplateFieldDefinitions,
    removeFieldDefinitions,
    deleteTemplate,
  } = useOCRTemplate();
  const { userSettings } = useUserStorageData();
  const [userData, setUserData] = useLocalStorage('userData');
  const {
    state,
    setTextractId,
    setOcrTemplate: setRtcOcrTemplate,
    setIdentifiersResponse,
    setAnalyzedArea,
    setValidateOCRAreaResponse,
  } = useContext(RTCContext) as RTCContextTypes;
  const {
    state: { selectedRecord, screen },
  } = useContext(StatusContext) as StatusContextTypes;

  const {
    fields: folderFields,
    folder,
    setFolder,
  } = useFolder(screen === 'indexing' ? userSettings.lastIndexedFolder : id);
  const [showFields, setShowFields] = useState(false);
  const [ocrTemplate, setOcrTemplate] = useState(null);
  const [ocrTemplateLoader, setOcrTemplateLoader] = useState(true);
  const [ocrTemplateDetails, setOcrTemplateDetails] = useState(null);
  const tableContext = useContext(TableContext);
  const { state: tableContextState } = tableContext;
  const [snackbar, setSnackbar] = useState<StatusProps>({
    status: false,
    snackbarMessage: '',
    severity: 'success',
  });
  const [headerValuePairs, setHeaderValuePairs] = useState<any>([]);

  const [validateAreaDialog, setValidateAreaDialog] = useState(DEFAULT_VALIDATE);
  const validate = useRef(false);
  const [confirmModal, setConfirmModal] = useState({});
  const [identifiersToFonfirm, setIdentifiersToConfirm] = useState<any>([]);
  const [modifiedIdentifiers, setModifiedIdentifiers] = useState<any>([]);

  const message = (res: any) => {
    if (res && !res.error && res.ocrTemplate) {
      setOcrTemplate(res.ocrTemplate);
      setSnackbar({
        status: true,
        snackbarMessage: 'The template has been successfully updated.',
        severity: 'success',
      });
    }

    if (res && res.error) {
      setSnackbar({
        status: true,
        snackbarMessage: "Something went wrong! The template hasn't been updated.",
        severity: 'error',
      });
    }
  };

  const onCreate = async () => {
    const tempIdentifiers = templateIdentifiers.filter((el) => el.identifierText !== '');

    if (!tempIdentifiers.length || tempIdentifiers.length < 1) {
      setSnackbar({
        status: true,
        snackbarMessage: 'Please add at least one identifier.',
        severity: 'error',
      });
      return;
    }

    const variables = {
      templateName: nameInputValue,
      folderId: userSettings.lastIndexedFolder,
      templateIdentifiers: tempIdentifiers,
      imageUsedAsTemplate: selectedRecord?.attachments[0]?.name,
      textractId,
    };

    const res = await createTemplate(variables);

    message(res);

    setShowFields(true);
  };

  const updateOCRTemplate = async () => {
    const ocrTemplateUpdates: OcrTemplateInput = {
      templateName: nameInputValue,
      folderId: userSettings.lastIndexedFolder,
      imageUsedAsTemplate: selectedRecord?.attachments[0]?.name,
      textractId,
    };

    window.log('ocrTemplateUpdates', ocrTemplateUpdates);
    const res = await updateTemplate({
      id: ocrTemplate?._id || ocrTemplate?.id,
      ocrTemplateUpdates,
    });

    message(res);
  };

  const updateTempIdentifiers = async () => {
    if (!ocrTemplate?._id && !ocrTemplate?.id) return;

    const newData = templateIdentifiers.filter((el) => el.identifierText !== '');
    const variables = {
      templateId: ocrTemplate?._id || ocrTemplate?.id,
      templateIdentifiers: newData,
    };

    window.log('updateTemplateIdentifiers', variables);
    const res = await updateTemplateIdentifiers(variables);

    setModTempIdentifiers(false);

    message(res);
  };

  const updateAdvancedSettings = async (fieldId: string) => {
    window.log('update-advanced-settings');
    const currentHVP = headerValuePairs.find((el) => el.field.id === fieldId);
    const savedFieldDefinition = ocrTemplateDetails?.templateOcrFieldDefinitions?.find(
      (el) => el.folderField === fieldId
    );

    if (!savedFieldDefinition) return;

    const savedTextractRequirements = savedFieldDefinition.textractRequirements;
    const savedHVP = savedFieldDefinition.textractRequirements.headerValuePair[0];

    const textractRequirements = {
      textType: savedTextractRequirements.textType,
      blockType: savedTextractRequirements.blockType,
      confidenceMinimum: savedTextractRequirements.confidenceMinimum,
      headerValuePair: {
        textType: savedHVP.textType,
        blockType: savedHVP.blockType,
        textToFind: savedHVP.textToFind,
        boxToFindValue: {
          top: savedHVP.boxToFindValue.top,
          left: savedHVP.boxToFindValue.left,
          height: savedHVP.boxToFindValue.height,
          width: savedHVP.boxToFindValue.width,
        },
        textToFindConfidenceMinimum: savedHVP.textToFindConfidenceMinimum,
        specialProcessing: currentHVP.specialProcessing,
      },
    };

    if (savedTextractRequirements.boundingBox) {
      textRequirements.boundingBox = {
        top: savedTextractRequirements.boundingBox.top,
        left: savedTextractRequirements.boundingBox.left,
        height: savedTextractRequirements.boundingBox.height,
        width: savedTextractRequirements.boundingBox.width,
      };
    }

    if (currentHVP.pageToFindValue) {
      textractRequirements.headerValuePair.pageToFindValue = currentHVP.pageToFindValue;
    }
    const ocrFieldDefinitions = [
      {
        folderField: fieldId,
        textractRequirements,
      },
    ];
    // obj?.headerValues.map((el) => {
    // }

    const variables = {
      templateId: ocrTemplate?._id || ocrTemplate?.id,
      ocrFieldDefinitions: ocrFieldDefinitions,
    };

    console.log('variables', variables);
    if (!variables) return;

    const res = await updateTemplateFieldDefinitions(variables);

    setModifiedFields([]);
    validate.current = false;
    message(res);
  };

  const updatefieldDefinitions = async () => {
    window.log('Update field definitions');
    const obj = headerValuePairs.find((el) => el.field.id === modifiedFields[0]);

    const savedFieldDefinition =
      ocrTemplateDetails?.templateOcrFieldDefinitions?.find(
        (el) => el.folderField === obj.field.id
      ) || {};

    const ocrFieldDefinitions = obj?.headerValues.map((el) => {
      let bounds;
      window.log('analyzed', analyzedResponseObjects);
      window.log(ocrTemplateDetails);
      if (analyzedResponseObjects[obj.field.id]) {
        bounds = analyzedResponseObjects[obj.field.id][0].valueBox;
      } else {
        const headerValuePair =
          savedFieldDefinition.textractRequirements.headerValuePair[0];
        if (headerValuePair.boxToFindValue) {
          const { height, left, top, width } = headerValuePair.boxToFindValue;
          bounds = { height, width, left, top };
        }
      }

      // @ts-ignore

      const textractRequirements: TextractRequirementsInput = {
        ...savedFieldDefinition.textractRequirements,
        textType: TextTypeEnum.PRINTED,
        blockType: BlockTypeEnum.WORD,
        confidenceMinimum: obj.suggestedMinimumConfidence,
      };

      if (boundingBox.current && !validate.current) {
        textractRequirements.boundingBox =
          el.textToFind !== '' ? null : boundingBox.current;
      } else if (boundingBox.current && validate.current) {
        textractRequirements.boundingBox = boundingBox.current;
      }

      if (el.textToFind !== '' && el.value.length > 0) {
        textractRequirements.regexMatch = '.*';
        textractRequirements.headerValuePair = {
          ...savedFieldDefinition.textractRequirements?.headerValuePair[0],
          textType: TextTypeEnum.PRINTED,
          blockType: BlockTypeEnum.WORD,
          textToFind: el.textToFind,
          boxToFindValue: bounds,
          textToFindConfidenceMinimum: obj.suggestedMinimumConfidence,
          specialProcessing: obj.specialProcessing,
        };

        if (
          !textractRequirements.headerValuePair.pageToFindValue ||
          textractRequirements.headerValuePair.pageToFindValue === ''
        ) {
          if (!obj.pageToFindValue && currentPage === 1) {
            textractRequirements.headerValuePair.pageToFindValue = 'FRONT';
          } else if (currentPage === totalPages) {
            textractRequirements.headerValuePair.pageToFindValue = 'BACK';
          } else if (obj.pageToFindValue === 'ANY') {
            textractRequirements.headerValuePair.pageToFindValue = 'ANY';
          }
        }
      } else {
        textractRequirements.regexMatch = '.*';
        textractRequirements.headerValuePair = null;
      }

      let updatedTextractRequirements = deleteTypename(textractRequirements, '_id');

      return {
        folderField: obj.field.id,
        textractRequirements: updatedTextractRequirements,
      };
    });

    const variables = {
      templateId: ocrTemplate?._id || ocrTemplate?.id,
      ocrFieldDefinitions: ocrFieldDefinitions,
    };

    if (!variables) return;

    console.log('variables', variables);
    const res = await updateTemplateFieldDefinitions(variables);

    setModifiedFields([]);
    validate.current = false;
    message(res);
  };

  const removeFromIndexedDB = async (fieldId: string) => {
    await db
      .table('indexingScreen')
      .where('recordID')
      .equals(selectedRecord?.id)
      .modify((record) => {
        delete record.fields[fieldId];
        return record;
      });
  };

  const handleRemoveFields = async (fieldId: string, inputType: string) => {
    if (inputType === 'main') {
      const res = await removeFieldDefinitions({
        templateId: ocrTemplate?._id || ocrTemplate?.id,
        folderFieldID: fieldId,
      });
      removeFromIndexedDB(fieldId);
      message(res);
    } else if (inputType === 'header' || inputType === 'value') {
      const hvpIndex = headerValuePairs.findIndex((el: any) => el.field.id === fieldId);
      const newHVP = [...headerValuePairs];

      if (inputType === 'header') {
        newHVP[hvpIndex].headerValues[0].textToFind = '';
      } else if (inputType === 'value') {
        newHVP[hvpIndex].headerValues[0].value = '';
      }

      setHeaderValuePairs(newHVP);
    }
  };

  const handleDelete = async () => {
    const id = ocrTemplate?._id || ocrTemplate?.id;
    const res = await deleteTemplate({ id });
    if (res && !res.error) {
      cleanup();

      setSnackbar({
        status: true,
        snackbarMessage: 'The template has been successfully deleted.',
        severity: 'success',
      });
    }

    if (res && res.error) {
      setSnackbar({
        status: true,
        snackbarMessage: "Something went wrong! The template hasn't been deleted.",
        severity: 'error',
      });
    }
  };

  const handleCancel = () => {
    const recordId = selectedRecord?.id;

    RTC.ocrRecord({
      recordId: recordId,
      folderId: screen === 'indexing' ? userSettings.lastIndexedFolder : id,
    });
  };

  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ status: false, snackbarMessage: '', severity: 'success' });
  };

  const debouncedChange = useCallback(
    debounce((value) => {
      setNameInputValue(value);
    }, 300),
    []
  );

  // // If there are no identifers adds only one when the name input has more than 3 characters
  // useEffect(() => {
  //   if (nameInputValue?.length >= 3 && templateIdentifiers?.length === 0) {
  //     setTemplateIdentifiers((curr) => [...curr, { identifierText: '' }]);
  //   }
  // }, [nameInputValue]);

  useEffect(() => {
    if (!folder || !folderFields?.length) return;
    const field = folderFields.find(
      (el) => el.id === folder.apProcessingSettings?.ocrTemplateNameFolderFieldId
    );

    if (!field) {
      setSnackbar({
        status: true,
        snackbarMessage:
          'Please select an OCR Template Field for this folder before continue',
        severity: 'warning',
      });
      return;
    }

    setOcrTemplateFolderField(field);
  }, [folder, folderFields]);

  useEffect(() => {
    if (nameInputValue && nameInputValue.length >= 3) {
      setIsExpanded(true);
      setShowFields(true);
    }
  }, [nameInputValue]);

  // This is the first useffect that should run, to get the textract id
  useEffect(() => {
    const recordId = selectedRecord?.id;
    setRecordId(recordId);
    RTC.ocrRecord({
      recordId: recordId,
      folderId: screen === 'indexing' ? userSettings.lastIndexedFolder : id,
    });
  }, [selectedRecord?.id]);

  // This sets only the value for the template identifiers
  useEffect(() => {
    if (state?.ocrIdentifiersResponse && state?.ocrIdentifiersResponse?.length > 0) {
      // This gets the identifiers to confirm from the response
      if (folderFieldId === ocrTemplateFolderField?.id) {
        if (state?.ocrIdentifiersResponse?.length > 1) {
          const newIdentifiers = state.ocrIdentifiersResponse.map((el, index) => {
            return {
              identifierText: el.text,
              boundsToFindIdentifier: boundingBox.current,
            };
          });

          setIdentifiersToConfirm(newIdentifiers);
          setConfirmModal({ open: true, message: '' });
        } else {
          const updateIdentifiers = [...templateIdentifiers];
          updateIdentifiers[identifierId].identifierText =
            state?.ocrIdentifiersResponse[0].text;
          updateIdentifiers[identifierId].boundsToFindIdentifier = boundingBox.current;
          setTemplateIdentifiers(updateIdentifiers);
          setModTempIdentifiers(true);
        }

        return;
      }
    }
  }, [state?.ocrIdentifiersResponse]);

  const modifyRecordFromIndexedDB = async (
    recordID: string,
    fieldId: string,
    value: any
  ) => {
    const folderField = folderFields?.find((ff) => ff.id === fieldId);
    const fieldType = utils.determineFolderFieldType(folderField?.type || 'none');
    let record = await db.table('indexingScreen').get({ recordID });
    if (record) {
      return await await db
        .table('indexingScreen')
        .where({ recordID })
        .modify((rec) => {
          rec.fields = { ...rec.fields, [fieldId]: { id: fieldId, [fieldType]: value } };
        });
    } else {
      let newStatus = {
        recordID,
        status: 'unindexed',
        fields: { [fieldId]: { id: fieldId, [fieldType]: value } },
      };
      return await db.table('indexingScreen').add(newStatus);
    }
  };

  // This sets the text to find and value for the header value pairs
  useEffect(() => {
    console.log('stateAnalyzed', state?.analyzedArea);
    console.log('action', action);
    if (
      folderFieldId !== ocrTemplateFolderField?.id &&
      state?.analyzedArea &&
      state?.analyzedArea?.SuggestedHeaderValuePair
    ) {
      const responseValue =
        action === 'update-field-value'
          ? state.analyzedArea?.AllTextAsString
          : state.analyzedArea?.SuggestedHeaderValuePair?.ValueTextResults;

      const responseHeader =
        action === 'update-field-header'
          ? state.analyzedArea?.AllTextAsString
          : state.analyzedArea?.SuggestedHeaderValuePair?.HeaderTextResults;

      console.log('responseValue', responseValue);
      console.log('responseHeader', responseHeader);
      const newHeaderValues = headerValuePairs.map((el, index) => {
        if (el.field.id === folderFieldId) {
          const headerValues = [...el.headerValues];
          if (action === 'update-field-value' || action === 'set-field-definition') {
            headerValues[0].value = responseValue;
          }
          if (action === 'update-field-header' || action === 'set-field-definition') {
            headerValues[0].textToFind = responseHeader;
          }

          return {
            ...el,
            main:
              action === 'update-field-value' || action === 'set-field-definition'
                ? responseValue
                : el.main,
            headerValues: headerValues,
            suggestedMinimumConfidence: state.analyzedArea?.SuggestedMinimumConfidence,
            specialProcessing:
              state.analyzedArea?.SuggestedHeaderValuePair?.SpecialProcessing,
          };
        } else {
          return el;
        }
      });

      // Set value to indexedDB
      selectedRecord &&
        modifyRecordFromIndexedDB(selectedRecord.id, folderFieldId, responseValue);

      setHeaderValuePairs(newHeaderValues);

      const validateData = {
        textractId: textractId,
        pageIndex: currentPage - 1,
        text: state.analyzedArea?.SuggestedHeaderValuePair?.HeaderTextResults,
      };

      if (action === 'update-field-header') {
        validateData.text = state.analyzedArea?.TextResults?.All_Text_As_String;
      }

      console.log('validateData', validateData);

      setValidateAreaData(validateData);
      // RTC.validateOcrArea(validateData);
      RTC.textOnDocumentCount(validateData);
    }
  }, [state?.analyzedArea]);

  // Populates the headerValuesPairs array to be rendered
  const addHeaderValuePairs = () => {
    if (headerValuePairs && headerValuePairs?.length > 0) return;
    const defaultHeaderValuePair = folderFields
      ?.filter(
        (field) =>
          field?.id !== folder?.apProcessingSettings?.ocrTemplateNameFolderFieldId &&
          field?.id !== 'CreatedAtId' &&
          field?.name !== 'createdAt'
      )
      .map((el, index) => {
        return {
          field: el,
          main: '',
          specialProcessing: 'NONE',
          headerValues: [
            {
              textToFind: '',
              value: '',
            },
          ],
        };
      });

    setHeaderValuePairs(defaultHeaderValuePair);
  };

  // Cleanup function perhaps not all of this is needed
  const cleanup = () => {
    setOcrTemplate(null);
    setTemplate(null);
    setOcrTemplateDetails(null);
    setTemplateIdentifiers([]);
    setNameInputValue('');
    setIsExpanded(false);
    setShowFields(false);
    setIdentifierId(0);
    setValuesOnlyFields([]);
    setModTempIdentifiers(false);
    setSelectedObject('');
    setTextractId(null);
    setRtcOcrTemplate({ template: false });
    setIdentifiersResponse(null);
    setAnalyzedArea(null);
    setValidateOCRAreaResponse(null);
    deleteAllObjects();
    setCanDraw(false);
    setAction('');
    setBackdrop(false);
    setObjects([]);
  };

  // If a template is returned from the db, set the template and template details in the state.
  useLayoutEffect(() => {
    if (screen !== 'indexing') return;
    if (!state.ocrTemplate) return setOcrTemplateLoader(false); // If ocrTemplate is null, it means that there is no template saved.
    if (state.ocrTemplate?.template === false) return; // If template is false, we don't know yet if there is a template saved

    addHeaderValuePairs();
    setOcrTemplate(state.ocrTemplate?.template);
    setOcrTemplateDetails(state.ocrTemplate?.templateDetails);
    setTemplate(state.ocrTemplate?.template);
  }, [state.ocrTemplate]);

  // Here the template passed is used for the folders screen
  useLayoutEffect(() => {
    if (screen === 'indexing') return;
    setOcrTemplateLoader(false);
    if (!template) {
      return;
    }

    addHeaderValuePairs();
    setOcrTemplate(template);
    setTemplate(template);
    setOcrTemplateDetails(template.templateDetails);
  }, [template]);

  // If a template is returned from the db, set the identifiers.
  useEffect(() => {
    if (ocrTemplate) {
      window.log('Template found!', ocrTemplate);
      setNameInputValue(ocrTemplate?.templateName);
      if (ocrTemplate.templateIdentifiers.length > 0) {
        const dbIdentifiers = ocrTemplate?.templateIdentifiers.map((el, index) => {
          if (!el) return;

          return { identifierText: el.identifierText };
        });
        setTemplateIdentifiers(dbIdentifiers);
      }
    }
  }, [ocrTemplate]);
  // This sets header values from the returned template if any
  useEffect(() => {
    if (ocrTemplateDetails && headerValuePairs?.length > 0) {
      const recordIds: any = [];

      const newHeaderValuePairs = [...headerValuePairs];

      headerValuePairs.forEach((el, index) => {
        recordIds.push({
          recordId: el.folderField,
          pageEnum: 'Front_Only',
        });
        const obj = ocrTemplateDetails.templateOcrFieldDefinitions.find(
          (fieldDef) => fieldDef.folderField === el.field.id
        );
        if (obj && obj.textractRequirements.headerValuePair) {
          const textToFind = obj.textractRequirements.headerValuePair[0].textToFind || '';
          const specialProcessing =
            obj.textractRequirements.headerValuePair[0].specialProcessing || '';
          const pageToFindValue =
            obj.textractRequirements.headerValuePair[0].pageToFindValue || '';

          newHeaderValuePairs[index].headerValues[0].textToFind = textToFind;
          newHeaderValuePairs[index].headerValues[0].value = el.headerValues[0].value;
          newHeaderValuePairs[index].specialProcessing = specialProcessing;
          if (pageToFindValue) {
            newHeaderValuePairs[index].pageToFindValue = pageToFindValue;
          }
          newHeaderValuePairs[index].main = el.main;
        } else if (obj && !obj.textractRequirements.headerValuePair) {
          setValuesOnlyFields((curr) => [...curr, el.field.id]);
        } else {
          newHeaderValuePairs[index].headerValues[0].textToFind = '';
          newHeaderValuePairs[index].headerValues[0].value = '';
          newHeaderValuePairs[index].main = '';
        }
      });

      setHeaderValuePairs(newHeaderValuePairs);

      if (!recordIds.length) return;
    }
  }, [ocrTemplateDetails, folder, userData]);

  const handleCancelValidateAreaModal = () => {
    setValidateAreaDialog(DEFAULT_VALIDATE);
    deleteObject(selectedObject);
  };
  const handleSubmitValidateAreaModal = () => {
    setAction('validate-ocr-area');
    validate.current = true;
    setValidateAreaDialog(DEFAULT_VALIDATE);
  };

  useEffect(() => {
    if (!ocrTemplate || !modTempIdentifiers) return;

    updateTempIdentifiers();
  }, [templateIdentifiers]);

  useEffect(() => {
    if (!modifiedFields?.length) return;

    function validateOCRResponse(data) {
      if (data.error && !data.error.includes('NO INSTANCES OF')) {
        setValidateAreaDialog({
          open: true,
          message:
            'Please draw the general area where you expect to find the value, make sure no other instance of that text is inside the area you draw',
        });
      } else {
        updatefieldDefinitions();
      }
    }

    function textOnDocumentCountResponse(count: number) {
      if (count > 1) {
        setValidateAreaDialog({
          open: true,
          message:
            'Please draw the general area where you expect to find the value, make sure no other instance of that text is inside the area you draw',
        });
      } else if (count === 1) {
        updatefieldDefinitions();
      }
    }

    const header = state?.analyzedArea?.SuggestedHeaderValuePair.HeaderTextResults;
    console.log('header', header);
    if (header === '') {
      updatefieldDefinitions();
    } else {
      // RTC.socket.on('validate-ocr-area-response', validateOCRResponse);
      RTC.socket.on('text-on-document-count-response', textOnDocumentCountResponse);
    }

    if (action === 'update-field-value') {
      updatefieldDefinitions();
    }

    return () => {
      // RTC.socket.off('validate-ocr-area-response', validateOCRResponse);
      RTC.socket.off('text-on-document-count-response', textOnDocumentCountResponse);
    };
  }, [modifiedFields]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  const handleBackdropClose = () => {
    setBackdrop(false);
  };

  useEffect(() => {
    const existRecordInIDB = async () => {
      return await db.table('indexingScreen').get({ recordID: selectedRecord?.id });
    };

    const scanRecords = async () => {
      const record = await existRecordInIDB();
      if (
        ocrTemplateFolderField &&
        nameInputValue &&
        nameInputValue.length >= 3 &&
        templateIdentifiers.length > 0 &&
        ocrScanRecords &&
        !record
      ) {
        console.log('OCR Scanning has been executed');
        ocrScanRecords({ preview: true, selected: [selectedRecord.id] });
      }
    };

    scanRecords();
  }, [ocrTemplateFolderField, nameInputValue]);

  const handleConfirmCancel = () => {
    setConfirmModal({ open: false, message: '' });
    setIdentifiersToConfirm([]);
    const identifiers = templateIdentifiers.filter((el) => el.identifierText !== '');
    setTemplateIdentifiers(identifiers);
    deleteObject(selectedObject);
  };

  const handleConfirmSubmit = () => {
    const updateIdentifiers = [...templateIdentifiers];

    if (!updateIdentifiers[identifierId]) {
      identifiersToFonfirm.forEach((el) => {
        updateIdentifiers.push({
          identifierText: el.identifierText,
          boundsToFindIdentifier: el.boundsToFindIdentifier,
        });
      });
    } else {
      identifiersToFonfirm.forEach((el, index) => {
        if (index === 0) {
          updateIdentifiers[identifierId].identifierText = el.identifierText; // corrected typo
          updateIdentifiers[identifierId].boundsToFindIdentifier =
            el.boundsToFindIdentifier;
        } else {
          updateIdentifiers.push({
            identifierText: el.identifierText,
            boundsToFindIdentifier: el.boundsToFindIdentifier,
          });
        }
      });
    }

    setTemplateIdentifiers(updateIdentifiers);
    setModTempIdentifiers(true);
    setConfirmModal({ open: false, message: '' });
  };

  const identifiersValueChange = useCallback(
    debounce((value, index) => {
      setModifiedIdentifiers(
        templateIdentifiers.map((item, idx) =>
          idx === index ? { ...item, identifierText: value } : item
        )
      );
      setModIdentifiersIds((curr) => (curr.includes(index) ? curr : [...curr, index]));
    }, 300),
    [templateIdentifiers]
  );

  const handleModifiedIdentifiers = () => {
    setTemplateIdentifiers(modifiedIdentifiers);
    setModTempIdentifiers(true);
    setModIdentifiersIds([]);
    setCanDraw(false);
    setSelectedObject('');
  };

  return (
    <>
      <Paper
        sx={{
          height: '100%',
          position: 'relative',
        }}
      >
        <Backdrop
          data-testid="ocr-template-loader"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backdropFilter: 'blur(2px)',
            height: '100%',
            width: '100%',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={ocrTemplateLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ backgroundColor: 'common.greyblueDark', padding: '5px 10px' }}>
          <Typography sx={{ color: 'common.white', textTransform: 'uppercase' }}>
            OCR Template Setup
          </Typography>
        </Box>
        <Stack justifyContent="space-between" p={2} height={'calc(100% - 30px)'}>
          <Stack height={'90%'} sx={{ gap: 2 }}>
            <Typography fontWeight={500}>Template identifier</Typography>
            <Accordion
              expanded={isExpanded}
              sx={{
                '& .MuiAccordionSummary-root': { cursor: 'auto !important' },
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light' ? '#f8f8f8' : '#16212f80',
              }}
            >
              <AccordionSummary
                expandIcon={
                  <IconButton
                    aria-label="expand-card"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded((curr) => !curr);
                    }}
                    disabled={!nameInputValue || nameInputValue.length < 3}
                  >
                    <FontAwesomeIcon icon="fas fa-chevron-down" size="16px" />
                  </IconButton>
                }
                aria-controls={`ocr-general-settings`}
                id={`ocr-general-settings`}
                sx={{ cursor: 'auto' }}
                onClick={(e) => e.stopPropagation()}
              >
                <Stack direction="row" spacing={1}>
                  {ocrTemplateFolderField && (
                    <>
                      <Typography data-testid="ocr-field" fontWeight={500} sx={label}>
                        {ocrTemplateFolderField?.name}
                      </Typography>
                      <InputWithActions
                        inputId="template-name"
                        value={nameInputValue}
                        action="initial-template-area"
                        identifierId={0}
                        field={ocrTemplateFolderField}
                        type={'template-name'}
                        canDrawArea={false}
                        canClear={false}
                        canHideArea={false}
                        debouncedChange={debouncedChange}
                        canSave={true}
                        setNameInputValue={setNameInputValue}
                        onSave={() => updateOCRTemplate()}
                        renderInput={
                          folder?.apProcessingSettings?.ocrTemplateNameSupplierCodePairs
                            ?.length
                            ? 'autocomplete'
                            : 'textfield'
                        }
                        folder={folder}
                        setFolder={setFolder}
                      />
                    </>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack data-testid="identifiers-container" gap={1}>
                  {ocrTemplateFolderField &&
                    nameInputValue &&
                    nameInputValue.length >= 3 &&
                    templateIdentifiers.length > 0 &&
                    templateIdentifiers.map((el, index) => {
                      return (
                        <InputWithActions
                          inputId={'identifier'}
                          el={el}
                          key={index}
                          identifier={el}
                          value={el.identifierText}
                          action="set-identifier"
                          identifierId={index}
                          field={ocrTemplateFolderField}
                          type={'identifier'}
                          canDrawArea={true}
                          canClear={true}
                          canHideArea={false}
                          canSave={true}
                          debouncedChange={identifiersValueChange}
                          onSave={handleModifiedIdentifiers}
                        />
                      );
                    })}
                </Stack>
              </AccordionDetails>
              <AccordionActions>
                <StyledActionButton
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={'fas fa-plus'} size="6px" />}
                  onClick={() => {
                    setTemplateIdentifiers((curr) => [...curr, { identifierText: '' }]);
                    setCanDraw(true);
                    togglePanning(false);
                    setFolderFieldId(ocrTemplateFolderField.id);
                    setInputType('identifier');
                    setAction('set-identifier');
                    const identifierId = templateIdentifiers.length;
                    setIdentifierId(identifierId);
                    setSelectedObject(
                      `${ocrTemplateFolderField.id}-identifier-${identifierId}`
                    );
                    setCurrentPage(1);
                    setBackdrop(true);
                  }}
                  sx={{
                    fontSize: 12,
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.greyblueDark
                        : theme.palette.common.lightblue,
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.common.greyblueDark,
                  }}
                >
                  {templateIdentifiers?.length < 1
                    ? 'Add an identifier'
                    : 'Add an extra identifier'}
                </StyledActionButton>
              </AccordionActions>
            </Accordion>

            {ocrTemplate && headerValuePairs?.length > 0 && (
              <OCRSetupHeaderValuePairs
                headerValuePairs={headerValuePairs}
                setHeaderValuePairs={setHeaderValuePairs}
                handleRemoveFields={handleRemoveFields}
                updateAdvancedSettings={updateAdvancedSettings}
                setOcrTemplateLoader={setOcrTemplateLoader}
                ocrTemplateDetails={ocrTemplateDetails}
                ocrTemplate={ocrTemplate}
              />
            )}
          </Stack>

          {nameInputValue && nameInputValue.length >= 3 && (
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Tooltip title="Delete Template">
                <span>
                  {ocrTemplate && (
                    <IconButton aria-label="delete" onClick={handleDelete}>
                      <FontAwesomeIcon
                        icon="fas fa-trash"
                        size="14px"
                        color="common.negative"
                      />
                    </IconButton>
                  )}
                </span>
              </Tooltip>
              {templateIdentifiers?.length > 0 &&
                templateIdentifiers[0].identifierText !== '' && (
                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <CancelButton
                      size="small"
                      variant="contained"
                      startIcon={<FontAwesomeIcon icon="fas fa-times" size="14px" />}
                      onClick={handleCancel}
                    >
                      Cancel
                    </CancelButton>
                    {!ocrTemplate && (
                      <SaveButton
                        size="small"
                        variant="outlined"
                        type="submit"
                        startIcon={<FontAwesomeIcon icon="fas fa-save" size="14px" />}
                        onClick={() => onCreate()}
                      >
                        Create Template
                      </SaveButton>
                    )}
                  </Stack>
                )}
            </Stack>
          )}
        </Stack>

        <Backdrop
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backdropFilter: 'blur(2px)',
            height: '100%',
            width: '100%',
            color: '#fff',
            textAlign: 'center',
            padding: '0 25px',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={backdrop}
        >
          <Stack gap={2} justifyContent="center" alignItems="center">
            <Typography variant="h5" sx={{ color: '#fff' }}>
              Draw a box around the text that you want to set as an identifier
            </Typography>
            <Typography variant="h5" sx={{ color: '#fff' }}>
              Remember, all identifiers must be on the first page, <br />
              we will enable the other pages once all identifiers are set.
            </Typography>
            <CancelButton
              size="small"
              variant="contained"
              startIcon={<FontAwesomeIcon icon="fas fa-times" size="14px" />}
              onClick={() => {
                setTemplateIdentifiers((curr) =>
                  curr.filter((el) => el.identifierText !== '')
                );
                setBackdrop(false);
              }}
              sx={{ marginTop: '20px' }}
            >
              Cancel
            </CancelButton>
          </Stack>
        </Backdrop>
      </Paper>
      <GeneralSnackbar status={snackbar} handleClose={handleCloseSnackbar} />
      <ModalWrapper
        isOpen={validateAreaDialog.open}
        setIsOpen={() => setValidateAreaDialog(DEFAULT_VALIDATE)}
      >
        <ModalContentBase
          title="Validate OCR area"
          submitButtonText="Draw area"
          contentMinHeight="min-content"
          onCancel={handleCancelValidateAreaModal}
          onSubmit={handleSubmitValidateAreaModal}
        >
          <Box sx={{ width: '100%', maxWidth: '600px' }}>
            {validateAreaDialog.message}
          </Box>
        </ModalContentBase>
      </ModalWrapper>
      <ModalWrapper
        isOpen={confirmModal.open}
        setIsOpen={() => setConfirmModal({ open: false, message: '' })}
      >
        <ModalContentBase
          title="Confirm identifiers"
          submitButtonText="Accept"
          contentMinHeight="min-content"
          onCancel={handleConfirmCancel}
          onSubmit={handleConfirmSubmit}
        >
          <Stack gap={1}>
            {identifiersToFonfirm.map((el, index) => {
              return (
                <ConfirmIdentifiersInput
                  key={el.identifierText}
                  identifier={el}
                  identifierIndex={index}
                  identifiersToConfirm={identifiersToFonfirm}
                  setIdentifiersToConfirm={setIdentifiersToConfirm}
                />
              );
            })}
          </Stack>
        </ModalContentBase>
      </ModalWrapper>
    </>
  );
};

export default OCRTemplateSetup;
