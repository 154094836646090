import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonGroup, Paper, Stack, Tooltip } from '@mui/material';
import { MutableRefObject, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FontAwesomeIcon from '../../components/FAIcon';
import { Row, SelectedFolder } from '../../components/masterTable/masterTableTypes';
import { modifyLocalStorageObject, useLocalStorage } from '../../hooks/useLocalStorage';
import FolderSelector from '../folder/FolderSelector';
import { Record } from '../search/types';
import Filters from './Filters';
import TemplateSelector from './TemplateSelector';

interface ReportingSidebarProps {
  setSelectedFolder: React.Dispatch<React.SetStateAction<SelectedFolder | null>>;
  setViewMode: React.Dispatch<React.SetStateAction<string>>;
  selectedFolder: SelectedFolder | null;
  loading: boolean;
  buttonRef: MutableRefObject<null | HTMLButtonElement>;
  rows: Row[] | Record[];
  setData: any;
  setCols: React.Dispatch<React.SetStateAction<any>>;
  setRows: React.Dispatch<React.SetStateAction<any>>;
}

export type Template = {
  id: string | number;
  label: string;
  name: string;
  type: 'workflow' | 'linked_information';
  variables: {
    filters: Filter[];
  };
  folderId?: string;
};

export type Filter = {
  id: string;
  label: string;
  type: string;
  fieldname?: string;
  identifier?: string;
  value: any;
} | null;

const initialTemplates: Template[] = [
  {
    label: 'Approved',
    id: 1,
    name: 'approved',
    type: 'workflow',
    variables: {
      filters: [
        {
          type: 'ReportTypeEnum',
          id: 'workflow-status',
          label: 'Workflow Status',
          value: 'Approved',
        },
      ],
    },
  },
  {
    label: 'Rejected',
    id: 2,
    name: 'rejected',
    type: 'workflow',
    variables: {
      filters: [
        {
          type: 'ReportTypeEnum',
          id: 'workflow-status',
          label: 'Workflow Status',
          value: 'Rejected',
        },
      ],
    },
  },
  {
    label: 'Unapproved',
    id: 3,
    name: 'Unapproved',
    type: 'workflow',
    variables: {
      filters: [
        {
          type: 'ReportTypeEnum',
          id: 'workflow-status',
          label: 'Workflow Status',
          value: 'Unapproved',
        },
      ],
    },
  },
  {
    label: 'Linked Data',
    id: 4,
    name: 'Not synced',
    type: 'linked_information',
    variables: {
      filters: [
        {
          type: 'ReportTypeEnum',
          id: 'linked-data-status',
          label: 'Linked data status',
          value: 'LinkedInfoException',
        },
      ],
    },
  },
];

const options = (
  folder: SelectedFolder | undefined,
  templates: Template[] = initialTemplates
) => {
  if (!folder) return [];
  if (!folder.workFlow) {
    templates = templates.filter((tmp) => tmp.type !== 'workflow');
  }

  templates = templates.filter((template) =>
    template.folderId ? template.folderId === folder.id : template
  );

  let hasDateTimeField = folder.folderFields.find((field) => field.type === 'datetime');

  if (hasDateTimeField) {
    // Date range filters is added automatically if folder has a date time ff
    templates = templates.map((template) => {
      let hasDateRangeFilter = template.variables.filters.find(
        (filter) => filter?.id === 'date-range'
      );
      if (hasDateRangeFilter) return template;
      return {
        ...template,
        variables: {
          ...template.variables,
          filters: [
            ...template.variables.filters,
            {
              type: 'dateRange',
              id: 'date-range',
              label: 'Date range',
              value: {
                from: null,
                to: null,
              },
            },
          ],
        },
      };
    });
  }
  return templates;
};

const ReportingSideBar = (props: ReportingSidebarProps) => {
  const { watch, setValue } = useFormContext();
  const {
    setSelectedFolder,
    setViewMode,
    selectedFolder,
    buttonRef,
    rows,
    setData,
    setCols,
    setRows,
  } = props;
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const [screenSelectedData, setScreenSelectedData] =
    useLocalStorage('screenSelectedData');
  const [savedTemplates] = useLocalStorage('reportingTemplates', []);
  const [userData, setUserData] = useLocalStorage('userData');
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [templates, setTemplates] = useState<Template[]>(
    savedTemplates ? savedTemplates : []
  );
  
  const handleChangeMode = (e: React.ChangeEvent<HTMLInputElement>) =>
    setViewMode(e.target.value);

  const clearSelection = () => {
    setData(null);
    setCols([]);
    setRows([]);
    setFilters([]);
    setSelectedTemplate(null);
    setValue('selectedTemplate', null);
    setScreenSelectedData(
      modifyLocalStorageObject(
        {
          ...screenSelectedData,
          indexingScreenSelected: { selectedRows: [], selected: [] },
        },
        `indexingScreenSelected`
      )
    );
  };
  const handleChangeFolder = (selected: SelectedFolder) => {
    clearSelection();
    setUserSettings(
      modifyLocalStorageObject(
        { ...userSettings, lastReportFolder: selected.id },
        'userSettings'
      )
    );
    setUserData(
      modifyLocalStorageObject(
        {
          ...userData,
          selFolder: selected,
        },
        'userData'
      )
    );
    setSelectedFolder(selected);
  };

  const handleCreateTemplate = (template: Template) => {
    const index = templates.findIndex((obj) => obj.name === template.name);
    if (index >= 0) {
      const updatedTemplates = templates;
      updatedTemplates[index] = template;
      setTemplates(updatedTemplates);
    } else {
      setTemplates((prev) => [...prev, template]);
    }
    setSelectedTemplate(template);
    setFilters(template.variables.filters);
    let savedTemplates = window.localStorage.getItem('reportingTemplates');
    const parsedTemplates = JSON.parse(savedTemplates || '[]');

    let updatedTemplates = parsedTemplates.length
      ? [...parsedTemplates, template]
      : [...templates, template];
    window.localStorage.setItem('reportingTemplates', JSON.stringify(updatedTemplates));
  };

  const handleEditTemplate = (template: Template) => {
    setTemplates((templates) => {
      let filteredTemplates = templates.filter((tmp) => tmp.id !== template.id);
      let updatedTemplates = [...filteredTemplates, template];
      window.localStorage.setItem('reportingTemplates', JSON.stringify(updatedTemplates));
      return updatedTemplates;
    });
    setFilters(template.variables.filters);
  };

  const handleDeleteTemplate = (id: string) => {
    setTemplates((templates) => {
      let filteredTemplates = templates.filter((tmp) => tmp.id !== id);
      window.localStorage.setItem(
        'reportingTemplates',
        JSON.stringify(filteredTemplates)
      );
      return filteredTemplates;
    });

    if (selectedTemplate?.id === id) {
      setSelectedTemplate(null);
      setFilters([]);
    }
  };

  let formFilters = watch('selectedTemplate.variables.filters');
  useEffect(() => {
    if (!formFilters) return;
    setFilters(formFilters);
  }, [formFilters]);

  useEffect(() => {
    if (selectedFolder) {
      setTemplates(
        options(selectedFolder, savedTemplates.length ? savedTemplates : initialTemplates)
      );
    }
  }, [selectedFolder, savedTemplates]);

  const filterFolders = (folders: SelectedFolder[]) => {
    return folders.filter(
      (folder) => folder.workFlow || folder.linked_Information_Mapping
    );
  };

  return (
    <Stack spacing={2} sx={{ width: { sm: '100%', md: '25%', lg: '25%' } }}>
      <FolderSelector
        title="Folder Name"
        handleChangeFolder={handleChangeFolder}
        selectedFolder={selectedFolder}
        setUserSettings={setUserSettings}
        userSettings={userSettings}
        filter={filterFolders}
        // setSelectedFolder={setSelectedFolder}
      />
      <Paper
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Stack width="100%" height="100%" overflow={'scrollY'}>
          <TemplateSelector
            options={templates}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            setFilters={setFilters}
            handleDeleteTemplate={handleDeleteTemplate}
          />
          <Filters
            selectedTemplate={selectedTemplate}
            filters={filters}
            setFilters={setFilters}
            handleCreateTemplate={handleCreateTemplate}
            selectedFolder={selectedFolder}
            handleEditTemplate={handleEditTemplate}
          />
        </Stack>
      </Paper>
      <Box>
        <ButtonGroup
          size="small"
          aria-label="small button group"
          sx={{ display: 'flex', width: '100%' }}
        >
          <LoadingButton
            loading={props.loading}
            loadingPosition="start"
            variant="contained"
            type="submit"
            startIcon={
              <Box>
                <FontAwesomeIcon icon="fas fa-search" size={13} />
              </Box>
            }
            sx={{
              fontSize: 14,
              flex: 2,
              '& .MuiCircularProgress-root': {
                color: 'common.positiveDark',
              },
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.common.blueShadow['500']
                  : theme.palette.primary.main,
              '&:hover': {
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.blueShadow['300']
                    : theme.palette.primary.dark,
              },
              '&.Mui-disabled': {
                backgroundColor: '#0a5478',
                color: 'common.lightblue',
              },
            }}
            disabled={watch('selectedTemplate.variables.filters') ? false : true}
          >
            <span>Generate Report</span>
          </LoadingButton>
          <Tooltip title="Share or download this report">
            <span>
              <Button
                variant="contained"
                key="two"
                onClick={() => buttonRef?.current?.click?.()}
                disabled={rows.length === 0}
                sx={{
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                  backgroundColor: 'common.greyblueDarker',
                  padding: '15px 10px',
                  color: 'common.white',
                  ':hover ': {
                    color: 'common.blue',
                    backgroundColor: 'common.white',
                  },
                }}
              >
                <FontAwesomeIcon icon="far fa-share-alt" size={15} />
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
      </Box>
    </Stack>
  );
};

export default ReportingSideBar;
