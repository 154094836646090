import { useEffect, useState, useContext } from 'react';
import { Box, IconButton, Paper, Slide, Stack, Typography } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';
import utils from '../../utils/utils';
import { Context as RTCContext } from '../../context/RTCContext';
import { RTCContextTypes } from '../../context/RTCContextTypes';

export interface Notification {
  id: string;
  action?: string;
  code: string;
  date: number;
  first: boolean;
  message: string;
  onlyPopup: boolean;
  read: boolean;
  screen: string;
  type?: string;
  notificationType: string;
  folderId: string;
  recordId: string;
  taskId: string;
  _id: string;
}

type recordInfo = {
  folderName: string;
  mainFieldName: string;
  mainFieldValue: string;
};

interface NotificationPopupProps {
  pop: boolean;
  data: Notification;
  mainField?: any;
  folder?: any;
  recordInfo: recordInfo | undefined;
  index: number;
}

const NotificationPopup = ({
  pop,
  data: notification,
  recordInfo,
  index,
}: NotificationPopupProps) => {
  const { markNotificationAsNotFirstTime, markOneAsRead, removeTaskNotification } =
    useContext(RTCContext) as RTCContextTypes;
  const [popped, setPopped] = useState(false);

  useEffect(() => {
    let timeOut = setTimeout(() => {
      setPopped(true);
    }, 1000 + 200 * index);
    return () => clearTimeout(timeOut);
  }, [index]);

  useEffect(() => {
    if (popped) {
      let setTime = setTimeout(() => {
        setPopped(false);
        !document.hidden &&
          markNotificationAsNotFirstTime(notification.taskId || notification._id);
      }, 2000 + 400 * index);
      return () => clearTimeout(setTime);
    }
  }, [pop, popped, index]);

  return (
    <Slide direction="left" in={popped} mountOnEnter unmountOnExit timeout={400}>
      <Paper
        onClick={() => {
          setPopped(false);
          if (notification.type === 'ApprovalRequired') {
            window.open(
              `${window.location.protocol}//${window.location.host}/dash/search/${notification.folderId}/${notification.recordId}`,
              '_blank',
              'noreferrer'
            );
            removeTaskNotification(notification.id);
          }
          if (notification.notificationType !== 'onlyPopup')
            markOneAsRead(notification._id);
        }}
        sx={{
          width: '280px',
          minHeight: '60px',
          maxHeight: '180px',
          zIndex: 999999,
          backgroundColor: 'common.greyblueDarkerTransp',
          // left: 'calc(100vw - 300px)',
          // top: height * 70 + 64 + 'px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '10px',
          boxSizing: 'border-box',
          backdropFilter: 'blur(4px)',
          boxShadow: '0px 5px 5px -1px rgb(0 0 0 / 20%)',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ marginRight: '20px' }}>
          {utils.notificationIcons(notification.code)}
        </Box>
        <Box
          sx={{
            width: '240px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle5">{notification.message}</Typography>
          {recordInfo && (
            <Box>
              <Typography variant="subtitle5">
                <strong>{`${recordInfo.mainFieldName}: `} </strong>
                {`${recordInfo.mainFieldValue}`}
              </Typography>{' '}
              <Stack direction="row">
                <Typography sx={{ color: 'common.lightblue', fontWeight: 'bold' }}>
                  Folder:&nbsp;
                </Typography>
                <Typography
                  sx={{ color: 'common.lightblue' }}
                >{`${recordInfo.folderName}`}</Typography>
              </Stack>
            </Box>
          )}
        </Box>
        <Box sx={{ marginLeft: '20px' }}>
          <IconButton
            onClick={(e) => {
              if (notification.notificationType !== 'onlyPopup')
                markOneAsRead(notification._id);
              e.stopPropagation();
            }}
            sx={{
              color: 'white.main',
              '&:hover': {
                backgroundColor: 'white.main',
                color: 'common.positive',
              },
            }}
          >
            <FontAwesomeIcon icon="fas fa-check-double" color="inherit" size="12px" />
          </IconButton>
        </Box>
      </Paper>
    </Slide>
  );
};

export default NotificationPopup;
