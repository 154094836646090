import { useContext, useState } from 'react';
import GeneralSnackbar, { HandleClose, StatusProps } from '../../../components/SnackBar';
import { Box, Container, Dialog, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_FOLDER, CREATE_FOLDER_ON_ORG } from '../../../utils/gql/gqlFolders';
import { AuthContextTypes, Context as AuthContext } from '../../../context/authContext';
import { Context as StatusContext } from '../../../context/statusContext';
import CreateFolderTab from './CreateFolderTab';
import FolderOptions from './FolderOptions';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import ModalActionsTabs from '../../../components/modal/ModalActionsTabs';
import FontAwesomeIcon from '../../../components/FAIcon';
import { FolderPopupProps } from '../types';
import { StatusContextTypes } from '../../../context/statusContextTypes';
import { TabPanelProps } from '@mui/lab';
import _ from 'lodash';

interface FormValues {
  details: {
    active: boolean;
    name: string;
  };
}

type CreateFolderProps = {
  name: string;
  active: boolean;
  autoAppendRecords: boolean;
  scanning: boolean;
  ocrEnabled: boolean;
  workFlow: boolean;
  enforceWorkflowNotes: boolean;
};

const classes = {
  '&.MuiPaper-root': { flexDirection: 'row' },
  dialog: { margin: 'auto', justifyContent: 'flex-start', flexDirection: 'row' },
  dialogTitle: {
    textAlign: 'center',
    padding: { xs: '5px 0', md: '10px 0' },
    fontSize: { xs: 19, md: 22 },
    backgroundColor: 'common.greyblueDark',
    fontWeight: 'bold',
  },
  box: {
    display: 'flex',
    height: 'auto',
    minHeight: '350px',
    flexDirection: { xs: 'column', md: 'row' },
  },
  tabs: {
    paddingTop: { xs: '0', md: '54px' },
    backgroundColor: 'common.greyblueDarker',
    minWidth: '150px',
    '& .MuiTabs-flexContainer': {
      flexDirection: { xs: 'row', md: 'column' },
    },
    '& .Mui-selected': {
      borderRight: { md: '2px solid', xs: '0' },
      borderBottom: { md: '0', xs: '2px solid' },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    tabsContent: {
      minWidth: '200px',
    },
  },
  buttonSubmit: {
    backgroundColor: 'common.blue',
    '&:hover': {
      backgroundColor: 'rgba(11, 114, 185, 1)',
    },
  },
  buttonCancel: {
    color: 'common.negativeDark',
    '&:hover': {
      color: 'common.negative',
      backgroundColor: 'rgba(247, 198, 197, 1)',
    },
  },
};

const initialStateSnackbar: StatusProps = {
  status: false,
  snackbarMessage: '',
  severity: 'warning',
};

function FolderPopup({ handleCloseModal, refetch, isOpenModal }: FolderPopupProps) {
  const methods = useForm<FormValues>();
  const [folderCreatedSnackbar, setFolderCreatedSnackbar] =
    useState<StatusProps>(initialStateSnackbar);
  const [tabsValue, setTabsValue] = useState<number>(0);
  const { state } = useContext(StatusContext) as StatusContextTypes;
  const authContext = useContext(AuthContext) as AuthContextTypes;
  const [{ suser, selOrg }] = useLocalStorage('userData');
  const isSuser = authContext.state.suser || suser;
  const [createFolderMutation] = useMutation(
    isSuser ? CREATE_FOLDER_ON_ORG : CREATE_FOLDER
  );

  const createFolder = ({
    name,
    active,
    autoAppendRecords,
    scanning,
    ocrEnabled,
    workFlow,
    enforceWorkflowNotes,
  }: CreateFolderProps) => {
    return createFolderMutation({
      variables: {
        name,
        active,
        autoAppendRecords,
        scanning,
        workFlow,
        ocrEnabled,
        organisationId: isSuser
          ? state.selectedOrg && _.isObject(state.selectedOrg)
            ? state.selectedOrg.id
            : selOrg.id
          : null,
      },
    });
  };

  const onFolderCreation = (values: FormValues) => {
    if (values && values.details && values.details.name === '') {
      methods.setError('details.name', { type: 'required', message: 'A field is empty' });
      return;
    }
    createFolder({ ...values.details, ...options })
      .then(({ data }) => {
        refetch().then(() => {
          methods.reset(
            {
              details: {
                active: true,
                name: '',
              },
            },
            { keepValues: false }
          );
          handleCloseModal();
        });
        setFolderCreatedSnackbar({
          status: true,
          snackbarMessage: 'Folder Created Successfully',
          severity: 'success',
        });
      })
      .catch((e) => {
        setFolderCreatedSnackbar({
          status: true,
          snackbarMessage: `${e}`,
          severity: 'error',
        });
      });
  };

  const handleCloseSnackbar: HandleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setFolderCreatedSnackbar(initialStateSnackbar);
  };

  const [options, setOptions] = useState<{
    autoAppendRecords: boolean;
    scanning: boolean;
    ocrEnabled: boolean;
    workFlow: boolean;
    enforceWorkflowNotes: boolean;
  }>({
    autoAppendRecords: true,
    scanning: false,
    ocrEnabled: false,
    workFlow: false,
    enforceWorkflowNotes: false,
  });

  const tabs = [
    // Array to dynamically create tabs
    {
      id: 0,
      name: 'Details',
      content: <CreateFolderTab classes={classes} />,
    },
    {
      id: 1,
      name: 'Global Options',
      content: (
        <>
          <Typography sx={classes.dialogTitle} variant="subtitle2">
            Global Options
          </Typography>
          <Container>
            <FolderOptions options={options} setOptions={setOptions} />
          </Container>
        </>
      ),
    },
  ];

  function a11yProps(index: number) {
    return {
      id: `user-create-tab-${index}`,
      'aria-controls': `user-create-tabpanel-${index}`,
    };
  }

  const handleChangeTabs = (e: React.SyntheticEvent, newValue: number) =>
    setTabsValue(newValue);

  function TabPanel(props: TabPanelProps & { index: number; value: number }) {
    const { children, value, index, sx, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0, minWidth: '400px', minHeight: '200px', ...sx }}>
            {children}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <>
      <Dialog
        open={isOpenModal}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth="sm"
        sx={classes.dialog}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onFolderCreation)}>
            <Box
              sx={{
                ...classes.box,
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? 'background.paper' : 'common.white',
              }}
            >
              <Stack justifyContent="space-between" sx={classes.tabs}>
                <Tabs
                  value={tabsValue}
                  onChange={handleChangeTabs}
                  aria-label="Create Folder"
                  textColor="secondary"
                  indicatorColor="secondary"
                  // sx={classes.tabs}
                >
                  {tabs &&
                    tabs.map((tab) => (
                      <Tab
                        key={tab.id}
                        label={tab.name}
                        sx={{
                          color: '#fff',
                          '& .MuiIcon-root': {
                            paddingRight: '5px',
                          },
                        }}
                        {...a11yProps(tab.id)}
                        iconPosition="start"
                        icon={
                          _.has(methods.formState.errors, tab.name.toLowerCase()) ? (
                            <FontAwesomeIcon
                              icon="fas fa-exclamation-triangle"
                              size={12}
                              color="#d0424f"
                            />
                          ) : undefined
                        }
                      />
                    ))}
                </Tabs>
              </Stack>

              <Stack justifyContent="space-between">
                {tabs &&
                  tabs.map((tab) => (
                    <TabPanel
                      key={tab.id}
                      value={tabsValue}
                      index={tab.id}
                      sx={classes.tabs.tabsContent}
                    >
                      {tab.content}
                    </TabPanel>
                  ))}
                <ModalActionsTabs
                  submitText="CREATE"
                  handleCancel={() => {
                    handleCloseModal();
                    methods.reset();
                  }}
                />
              </Stack>
            </Box>
          </form>
        </FormProvider>
      </Dialog>
      <GeneralSnackbar
        status={folderCreatedSnackbar}
        handleClose={handleCloseSnackbar}
      ></GeneralSnackbar>
    </>
  );
}

export default FolderPopup;
