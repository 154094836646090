import { Box } from '@mui/material';
import { useState } from 'react';
import DrawerPermission from './DrawerPermission';
import { DrawerContainerPermissionsProps } from './permissionsTypes';

const DrawerContainerPermissions = ({
  initial,
  initialSchema,
  setGlobalState,
  globalState,
}: DrawerContainerPermissionsProps) => {
  // saves data from item parent drawer
  const [parentItem, setParentItem] = useState<{}>({});
  // array of the path to the selected item
  const [parentLabel, setParentLabel] = useState<Array<string>>(['']);
  const [parentValue, setParentValue] = useState<boolean | null>(null);
  return (
    <Box
      height="100%"
      display="flex"
      sx={{
        flex: 24,
        padding: 0,
        overflowX: 'auto',
        maxWidth: '100%',
        boxSizing: 'border-box',
        '&::-webkit-scrollbar': {
          width: '2px',
          height: '8px',
        },

        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
        },

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#79909f',
          borderRadius: '4px',
        },
      }}
    >
      <DrawerPermission
        initial={initial}
        content={initial}
        initialSchema={initialSchema}
        setGlobalState={setGlobalState}
        initialParentLabel={['']}
        globalState={globalState}
        setParentItem={setParentItem}
        parentItem={parentItem}
        setParentLabel={setParentLabel}
        parentLabel={parentLabel}
        initialDrawerNumber={0}
        setParentValue={setParentValue}
        parentValue={parentValue}
      />
    </Box>
  );
};

export default DrawerContainerPermissions;
