import { gql } from 'apollo-boost';

export const GET_PERMISSION_TEMPLATES = gql`
  query permissionTemplates {
    permissionTemplates {
      permissionsTemplates {
        id
        fullFolderAccess
        folderAccessDetails {
          folderID
          canAccess
          fullAccess
          folderPermissions {
            canDeleteNotes
            canDeleteRecords
            canRemoveIndex
            processPathPermissions {
              canSetProcessPath
              canPerformProcessStep
              canSearchUsingAnyFilter
              requireNoteWhenApproving
              requireNoteWhenRejecting
            }
            browsePermissions {
              canEditIndexedRecords
              folderFieldRestrictions {
                folderFieldId
                folderFieldRestrictionType
                folderFieldFieldType
                folderFieldValue
              }
            }
          }
        }
        fullScreenAccess
        templateName
      }
      error
    }
  }
`;

export const GET_PERMISSION_TEMPLATE_BY_ID = gql`
  query permissionTemplate($templateId: ID!) {
    permissionTemplate(templateId: $templateId) {
      permissionsTemplate {
        id
        fullFolderAccess
        folderAccessDetails {
          folderID
          canAccess
          fullAccess
          folderPermissions {
            canDeleteNotes
            canDeleteRecords
            canRemoveIndex
            processPathPermissions {
              canSetProcessPath
              canPerformProcessStep
              canSearchUsingAnyFilter
              requireNoteWhenApproving
              requireNoteWhenRejecting
            }
            browsePermissions {
              canEditIndexedRecords
              folderFieldRestrictions {
                folderFieldId
                folderFieldRestrictionType
                folderFieldFieldType
                folderFieldValue
              }
            }
          }
        }
        fullScreenAccess
        templateName
      }
      error
    }
  }
`;

export const DEL_PERMISSION_TEMPLATE_BY_ID = gql`
  mutation deletePermissionTemplateById($templateId: ID!) {
    deletePermissionTemplateById(templateId: $templateId) {
      permissionsTemplate {
        id
        fullFolderAccess
        folderAccessDetails {
          folderID
          canAccess
          fullAccess
          folderPermissions {
            canDeleteNotes
            canDeleteRecords
            canRemoveIndex
            processPathPermissions {
              canSetProcessPath
              canPerformProcessStep
              canSearchUsingAnyFilter
              requireNoteWhenApproving
              requireNoteWhenRejecting
            }
            browsePermissions {
              canEditIndexedRecords
              folderFieldRestrictions {
                folderFieldId
                folderFieldRestrictionType
                folderFieldFieldType
                folderFieldValue
              }
            }
          }
        }
        fullScreenAccess
        templateName
      }
      error
    }
  }
`;

export const APPLY_TEMPLATE_TO_USER = gql`
  mutation applyTemplateToUser($templateId: ID!, $userId: ID!) {
    applyTemplateToUser(templateId: $templateId, userId: $userId) {
      success
      error
    }
  }
`;

export const ADD_PERMISSION_TEMPLATE = gql`
  mutation addEditPermissionTemplate($permissions: PermissionsInput!) {
    addEditPermissionTemplate(permissions: $permissions) {
      permissionsTemplate {
        id
        fullFolderAccess
        folderAccessDetails {
          folderID
          canAccess
          fullAccess
          folderPermissions {
            canDeleteNotes
            canDeleteRecords
            canRemoveIndex
            processPathPermissions {
              canSetProcessPath
              canPerformProcessStep
              canSearchUsingAnyFilter
              requireNoteWhenApproving
              requireNoteWhenRejecting
            }
            browsePermissions {
              canEditIndexedRecords
              folderFieldRestrictions {
                folderFieldId
                folderFieldRestrictionType
                folderFieldFieldType
                folderFieldValue
              }
            }
          }
        }
        fullScreenAccess
        templateName
      }
      error
    }
  }
`;
