import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, ButtonProps, Stack, styled } from '@mui/material';
import FontAwesomeIcon from '../../../components/FAIcon';
import { Step, StepError, Workflow } from '../types';
import TemplateDialog from './TemplateDialog';
import TemplateSelector from './TemplateSelector';
import { toBeApprovedByIsEmpty } from '../utils';

const StyledBtn = styled(Button)<ButtonProps & { bgColor?: string }>(({ bgColor }) => ({
  backgroundColor: bgColor ?? '#3A5463',
  textTransform: 'uppercase',
}));

interface FooterProps {
  context: string;
  handleSaveTemplate: (name: string, applyToFolder: boolean) => void;
  applyTemplateToRecord: () => void;
  workflows: Workflow[];
  setSelectedWorkflow: Dispatch<SetStateAction<Workflow | null>>;
  setWorkflowModal: Dispatch<SetStateAction<boolean>>;
  steps: Step[];
  setSteps: Dispatch<SetStateAction<Step[]>>;
  selectedWorkflow: Workflow | null;
  setStepsWithErrors: Dispatch<SetStateAction<StepError[]>>;
  setModalHasModifications: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer = ({
  context,
  handleSaveTemplate,
  applyTemplateToRecord,
  workflows,
  setSelectedWorkflow,
  setWorkflowModal,
  selectedWorkflow,
  steps,
  setStepsWithErrors,
  stepsWithErrors,
  setSteps,
  setModalHasModifications,
}: FooterProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    let check = toBeApprovedByIsEmpty(steps);
    if (check && check.length) {
      setStepsWithErrors(
        check.map((step) => ({
          stepOrder: step.stepOrder,
          error: 'Add users that need to approve this step.',
        }))
      );
    } else {
      setOpenModal(true);
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'common.greyblueDarker',
          padding: '10px',
          maxHeight: '100px',
        }}
      >
        <Stack
          direction="row"
          justifyContent={context === 'tab' ? 'end' : 'space-between'}
        >
          {context === 'modal' && (
            <Stack sx={{ gap: 2, flexDirection: 'row' }}>
              <StyledBtn
                aria-label="cancel"
                onClick={() => {
                  setWorkflowModal(false);
                }}
                variant="contained"
                startIcon={
                  <FontAwesomeIcon
                    icon="fas fa-times-circle"
                    size="12px"
                    color="common.negative"
                  />
                }
              >
                Cancel
              </StyledBtn>
              <StyledBtn
                aria-label="remove-all"
                onClick={() => {
                  setModalHasModifications(true);
                  return selectedWorkflow
                    ? setSelectedWorkflow((prev) =>
                        prev ? { ...prev, processSteps: [] } : null
                      )
                    : setSteps([]);
                }}
                variant="contained"
                startIcon={
                  <FontAwesomeIcon
                    icon="fas fa-times-circle"
                    size="12px"
                    color="common.negative"
                  />
                }
              >
                Remove all
              </StyledBtn>
            </Stack>
          )}
          <Stack direction="row" spacing={1} alignContent="center">
            {context === 'tab' && (
              <TemplateSelector
                workflows={workflows}
                setSelectedWorkflow={setSelectedWorkflow}
              />
            )}
            <StyledBtn
              aria-label="set workflow to record"
              variant="contained"
              startIcon={
                <FontAwesomeIcon
                  icon="fas fa-check-circle"
                  size="12px"
                  color={stepsWithErrors.length ? 'common.negative' : 'common.positive'}
                />
              }
              onClick={() => {
                if (context === 'modal') {
                  applyTemplateToRecord();
                } else {
                  applyTemplateToRecord();
                }
              }}
            >
              Set workflow
            </StyledBtn>
          </Stack>
        </Stack>
      </Box>
      <TemplateDialog
        handleSaveTemplate={handleSaveTemplate}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default Footer;
