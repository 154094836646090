import { Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    borderRadius: '6px',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.common.white
        : theme.palette.common.blueGrey[800],
    borderColor:
      theme.palette.mode === 'light' ? '#778E9F' : theme.palette.common.greyblueDarker,

    '&.Mui-disabled': {
      fontWeight: 'bold',
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.common.lightGrey
          : theme.palette.common.blueGrey[300],
      borderColor: theme.palette.grey[400],
    },
  },
  '.MuiFormLabel-root.Mui-error': {
    fontWeight: theme.palette.mode === 'light' ? 200 : 600,
  },
  '& fieldset': {
    borderColor:
      theme.palette.mode === 'light' ? '#778E9F' : theme.palette.common.greyblueDarker,
  },
}));
