import React, { useState } from 'react';
import Papa from 'papaparse';
import {
  Autocomplete,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@material-ui/icons/Save';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import LinkIcon from '@material-ui/icons/Link';
import ReplayIcon from '@material-ui/icons/Replay';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import {
  GET_FOLDER_BY_ID,
  GET_FOLDER_BY_ID_BY_ORG,
  UPDATE_FOLDER,
} from '../../../utils/gql/gqlFolders';
import { useMutation } from '@apollo/react-hooks';
import { DropzoneArea } from 'material-ui-dropzone';
import FontAwesomeIcon from '../../../components/FAIcon';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { makeStyles } from '@mui/styles';
import { StyledAutocomplete } from '../../../components/form/StyledAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.common.greyblueDarker
        : theme.palette.background.paper,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: 0,
    maxHeight: '70vh',
    [theme.breakpoints.down('md')]: {
      maxHeight: '30vh',
    },
  },
  listTitle: {
    minWidth: 130,
  },
  listTitleLeft: {
    minWidth: 130,
    marginLeft: 10,
  },
  mainField: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.common.greyblueDark : '#43607210',
    marginBottom: 20,
    padding: '10px 0',
    borderRadius: 5,
    textAlign: 'left',
    fontWeight: 700,
  },
  paper: {
    margin: 0,
    color: '#586069',
    fontSize: 13,
    backgroundColor: '#e8e8e8',
  },
  option: {
    minHeight: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dropzone: {
    marginTop: { xs: 1, md: 20 },
    padding: 40,
    height: { xs: '50%', md: '100%' },
    backgroundColor: '#f1f1f1!important',
    boxShadow: '0px 3px 5px 3px inset #0000000f',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  dropzoneParagraphClass: {
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    color: theme.palette.common.greyblueDark,
  },
  title: {
    textAlign: 'center',
    padding: { xs: '5px 0', md: '10px 0' },
    fontSize: 15,
    backgroundColor: theme.palette.common.greyblue,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  heightDiv: {
    overflowY: 'auto',
    maxHeight: '70vh',
    [theme.breakpoints.down('md')]: {
      maxHeight: '30vh',
    },
  },
}));
const setMapObj = (fields, folder) => {
  let folderFields = fields.map((field) => {
    return { title: field.name, id: field.id };
  });
  let map = {};
  fields.forEach((field) => {
    map[field.name] = {
      linkedFieldName: '',
      fieldId: field.id,
    };
  });
  if (folder.linked_Information_Mapping) {
    window.log(folder.linked_Information_Mapping);
    if (folder.linked_Information_Mapping.linkedValues.length) {
      folder.linked_Information_Mapping.linkedValues.forEach((likedValue) => {
        let name = folderFields.filter((field) => field.id === likedValue.fieldId);
        map[name[0].title] = {
          linkedFieldName: likedValue.linkedFieldName,
          fieldId: likedValue.fieldId,
        };
      });
    }
    if (folder.linked_Information_Mapping.primaryLink) {
      let name = folderFields.filter(
        (field) => field.id === folder.linked_Information_Mapping.primaryLink.fieldId
      );
      map[name[0].title] = {
        linkedFieldName: folder.linked_Information_Mapping.primaryLink.linkedFieldName,
        fieldId: folder.linked_Information_Mapping.primaryLink.fieldId,
      };
    }
  }

  return map;
};
const LinkedInfo = ({ fields, folder, setSnackbar, refetch, setCurrentFolder }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [{ suser }] = useLocalStorage('userData');
  const [mapping, setMapping] = useState(setMapObj(fields, folder));
  const [restart, setRestart] = useState(false);
  const [updateFolder] = useMutation(UPDATE_FOLDER);

  const addLinkedInformationMapping = () => {
    const getPrimaryField = () => {
      let primaryField;
      for (let key in mapping) {
        if (mapping[key].fieldId === folder.primaryFolderFieldId) {
          primaryField = mapping[key];
        }
      }

      return primaryField;
    };
    const getSecondaryFields = () => {
      let maps = [];
      for (let key in mapping) {
        if (
          mapping[key].fieldId !== folder.primaryFolderFieldId &&
          mapping[key].linkedFieldName
        ) {
          maps.push(mapping[key]);
        }
      }
      if (!maps.length) {
        return null;
      }
      return maps;
    };
    let infoMapping = {
      linkType: 'csv',
      primaryLink: getPrimaryField(),
      linkedValues: getSecondaryFields(),
    };
    window.log(infoMapping);
    return updateFolder({
      variables: {
        folderId: folder.id,
        infoMapping,
      },
    })
      .then((data) => {
        window.log(data);
        if (data.data.error) {
          setSnackbar({
            status: true,
            snackbarMessage: 'There was an error setting up the mapping.',
            severity: 'error',
          });
        } else if (data.data.addLinkedInformationMappingToFolder) {
          setSnackbar({
            status: true,
            snackbarMessage: 'Mapping saved.',
            severity: 'success',
          });
        }
        refetch();
        return Promise.resolve();
      })
      .catch((e) => {
        console.error('Could not created linked info: ', e);
      });
  };
  const getOptions = (results) => {
    let noneOption = { title: 'none', value: '' };
    results = results.map((result, i) => {
      return { title: result, value: result };
    });

    return [noneOption, ...results];
  };
  const resetMapObj = () => {
    let map = {};
    fields.forEach((field) => {
      map[field.name] = {
        linkedFieldName: '',
        fieldId: field.id,
      };
    });
    setMapping(map);
  };
  const uploadCSV = (file) => {
    window.log(file);
    if (file[0]) {
      const parsedCSV = Papa.parse(file[0], {
        complete: (results, parser) => {
          window.log(results);
          if (results.data[0]) {
            setRows(getOptions(results.data[0]));
          }
        },
      });
    }
  };
  const createLabels = () => {
    let filteredFields = fields.filter((field) => field.name !== 'createdAt');

    let primaryField = filteredFields.map((value, key) => {
      const labelId = `checkbox-list-label-${value.name}`;
      if (value.id === folder.primaryFolderFieldId) {
        return (
          <div className={classes.mainField} key={key}>
            <Typography variant="caption" style={{ marginLeft: 16 }}>
              Primary Field
            </Typography>
            <ListItem key={value.id} role={undefined} dense button>
              <ListItemText
                id={labelId}
                className={classes.listTitle}
                primary={value.name}
              />
              <LinkIcon
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'common.white' : 'common.blue',
                }}
              ></LinkIcon>
              <StyledAutocomplete
                classes={{
                  paper: (theme) => (theme.palette.mode === 'dark' ? {} : classes.paper),
                  option: (theme) =>
                    theme.palette.mode === 'dark' ? {} : classes.option,
                }}
                id="folderFieldLink"
                options={rows}
                getOptionLabel={(option) => {
                  window.log(option);
                  if (option.title) {
                    return option.title;
                  } else {
                    return option;
                  }
                }}
                style={{ width: 300 }}
                value={mapping[value.name].linkedFieldName}
                isOptionEqualToValue={(op, val) => {
                  return op.value === val;
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Field" variant="outlined" />
                )}
                noOptionsText={'All Fields assigned'}
                onChange={(e, newValue) => {
                  window.log('newValue: ', newValue);
                  if (newValue) {
                    let newMapping = { ...mapping };
                    for (let key in mapping) {
                      if (
                        mapping[key].fieldId !== value.id &&
                        mapping[key].linkedFieldName === newValue.title
                      ) {
                        newMapping[key].linkedFieldName = '';
                      }
                      if (mapping[key].fieldId === value.id) {
                        newMapping[key].linkedFieldName = newValue.title;
                      }
                    }

                    setMapping({ ...newMapping });
                    window.log(mapping);
                  }
                }}
              />
            </ListItem>
          </div>
        );
      }
    });
    let secondaryFields = filteredFields.map((value, key) => {
      const labelId = `checkbox-list-label-${value.name}`;
      if (value.id !== folder.primaryFolderFieldId) {
        return (
          <ListItem
            key={value.id}
            role={undefined}
            dense
            button
            className={
              value.id === folder.primaryFolderFieldId ? classes.optionMain : null
            }
          >
            <ListItemText
              id={labelId}
              className={classes.listTitle}
              primary={value.name}
            />
            <LinkIcon
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'common.white' : 'common.blue',
              }}
            ></LinkIcon>
            <StyledAutocomplete
              classes={{
                paper: (theme) => (theme.palette.mode === 'dark' ? {} : classes.paper),
                option: (theme) => (theme.palette.mode === 'dark' ? {} : classes.option),
              }}
              id="folderFieldLink"
              options={rows}
              getOptionLabel={(option) => {
                if (option.title) {
                  return option.title;
                } else {
                  return option;
                }
              }}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Field" variant="outlined" />
              )}
              noOptionsText={'All Fields assigned'}
              value={mapping[value.name].linkedFieldName}
              isOptionEqualToValue={(op, val) => {
                return op.value === val;
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  let newMapping = { ...mapping };
                  for (let key in mapping) {
                    if (
                      mapping[key].fieldId !== value.id &&
                      mapping[key].linkedFieldName === newValue.title
                    ) {
                      newMapping[key].linkedFieldName = '';
                    }
                    if (mapping[key].fieldId === value.id) {
                      newMapping[key].linkedFieldName = newValue.title;
                    }
                  }
                  window.log(newMapping);
                  setMapping({ ...newMapping });
                  window.log(mapping);
                }
              }}
            />
          </ListItem>
        );
      }
    });
    return [...primaryField, ...secondaryFields];
  };
  return (
    <>
      {rows.length ? (
        <>
          <div style={{ textAlign: 'end' }}>
            <div className={classes.heightDiv}>
              <List sx={classes.root}>{fields && createLabels()}</List>
            </div>
            <ButtonGroup
              style={{ marginTop: 20 }}
              color="primary"
              aria-label="primary button group"
            >
              <Tooltip title="Save Mapping">
                <IconButton
                  style={{ minWidth: 20 }}
                  color="primary"
                  onClick={() => {
                    let maps = [];
                    for (let key in mapping) {
                      if (mapping[key].linkedFieldName) {
                        maps.push(true);
                      }
                    }
                    if (maps.includes(true)) {
                      addLinkedInformationMapping();
                    }
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Clear Mapping">
                <IconButton
                  style={{ minWidth: 20 }}
                  color="error"
                  onClick={() => {
                    resetMapObj();
                  }}
                >
                  <DeleteSweepIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Restart">
                <IconButton
                  sx={{
                    minWidth: 20,
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? 'common.white'
                        : 'common.greyblueDark',
                  }}
                  onClick={() => setRows([])}
                >
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          </div>
        </>
      ) : folder.linked_Information_Mapping && !restart ? (
        <div style={{ textAlign: 'right' }}>
          <List className={classes.root}>
            {fields.map((value, key) => {
              const labelId = `checkbox-list-label-${value.name}`;
              if (value.id === folder.primaryFolderFieldId) {
                return (
                  <div className={classes.mainField} value={value.id}>
                    <Typography variant="caption" style={{ marginLeft: 16 }}>
                      Primary Field
                    </Typography>
                    <ListItem key={value.id} role={undefined} dense button>
                      <ListItemText
                        id={labelId}
                        className={classes.listTitle}
                        primary={value.name}
                      />
                      <LinkIcon
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === 'dark'
                              ? 'common.white'
                              : 'common.blue',
                        }}
                      />
                      <ListItemText
                        id={labelId}
                        className={classes.listTitleLeft}
                        primary={
                          folder.linked_Information_Mapping.primaryLink
                            ? folder.linked_Information_Mapping.primaryLink
                                .linkedFieldName
                            : 'not linked'
                        }
                      />
                    </ListItem>
                  </div>
                );
              } else {
                const getLinkedValue = (linkedValues, field) => {
                  let valuesToReturn;
                  if (linkedValues) {
                    valuesToReturn = linkedValues.filter(
                      (linked) => linked.fieldId === field.id
                    );
                  }
                  if (valuesToReturn[0]) {
                    return valuesToReturn[0].linkedFieldName;
                  } else {
                    return 'not linked';
                  }
                };
                return (
                  <ListItem key={value.id} role={undefined} dense button>
                    <ListItemText
                      id={labelId}
                      className={classes.listTitle}
                      primary={value.name}
                    />
                    <LinkIcon
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === 'dark' ? 'common.white' : 'common.blue',
                      }}
                    ></LinkIcon>
                    <ListItemText
                      id={labelId}
                      className={classes.listTitleLeft}
                      primary={getLinkedValue(
                        folder.linked_Information_Mapping.linkedValues,
                        value
                      )}
                    />
                  </ListItem>
                );
              }
            })}
          </List>
          <ButtonGroup
            style={{ marginTop: 20 }}
            color="primary"
            aria-label="primary button group"
          >
            <Tooltip title="Restart">
              <IconButton
                style={{ minWidth: 20 }}
                onClick={() => {
                  setRestart(true);
                  resetMapObj();
                }}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </div>
      ) : (
        <>
          <Typography className={classes.title}>Mapping external data</Typography>
          <Box sx={{ height: { xs: 'auto', sm: 'auto', md: '60px' } }}>
            <Typography>
              Setup here the way you want to map the external information into
              datacapture.
            </Typography>
            <Typography>
              Add a CSV file that you want to import to start the setup.
            </Typography>
          </Box>
          <DropzoneArea
            dropzoneParagraphClass={classes.dropzoneParagraphClass}
            dropzoneClass={classes.dropzone}
            onDrop={(droppedFiles) => uploadCSV(droppedFiles)}
            showPreviewsInDropzone={false}
            filesLimit={1}
            maxFileSize={1000000}
            acceptedFiles={['text/csv']}
            Icon={() => {
              return <FontAwesomeIcon icon="fa-file-upload" size={42} color="#436072" />;
            }}
          />
        </>
      )}
    </>
  );
};

export default LinkedInfo;
