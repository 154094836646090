import { Autocomplete, IconButton } from '@mui/material';
import React, { useMemo, useState } from 'react';
import FontAwesomeIcon from '../FAIcon';
import IdentifierConfigModal from '../../containers/folder/subcomponents/OCRIdentifier/IdentifierConfigModal';
import { StyledTextField } from './StyledComponents';
import { ValuePair } from '../../containers/folder/KeyValueFilterInput';

interface KeyValuePairAutocompleteProps {
  value: any;
  folder: any;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setNameInputValue: React.Dispatch<React.SetStateAction<string>>;
  setFolder: React.Dispatch<React.SetStateAction<any>>;
}

const KeyValuePairAutocomplete = ({
  value,
  folder,
  inputValue,
  setInputValue,
  setNameInputValue,
  setFolder,
}: KeyValuePairAutocompleteProps) => {
  const findCompleteValue = useMemo(
    () =>
      folder?.apProcessingSettings?.ocrTemplateNameSupplierCodePairs?.find(
        (key: ValuePair) => key.code === value
      ),
    [folder, value]
  );
  const [val, setValue] = useState(findCompleteValue);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => setIsModalOpen(false);
  const handleOpen = () => setIsModalOpen(true);
  return (
    <>
      <Autocomplete
        sx={{
          width: '200px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputBase-root': { padding: '2px' },
        }}
        autoHighlight={true}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option) => `${option.code} (${option.name})`}
        value={findCompleteValue || null}
        options={folder.apProcessingSettings?.ocrTemplateNameSupplierCodePairs}
        inputValue={inputValue}
        defaultValue={val}
        onChange={(e, newValue) => {
          if (newValue === null) newValue = { code: '' };
          setInputValue(newValue);
          setValue(newValue);
          setNameInputValue(newValue.code);
        }}
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => <StyledTextField autoFocus {...params} />}
        renderOption={(props, option) => (
          <li {...props}>{`${option.code} (${option.name})`}</li>
        )}
      />
      <IconButton sx={{ borderRadius: '4px' }} onClick={handleOpen}>
        <FontAwesomeIcon icon="fas fa-cog" size="16px" />
      </IconButton>
      <IdentifierConfigModal
        folder={folder}
        isOpen={isModalOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        refetch={setFolder}
        fromMasterTable
      />
    </>
  );
};

export default KeyValuePairAutocomplete;
