import { styled, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import FontAwesomeIcon from '../../FAIcon';

import { FolderField, LastEnteredValue } from '../masterTableTypes';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: '10px',
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&.MuiToggleButton-root': {
      color: theme.palette.common.white,
      padding: '10px',
      borderRadius: '100%',
      '&:hover': {
        color: theme.palette.common.orange,
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      color: theme.palette.common.orange,
      backgroundColor: 'transparent',
    },
  },
}));

interface HeaderToggleButtonsProps {
  column: FolderField;
  holdFieldValue: React.MutableRefObject<string[]>;
  setHoldFieldValue: React.Dispatch<React.SetStateAction<string[]>>;
  lastEnteredValues: LastEnteredValue[];
}

const HeaderToggleButtons = (props: HeaderToggleButtonsProps) => {
  const { column, holdFieldValue } = props;
  const [toggled, setToggled] = useState<string[]>([]);

  const handleSelected = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string[]
  ) => {
    setToggled(value);
    if (holdFieldValue.current.includes(column.name)) {
      holdFieldValue.current = holdFieldValue.current.filter(
        (name) => name !== column.name
      );
    } else {
      holdFieldValue.current = [...holdFieldValue.current, column.name];
    }
  };

  return (
    <StyledToggleButtonGroup
      value={toggled}
      onChange={handleSelected}
      aria-label="Column options"
    >
      <Tooltip title="Copy value" arrow>
        <ToggleButton
          selected={holdFieldValue.current.includes(column.name)}
          value="holdValue"
          aria-label="holdValue"
        >
          <FontAwesomeIcon icon="fas fa-copy" size="15px" />
        </ToggleButton>
      </Tooltip>
    </StyledToggleButtonGroup>
  );
};

export default HeaderToggleButtons;
