import { useState } from 'react';
import { Box } from '@mui/material';
import DrawerLabel from './DrawerLabel';
import { DrawerPermissionProps } from './permissionsTypes';

const DrawerPermission = ({
  content = [],
  initial,
  initialSchema,
  setGlobalState,
  globalState,
  setParentItem,
  parentItem,
  setParentLabel,
  parentLabel,
  initialDrawerNumber,
  setParentValue,
  parentValue,
}: DrawerPermissionProps) => {
  const [index, setIndex] = useState<number>(0);
  const [openChildren, setOpenChildren] = useState<boolean>(false);
  const [contentChildren, setContentChildren] = useState<any>({});
  const [schema, setSchema] = useState(initialSchema);
  const [dropdownValue, setDropdownValue] = useState<number | string | null>(null);
  const [drawerNumber] = useState(initialDrawerNumber);

  const sortData = (array: any[]) => {
    const sortedData = array.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    return sortedData;
  };

  if (!content || content.length === 0 || Object.keys(content).length === 0) return null;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          boxShadow: '6px 1px 20px 6px #00000014',
          minWidth: 220,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '2px',
          },

          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 5px 0 6px -6px rgba(0, 0, 0, 0.3)',
          },

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#79909f',
            borderRadius: '4px',
          },
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {initial
              ? (schema.sort ? sortData(initial) : initial).map(
                  (item: any, i: number) => (
                    <DrawerLabel
                      key={i + item.name}
                      item={item}
                      i={i}
                      index={index}
                      setIndex={setIndex}
                      setOpenChildren={setOpenChildren}
                      openChildren={openChildren}
                      setContentChildren={setContentChildren}
                      initial={true}
                      setSchema={setSchema}
                      schema={schema}
                      contentChildren={contentChildren}
                      setGlobalState={setGlobalState}
                      setParentLabel={setParentLabel}
                      setParentItem={setParentItem}
                      parentItem={parentItem}
                      parentLabel={parentLabel}
                      globalState={globalState}
                      setDropdownValue={setDropdownValue}
                      dropdownValue={dropdownValue}
                      drawerNumber={drawerNumber}
                      setParentValue={setParentValue}
                      parentValue={parentValue}
                    />
                  )
                )
              : (schema.sort ? sortData(content) : content).map(
                  (item: any, i: number) => (
                    <DrawerLabel
                      key={i + item.name}
                      item={item}
                      i={i}
                      index={index}
                      setIndex={setIndex}
                      setOpenChildren={setOpenChildren}
                      openChildren={openChildren}
                      setContentChildren={setContentChildren}
                      schema={schema}
                      setSchema={setSchema}
                      contentChildren={contentChildren}
                      setGlobalState={setGlobalState}
                      setParentLabel={setParentLabel}
                      parentLabel={parentLabel}
                      globalState={globalState}
                      setParentItem={setParentItem}
                      parentItem={parentItem}
                      setDropdownValue={setDropdownValue}
                      dropdownValue={dropdownValue}
                      drawerNumber={drawerNumber}
                      setParentValue={setParentValue}
                      parentValue={parentValue}
                      initial={false}
                      content={content}
                    />
                  )
                )}
          </Box>
        </Box>
      </Box>
      {openChildren && Object.keys(schema).length !== 0 && (
        <DrawerPermission
          content={contentChildren}
          initialSchema={{ ...schema?.children, content: schema?.children?.content }}
          setGlobalState={setGlobalState}
          initialParentLabel={parentLabel}
          globalState={globalState}
          setParentItem={setParentItem}
          parentItem={parentItem}
          setParentLabel={setParentLabel}
          parentLabel={parentLabel}
          initialDrawerNumber={drawerNumber + 1}
          setParentValue={setParentValue}
          parentValue={parentValue}
        />
      )}
    </>
  );
};

export default DrawerPermission;
