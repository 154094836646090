import { getWeek } from 'date-fns';
import { folderDePrueba2, folderDePrueba4 } from '../containers/dashboard/MockupData';

type valueKeys = keyof value;

interface value {
  deleted: number;
  indexed: number;
  scanned: number;
}

interface getTotalData {
  date: string;
  startEndDay?: string[];
  value: value;
}

export const getTotal = (data: getTotalData[], type: string) => {
  // data is an array with the information and type is the key to be added to
  // Gets the total of all data
  return data.reduce(
    (accumulator, curr) => accumulator + curr.value[type as valueKeys],
    0
  );
};

/**
 * @param {Array} props.data Array with the information coming from the DB
 * @param {String} props.type Key where it must iterate
 */

// weekConvert is a function that converts a time span in days to weeks
// Example: receive an array with 42 days and return which week it belongs to and its total.
// {date: 'week 1', value: 88 }, {date: 'week 2', value: 59}, etc
export interface weekConvertData {
  data: getTotalData[];
  frecuency: string;
  id: string;
  name: string;
}
export const weekConvert = (data: weekConvertData, type: string) => {
  // data is an array with the information and type is the key to be added to
  let startEndDay = []; // indicates the start date and end date of the week
  let arr = [];
  let dayCounter = 0; // counts the days that enter each week
  let currentWeek = 0;
  let firstDay = 0; // indicates the first day of each week
  for (let i = 0; i < data.data.length; i++) {
    const weekNumber = getWeek(new Date(data.data[i].date), { weekStartsOn: 1 });
    if (i === 0) currentWeek = weekNumber; // indicates the first week in the first iteration
    if (currentWeek === weekNumber) dayCounter++;

    if (weekNumber !== currentWeek) {
      startEndDay.push(data.data[firstDay].date);

      // when changing the week, enter and cut the array
      if (firstDay !== 0) dayCounter++;
      startEndDay.push(data.data[dayCounter + firstDay - 1].date);

      let week = data.data.slice(firstDay, dayCounter + firstDay); // cuts off from the first day of the week, depending on the number of days
      arr.push(week);
      firstDay = dayCounter + firstDay;
      dayCounter = 0;
    }
    currentWeek = weekNumber;
  }

  let result = arr.map((item, index) => {
    return item.reduce((acc, current) => {
      // returns what week number it is and its total
      let total = acc.value[type as valueKeys] + current.value[type as valueKeys];
      return {
        ...acc,
        date: `week ${index + 1}`, // week 1, week 2, week 3, etc.
        value: { ...acc.value, [type]: total },
        startEndDay: [item[0].date, item[item.length - 1].date],
      };
    });
  });
  return result;
};

/**
 * @param {Array} props.data Array with the information coming from the DB
 * @param {String} props.type Key where it must iterate
 */

// monthConvert is a function that converts a time span in days to months
// Example: receive an array with 90 days and return which month it belongs to and its total.
// {date: 'Apr', value: 188 }, {date: 'May', value: 219}, etc
export const monthConvert = (data: any, type: string) => {
  // data is an array with the information and type is the key to be added to
  let startEndDay = []; // indicates the start date and end date of the month
  let arr = [];
  let dayCounter = 0; // counts the days that enter each month
  let currentMonth = 0;
  let firstDay = 0; // indicates the first day of each month
  for (let i = 0; i <= data.data.length + 1; i++) {
    if (data.data[i]) {
      const monthNumber = new Date(data.data[i].date).getMonth() + 1;
      if (i === 0) currentMonth = monthNumber; // indicates the first month in the first iteration
      if (currentMonth === monthNumber) dayCounter++;
      if (monthNumber !== currentMonth || i === data.data.length - 1) {
        startEndDay.push(data.data[firstDay].date);
        // when changing the month, enter and cut the array
        if (firstDay !== 0) dayCounter++;
        startEndDay.push(data.data[dayCounter + firstDay - 1].date);
        let month = data.data.slice(firstDay, dayCounter + firstDay); // c
        arr.push(month);
        firstDay = dayCounter + firstDay;
        dayCounter = 0;
      }
      currentMonth = monthNumber;
    }
  }
  //   window.log(arr);
  let result = arr.map((item, index) => {
    return item.reduce((acc: any, current: any) => {
      // returns what month number it is and its total
      let month = new Date(current.date).toLocaleString('en-EN', { month: 'short' });
      let total = acc.value[type] + current.value[type];
      return {
        ...acc,
        date: `${month}`,
        value: { ...acc.value, [type]: total },
        startEndDay: [item[0].date, item[item.length - 1].date],
      };
    });
  });
  return result;
};

export const getDataByFrecuency = (
  data: weekConvertData,
  type: string,
  frecuency: string
) => {
  switch (frecuency) {
    case 'day':
      return data.data;
    case 'week':
      return weekConvert(data, type);
    case 'month':
      return monthConvert(data, type);
    default:
      return data.data;
  }
};
