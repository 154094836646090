import { Box, Button, Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';
import FontAwesomeIcon from '../FAIcon';
import SearchInput from './SearchInput';

export interface actions {
  tooltipTitle: string;
  buttonIcon: ReactNode | JSX.Element;
  buttonOnClick: () => void;
  id?: string;
}

export interface ToolbarActionRightProps {
  handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSearchQuery?: React.Dispatch<React.SetStateAction<string | null>>;
  hasBackground?: boolean;
  actions?: actions[] | null;
}
const ToolbarActionRight = ({
  handleSearch,
  setSearchQuery,
  actions = null,
  hasBackground = true,
}: ToolbarActionRightProps) => {
  return (
    <Box sx={{ display: 'flex', maxHeight: '40px', alignItems: 'center' }}>
      {handleSearch && (
        <Box sx={{ marginRight: !actions || !actions.length ? '10px' : 0 }}>
          <SearchInput handleSearch={handleSearch} setSearchQuery={setSearchQuery} />
        </Box>
      )}
      {actions && actions.length > 1 && (
        <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            icon="fas fa-grip-lines-vertical"
            size="18px"
            color={hasBackground ? '#b1c3cf' : 'rgba(255,255,255,0.2)'}
            padding={0}
          />
        </Box>
      )}
      <Box sx={{ display: 'flex' }}>
        {actions &&
          actions.map((action, i) => (
            <Tooltip key={i} title={action.tooltipTitle}>
              <Button
                id={action.id ? action.id : `action-${i}`}
                key={i}
                disableElevation
                sx={{
                  minWidth: '10px',
                  padding: '8px 10px 8px 6px',
                  marginLeft: '4px',
                  color: hasBackground ? 'common.white' : 'common.greyblueDark',
                  '&:hover': {
                    color: 'common.blue',
                    backgroundColor: 'common.white',
                  },
                }}
                onClick={action.buttonOnClick}
              >
                {action.buttonIcon}
              </Button>
            </Tooltip>
          ))}
      </Box>
    </Box>
  );
};

export default ToolbarActionRight;
