import { Alert, AppBar, Collapse, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import FontAwesomeIcon from '../FAIcon';
import { Context as RTCContext } from '../../context/RTCContext';
import { db } from '../../utils/indexedDB/indexeddb';
import RTC from '../../../src/utils/socketComm';
import notifications from '../../containers/Notifications';
import { useCountdown } from '../../hooks/useCountdown';
import { Notification } from '../notificationPopup/NotificationPopup';

const CountDownTimer = ({ message, endDate }: { message: string; endDate: string }) => {
  const [days, hours, minutes, seconds] = useCountdown(endDate);

  return `${message} ${days} days and ${hours} hours and ${minutes} min`;
};

const NotificationsToolbar = () => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState<Notification | null>(null);
  const { state, removeTaskNotification } = useContext(RTCContext);
  console.log(notification);

  useEffect(() => {
    if (state.notifications.comm.length === 0 && state.notifications.toolbar.length === 0)
      return setNotification(null);
    // if (state.notifications.toolbar.length > 0) {
    //   state.notifications.toolbar.find(
    //     (notification) =>
    //       notification.notificationType === 'toolbar' && setNotification(notification)
    //   );
    // }

    if (state.notifications.comm.length > 0) {
      state.notifications.comm.find(
        (notification: Notification) =>
          notification.notificationType === 'toolbar' && setNotification(notification)
      );
    }
  }, [state]);

  useEffect(() => {
    notification && setOpen(true);
  }, [notification]);

  let isMaintenance = false;

  if (notification) {
    isMaintenance =
      notification.type === 'maintenance' || notification.type === 'maintenance-schedule';
  }

  const deleteNotification = () => {
    setOpen(false);
    removeTaskNotification(notification?.id);
  };
  if (!notification) return null;
  return (
    <AppBar
      sx={{
        position: 'static',
        zIndex: '9999',
        textAlign: 'center',
      }}
    >
      <Collapse in={open}>
        <Alert
          icon={
            isMaintenance ? (
              <FontAwesomeIcon
                icon="fas fa-exclamation-triangle"
                size={14}
                color="#000"
              />
            ) : (
              <FontAwesomeIcon icon="fas fa-newspaper" size={14} />
            )
          }
          action={
            isMaintenance ? (
              <FontAwesomeIcon
                icon="fas fa-exclamation-triangle"
                size={14}
                color="#000"
              />
            ) : (
              <>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                    deleteNotification();
                  }}
                  sx={{ padding: 0, color: 'common.positiveDark' }}
                >
                  <FontAwesomeIcon icon="fas fa-times" size={14} />
                </IconButton>
              </>
            )
          }
          sx={{
            borderRadius: 0,
            padding: '0px 16px',
            alignItems: 'center',
            justifyContent: 'space-between',
            '.MuiAlert-action': { margin: 0, padding: 0 },
            '.MuiAlert-message': { padding: 0 },
            backgroundColor: isMaintenance ? '#FBC441' : '#dbffea',
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              color: isMaintenance ? '#000' : 'common.positiveDark',
              textTransform: 'uppercase',
            }}
          >
            {notification &&
            notification.type === 'maintenance-schedule' &&
            notification.startDate
              ? CountDownTimer({
                  message: notification.message,
                  endDate: notification.startDate,
                })
              : notification.message || 'NOTIFICATION'}
            {/* {notification && notification.type !== 'maintenance-schedule'
              ? notification.message
              : 'NOTIFICATION'} */}
          </Typography>
        </Alert>
      </Collapse>
    </AppBar>
  );
};

export default NotificationsToolbar;
