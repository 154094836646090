import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import FontAwesomeIcon from '../FAIcon';

interface NotificationToolbarProps {
  removeAllTaskNotification: () => void;
  markAllAsRead: () => void;
  commNotifications: Notification[];
}

const NotificationToolbar = ({
  removeAllTaskNotification,
  markAllAsRead,
  commNotifications,
}: NotificationToolbarProps) => {
  let disabled = commNotifications?.length === 0;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px',
      }}
    >
      <Typography
        sx={{
          color: 'white.main',
          fontSize: '.9rem',
          padding: '10px',
          fontWeight: 700,
        }}
        variant="subtitle3"
      >
        Notifications
      </Typography>
      <Box>
        <Tooltip title="Mark all as read" arrow>
          <span>
            <IconButton
              onClick={() => markAllAsRead()}
              sx={{
                color: 'white.main',
                '&:hover': {
                  color: 'common.positive',
                  backgroundColor: 'common.white',
                  borderColor: '#0062cc',
                  boxShadow: 'none',
                },
              }}
              aria-label="Mark all as read"
              disabled={disabled}
            >
              <FontAwesomeIcon icon="fas fa-check-double" size="12px" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Clear All" arrow>
          <span>
            <IconButton
              onClick={() => removeAllTaskNotification()}
              sx={{
                color: 'white.main',
                '&:hover': {
                  color: 'common.negative',
                  backgroundColor: 'common.white',
                  borderColor: '#0062cc',
                  boxShadow: 'none',
                },
              }}
              aria-label="Clear all"
              disabled={disabled}
            >
              <FontAwesomeIcon icon="fas fa-eraser" size="12px" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Setup Notifications" arrow>
          <span>
            <IconButton
              sx={{
                color: 'white.main',
                '&:hover': {
                  backgroundColor: '#0069d9',
                  borderColor: '#0062cc',
                  boxShadow: 'none',
                },
              }}
              aria-label="Setup Notifications"
              disabled
            >
              <FontAwesomeIcon icon="fas fa-cog" size="12px" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default NotificationToolbar;
