import React, { useEffect, useState } from 'react';

import ModalWrapper from '../modal/ModalWrapper';
import { Typography } from '@mui/material';
import ModalContent from './ModalContent';
import Stepper from './Stepper';

import data from './example.json';
import { modifyLocalStorageObject, useLocalStorage } from '../../hooks/useLocalStorage';

export interface Features {
  'v1.0': Version[];
  'v1.2': Version[];
  'v2.0.1': Version[];
  'v2.0.2': Version[];
  'v2.0.3': Version[];
}

export interface Version {
  image?: string;
  title: string;
  description: string;
  date: string;
  video?: string;
}

interface NewFeaturesProps {
  isOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appversion: keyof Features;
  setappversion: (appversion: string) => void;
}

const NewFeatures = ({
  isOpen,
  setModalIsOpen,
  appversion,
  setappversion,
}: NewFeaturesProps) => {
  let features: Features = data;
  const [activeStep, setActiveStep] = useState(0);
  const [userSettings, setUserSettings] = useLocalStorage('userSettings');
  const [showAgain, setShowAgain] = useState(false);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleClose = () => {
    setUserSettings(
      modifyLocalStorageObject(
        { ...userSettings, showNewFeatures: showAgain },
        'userSettings'
      )
    );
  };

  useEffect(() => {
    const arrVersions = Object.keys(features);
    const lastVersion = arrVersions[arrVersions.length - 1];

    if (appversion !== lastVersion) {
      setUserSettings(
        modifyLocalStorageObject(
          { ...userSettings, showNewFeatures: true },
          'userSettings'
        )
      );
      setappversion(`${lastVersion}`);
    }
  }, []);

  return (
    <ModalWrapper isOpen={isOpen} setIsOpen={setModalIsOpen}>
      <ModalContent
        title={`New Features in ${appversion}`}
        img={features[appversion][activeStep].image}
        video={features[appversion][activeStep].video}
        contentMinHeight={'140px'}
        stepper={
          <Stepper
            data={features}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            setModalIsOpen={setModalIsOpen}
            appversion={appversion}
          />
        }
        setIsOpen={setModalIsOpen}
        showAgain={showAgain}
        setShowAgain={setShowAgain}
        onClose={handleClose}
      >
        <Typography
          variant="button"
          sx={{
            margin: '0 auto',
            fontSize: 17,
            textAlign: 'center',
            color: '#79909f',
          }}
        >
          {`${data[appversion][activeStep].title}`}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ maxWidth: '95%', margin: '0 auto', fontSize: 16 }}
        >
          {data[appversion][activeStep].description}
        </Typography>
      </ModalContent>
    </ModalWrapper>
  );
};

export default NewFeatures;
