import React from 'react';

const Timer = () => {
  const handleOnMouseMove = (e) => {
    window.log('mouse moved', e);
  };
  return <div onMouseMove={handleOnMouseMove}></div>;
};

export default Timer;
